import React, { useState } from "react";
import { Button, Popover, Whisper } from "rsuite";
import PropTypes from "prop-types";
import { t } from "i18next";

const ShowMore = ({ description, charecterLength, target }) => {
  const [showMore, setShowMore] = useState(false);

  return (
    <>
      {description && description != "" ? (
        <>
          {charecterLength ? (
            <>
              <span className="white-space-line">
                {showMore
                  ? description
                  : `${description.substring(0, charecterLength)}`}
                {description.length > charecterLength ? <>{showMore ? " " : <>...</>}</> : ""}
              </span>
            </>
          ) : (
            <>
              {description.length != 0 && target == undefined ? (
                <span className="white-space-line">
                  {description.substring(0, 160)}
                  {description.length > 160 ? <>{showMore ? " " : <>...</>}</> : " "}
                </span>
              ) : (
                <>
                  {target == "portalSourcingSubject" ? <>
                    {description.substring(0, 20)}
                    {description.length > 20 ? <>...</> : " "}
                  </> : null}</>
              )}
            </>
          )}

          <span
            className="text-primary cursor-pointer"
            onClick={() => setShowMore(!showMore)}
          >
            {charecterLength && description.length > charecterLength ? (
              <>{showMore ? t("showLess", { defaultValue: "Show Less" }) : t("showMore", { defaultValue: "Show More" })}</>
            ) : null}
          </span>
        </>
      ) : null}
    </>
  );
};
ShowMore.propTypes = {
  description: PropTypes.string,
  charecterLength: PropTypes.number,
  target: PropTypes.string,
};
export default ShowMore;
