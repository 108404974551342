import React, { useEffect, useRef, useState, useContext } from "react";
import { useParams, useLoaderData, useRouteLoaderData } from "react-router-dom";

import JobsList from "./JobsList";
import SearchBar from "./SearchBar";
import JobDetails from "./JobDetails";
import { Pagination, Placeholder, Loader } from "rsuite";
import axios from "axios";
import UserContext from "../../app/context/UserContext";

import { Trans } from "react-i18next";
import usePost from "../../customhooks/UsePost";
import Loading from "../../Loder";

export default function Jobs() {
  let { selectedJob, index = 0, page = 1, search } = useParams();
  let { jobs } = useLoaderData();
  const Loadedetails = useRouteLoaderData("jobs");
  console.log("STEP42:JOBS COMPONENT");

  const [Page, setPage] = React.useState(page);
  const [limit, setLimit] = React.useState(6);
  const [total, setTotal] = useState(jobs?.data?.totalResults ?? 0);
  const [JobsListData, setJobsListData] = React.useState(
    jobs?.data?.result ?? []
  );
  const [searchId, setSearchID] = useState(jobs?.searchID ?? "");
  const [showJobDetails, setShowJobDetails] = useState(false);
  const { user, setUserDetails } = useContext(UserContext);

  console.log(user, "userdetails");
  // usePost
  const {
    post: getJobs,
    data,
    isLoading,
    error,
  } = usePost("/jobsearch/search");

  const {
    post: resumeRecommendedJobs,
    data: RecommendedJobs,
    isLoading: resumeRecommendedJobsLoading,
    error: RecommendedJobsError,
  } = usePost("/getresumerecommendedjobs");

  const [Searchvalue, setSearchvalue] = React.useState(index);
  const [selectedMobileIndex, setSelectedMobileIndex] = useState(null);
  const isMounted = useRef(false);
  const [cookies, setCookie] = useState("");
  const Getcookies = async () => {
    try {
      let cookieDetails = await axios.get(
        window.location.origin + "/jsv4/webapi/getcookie"
      );
      cookieDetails = cookieDetails?.data;
      // console.log("cookieDetailscookieDetails", cookieDetails);
      // console.log(user?.resumeID, "sqwdefrgthyjuki");
      if (cookieDetails["Guest_User_ResumeID"]) {
        setCookie(cookieDetails["Guest_User_ResumeID"]);
      }
    } catch (error) {
      console.log("Error in getCookies : ", error);
    }
  };
  useEffect(() => {
    if (user && user?.resumeID) {
      setCookie(user?.resumeID);
    } else {
      if (!isMounted.current) {
        Getcookies();
        isMounted.current = true;
      }
    }
  }, [user]);
  // console.log("cookies", user?.resumeID);
  const handleChangeLimit = (dataKey) => {
    setPage(1);
    setLimit(dataKey);
  };
  const [resume, setResume] = useState([]);

  useEffect(() => {
    if (cookies !== "" && cookies !== undefined) {
      // console.log(Loadedetails);
      let jobsdata = {
        companyID: Loadedetails?.companyID,
        resumeID: cookies,
        userID: user?._id,
      };
      resumeRecommendedJobs(jobsdata)
        .then((res) => {
          // console.log(res?.data, "hsssssssssssdkk");
          if (res) {
            setResume(res?.data);
          }
        })
        .catch((error) => {
          console.error("Error while fetching favourite jobs:", error);
        });
    }
  }, [cookies]);
  // console.log(resume);

  const handlePageChange = (e) => {
    // console.log(Loadedetails, "hewrt5y67u");
    setPage(e);
    setSearchvalue(0);
    let searchJson1 = {
      searchID: searchId,
      size: limit,
      skip: (e - 1) * limit,
    };
    // searchJson1.searchID = searchId;
    // searchJson1.size = limit;
    // searchJson1.page = e;
    // searchJson1.skip = (e - 1) * limit;
    // Loadedetails.searchJson.companyID = Loadedetails.context?.companyID?.toString();
    getJobs(searchJson1).then((res) => {
      setJobsListData(res?.data?.result ?? []);
    });

    if (typeof window !== "undefined") {
      const { search } = window.location;
      const params = new URLSearchParams(search);
      if (params.has("page")) {
        params.delete("page");
      }
      if (params.has("index")) {
        params.set("index", 1);
      }
      params.set("page", e);

      window.history.replaceState(
        {},
        "",
        `${window.location.pathname}?${params}`
      );
    }
  };

  useEffect(() => {
    const queryString = window.location.search;
    const parameters = new URLSearchParams(queryString);
    if (parameters.get("page")) {
      setPage(parseInt(parameters.get("page")));
    }
  }, [Page]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [Page, Searchvalue]);
  // console.log(Loadedetails, "ertrytuyiop");

  const handleJobSelection = (index) => {
    setSearchvalue(index);
    setShowJobDetails(true);
  };

  const handleBackToList = () => {
    setShowJobDetails(false);
  };
  // console.log(Searchvalue, "sdefrtyui");
  return (
    <>
      <div className="my-5">
        <SearchBar
          searchJson={Loadedetails.searchbody}
          getJobs={getJobs}
          setJobsListData={setJobsListData}
          setPage={setPage}
          JobsListData={JobsListData}
          setTotal={setTotal}
          attributes={jobs?.data?.attributesAll}
          searchID={searchId}
          setSearchID={setSearchID}
          companyID={Loadedetails.companyID}
          setSearchvalue={setSearchvalue}
          filtersData={Loadedetails?.searchbody?.filter}
          isLoading={isLoading}
          Page={Page}
          limit={limit}
          total={total}
          setShowJobDetails={setShowJobDetails}
          showJobDetails={showJobDetails}
        />

        <div className="d-lg-block d-none">
          {JobsListData?.length > 0 ? (
            <div className="row">
              <div className="col-4">
                {JobsListData?.map((x, index) => {
                  return (
                    <div onClick={handleJobSelection} key={x._id}>
                      <JobsList
                        key={x._id}
                        data={x}
                        dataindex={index}
                        Searchvalue={Searchvalue}
                        setSearchvalue={setSearchvalue}
                        isLoading={isLoading}
                        // isActive={index === Searchvalue || (window.innerWidth > 990 && index === 0)}
                        selectedMobileIndex={selectedMobileIndex}
                      />
                    </div>
                  );
                })}
              </div>

              <div className="col-8 pt-3">
                {/* {showJobDetails ? (
                  <JobDetails
                    selectedJob={
                      JobsListData?.filter(
                        (x, index) => index === Searchvalue
                      )?.[0]
                    }
                    isLoading={isLoading}
                    Searchvalue={Searchvalue}
                  />
                ) : (
                  <JobDetails
                    selectedJob={
                      JobsListData?.filter(
                        (x, index) => index === Searchvalue
                      )?.[0]
                    }
                    // selectedJob={JobsListData?.[0]}
                    isLoading={isLoading}
                    Searchvalue={Searchvalue}
                  />
                )} */}
                <div className="d-flex">
                  <JobDetails
                    selectedJob={
                      JobsListData?.filter(
                        (x, index) => index === Searchvalue
                      )?.[0]
                    }
                    isLoading={isLoading}
                    Searchvalue={Searchvalue}
                  />
                  <div className="ms-2">
                    <>
                      {resume?.length > 0 ? (
                        <div className="card">
                          <div className="card-body ">
                            <h6 className="SuggestedJobsCard">
                              <Trans
                                i18nKey="suggestedJobsBasedOnSkills"
                                defaults="Suggested Jobs based on Skills"
                              />
                            </h6>
                            {resume?.map((x) => {
                              return (
                                <div key={x?._id}>
                                  <a
                                    href={`/job/${x?._source?.passcode}`}
                                    target="_blank"
                                  >
                                    {x?._source?.title}
                                  </a>
                                  {x?._source?.job?.details?.locations?.length >
                                    0 && (
                                    <div>
                                      Location :{" "}
                                      {x?._source?.job?.details?.locations?.map(
                                        (x) => x?.text
                                      )}
                                    </div>
                                  )}
                                  <div>
                                    Job Type :{" "}
                                    {x?._source?.job?.details?.jobTitle
                                      ?.jobType ?? "NA"}
                                  </div>
                                  <div>
                                    Salary :{" "}
                                    <span>
                                      $
                                      <span className="pe-1 ">
                                        {x?._source?.job?.details?.offeredSalary
                                          ?.minvalue ==
                                        x?._source?.job?.details?.offeredSalary
                                          ?.maxvalue ? (
                                          <>
                                            {x?._source?.job?.details
                                              ?.offeredSalary?.minvalue ?? "0"}
                                          </>
                                        ) : (
                                          <>
                                            {x?._source?.job?.details
                                              ?.offeredSalary?.minvalue
                                              ? x?._source?.job?.details
                                                  ?.offeredSalary?.minvalue
                                              : "0"}
                                            -
                                            {x?._source?.job?.details
                                              ?.offeredSalary?.maxvalue
                                              ? x?._source?.job?.details
                                                  ?.offeredSalary?.maxvalue
                                              : "0"}
                                          </>
                                        )}
                                      </span>
                                      <span>
                                        {x?._source?.job?.details?.offeredSalary
                                          ?.period
                                          ? x?._source?.job?.details
                                              ?.offeredSalary?.period
                                          : "NA"}
                                      </span>
                                    </span>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      ) : resumeRecommendedJobsLoading ? (
                        <div>
                          <Loader content="loading" />
                        </div>
                      ) : (
                        <></>
                      )}
                    </>
                  </div>
                </div>
              </div>
              <div className={`row mt-3 ${isLoading ? "d-none" : ""}`}>
                <Pagination
                  prev
                  next
                  first
                  last
                  ellipsis
                  boundaryLinks
                  maxButtons={5}
                  size="md"
                  layout={["pager"]}
                  total={total}
                  // limitOptions={[10, 25, 50, 100]}
                  limit={limit}
                  activePage={Page}
                  onChangePage={handlePageChange}
                  onChangeLimit={handleChangeLimit}
                  className="Pagination  "
                />
              </div>
            </div>
          ) : (
            searchId?.length > 0 && (
              <div className="d-flex justify-content-center pt-5">
                <h1>
                  <Trans i18nKey="noJobsFound" defaults="No Jobs Found" />
                </h1>
              </div>
            )
          )}
        </div>

        <div className="d-lg-none d-block">
          {JobsListData?.length > 0 && !showJobDetails ? (
            <div className="row">
              <div className="col-12">
                {JobsListData?.map((x, index) => {
                  return (
                    <div onClick={handleJobSelection} key={x._id}>
                      <JobsList
                        key={x._id}
                        data={x}
                        dataindex={index}
                        Searchvalue={Searchvalue}
                        setSearchvalue={setSearchvalue}
                        isLoading={isLoading}
                      />
                    </div>
                  );
                })}
              </div>
              <div className={`row mt-3 ${isLoading ? "d-none" : ""} `}>
                <Pagination
                  prev
                  next
                  first
                  last
                  ellipsis
                  boundaryLinks
                  maxButtons={5}
                  size="md"
                  layout={["pager"]}
                  total={total}
                  // limitOptions={[10, 25, 50, 100]}
                  limit={limit}
                  activePage={Page}
                  onChangePage={handlePageChange}
                  onChangeLimit={handleChangeLimit}
                  className="Pagination"
                />
              </div>
            </div>
          ) : (
            searchId?.length > 0 &&
            JobsListData?.length <= 0 && (
              <div className="d-flex justify-content-center pt-5">
                <h1>
                  <Trans i18nKey="noJobsFound" defaults="No Jobs Found" />
                </h1>
              </div>
            )
          )}

          {showJobDetails && (
            <div className="row">
              <div className="col-12">
                <div className="  lg">
                  <button
                    className="btn btn- primary"
                    onClick={handleBackToList}
                  >
                    <i className="fa-solid fa-arrow-left  w-auto h-20px"></i>
                  </button>
                </div>
                <JobDetails
                  selectedJob={
                    JobsListData?.filter(
                      (x, index) => index === Searchvalue
                    )?.[0]
                  }
                  isLoading={isLoading}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
