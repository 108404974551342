import React, { useEffect, useState } from "react";
import { Card, Button, Form, Spinner } from "react-bootstrap";
import { Trans } from "react-i18next";
import { Divider, DatePicker, SelectPicker } from "rsuite";
import { useForm, Controller } from "react-hook-form";
import { DateTime } from "luxon";
import axios from "axios";
import { useSearchParams } from "react-router-dom";
import usePost from "../../customhooks/UsePost";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

function DirectRescheduleSlot() {
    const {
        register,
        control,
        handleSubmit,
        setValue,
        watch
    } = useForm({
        defaultValues: {
            start: null,
            duration: null,
            timeZone: null
        }
    });
    let [searchParams] = useSearchParams();
    const durationData = [30, 60, 90, 120].map((item) => ({ label: `${item} minutes`, value: item }));
    const [interviewID, setInterviewID] = useState(null);
    const [companyID, setCompanyID] = useState(null);
    const [roundNumber, setRoundNumber] = useState(null);
    const [isRescheduled, setIsRescheduled] = useState(false);
    const [timeZone, setTimeZone] = useState("Asia/Kolkata");
    const [collectionName, setCollectionName] = useState(null)
    const [timeZoneData, setTimeZoneData] = useState([]);
    const [campaignID, setCampaignID] = useState(null);

    useEffect(() => {
        setValue("timeZone", timeZone);
        setValue("duration", 30)
    }, [timeZone])

    useEffect(() => {
        if (searchParams.get("iid") !== null) {
            setInterviewID(searchParams.get("iid"))
        }
        if (searchParams.get("companyid") !== null) {
            setCompanyID(searchParams.get("companyid"))
        }
        if (searchParams.get("round") !== null) {
            setRoundNumber(searchParams.get("round"))
        }
    }, [searchParams]);

    useEffect(() => {
        const getPreviousSlot = async () => {
            try {
                let getInterview = await axios.get(
                    window.location.origin + "/jsv4/webapi/getInterviewDetails",
                    {
                        params: {
                            interviewID: interviewID,
                            companyID: companyID
                        }
                    }
                )

                if (getInterview?.data?.status && getInterview?.data?.result?.status) {
                    setCampaignID(getInterview?.data?.result?.interview?.campaignID)
                } else {
                    console.log("getInterviewDetails: ", getInterview?.data);
                }
            } catch (error) {
                console.log("error", error);
            }

            try {
                let isInterviewBooked = await axios.get(
                    window?.location?.origin +
                    "/jsv4/webapi/schedulevideo/checkInterviewBookingStatus",
                    {
                        params: {
                            interviewID: interviewID,
                            roundNumber: roundNumber,
                            campaignID: campaignID,
                            companyID: companyID,
                        },
                    }
                );
                console.log("asdfghjklsdfghjkl", isInterviewBooked?.data);

                if (isInterviewBooked?.data?.status) {
                    if (isInterviewBooked?.data?.result?.status) {
                        setTimeZone(isInterviewBooked?.data?.result?.slotDetails?.timeZoneFullName);
                        setCollectionName(isInterviewBooked?.data?.result?.collectionName);
                    }
                }
            } catch (error) {
                console.log("error", error)
            }

        }
        getPreviousSlot();
    }, [interviewID, roundNumber, companyID, campaignID]);

    useEffect(() => {
        const getTimeZoneList = async () => {
            let timezonedata = await axios.get(window.location.origin + "/jsv4/webapi/getTimeZoneList",);
            // console.log("timezonedata", timezonedata?.data);

            timezonedata = timezonedata?.data

            if (timezonedata?.length > 0) {
                let timezonelist = timezonedata.map((zone) => {
                    return {
                        value: zone.timezoneName,
                        label:
                            zone.timezoneName +
                            "(" +
                            zone.timezoneAbbr +
                            zone.timezone +
                            ")",
                    };
                })
                setTimeZoneData(timezonelist);
            }
        }
        getTimeZoneList();
    }, [])

    // usePost
    const {
        post: directRescheduleTimeSlot,
        // data: rescheduleTimeSlotData,
        isLoading: directRescheduleTimeSlotLoading,
        // error: directRescheduleTimeSlotError,
    } = usePost("/schedulevideo/directReScheduleRequestConfirm")

    const onSubmit = async (data) => {
        console.log("dataaaaaaaa", data)

        let obj = {
            startDate: DateTime.fromJSDate(data?.start).toFormat("yyyy/MM/dd HH:mm"),
            Duration: data?.duration,
            timeZone: data?.timeZone,
            interviewID: interviewID,
            roundNumber: roundNumber,
            collectionName: collectionName,
            userID: searchParams.get("userid") != null ? searchParams.get("userid") : "",
            participant: searchParams.get("participant") != null ? searchParams.get("participant") : ""
        }

        console.log("objjjjjjjjjj", obj);

        directRescheduleTimeSlot(obj)
            .then((res) => {
                console.log("response from directRescheduleTimeSlot", res)

                if (res?.status && res?.result?.status) {
                    setIsRescheduled(true)
                }

            })
            .catch((error) => {
                console.log("error in directRescheduleTimeSlot", error);
            })
    }

    const [menuWidth, setMenuWidth] = useState('200px');
    useEffect(() => {
        const handleResize = () => {
            if (typeof window !== 'undefined') {
                const width = window.innerWidth; 
                let calculatedWidth = '200px';
                if (width < 379) {
                    calculatedWidth = '200px'; 
                } else if (width >= 380 && width < 450) {
                    calculatedWidth = '300px'; 
                } else {
                    calculatedWidth = '450px'; 
                }
                setMenuWidth(calculatedWidth);
            }
        };
    
        handleResize(); 
        window.addEventListener('resize', handleResize); 
    
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <>
            {
                isRescheduled ?
                    (
                        <Card className="mx-lg-5">
                            <Card.Body className="p-5">
                                <div className="row">
                                    <FontAwesomeIcon icon={faCheck} className="check-icon text-success" />
                                </div>
                                <div className="row justify-content-center text-success fs-4">
                                    <Trans i18nKey="thankYouForTheConfirmation" defaults="Thank you for the confirmation" />{"!"}
                                </div>
                            </Card.Body>
                        </Card>
                    )
                    :
                    (
                        <Card className="mx-lg-5">
                            <Card.Header className="py-3 fs-5 text-muted">
                                <Trans i18nKey="confirmReschedule" defaults="Confirm Reschedule" />
                            </Card.Header>
                            <Divider className="my-0" />
                            <Card.Body className="p-5">
                                <Form>
                                    <div className="row">
                                        <div className=" col-md-4 mb-3">
                                            <div >
                                                <Form.Label>
                                                    <Trans i18nKey="start" defaults="Start" />
                                                </Form.Label>
                                                <span className="text-danger px-1">{"*"}</span>
                                            </div>
                                            <Controller
                                                control={control}
                                                name="start"
                                                refs={register("start", {
                                                    required: true,
                                                })}
                                                render={({ field: { onChange, value, refs } }) => (
                                                    <DatePicker
                                                        format="yyyy-MM-dd HH:mm"
                                                        // showMeridian
                                                        placeholder="Select Date"
                                                        value={value}
                                                        onChange={onChange}
                                                        placement="bottomStart"
                                                        cleanable={false}
                                                        className="w-100" 
                                                        shouldDisableDate={(date) =>
                                                            date < DateTime.fromJSDate(new Date()).startOf("day")
                                                        }
                                                    />
                                                )}
                                            />
                                        </div>
                                        <div className="col-md-4 mb-3">
                                            <div >
                                                <Form.Label>
                                                    <Trans i18nKey="duration" defaults="Duration" />
                                                </Form.Label>
                                                <span className="text-danger px-1">{"*"}</span>
                                            </div>
                                            <Controller
                                                control={control}
                                                name="duration"
                                                refs={register("duration", {
                                                    required: true,
                                                })}
                                                render={({ field: { onChange, onBlur, value } }) => (
                                                    <SelectPicker
                                                        data={durationData}
                                                        placement="auto"
                                                        className="w-100"
                                                        onChange={onChange}
                                                        cleanable={false}
                                                        defaultValue={30}
                                                        value={value}
                                                        menuStyle={{ width: '200px' }}
                                                    />
                                                )}
                                            />
                                        </div>
                                        <div className=" col-md-4 mb-3">
                                            <div >
                                                <Form.Label>
                                                    <Trans i18nKey="timeZone" defaults="Time Zone" />
                                                </Form.Label>
                                                <span className="text-danger px-1">{"*"}</span>
                                            </div>
                                            <Controller
                                                control={control}
                                                name="timeZone"
                                                refs={register("timeZone", {
                                                    required: true,
                                                })}
                                                render={({ field: { onChange, onBlur, value } }) => (
                                                    <SelectPicker
                                                        data={timeZoneData}
                                                        placement="auto"
                                                        className="w-100"
                                                        onChange={onChange}
                                                        cleanable={false}
                                                        // defaultValue={timeZone}
                                                        value={value}
                                                        menuStyle={{ width: menuWidth }}                                                       
                                                    />
                                                )}
                                            />
                                        </div>
                                    </div>
                                </Form>
                            </Card.Body>
                            <Divider className="my-0" />
                            <div className="py-2 text-center">
                                <Button
                                    variant="primary"
                                    onClick={handleSubmit(onSubmit)}
                                    disabled={watch()?.start == null || watch()?.duration == null || watch()?.timeZone == null || directRescheduleTimeSlotLoading}
                                >
                                    {directRescheduleTimeSlotLoading &&
                                        <Spinner
                                            animation="border"
                                            variant="light"
                                            size="sm"
                                            className="me-1"
                                        />
                                    }
                                    <Trans i18nKey="submit" defaults="Submit" />
                                </Button>
                            </div>
                        </Card>
                    )
            }
        </>
    )
}

export default DirectRescheduleSlot;