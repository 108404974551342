import React from "react";
import { useState, useEffect } from "react";
import { Dropdown, Button, Form, FormControl } from "react-bootstrap";
import "../SearchBar.css";
import { Trans, useTranslation } from "react-i18next";

function SmallScreenWorktypeAttributes({
  workTypeAttribute,
  getJobs,
  setJobsListData,
  setPage,
  setTotal,
  selectedOptions,
  setSelectedOptions,
  setSearchvalue,
  SearchBodyData,
  isLoading,
  setAttributeData,
  setworktypetags,
  setShowJobDetails,
  workTypeSelectedList,
  setworkTypeSelectedList,
  worktypesets,
  setworktypesets,
}) {
  const [showDropdown, setShowDropdown] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const { t } = useTranslation();
  // const [worktypesets, setworktypesets] = useState([]);
  // useEffect(() => {
  //   if (showDropdown) {
  //     setworktypesets(selectedOptions);
  //   }
  // }, [showDropdown]);
  const handleDropdownSelect = (option) => {
    if (worktypesets.includes(option)) {
      setworktypesets(worktypesets.filter((item) => item !== option));
      setworkTypeSelectedList(worktypesets.filter((item) => item !== option));
    } else {
      setworktypesets([...worktypesets, option]);
      setworkTypeSelectedList([...worktypesets, option]);
    }
  };

  // console.log(workTypeAttribute,"workTypeAttributeworkTypeAttribute")
  const filteredOptions = workTypeAttribute
    ?.map((option) => {
      const formattedKey = option?.key
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
      return { ...option, formattedKey };
    })
    ?.filter((option) =>
      option?.formattedKey.toLowerCase().includes(searchQuery.toLowerCase())
    );

  return (
    <>
      <Dropdown
        show={showDropdown}
        onToggle={(isOpen) => {
          setShowDropdown(isOpen);
          setSearchQuery("");
        }}
        className="position-static"
      >
        <Dropdown.Toggle
          variant="none"
          id="dropdown-basic"
          className="attributeSmall text-left text-muted  rounded-pill w-100  border"
          size="md"
        >
          <Trans i18nKey="workType" defaults="Work type" />
        </Dropdown.Toggle>

        <Dropdown.Menu className="dropdown-menu-custom">
          <FormControl
            type="text"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            placeholder={t("searchWorkType", {
              defaultValue: "Search Work Type",
            })}
            className="mx-2 mb-2 atributesSearch mx-auto"
            // disabled={filteredOptions.length > 0 ? false : true}

          />

          <div style={{ maxHeight: "200px", overflowY: "auto" }}>
            {/* <Form className="dropdown-form">
              <div className="scrollable-options">
                {filteredOptions?.map((option) => (
                  <div key={option?.key} className="ms-3">
                    <Form.Check
                      className="custom-checkbox"
                      type="checkbox"
                      id={option?.key}
                      label={`${option?.formattedKey} (${option?.doc_count})`}
                      checked={worktypesets.includes(option?.key)}
                      onChange={() => handleDropdownSelect(option?.key)}
                    />
                  </div>
                ))}
              </div>
            </Form> */}
             <Form className="dropdown-form">
              <div className="scrollable-options">
                {workTypeAttribute.length > 0 ? (
                  filteredOptions.map((option) => (
                    <div key={option.key} className="ms-3">
                      <Form.Check
                        className="custom-checkbox"
                        type="checkbox"
                        id={option.key}
                        label={`${option.formattedKey} (${option.doc_count})`}
                        checked={worktypesets.includes(option.key)}
                        onChange={() => handleDropdownSelect(option.key)}
                      />
                    </div>
                  ))
                ) : (
                  <div className="text-center p-2">
                    <Trans i18nKey="notFound" defaults="Not Found" />
                  </div>
                )}
              </div>
            </Form>
          </div>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
}

export default SmallScreenWorktypeAttributes;
