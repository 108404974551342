import React, { useState, useEffect, useRef } from "react";
import { Popover, Overlay } from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";
import { InputPicker } from "rsuite";

function ExperienceSelect({ selectedExperience, setSelectedExperience }) {
  const [showPopover, setShowPopover] = useState(false);

  const experienceData = [
    { label: "Less than 1 year", value: "1" },
    { label: "2 years", value: "2" },
    { label: "3 years", value: "3" },
    { label: "4 years", value: "4" },
    { label: "5 years", value: "5" },
    { label: "More than 5 years", value: "6" },
  ];

  const target = useRef(null);
  const popoverRef = useRef(null);

  const handleClickOutside = (event) => {
    if (
      popoverRef.current &&
      !popoverRef.current.contains(event.target) &&
      target.current &&
      !target.current.contains(event.target)
    ) {
      setShowPopover(false);
    }
  };

  useEffect(() => {
    if (showPopover) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showPopover]);

  return (
    <div className="">
      <Overlay
        show={showPopover}
        target={target.current}
        placement="bottom"
        containerPadding={20}
        rootClose={true}
        onHide={() => setShowPopover(false)}
      >
        <Popover id="popover-basic" ref={popoverRef} style={{zIndex: 5}}> 
          <Popover.Body>
            <div className="onPopOver">
            <InputPicker
              data={experienceData}
              className="no-border width align-items-center  "
              placeholder="Select Experience"
              value={selectedExperience}
              onChange={(newValue) => {
                setSelectedExperience(newValue);
                setShowPopover(false);
              }}
            />
            </div>
          </Popover.Body>
        </Popover>
      </Overlay>

      {selectedExperience ? (
        <span
          className="me-3 TitleStyles fontSize"
          ref={target}
          onClick={() => setShowPopover(true)}
        >
          <i>
            {experienceData.find((exp) => exp.value === selectedExperience)
              ?.label || selectedExperience}
          </i>
        </span>
      ) : (
        <span
          className="me-3 anchorLike fontSize fontPlcaeholdr"
          ref={target}
          onClick={() => setShowPopover(true)}
        >
          <Trans i18nKey="SelectExperience" defaults="Select Experience" />
        </span>
      )}
    </div>
  );
}

export default ExperienceSelect;
