import React, { useEffect, useState } from "react";
import { Card, Button, Spinner } from "react-bootstrap";
import { Trans } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { useSearchParams } from "react-router-dom";
import { DateTime } from "luxon";
import usePost from "../../customhooks/UsePost";
import { Notification, useToaster, Loader, Divider } from "rsuite";
import axios from "axios";
import useIsSsr from "../../customhooks/UseIsSSR"


export default function CancelSlot() {
    let [searchParams] = useSearchParams();
    // const { toaster } = useToaster();
    const [iscanceled, setIsCanceled] = useState(false);
    const [data, setData] = useState(null)
    const [interviewID, setInterviewID] = useState(null);
    const [companyID, setCompanyID] = useState(null);
    const [roundNumber, setRoundNumber] = useState(null);
    const [campaignID, setCampaignID] = useState(null);
    const [payload1, setPayload1] = useState(null);
    const [jobTitle, setJobTitle] = useState(null);

    useEffect(() => {
        if (searchParams.get("iid") !== null) {
            setInterviewID(searchParams.get("iid"))
        }
        if (searchParams.get("companyid") !== null) {
            setCompanyID(searchParams.get("companyid"))
        }
        if (searchParams.get("round") !== null) {
            setRoundNumber(searchParams.get("round"))
        }
    }, [searchParams]);

    useEffect(() => {
        if (interviewID && companyID) {
            setPayload1({
                interviewID: interviewID,
                companyID: companyID
            })
        }
    }, [interviewID, companyID]);

    const getCampaignID = async () => {
        try {
            let getInterview = await axios.get(
                window.location.origin + "/jsv4/webapi/getInterviewDetails",
                {
                    params: payload1
                }
            )

            if (getInterview?.data?.status && getInterview?.data?.result?.status) {
                setCampaignID(getInterview?.data?.result?.interview?.campaignID)
            } else {
                console.log("getInterviewDetails: ", getInterview?.data);
            }
        } catch (error) {
            console.log("error", error);
        }
    }

    useEffect(() => {
        if (payload1 !== null) {
            getCampaignID();
        }
    }, [payload1]);

    // useEffect(() => {
    //     if (interviewID && campaignID && roundNumber) {
    //         setPayload2({
    //             interviewID: interviewID,
    //             roundNumber: roundNumber,
    //             campaignID: campaignID,
    //         })
    //     }
    // }), [interviewID, campaignID, roundNumber]

    useEffect(() => {
        const getData = async () => {
            try {
                let slotDetails = await axios.get(
                    window.location.origin +
                    "/jsv4/webapi/schedulevideo/checkInterviewBookingStatus",
                    {
                        params: {
                            interviewID: interviewID,
                            roundNumber: roundNumber,
                            campaignID: campaignID,
                            companyID: companyID,
                        }
                    }
                );
                console.log("datatatata", slotDetails?.data)

                if (slotDetails?.data?.status && slotDetails?.data?.result?.status) {
                    if (slotDetails?.data?.result?.selected) {
                        setData(slotDetails?.data?.result)
                        let campaignDetails = slotDetails?.data?.result?.campaignDetails
                        if (campaignDetails?.displayName && campaignDetails?.displayName == "Job Title") {
                            if (campaignDetails?.job && campaignDetails?.job?.details && campaignDetails?.job?.details?.jobTitle && campaignDetails?.job?.details?.jobTitle?.text && campaignDetails?.job?.details?.jobTitle?.text != "") {
                                setJobTitle(campaignDetails?.job?.details?.jobTitle?.text)
                            }
                        } else {
                            setJobTitle(campaignDetails?.title)
                        }
                    }
                } else {
                    // console.log("eeeeeeeeeee", slotDetails?.data)
                }
            } catch (error) {
                console.log("error", error)
            }
        }
        getData();
    }, [interviewID, campaignID, roundNumber])


    // useEffect(() => {
    //     if (payload2 !== null) {
    //         getData();
    //     }
    // }, [payload2])

    // usePost
    const {
        post: cancelSlot,
        data: cancelSlotData,
        isLoading: cancelSlotLoading,
        error,
    } = usePost("/schedulevideo/cancelScheduleMeetingSlot");
 
    const onSubmit = () => {
        let slot = {
            campaignID: data?.slotDetails?.campaignID,
            interviewID: data?.slotDetails?.interviewID,
            roundNumber: data?.slotDetails?.roundNumber,
            userID: data?.slotDetails?.userID,
            slotID: data?.slotDetails?._id,
            companyID: data?.slotDetails?.companyID,
            participant: searchParams.get("participant") != null ? searchParams.get("participant") : ""
        }

        cancelSlot(slot).then(async (resp) => {
            // console.log("response", resp)

            if (resp?.status && resp?.result?.status) {
                setIsCanceled(true);

                // delete cookie
                try {
                    let deleteCookie = await axios.post(window.location.origin + "/jsv4/webapi/deletecookie",
                        {
                            cookieName: `autoScheduleSlotDetails_${data?.slotDetails?.campaignID}_${data?.slotDetails?.roundNumber}`
                        })

                    console.log("deleteCookie : ", deleteCookie?.data)
                } catch (error) {
                    console.log("Error in setcookie", error);
                }

            }
            // else {
            //     toaster.push(
            //         <Notification type={"error"} closable header={<Trans i18nKey="error" defaults="Error" />}>
            //             <p><Trans i18nKey="pleasetryagainlater" defaults="Please Try Again Later"></Trans>{"!"}</p>
            //         </Notification>,
            //         { placement: "topEnd" }
            //     )
            // }
        }).catch((error) => {
            console.log("Error", error)
        })

    }
    // console.log(data,"dataaaa123")
    return (
        <>
            {
                iscanceled ? (
                    <Card className="mx-lg-5">
                        <Card.Body className="p-5">
                            <div className="row">
                                <FontAwesomeIcon icon={faCheck} className="check-icon text-success" />
                            </div>
                            <div className="row justify-content-center text-success fs-4">
                                <Trans i18nKey="thankYouForTheConfirmation" defaults="Thank you for the confirmation" />{"!"}
                            </div>
                        </Card.Body>
                    </Card>
                ) : (
                    <Card className="mx-lg-5 ">
                        <Card.Header className="py-3">
                            <span className="fs-5 text-muted">
                                <Trans i18nKey="cancelMeeting" defaults="Cancel Meeting" />
                            </span>
                        </Card.Header>
                        <Divider className="mt-0 mb-1" />
                        <Card.Body className="py">
                            <div className="row cancelSlotDetails">
                                <div className="row pb-2">
                                    <div className="col-5 text-start ps-lg-5">
                                        <Trans i18nKey="companyName" defaults="Company Name" />
                                    </div>
                                    <div className="col-2 text-start">
                                        {":"}
                                    </div>
                                    {data && (
                                        <div className="col-5 text-start ps-lg-5">
                                            {data?.companyName}
                                        </div>
                                    )}
                                </div>
                                <div className="row pb-2">
                                    <div className="col-5 text-start ps-lg-5">
                                        <Trans i18nKey="jobTitle" defaults="Job Title" />
                                    </div>
                                    <div className="col-2 text-start">
                                        {":"}
                                    </div>
                                    {data && (
                                        <div className="col-5 text-start ps-lg-5">
                                            {jobTitle}
                                        </div>
                                    )}
                                </div>
                                <div className="row pb-2">
                                    <div className="col-5 text-start ps-lg-5">
                                        <Trans i18nKey="recruiterName" defaults="Recruiter Name" />
                                    </div>
                                    <div className="col-2 text-start">
                                        {":"}
                                    </div>
                                    {data && (
                                        <div className="col-5 text-start ps-lg-5">
                                            {data?.slotDetails?.participants?.find((x) => x?.host === true)?.name}
                                        </div>
                                    )}
                                </div>
                                <div className="row pb-2">
                                    <div className="col-5 text-start ps-lg-5">
                                        <Trans i18nKey="jobSeekerName" defaults="Job Seeker Name" />
                                    </div>
                                    <div className="col-2 text-start">
                                        {":"}
                                    </div>
                                    {data && (
                                        <div className="col-5 text-start ps-lg-5">
                                            {/* {data?.interviewDetails?.profile?.emails[0]?.email} */}
                                            {data?.interviewDetails?.profile?.fullName ?  data?.interviewDetails?.profile?.fullName  
                                            : data?.interviewDetails?.profile?.emails[0]?.email}
                                        </div>
                                    )}
                                </div>
                                <div className="row pb-3">
                                    <div className="col-5 text-start ps-lg-5">
                                        <Trans i18nKey="dateAndTime" defaults="Date and Time" />
                                    </div>
                                    <div className="col-2 text-start">
                                        {":"}
                                    </div>
                                    {data && (
                                        <div className="col-5 text-start ps-lg-5">
                                            {DateTime.fromISO(data?.slotDetails?.startDate).setZone(data?.slotDetails?.timeZoneFullName).toFormat("EEEE, MMMM dd, yyyy")}{" "}
                                            {DateTime.fromISO(data?.slotDetails?.startDate).setZone(data?.slotDetails?.timeZoneFullName).toFormat(`${data?.defaultTimeFormat == "12 hours" ? "hh:mm a" : "HH:mm"}`)}
                                            {" "}{"("}{data?.slotDetails?.timeZoneFullName}{")"}
                                        </div>
                                    )}
                                </div>
                                <div className="row">
                                    <div className="col-lg-11 col-xs-12 text-center fs-6">
                                        <Trans i18nKey="areYouSureYouWantToCancelTheMeeting" defaults="Are you sure you want to cancel the meeting" />{"?"}
                                    </div>
                                </div>
                            </div>
                        </Card.Body>
                        <Divider className="mb-2 mt-0" />
                        <div className="row pb-2">
                            <div className="col-11 text-center">
                                <Button
                                    variant="primary"
                                    // size="sm"
                                    onClick={onSubmit}
                                    disabled={cancelSlotLoading}
                                >
                                    {cancelSlotLoading &&
                                        <Spinner
                                            animation="border"
                                            variant="light"
                                            size="sm"
                                            className="me-1"
                                        />
                                    }
                                    <Trans i18nKey="confirm" defaults="Confirm" />
                                </Button>
                            </div>
                        </div>
                    </Card>
                )
            }
        </>
    )
}