import React, { useEffect, useState,useContext } from 'react';
import { PropTypes } from "prop-types"
import { useForm } from 'react-hook-form';
import Loading from '../../../../Loder';
import { Trans, useTranslation } from "react-i18next";
import usePost from '../../../../customhooks/UsePost';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faCopy } from '@fortawesome/free-solid-svg-icons';
import EmailForm from './EmailForm';
import UserContext from '../../../../app/context/UserContext';

const EmailEditSessionForm = ({ triggerRef, resumeId,companyID,clientID, email, setHideEmailDetailsView, setHidePhoneDetailsView, getData,triggerContactDetailsRef,setUpdateResumeDataData }) => {
    const {
        control,
        register,
        setValue,
        handleSubmit,
        setError,
        formState: { errors },
        getValues,
        clearErrors
    } = useForm();
    const { t } = useTranslation();

    const {
        post: updateResumeDetails,
        isLoading: UpdateResumeDataDataLoading,
      } = usePost("/user/Updateresumedeatails");
    const { user, setUserDetails } = useContext(UserContext);
    const saveEmail = (data) => {
        let emailFlag = true
        for (let i = 0; i < data?.emails?.length; i++) {
            for (let j = i + 1; j < data?.emails?.length; j++) {
                if (data?.emails[i]?.email == data?.emails[j].email) {
                    emailFlag = false
                    setError("emails[" + j + "].email",
                        {
                            type: "manual",
                            message: t("emailAlreadyExists",{defaultValue:"Email already exists"}),
                        }
                    );
                }

            }
        }
        if (emailFlag) {
            
            updateResumeDetails({
                target: "email",
                resumeID: resumeId,
                companyID:companyID,
                clientID:clientID,
                user:user,
                getValues: {"emails" : data?.emails},
                type:"Profile Edited"
            }).then((res) => {
                if (res) {
                    setUpdateResumeDataData(res)
                    triggerContactDetailsRef.current.close()
                }
              })
              .catch((error) => {
                console.error("Error while fetching Resume:", error);
              });
        }

    };
    const handleGoBackToContactDetails = () => {
        setHideEmailDetailsView(true);
        setHidePhoneDetailsView(true);
    }

    return (
        <>
            <div className='row'>
                <div className=' d-flex align-items-center col-md-12'>
                    <span className="IconArea actionIcon actionIcon-sm" title="Click here to go back to contact Details" onClick={handleGoBackToContactDetails} >
                        <FontAwesomeIcon
                            className="mx-1 cursor-pointer text-primary"
                            icon={faArrowLeft}
                        />
                    </span>
                    <h6 className='m-0 p-0 text-dark'><Trans i18nKey="email" defaults="Email" /></h6>
                </div>
            </div>

            <div className="emailFormBody mt-2">

                <React.Suspense
                    fallback={<Loading rows={0} />}
                >
                    <EmailForm
                        emails={email}
                        register={register}
                        setValue={setValue}
                        control={control}
                        errors={errors}
                        handleSubmit={handleSubmit}
                        getValues={getValues}
                        setError={setError}
                        clearErrors={clearErrors}
                    />
                </React.Suspense>

            </div>
            <div className="row my-2">
                <div className="col-md-12 d-flex justify-content-end">
                    {/* <span>
                        <button className="btn btn-sm btn-light" onClick={close}>
                            Cancel
                        </button>
                    </span> */}
                    <span>

                        <button
                            className="btn btn-sm btn-primary"
                            disabled={UpdateResumeDataDataLoading}
                            onClick={handleSubmit(saveEmail)}
                        >
                            {UpdateResumeDataDataLoading ? (
                                <span
                                    className="spinner-border spinner-border-sm "
                                    role="status"
                                    aria-hidden="true"
                                ></span>
                            ) : null}
                            <span className='px-1'>
                                <Trans
                                    i18nKey="save"
                                    defaults="Save"
                                />
                            </span>
                        </button>
                    </span>
                </div>
            </div>
        </>
    );
};

export default EmailEditSessionForm;