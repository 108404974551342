import React, { useEffect, useState, useRef } from "react";
import { useFieldArray, Controller } from "react-hook-form";
import { Form } from "react-bootstrap";
import { Radio, RadioGroup, SelectPicker } from "rsuite";
import { Trans } from "react-i18next";
import { DateTime } from "luxon";
import { faPlusCircle, faMinusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import UserDateInput from "../../../Date/UserDateInput";
import LocationField from "../fields/LocationField";
import changingDateFormat from "../../../../../api/services/changingDateFormat";
import { t } from "i18next";
import DegreeForm from "../../../profile/form/DegreeForm";
import MinorFrom from "../../../profile/form/Minor";

const EducationModal = ({
  register,
  control,
  setValue,
  errors,
  getValues,
  target,
  educationList,
  educationRowsList,
  clearErrors,
  setError,
  handleSubmit
}) => {
  const {
    fields: educationFields,
    append: educationAppend,
    remove: educationRemoved,
    replace: educationReplace,
  } = useFieldArray({
    control,
    name: "education",
  });
  const [educationStatus, setEducationStatus] = useState([]);

  let educationStatusValues = 
  [
    "Studying",
    "Passed",
    "Unknown",
    "Failed"
]
  useEffect(()=>{
    if(educationStatusValues){
      setEducationStatus(educationStatusValues?.map((x) => {
        return {
          label: x,
          value: x
        }
    
      }))
    }
  },[])

  const inputRef = useRef([]);
  const [dateClear, setDateClear] = useState(true);
  useEffect(() => {
    setDateClear(false);
  }, [dateClear]);
  useEffect(() => {
    if (educationList && educationList.length > 0) {
      educationReplace();
      if (target == "createProfile") {
        educationAppend(
          educationList.map((x) => {
            return {
              campus: x?.campus,
              university: x?.university,
              degree: x?.degree,
              major: x?.major.length > 0 ? x?.major?.map((item) => item) : [],
              minor: x?.minor.length > 0 ? x?.minor?.map((item) => item) : [],
              meritGPA: x?.meritGPA,
              // meritPercentage: x?.merit?.percentage,
              meritType: x?.meritType,
              status: x?.status,
              location: x?.location,
              startDate: x?.startDate,

              endDate: x?.endDate,
            };
          })
        );
      } else {
        educationAppend(
          educationList.map((x) => {
            return {
              campus: x.campus.text,
              university: x.university?.text,
              degree: x.degree?.text,
              major:
                x?.major.length > 0 ? x?.major?.map((item) => item.text) : [],
              minor:
                x?.minor.length > 0 ? x?.minor?.map((item) => item.text) : [],
              meritGPA:
                x?.merit?.GPA !== 0 && x?.merit?.GPA !== ""
                  ? x.merit?.GPA
                  : x.merit?.percentage,
              // meritPercentage: x?.merit?.percentage,
              meritType:
                x?.merit?.GPA !== 0 && x?.merit?.GPA !== ""
                  ? "GPA"
                  : "percentage",
              status: x?.currentStatus,
              location: x?.location.text,
              startDate:
                x?.startDate?.standard != ""
                  ? DateTime.fromISO(
                      changingDateFormat(x?.startDate.standard),
                      "yyyy-MM-dd"
                    ).toJSDate()
                  : "",
              endDate:
                x?.endDate.standard && x?.endDate.text != "Present"
                  ? DateTime.fromISO(
                      changingDateFormat(x?.endDate.standard),
                      "yyyy-MM-dd"
                    ).toJSDate()
                  : "",
            };
          })
        );
      }
    } else {
      educationReplace();
      educationAppend([
        {
          campus: "",
          university: "",
          degree: "",
          major: [],
          minor: [],
          meritGPA: "",
          // meritPercentage: "",
          meritType: "GPA",
          status: "",
          location: "",
          startDate: "",
          endDate: "",
        },
      ]);
    }
  }, []);
  const handleAddressChange = (place, index) => {
    let location = {
      city: "",
      country: "",
      pin: { lat: 0, lon: 0 },
      state: "",
      zipcode: "",
      text: "",
    };
    let address1 = "";

    if (place) {
      if (place?.address_components.length > 0) {
        for (let i in place?.address_components) {
          if (
            place?.address_components[i].types.indexOf("street_number") != -1
          ) {
            address1 += place.address_components[i].long_name + ",";
          }
          if (place.address_components[i].types.indexOf("route") != -1) {
            address1 += place.address_components[i].long_name + ",";
          }
          if (place.address_components[i].types.indexOf("neighborhood") != -1) {
            address1 += place.address_components[i].long_name + ",";
          }
          if (
            place.address_components[i].types.indexOf("sublocality_level_3") !=
            -1
          ) {
            address1 += place.address_components[i].long_name + ",";
          }
          if (
            place.address_components[i].types.indexOf("sublocality_level_2") !=
            -1
          ) {
            address1 += place.address_components[i].long_name + ",";
          }
          if (
            place.address_components[i].types.indexOf("sublocality_level_1") !=
            -1
          ) {
            address1 += place.address_components[i].long_name + ",";
          }
          if (place.address_components[i].types.indexOf("postal_code") != -1) {
            location.zipcode += place.address_components[i].long_name;
          }
          if (
            place.address_components[i].types.indexOf("postal_code_suffix") !=
            -1
          ) {
            location.zipcode += place.address_components[i].long_name;
          }
          if (place.address_components[i].types.indexOf("locality") != -1) {
            location.city += place.address_components[i].long_name;
          }
          if (
            place.address_components[i].types.indexOf(
              "administrative_area_level_1"
            ) != -1
          ) {
            location.state = place.address_components[i].long_name;
          }
          if (place.address_components[i].types.indexOf("country") != -1) {
            location.country = place.address_components[i].long_name;
          }
        }
      }
    }

    if (address1) {
      location.text += address1;
    }
    if (location.city != "") {
      location.text += location.city + ",";
    }
    if (location.state != "") {
      location.text += location.state;
    }
    if (location.zipcode != "") {
      location.text += " " + location.zipcode + ",";
    } else {
      location.text += ",";
    }
    if (location.country != "") {
      location.text += location.country;
    }
    location.pin.lat = place.geometry.location.lat();
    location.pin.lon = place.geometry.location.lng();
    // console.log(address1, location, "locatione52");
    setValue(`education[${index}].location`, location.text);
    setValue(`education[${index}].locationObJ`, location);
  };
  function handleMeritChange() {
    for (let i in educationFields) {
      let flag = true;
      if (getValues()?.education[i]?.meritType == "percentage") {
        if (getValues("education[" + i + "].meritGPA") > 100) {
          flag = false;
          setError("education[" + i + "].meritGPA", {
            type: "manual",
            message: "Percentage should be less than or equal to 100",
          });
        }
      }
      if (getValues()?.education[i]?.meritType == "GPA") {
        if (getValues("education[" + i + "].meritGPA") > 10) {
          flag = false;
          setError("education[" + i + "].meritGPA", {
            type: "manual",
            message: "GPA value should be less than or equal to 10",
          });
        }
      }
      if (flag) {
        clearErrors("education[" + i + "].meritGPA");
      }
    }
  }

  const handleAddEducation = () => {
    educationAppend({
      campus: "",
      university: "",
      degree: "",
      major: [],
      minor: [],
      meritGPA: "",
      // meritPercentage: "",
      meritType: "GPA",
      status: "",
      location: "",
      startDate: "",
      endDate: "",
    });
  };

  function DurationValidation() {
    for (let i in educationFields) {
      let flag = true;
      if (
        getValues("education[" + i + "].startDate") != "" &&
        getValues("education[" + i + "].endDate") != ""
      ) {
        if (
          DateTime.fromJSDate(
            getValues("education[" + i + "].startDate")
          ).toMillis() >=
          DateTime.fromJSDate(
            getValues("education[" + i + "].endDate")
          ).toMillis()
        ) {
          flag = false;
          setError("education[" + i + "].endDate", {
            type: "manual",
            message: "End date should be greater than start date",
          });
        }
      }
      if (flag) {
        clearErrors([
          "education[" + i + "].startDate",
          "education[" + i + "].endDate",
        ]);
      }
    }
  }

  return (
    <>
      {educationFields?.length > 0 &&
        educationFields.map((itemData, index1) => {
          return (
            <div className="row py-2" key={itemData?.id}>
              <div className="col-sm-12 col-md-12">
                <div className="card">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-sm-12 justify-content-end d-flex">
                        <span
                          className="px-2 IconArea actionIcon actionIcon-sm"
                          onClick={() => {
                            educationRemoved(index1);
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faMinusCircle}
                          ></FontAwesomeIcon>
                        </span>
                      </div>
                    </div>
                    {educationRowsList?.length > 0 &&
                      educationRowsList.map((item, index) => {
                        let colNumber = 12;
                        if (item.columns.length > 0) {
                          colNumber = 12 / item.columns.length;
                        }
                        return (
                          <div className="row py-2" key={index}>
                            {item?.columns?.length > 0 &&
                              item?.columns.map((column, index) => {
                                return (
                                  <div
                                    className={"col-sm-" + colNumber}
                                    key={column?.fieldCode}
                                  >
                                    {column?.fieldCode == "Campus" ? (
                                      <>
                                        <div>
                                          <label>
                                          {t(column?.i18nKey, { defaultValue: column?.fieldName })}
                                          </label>
                                          {column?.required && (
                                            <span className="text-danger">
                                              {"*"}
                                            </span>
                                          )}
                                          <Controller
                                            control={control}
                                            name={`education[${index1}].campus`}
                                            refs={register(
                                              `education[${index1}].campus`,
                                              {
                                                required: column?.required
                                                  ? true
                                                  : false,
                                              }
                                            )}
                                            render={({
                                              field: {
                                                onChange,
                                                onBlur,
                                                value,
                                                refs,
                                              },
                                            }) => (
                                              <Form.Control
                                                size="sm"
                                                className="w-100"
                                                placeholder={t("enter", { defaultValue: "Enter" }) + " " + t(column?.i18nKey, { defaultValue: column?.fieldName || "Campus" })}
                                                disabled={
                                                  column?.editable
                                                    ? false
                                                    : true
                                                }
                                                refs={refs}
                                                value={value}
                                                onChange={onChange}
                                              />
                                            )}
                                          />
                                          {errors?.["education"]?.[index1]
                                            ?.campus && (
                                            <span className="text-danger mt-3">
                                              {errors?.["education"]?.[index1]
                                                ?.campus?.message != "" ? (
                                                errors?.["education"]?.[index1]
                                                  ?.campus?.message
                                              ) : (
                                                <Trans
                                                  i18nKey="campusIsRequired"
                                                  defaults="Campus is required"
                                                />
                                              )}{" "}
                                            </span>
                                          )}
                                        </div>
                                      </>
                                    ) : null}
                                    {column?.fieldCode == "University" ? (
                                      <>
                                        <div>
                                          <label>
                                          {t(column?.i18nKey, { defaultValue: column?.fieldName })}
                                          </label>
                                          {column?.required && (
                                            <span className="text-danger">
                                              {"*"}
                                            </span>
                                          )}
                                          <Controller
                                            control={control}
                                            name={`education[${index1}].university`}
                                            refs={register(
                                              `education[${index1}].university`,
                                              {
                                                required: column?.required
                                                  ? true
                                                  : false,
                                              }
                                            )}
                                            render={({
                                              field: {
                                                onChange,
                                                onBlur,
                                                value,
                                                refs,
                                              },
                                            }) => (
                                              <Form.Control
                                                size="sm"
                                                // defaultValue={
                                                //     itemData?.university
                                                // }
                                                refs={refs}
                                                className="w-100"
                                                placeholder={t("enter", { defaultValue: "Enter" }) + " " + t(column?.i18nKey, { defaultValue: column?.fieldName || "University" })}
                                                disabled={
                                                  column?.editable
                                                    ? false
                                                    : true
                                                }
                                                value={value}
                                                onChange={onChange}
                                              />
                                            )}
                                          />
                                          {errors?.["education"]?.[index1]
                                            ?.university && (
                                            <span className="text-danger mt-3">
                                              {errors?.["education"]?.[index1]
                                                ?.university?.message != "" ? (
                                                errors?.["education"]?.[index1]
                                                  ?.university?.message
                                              ) : (
                                                <Trans
                                                  i18nKey="universityIsRequired"
                                                  defaults="University is required"
                                                />
                                              )}{" "}
                                            </span>
                                          )}
                                        </div>
                                      </>
                                    ) : null}
                                    {column?.fieldCode == "Edu Status" ? (
                                      <>
                                        <div className="form-group">
                                          <label>
                                          {t(column?.i18nKey, { defaultValue: column?.fieldName })}
                                          </label>
                                          {column?.required && (
                                            <span className="text-danger">
                                              {"*"}
                                            </span>
                                          )}
                                          <Controller
                                            control={control}
                                            name={`education[${index1}].status`}
                                            refs={register(
                                              `education[${index1}].status`,
                                              {
                                                required: column?.required
                                                  ? true
                                                  : false,
                                              }
                                            )}
                                            render={({
                                              field: {
                                                onChange,
                                                onBlur,
                                                value,
                                                refs,
                                              },
                                            }) => (
                                              <SelectPicker
                                                className="w-100"
                                                data={educationStatus}
                                                placeholder={t("select", { defaultValue: "Select" }) + " " + t(column?.i18nKey, { defaultValue: column?.fieldName || "Status" })}
                                                cleanable={false}
                                                onChange={onChange}
                                                value={value}
                                                disabled={
                                                  column?.editable
                                                    ? false
                                                    : true
                                                }
                                                placement="auto"
                                                preventOverflow={true}
                                              />
                                            )}
                                          />
                                          {errors?.["education"]?.[index1]
                                            ?.status && (
                                            <span className="text-danger mt-3">
                                              {errors?.["education"]?.[index1]
                                                ?.status?.message != "" ? (
                                                errors?.["education"]?.[index1]
                                                  ?.status?.message
                                              ) : (
                                                <Trans
                                                  i18nKey="educationStatusIsRequired"
                                                  defaults="Education Status is required"
                                                />
                                              )}{" "}
                                            </span>
                                          )}
                                        </div>
                                      </>
                                    ) : null}

                                    {column?.fieldCode == "Degree" ? (
                                      <>
                                        <DegreeForm
                                          index={index1}
                                          control={control}
                                          register={register}
                                          setValue={setValue}
                                          getValues={getValues}
                                          errors={errors}
                                          itemData={itemData}
                                          registerName={`education[${index1}].degree`}
                                          column={column}
                                        />
                                        {/* <div>
                                                <label>
                                                  {t(column?.fieldName, { defaultValue: column?.fieldName })}
                                                </label>
                                                <Controller
                                                  control={control}
                                                  name={`education[${index1}].degree`}
                                                  refs={register(
                                                    `education[${index1}].degree`,
                                                    {
                                                      required: false,
                                                    }
                                                  )}
                                                  render={({
                                                    field: {
                                                      onChange,
                                                      onBlur,
                                                      value,
                                                      refs,
                                                    },
                                                  }) => (
                                                    <AutoComplete
                                                      size="sm"
                                                      className="w-100"
                                                      refs={refs}
                                                      placeholder={t("enter", { defaultValue: "Enter" }) + " " + t(column?.fieldName, { defaultValue: column?.fieldName || "Degree" })}
                                                      onChange={(value) => {
                                                        esDataArr({ "context": "degree", "text": value });
                                                        onChange(value);
                                                      }}
                                                      data={
                                                        esData?.data.map((jobTitle) => jobTitle.text)}
                                                      value={value}

                                                    />

                                                  )}
                                                />
                                              </div> */}
                                      </>
                                    ) : null}
                                    {column?.fieldCode == "Major" ? (
                                      <>
                                        <div>
                                          <MinorFrom
                                            index={index1}
                                            control={control}
                                            register={register}
                                            setValue={setValue}
                                            getValues={getValues}
                                            errors={errors}
                                            itemData={itemData}
                                            registerName={`education[${index1}].major`}
                                            placeholderName={"major"}
                                            column={column}
                                            labelName={"major"}
                                          />
                                        </div>
                                        {/* <div >
                                                <label>
                                                  {t(column?.fieldName, { defaultValue: column?.fieldName })}
                                                </label>
                                                <Controller
                                                  control={control}
                                                  name={`education[${index1}].major`}
                                                  refs={register(
                                                    `education[${index1}].major`,
                                                    {
                                                      required: false,
                                                    }
                                                  )}
                                                  render={({
                                                    field: {
                                                      onChange,
                                                      onBlur,
                                                      value,
                                                      refs,
                                                    },
                                                  }) => (
                                                    <TagInput
                                                      size="sm"
                                                      className="w-100"
                                                      refs={refs}
                                                      value={value}
                                                      onChange={onChange}
                                                    />
                                                  )}
                                                />
                                              </div> */}
                                      </>
                                    ) : null}
                                    {column?.fieldCode == "Minor" ? (
                                      <>
                                        <div>
                                          <MinorFrom
                                            index={index1}
                                            control={control}
                                            register={register}
                                            setValue={setValue}
                                            getValues={getValues}
                                            errors={errors}
                                            itemData={itemData}
                                            registerName={`education[${index1}].minor`}
                                            placeholderName={"minor"}
                                            column={column}
                                            labelName={"minor"}
                                          />
                                        </div>
                                        {/* <div>
                                                <label>
                                                  {t(column?.fieldName, { defaultValue: column?.fieldName })}
                                                </label>
                                                <Controller
                                                  control={control}
                                                  name={`education[${index1}].minor`}
                                                  refs={register(
                                                    `education[${index1}].minor`,
                                                    {
                                                      required: false,
                                                    }
                                                  )}
                                                  render={({
                                                    field: {
                                                      onChange,
                                                      onBlur,
                                                      value,
                                                      refs,
                                                    },
                                                  }) => (
                                                    <TagInput
                                                      size="sm"
                                                      className="w-100"
                                                      value={value}
                                                      placeholder={t("enter", { defaultValue: "Enter" }) + " " + t(column?.fieldName, { defaultValue: column?.fieldName || "Minor" })}
                                                      refs={refs}
                                                      onChange={onChange}
                                                    />
                                                  )}
                                                />
                                              </div> */}
                                      </>
                                    ) : null}
                                    {column?.fieldCode == "Merit" ? (
                                      <>
                                        <div className="row">
                                          <div className="col-sm-8">
                                            <div className="form-group">
                                              <label>
                                              {t(column?.i18nKey, { defaultValue: column?.fieldName })}
                                              </label>
                                              {column?.required && (
                                                <span className="text-danger">
                                                  {"*"}
                                                </span>
                                              )}
                                              <Controller
                                                control={control}
                                                name={`education[${index1}].meritGPA`}
                                                refs={register(
                                                  `education[${index1}].meritGPA`
                                                )}
                                                rules={{
                                                  required:
                                                  column?.required || getValues()?.education?.[
                                                      index1
                                                    ]?.meritGPA != ""
                                                      ? true
                                                      : false,
                                                  validate: {
                                                    pattern: (v) => {
                                                      if (
                                                        v &&
                                                        String(v).match(
                                                          "^[0-9]\\d*"
                                                        ) === null
                                                      ) {
                                                        return t(
                                                          "valueShouldBePositive",
                                                          {
                                                            defaultValue:
                                                              "Value should be positive",
                                                          }
                                                        );
                                                      }
                                                      return true;
                                                    },
                                                    typeError: (v) => {
                                                      if (
                                                        getValues()
                                                          ?.education?.[index1]
                                                          ?.meritType === "GPA"
                                                      ) {
                                                        if (v > 10) {
                                                          return t(
                                                            "gpaValueError",
                                                            {
                                                              defaultValue:
                                                                "GPA value should be less than or equal to 10",
                                                            }
                                                          );
                                                        }
                                                      }
                                                    },
                                                    limitError: (v) => {
                                                      if (
                                                        getValues()
                                                          ?.education?.[index1]
                                                          ?.meritType ===
                                                        "percentage"
                                                      ) {
                                                        if (v > 100) {
                                                          return t(
                                                            "percentageValueError",
                                                            {
                                                              defaultValue:
                                                                "Percentage should be less than or equal to 100",
                                                            }
                                                          );
                                                        }
                                                      }
                                                    },
                                                  
                                                  },
                                                }}
                                                render={({
                                                  field: {
                                                    onChange,
                                                    onBlur,
                                                    value,
                                                    refs,
                                                  },
                                                }) => (
                                                  <Form.Control
                                                    type="number"
                                                    size="sm"
                                                    placeholder={t("enter", { defaultValue: "Enter" }) + " " + t(column?.i18nKey, { defaultValue: column?.fieldName || "Merit" })}
                                                    disabled={
                                                      column?.editable
                                                        ? false
                                                        : true
                                                    }
                                                    refs={refs}
                                                    value={value}
                                                    onChange={(value) => {
                                                      onChange(value);
                                                      handleMeritChange();
                                                    }}
                                                  />
                                                )}
                                              />
                                            </div>
                                          </div>
                                          <div className="col-sm-4 d-flex align-items-end">
                                            <Controller
                                              control={control}
                                              name={`education[${index1}].meritType`}
                                              refs={register(
                                                `education[${index1}].meritType`
                                              )}
                                              render={({
                                                field: {
                                                  onChange,
                                                  onBlur,
                                                  value,
                                                },
                                              }) => (
                                                <RadioGroup
                                                  name="radioList"
                                                  value={value}
                                                  onChange={(value) => {
                                                    onChange(value);
                                                    handleMeritChange();
                                                  }}
                                                  inline
                                                >
                                                  <Radio
                                                    value="GPA"
                                                    disabled={
                                                      column?.editable
                                                        ? false
                                                        : true
                                                    }
                                                  >
                                                    <Trans
                                                      i18nKey="gpa"
                                                      defaults="GPA"
                                                    />
                                                  </Radio>
                                                  <Radio
                                                    value="percentage"
                                                    disabled={
                                                      column?.editable
                                                        ? false
                                                        : true
                                                    }
                                                  >
                                                    {"%"}
                                                  </Radio>
                                                </RadioGroup>
                                              )}
                                            />
                                          </div>
                                        </div>
                                        {errors?.["education"]?.[index1]?.[
                                          "meritGPA"
                                        ] && errors?.["education"]?.[index1]?.[
                                          "meritGPA"
                                        ]?.type == "required" && (
                                          <span className="text-danger mt-3">
                                            {errors?.["education"]?.[index1]?.[
                                          "meritGPA"
                                        ]?.message !=
                                            "" ? (
                                              errors?.["education"]?.[index1]?.[
                                                "meritGPA"
                                              ]?.message
                                            ) : (
                                              <Trans
                                                i18nKey="meritGPAIsRequired"
                                                defaults="Merit GPA is required"
                                              />
                                            )}{" "}
                                          </span>
                                        )}
                                        {errors?.["education"]?.[index1]?.[
                                          "meritGPA"
                                        ] &&
                                          (errors?.["education"]?.[index1]?.[
                                            "meritGPA"
                                          ]?.type == "pattern" ||
                                            errors?.["education"]?.[index1]?.[
                                              "meritGPA"
                                            ]?.type == "typeError" ||
                                            errors?.["education"]?.[index1]?.[
                                              "meritGPA"
                                            ]?.type == "limitError" ||
                                            errors?.["education"]?.[index1]?.[
                                              "meritGPA"
                                            ]?.type == "manual") && (
                                            <span className="text-danger mt-3">
                                              {
                                                errors?.["education"]?.[
                                                  index1
                                                ]?.["meritGPA"]?.message
                                              }
                                            </span>
                                          )}
                                      </>
                                    ) : null}
                                    {column?.fieldCode == "Edu Start Date" ? (
                                      <>
                                        <div className="form-group">
                                          <label>
                                            {t(column?.i18nKey, {
                                              defaultValue: column?.fieldName,
                                            })}
                                          </label>
                                          {column?.required && (
                                            <span className="text-danger">
                                              {"*"}
                                            </span>
                                          )}
                                          <div>
                                            <Controller
                                              control={control}
                                              name={`education[${index1}].startDate`}
                                              refs={register(
                                                `education[${index1}].startDate`,
                                                {
                                                  required: column?.required
                                                    ? true
                                                    : false,
                                                }
                                              )}
                                              rules={{
                                                required:
                                                  getValues()?.education?.[
                                                    index1
                                                  ]?.startDate != ""
                                                    ? true
                                                    : false,
                                              }}
                                              render={({
                                                field: {
                                                  onChange,
                                                  onBlur,
                                                  value,
                                                  refs,
                                                },
                                              }) =>
                                                (itemData?.startDate &&
                                                  itemData?.startDate != "") ||
                                                getValues()?.education[index1]
                                                  ?.startDate != "" ? (
                                                  <UserDateInput
                                                    defaultValue={
                                                      value
                                                        ? value
                                                        : getValues()
                                                            ?.education[index1]
                                                            ?.startDate
                                                    }
                                                    refs={refs}
                                                    key={dateClear}
                                                    setDateClear={setDateClear}
                                                    disabled={
                                                      column?.editable
                                                        ? false
                                                        : true
                                                    }
                                                    onChange={(value) => {
                                                      onChange(value);
                                                      DurationValidation();
                                                    }}
                                                    placement="auto"
                                                  />
                                                ) : (
                                                  <UserDateInput
                                                    //   defaultValue=""
                                                    refs={refs}
                                                    key={dateClear}
                                                    setDateClear={setDateClear}
                                                    disabled={
                                                      column?.editable
                                                        ? false
                                                        : true
                                                    }
                                                    onChange={(value) => {
                                                      onChange(value);
                                                      DurationValidation();
                                                    }}
                                                    placement="auto"
                                                  />
                                                )
                                              }
                                            />
                                            {errors?.["education"]?.[index1]?.[
                                          "startDate"
                                        ] && errors?.["education"]?.[index1]?.[
                                          "startDate"
                                        ]?.type == "required" && (
                                          <span className="text-danger mt-3">
                                            {errors?.["education"]?.[index1]?.[
                                          "startDate"
                                        ]?.message !=
                                            "" ? (
                                              errors?.["education"]?.[index1]?.[
                                                "startDate"
                                              ]?.message
                                            ) : (
                                              <Trans
                                                i18nKey="startDateIsRequired"
                                                defaults="Start Date is required"
                                              />
                                            )}{" "}
                                          </span>
                                        )}
                                          </div>
                                        </div>
                                      </>
                                    ) : null}
                                    {/* {column.fieldCode ==
                                          "Edu End Date" ? (
                                          <>
                                            <div className="form-group">
                                              <label>
                                                {t(column?.fieldName, { defaultValue: column?.fieldName })}
                                              </label>
                                              <div>
                                                <Controller
                                                  control={control}
                                                  name={`education[${index1}].endDate`}
                                                  refs={register(
                                                    `education[${index1}].endDate`,
                                                    {
                                                      required: false,
                                                    }
                                                  )}
                                                  render={({
                                                    field: {
                                                      onChange,
                                                      onBlur,
                                                      value,
                                                      refs,
                                                    },
                                                  }) =>
                                                    (itemData?.endDate &&
                                                      itemData?.endDate !=
                                                      "") || getValues().education[index1]?.endDate != "" ? (
                                                      <UserDateInput
                                                        defaultValue={value ? value : getValues()?.education[index1]?.endDate}
                                                        refs={refs}
                                                        key={dateClear}
                                                        setDateClear={
                                                          setDateClear
                                                        }
                                                        onChange={onChange}
                                                        placement="auto"
                                                      />
                                                    ) : (
                                                      <UserDateInput
                                                        //   defaultValue=""
                                                        refs={refs}
                                                        key={dateClear}
                                                        setDateClear={
                                                          setDateClear
                                                        }
                                                        onChange={onChange}
                                                        placement="auto"
                                                      />
                                                    )
                                                  }
                                                />
                                              </div>
                                            </div>
                                          </>
                                        ) : null} */}
                                    {column?.fieldCode == "Edu End Date" ? (
                                      <>
                                        <div className="form-group">
                                          <label>
                                          {t(column?.i18nKey, { defaultValue: column?.fieldName })}
                                          </label>
                                          {column?.required && (
                                            <span className="text-danger">
                                              {"*"}
                                            </span>
                                          )}
                                          <div>
                                            <Controller
                                              control={control}
                                              name={`education[${index1}].endDate`}
                                              refs={register(
                                                `education[${index1}].endDate`,
                                                {
                                                  required: column?.required
                                                    ? true
                                                    : false,
                                                }
                                              )}
                                              rules={{
                                                validate: {
                                                  valueError: (v) => {
                                                    if (
                                                      getValues()?.education?.[
                                                        index1
                                                      ]?.startDate != ""
                                                    ) {
                                                      if (
                                                        DateTime.fromJSDate(
                                                          getValues()
                                                            ?.education?.[
                                                            index1
                                                          ]?.startDate
                                                        ) >
                                                        DateTime.fromJSDate(v)
                                                      ) {
                                                        return t(
                                                          "endDateShouldBeGreaterThan",
                                                          {
                                                            defaultValue:
                                                              "End date should be greater than start date",
                                                          }
                                                        );
                                                      }
                                                    }
                                                  },
                                                },
                                              }}
                                              render={({
                                                field: {
                                                  onChange,
                                                  onBlur,
                                                  value,
                                                  refs,
                                                },
                                              }) =>
                                                (itemData?.endDate &&
                                                  itemData?.endDate != "") ||
                                                getValues()?.education[index1]
                                                  ?.endDate != "" ? (
                                                  <UserDateInput
                                                    defaultValue={
                                                      value
                                                        ? value
                                                        : getValues()
                                                            ?.education[index1]
                                                            .endDate
                                                    }
                                                    refs={refs}
                                                    key={dateClear}
                                                    setDateClear={setDateClear}
                                                    disabled={
                                                      column?.editable
                                                        ? false
                                                        : true
                                                    }
                                                    onChange={(value) => {
                                                      onChange(value);
                                                      DurationValidation();
                                                    }}
                                                    placement="auto"
                                                  />
                                                ) : (
                                                  <UserDateInput
                                                    //   defaultValue=""
                                                    refs={refs}
                                                    key={dateClear}
                                                    setDateClear={setDateClear}
                                                    disabled={
                                                      column?.editable
                                                        ? false
                                                        : true
                                                    }
                                                    onChange={(value) => {
                                                      onChange(value);
                                                      DurationValidation();
                                                    }}
                                                    placement="auto"
                                                  />
                                                )
                                              }
                                            />
                                          </div>
                                          {errors?.["education"]?.[index1]?.[
                                          "endDate"
                                        ] && errors?.["education"]?.[index1]?.[
                                          "endDate"
                                        ]?.type == "required" && (
                                          <span className="text-danger mt-3">
                                            {errors?.["education"]?.[index1]?.[
                                          "endDate"
                                        ]?.message !=
                                            "" ? (
                                              errors?.["education"]?.[index1]?.[
                                                "endDate"
                                              ]?.message
                                            ) : (
                                              <Trans
                                                i18nKey="endDateIsRequired"
                                                defaults="End Date is required"
                                              />
                                            )}{" "}
                                          </span>
                                        )}
                                        </div>
                                        
                                      </>
                                    ) : null}
                                    {column?.fieldCode == "Edu Location" ? (
                                      <>
                                        <div className="form-group">
                                          <label>
                                          {t(column?.i18nKey, { defaultValue: column?.fieldName })}
                                          </label>
                                          {column?.required && (
                                            <span className="text-danger">
                                              {"*"}
                                            </span>
                                          )}
                                          <Controller
                                            control={control}
                                            name={`education[${index1}].location`}
                                            refs={register(
                                              `education[${index1}].location`,
                                              {
                                                required: column?.required
                                                  ? true
                                                  : false,
                                              }
                                            )}
                                            render={({
                                              field: {
                                                onChange,
                                                onBlur,
                                                value,
                                                refs,
                                              },
                                            }) => (
                                              <div className="w-100">
                                                <LocationField
                                                  handlePlaceChange={
                                                    handleAddressChange
                                                  }
                                                  onChange={onChange}
                                                  value={value}
                                                  column={column}
                                                  index={index1}
                                                />
                                              </div>
                                            )}
                                          />
                                          {errors?.["education"]?.[index1]?.[
                                          "location"
                                        ] && errors?.["education"]?.[index1]?.[
                                          "location"
                                        ]?.type == "required" && (
                                          <span className="text-danger mt-3">
                                            {errors?.["education"]?.[index1]?.[
                                          "location"
                                        ]?.message !=
                                            "" ? (
                                              errors?.["education"]?.[index1]?.[
                                                "location"
                                              ]?.message
                                            ) : (
                                              <Trans
                                                i18nKey="locationIsRequired"
                                                defaults="Location is required"
                                              />
                                            )}{" "}
                                          </span>
                                        )}
                                        </div>
                                      </>
                                    ) : null}
                                    {/* 
                                                                                    {column.fieldCode ==
                                                                                        "Edu Duration" ? (
                                                                                        <>
                                                                                            <div className="form-group">
                                                                                                <label>Duration</label>
                                                                                                <Controller
                                                                                                    control={control}
                                                                                                    name={`education[${index1}].Duration`}
                                                                                                    refs={register(
                                                                                                        `education[${index1}].Duration`,
                                                                                                        {
                                                                                                            required: false,
                                                                                                        }
                                                                                                    )}

                                                                                                    render={({
                                                                                                        field: {
                                                                                                            onChange,
                                                                                                            onBlur,
                                                                                                            value,
                                                                                                            refs,
                                                                                                        },
                                                                                                    }) => (
                                                                                                        <Form.Control
                                                                                                            size="sm"
                                                                                                            placeholder="Duration"
                                                                                                            // value={value}
                                                                                                            defaultValue={
                                                                                                                itemData?.duration?.text
                                                                                                            }
                                                                                                            refs={refs}
                                                                                                            onChange={onChange}
                                                                                                        />
                                                                                                    )}
                                                                                                />
                                                                                            </div>
                                                                                        </>
                                                                                    ) : null} */}
                                  </div>
                                );
                              })}
                          </div>

                          // <div className="row" key={index}>
                          //     <div className="col-md-6">
                          //         <div className="form-group">
                          //             <label>{item.fieldName}</label>
                          //             <input
                          //                 type="text"
                          //                 className="form-control"
                          //                 placeholder={item.fieldName}
                          //                 name={`education[${index}].${item.fieldName}`}
                          //                 refs={register(`education[${index}].${item.fieldName}`, {
                          //                     required: false,
                          //                     validate: {
                          //                         emptyValue: (v) => v.replace(/\s/g, "") != ""
                          //                     }
                          //                 })}
                          //             />

                          //             {/* {errors?.education?.[index]?.[item.fieldName] && <span className="text-danger">This field is required</span>} */}
                          //         </div>
                          //     </div>
                          // </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      <div className="my-2">
        <div className="justify-content-center d-flex">
          <span
            className="px-2 IconArea actionIcon actionIcon-lg"
            onClick={handleSubmit(handleAddEducation)}
          >
            <FontAwesomeIcon icon={faPlusCircle}></FontAwesomeIcon>
          </span>
        </div>
        <div className="justify-content-center d-flex">
          <span className="">
            <Trans
              i18nKey="addEducationDetails"
              defaults="Add Education Details"
            />
          </span>
        </div>
      </div>
    </>
  );
};
export default EducationModal;
