import React, { useEffect, useState } from "react";
import { TagPicker } from "rsuite";
import PropTypes from "prop-types";
// import { useGetSuggestionsMutation } from "../../../services/suggestions";
// import SpinnerIcon from "@rsuite/icons/legacy/Spinner";
import { Trans } from "react-i18next";
import { useTranslation } from "react-i18next";
function CheckBoxesSuggestions({
  // AdvancedSearchFormRef,
  column,
  placeholder,
  context,
  type,
  selectedOptions,
  onChange,
  value,
  size,
  from,
}) {
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  // const [value, setValue] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  // const [getSuggestions] = useGetSuggestionsMutation();
  const [cacheData, setCacheData] = React.useState([]);
  const handleSelect = (value, item, event) => {
    setCacheData([...cacheData, item]);
  };
  // const handleChange = (values) => {
  //   // setValue(values)
  //   if (type == "keywords") {
  //     AdvancedSearchFormRef.current = values;
  //   } else {
  //     AdvancedSearchFormRef.current.find((x) => x.type == type).value = values;
  //   }
  // };

  useEffect(() => {
    if (selectedOptions) {
      setCacheData([
        ...cacheData,
        ...selectedOptions.map((x) => {
          return { label: x, value: x };
        }),
      ]);
      setData(
        ...data,
        selectedOptions?.map((x) => {
          return { label: x, value: x };
        })
      );
    }
  }, []);

  // useEffect(() => {
  //   if (search != "") {
  // getSuggestions({ context: context, text: search }).then((result) => {
  //   setData(
  //     result?.data?.data?.map((x) => {
  //       return { label: x.text, value: x.text };
  //     })
  //   );
  //   setLoading(false);
  // });
  // }, [search]);

  return (
    <div>
      <TagPicker
        cacheData={cacheData}
        creatable
        data={data}
        name="keywords"
        className="w-100"
        onSelect={handleSelect}
        onChange={(value) => {
          // handleChange(value);
          if (onChange) {
            onChange(value);
          }
        }}
        value={value}
        defaultValue={selectedOptions}
        onSearch={(e) => setSearch(e)}
        onCreate={(value, item) => {
          setCacheData([...cacheData, item]);
        }}
        // disabled={
        //   (from = "customLayout" || column?.editable || !column ? false : true)
        // }
        disabled={
          from == "customLayout" || column?.editable || !column ? false : true
        }
        placement="auto"
        size={size ? size : "md"}
        // placeholder={placeholder}
        placeholder={
          t("enter", { defaultValue: "Enter" }) +
          " " +
          t(placeholder, { defaultValue: placeholder })
        }
        // renderMenu={(menu) => {
        //   if (loading) {
        //     return (
        //       <p className="checkBoxLoader">
        //         <SpinnerIcon spin />
        //         <Trans defaults="Loading..." i18nKey="loading" />
        //       </p>
        //     );
        //   }
        //   return menu;
        // }}
      />
    </div>
  );
}

CheckBoxesSuggestions.propTypes = {
  // AdvancedSearchFormRef: PropTypes.object.isRequired,
  placeholder: PropTypes.string.isRequired,
  context: PropTypes.any.isRequired,
  type: PropTypes.string.isRequired,
  selectedOptions: PropTypes.array,
  onChange: PropTypes.func,
  value: PropTypes.array,
  size: PropTypes.string,
};

export default CheckBoxesSuggestions;
