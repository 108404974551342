import React, { useState, useEffect, useRef } from "react";
import { Popover,Overlay,Form } from "react-bootstrap";
import usePost from "../../customhooks/UsePost";
import { Trans, useTranslation } from "react-i18next";

function JobRoleSelect({
  selectedRole,
  setSelectedRole,
  suggestions,
  setSuggestions,
}) {
  const [searchInput, setSearchInput] = useState("");
  const [showPopover, setShowPopover] = useState(false);
  const [words, setWords] = useState("");
 
  const {
    post: getJobs,
    isLoading: loading,
    data,
    error,
  } = usePost("/jobsearch/autosuggestions");

  const fetchdata = (word) => {
    setWords(word);
    let body = { searchkey: word ?? "" };

    getJobs(body).then((res) => {
      const UpdateDate = res?.data?.result?.map((x) => {
        return { label: x, value: x };
      });

      setSuggestions(UpdateDate);
    });
  };

  const handleRoleSelect = (role) => {
    if (!selectedRole.some((selected) => selected.value === role.value)) {
      setSelectedRole([...selectedRole, role]);
    }
    setSearchInput("");
    setShowPopover(false);
  };

  const handleRoleRemove = (role) => {
    setSelectedRole(
      selectedRole.filter((selected) => selected.value !== role.value)
    );
  };

  const target = useRef(null);
  const popoverRef = useRef(null);

  const handleClickOutside = (event) => {
    if (
      popoverRef.current &&
      !popoverRef.current.contains(event.target) &&
      target.current &&
      !target.current.contains(event.target)
    ) {
      setShowPopover(false);
      setSearchInput("");
    }
  };

  useEffect(() => {
    if (showPopover) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showPopover]);

  return (
    <>
      <div className="">
        {selectedRole.length > 0 ? (
          <span
            className="me-3 TitleStyles fontSize"
            onClick={() => handleRoleRemove(role)}
          >
            <i>{selectedRole[0]?.label}</i>
          </span>
        ) : (
          // ))
          <span
            className="me-3 anchorLike fontSize fontPlcaeholdr"
            ref={target}
            onClick={() => {
              setShowPopover(true);
              setSearchInput("");
            }}
          >
            <Trans i18nKey="selectAJobTitle" defaults="select A Job Title" />
          </span>
        )}

        <Overlay
          show={showPopover}
          target={target.current}
          placement="bottom"
          containerPadding={20}
          rootClose={true}
          onHide={() => {
            setShowPopover(false);
            setSearchInput("");
          }}
        >
          <Popover id="popover-basic" ref={popoverRef}  style={{zIndex: 5}}>
            <Popover.Body>
              <Form.Control
                type="text"
                placeholder="Search Job Title"
                value={searchInput}
                onChange={(e) => {
                  setSearchInput(e.target.value);
                  fetchdata(e.target.value);
                }}
              />
              <div>
                {suggestions.map((job, index) => (
                  <div
                    key={index}
                    className={`job-item ${
                      selectedRole.some(
                        (selected) => selected.value === job.value
                      )
                        ? "selected"
                        : ""
                    }`}
                    onClick={() => handleRoleSelect(job)}
                  >
                    <h6 className="mt-2">{job.label}</h6>
                  </div>
                ))}
              </div>
            </Popover.Body>
          </Popover>
        </Overlay>
      </div>
    </>
  );
}

export default JobRoleSelect;
