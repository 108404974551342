import React, { Suspense, useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Modal, toaster, Notification } from "rsuite";
import { Trans, useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { Button, Spinner } from "react-bootstrap";
import Loading from "../../utilities/Loading";
import "./CreateProfile.css";
import { parsePhoneNumber } from "react-phone-number-input";
import countryNames from "react-phone-number-input/locale/en";
import usePost from "../../customhooks/UsePost";
import UserContext from "../../app/context/UserContext";
import { useSearchParams } from "react-router-dom";
import useIsSsr from "../../customhooks/UseIsSSR";
const CreateProfileUpload = React.lazy(() => import("./CreateProfileUpload"));
const CreateProfileForm = React.lazy(() => import("./CreateProfileForm"));
const UploadedProfilesDetails = React.lazy(() =>
  import("./UploadedProfilesDetails")
);
const CreateManualProfileMain = React.lazy(() =>
  import("./CreateManualProfileMain")
);
const GuestProfilePreview = React.lazy(() => import("./GuestProfilePreview"));
// const GuestResumePreview = React.lazy(() =>
//   import("../jobs/careersJobdeatails/GuestResumePreview")
// );
import { DateTime } from "luxon";

function CreateProfileModal({
  open,
  setOpen,
  campaignID,
  page,
  setOpenDuplicate,
  setDuplicateProfiles,
  setCookie,
  setResumeID,
  setupdatadataMri,
  companyID,
  notduplicate,
  updateReferraldataLoading,
  setGuestCookie
}) {
  const isSsr = useIsSsr();
  const { user, setUser } = useContext(UserContext);
  const { t } = useTranslation();
  const [showUploadedProfiles, setShowUploadedProfiles] = useState(false);
  const [uploadedProfiles, setUploadedProfiles] = useState([]);
  const [createManualProfile, setCreateManualProfile] = useState(false);
  const [searchParams] = useSearchParams();
  const [previewGuestProfile, setPreviewGuestProfile] = useState(false);
  const [previewGuestResume, setPreviewGuestResume] = useState(false);
  const [resumeEdit, setResumeEdit] = useState(false);
  const [step, setStep] = useState(0);
  const [resumeId, setResumeId] = useState("");
  const [fromUpload, setFromUpload] = useState(false);

  // console.log("shfdhshdgsdfsdf", campaignID)
  // console.log("fromfromfrom", page)
  const onChange = (nextStep) => {
    if (nextStep < 0) {
      setStep(0);
    } else {
      if (nextStep > 4) {
        setStep(4);
      } else {
        setStep(nextStep);
      }
    }
    // setStep(nextStep < 0 ? 0 : nextStep > 4 ? 4 : nextStep);
  };
  const onPrevious = () => onChange(step - 1);
  const onNext = () => onChange(step + 1);

  const {
    register,
    control,
    getValues,
    setValue,
    watch,
    clearErrors,
    formState: { errors },
    setError,
    handleSubmit,
    reset,
  } = useForm();

  const fileOnChange = (files) => {
    setShowUploadedProfiles(true);
    setCreateManualProfile(false);
    setUploadedProfiles(...uploadedProfiles, files);
  };

  function getBase64File(file) {
    if (file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result.split(";base64,")[1]);
        reader.onerror = (error) => reject(error);
      });
    }
  }

  const { post: setCookies } = usePost("/setcookie");

  // usePost
  const {
    post: uploadResumes,
    data: uploadResumesData,
    isLoading: uploadResumesLoading,
    error: uploadResumesError,
  } = usePost("/user/uploadResumes");

  const {
    post: createUserResumes,
    data: createUserResumesData,
    isLoading: createUserResumesLoading,
    error: createUserResumesError,
  } = usePost("/user/createUserResumes");

  const {
    post: uploadUserResumes,
    data: uploadUserResumesData,
    isLoading: uploadUserResumesLoading,
    error: uploadUserResumesError,
  } = usePost("/user/uploadUserResumes");

  const uploadProfiles = async () => {
    setCreateManualProfile(false);
    if (page == "userbasicprofile" || page == "referalresume") {
      if (getValues()?.UploadProfiles?.length <= 1) {
        for (let i in getValues()?.UploadProfiles) {
          let file = getValues()?.UploadProfiles[i];
          if (file?.blobFile.size <= 2e6) {
            let fileType = file?.name?.split(".").pop().toLowerCase();
            let acceptedFileTypes = [
              "doc",
              "docx",
              "pdf",
              "rtf",
              "txt",
              "odt",
              "ott",
              "htm",
              "html",
              // "ods",
              "xls",
              "xlsx",
              "csv",
            ];
            if (acceptedFileTypes.includes(fileType)) {
              let base64Files = [];
              base64Files.push({
                originalname: file.name,
                size: file.blobFile.size,
                type: "docFile",
                path: "",
                base64File: await getBase64File(file.blobFile),
              });
              if (base64Files?.length > 0) {
                uploadUserResumes({
                  base64Files: base64Files,
                  from: page != "" ? page : "",
                  // campaignID:
                  //   campaignID != undefined && campaignID != "" ? campaignID : "",
                  jobSeekerID:
                    user?._id != undefined && user?._id != "" ? user?._id : "",
                  source:
                    searchParams.get("source") != null
                      ? searchParams.get("source")
                      : "",
                  referenceURL: isSsr
                    ? ""
                    : window.location.origin + window.location.pathname,
                  companyID:
                    page == "userbasicprofile" ? user?.companyID : companyID,
                })
                  .then((resp) => {
                    console.log("response", resp);

                    if (resp?.status) {
                      if (resp?.result?.status && resp.result.status === true) {
                        setResumeID(resp.result?.resumeID);
                        if (page == "userbasicprofile") {
                          if (
                            resp?.result?.resumeID &&
                            resp.result.resumeID != ""
                          ) {
                            setUser({
                              ...user,
                              resumeID: resp.result.resumeID,
                            });
                          }
                        }

                        toaster.push(
                          <Notification
                            type={"success"}
                            header={t("success", { defaultValue: "Success" })}
                            closable
                          >
                            {t("resumeCreatedSuccessfully", {
                              defaultValue: "Resume created Successfully",
                            })}
                          </Notification>,
                          { placement: "topEnd" }
                        );
                        setOpen(false);
                      }
                    } else {
                      toaster.push(
                        <Notification
                          type={"error"}
                          header={t("error", { defaultValue: "Error" })}
                          closable
                        >
                          {t("somethingWentWrongPleaseTryAgainLater", {
                            defaultValue:
                              "Something went wrong please try again later",
                          })}
                        </Notification>,
                        { placement: "topEnd" }
                      );
                    }
                  })
                  .catch((error) => {
                    // console.log("error", error)
                  });
              }
            } else {
              setShowUploadedProfiles(false);
              toaster.push(
                <Notification
                  type={"error"}
                  header={t("error", { defaultValue: "Error" })}
                  closable
                >
                  {t("pleaseUploadTheAcceptedFileFormats", {
                    defaultValue: "Please upload the accepted file formats",
                  })}
                </Notification>,
                { placement: "topEnd" }
              );
            }
          } else {
            setShowUploadedProfiles(false);
            toaster.push(
              <Notification
                type={"error"}
                header={t("error", { defaultValue: "Error" })}
                closable
              >
                {t("pleaseUploadAFileLessThanMb", {
                  defaultValue: "Please upload a file less than 2Mb",
                })}
              </Notification>,
              { placement: "topEnd" }
            );
          }
        }
      } else {
        toaster.push(
          <Notification
            type={"error"}
            header={t("error", { defaultValue: "Error" })}
            closable
          >
            {t("uploadingFilesLimitIs", {
              defaultValue: "Uploading files limit is 1",
            })}
          </Notification>,
          { placement: "topEnd" }
        );
      }
    } else {
      if (getValues()?.UploadProfiles?.length <= 1) {
        for (let i in getValues()?.UploadProfiles) {
          let file = getValues()?.UploadProfiles[i];
          if (file?.blobFile.size <= 2e6) {
            let fileType = file?.name?.split(".").pop().toLowerCase();
            let acceptedFileTypes = [
              "doc",
              "docx",
              "pdf",
              "rtf",
              "txt",
              "odt",
              "ott",
              "htm",
              "html",
              // "ods",
              "xls",
              "xlsx",
              "csv",
            ];
            if (acceptedFileTypes.includes(fileType)) {
              let base64Files = [];
              base64Files.push({
                originalname: file.name,
                size: file.blobFile.size,
                type: "docFile",
                path: "",
                base64File: await getBase64File(file.blobFile),
              });
              if (base64Files?.length > 0) {
                uploadResumes({
                  base64Files: base64Files,
                  from: page != "" ? page : "",
                  campaignID:
                    campaignID != undefined && campaignID != ""
                      ? campaignID
                      : "",
                  jobSeekerID:
                    user?._id != undefined && user?._id != "" ? user?._id : "",
                  source:
                    searchParams.get("source") != null
                      ? searchParams.get("source")
                      : "",
                  referenceURL: isSsr
                    ? ""
                    : window.location.origin + window.location.pathname,
                })
                  .then((resp) => {
                    // console.log("response", resp)

                    if (resp?.status) {
                      if (resp?.result?.status && resp.result.status === true) {
                        if (page && page == "login") {
                          if (
                            resp?.result?.resumeID &&
                            resp.result.resumeID != ""
                          ) {
                            setUser({
                              ...user,
                              resumeID: resp.result.resumeID,
                            });
                          }

                          if (
                            resp?.result?.campaignID &&
                            resp.result.campaignID != ""
                          ) {
                            if (
                              !user.attachedCampaigns.includes(
                                resp.result.campaignID
                              )
                            ) {
                              setUser({
                                ...user,
                                attachedCampaigns: [
                                  ...user.attachedCampaigns,
                                  resp.result.campaignID,
                                ],
                              });
                            }
                          }
                          toaster.push(
                            <Notification
                              type={"success"}
                              header={t("success", { defaultValue: "Success" })}
                              closable
                            >
                              {t("jobAppliedSuccessfully", {
                                defaultValue: "Job applied Successfully",
                              })}
                            </Notification>,
                            { placement: "topEnd" }
                          );
                        } else {
                          if (page && page == "guest") {
                            // setting resumeID in cookies for guest user
                            if (resp?.result?.details) {
                              setResumeId(resp?.result?.resumeID);
                              toaster.push(
                                <Notification
                                  type={"success"}
                                  header={t("success", {
                                    defaultValue: "Success",
                                  })}
                                  closable
                                >
                                  {t("pleaseCheckYourProfile", {
                                    defaultValue: "Please Check Your Profile",
                                  })}
                                </Notification>,
                                { placement: "topEnd" }
                              );
                              // setShowUploadedProfiles(true);
                              setFromUpload(true);
                              setUploadedProfiles([]);
                              setCreateManualProfile(true);
                            } else if (resp?.result?.required) {
                              setResumeId(resp?.result?.resumeID);
                              toaster.push(
                                <Notification
                                  type={"success"}
                                  header={t("success", {
                                    defaultValue: "Success",
                                  })}
                                  closable
                                >
                                  {t("pleaseCompleteYourProfile", {
                                    defaultValue:
                                      "Please Complete Your Profile",
                                  })}
                                </Notification>,
                                { placement: "topEnd" }
                              );
                              // setShowUploadedProfiles(true);
                              setFromUpload(true);
                              setUploadedProfiles([]);
                              setCreateManualProfile(true);
                            } else {
                              if (
                                resp?.result?.resumeID &&
                                resp.result.resumeID != ""
                              ) {
                                setCookies({
                                  cookieName: "Guest_User_ResumeID",
                                  cookieValue: resp?.result?.resumeID,
                                  expires: DateTime.now()
                                    .plus({ months: 3 })
                                    .toISO(),
                                })
                                  .then((res) => {
                                    // console.log("cookie res", res);

                                    if (res?.status) {
                                      setCookie(resp?.result?.resumeID);
                                      setupdatadataMri(true);
                                      setGuestCookie(resp?.result?.resumeID);
                                    }
                                  })
                                  .catch((error) => {
                                    console.log("Error in setCookie : ", error);
                                  });
                              }

                              toaster.push(
                                <Notification
                                  type={"success"}
                                  header={t("success", {
                                    defaultValue: "Success",
                                  })}
                                  closable
                                >
                                  {t("jobAppliedSuccessfully", {
                                    defaultValue: "Job applied Successfully",
                                  })}
                                </Notification>,
                                { placement: "topEnd" }
                              );
                              let fullUrl;
                              if (
                                window.location.pathname.includes("/careers")
                              ) {
                                fullUrl =
                                  window.location.origin +
                                  "/careers/myaccount/guest";
                                  window.open(fullUrl, "_blank");
                              } else {
                                // fullUrl = window.location.origin + "/myaccount/guest";
                                let rid = resp?.result?.resumeID 
                                let url =  rid ?   `/myaccount/guest?rid=${rid}` : `/myaccount/guest`;
                                fullUrl = window.location.origin + `${url}`
                              window.open(fullUrl, "_blank");
                              }
                              // window.open(fullUrl, "_blank");

                              setOpen(false);
                            }
                          }
                        }
                      } else {
                        if (
                          resp?.result?.message.includes(
                            "Campaign is not active"
                          )
                        ) {
                          toaster.push(
                            <Notification
                              type={"error"}
                              header={t("error", { defaultValue: "Error" })}
                              closable
                            >
                              {t("thisJobIsNoLongerAcceptingApplications", {
                                defaultValue:
                                  "This job is no longer accepting applications",
                              })}
                            </Notification>,
                            { placement: "topEnd" }
                          );
                          setOpen(false);
                        } else {
                          toaster.push(
                            <Notification
                              type={"error"}
                              header={t("error", { defaultValue: "Error" })}
                              closable
                            >
                              {t("somethingWentWrongPleaseTryAgainLater", {
                                defaultValue:
                                  "Something went wrong please try again later",
                              })}
                            </Notification>,
                            { placement: "topEnd" }
                          );
                        }
                      }
                    } else {
                      toaster.push(
                        <Notification
                          type={"error"}
                          header={t("error", { defaultValue: "Error" })}
                          closable
                        >
                          {t("somethingWentWrongPleaseTryAgainLater", {
                            defaultValue:
                              "Something went wrong please try again later",
                          })}
                        </Notification>,
                        { placement: "topEnd" }
                      );
                    }
                  })
                  .catch((error) => {
                    toaster.push(
                      <Notification
                        type={"error"}
                        header={t("error", { defaultValue: "Error" })}
                        closable
                      >
                        {t("somethingWentWrongPleaseTryAgainLater", {
                          defaultValue:
                            "Something went wrong please try again later",
                        })}
                      </Notification>,
                      { placement: "topEnd" }
                    );
                    // console.log("error", error)
                  });
              }
            } else {
              setShowUploadedProfiles(false);
              toaster.push(
                <Notification
                  type={"error"}
                  header={t("error", { defaultValue: "Error" })}
                  closable
                >
                  {t("pleaseUploadTheAcceptedFileFormats", {
                    defaultValue: "Please upload the accepted file formats",
                  })}
                </Notification>,
                { placement: "topEnd" }
              );
            }
          } else {
            setShowUploadedProfiles(false);
            toaster.push(
              <Notification
                type={"error"}
                header={t("error", { defaultValue: "Error" })}
                closable
              >
                {t("pleaseUploadAFileLessThanMb", {
                  defaultValue: "Please upload a file less than 2Mb",
                })}
              </Notification>,
              { placement: "topEnd" }
            );
          }
        }
      } else {
        toaster.push(
          <Notification
            type={"error"}
            header={t("error", { defaultValue: "Error" })}
            closable
          >
            {t("uploadingFilesLimitIs", {
              defaultValue: "Uploading files limit is 1",
            })}
          </Notification>,
          { placement: "topEnd" }
        );
      }
    }
  };
  // console.log(getValues(),"gettttttttttt");
  // usePost
  const {
    post: createResumes,
    data: createResumesData,
    isLoading: createResumesLoading,
    error: createResumesError,
  } = usePost("/user/createResumes");

  const {
    post: guestSignUp,
    data: guestSignUpData,
    isLoading: guestSignUpLoading,
    error: guestSignUpError,
  } = usePost("/user/guestsignup");

  // const createGuestProfile = () => {
  //   setStep(0);
  //   setResumeEdit(false);
  //   setPreviewGuestProfile(true);
  //   setCreateManualProfile(false);
  // };
  // const editGuestProfile = () => {
  //   setPreviewGuestProfile(false);
  //   setCreateManualProfile(true);
  // };
  // const editGuestResume = () => {
  //   setStep(0);
  //   setResumeEdit(true);
  //   // setPreviewGuestProfile(false)
  // };
  //   console.log(resumeEdit,"modddddddddddddd");
  // console.log(notduplicate);
  useEffect(() => {
    if (notduplicate) {
      createProfileModalSubmit();
    }
  }, [notduplicate]);
  const createProfileModalSubmit = () => {
    let flag = true;
    if (getValues()) {
      // console.log("allvalues:", getValues())

      let createResumeObj = {
        phones: [],
        skills: [],
        from: page != "" ? page : "",
        campaignID:
          campaignID != undefined && campaignID != "" ? campaignID : "",
        jobSeekerID: user?._id != undefined && user?._id != "" ? user?._id : "",
        source:
          searchParams.get("source") != null ? searchParams.get("source") : "",
        referenceURL: isSsr
          ? ""
          : window.location.origin + window.location.pathname,
      };
      if (page == "userbasicprofile") {
        createResumeObj.companyID = user?.companyID;
      } else if (page == "referalresume") {
        createResumeObj.companyID = companyID;
      }
      // basic details
      createResumeObj.firstName =
        getValues()?.firstName !== undefined ? getValues()?.firstName : "";
      createResumeObj.middleName =
        getValues()?.middleName !== undefined ? getValues()?.middleName : "";
      createResumeObj.lastName =
        getValues()?.lastName !== undefined ? getValues()?.lastName : "";
      createResumeObj.city =
        getValues()?.profileCity !== undefined ? getValues()?.profileCity : "";
      createResumeObj.state =
        getValues()?.profileState !== undefined
          ? getValues()?.profileState
          : "";
      createResumeObj.country =
        getValues()?.profileCountry !== undefined
          ? getValues()?.profileCountry
          : "";
      createResumeObj.postalCode =
        getValues()?.profilePostalCode !== undefined
          ? getValues()?.profilePostalCode
          : "";
      createResumeObj.location =
        getValues()?.profileLocation !== undefined
          ? getValues()?.profileLocation
          : "";
      if (getValues()?.emails) {
        if (getValues()?.emails?.length > 0) {
          if (!checkEmailDuplicateOnSubmit(getValues()?.emails)) {
            flag = false;
          }
          createResumeObj.emails = getValues()?.emails;
        }
      }
      if (getValues()?.phones) {
        if (getValues()?.phones?.length > 0) {
          let phones = getValues()?.phones;
          if (!checkPhoneNumberDuplicateOnSubmit(phones)) {
            flag = false;
          }

          for (let i in phones) {
            if (
              phones[i]?.phone?.trim() != "" &&
              phones[i]?.phone !== undefined
            ) {
              let phoneSchema = {
                phone: "",
                country: "",
                countryCode: "",
                internationalPhoneCode: "",
                phoneNumberOnly: "",
              };
              let parsedNumber = parsePhoneNumber(String(phones[i]?.phone));
              phoneSchema.phone = parsedNumber?.number;
              phoneSchema.country = countryNames[parsedNumber?.country];
              phoneSchema.countryCode = parsedNumber?.country;
              phoneSchema.internationalPhoneCode =
                parsedNumber?.countryCallingCode;
              phoneSchema.phoneNumberOnly = parsedNumber?.nationalNumber;
              createResumeObj.phones.push(phoneSchema);
            }
          }
        }
      }

      // summary
      createResumeObj.objective =
        getValues()?.Objective !== undefined ? getValues()?.Objective : "";
      createResumeObj.summary =
        getValues()?.Summary !== undefined ? getValues()?.Summary : "";
      if (getValues()?.skills) {
        if (getValues()?.skills?.length > 0) {
          let skills = getValues()?.skills;
          for (let i in skills) {
            let skill = {};
            skill.skill = skills[i]?.skills;
            if (skills[i]?.yearsOfExperience == "") {
              skill.yearsOfExperience = 0;
            } else {
              skill.yearsOfExperience = parseInt(skills[i]?.yearsOfExperience);
            }
            createResumeObj.skills.push(skill);
          }
        }
      }

      createResumeObj.education =
        getValues()?.education !== undefined ? getValues()?.education : [];
      createResumeObj.experience =
        getValues()?.experience !== undefined ? getValues()?.experience : [];

      // other info
      if (
        getValues()?.minimumExpectedSalary !== undefined &&
        getValues()?.maximumExpectedSalary !== undefined
      ) {
        if (
          getValues()?.minimumExpectedSalary?.trim() != "" &&
          getValues()?.maximumExpectedSalary?.trim() != ""
        ) {
          if (
            parseInt(getValues()?.minimumExpectedSalary) >
            parseInt(getValues()?.maximumExpectedSalary)
          ) {
            flag = false;
            setError("minimumExpectedSalary", {
              type: "manual",
              message: (
                <Trans
                  i18nKey="minimumExpectedSalaryShouldBeLessThanMaximumExpectedSalary"
                  defaults="Minimum expected salary should be less than maximum expected salary"
                />
              ),
            });
          }
        }
      }

      createResumeObj.currentSalary =
        getValues()?.currentSalary !== undefined
          ? getValues()?.currentSalary
          : "";
      createResumeObj.salaryPeriod =
        getValues()?.salaryPeriod !== undefined
          ? getValues()?.salaryPeriod
          : "";
      createResumeObj.salaryCurrency =
        getValues()?.salaryCurrency !== undefined
          ? getValues()?.salaryCurrency
          : "";
      createResumeObj.noticePeriod =
        getValues()?.noticePeriod !== undefined
          ? getValues()?.noticePeriod
          : "";
      createResumeObj.workPermit =
        getValues()?.workPermit !== undefined ? getValues()?.workPermit : "";
      createResumeObj.gender =
        getValues()?.gender !== undefined ? getValues()?.gender : "";
      createResumeObj.veteranStatus =
        getValues()?.veteranStatus !== undefined
          ? getValues()?.veteranStatus
          : "";
      createResumeObj.dateOfBirth =
        getValues()?.dateOfBirth !== undefined ? getValues()?.dateOfBirth : "";
      createResumeObj.ageData =
        getValues()?.ageData !== undefined ? getValues()?.ageData : "";
      createResumeObj.maritalStatus =
        getValues()?.maritalStatus !== undefined
          ? getValues()?.maritalStatus
          : "";
      createResumeObj.nationality =
        getValues()?.nationality !== undefined ? getValues()?.nationality : "";
      createResumeObj.languagesKnown =
        getValues()?.languagesKnown !== undefined
          ? getValues()?.languagesKnown
          : [];
      createResumeObj.websites =
        getValues()?.websites !== undefined ? getValues()?.websites : [];
      createResumeObj.preferredLocations =
        getValues()?.preferred !== undefined ? getValues()?.preferred : [];
      createResumeObj.maximumExpectedSalary =
        getValues()?.maximumExpectedSalary !== undefined
          ? getValues()?.maximumExpectedSalary
          : "";
      createResumeObj.minimumExpectedSalary =
        getValues()?.minimumExpectedSalary !== undefined
          ? getValues()?.minimumExpectedSalary
          : "";
      createResumeObj.compensationCurrency =
        getValues()?.compensationCurrency !== undefined
          ? getValues()?.compensationCurrency
          : "";
      createResumeObj.compensationPeriod =
        getValues()?.compensationPeriod !== undefined
          ? getValues()?.compensationPeriod
          : "";
      createResumeObj.resumeID = resumeId;
      createResumeObj.fromUpload = fromUpload;
      if (notduplicate) {
        createResumeObj.notduplicate = false;
      } else {
        createResumeObj.notduplicate = true;
      }
      if (flag) {
        // console.log("createResumeObj", createResumeObj);

        if (page == "userbasicprofile" || page == "referalresume") {
          console.log(page, companyID);
          createUserResumes(createResumeObj)
            .then((resp) => {
              console.log(
                "response of create user from userbasic profile",
                resp
              );

              if (resp?.status) {
                if (resp?.result?.status && resp.result.status === true) {
                  if (
                    resp?.result?.disqualifiedExists &&
                    resp?.result?.disqualifiedExists === true
                  ) {
                    toaster.push(
                      <Notification
                        type={"warning"}
                        header={<Trans i18nKey="warning" defaults="Warning" />}
                        closable
                      >
                        <Trans
                          i18nKey="youCanNotCreateAProfileWithTheseContactDetailsTheseContactDetailsAreAssociatedWithDisqualifiedProfile"
                          defaults="You can not create a profile with these contact details. These contact details are associated with disqualified profile"
                        />
                      </Notification>,
                      {
                        placement: "topEnd",
                      }
                    );
                    // setOpen(false);
                  } else {
                    if (resp?.result?.duplicate) {
                      if (
                        resp?.result?.resumes &&
                        resp?.result?.resumes?.length > 0
                      ) {
                        // setOpen(false);
                        setOpenDuplicate(true);
                        setDuplicateProfiles(resp?.result?.resumes);
                        // reset();
                        // setCreateManualProfile(false);
                      }
                      toaster.push(
                        <Notification
                          type={"success"}
                          header={t("success", { defaultValue: "Success" })}
                          closable
                        >
                          {t("duplicateProfileFound", {
                            defaultValue: "Duplicate Profile Found",
                          })}
                        </Notification>,
                        { placement: "topEnd" }
                      );
                      // setOpen(false);
                      setOpenDuplicate(true);
                      setDuplicateProfiles(resp?.result?.resumes);
                    } else {
                      if (resp?.status && resp?.result?.resumeID != "") {
                        if (
                          resp?.result?.resumeID &&
                          resp.result.resumeID != ""
                        ) {
                          if (page == "userbasicprofile") {
                            setUser({
                              ...user,
                              resumeID: resp.result.resumeID,
                            });
                          }
                          setResumeID(resp?.result?.resumeID);
                        }
                        toaster.push(
                          <Notification
                            type={"success"}
                            header={t("success", { defaultValue: "Success" })}
                            closable
                          >
                            {t("profileCreatedSuccessfully", {
                              defaultValue: "Profile Created Successfully",
                            })}
                          </Notification>,
                          { placement: "topEnd" }
                        );
                        setOpen(false);
                        setCreateManualProfile(false);
                        reset();
                      }
                    }
                  }
                } else {
                  toaster.push(
                    <Notification
                      type={"error"}
                      header={t("error", { defaultValue: "Error" })}
                      closable
                    >
                      {t("somethingWentWrongPleaseTryAgainLater", {
                        defaultValue:
                          "Something went wrong please try again later",
                      })}
                    </Notification>,
                    { placement: "topEnd" }
                  );
                  setOpen(false);
                  setCreateManualProfile(false);
                }
              } else {
                toaster.push(
                  <Notification
                    type={"error"}
                    header={t("error", { defaultValue: "Error" })}
                    closable
                  >
                    {t("somethingWentWrongPleaseTryAgainLater", {
                      defaultValue:
                        "Something went wrong please try again later",
                    })}
                  </Notification>,
                  { placement: "topEnd" }
                );
              }
            })
            .catch((error) => {
              console.log(
                "error in create Resumes from userbasicprofile: ",
                error
              );
            });
        } else {
          createResumes(createResumeObj)
            .then((resp) => {
              // console.log("response", resp)

              if (resp?.status) {
                if (resp?.result?.status && resp.result.status === true) {
                  if (
                    resp?.result?.disqualifiedExists &&
                    resp?.result?.disqualifiedExists === true
                  ) {
                    toaster.push(
                      <Notification
                        type={"warning"}
                        header={<Trans i18nKey="warning" defaults="Warning" />}
                        closable
                      >
                        <Trans
                          i18nKey="youCanNotCreateAProfileWithTheseContactDetailsTheseContactDetailsAreAssociatedWithDisqualifiedProfile"
                          defaults="You can not create a profile with these contact details. These contact details are associated with disqualified profile"
                        />
                      </Notification>,
                      {
                        placement: "topEnd",
                      }
                    );
                    // setOpen(false);
                  } else {
                    if (resp?.result?.duplicate) {
                      if (resp?.result?.attached) {
                        if (page && page == "guest") {
                          // setting resumeID in cookies for guest user
                          if (
                            resp?.result?.resumeID &&
                            resp.result.resumeID != ""
                          ) {
                            setCookies({
                              cookieName: "Guest_User_ResumeID",
                              cookieValue: resp?.result?.resumeID,
                              expires: DateTime.now()
                                .plus({ months: 3 })
                                .toISO(),
                            })
                              .then(async (res) => {
                                // console.log("cookie res", res);

                                if (res?.status) {
                                  setCookie(resp?.result?.resumeID);
                                  setGuestCookie(resp?.result?.resumeID);
                                }
                              })
                              .catch((error) => {
                                console.log("Error in setCookie : ", error);
                              });
                          }
                        }
                        let phoness = [];
                        if (getValues()?.phones) {
                          if (getValues()?.phones?.length > 0) {
                            let phones = getValues()?.phones;
                            if (!checkPhoneNumberDuplicateOnSubmit(phones)) {
                              flag = false;
                            }

                            for (let i in phones) {
                              if (
                                phones[i]?.phone?.trim() != "" &&
                                phones[i]?.phone !== undefined
                              ) {
                                let phone = {
                                  phone: "",
                                  country: "",
                                  countryCode: "",
                                  internationalPhoneCode: "",
                                  phoneNumberOnly: "",
                                };
                                let parsedNumber = parsePhoneNumber(
                                  String(phones[i]?.phone)
                                );
                                phone.phone = parsedNumber?.number;
                                phone.country =
                                  countryNames[parsedNumber?.country];
                                phone.countryCode = parsedNumber?.country;
                                phone.internationalPhoneCode =
                                  parsedNumber?.countryCallingCode;
                                phone.phoneNumberOnly =
                                  parsedNumber?.nationalNumber;
                                phoness.push(phone);
                              }
                            }
                          }
                        }
                        guestSignUp({
                          name:
                            getValues()?.firstName +
                            " " +
                            getValues()?.lastName,
                          email:
                            getValues()?.emails &&
                            getValues()?.emails?.length > 0
                              ? getValues()?.emails[0]
                              : "",
                          phone: phoness[0],
                          resumeID: resp?.result?.resumeID,
                        })
                          .then((res) => {
                            if (res?.status) {
                              console.log("guest signup successfull");
                            }
                          })
                          .catch((error) => {
                            console.log("Error in guest signup : ", error);
                          });

                        toaster.push(
                          <Notification
                            type={"success"}
                            header={t("success", { defaultValue: "Success" })}
                            closable
                          >
                            {t("youHaveAlreadyAppliedForThisJob", {
                              defaultValue:
                                "You have already applied for this job",
                            })}
                          </Notification>,
                          { placement: "topEnd" }
                        );
                        // const fullUrl =
                        //   window.location.origin + "/myaccount/guest";
                        // window.open(fullUrl, "_blank");
                        let fullUrl;
                              if (
                                window.location.pathname.includes("/careers")
                              ) {
                                fullUrl =
                                  window.location.origin +
                                  "/careers/myaccount/guest";
                              } else {
                              // fullUrl =
                              // window.location.origin + "/myaccount/guest";
                              // fullUrl = window.location.origin + "/myaccount/guest";
                                let rid = resp?.result?.resumeID 
                                let url =  resp?.result?.resumeID ?   `/myaccount/guest?rid=${rid}` : `/myaccount/guest`;
                                // fullUrl = window.location.origin + "/myaccount/guest";
                                fullUrl = window.location.origin + `${url}`
                              }
                        // setTimeout(() => {
                        //   window.location.replace("/myaccount/guest");
                        // }, 1000);
                        setOpen(false);
                      } else {
                        if (
                          resp?.result?.resumes &&
                          resp?.result?.resumes?.length > 0
                        ) {
                          // setOpen(false);
                          setOpenDuplicate(true);
                          setDuplicateProfiles(resp?.result?.resumes);
                        }
                      }
                    } else {
                      if (page && page == "login") {
                        if (
                          resp?.result?.resumeID &&
                          resp.result.resumeID != ""
                        ) {
                          setUser({
                            ...user,
                            resumeID: resp.result.resumeID,
                          });
                        }

                        if (
                          resp?.result?.campaignID &&
                          resp.result.campaignID != ""
                        ) {
                          if (
                            !user.attachedCampaigns.includes(
                              resp.result.campaignID
                            )
                          ) {
                            setUser({
                              ...user,
                              attachedCampaigns: [
                                ...user.attachedCampaigns,
                                resp.result.campaignID,
                              ],
                            });
                          }
                        }
                      } else {
                        if (page && page == "guest") {
                          // setting resumeID in cookies for guest user
                          if (
                            resp?.result?.resumeID &&
                            resp.result.resumeID != ""
                          ) {
                            setCookies({
                              cookieName: "Guest_User_ResumeID",
                              cookieValue: resp?.result?.resumeID,
                              expires: DateTime.now()
                                .plus({ months: 3 })
                                .toISO(),
                            })
                              .then((res) => {
                                // console.log("cookie res", res);

                                if (res?.status) {
                                  setCookie(resp?.result?.resumeID);
                                  setGuestCookie(resp?.result?.resumeID);
                                }
                              })
                              .catch((error) => {
                                console.log("Error in setCookie : ", error);
                              });
                            let phoness = [];
                            if (getValues()?.phones) {
                              if (getValues()?.phones?.length > 0) {
                                let phones = getValues()?.phones;
                                if (
                                  !checkPhoneNumberDuplicateOnSubmit(phones)
                                ) {
                                  flag = false;
                                }

                                for (let i in phones) {
                                  if (
                                    phones[i]?.phone?.trim() != "" &&
                                    phones[i]?.phone !== undefined
                                  ) {
                                    let phone = {
                                      phone: "",
                                      country: "",
                                      countryCode: "",
                                      internationalPhoneCode: "",
                                      phoneNumberOnly: "",
                                    };
                                    let parsedNumber = parsePhoneNumber(
                                      String(phones[i]?.phone)
                                    );
                                    phone.phone = parsedNumber?.number;
                                    phone.country =
                                      countryNames[parsedNumber?.country];
                                    phone.countryCode = parsedNumber?.country;
                                    phone.internationalPhoneCode =
                                      parsedNumber?.countryCallingCode;
                                    phone.phoneNumberOnly =
                                      parsedNumber?.nationalNumber;
                                    phoness.push(phone);
                                  }
                                }
                              }
                            }
                            guestSignUp({
                              name:
                                getValues()?.firstName +
                                " " +
                                getValues()?.lastName,
                              email:
                                getValues()?.emails &&
                                getValues()?.emails?.length > 0
                                  ? getValues()?.emails[0]
                                  : "",
                              phone: phoness[0],
                              resumeID: resp?.result?.resumeID,
                            })
                              .then((res) => {
                                if (res?.status) {
                                  console.log("guest signup successfull");
                                }
                              })
                              .catch((error) => {
                                console.log("Error in guest signup : ", error);
                              });
                          }
                        }
                      }

                      toaster.push(
                        <Notification
                          type={"success"}
                          header={t("success", { defaultValue: "Success" })}
                          closable
                        >
                          {t("jobAppliedSuccessfully", {
                            defaultValue: "Job applied Successfully",
                          })}
                        </Notification>,
                        { placement: "topEnd" }
                      );
                      let fullUrl;
                      if (window.location.pathname.includes("/careers")) {
                        fullUrl =
                          window.location.origin + "/careers/myaccount/guest";
                          window.open(fullUrl, "_blank");
                      } else {
                        // fullUrl = window.location.origin + "/myaccount/guest";
                        let rid = resp?.result?.resumeID 
                        let url =  rid ?   `/myaccount/guest?rid=${rid}` : `/myaccount/guest`;
                        fullUrl = window.location.origin + `${url}`
                      window.open(fullUrl, "_blank");
                      }
                      // window.open(fullUrl, "_blank");
                      setOpen(false);
                    }
                  }
                } else {
                  if (
                    resp?.result?.message.includes("Campaign is not active")
                  ) {
                    toaster.push(
                      <Notification
                        type={"error"}
                        header={t("error", { defaultValue: "Error" })}
                        closable
                      >
                        {t("thisJobIsNoLongerAcceptingApplications", {
                          defaultValue:
                            "This job is no longer accepting applications",
                        })}
                      </Notification>,
                      { placement: "topEnd" }
                    );
                    setOpen(false);
                  } else {
                    toaster.push(
                      <Notification
                        type={"error"}
                        header={t("error", { defaultValue: "Error" })}
                        closable
                      >
                        {t("somethingWentWrongPleaseTryAgainLater", {
                          defaultValue:
                            "Something went wrong please try again later",
                        })}
                      </Notification>,
                      { placement: "topEnd" }
                    );
                  }
                }
              } else {
                toaster.push(
                  <Notification
                    type={"error"}
                    header={t("error", { defaultValue: "Error" })}
                    closable
                  >
                    {t("somethingWentWrongPleaseTryAgainLater", {
                      defaultValue:
                        "Something went wrong please try again later",
                    })}
                  </Notification>,
                  { placement: "topEnd" }
                );
              }
            })
            .catch((error) => {
              console.log("error in createResumes : ", error);
              toaster.push(
                <Notification
                  type={"error"}
                  header={t("error", { defaultValue: "Error" })}
                  closable
                >
                  {t("somethingWentWrongPleaseTryAgainLater", {
                    defaultValue:
                      "Something went wrong please try again later",
                  })}
                </Notification>,
                { placement: "topEnd" }
              );
            });
        }
      }
    }
  };

  function checkEmailDuplicateOnSubmit(emails) {
    let emailAvailable = true;
    for (let i = 0; i < emails.length; i++) {
      for (let j = 0; j < emails.length; j++) {
        if (i != j) {
          if (emails[i]?.email == emails[j]?.email) {
            setError("emails[" + j + "].email", {
              type: "manual",
              message: t("emailAlreadyExists", {
                defaultValue: "Email already exists",
              }),
            });
            emailAvailable = false;
          }
        }
      }
    }
    return emailAvailable;
  }

  function checkPhoneNumberDuplicateOnSubmit(phones) {
    let phoneNumberAvailable = true;
    for (let i = 0; i < phones.length; i++) {
      for (let j = 0; j < phones.length; j++) {
        if (i != j) {
          if (phones[i]?.phone == phones[j]?.phone) {
            setError("phones[" + j + "].phone", {
              type: "manual",
              message: t("phonenumberIsAlreadyPresent", {
                defaultValue: "Phonenumber is already present",
              }),
            });
            phoneNumberAvailable = false;
          }
        }
      }
    }
    return phoneNumberAvailable;
  }
  const [valuesToSubmit, setValuesToSubmit] = useState({});
  //   console.log(getValues(),"zzzzzzzzzzzzzzzzzzzzzzzz");
  const handleSumbiteditvalues = () => {
    // console.log(getValues());
    if (valuesToSubmit && valuesToSubmit != {}) {
      UpdateResumes({
        resumeID: profiledata?.Resumedetails?._id,
        companyID: JobDetails?.companyID,
        clientID: JobDetails?.clientID,
        getValues: valuesToSubmit,
      })
        .then((resp) => {
          if (resp?.result?.status) {
            toaster.push(
              <Notification
                type={"success"}
                header={t("success", { defaultValue: "Success" })}
                closable
              >
                {t("profileCreatedSuccessfully", {
                  defaultValue: "Profile Created Successfully",
                })}
              </Notification>,
              { placement: "topEnd" }
            );
            setOpen(false);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  useEffect(() => {
    if (open === false) {
      setFromUpload(false);
    }
  }, [open]);

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      overflow={false}
      // enforceFocus={true}
      size="full"
    >
      <Modal.Header>
        <Modal.Title>
          {/* {previewGuestProfile || previewGuestResume ? (
            <h4>
              <Trans
                i18nKey="submitYourProfile"
                defaults="Submit Your Profile"
              />
            </h4>
          ) : ( */}
          <h4>
            <Trans i18nKey="createProfile" defaults="Create Profile" />
          </h4>
          {/* )} */}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {showUploadedProfiles ? (
          <Suspense fallback={<Loading rows={1} />}>
            <UploadedProfilesDetails
              uploadedProfiles={uploadedProfiles}
              register={register}
              getValues={getValues}
              setValue={setValue}
            ></UploadedProfilesDetails>
          </Suspense>
        ) : (
          <>
            {/* {previewGuestResume ? (
              <Suspense fallback={<Loading rows={1} />}>
                <GuestResumePreview
              step={step}
              setStep={setStep}
              setOpen={setOpen}
              resumeId={resumeId}
              campaignID={campaignID}
              resumeEdit={resumeEdit}
            ></GuestResumePreview>
              </Suspense>
            ) : ( */}
            <>
              <div className="row my-3 ">
                <div className="col-md-5 col-lg-5 col-sm-12 col-xs-12  d-flex flex-column align-items-center justify-content-center">
                  <Suspense fallback={<Loading rows={1} />}>
                    <CreateProfileUpload
                      fileOnChange={fileOnChange}
                      register={register}
                      control={control}
                    ></CreateProfileUpload>
                  </Suspense>
                  {/* <div className='d-flex justify-content-center text-center my-2 text-secondary'>
                                    <Trans defaults='We accept only doc, docx, pdf and txt with a max file size up to: 2 MB' i18nKey="formatSizeWarningMessage" />
                                </div> */}
                </div>

                <div className=" col-md-2 col-lg-2 col-sm-12 col-xs-12 d-flex justify-content-center align-items-center">
                  <span>
                    <Trans defaults="OR" i18nKey="or" />
                  </span>
                </div>
                <div
                  className="col-md-5 col-lg-5 col-sm-12 col-xs-12  profileFormCard cursor-pointer"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setShowUploadedProfiles(true);
                    setCreateManualProfile(true);
                    setResumeId("");
                    // setCreateButtonName(
                    //     <Trans i18nKey="create" defaults="Create" />
                    // );
                  }}
                >
                  <Suspense fallback={<Loading rows={1} />}>
                    <CreateProfileForm></CreateProfileForm>
                  </Suspense>
                </div>
              </div>
            </>
            {/* )} */}
          </>
        )}
        {/* 
        {previewGuestProfile && (
          <Suspense fallback={<Loading rows={1} />}>
            <GuestProfilePreview
              register={register}
              control={control}
              setValue={setValue}
              handleSubmit={handleSubmit}
              getValues={getValues}
              errors={errors}
              watch={watch}
              clearErrors={clearErrors}
              required={false}
              setError={setError}
            ></GuestProfilePreview>
          </Suspense>
        )} */}

        {createManualProfile && (
          <Suspense fallback={<Loading rows={1} />}>
            <CreateManualProfileMain
              register={register}
              control={control}
              step={step}
              setStep={setStep}
              setValue={setValue}
              handleSubmit={handleSubmit}
              getValues={getValues}
              errors={errors}
              page={page}
              watch={watch}
              clearErrors={clearErrors}
              required={false}
              setError={setError}
              resumeId={resumeId}
              campaignID={campaignID}
            ></CreateManualProfileMain>
          </Suspense>
        )}
      </Modal.Body>
      <Modal.Footer>
        {showUploadedProfiles && (
          <>
            <Button
              className="btn-sm float-start"
              variant="secondary"
              onClick={() => {
                setShowUploadedProfiles(false);
                setCreateManualProfile(false);
                // setPreviewGuestProfile(false);
                setUploadedProfiles([]);
                setStep(0);
              }}
            >
              <Trans i18nKey="goBack" defaults="Go Back" />
            </Button>
            {createManualProfile ? (
              <Button
                variant="primary"
                className="btn-sm"
                onClick={handleSubmit(createProfileModalSubmit)}
                disabled={
                  !watch()?.createAge ||
                  !watch()?.createLegal ||
                  createResumesLoading ||
                  createUserResumesLoading ||
                  updateReferraldataLoading
                }
              >
                {(createResumesLoading ||
                  createUserResumesLoading ||
                  updateReferraldataLoading) && (
                  <Spinner animation="border" size="sm" className="me-1" />
                )}
                <Trans i18nKey="submit" defaults="Submit" />
              </Button>
            ) : (
              <Button
                variant="primary"
                className="btn-sm"
                onClick={handleSubmit(uploadProfiles)}
                disabled={
                  !watch()?.uploadAge ||
                  !watch()?.uploadLegal ||
                  uploadResumesLoading ||
                  uploadUserResumesLoading
                }
              >
                {(uploadResumesLoading || uploadUserResumesLoading) && (
                  <Spinner animation="border" size="sm" className="me-1" />
                )}
                <Trans i18nKey="upload" defaults="Upload" />
              </Button>
            )}
          </>
        )}
      </Modal.Footer>
    </Modal>
  );
}

CreateProfileModal.propTypes = {
  showProfileModal: PropTypes.bool,
  handleClose: PropTypes.any,
  campaignID: PropTypes.any,
};

export default CreateProfileModal;
