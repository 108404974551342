import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { t } from 'i18next';
import { Trans } from 'react-i18next';
import { Input } from 'rsuite';

const EthnicityForm = ({ ethnicityData,resumeId, control,errors, register, setValue, fieldList }) => {
    useEffect(() => {
        if(ethnicityData){
            setValue("Ethnicity", ethnicityData)            
        }else{
            setValue("Ethnicity", "")            
        }
    }, [ethnicityData])

    return (
        <>
            <div className="form-group">
                <label className='text-dark'>
                {t(fieldList?.i18nKey, { defaultValue: fieldList?.fieldName })}
                </label>
                {fieldList?.required && <span className="text-danger">{"*"}</span>}
                <Controller
                    control={control}
                    name={"Ethnicity"}
                    refs={register("Ethnicity", {
                        required: fieldList?.required ? true : false,
                        
                      })}
                    render={({ field: { onChange, onBlur, value } }) => (
                        <Input
                            size="sm"
                            className="w-100"
                            onChange={onChange}
                            disabled={(fieldList?.editable || !fieldList) ? false : true}
                            value={value}
                            placeholder={t("enter", { defaultValue: "Enter" }) + " " + t("Ethnicity", { defaultValue: "Ethnicity" }) }

                        />
                    )}
                />
                {errors?.Ethnicity&& (
          <span className="text-danger ">
            {errors?.Ethnicity?.message != "" ? (
              errors?.Ethnicity?.message
            ) : (
              <Trans
                i18nKey="ethnicityIsRequired"
                defaults="Ethnicity is required"
              />
            )}{" "}
          </span>
        )}
            </div>
        </>
    );
};

export default EthnicityForm;