import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBan,
  faCircleCheck,
  faCopy,
  faPencil,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { Notification, toaster } from "rsuite";
import { Trans } from "react-i18next";
import PhoneNumberSessionEdit from "../forms/PhoneNumberSessionEdit.";
import Loading from "../../../../Loder";
import { v4 as uuidv4 } from "uuid";
import usePost from "../../../../customhooks/UsePost";
import UserContext from "../../../../app/context/UserContext";
const PhoneNumber = ({
  resumeId,
  setHideEmailDetailsView,
  hideEmailDetailsView,
  hidePhoneDetailsView,
  setHidePhoneDetailsView,
  fieldObj,
  getData,
  triggerContactDetailsRef,
  resume, setUpdateResumeDataData,
  type
}) => {
  const [phoneNumber, setPhoneNumber] = useState("");

  useEffect(() => {
    if (resume) {
      setPhoneNumber(resume?.resume?.profile?.phones);
    }
  }, [resume]);
  const handleEditOrCreate = () => {
    setHideEmailDetailsView(true);
    setHidePhoneDetailsView(false);
  };
  const handleCopyPhoneNumber = (text) => {
    // copyText_TS(text)
    toaster.push(
      <Notification
        type={"success"}
        header={<Trans i18nKey="success" defaults="Success" />}
        closable
      >
        <Trans
          i18nKey="phoneNumberCopiedSuccessfully"
          defaults="Phone number copied successFully"
        />
      </Notification>,
      { placement: "topEnd" }
    );
  };
  const triggerRef = React.useRef();
  const close = () => triggerRef.current.close();
  const open = () => triggerRef.current.open();
  return (
    <>
      {hidePhoneDetailsView ? (
        <>
          <div className="row">
            <div className="col-sm-7 text-dark">
              <h6>
                <Trans i18nKey="phoneNumbers" defaults="Phone Numbers" />
              </h6>
            </div>
            <div className='col-sm-5 d-flex justify-content-end'>
              {(fieldObj?.editable || fieldObj?.editable == undefined) && type !== "referrer" ? (
                  <span
                    className="IconArea actionIcon actionIcon-sm"
                    title="Click here to add number"
                    onClick={handleEditOrCreate}
                  >
                    <FontAwesomeIcon
                      className=" cursor-pointer text-primary"
                      icon={faPlus}
                    />
                  </span>
              ) : null}
            </div>
          </div>
          <div
            className=" align-items-center"
            style={{
              minHeight: "fit-content",
              maxHeight: "200px",
              overflowY: "auto",
            }}
          >
            {phoneNumber?.length > 0 ? (
              <>
                {phoneNumber?.map((keyData, index) => {
                  return (
                    
                      <div
                        className="row w-100 py-1 align-items-center "
                        key={uuidv4()}
                      >
                        <div className="col-sm-12 d-flex" key={uuidv4()}>
                          {keyData?.subscribeStatus === "Opt-in" ? (
                              <span
                                className="pe-1"
                                title={keyData?.subscribeStatus}
                              >
                                <FontAwesomeIcon
                                  className="mx-1 text-success"
                                  icon={faCircleCheck}
                                />
                              </span>
                          ) : (
                              <span
                                className="pe-1"
                                title={keyData?.subscribeStatus}
                              >
                                <FontAwesomeIcon
                                  icon={faBan}
                                  className="mx-1 text-danger"
                                />
                              </span>
                          )}
                          <div key={uuidv4()} className="me-1 text-truncate text-dark">
                            {keyData?.text}
                          </div>
                          {/* <CopyToClipboard text={keyData?.text}>
                          <span className="ps-1" title="Click here to copy number" onClick={handleCopyPhoneNumber} >
                            <FontAwesomeIcon
                              className="mx-1 cursor-pointer text-primary"
                              icon={faCopy}
                            />
                          </span>
                        </CopyToClipboard> */}
                        </div>
                      </div>
                    
                  );
                })}
              </>
            ) : (
                <span className="text-dark">
                  <Trans i18nKey="notSpecified" defaults="Not Specified" />
                </span>
            )}
          </div>
        </>
      ) : (
          <React.Suspense fallback={<Loading rows={0} />}>
            <PhoneNumberSessionEdit
            resumeId={resumeId}
            companyID={resume?.companyID}
            clientID={resume?.clientID}
            triggerRef={triggerRef}
            phoneNumber={phoneNumber}
            setHideEmailDetailsView={setHideEmailDetailsView}
            setHidePhoneDetailsView={setHidePhoneDetailsView}
            getData={getData}
            triggerContactDetailsRef={triggerContactDetailsRef}
            setUpdateResumeDataData={setUpdateResumeDataData}
          />
          </React.Suspense>
      )}
    </>
  );
};

export default PhoneNumber;
