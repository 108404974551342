import React from "react";
import PropTypes from "prop-types";
import { v4 as uuidv4 } from "uuid"


const PopOverContent = ({ target, list }) => {
    const openWebSiteLink = (value) => {
        window.open(value)
    }
    return (
        <>
            {target == "email" ? (
                <span>
                    {list?.map(
                        (keyData, index) => {
                            return (
                                <span key={uuidv4()} className="badge  m-1 rounded-pill bd-blue">
                                    {index != 0 ? (keyData?.text) : (<></>)}
                                </span>
                            );

                        }
                    )
                    }
                </span>
            ) : null}
            {target == "phone" ? (
                <span>
                    {list?.map(
                        (keyData, index) => {
                            return (
                                <span key={uuidv4()} className="badge  m-1 rounded-pill bd-blue">
                                    {index != 0 ? (keyData?.text) : (<></>)}
                                </span>
                            );
                        }
                    )
                    }
                </span>
            ) : null}
            {target == "tags" ? (
                <span>
                    {list?.map(
                        (keyData, index) => {
                            return (
                                <span key={uuidv4()} className="badge  m-1 rounded-pill bd-blue">
                                    {index > 2 ? (keyData?.text) : (<></>)}
                                </span>
                            );
                        }
                    )
                    }
                </span>
            ) : null}
            {target == "preferedLocation" ? (
                <span>
                    {list?.map(
                        (keyData, index) => {
                            return (
                                <span key={uuidv4()} className="badge  m-1 rounded-pill bd-blue">
                                    {index >= 1 ? (keyData?.text) : (<></>)}
                                </span>
                            );
                        }
                    )
                    }
                </span>
            ) : null}
            {target == "websites" ? (
                <span>
                    {list?.map(
                        (keyData, index) => {
                            return (
                                <>
                                    {index != 0 ? (<>
                                        <div key={uuidv4()} className="px-1 py-1" style={{ width: "250px" }}>
                                            <span className="d-flex"><b>{keyData.socialMedia}</b>{":"}
                                                <div title={keyData.text} className="text-primary cursor-pointer mx-1 text-decoration-underline text-truncate" onClick={() => openWebSiteLink(keyData.text)}>{keyData.text}</div>
                                            </span>
                                        </div>
                                    </>)
                                        : null}
                                </>
                            )
                        }
                    )
                    }
                </span>
            ) : null
            }
            {
                target == "languagesKnown" ? (
                    <span>
                        {list?.map(
                            (keyData, index) => {
                                return (
                                    <span key={uuidv4()} className="badge  m-1 rounded-pill bd-blue">
                                        {index >= 1 ? (keyData?.text) : (<></>)}
                                    </span>
                                );
                            }
                        )
                        }
                    </span>
                ) : null
            }
            {
                target == "defaultValue" ? (
                    <span>
                        {list?.defaultValue?.map(
                            (keyData, index) => {
                                return (
                                    <span key={uuidv4()} className="">
                                        {index != 0 ? (keyData) : (<></>)}
                                    </span>
                                );
                            }
                        )
                        }
                    </span>
                ) : null
            }
            
            {
                target == "customFieldsMultiSelect" ? (
                    <>
                        {list?.value?.map(
                            (keyData, index) => {
                                return (
                                    <>
                                        {index != 0 ? (
                                            <>
                                                <span key={uuidv4()} title={keyData} className="cursor-pointer  text-truncate px-1" >{keyData}</span>
                                            </>
                                        )
                                            : null}
                                    </>
                                )
                            }
                        )
                        }

                    </>
                ) : null
            }
            {target == "major" ? (
                <span>
                    {list?.map(
                        (keyData, index) => {
                            return (
                                <span key={uuidv4()} className="badge m-1 rounded-pill bd-blue">
                                    {index >= 1 ? (keyData?.text) : (<></>)}
                                </span>
                            );
                        }
                    )
                    }
                </span>
            ) : null}
            {
                target == "preferedRoles" ? (
                    <span>
                        {list?.map(
                            (keyData, index) => {
                                return (
                                    <span key={uuidv4()} className="badge  m-1 rounded-pill bd-blue">
                                        {index >= 1 ? (keyData?.jobTitle) : (<></>)}
                                    </span>
                                );
                            }
                        )
                        }
                    </span>
                ) : null
            }
        </>
    )

}
export default PopOverContent;

PopOverContent.propTypes = {
    target: PropTypes.string,
    list: PropTypes.array,
}
