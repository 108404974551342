import { Container, Navbar, Image } from "react-bootstrap";
import User from "./User";
function NavHeader() {
  return (
    <Navbar expand="lg" className="main-header" sticky="top">
      <Container fluid>
        <div className=" w-100 d-flex justify-content-between align-items-center mx-5">
          <div>
            <Image
              src="https://recruiterqa.maprecruit.com/images/coarthatechnosolutionsIN/logos/552d016291bad07ccbb455fe_logo_MapRecruitLogo-N-3.png"
              className="w-100"
              style={{ maxHeight: "30px" }}
            />
          </div>
          <div>
            <User />
          </div>
        </div>
      </Container>
    </Navbar>
  );
}

export default NavHeader;
