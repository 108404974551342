import React, { useRef, useState, useEffect } from "react";
import { StandaloneSearchBox } from "@react-google-maps/api";
import "./SearchBarStyles.css";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { Trans, useTranslation } from "react-i18next";


export default function LocationKey({ value, setValue, locationObj }) {
  const inputRef = useRef(null);
  const [isFocused, setIsFocused] = useState(true);
  const [predictions, setPredictions] = useState([]);
  const [selectedPredictionIndex, setSelectedPredictionIndex] = useState(0);
  const { t } = useTranslation();
  const location = useLocation();
  // console.log(location);

  // useEffect(() => {
  //   const queryParams = queryString.parse(location.search);
  //   setValue(queryParams.location || "");
  // }, [location.search, setValue]);
  const isMounted = React.useRef(false);

  const Googledata = () => {
    const google = window.google;
    if (!google) return;
    const autocomplete = new google.maps.places.Autocomplete(inputRef.current, {
      types: ["geocode"],
      componentRestrictions: { country: ["US"] }, // Replace 'US' with your desired country code.
      fields: [
        "address_components",
        "geometry",
        "icon",
        "name",
        "formatted_address",
        "place_id",
      ],
    });

    autocomplete.addListener("place_changed", () => {
      const place = autocomplete.getPlace();

      handlePlacesChanged(place);
    });
  };

  useEffect(() => {
    if (!isMounted.current) {
      Googledata();
      isMounted.current = true;
    }
  }, []);

  const handlePlacesChanged = (places) => {
    // console.log(places, "rdtfyguhijooooojiji");
    // const places = inputRef?.current?.getPlaces();
    // if (places && places.length > 0) {
    //   const formattedAddress = places[0].formatted_address;
    //   setValue(formattedAddress);
    //   locationdetails(places[0]);
    // }
    if (places) {
      // const formattedAddress = places?.formatted_address;
      if (places?.formatted_address) {
        setValue(places?.formatted_address);
      }
      locationdetails(places);
    }
  };

  function locationdetails(place) {
    if (place) {
      // console.log(place, "placeee");
      if (place.address_components) {
        //console.log(place.address_components,"12345");
        for (let p in place.address_components) {
          if (place.address_components[p].types.indexOf("locality") !== -1) {
            locationObj.current.city = place.address_components[p].long_name;
          } else if (
            place.address_components[p].types.indexOf(
              "administrative_area_level_1"
            ) !== -1
          ) {
            locationObj.current.state = place.address_components[p].long_name;
          } else if (
            place.address_components[p].types.indexOf("country") !== -1
          ) {
            locationObj.current.country = place.address_components[p].long_name;
          } else if (
            place.address_components[p].types.indexOf("postal_code") !== -1
          ) {
            locationObj.current.zipcode = place.address_components[p].long_name;
          }
        }
        locationObj.current.pin.lat = place.geometry.location.lat();
        locationObj.current.pin.lon = place.geometry.location.lng();
        locationObj.current.text = place.formatted_address;
      }
    }
  }
  const handleChange = (event) => {
    setValue(event.target.value);
    // const service = new window.google.maps.places.AutocompleteService();
    // service.getPlacePredictions(
    //   { input: event.target.value, componentRestrictions: { country: ["US"] } },
    //   (predictions, status) => {
    //     if (status === window.google.maps.places.PlacesServiceStatus.OK) {
    //       setPredictions(predictions);
    //     } else {
    //       setPredictions([]); // Clear predictions if there's an error
    //     }
    //   }
    // );
  };
  // const handleKeyPress = (event) => {
  //   if (event.key === "Enter") {
  //     // Prevent the default form submission behavior
  //     event.preventDefault();
  //     // console.log(predictions, "wqerty6u7");
  //     if (predictions.length > 0) {
  //       if (selectedPredictionIndex === 0) {
  //         // If no prediction is explicitly selected, select the first one
  //         setSelectedPredictionIndex(0);
  //       }

  //       // Select the first prediction
  //       const firstPrediction = predictions[selectedPredictionIndex];
  //       setValue(firstPrediction?.description);
  //       setPredictions([]); // Clear predictions

  //       // Fetch place details for the selected prediction
  //       const service = new window.google.maps.places.PlacesService(
  //         document.createElement("div")
  //       );
  //       service.getDetails(
  //         { placeId: firstPrediction.place_id },
  //         (place, status) => {
  //           if (status === window.google.maps.places.PlacesServiceStatus.OK) {
  //             // Handle the selected place as needed
  //             handlePlacesChanged(place);
  //           }
  //         }
  //       );
  //     } else if (event.key === "ArrowUp") {
  //       // Handle up arrow key press
  //       event.preventDefault();
  //       if (selectedPredictionIndex > 0) {
  //         setSelectedPredictionIndex(selectedPredictionIndex - 1);
  //       }
  //     } else if (event.key === "ArrowDown") {
  //       // Handle down arrow key press
  //       event.preventDefault();
  //       if (selectedPredictionIndex < predictions.length - 1) {
  //         setSelectedPredictionIndex(selectedPredictionIndex + 1);
  //       }
  //     }
  //   }
  // };
  // console.log(selectedPredictionIndex, "selectedPredictionIndex");
  return (
    // <StandaloneSearchBox
    //   onLoad={(ref) => {
    //     inputRef.current = ref;
    //   }}
    //   onPlacesChanged={handlePlacesChanged}
    //   fields={["address_components", "geometry", "icon", "name"]}
    //   options={{ componentRestrictions: { country: ["US"] } }}
    // >
    <input
      ref={inputRef}
      // placeholder="Enter Location"
      placeholder={t("enterLocation", { defaultValue: "Enter location" })}
      size="lg"
      className={`mb-2 mt-2 no-border height width light-placeholder  d-flex justify-content-center align-items-center ${
        isFocused ? "no-border" : ""
      } `}
      value={value}
      onChange={handleChange}
      // onKeyPress={handleKeyPress}
    />
    // </StandaloneSearchBox>
  );
}
