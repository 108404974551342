import React, { useState, useEffect } from "react";
import "../SearchBar.css";
import { Dropdown, Button, Form, FormControl } from "react-bootstrap";
import { Notification, useToaster, Loader } from "rsuite";
import { Trans, useTranslation } from "react-i18next";

export default function WorkTypeAttribute({
  workTypeAttribute,
  getJobs,
  setJobsListData,
  setPage,
  setTotal,
  selectedOptions,
  setSelectedOptions,
  setSearchvalue,
  SearchBodyData,
  isLoading,
  setAttributeData,
  setworktypetags,
  setShowJobDetails,
}) {
  // useEffect(() => {
  //   if (SearchBodyData && SearchBodyData?.filter) {
  //     const workTypeData = SearchBodyData?.filter.find(
  //       (item) => item?.type === "workType"
  //     );

  //     if (
  //       workTypeData &&
  //       workTypeData?.workType &&
  //       workTypeData?.workType.length > 0
  //     ) {
  //       const selectedWorkTypes = workTypeData?.workType;
  //       setSelectedOptions(selectedWorkTypes);
  //     }
  //   }
  // }, [SearchBodyData, setSelectedOptions]);

  /*  console.log(workTypeAttribute,"bbbbbbbbbbbbb") */
  // const [selectedOptions, setSelectedOptions] = useState([]);
  const { t } = useTranslation();
  const [showDropdown, setShowDropdown] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [type, setType] = React.useState("error");
  const toaster = useToaster();
  const [worktypesets, setworktypesets] = useState([]);
  useEffect(() => {
    if (showDropdown) {
      setworktypesets(selectedOptions);
    }
  }, [showDropdown]);
  const handleDropdownSelect = (option) => {
    if (worktypesets.includes(option)) {
      setworktypesets(worktypesets.filter((item) => item !== option));
    } else {
      setworktypesets([...worktypesets, option]);
    }
  };

  const handleResetButtonClick = () => {
    setSelectedOptions([]);
    setworktypesets([]);
    setSearchQuery("");
    const queryString = window.location.search;
    const parameters = new URLSearchParams(queryString);

    let body = {
      size: 6,
      page: 1,
      skip: 0,
      searchID: parameters.get("searchID") || "",
      filter: [
        {
          type: "workType",
          workType: [],
        },
      ],
      newSearch: false,
    };

    getJobs(body)
      .then((res) => {
        setJobsListData(res?.data?.result ?? []);
        setTotal(res?.data?.totalResults ?? 0);
        setAttributeData(res?.data?.attributesAll);
        setShowJobDetails(false);
      })
      .catch((error) => {
        console.error("Error while fetching jobs:", error);
      });

    setPage(1);
    setShowDropdown(false);

    setSearchvalue(0);
    if (parameters.has("page")) {
      parameters.set("page", 1);
    }
    if (parameters.has("index")) {
      parameters.set("index", 1);
    }
    window.history.replaceState(
      {},
      "",
      `${window.location.pathname}?${parameters}`
    );
  };

  const handleApplyButtonClick = () => {
    setSelectedOptions(worktypesets);
    if (worktypesets.length === 0) {
      toaster.push(
        <Notification type={type} header={t("error", { defaultValue: "Error" })} closable>
          {/* <h6>Please select atleast one option in list </h6> */}
          {t("PleaseSelectAtLeastOneOptionInTheList", { defaults: "Please select atleast one option in list" })}
        </Notification>,
        {
          placement: "topEnd",
          duration: 3000,
        }
      );
      return;
    }

    const queryString = window.location.search;
    const parameters = new URLSearchParams(queryString);

    let body = {
      size: 6,
      page: 1,
      skip: 0,
      searchID: parameters.get("searchID"),
      filter: [
        {
          type: "workType",
          workType: worktypesets,
        },
      ],
      newSearch: false,
    };
    getJobs(body)
      .then((res) => {
        setJobsListData(res?.data?.result ?? []);
        setTotal(res?.data?.totalResults ?? 0);
        setAttributeData(res?.data?.attributesAll);
        setShowJobDetails(false);
      })
      .catch((error) => {
        console.error("Error while fetching jobs:", error);
      });

    setPage(1);
    setShowDropdown(false);
    setSearchvalue(0);
    if (parameters.has("page")) {
      parameters.set("page", 1);
    }
    if (parameters.has("index")) {
      parameters.set("index", 1);
    }
    window.history.replaceState(
      {},
      "",
      `${window.location.pathname}?${parameters}`
    );
  };

  /*  const filteredOptions = workTypeAttribute?.filter((option) =>
     option?.key.toLowerCase().includes(searchQuery.toLowerCase())
   ); */

  const filteredOptions = workTypeAttribute
    ?.map((option) => {
      const formattedKey = option?.key
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
      return { ...option, formattedKey };
    })
    ?.filter((option) =>
      option?.formattedKey.toLowerCase().includes(searchQuery.toLowerCase())
    );

  // const filteredOptions = workTypeAttribute
  //   ?.map((option) => {
  //     const formattedKey = option?.key
  //       .split(" ")
  //       .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
  //       .join(" ");
  //     return { ...option, formattedKey };
  //   })
  //   ?.filter((option) =>
  //     option?.formattedKey.toLowerCase().includes(searchQuery.toLowerCase())
  //   );

  return (
    <>
      <Dropdown
        show={showDropdown}
        onToggle={(isOpen) => {setShowDropdown(isOpen); setSearchQuery("")}}
        className="position-static"
      >
        <Dropdown.Toggle
          variant="none"
          id="dropdown-basic"
          className="  attributeStyles text-left text-muted  rounded-pill"
          size="md"
        >
          {/* Work type */}
          
          <Trans i18nKey="workType" defaults="Work type"/>
        </Dropdown.Toggle>

        <Dropdown.Menu className="dropdown-menu-custom">
          <FormControl
            type="text"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            // placeholder="Search work type.."
            placeholder={t("searchWorkType", { defaultValue: "Search Work Type" })}
            className="mx-2 mb-2 atributesSearch mx-auto"
          />

          <div style={{ maxHeight: "200px", overflowY: "auto" }}>
            <Form className="dropdown-form">
              <div className="scrollable-options">
                {filteredOptions?.map((option) => (
                  <div key={option?.key} className="ms-3">
                    <Form.Check
                      className="custom-checkbox"
                      type="checkbox"
                      id={option?.key}
                      label={`${option?.formattedKey} (${option?.doc_count})`}
                      checked={worktypesets.includes(option?.key)}
                      onChange={() => handleDropdownSelect(option?.key)}
                    />
                  </div>
                ))}
              </div>
            </Form>
          </div>
          <Dropdown.Divider />
          <div className="d-flex justify-content-between ">
            {isLoading ? (
              <Loader className="justify-content-center text-center d-flex ms-4 reset-button" />
            ) : (
              <button
                type="button"
                className="justify-content-center btn btn-light text-center d-flex ms-3 reset-button"
                onClick={handleResetButtonClick}
              >
              <Trans i18nKey="reset" defaults="Reset" />
              </button>
            )}
            {isLoading ? (
              <Loader className="justify-content-center text-center d-flex me-4 reset-button" />
            ) : (
              <Button
                variant="primary"
                onClick={handleApplyButtonClick}
                className="justify-content-center text-center d-flex me-3"
              >
              <Trans i18nKey="apply" defaults="Apply" />
              </Button>
            )}
          </div>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
}

{
  /*  <Form.Check
                      className="custom-checkbox" // Apply your custom class here
                      type="checkbox"
                      id={option?.key}
                      checked={worktypesets.includes(option?.key)}
                      onChange={() => handleDropdownSelect(option?.key)}
                    >
                      <label className="custom-checkbox-label">{`${option?.formattedKey} (${option?.doc_count})`}</label>
                    </Form.Check> */
}
