import * as React from "react";
import * as ReactDOM from "react-dom/client";
import { I18nextProvider } from "react-i18next";
// import { store } from "./app/store";
// import { Provider } from "react-redux";

// import { i18nInit } from "./i18nconfig";
import i18next from "i18next";
import { socket, SocketContext } from "./app/context/SocketContext";
// import { ThemeContext } from "./app/context/ThemeContext";

import Backend from "i18next-http-backend";
import {
  createBrowserRouter,
  matchRoutes,
  RouterProvider,
} from "react-router-dom";

// import { initLocale, i18n } from "./i18nconfig";
// import { LoadScript } from "@react-google-maps/api";

import { routes } from "./App";

hydrate();

async function hydrate() {
  // Initialize i18next
  i18next.use(Backend).init({
    lng: "en",
    fallbackLng: "en",
    // preload: ["en", "fr"],
    backend: {
      loadPath: "/careers/getKeys?lng={{lng}}&ns={{ns}}",
    },
  });



  // Determine if any of the initial routes are lazy
  let lazyMatches = matchRoutes(routes, window.location)?.filter(
    (m) => m.route.lazy
  );

  // Load the lazy matches and update the routes before creating your router
  // so we can hydrate the SSR-rendered content synchronously
  if (lazyMatches && lazyMatches?.length > 0) {
    await Promise.all(
      lazyMatches.map(async (m) => {
        let routeModule = await m.route.lazy();
        Object.assign(m.route, { ...routeModule, lazy: undefined });
      })
    );
  }

  let router = createBrowserRouter(routes);
  // const googleAutoCompleteKey = import.meta.env
  //   .VITE_GOOGLE_AUTO_COMPLETE_API_KEY;
  //   console.log(googleAutoCompleteKey, "jnjfnj");

  // let translateKeys = {
  //   "en": {
  //     "translation": {
  //       "hello": "Hello 3",
  //     "jobDescription" : "Job Description 5"
  //     }
  //   }
  // }
  // await initLocale("en",translateKeys);

  ReactDOM.hydrateRoot(
    document.getElementById("app"),
    <React.StrictMode>
      {/* <Provider store={store}> */}
      <SocketContext.Provider value={socket}>
        <I18nextProvider
          i18n={
            i18next
            
          }
        >
          {/* <LoadScript
            googleMapsApiKey={googleAutoCompleteKey}
            libraries={["places"]}
          > */}
          {/* <ThemeContext.Provider value={ThemeVariables}> */}
            <RouterProvider router={router} fallbackElement={null} />
          {/* </ThemeContext.Provider> */}
          {/* </LoadScript> */}
        </I18nextProvider>
      </SocketContext.Provider>
      {/* </Provider> */}
    </React.StrictMode>
  );
}
