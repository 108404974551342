import React, { useEffect, useContext } from "react";
import { Button, Form, Modal, Notification, toaster } from "rsuite";
import ReferenceModal from "./ReferenceModal";
import { useForm, useFieldArray } from "react-hook-form";
import { PropTypes } from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle, faMinusCircle } from "@fortawesome/free-solid-svg-icons";
import {
  isValidPhoneNumber,
  parsePhoneNumber,
  formatPhoneNumberIntl,
  formatPhoneNumber,
} from "react-phone-number-input";
import { DateTime } from "luxon";
import usePost from "../../../../customhooks/UsePost";
import { Trans, useTranslation } from "react-i18next";
// import { t } from "i18next";
import UserContext from "../../../../app/context/UserContext";

const ReferenceSessionEdit = ({
  open,
  setOpen,
  control,
  register,
  clearErrors,
  setValue,
  setError,
  handleSubmit,
  errors,
  getValues,
  referenceRefetch,
  referenceList,
  referenceRowsList,
  resumeId,
  companyID,
  clientID,
  standardFieldsList,
  setUpdateResumeDataData,
}) => {
  const handleReferenceClose = () => setOpen(false);
  const { t } = useTranslation();

  const { post: updateResumeDetails, isLoading: UpdateResumeDataDataLoading } =
    usePost("/user/Updateresumedeatails");
  const { user, setUserDetails } = useContext(UserContext);

  const validateEmail = (email) => {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  };
  const submitReferenceDetails = () => {
    let referenceData = getValues();
    let phoneNuberflag = true;
    let emailCheckFlag = true;
    let referenceDetails = [];
    let PhoneNumberList = [];
    let emailList = [];
    let dateCheck = true;
    let fullNamecheckFlag = true;
    if (referenceData?.reference?.length > 0) {
      for (let i in referenceData?.reference) {
        let referenceObj = {
          fullName: "",
          email: [],
          phone: [],
          comment: "",
          company: "",
          location: "",
          locationObJ: {},
          jobTitle: "",
          review: "",
          startDate: "",
          endDate: "",
        };
        if (referenceData?.reference[i]?.phone.length > 0) {
          PhoneNumberList = [];
          for (let j in referenceData?.reference[i].phone) {
            if (
              referenceData?.reference[i].phone[j]?.text &&
              referenceData?.reference[i].phone[j].text != ""
            ) {
              if (
                !isValidPhoneNumber(referenceData?.reference[i].phone[j].text)
              ) {
                phoneNuberflag = false;
                setError(
                  `reference[${i}].phone[${j}].text`,
                  {
                    type: "manual",
                    message: "Please enter valid phone number",
                  },
                  { shouldFocus: true }
                );
              } else {
                phoneNuberflag = phoneNuberflag ? true : false;

                PhoneNumberList.push(
                  parsePhoneNumber(referenceData?.reference[i].phone[j].text)
                );
              }
            }
            // else {
            //   phoneNuberflag = false;
            //   setError(
            //     `reference[${i}].phone[${j}].text`,
            //     {
            //       type: "manual",
            //       message: "Please enter  phone number or email",
            //     },
            //     { shouldFocus: true }
            //   );
            // }
            // if (phoneNuberflag) {
            //   if (emailList?.length <= 0) {
            //     clearErrors(`reference[${i}].email[]`);

            //   }

            // }
          }
          const uniquePhoneNumbers = new Set(
            PhoneNumberList.map((phone) => phone.number)
          );
          if (uniquePhoneNumbers.size !== PhoneNumberList?.length) {
            let lastDuplicateIndex = -1;
            for (let j = 0; j < PhoneNumberList.length; j++) {
              for (let k = j + 1; k < PhoneNumberList.length; k++) {
                if (PhoneNumberList[j].text === PhoneNumberList[k].text) {
                  lastDuplicateIndex = k;
                  emailCheckFlag = false;
                }
              }
            }
            for (let j = 0; j < PhoneNumberList.length; j++) {
              if (j !== lastDuplicateIndex) {
                clearErrors(`reference[${i}].phone[${j}].text`);
              }
            }
            // console.log("ajsdaksjbdas", lastDuplicateIndex)
            if (lastDuplicateIndex !== -1) {
              phoneNuberflag = false;
              setError(
                `reference[${i}].phone[${lastDuplicateIndex}].text`,
                {
                  type: "manual",
                  message: "Phone number already exists",
                },
                { shouldFocus: true }
              );
            }
          }
        }
        if (referenceData?.reference[i].email.length > 0) {
          emailList = [];
          for (let j in referenceData?.reference[i].email) {
            if (
              referenceData?.reference[i].email[j].text &&
              referenceData?.reference[i].email[j].text != ""
            ) {
              if (!validateEmail(referenceData?.reference[i].email[j].text)) {
                emailCheckFlag = false;

                setError(
                  `reference[${i}].email[${j}].text`,
                  {
                    type: "manual",
                    message: "Please enter valid email",
                  },
                  { shouldFocus: true }
                );
              } else {
                emailCheckFlag = emailCheckFlag ? true : false;
                emailList.push(referenceData?.reference[i].email[j].text);
              }
            }
            // else {
            //   if (PhoneNumberList?.length > 0) {
            //     emailCheckFlag = true;

            //   }
            //   else {
            //     emailCheckFlag = false;
            //     setError(
            //       `reference[${i}].email[${j}].text`,
            //       {
            //         type: "manual",
            //         message: "Please enter email or phone number",
            //       },
            //       { shouldFocus: true }
            //     );
            //   }

            // }
            // if (emailCheckFlag) {
            //   if (PhoneNumberList?.length <= 0) {
            //     clearErrors(`reference[${i}].phone[]`);

            //   }

            // }
          }
          const uniqueEmails = new Set(emailList);
          if (uniqueEmails?.size !== emailList?.length) {
            let lastDuplicateIndex = -1;
            for (let k = 0; k < emailList?.length; k++) {
              for (let j = k + 1; j < emailList?.length; j++) {
                if (emailList[j]?.text === emailList[k]?.text) {
                  lastDuplicateIndex = j;
                  emailCheckFlag = false;
                }
              }
            }
            for (let k = 0; k < emailList?.length; k++) {
              if (k !== lastDuplicateIndex) {
                clearErrors(`reference[${i}].email[${k}].text`);
              }
            }
            setError(
              `reference[${i}].email[${lastDuplicateIndex}].text`,
              {
                type: "manual",
                message: "Email already exists",
              },
              { shouldFocus: true }
            );
          }
        }

        if (emailList?.length > 0) {
          if (PhoneNumberList?.length <= 0) {
            phoneNuberflag = true;
            clearErrors(`reference[${i}].phone[]`);
          }
        }
        if (PhoneNumberList?.length > 0) {
          if (emailList?.length <= 0) {
            emailCheckFlag = true;
            clearErrors(`reference[${i}].email[]`);
          }
        }

        if (
          DateTime.fromJSDate(
            referenceData?.reference[i].startDate
          ).toMillis() >
          DateTime.fromJSDate(referenceData?.reference[i].endDate).toMillis()
        ) {
          dateCheck = false;
          setError(
            `reference[${i}].endDate`,
            {
              type: "manual",
              message: "End date should be greater than start date",
            },
            { shouldFocus: true }
          );
        } else {
          dateCheck = dateCheck ? true : false;
        }

        // if (referenceData?.reference[i].fullName == "") {
        //   fullNamecheckFlag = false
        //   setError(
        //     `reference[${i}].fullName`,
        //     {
        //       type: "manual",
        //       message: "Fullname is required",
        //     },
        //     { shouldFocus: true }
        //   );
        // }
        if (referenceData?.reference[i].fullName) {
          referenceObj.fullName = referenceData?.reference[i].fullName;
        }
        if (referenceData?.reference[i].company) {
          referenceObj.company = referenceData?.reference[i].company;
        }
        if (referenceData?.reference[i].location) {
          referenceObj.location = referenceData?.reference[i].location;
        }
        if (referenceData?.reference[i].jobTitle) {
          referenceObj.jobTitle = referenceData?.reference[i].jobTitle;
        }
        if (referenceData?.reference[i].review) {
          referenceObj.review = referenceData?.reference[i].review;
        }
        if (referenceData?.reference[i]?.startDate == "") {
          referenceObj.startDate = "";
        } else {
          referenceObj.startDate = DateTime.fromJSDate(
            referenceData?.reference[i].startDate
          ).toFormat("yyyy-MM-dd");
        }
        if (referenceData?.reference[i]?.endDate == "") {
          referenceObj.endDate = "";
        } else {
          referenceObj.endDate = DateTime.fromJSDate(
            referenceData?.reference[i].endDate
          ).toFormat("yyyy-MM-dd");
        }
        if (referenceData?.reference[i].comment) {
          referenceObj.comment = referenceData?.reference[i].comment;
        }
        if (referenceData?.reference[i].locationObJ) {
          referenceObj.locationObJ = referenceData?.reference[i].locationObJ;
        }
        if (referenceData?.reference[i].phone) {
          referenceObj.phone = PhoneNumberList;
        }
        if (referenceData?.reference[i].email) {
          referenceObj.email = emailList;
        }
        referenceDetails.push(referenceObj);
      }
      if (phoneNuberflag && emailCheckFlag && dateCheck && fullNamecheckFlag) {
        clearErrors();
        updateResumeDetails({
          target: "reference",
          user: user,
          resumeID: resumeId,
          companyID: companyID,
          clientID: clientID,
          getValues: {
            referenceDetails: referenceDetails,
            sectionName: standardFieldsList?.sectionName
              ? standardFieldsList?.sectionName
              : "Reference",
          },
          type:"Profile Edited"
        })
          .then((res) => {
            if (res) {
              setUpdateResumeDataData(res);
              handleReferenceClose();
            }
          })
          .catch((error) => {
            console.error("Error while fetching Resume:", error);
          });
      }
    } else {
      updateResumeDetails({
        target: "reference",
        resumeID: resumeId,
        companyID: companyID,
        user: user,
        clientID: clientID,
        getValues: {
          referenceDetails: [],
          sectionName: standardFieldsList?.sectionName
            ? standardFieldsList?.sectionName
            : "Reference",
        },
        type:"Profile Edited"
      })
        .then((res) => {
          if (res) {
            setUpdateResumeDataData(res);
            handleReferenceClose();
          }
        })
        .catch((error) => {
          console.error("Error while fetching Resume:", error);
        });
    }
  };

  return (
    <>
      <Modal size={"lg"} open={open} onClose={handleReferenceClose}>
        <Modal.Header>
          <Modal.Title>
            {t(standardFieldsList?.i18nKey, {
              defaultValue: standardFieldsList?.sectionName,
            })}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row w-100">
            <div className="col-sm-12 col-md-12">
              <ReferenceModal
                control={control}
                register={register}
                clearErrors={clearErrors}
                setValue={setValue}
                errors={errors}
                setError={setError}
                getValues={getValues}
                referenceList={referenceList}
                referenceRowsList={referenceRowsList}
                handleSubmit={handleSubmit}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="row mt-3">
            <div className="col-md-12 col-sm-12 d-flex justify-content-between">
              <button
                className="btn btn-sm btn-light"
                onClick={handleReferenceClose}
              >
                <Trans i18nKey="cancel" defaults="Cancel" />
              </button>
              <button
                onClick={handleSubmit(submitReferenceDetails)}
                disabled={UpdateResumeDataDataLoading}
                className="btn btn-sm btn-primary"
              >
                {UpdateResumeDataDataLoading && (
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                )}
                <span className="px-1">
                  <Trans i18nKey="save" defaults="Save" />
                </span>
              </button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ReferenceSessionEdit;
