import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLocationDot,
  faBriefcase,
  faMoneyBills,
} from "@fortawesome/free-solid-svg-icons";
import React, { useEffect, useRef, useState } from "react";
import JobDetails from "./JobDetails";
import { useParams } from "react-router-dom";
import queryString from "query-string";
import "./JobList.css";
import { Placeholder } from "rsuite";
import parse from "html-react-parser";
import { DateTime } from "luxon";
import { useNavigate } from "react-router-dom";
// import { use } from "i18next";
import { Trans, useTranslation } from "react-i18next";

export default function JobsList({
  data,
  dataindex,
  Searchvalue,
  setSearchvalue,
  isLoading,
  type,
  selectedMobileIndex,
  //handleMobileJobSelection,
}) {
  const { t } = useTranslation();
  // console.log(data =="");
  // if (typeof window !== "undefined") {
  //   const { search } = window.location;
  //   const params = new URLSearchParams(search);
  //   if (data == "") {
  //     params.delete("index");
  //   } else {
  //     params.set("index", Searchvalue + 1);
  //   }
  //   window.history.replaceState({}, "", `${window.location.pathname}?${params}`);
  // }
  const handleJobDetails = (e) => {
    if (typeof window !== "undefined") {
      const { search } = window.location;
      const params = new URLSearchParams(search);
      if (params.has("index")) {
        params.delete("index");
      }
      params.set("index", e + 1);
      // window.location.search = queryString.stringify(params);
      window.history.replaceState(
        {},
        "",
        `${window.location.pathname}?${params}`
      );
    }
  };
  const navigate = useNavigate();
  useEffect(() => {
    if (typeof window !== "undefined" && type !== "jobdetails") {
      if (window.location.search.includes("index")) {
        const { search } = window.location;
        const params = new URLSearchParams(search);
        if (params.has("index")) {
          setSearchvalue(parseInt(params.get("index")) - 1);
        }
        // params.set("index", parseInt(params.get("index")));
        window.history.replaceState(
          {},
          "",
          `${window.location.pathname}?${params}`
        );
      }
    }
  }, [Searchvalue]);
  // console.log(index);

  /* const renderParagraphs = (htmlText) => {
    const paragraphs = htmlText.split('\n').map((paragraph, index) => (
      <p key={index}>{paragraph}</p>
    ));
    return paragraphs;
  }; */

  const [newDescription, setNewDescription] = useState("");

  useEffect(() => {
    if (data?._source?.job?.details?.jobDescription?.text) {
      const tempDiv = document.createElement("div");
      tempDiv.innerHTML = data._source.job.details.jobDescription.text;
      const tempText = tempDiv.textContent.replaceAll("\n", " ");
      setNewDescription(tempText);
    } else {
      setNewDescription("NA");
    }
  }, [data?._source?.job?.details?.jobDescription?.text]);
  const [Loactionreslut, setLocationreslut] = useState("NA");

  useEffect(() => {
    let objectWithNonEmptyCity = null;
    for (let i = 0; i < data?._source?.job?.details?.locations.length; i++) {
      const location = data?._source?.job?.details?.locations[i];
      if (location.city && location.city.trim() !== "") {
        objectWithNonEmptyCity = location;
        break;
      }
    }
    if (objectWithNonEmptyCity !== null) {
      setLocationreslut(objectWithNonEmptyCity);
    } else {
      setLocationreslut("NA");
    }
  }, [Loactionreslut, data]);
  // console.log(Loactionreslut);
  return (
    <div className="pt-3 ">
      {isLoading ? (
        <div className="card">
          <div className="card-body">
            <Placeholder.Grid rows={2} columns={2} active />
          </div>
        </div>
      ) : (
        <div
          className={
            "card  cardBackground Jobcaards " +
            (dataindex == Searchvalue ? "jobActive bg-white " : "")
          }
          role="button"
          onClick={() => {
            if (type !== "jobdetails") {
              handleJobDetails(dataindex);
              setSearchvalue(dataindex);
            }
          }}
        >
          <div className="card-body">
            <h5 className=" text-capitalize titleColor">
              {" "}
              {/* text-primary */}
              {/* <b>
                {data?._source?.title ? data._source.title : "NA"}
              </b> */}
              {data?._source?.displayName ? (
                <>
                  {data?._source?.displayName == "Campaign Title" ? (
                    <b>{data?._source?.title ?? "unkown"}</b>
                  ) : (
                    <>
                      {data?._source?.job.details?.jobTitle?.text ?? "unkown"}
                    </>
                  )}
                </>
              ) : (
                <b>{data?._source?.title ?? "unkown"}</b>
              )}
            </h5>
            <div className="my-2">
              <i className="fa-solid fa-location-dot pe-2 text-muted"></i>

              {/* <FontAwesomeIcon icon={faLocationDot} className="pe-2 text-muted" /> */}
              <span className="text-capitalize ">
                {/* {data?._source?.job?.details?.locations?.[0]?.text
                  ? data?._source?.job?.details?.locations?.[0]?.text
                  : "NA"} */}
                {/* {Loactionreslut?.city ? (
                  <>
                    {Loactionreslut?.city}, {Loactionreslut?.state}
                  </>
                ) : (
                  "NA"
                )} */}
                  {Loactionreslut?.cityCanonical ? (
                          <>
                            {Loactionreslut?.cityCanonical},{" "}
                            {Loactionreslut?.stateCanonical
                              ? Loactionreslut?.stateCanonical
                              : Loactionreslut?.state}
                          </>
                        ) : Loactionreslut?.city ? (
                          <>
                            {Loactionreslut?.city},{" "}
                            {Loactionreslut?.stateCanonical
                              ? Loactionreslut?.stateCanonical
                              : Loactionreslut?.state}{" "}
                          </>
                        ) : (
                          "NA"
                        )}
              </span>
            </div>
            <div className="my-2">
              <i className="fa-solid fa-briefcase pe-2 text-muted"></i>

              {/* <FontAwesomeIcon icon={faBriefcase} className="pe-2 text-muted" /> */}
              <span className="text-capitalize ">
                {data?._source?.job?.details?.jobTitle?.jobType
                  ? data?._source?.job?.details?.jobTitle?.jobType
                  : "NA"}
              </span>
            </div>
            <div className="my-2">
              <i className="fa-solid fa-money-bills pe-2 text-muted"></i>
              {/* <FontAwesomeIcon icon={faMoneyBills} className="pe-2 text-muted" /> */}
              <span>
                $
                <span className="pe-1 ">
                  {data?._source?.job?.details?.offeredSalary?.minvalue ==
                  data?._source?.job?.details?.offeredSalary?.maxvalue ? (
                    <>
                      {data?._source?.job?.details?.offeredSalary?.minvalue ??
                        "0"}
                    </>
                  ) : (
                    <>
                      {data?._source?.job?.details?.offeredSalary?.minvalue
                        ? data?._source?.job?.details?.offeredSalary?.minvalue
                        : "0"}
                      -
                      {data?._source?.job?.details?.offeredSalary?.maxvalue
                        ? data?._source?.job?.details?.offeredSalary?.maxvalue
                        : "0"}
                    </>
                  )}
                  {/* {data?._source?.job?.details?.offeredSalary?.minvalue
                    ? data?._source?.job?.details?.offeredSalary?.minvalue
                    : "0"}
                  -
                  {data?._source?.job?.details?.offeredSalary?.maxvalue
                    ? data?._source?.job?.details?.offeredSalary?.maxvalue
                    : "0"} */}
                </span>
                <span>
                  {data?._source?.job?.details?.offeredSalary?.period
                    ? data?._source?.job?.details?.offeredSalary?.period
                    : "NA"}
                </span>
              </span>
            </div>
            <div className="my-2">
              <i className="fa-solid fa-bullhorn pe-2 text-muted"></i>
              <span>
                {data?._source?.passcode ? data?._source?.passcode : "NA"}
              </span>
            </div>
            <div>
              <p className="descriptionlines ">
                {newDescription}
                {/*  {data?._source?.job?.details?.jobDescription?.text
                  ? data?._source?.job?.details?.jobDescription?.text.replace(
                      /<[^>]+>/g,
                      ""
                    )
                  : "NA"} */}
                {/* <div dangerouslySetInnerHTML={{ __html: (data?._source?.job?.details?.jobDescription?.text) || "NA" }}></div> */}
              </p>
            </div>
            <div>
              <div>
              {/* <>
              <Trans i18nKey="posted" defaults="Posted"/>
             </> */}
               <span>{t("posted", { defaultValue: "Posted" })}</span>
                {data?._source?.createdAt ? (
                  <span className="ps-1">
                    {data?._source?.createdAt}
                    {/* {DateTime.fromJSDate(new Date(data?._source?.createdAt))
                      .toUTC()
                      .toRelative()} */}
                  </span>
                ) : (
                  " NA"
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
