import axios from "axios";
import { DateTime } from "luxon";
import axiosRetry from "axios-retry";

// Get UTC time in milliseconds
const pageOpenTime = DateTime.utc().toMillis();

// Set the base URL conditionally for client-side only
if (typeof window !== "undefined") {
  axios.defaults.baseURL = window.location.origin + "/jsv4/webapi/";
}

// Set other Axios defaults
axios.defaults.withCredentials = true;
axios.defaults.timeout = 120000; // network timeout for all requests in milliseconds

// Add page open time to all requests
axios.interceptors.request.use((config) => {
  config.headers["X-Page-Open-Time"] = pageOpenTime;
  return config;
});

// Set up retry mechanism with exponential backoff
axiosRetry(axios, { retries: 3, retryDelay: axiosRetry.exponentialDelay });

// Error handling and response interceptors
if (typeof window !== "undefined") {
  if (
    window.location.hostname !== "localhost" &&
    (process.env.NODE_ENV !== "local" || process.env.NODE_ENV !== "development")
  ) {
    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (
          error.response &&
          error.response.status === 500 &&
          error.response.headers["x-error-type"] === "timing-discrepancy"
        ) {
          // Handle timing discrepancy by reloading the page
          window.location.reload(true);
        }

        try {
          if (
            error.response &&
            (error.response.status === 500 || error.response.status === 504) &&
            error.response.headers["x-error-type"] !== "timing-discrepancy"
          ) {
            console.log(
              `Got ${error.response.status} from server for ${
                error.response.config.url
              } with ${error.response.config.method} method and ${
                error.response.config.data || "{}"
              } data`
            );
            axios.post("/logError", {
              error: `Got ${error.response.status} from server for ${
                error.response.config.url
              } with ${error.response.config.method} method and ${
                error.response.config.data || "{}"
              } data`,
            });
          }
        } catch (e) {
          console.log("error from axios", e);
        }

        console.log("axios", error);
        return Promise.reject(error);
      }
    );
  }
}

export default axios;
