import { Loader } from "rsuite";
import React, { useState, useContext } from "react";
import { Button } from "react-bootstrap";
const ReferFriendModal = React.lazy(() => import("./ReferFriendModal"));
import { Trans, useTranslation } from "react-i18next";
import UserContext from "../../app/context/UserContext";
const ApplyNowModal = React.lazy(() => import("./ApplyNowModal"));

function ReferFriend({ campaignID }) {
  const { t } = useTranslation()
  const [openRefer, setOpenRefer] = useState(false);
  const [openLogin, setOpenLogin] = useState(false)
  const { user } = useContext(UserContext);

  function handleReferFriend() {
    if (user) {
      // console.log("user present : ", user);
      setOpenRefer(true);
    } else {
      // console.log("no user");
      setOpenLogin(true)
    }
  }

  return (
    <>
      <Button
        variant="primary"
        onClick={() => handleReferFriend()}
      >
        {/* {t("referAFriend", { defaultValue: "Refer a Friend" })} */}
        <Trans i18nKey="referAFriend" defaults="Refer a Friend"/>
      </Button>

      {openRefer && (
        <React.Suspense
          fallback={<Loader center speed="normal" size="sm" backdrop={true} />}
        >
          <ReferFriendModal
            open={openRefer}
            setOpen={setOpenRefer}
            campaignID={campaignID}
            user={user}
          />
        </React.Suspense>
      )}

      {openLogin && (
        <React.Suspense
          fallback={<Loader center speed="normal" size="sm" backdrop={true} />}
        >
          <ApplyNowModal
            openModal={openLogin}
            setOpenModal={setOpenLogin}
            from={"referFriend"}
            setOpenRefer={setOpenRefer}
          />
        </React.Suspense>
      )}
    </>
  );
}

export default ReferFriend;
