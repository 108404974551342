let campaignLanguagesList = [{
    language: 'Afrikaans',
    languageCode: 'af'
}, {
    language: 'Albanian',
    languageCode: 'sq'
}, {
    language: 'Amharic',
    languageCode: '	am'
}, {
    language: 'Arabic',
    languageCode: 'ar'
}, {
    language: 'Azerbaijani',
    languageCode: 'az'
}, {
    language: 'Basque',
    languageCode: 'eu'
}, {
    language: 'Belarusian',
    languageCode: 'be'
}, {
    language: 'Bengali',
    languageCode: 'bn'
}, {
    language: 'Bosnian',
    languageCode: 'bs'
}, {
    language: 'Bulgarian',
    languageCode: 'bg'
}, {
    language: 'Catalan',
    languageCode: 'ca'
}, {
    language: 'Cebuano',
    languageCode: 'ca'
}, {
    language: 'Chinese (Simplified)',
    languageCode: 'zh-Hans'
}, {
    language: 'Chinese (Traditional)',
    languageCode: 'zh-Hant'
}, {
    language: 'Corsican',
    languageCode: 'co'
}, {
    language: 'Croatian',
    languageCode: 'hr'
}, {
    language: 'Czech',
    languageCode: 'cs'
}, {
    language: 'Danish',
    languageCode: 'da'
}, {
    language: 'Dutch',
    languageCode: 'nl'
}, {
    language: 'English',
    languageCode: 'en'
}, {
    language: 'Esperanto',
    languageCode: 'eo'
}, {
    language: 'Estonian',
    languageCode: 'et'
}, {
    language: 'Finnish',
    languageCode: 'fi'
}, {
    language: 'French',
    languageCode: 'fr'
}, {
    language: 'Frisian',
    languageCode: 'fy'
}, {
    language: 'Galician',
    languageCode: 'gl'
}, {
    language: 'Georgian',
    languageCode: 'ka'
}, {
    language: 'German',
    languageCode: 'de'
}, {
    language: 'Greek',
    languageCode: 'el'
}, {
    language: 'Gujarati',
    languageCode: 'gu'
}, {
    language: 'Haitian Creole',
    languageCode: 'ht'
}, {
    language: 'Hausa',
    languageCode: 'ha'
}, {
    language: 'Hebrew',
    languageCode: 'he'
}, {
    language: 'Hindi',
    languageCode: 'hi'
}, {
    language: 'Hungarian',
    languageCode: 'hu'
}, {
    language: 'Icelandic',
    languageCode: 'is'
}, {
    language: 'Igbo',
    languageCode: 'ig'
}, {
    language: 'Indonesian',
    languageCode: 'id'
}, {
    language: 'Irish',
    languageCode: 'ga'
}, {
    language: 'Italian',
    languageCode: 'it'
}, {
    language: 'Japanese',
    languageCode: 'ja'
}, {
    language: 'Javanese',
    languageCode: 'jv'
}, {
    language: 'Kannada',
    languageCode: 'kn'
}, {
    language: 'Kazakh',
    languageCode: 'kk'
}, {
    language: 'Khmer',
    languageCode: 'km'
}, {
    language: 'Korean',
    languageCode: 'ko'
}, {
    language: 'Kurdish',
    languageCode: 'ku'
}, {
    language: 'Kyrgyz',
    languageCode: 'ky'
}, {
    language: 'Lao',
    languageCode: 'lo'
}, {
    language: 'Latin',
    languageCode: 'la'
}, {
    language: 'Latvian',
    languageCode: 'lv'
}, {
    language: 'Lithuanian',
    languageCode: 'lt'
}, {
    language: 'Luxembourgish',
    languageCode: 'lb'
}, {
    language: 'Macedonian',
    languageCode: 'mk'
}, {
    language: 'Malagasy',
    languageCode: 'mg'
}, {
    language: 'Malay',
    languageCode: 'ms'
}, {
    language: 'Malayalam',
    languageCode: 'ml'
}, {
    language: 'Maltese',
    languageCode: 'mt'
}, {
    language: 'Maori',
    languageCode: 'mi'
}, {
    language: 'Marathi',
    languageCode: 'mr'
}, {
    language: 'Mongolian',
    languageCode: 'mn'
}, {
    language: 'Myanmar (Burmese)',
    languageCode: 'my'
}, {
    language: 'Nepali',
    languageCode: '	ne'
}, {
    language: 'Norwegian',
    languageCode: 'no'
}, {
    language: 'Nyanja',
    languageCode: 'ny'
}, {
    language: 'Samoan',
    languageCode: 'sm'
}, {
    language: 'Scots Gaelic',
    languageCode: 'gd'
}, {
    language: 'Serbian',
    languageCode: 'sr'
}, {
    language: 'Sesotho',
    languageCode: 'st'
}, {
    language: 'Shona',
    languageCode: 'sn'
}, {
    language: 'Sindhi',
    languageCode: 'sd'
}, {
    language: 'Sinhala (Sinhalese)',
    languageCode: 'si'
}, {
    language: 'Slovak',
    languageCode: 'sk'
}, {
    language: 'Slovenian',
    languageCode: 'sl'
}, {
    language: 'Telugu',
    languageCode: 'te'
},
{
    language: 'Turkish',
    languageCode: 'tr'
}, {
    language: 'Ukrainian',
    languageCode: 'uk'
}, {
    language: 'Urdu',
    languageCode: 'ur'
}, {
    language: 'Uzbek',
    languageCode: 'uz'
}, {
    language: 'Vietnamese',
    languageCode: 'vi'
}, {
    language: 'Welsh',
    languageCode: 'cy'
}, {
    language: 'Xhosa',
    languageCode: 'xh'
}, {
    language: 'Yiddish',
    languageCode: 'yi'
}, {
    language: 'Yoruba',
    languageCode: 'yo'
}, {
    language: 'Zulu',
    languageCode: 'zu'
}];

export default campaignLanguagesList;