import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Trans,useTranslation } from 'react-i18next';
import React, { useEffect ,useContext} from 'react';
import PhoneNumberForm from './PhoneNumberForm';
import { PropTypes } from "prop-types"
import Loading from '../../../../Loder';
import usePost from '../../../../customhooks/UsePost';
import { useForm } from 'react-hook-form';
import { parsePhoneNumber, isValidPhoneNumber } from 'react-phone-number-input';
import countryNames from "react-phone-number-input/locale/en";
import UserContext from '../../../../app/context/UserContext';

const PhoneNumberSessionEdit = ({ resumeId,companyID,clientID, phoneNumber, setHideEmailDetailsView, setHidePhoneDetailsView,getData,triggerContactDetailsRef, setUpdateResumeDataData}) => {
    const {
        control,
        register,
        setValue,
        handleSubmit,
        formState: { errors },
        getValues,
        setError,
        clearErrors
    } = useForm();
    const { t } = useTranslation();
    const {
        post: updateResumeDetails,
        isLoading: UpdateResumeDataDataLoading,
      } = usePost("/user/Updateresumedeatails");
    const { user, setUserDetails } = useContext(UserContext);
    const savePhones = (data) => {
        let flag = true;
        let phones = data?.phones
        for (let i = 0; i < phones?.length; i++) {
            for (let j = i + 1; j < phones?.length; j++) {
                if (phones[i]?.phone == phones[j]?.phone) {
                    flag = false
                    setError("phones[" + j + "].phone",
                        {
                            type: "manual",
                            message: t("phonenumberIsAlreadyPresent",{defaultValue:"Phonenumber is already present"}),
                        }
                    );
                }

            }
        }
        // validation
        if (data?.phones && data?.phones.length > 0) {

            for (let i in phones) {
                if (phones[i]?.phone?.trim() == "" || phones[i]?.phone === undefined || phones?.phone === null) {
                    flag = false
                    setError("phones." + i + ".phone", {
                        type: "manual",
                        message:  t("phoneNumberIsRequired",{defaultValue:"Phone number is required"}),
                    })
                } else {
                    if (phones[i]?.phone.trim() != "") {
                        if (!isValidPhoneNumber(String(phones[i]?.phone))) {
                            flag = false
                            setError("phones." + i + ".phone", {
                                type: "manual",
                                message: t("pleaseEnterAValidPhoneNumber",{defaultValue:"Please enter a valid phone number"}),
                            })
                        }
                    }
                }

            }
        }


        if (flag) {
            let phonesList = [];
            if (data?.phones) {
                let phones = data?.phones;
                for (let i in phones) {
                    let phoneSchema = {
                        phone: "",
                        country: "",
                        countryCode: "",
                        internationalPhoneCode: "",
                        phoneNumberOnly: ""
                    };
                    let parsedNumber = parsePhoneNumber(String(phones[i]?.phone));
                    phoneSchema.phone = parsedNumber?.number;
                    phoneSchema.country = countryNames[parsedNumber?.country]
                    phoneSchema.countryCode = parsedNumber?.country;
                    phoneSchema.internationalPhoneCode = parsedNumber?.countryCallingCode;
                    phoneSchema.phoneNumberOnly = parsedNumber?.nationalNumber;

                    phonesList.push(phoneSchema);
                }
            }
            updateResumeDetails({
                target: "phones",
                user:user,
                resumeID: resumeId,
                companyID:companyID,
                clientID:clientID,
                getValues: {"phones" : phonesList},
                type:"Profile Edited"
            }).then((res) => {
                if (res) {
                    setUpdateResumeDataData(res)
                    triggerContactDetailsRef.current.close()
                }
              })
              .catch((error) => {
                console.error("Error while fetching Resume:", error);
              });
        }
    };
    const handleGoBackToContactDetails = () => {
        setHideEmailDetailsView(true);
        setHidePhoneDetailsView(true);
    }
    return (
        <>
            <div className='row'>
                <div className=' d-flex align-items-center col-md-12'>
                    <span className="IconArea actionIcon actionIcon-sm" title="Click here to go back to contact Details" onClick={handleGoBackToContactDetails} >
                        <FontAwesomeIcon
                            className="mx-1 cursor-pointer text-primary"
                            icon={faArrowLeft}
                        />
                    </span>
                    <h6 className='m-0 p-0 text-dark'>
                        <Trans
                            i18nKey="phoneNumbers"
                            defaults="Phone Numbers"
                        />

                    </h6>
                </div>
            </div>
            <h6></h6>
            <div className="phoneNumbersFormBody">
                <div className="row w-100">
                    <div className="col-sm-12 col-sm-12">

                        <React.Suspense
                            fallback={<Loading rows={0} />}
                        >
                            <PhoneNumberForm
                                phoneNumbers={phoneNumber}
                                register={register}
                                setValue={setValue}
                                control={control}
                                getValues={getValues}
                                errors={errors}
                                handleSubmit={handleSubmit}
                                required={true}
                                setError={setError}
                                clearErrors={clearErrors}
                            />
                        </React.Suspense>


                    </div>
                </div>
            </div>
            <div className="row my-2">
                <div className="col-md-12 d-flex justify-content-end">

                    <span>
                        <button
                            className="btn btn-sm btn-primary"
                            disabled={UpdateResumeDataDataLoading}
                            onClick={handleSubmit(savePhones)}
                        >
                            {UpdateResumeDataDataLoading ? (
                                <span
                                    className="spinner-border spinner-border-sm"
                                    role="status"
                                    aria-hidden="true"
                                ></span>
                            ) : null}
                            <span className='px-1'>
                                <Trans
                                    i18nKey="save"
                                    defaults="Save"
                                />
                            </span>


                        </button>
                    </span>
                </div>
            </div>
        </>
    );
};

export default PhoneNumberSessionEdit;