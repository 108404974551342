import React, { useEffect, useState } from "react";
import { InputPicker, SelectPicker } from "rsuite";
import "./SearchBarStyles.css";
import { Trans, useTranslation } from "react-i18next";

function SmallExperenceKey({ value, setValue }) {
    const { t } = useTranslation();

  const experienceData = [
    {
      label: "Less than 1 year",
      value: "1",
    },
    // { label: "Fresher (less than 1 year)", value: "1" },

    { label: "2 years", value: "2" },
    { label: "3 years", value: "3" },
    { label: "4 years", value: "4" },
    { label: "5 years", value: "5" },
    { label: "More than 5 years", value: "6" },
  ];

  return (
    <>
      <InputPicker
        data={experienceData}
        className=" no-border   align-items-center w-100"
        placeholder={t("selectExperience", { defaultValue: "Select Experience" })}
        value={value}
        onChange={(newValue) => setValue(newValue)}
      />
    </>
  );
}

export default SmallExperenceKey;
