import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { Form } from 'react-bootstrap';
// import { useGetResumedbSchemaQuery } from '../../../services/resume/resumeDetailsService';
import { SelectPicker } from 'rsuite';
import { Trans } from 'react-i18next';
import { t } from 'i18next';
import usePost from '../../../customhooks/UsePost';

const CompensationCurrencyFormField = ({ compensationCurrencyData, control, register, getValues, setValue, errors,fieldList, type}) => {

    useEffect(() => {
        if (compensationCurrencyData) {
            setValue("compensationCurrency", compensationCurrencyData)
        }else{
            setValue("compensationCurrency", 'USD')   
        }
    }, [compensationCurrencyData])
    const [resumeSchema, setResumeSchema] = useState({})
    const {
        post: schema,
        isLoading,
        error: err,
      } = usePost("/user/getresumedbschema"); 
    
      useEffect(()=>{
        schema({get: "schema"}).then((res)=>{
            setResumeSchema(res?.result?.resumeSchema)
        }).catch((error)=>{
          console.log(error)
        })
      },[])

      const [compensationCurrencyValues, setCompensationCurrencyValues] = useState([])

    useEffect(() => {
        if (resumeSchema?.schema?.values?.salaryCurrency) {
            setCompensationCurrencyValues(
                resumeSchema?.schema?.values?.salaryCurrency.map(
                    item => ({ label: item, value: item })
                )
            )
        }
    }, [resumeSchema]);

    return (
        <>
            <div className="form-group">
                <label className='text-dark'>
                {t(fieldList?.i18nKey, { defaultValue: fieldList?.fieldName })}
                </label>
                {fieldList?.required && <span className="text-danger">{"*"}</span>}
                <Controller
                    control={control}
                    name={"compensationCurrency"}
                    refs={register("compensationCurrency",{
                        required:fieldList?.required ? true : false
                    })}
                    render={({ field: { onChange, onBlur, value } }) => (
                        <SelectPicker
                            className="w-100"
                            data={compensationCurrencyValues}
                            placeholder={t("select", { defaultValue: "Select" }) + " " + t("compensationCurrency", { defaultValue: "Currency" })}
                            cleanable={false}
                            onChange={onChange}
                            disabled={(  type=="customLayout" ||fieldList?.editable || !fieldList) ? false : true}
                            value={value}
                            placement="auto"
                        />
                    )}
                />
            </div>
            {errors?.compensationCurrency&& (
          <span className="text-danger ">
            {errors?.compensationCurrency?.message != "" ? (
              errors?.compensationCurrency?.message
            ) : (
              <Trans
                i18nKey="compensationCurrencyIsRequired"
                defaults="Compensation Currency is required"
              />
            )}{" "}
          </span>
        )}
            {/* {
                errors?.compensationCurrency && <span className="text-danger">{errors?.compensationCurrency?.message}</span>
            } */}
        </>
    );
};
CompensationCurrencyFormField.propTypes = {
    compensationCurrencyData: PropTypes.any.isRequired,
    register: PropTypes.any.isRequired,
    control: PropTypes.any.isRequired,
    getValues: PropTypes.any.isRequired,
    setValue: PropTypes.any.isRequired,
    errors: PropTypes.any.isRequired,

}

export default CompensationCurrencyFormField;