import React, { useContext, useEffect, useState } from "react";
import ResumeView from "./ResumeView";
import usePost from "../../../../customhooks/UsePost";
import UserContext from "../../../../app/context/UserContext";
import Loading from "../../../../Loder";

const ResumeModal = ({ resumeData, type, page, companyID }) => {
  const {
    post: resumeFileDetails,
    data,
    isLoading: resumeFileDetailsLoading,
    error,
  } = usePost("/getresumefile");
  const { user, setUserDetails } = useContext(UserContext);
  const [resumeFile, setResumeFile] = useState({});
  let primaryKey;
  let pdfFileName = "";
  let pdfFilePath = "";

  if (
    resumeData?.metaData &&
    resumeData?.metaData?.resumes &&
    resumeData?.metaData?.resumes?.length > 0
  ) {
    primaryKey = resumeData?.metaData?.resumes.find((x) => x?.primary == true);
  }
  if (primaryKey) {
    if (
      primaryKey?.newPDFFileName &&
      primaryKey?.newPDFFilePath &&
      primaryKey?.newPDFFileName != "" &&
      primaryKey?.newPDFFilePath != ""
    ) {
      pdfFileName = primaryKey?.newPDFFileName;
      pdfFilePath = primaryKey?.newPDFFilePath;
    } else if (
      primaryKey?.newHTMLFileName &&
      primaryKey?.newHTMLFilePath &&
      primaryKey.newHTMLFileName != "" &&
      primaryKey.newHTMLFilePath != ""
    ) {
      pdfFileName = primaryKey.newHTMLFileName;
      pdfFilePath = primaryKey.newHTMLFilePath;
    }
  }
  if (pdfFilePath == "" || pdfFileName == "") {
    if (
      resumeData?.metaData &&
      resumeData?.metaData.newPDFFileName &&
      resumeData?.metaData?.newPDFFilePath &&
      resumeData?.metaData.newPDFFilePath != "" &&
      resumeData?.metaData.newPDFFileName != ""
    ) {
      pdfFileName = resumeData?.metaData?.newPDFFileName;
      pdfFilePath = resumeData?.metaData?.newPDFFilePath;
    } else if (
      resumeData?.metaData &&
      resumeData?.metaData?.newHTMLFileName &&
      resumeData?.metaData?.newHTMLFilePath &&
      resumeData?.metaData?.newHTMLFileName != "" &&
      resumeData?.metaData?.newHTMLFilePath != ""
    ) {
      pdfFileName = resumeData?.metaData?.newHTMLFileName;
      pdfFilePath = resumeData?.metaData?.newHTMLFilePath;
    }
  }
  // let resumeFileData = {}
  const [resumeFileData, setResumeFileData] = useState({});
  useEffect(() => {
    if (user?.companyID && pdfFileName && pdfFilePath) {
      setResumeFileData({
        fileName: pdfFileName,
        filePath: pdfFilePath,
        companyID: user?.companyID,
      });
    } else if (
      page == "referalresume" &&
      companyID &&
      pdfFileName &&
      pdfFilePath
    ) {
      setResumeFileData({
        fileName: pdfFileName,
        filePath: pdfFilePath,
        companyID: companyID,
      });
    }
  }, [user, pdfFileName, pdfFilePath,page]);
  useEffect(() => {
    // resumeFileData["fileName"] = pdfFileName;
    // resumeFileData["filePath"] = pdfFilePath;
    // resumeFileData["companyID"] = user?.companyID;
    if (
      resumeFileData?.fileName &&
      resumeFileData.fileName !== "" &&
      resumeFileData.filePath &&
      resumeFileData.filePath != "" &&
      resumeFileData.companyID &&
      resumeFileData.companyID != ""
    ) {
      resumeFileDetails(resumeFileData)
        .then((res) => {
          if (res) {
            setResumeFile({
              result: res,
            });
          }
        })
        .catch((error) => {
          console.error("Error while fetching Resume:", error);
        });
    }
  }, [resumeFileData]);
  // console.log(resumeData,pdfFileName,"lkkkkkkkkkkkkkkkkkkkkpppppppppp");
  return (
    <div className="card-body d-flex justify-content-center align-items-center text-center ">
      {!resumeFileDetailsLoading ? (
        <ResumeView
          pdfFileName={pdfFileName}
          data={resumeFile}
          loading={resumeFileDetailsLoading}
          type={type}
        />
      ) : (
        <Loading rows={5} />
      )}
    </div>
  );
};

export default ResumeModal;
