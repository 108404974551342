import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { Loader, Placeholder } from "rsuite";
import PropTypes from "prop-types";
import { t } from "i18next";

// function Loading() {
//   const [height, setHeight] = useState(0);

//   useEffect(() => {
//     const handleResize = () => {
//       const availableHeight = document.body.offsetHeight;
//       setHeight(availableHeight);
//     };

//     handleResize();

//     window.addEventListener("resize", handleResize);

//     return () => window.removeEventListener("resize", handleResize);
//   }, []);

//   const style = {
//     position: "absolute",
//     top: 0,
//     left: 0,
//     width: "100%",
//     height: height,
//     backgroundColor: "rgba(0, 0, 0, 0.5)",
//     display: "flex",
//     alignItems: "center",
//     justifyContent: "center",
//   };

//   return ReactDOM.createPortal(
//     <div style={style}>
//       <div
//         className="message"
//         style={{ color: "white", position: "absolute", top: "30%" }}
//       >
//         <img
//           width="45px"
//           height="45px"
//           src={"/assets/images/Preloader_3.gif"}
//           className="App-logo"
//           alt="logo"
//         />
//       </div>
//     </div>,
//     document.body
//   );
// }

function Loading({ rows = 3 }) {
  return (<>
    <Placeholder.Paragraph rows={rows} />
    <Loader center content={t("loading", { defaultValue: "Loading" })} />
  </>
  )
}
Loading.propTypes = {
  rows: PropTypes.number
}
export default Loading;
