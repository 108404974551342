import React from "react";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import { SelectPicker } from "rsuite";
import { useEffect, useState } from "react";
// import { useGetResumedbSchemaQuery } from '../../../services/resume/resumeDetailsService';
import { Trans } from "react-i18next";
import { t } from "i18next";
import usePost from "../../../customhooks/UsePost";

const salaryPeriodSelect = [
  "Per Hour",
  "Per Month",
  "Per Annum",
  "Per Week",
  "Per Day",
  "Per Bi-Weekly",
  "Per 3 months",
  "Per 15 Days",
  "Per 6 Months",
].map((item) => ({ label: item, value: item }));
const CompensationPeriodFormField = ({
  compensationPeriodData,
  control,
  register,
  getValues,
  setValue,
  errors,
  fieldList,
  type,
}) => {
  useEffect(() => {
    if (compensationPeriodData) {
      setValue("compensationPeriod", compensationPeriodData);
    }
  }, [compensationPeriodData]);

  const [compensationPeriodValues, setCompensationPeriodValues] = useState([]);

  const [resumeSchema, setResumeSchema] = useState({})
    const {
        post: schema,
        isLoading,
        error: err,
      } = usePost("/user/getresumedbschema"); 
    
      useEffect(()=>{
        schema({get: "schema"}).then((res)=>{
            setResumeSchema(res?.result?.resumeSchema)
        }).catch((error)=>{
          console.log(error)
        })
      },[])

  useEffect(() => {
      if (resumeSchema?.schema?.values?.salaryPeriod) {
          setCompensationPeriodValues(
              resumeSchema?.schema?.values?.salaryPeriod.map(
                  item => ({ label: item, value: item })
              )
          )
      }
  }, [resumeSchema])

  return (
    <>
      <div className="form-group">
        <label className="text-dark">
        {t(fieldList?.i18nKey, { defaultValue: fieldList?.fieldName })}
        </label>
        {fieldList?.required && <span className="text-danger">{"*"}</span>}
        <Controller
          control={control}
          name={"compensationPeriod"}
          refs={register("compensationPeriod", {
            required: fieldList?.required ? true : false,
          })}
          render={({ field: { onChange, onBlur, value } }) => (
            <SelectPicker
              className="w-100"
              data={compensationPeriodValues}
              placeholder={
                t("select", { defaultValue: "Select" }) +
                " " +
                t("compensationPeriod", { defaultValue: "Period" })
              }
              cleanable={false}
              onChange={onChange}
              disabled={
                type == "customLayout" || fieldList?.editable || !fieldList
                  ? false
                  : true
              }
              value={value}
              // disabled={(fieldList?.editable || !fieldList) ? false : true}
              placement="auto"
            />
          )}
        />
      </div>
      {errors?.compensationPeriod && (
        <span className="text-danger">
          {errors?.compensationPeriod?.message != "" ? (
            errors?.compensationPeriod?.message
          ) : (
            <Trans
              i18nKey="compensationPeriodIsRequired"
              defaults="Compensation Period is required"
            />
          )}{" "}
        </span>
      )}
      {/* {
                errors?.compensationPeriod && <span className="text-danger">{errors?.compensationPeriod?.message}</span>
            } */}
    </>
  );
};
CompensationPeriodFormField.propTypes = {
  compensationPeriodData: PropTypes.any.isRequired,
  register: PropTypes.any.isRequired,
  control: PropTypes.any.isRequired,
  getValues: PropTypes.any.isRequired,
  setValue: PropTypes.any.isRequired,
  errors: PropTypes.any.isRequired,
};
export default CompensationPeriodFormField;
