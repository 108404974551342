import React, { Suspense, useContext, useState } from "react";
import { Loader } from "rsuite";
import { Trans, useTranslation } from "react-i18next";
import "../createProfile/CreateProfile.css";
import UserContext from "../../app/context/UserContext";
import { useSearchParams } from "react-router-dom";
import useIsSsr from "../../customhooks/UseIsSSR";

const DuplicateProfile = React.lazy(() =>
  import("../createProfile/DuplicateProfile")
);
// const CreateProfileModal = React.lazy(() =>
//   import("../createProfile/CreateProfileModal")
// );
import CreateProfileModal from "../createProfile/CreateProfileModal";

function CreateUserResume({ setResumeID, page, companyID ,updateReferraldataLoading}) {
  const isSsr = useIsSsr();
  const { user, setUser } = useContext(UserContext);
  const { t } = useTranslation();

  const [searchParams] = useSearchParams();
  const [open, setOpen] = useState(false);
  // const [page, setPage] = useState("userbasicprofile");
  const [openDuplicate, setOpenDuplicate] = useState(false);
  const [duplicateProfiles, setDuplicateProfiles] = useState([]);
  const [cookie, setCookie] = useState(null);
  const [openCreateProfile, setOpenCreateProfile] = useState(false);
  const [notduplicate, setnotduplicate] = useState(false);

  return (
    <>
      <div>
        <div className=" card text-center p-5 mt-3">
          <h2>
            <Trans i18nKey="noResumeFound" defaults="No Resume Found" />
          </h2>
          <p>
            {" "}
            <Trans
              i18nKey="itSeemsYouDon'tHaveAResumeYet.CreateOneNow"
              defaults="It seems you don't have a resume yet. Create one now"
            />{" "}
            {":"}
          </p>
          <button
            className="btn btn-primary mx-auto d-block"
            onClick={() => {
              setOpen(true);
              setOpenCreateProfile(true);
            }}
          >
            <Trans i18nKey="createResume" defaults="Create Resume" />
          </button>
        </div>
      </div>

      {openCreateProfile && (
        // <Suspense
        //   fallback={<Loader center speed="normal" size="sm" backdrop={true} />}
        // >
          <CreateProfileModal
            open={openCreateProfile}
            setOpen={setOpenCreateProfile}
            // campaignID={campaignID}
            page={page}
            setOpenDuplicate={setOpenDuplicate}
            setDuplicateProfiles={setDuplicateProfiles}
            setCookie={setCookie}
            setResumeID={setResumeID}
            companyID={companyID}
            notduplicate={notduplicate}
            updateReferraldataLoading={updateReferraldataLoading}
          ></CreateProfileModal>
        // </Suspense>
      )}

      {openDuplicate && (
        <React.Suspense
          fallback={<Loader center speed="normal" size="sm" backdrop={true} />}
        >
          <DuplicateProfile
            open={openDuplicate}
            setOpen={setOpenDuplicate}
            duplicateProfiles={duplicateProfiles}
            // campaignID={campaignID}
            page={page}
            jobSeekerID={
              user?._id != undefined && user?._id != "" ? user?._id : ""
            }
            // setCookie={setCookie}
            setResumeID={setResumeID}
            setnotduplicate={setnotduplicate}
            setOpenCreateProfile={setOpenCreateProfile}
            updateReferraldataLoading={updateReferraldataLoading}
            companyID={companyID}
          />
        </React.Suspense>
      )}
    </>
  );
}

export default CreateUserResume;
