import React from 'react';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';

const DurationCalculation = ({ startDate }) => {
    const now = DateTime.now();
    const start = DateTime.fromISO(startDate);
    const diff = now.diff(start, ['years', 'months', 'days', 'hours', 'minutes', 'seconds']);
    const years = Math.floor(diff.years);
    const months = Math.floor(diff.months);
    const days = Math.floor(diff.days);
    // const hours = Math.floor(diff.hours);
    // const minutes = Math.floor(diff.minutes);
    // const seconds = Math.floor(diff.seconds);
    return (
        <>
            {years > 0 ? `${years} years ` : null}
            {months > 0 ? `${months} months ` : null}
            {days > 0 ? `${days} days ` : null}
        </>
    )
};
DurationCalculation.propTypes = {
    startDate: PropTypes.any,
}
export default DurationCalculation;