import React from 'react';
import PropTypes from "prop-types";
import { faMinusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Controller } from "react-hook-form"
import { Trans, useTranslation } from "react-i18next";



const EmailFields = ({ fields, register, remove, errors, control, getValues, setError, clearErrors,indeee,resume,setUpdateResumeDataData }) => {
    const { t } = useTranslation();

    function checkEmailDuplicateOnChange() {
        for (let i = 0; i < fields.length; i++) {
            for (let j = 0; j < fields.length; j++) {
                if (i != j) {
                    clearErrors([`emails.${j}.email`]);
                    if (getValues(`emails.${i}.email`) == getValues(`emails.${j}.email`)) {
                        setError("emails[" + j + "].email", {
                            type: "manual",
                            message: t("emailAlreadyExists",{defaultValue:"Email already exists"}),
                        });
                    }
                }
            }
        }
    }

    return (
        <>
            {fields.map((field, index) => (
                <div key={field.id} className='row w-100 pb-2'>
                    <div className='col-11'>
                        <Controller
                            control={control}
                            name={`emails[${index}].email`}
                            refs={register(`emails[${index}].email`, {
                                required: true,
                                pattern: {
                                    value: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/gi,
                                    message: t("pleaseEnterValidEmailAddress", { defaultValue: "please enter valid email address" })
                                },
                                validate: (v) => v.replace(/\s/g, "") != ""
                            })}
                            render={({ field: { onChange, onBlur, value } }) => (
                                <input
                                    type="email"
                                    className='form-control form-control-sm'
                                    value={value}
                                    onChange={(e) => {
                                        onChange(e?.target?.value)
                                        // handleEmailChange()
                                        checkEmailDuplicateOnChange()
                                    }}
                                    disabled={indeee.includes(index) ? true : false}
                                />
                            )
                            
                        }
                        />
                    </div>
                    <div className='col-1'>
                        {indeee.includes(index) ? "" : 
                        <span
                            className="px-2 IconArea actionIcon actionIcon-sm"
                            onClick={() => {
                                remove(index);
                                clearErrors(["emails"])
                            }}
                        >
                            <FontAwesomeIcon icon={faMinusCircle}></FontAwesomeIcon>
                        </span>}
                    </div>
                    {errors?.["emails"]?.[index]?.["email"] && (
                        <span className="text-danger">
                            {errors?.["emails"]?.[index]?.["email"]?.message != ""
                                ? errors?.["emails"]?.[index]?.["email"].message
                                : t("emailIsRequired", { defaultValue: "Email is required" }) }
                        </span>
                    )}
                </div>
            ))}
        </>
    );
};

export default EmailFields;