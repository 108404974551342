import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import { t } from "i18next";
import { Trans } from "react-i18next";

const CurrentCompanyFormField = ({
  currentCompanyData,
  control,
  register,
  getValues,
  setValue,
  errors,
  fieldList,
  clearErrors,
  type,
}) => {
  useEffect(() => {
    if (currentCompanyData) {
      setValue("currentCompany", currentCompanyData);
    }
  }, [currentCompanyData]);

  // const handleChange = (e) => {
  //   const value = e.target.value;
  //   console.log(value,"mmmmmmm");
  //   if (value.trim() !== "") {
  //     clearErrors(); // Clear the error if value is not empty
  //   }
  // }
  return (
    <>
      <div className="form-group">
        <label className="text-dark">
          {t("currentCompany", { defaultValue: "Current Company" })}
        </label>
        {type == "customLayout" && fieldList?.required && (
          <span className="text-danger">{"*"}</span>
        )}
        <Controller
          control={control}
          name={"currentCompany"}
          refs={register("currentCompany", {
            required:
              type == "customLayout" && fieldList?.required ? true : false,
            // validate: {
            //   emptyValue: (v) => (v && v.trim() !== ""),
            // },
          })}
          render={({ field: { onChange, onBlur, value } }) => (
            <input
              className="form-control"
              name="currentCompany"
              disabled={
                (type == "customLayout" && fieldList?.editable) || !fieldList
                  ? false
                  : true
              }
              placeholder={
                t("enter", { defaultValue: "Enter" }) +
                " " +
                t("currentCompany", { defaultValue: "Current Company" })
              }
              value={value}
              onChange={onChange}
            />
          )}
        />

        {errors?.currentCompany && (
          <span className="text-danger mt-3">
            {errors?.currentCompany?.message != "" ? (
              errors?.currentCompany?.message
            ) : (
              <Trans
                i18nKey="currentCompanyIsRequired"
                defaults="Current Company is required"
              />
            )}{" "}
          </span>
        )}
      </div>
    </>
  );
};

export default CurrentCompanyFormField;
