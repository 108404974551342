import React, { useEffect, useState } from "react";
import { useForm, useFieldArray, Controller, set } from "react-hook-form";
import PhoneInput, {
  isValidPhoneNumber,
  parsePhoneNumber,
} from "react-phone-number-input";
import { Button, Form, Spinner } from "react-bootstrap";
import CareesEmailForm from "./CareesEmails";
import CareersPhoneNumberForm from "./CareersPhonenum";
import usePost from "../../../customhooks/UsePost";
import { useSearchParams } from "react-router-dom";
import { useTranslation, Trans } from "react-i18next";
import CareersUploaderesume from "./Careersuploderesume";
import CareersEditResume from "./CareersEditresume";
import queryString from "query-string";
import { DateTime } from "luxon";
import {
  Avatar,
  Placeholder,
  Loader,
  Notification,
  useToaster,
  Whisper,
  Tooltip,
  toaster,
} from "rsuite";
// import { parsePhoneNumber } from "react-phone-number-input";
import countryNames from "react-phone-number-input/locale/en";
import DuplicateProfile from "../../createProfile/DuplicateProfile";
import LinkProfile from "../LinkProfile";

function CareersApplyNow({
  JobDetails,
  setRid,
  setIid,
  setRoundNumber,
  resumeCookie,
  setResumeCookie,
  openLinkProfile,
  setOpenLinkProfile,
  section1Ref,
  scrollToSection,
}) {
  const {
    post: createResumes,
    data: createResumesData,
    isLoading: createResumesLoading,
    error: createResumesError,
  } = usePost("/user/createResumes");
  const {
    post: Candiatedetails,
    isLoading: CandiatedetailsLoading,
    error: CandiatedetailsError,
  } = usePost("/user/getCandidatedeatils");

  const {
    post: applyJobInterview,
    isLoading: applyJobInterviewLoading,
    error: applyJobInterviewError,
  } = usePost("/user/applyJobInterview");

  const {
    post: getInterviewId,
    isLoading: getInterviewIdLoading,
    error: getInterviewIdError,
  } = usePost("/user/getInterviewId");

  const {
    post: updateInterviewDocument,
    isLoading: updateInterviewDocumentLoading,
    error: updateInterviewDocumentError,
  } = usePost("/user/updateInterviewDocument");
  const {
    post: scheduleInterview,
    isLoading: scheduleInterviewLoading,
    error: scheduleInterviewError,
  } = usePost("/user/scheduleInterview");
  const { post: setCookies } = usePost("/setcookie");

  const {
    register,
    handleSubmit,
    reset,
    control,
    clearErrors,
    setError,
    getValues,
    setValue,
    formState: { errors, isSubmitSuccessful },
    watch,
  } = useForm();

  // console.log(JobDetails);
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();
  const [openDuplicate, setOpenDuplicate] = useState(false);
  const [showinputdata, setinputdata] = useState(false);
  const [duplicateProfiles, setDuplicateProfiles] = useState([]);
  const [displycard, setdisplycard] = useState(false);
  const [ProfileEditdetails, setProfileEditdetails] = useState(false);
  const [resumedata, setresumedata] = useState(null);
  const [notduplicate, setnotduplicate] = useState(false);

  useEffect(() => {
    if (resumedata !== null) {
      let applyinterviwedata = {
        event: "Applied-Job",
        campaignID: JobDetails?._id,
        companyID: JobDetails?.companyID,
        clientID: JobDetails?.clientID,
        userID: JobDetails?.userID,
        resumeID: resumedata,
        realTime: false,
      };
      applyJobInterview(applyinterviwedata)
        .then((resp) => {
          let obj = {
            campaignID: JobDetails?._id,
            companyID: JobDetails?.companyID,
            resumeID: resumedata,
          };
          if (resp?.result?.status) {
            getInterviewId(obj).then((resp) => {
              // console.log(resp?.result?.data);
              if (resp?.result?.data?.status) {
                setIid(resp?.result?.data?.interviewID);
                setRoundNumber(resp?.result?.data?.screeningRoundNumber);
                setRid(resumedata);
                if (resp?.result?.data?.screeningRound) {
                  let updateinteviw = {
                    applicationChannel: "Direct Apply",
                    applicationMethod: "Active Sourcing",
                    applicationStatus: "Interested",
                    applicationType: "External Sourcing",
                    campaignID: JobDetails?._id,
                    interviewID: resp?.result?.data?.interviewID,
                    linkedBy: "Job Seeker",
                    linkedByID: resumedata,
                    resumeID: resumedata,
                    statusUpdatedBy: "Job Seeker",
                    statusUpdatedByID: resumedata,
                    companyID: JobDetails?.companyID,
                  };
                  updateInterviewDocument(updateinteviw).then(
                    (uppdateinterw) => {
                      console.log(uppdateinterw);
                    }
                  );
                  var scheduleData = {
                    event: "Invitation-Interview",
                    companyID: JobDetails?.companyID,
                    clientID: JobDetails?.clientID,
                    campaignID: JobDetails._id,
                    userID: JobDetails?.userID,
                    screeningRoundNumber:
                      resp?.result?.data?.screeningRoundNumber,
                    resumeID: [resumedata],
                  };

                  let round = [];
                  if (JobDetails) {
                    if (JobDetails?.screeningRounds) {
                      round = JobDetails?.screeningRounds.filter(
                        (x) =>
                          x.roundNumber ==
                          parseInt(resp?.result?.data?.screeningRoundNumber)
                      );
                      if (round && round[0]) {
                        if (
                          round[0].roundType == "Live Video" ||
                          round[0].roundType == "In-Person" ||
                          round[0].roundType == "Interview"
                        ) {
                          scheduleData["event"] = "Live-Video-AutoSchedule";
                        }
                      }
                    }
                  }
                  if (
                    scheduleData.event != "Live-Video-AutoSchedule" ||
                    (scheduleData.event == "Live-Video-AutoSchedule" &&
                      round &&
                      round[0] &&
                      round[0].f2fSchedule &&
                      round[0].f2fSchedule.autoSchedule &&
                      round[0].f2fSchedule.autoSchedule.length > 0)
                  ) {
                    scheduleInterview(scheduleData).then((sheulde) => {
                      console.log(sheulde);
                    });
                  }
                }
              }
            });
          }
        })
        .catch((error) => {
          console.log("erorororor", error);
        });
    }
  }, [resumedata]);
  useEffect(() => {
    if (notduplicate) {
      onSubmit();
    }
  }, [notduplicate]);

  const onSubmit = (data) => {
    let flag = true;

    if (getValues()) {
      // console.log("allvalues:", getValues())

      let createResumeObj = {
        phones: [],
        from: "guest",
        campaignID:
          JobDetails?._id != undefined && JobDetails?._id != ""
            ? JobDetails?._id
            : "",
        jobSeekerID: "",
        source:
          searchParams.get("source") != null ? searchParams.get("source") : "",
        referenceURL: window.location.origin + window.location.pathname,
      };
      if (notduplicate) {
        createResumeObj.notduplicate = false;
      } else {
        createResumeObj.notduplicate = true;
      }
      // basic details
      createResumeObj.firstName =
        getValues()?.firstname !== undefined ? getValues()?.firstname : "";
      createResumeObj.middleName =
        getValues()?.middleName !== undefined ? getValues()?.middleName : "";
      createResumeObj.lastName =
        getValues()?.lastName !== undefined ? getValues()?.lastName : "";
      if (getValues()?.emails) {
        if (getValues()?.emails?.length > 0) {
          if (!checkEmailDuplicateOnSubmit(getValues()?.emails)) {
            flag = false;
          }
          createResumeObj.emails = getValues()?.emails;
        }
      }
      if (getValues()?.phones) {
        if (getValues()?.phones?.length > 0) {
          let phones = getValues()?.phones;
          if (!checkPhoneNumberDuplicateOnSubmit(phones)) {
            flag = false;
          }

          for (let i in phones) {
            if (
              phones[i]?.phone?.trim() != "" &&
              phones[i]?.phone !== undefined
            ) {
              let phoneSchema = {
                phone: "",
                country: "",
                countryCode: "",
                internationalPhoneCode: "",
                phoneNumberOnly: "",
              };
              let parsedNumber = parsePhoneNumber(String(phones[i]?.phone));
              phoneSchema.phone = parsedNumber?.number;
              phoneSchema.country = countryNames[parsedNumber?.country];
              phoneSchema.countryCode = parsedNumber?.country;
              phoneSchema.internationalPhoneCode =
                parsedNumber?.countryCallingCode;
              phoneSchema.phoneNumberOnly = parsedNumber?.nationalNumber;
              createResumeObj.phones.push(phoneSchema);
            }
          }
        }
      }
      createResumes(createResumeObj)
        .then((resp) => {
          if (resp?.status) {
            if(resp?.result?.status && resp.result.status === true && resp?.result?.disqualifiedExists && resp?.result?.disqualifiedExists === true){
              toaster.push(
                <Notification
                  type={"error"}
                  header={t("error", { defaultValue: "Error" })}
                  closable
                >
                  {t("emailAddressEnteredIsDisqualifiedAsPerOurRecordsPleaseEnterADifferentEmailAddressToProceed", {
                    defaultValue: "Email address entered is disqualified as per our records. Please enter a different email address to proceed.",
                  })}
                </Notification>,
                { placement: "topEnd" , duration: 10000}
              );
            }
            else if (resp?.result?.status && resp.result.status === true) {
              if (resp?.result?.duplicate) {
                if (resp?.result?.attached) {
                  // set resumeID in cookies
                  if (resp?.result?.resumeID && resp.result.resumeID != "") {
                    setCookies({
                      cookieName: "Guest_User_ResumeID",
                      cookieValue: resp?.result?.resumeID,
                      expires: DateTime.now().plus({ months: 3 }).toISO(),
                    })
                      .then(async (res) => {
                        console.log("cookie res", res);

                        if (res?.status) {
                          setResumeCookie(resp?.result?.resumeID);
                        }
                      })
                      .catch((error) => {
                        console.log("Error in setCookie : ", error);
                      });
                  }

                  toaster.push(
                    <Notification
                      type={"success"}
                      header={t("success", { defaultValue: "success" })}
                      closable
                    >
                      {t("youHaveAlreadyAppliedForThisJob", {
                        defaultValue: "You have already applied for this job",
                      })}
                    </Notification>,
                    { placement: "topEnd" }
                  );
                  setinputdata(true);
                  setresumedata(resp?.result?.resumes11);
                } else {
                  if (
                    resp?.result?.resumes &&
                    resp?.result?.resumes?.length > 0
                  ) {
                    // setinputdata(true);
                    setOpenDuplicate(true);
                    setDuplicateProfiles(resp?.result?.resumes);
                    // setresumedata(resp?.result?.resumes[0]?._id);
                  }
                }
              } else {
                // set resumeID in cookies
                if (resp?.result?.resumeID && resp.result.resumeID != "") {
                  setCookies({
                    cookieName: "Guest_User_ResumeID",
                    cookieValue: resp?.result?.resumeID,
                    expires: DateTime.now().plus({ months: 3 }).toISO(),
                  })
                    .then(async (res) => {
                      console.log("cookie res", res);

                      if (res?.status) {
                        setResumeCookie(resp?.result?.resumeID);
                      }
                    })
                    .catch((error) => {
                      console.log("Error in setCookie : ", error);
                    });
                }

                toaster.push(
                  <Notification
                    type={"success"}
                    header={t("success", { defaultValue: "success" })}
                    closable
                  >
                    {t("jobAppliedSuccessfully", {
                      defaultValue: "Job applied Successfully",
                    })}
                  </Notification>,
                  { placement: "topEnd" }
                );
                setinputdata(true);
                setresumedata(resp?.result?.resumeID);
              }

              if (notduplicate) {
                setOpenDuplicate(false);
                setnotduplicate(false);
              }
            } else {
              setOpenDuplicate(false);
              if (resp?.result?.message.includes("Campaign is not active")) {
                toaster.push(
                  <Notification
                    type={"error"}
                    header={t("error", { defaultValue: "Error" })}
                    closable
                  >
                    {t("thisJobIsNoLongerAcceptingApplications", {
                      defaultValue:
                        "This job is no longer accepting applications",
                    })}
                  </Notification>,
                  { placement: "topEnd" }
                );
              } else {
                toaster.push(
                  <Notification
                    type={"error"}
                    header={t("error", { defaultValue: "Error" })}
                    closable
                  >
                    {t("somethingWentWrongPleaseTryAgainLater", {
                      defaultValue:
                        "Something went wrong please try again later",
                    })}
                  </Notification>,
                  { placement: "topEnd" }
                );
              }
            }
          } else {
            if (notduplicate) {
              setOpenDuplicate(false);
              setnotduplicate(false);
            }
            toaster.push(
              <Notification
                type={"error"}
                header={t("error", { defaultValue: "Error" })}
                closable
              >
                {t("somethingWentWrongPleaseTryAgainLater", {
                  defaultValue: "Something went wrong please try again later",
                })}
              </Notification>,
              { placement: "topEnd" }
            );
          }
        })
        .catch((error) => {
          console.log("erorororor", error);
        });
      console.log(data, "ffffffffffffffff");
      console.log(createResumeObj);
    }
  };
  function checkEmailDuplicateOnSubmit(emails) {
    let emailAvailable = true;
    for (let i = 0; i < emails.length; i++) {
      for (let j = 0; j < emails.length; j++) {
        if (i != j) {
          if (emails[i]?.email == emails[j]?.email) {
            setError("emails[" + j + "].email", {
              type: "manual",
              message: "Email already exists",
            });
            emailAvailable = false;
          }
        }
      }
    }
    return emailAvailable;
  }

  function checkPhoneNumberDuplicateOnSubmit(phones) {
    let phoneNumberAvailable = true;
    for (let i = 0; i < phones.length; i++) {
      for (let j = 0; j < phones.length; j++) {
        if (i != j) {
          if (phones[i]?.phone == phones[j]?.phone) {
            setError("phones[" + j + "].phone", {
              type: "manual",
              message: "Phone number already exists",
            });
            phoneNumberAvailable = false;
          }
        }
      }
    }
    return phoneNumberAvailable;
  }

  const [applynowdata, setapplynowdata] = useState(false);

  // useEffect(() => {
  //   if (typeof window !== "undefined") {
  //     if (window.location.search.includes("rid")) {
  //       setapplynowdata(true);
  //     }
  //   }
  // }, [JobDetails]);
  const [isChecked, setIsChecked] = useState(true);

  // Function to handle checkbox state changes
  const handleCheckboxChange = () => {
    setIsChecked(!isChecked); // Toggle the current state
  };
  const handledisplay = () => {
    setdisplycard(true);
  };
  const [profiledata, setProfiledata] = useState(null);
  console.log(profiledata, "grty");
  useEffect(() => {
    if (typeof window !== "undefined") {
      if (
        (window.location.search.includes("iid") || resumedata !== null) &&
        JobDetails?.companyID
      ) {
        // if (applynowdata || showinputdata) {
        const queryString = window.location.search;
        const parameters = new URLSearchParams(queryString);
        let obj = {
          companyID: JobDetails?.companyID,
          interviewID: parameters.get("iid"),
          resumeID:
            parameters.get("rid") || parameters.get("jid") || resumedata,
        };
        Candiatedetails(obj)
          .then((resp) => {
            if (resp?.result?.status) {
              setapplynowdata(true);
              setProfiledata(resp?.result?.profileDetails);
            }
            console.log(resp?.result?.profileDetails, "sdfrgtyui");
          })
          .catch((error) => {
            console.log("erorororor", error);
          });
      }
    }
    // }
  }, [JobDetails, showinputdata, resumedata]);
  const [Editresumedetails, setEditresumedetails] = useState(false);
  const [Mainmenudetails, setMainmenudetails] = useState(false);

  const [isSmallScreen, setIsSmallScreen] = useState(
    typeof window !== "undefined" ? window.innerWidth < 768 : false
  );

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(
        typeof window !== "undefined" ? window.innerWidth < 768 : false
      );
    };

    if (typeof window !== "undefined") {
      window.addEventListener("resize", handleResize);

      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }
  }, []);

  useEffect(() => {
    if (
      resumeCookie &&
      resumeCookie !== null &&
      !showinputdata &&
      !applynowdata
    ) {
      setdisplycard(true);
    } else {
      setdisplycard(false);
    }
  }, [resumeCookie, showinputdata, applynowdata]);

  return (
      <div
        className={`pb-4 card border border-primary border-4 border-end-0 border-bottom-0 border-start-0 profileFormDiv ${
          displycard ? "d-none" : ""
        }`}
      >
        <div className="card-body">
          <div className={`${isSmallScreen ? "" : "p-4"}`}>
            {createResumesLoading || CandiatedetailsLoading ? (
                <Placeholder.Grid rows={6} columns={2} active />
            ) : (
              <>
                {!showinputdata && !applynowdata ? (
                  <>
                    <h3 className="text-capitalize heading">
                      <Trans
                        i18nKey="submitYourProfile"
                        defaults="Submit Your Profile"
                      />
                    </h3>
                    <div className="row">
                      <div className="col-md-4 col-12">
                        <label className="mb-1">
                          <Trans i18nKey="firstName" defaults="First Name" />
                        </label>
                        <span className="text-danger">{"*"}</span>
                        <input
                          className="form-control mb-1"
                          type="text"
                          name="firstname"
                          defaultValue={""}
                          placeholder="Enter your name"
                          {...register("firstname", {
                            required: true,
                            validate: {
                              emptyValue: (v) =>
                                v && v !== ""
                                  ? v.replace(/\s/g, "") !== ""
                                  : true,
                            },
                            maxLength: 160,
                            minLength: 1,
                          })}
                        />
                        {errors.firstname && (
                          <span className="text-danger">
                            <Trans
                              i18nKey="pleaseEenterYourFirstName"
                              defaults="Please enter your first name"
                            />
                          </span>
                        )}
                      </div>
                      <div className="col-md-4 col-12">
                        <label className="mb-1">
                          <Trans i18nKey="middleName" defaults="Middle Name" />
                        </label>
                        <input
                          className="form-control mb-1"
                          type="text"
                          name="middleName"
                          defaultValue={""}
                          placeholder="Enter middle name"
                          {...register("middleName", {
                            required: false
                          })}
                        />
                      </div>
                      <div className="col-md-4 col-12">
                        {" "}
                        <label className="mb-1">
                          <Trans i18nKey="lastName" defaults="Last Name" />
                        </label>
                        <span className="text-danger">{"*"}</span>
                        <input
                          className="form-control mb-1"
                          type="text"
                          name="lastName"
                          defaultValue={""}
                          placeholder="Enter last name"
                          {...register("lastName", {
                            required: true,
                            validate: {
                              emptyValue: (v) =>
                                v && v !== ""
                                  ? v.replace(/\s/g, "") !== ""
                                  : true,
                            },
                            maxLength: 160,
                            minLength: 1,
                          })}
                        />
                        {errors.lastName && (
                          <span className="text-danger">
                            <Trans
                              i18nKey="pleaseEenterYourLastName"
                              defaults="Please enter your last name"
                            />
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="row ">
                      <div className="col-md-6 col-12 mt-3">
                        <label className="mb-1">
                          <Trans i18nKey="email" defaults="Email" />
                        </label>
                        <span className="text-danger">{"*"}</span>
                        <CareesEmailForm
                          // emails={getValues().emails}
                          // emails={[]}
                          emails={
                            getValues()?.emails ? getValues()?.emails : []
                          }
                          register={register}
                          setValue={setValue}
                          control={control}
                          errors={errors}
                          clearErrors={clearErrors}
                          handleSubmit={handleSubmit}
                          getValues={getValues}
                          setError={setError}
                        />
                      </div>
                      <div className="col-md-6 col-12 mt-3">
                        <label className="mb-1">
                          <Trans
                            i18nKey="phoneNumber"
                            defaults="Phone Number"
                          />
                        </label>
                        {/* <span className="text-danger">{"*"}</span> */}

                        <CareersPhoneNumberForm
                          // phoneNumbers={getValues().phones}
                          phoneNumbers={[]}
                          register={register}
                          setValue={setValue}
                          control={control}
                          clearErrors={clearErrors}
                          errors={errors}
                          handleSubmit={handleSubmit}
                          // required={required}
                          getValues={getValues}
                          setError={setError}
                        />
                        {/* <div className="d-flex">
            <input className="form-control mb-1" />
            <i className="fa fa-plus-circle pulscircle ms-2 pt-2"></i>
          </div> */}
                      </div>
                    </div>

                    <div className="row ">
                      <div className="col-12 mt-3">
                        <div className="form-check">
                          <input
                            className="form-check-input cursorpointer"
                            type="checkbox"
                            id="flexCheckChecked"
                            checked={isChecked} // Bind the checked state to the component's state
                            onChange={handleCheckboxChange}
                          ></input>
                          <label
                            className="form-check-label"
                            htmlFor="flexCheckChecked"
                          >
                            {/* <Trans
                              i18nKey="iAgreeToThePrivacyPolicyAndTermsAndConditions"
                              defaults="I AGREE TO THE PRIVACY POLICY AND TERMS AND CONDITIONS"
                            /> */}
                            <Trans
                              i18nKey="iAgreeToThe"
                              defaults="I AGREE TO THE"
                            />{" "}
                            <a
                              href="https://www.maprecruit.ai/data-privacy"
                              target="_blank"
                              className="linkTag"
                            >
                              <Trans
                                i18nKey="privacyPolicy"
                                defaults="PRIVACY POLICY"
                              />{" "}
                            </a>
                            <Trans i18nKey="and" defaults="AND" />{" "}
                            <a
                              href="https://www.maprecruit.ai/subscription-agreement"
                              target="_blank"
                              className="linkTag"
                            >
                              <Trans
                                i18nKey="termsAndConditions1"
                                defaults="TERMS AND CONDITIONS"
                              />{" "}
                            </a>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="row m-3">
                      <div className="col-12 mt-3 d-flex justify-content-center">
                        <button
                          className="btn btn-primary m-3"
                          onClick={handleSubmit(onSubmit)}
                        >
                          <Trans i18nKey="submit" defaults="Submit" />
                        </button>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    {!ProfileEditdetails ? (
                      <div className="row mt-5">
                        <div className="col-12 d-flex justify-content-center mb-3">
                          <img src="/assets/images/check.svg" width={80} />
                        </div>
                        <div className="col-12 d-flex justify-content-center">
                          <h2 className="text-primary text-capitalize heading">
                            {profiledata?.profile?.firstName && (
                              <>
                                <Trans i18nKey="hey" defaults="Hey" />{" "}
                                {profiledata?.profile?.firstName} !
                              </>
                            )}
                          </h2>
                        </div>
                        <div className="col-12 d-flex justify-content-center my-3">
                          <span>
                            <Trans
                              i18nKey="PleaseClicktheButtonBelowtoExploreifWe’reAMatch"
                              defaults="Please click the button below to explore if we’re a match."
                            />
                          </span>
                        </div>
                        <div className="col-12 d-flex justify-content-center">
                          <button
                            className="btn btn-primary px-3 py-2"
                            onClick={handledisplay}
                          >
                            <Trans defaults="Apply Now" i18nKey="applyNow" />
                          </button>
                        </div>
                        {!Mainmenudetails && (
                          <div className="col-12 my-3">
                            <div className="row align-items-center text-center">
                              <div className="col-md-1 col-xs-12"></div>
                              <div className="col-md-4 col-xs-12">
                                <CareersUploaderesume
                                  profiledata={profiledata}
                                  JobDetails={JobDetails}
                                  setEditresumedetails={setEditresumedetails}
                                  setProfileEditdetails={setProfileEditdetails}
                                  setProfiledata={setProfiledata}
                                  setResumeCookie={setResumeCookie}
                                />
                              </div>
                              <div className="col-md-2 col-12 my-2">
                                <Trans i18nKey="or" defaults="Or" />
                              </div>
                              <div className="col-md-4 col-12">
                                <p className="text-start text-muted mb-2">
                                  <Trans
                                    defaults="Create Resume"
                                    i18nKey="createResume"
                                  />
                                </p>
                                <button
                                  className="btn btn-secondary-soft  w-100"
                                  onClick={() => {
                                    setProfileEditdetails(true);
                                    setEditresumedetails(true);
                                  }}
                                >
                                  <Trans
                                    defaults="Create profile"
                                    i18nKey="createProfile"
                                  />
                                </button>
                              </div>
                              <div className="col-md-1 col-xs-12"></div>
                            </div>
                          </div>
                        )}
                      </div>
                    ) : (
                      <>
                        <CareersEditResume
                          JobDetails={JobDetails}
                          setProfileEditdetails={setProfileEditdetails}
                          ProfileEditdetails={ProfileEditdetails}
                          profiledata={profiledata}
                          Editresumedetails={Editresumedetails}
                          setEditresumedetails={setEditresumedetails}
                          setMainmenudetails={setMainmenudetails}
                          setProfiledata={setProfiledata}
                        />
                      </>
                    )}
                  </>
                )}
              </>
            )}

            {openDuplicate && (
              <React.Suspense
                fallback={
                  <Loader center speed="normal" size="sm" backdrop={true} />
                }
              >
                <DuplicateProfile
                  open={openDuplicate}
                  setOpen={setOpenDuplicate}
                  duplicateProfiles={duplicateProfiles}
                  campaignID={JobDetails?._id}
                  setresumedata={setresumedata}
                  type="showJob"
                  section1Ref={section1Ref}
                  scrollToSection={scrollToSection}
                  setnotduplicate={setnotduplicate}
                  createdublicateprofile={onSubmit}
                  createResumesLoading={createResumesLoading}
                  setinputdata={setinputdata}
                  page="guest"
                />
              </React.Suspense>
            )}

            {openLinkProfile && (
              <LinkProfile
                openLinkProfile={openLinkProfile}
                setOpenLinkProfile={setOpenLinkProfile}
                campaignID={JobDetails?._id}
                page={"guest"}
                resumeID={resumeCookie}
                setresumedata={setresumedata}
                type="showJob"
                setinputdata={setinputdata}
                section1Ref={section1Ref}
                scrollToSection={scrollToSection}
              />
            )}
          </div>
        </div>
      </div>
  );
}

export default CareersApplyNow;
