import React, { useEffect } from "react";
import { Controller } from "react-hook-form";
import { Trans } from "react-i18next";
import { t } from "i18next";

const YearsOfExperienceFormField = ({
  yearsOfExperienceData,
  control,
  resumeId,
  register,
  getValues,
  setValue,
  errors,
  fieldList,
}) => {
  useEffect(() => {
    if (yearsOfExperienceData) {
      setValue("yearsOfExperience", yearsOfExperienceData);
    }
  }, [yearsOfExperienceData]);
  return (
    <>
      <div className="form-group">
        <label htmlFor="" className="text-dark">
          {" "}
          {t(fieldList?.i18nKey, { defaultValue: fieldList?.fieldName })}
          {/* <Trans i18nKey="yearsOfExperience" defaults="YearsOfExperience" /> */}
        </label>
        {fieldList?.required && <span className="text-danger">{"*"}</span>}
        <Controller
          control={control}
          name={"yearsOfExperience"}
          refs={register("yearsOfExperience", {
            required: fieldList?.required ? true : false,
            max: 50,
            min: 0,
          })}
          render={({ field: { onChange, onBlur, value } }) => (
            <input
              type="number"
              className="form-control"
              name="yearsOfExperience"
              disabled={fieldList?.editable || !fieldList ? false : true}
              placeholder={
                fieldList
                  ? t("enter", { defaultValue: "Enter" }) +
                    " " +
                    t(fieldList?.i18nKey, {
                      defaultValue:
                        fieldList?.fieldName || "Enter years of experience",
                    })
                  : t("enter", { defaultValue: "Enter" }) +
                    " " +
                    t("yearsOfExperience", {
                      defaultValue: "Enter years of experience",
                    })
              }
              onChange={onChange}
              value={value}
            />
          )}
        />
        {errors.yearsOfExperience?.type === "required" && (
          <span className="text-danger mt-3">
            {errors?.yearsOfExperience?.message != "" ? (
              errors?.yearsOfExperience?.message
            ) : (
              <Trans
                i18nKey="yearsOfExperienceIsRequired"
                defaults="Years Of Experience is required"
              />
            )}{" "}
          </span>
        )}
        {errors.yearsOfExperience?.type === "max" && (
          <span className="text-danger">
            <Trans i18nKey="maxRequired" defaults="Max 50 required" />
          </span>
        )}
        {errors.yearsOfExperience?.type === "min" && (
          <span className="text-danger">
            <Trans i18nKey="minRequired" defaults="Min 0 required" />
          </span>
        )}
      </div>
    </>
  );
};

export default YearsOfExperienceFormField;
