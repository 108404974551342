import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { DatePicker } from "rsuite";
// import { useUserDetails } from "../../app/customHooks/UseUserDetails"; 
import { DateTime } from "luxon";

const UserDateInput = ({
  //   date,
  //   onChange = () => {},
  //   placement = "auto",
  //   placeholder = "Select date",
  setDateClear,
  refs = React.createRef(),
  ...props
}) => {


  // console.log("propsdjfnjdn", props);

  //   const [show, setShow] = useState(false);
  //   const [value, setValue] = useState(date);
  // const { userDateFormat, userTimeZone } = useUserDetails();

  //   const handleDateChange = (date) => {
  //     // setValue(date);
  //     // onChange(date);
  //   };
  useEffect(() => {
    if (setDateClear) {
      setDateClear(false);
    }
  }, []);
  // const Clean = () => {
  //   refs.current.value = "";
  // };
  return (
    <DatePicker
      //   ref={ref}
      //   placeholder={placeholder}
      //   placement={placement}
      //   value={value}
      oneTap 
      onChange={(date) => {
        refs.current.value = DateTime.fromJSDate(date)
          .setZone("utc")
          .toJSDate();
        if (props?.onChange) props?.onChange(date);
      }}

      // defaultValue={
      //   props?.defaultValue
      //     ? props?.defaultValue : null
      // }
      
      defaultValue={
        props?.defaultValue
          ? DateTime.fromJSDate(props?.defaultValue)
            .setZone("America/Chicago")
            .toFormat("yyyy-MM-dd")
          : null
      }
      // defaultValue={
      //   props?.defaultValue && props?.defaultValue !== null
      //     ? new Date(props?.defaultValue)
           
      //     : new Date()
      // }
      //   onToggle={setShow}
      //   show={show}
      // format={userDateFormat.dateFormat}

      format={"yyyy-MM-dd"}
      {...props}
      placement="autoVerticalStart"
      appearance="subtle"

      // onClean={() => {
      //   refs.current.value = "";
      // }}
    />
  );
};

// UserDateInput.propTypes = {
//   //   date: PropTypes.instanceOf(Date),
//   onChange: PropTypes.func,
//   //   placement: PropTypes.string,
//   //   placeholder: PropTypes.string,
//   refs: PropTypes.any,
//   defaultValue: PropTypes.instanceOf(Date),
//   setDateClear: PropTypes.any,
// };

export default UserDateInput;
