import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { Form } from 'react-bootstrap';
import { Trans } from 'react-i18next';
import { t } from 'i18next';
// import { useGetResumedbSchemaQuery } from '../../../services/resume/resumeDetailsService';
import { SelectPicker } from 'rsuite';
import usePost from '../../../customhooks/UsePost';

const WorkPermitFormField = ({ workPermitData, control, register, getValues, setValue, errors,fieldList,type}) => {
    useEffect(() => {
        if (workPermitData) {
            setValue("workPermit", workPermitData)
        }
    }, [workPermitData])
    const [workPermitDropDownValues, setWorkPermitDropDownValues] = useState([])
    const [resumeSchema, setResumeSchema] = useState({})
    const {
        post: schema,
        isLoading,
        error: err,
      } = usePost("/user/getresumedbschema"); 
    
      useEffect(()=>{
        schema({get: "schema"}).then((res)=>{
            setResumeSchema(res?.result?.resumeSchema)
        }).catch((error)=>{
          console.log(error)
        })
      },[]);

    useEffect(() => {
        if (resumeSchema) {
            console.log(resumeSchema, "resumeSchema");
            if (resumeSchema?.schema?.values?.workPermitStatus) {
                setWorkPermitDropDownValues(resumeSchema?.schema?.values?.workPermitStatus.map((x) => {
                    return {
                        label: x,
                        value: x
                    }

                }))
            }
        }
    }, [resumeSchema])




    return (
        <>
            <div className="form-group">
                <label className='text-dark'>
                    {t(fieldList?.i18nKey, { defaultValue: fieldList?.fieldName })}
                </label>
                {fieldList?.required && (
                  <span className="text-danger">{"*"}</span>
                )}
                <Controller
                    control={control}
                    name={"workPermit"}
                    refs={register("workPermit",{
                        required:fieldList?.required ? true : false,
                        validate: {
                          emptyValue: (v) =>
                            v && v != "" ? v.replace(/\s/g, "") != "" : true,
                        },
                    })}
                    render={({ field: { onChange, onBlur, value } }) => (
                        <SelectPicker
                            className="w-100"
                            data={workPermitDropDownValues}
                            placeholder={t("enter", { defaultValue: "Enter" }) + " " + t("workPermit", { defaultValue: "Work Permit" })}
                            cleanable={false}
                            onChange={onChange}
                            disabled={type=="customLayout" ||fieldList?.editable || !fieldList ? false : true}
                            value={value}
                            placement="auto"
                            preventOverflow={true}
                        />

                    )}
                />
            </div>
            {errors?.workPermit?.type == "required" && (
          <span className="text-danger">
            {errors?.workPermit?.message != "" ? (
              errors?.workPermit?.message
            ) : (
              <Trans
                i18nKey="workPermitIsRequired"
                defaults="workPermit is required"
              />
            )}{" "}
          </span>
        )}
        </>
    );
};

export default WorkPermitFormField;