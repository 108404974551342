import queryString from "query-string";
import React, { useEffect, useRef, useState } from "react";
import { InputGroup, Drawer, Button, Placeholder ,IconButton} from "rsuite";
import { Trans,useTranslation } from "react-i18next";
import SearchKey from "./SearchBarComponents/SearchKey";
import ExperenceKey from "./SearchBarComponents/ExperenceKey";
import LocationKey from "./SearchBarComponents/LocationKey";
import "./SearchBar.css";
import SearchAttributes from "./searchattributes/SearchAttributes";
import searchJsonConfig from "../../utilities/search.json";
import usePost from "../../customhooks/UsePost";
import {
  useLocation,
  useRouteLoaderData,
  useLoaderData,
} from "react-router-dom";
import { Loader } from "rsuite";
import SmallScreenSearch from "./SmallScreenSearch";
// import { set } from "react-hook-form";
/* import {selectedOptionsRef} from './searchattributes/LocationAttribute' */
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
export default function SearchBar({
  getJobs,
  searchJson,
  setJobsListData,
  setPage,
  JobsListData,
  setTotal,
  attributes,
  searchID,
  setSearchID,
  companyID,
  setSearchvalue,
  filtersData,
  isLoading,
  Page,
  limit,
  total,
  setShowJobDetails,
  showJobDetails,
}) {
  //let SearchBodyData = useLoaderData();
// console.log(showJobDetails,"showJobDetailsshowJobDetailsshowJobDetails")
 // console.log("slfjvhlsfji", searchJson);

  // console.log(SearchBodyData,"SearchDetails");
  const { t } = useTranslation();
  const location = useLocation();
  const [searchQuery, setSearchQuery] = useState(null);

  const locationObj = useRef({
    city: "",
    country: "",
    pin: { lat: 0, lon: 0 },
    state: "",
    zipcode: "",
  });
  const user = useRouteLoaderData("jobs");
  const {
    post: Searchattributes,
    data,
    isLoading: searchattributesLoading,
    error,
  } = usePost("/jobsearch/searchattributes");

  // const [attribute, setAttribute] = useState({
  //   searchKey: searchJson?.searchKey,
  //   companyID: searchJson?.companyID,
  // });
  const [attributeData, setAttributeData] = useState(attributes);
  const [sortOption, setSortOption] = useState(
    searchJson?.sort ?? [{ type: "date", order: "desc" }]
  );
  // useEffect(() => {
  //   if (
  //     (attribute?.searchKey !== "" && attribute?.searchKey !== undefined) ||
  //     (attribute?.companyID !== "" && attribute?.companyID !== undefined)
  //   ) {
  //     Searchattributes(attribute).then((res) => {
  //       setAttributeData(res?.data?.result);
  //     });
  //   }
  // }, [attribute?.searchKey]);

  const [searchBar1Values, setSearchBar1Values] = useState([]);
  const [workTypeAttribute, setworkTypeAttribute] = useState([]);
  const [industryTypeAttributes, setIndustryTypeAttributes] = useState([]);
  // const workTypeAttribute = React.useRef([]);
  const [searchBar2Value, setSearchBar2Value] = useState("");
  const [searchBar3Value, setSearchBar3Value] = useState("");
  const [validationError, setValidationError] = useState("");
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [isSearchMade, setIsSearchMade] = useState(false);

  // console.log(locationObj, "locationnnnn");

  /* console.log(searchBar3Value.toString(), 'searchBar3Values') */
  /*  console.log(attributes,"attributes123") */
  useEffect(() => {
    // const queryParams = queryString.parse(location.search);
    // console.log(queryParams)
    // const keywords = queryParams.keywords || [];
    // setSearchBar1Values(Array.isArray(keywords) ? keywords : [keywords]);
    // setSearchBar2Value(queryParams.experience || "");
    // // setSearchBar3Value(queryParams.location || "");

    // let locationObj = queryParams.location;
    // console.log(locationObj)
    // if(locationObj){
    //   locationObj = JSON.parse(locationObj);
    //   let text = "";
    //   if(locationObj.city){
    //     text += locationObj.city;
    //   }

    //   if(locationObj.state){
    //     text += locationObj.state;
    //   }

    //   if(locationObj.country){
    //     text += locationObj.country;
    //   }

    //   setSearchBar3Value(text)
    // }

    // console.log(queryParams)

    const queryString = window.location.search;
    const parameters = new URLSearchParams(queryString);
    // const value = parameters.get("k");

    // console.log(parameters.get("loc"))
    if (parameters.get("searchID")) {
      setSearchID(parameters.get("searchID"));
      //setIsSearchMade(true);
    }
    if (parameters.get("keywords")) {
      setSearchBar1Values(parameters.get("keywords").split(", "));
      setIsSearchMade(true);
    }

    if (parameters.get("experience")) {
      setSearchBar2Value(parameters.get("experience"));
      setIsSearchMade(true);
    }
    if (parameters.get("maxExp")) {
      setSearchBar2Value("6");
      setIsSearchMade(true);
    }
    if (parameters.get("industry")) {
      if(parameters.get("industry").includes("-")){
        let industryParam = parameters.get("industry").replace(/-/g, ' ');
        parameters.set("industry", industryParam);
        window.history.replaceState(
          {},
          "",
          `${window.location.pathname}?${parameters}`
        );
        setIndustryTypeAttributes([industryParam])
      }else{
        setIndustryTypeAttributes([parameters.get("industry")])
      }
      setIsSearchMade(true);
    }
    /* 
    if (parameters.get("location")) {
      setSearchBar3Value(parameters.get("location"));
      //setIsSearchMade(true);
    } */

    if (
      parameters.get("city") ||
      parameters.get("state") ||
      parameters.get("country") ||
      parameters.get("zipcode") ||
      parameters.get("locationtext") ||
      parameters.get("lat") ||
      parameters.get("lon")
    ) {
      const city = parameters.get("city") || "";
      const state = parameters.get("state") || "";
      const country = parameters.get("country") || "";
      const zipcode = parameters.get("zipcode") || "";

      locationObj.current = {
        city: city,
        state: state,
        country: country,
        zipcode: zipcode,
        pin: {
          lon: parameters.get("lon") ?? "",
          lat: parameters.get("lat") ?? "",
        },
        text: parameters.get("locationtext") ?? "",
      };
      const formattedLocation = `${city}${state ? " " + state : ""}${
        zipcode ? " " + zipcode : ""
      }  ${country ? " " + country : ""}`;
      if (parameters.has("locationtext")) {
        setSearchBar3Value(parameters.get("locationtext"));
      } else {
        setSearchBar3Value(formattedLocation);
      }
      setIsSearchMade(true);
    }

    // if (parameters.get("location") && searchBar3Value !== parameters.get("location")) {
    //   setSearchBar3Value(parameters.get("location"));
    // }
    // }

    /*  if (parameters.get("loc")) {
      locationObj.current = JSON.parse(parameters.get("loc"));
      setIsSearchMade(true);
    }

    console.log(parameters.get("loc")); */

    //setting selecteed options

    /*  if (parameters.get("city") || parameters.get("state") || parameters.get("country")) {
      const city = parameters.get("city") || "";
      const state = parameters.get("state") || "";
      const country = parameters.get("country") || "";
  
      const locationOptions = [];
  
      if (city) {
        locationOptions.push({ label: "City", value: city });
      }
  
      if (state) {
        locationOptions.push({ label: "State", value: state });
      }
  
      if (country) {
        locationOptions.push({ label: "Country", value: country });
      }
  
     
      setSelectedOptions(locationOptions);
  
      locationObj.current = {
        city: city,
        state: state,
        country: country
      };
  
      //const formattedLocation = `${city}${state ? ', ' + state : ''}${country ? ', ' + country : ''}`;
      setSearchBar3Value(locationObj.current);
    }


 */

    // setSearchQuery(queryParams);
  }, [location.search]);
  //console.log(first)
  // console.log(selectedOptions, "selectedOptions123");

  const search = () => {
    // const query = {};

    // if (searchBar1Values.length > 0) {
    //   query.keywords = searchBar1Values;
    // }

    // if (searchBar2Value) {
    //   query.experience = searchBar2Value;
    // }

    // if (searchBar3Value) {
    //   if (searchQuery.location) {
    //     searchQuery.location = JSON.parse(searchQuery.location);
    //     searchQuery.location.city = searchBar3Value;
    //     query.location = JSON.stringify(searchQuery.location);
    //   } else {
    //     query.location = JSON.stringify({
    //       city: searchBar3Value,
    //       state: "",
    //       country: "",
    //       pincode: "",
    //       pin: {
    //         lat: "",
    //         lon: "",
    //       },
    //     });
    //   }
    // }

    // if (Object.keys(query).length === 0) {
    //   setValidationError("Enter at least one  parameter");
    //   return;
    // }

    // setValidationError("");

    // const queryStringified = queryString.stringify(query);
    // window.history.replaceState({}, "", `?${queryStringified}`);
    // searchJson.searchKey = searchBar1Values;
    // searchJson.filters = [
    //   {
    //     type: "experience",
    //     maxExp: searchBar2Value,
    //     minExp: 0,
    //   },
    //   {
    //     type: "location",
    //     location: query.location,
    //   },
    // ];
    // searchJson.size = 10;
    // searchJson.page = 1;
    // searchJson.skip = 0;
    // searchJson.companyID = "552d016291bad07ccbb455fe";
    // getJobs(searchJson).then((res) => {
    //   setJobsListData(res?.data?.result ?? []);
    //   setTotal(res?.data?.totalResults ?? 0);
    // });
    // setPage(1);

    const query = {};

    if (searchBar1Values.length > 0) {
      query.keywords = searchBar1Values.join(", ");
    }

    if (searchBar2Value) {
      if (searchBar2Value == "6") {
        query.minExp = 5;
        query.maxExp = 50;
      } else {
        query.experience = searchBar2Value;
      }
      // query.experience = searchBar2Value;
    }

    /* if (searchBar3Value) {
      query.location = searchBar3Value;

      //  if (locationObj.current) {
       // query.loc = JSON.stringify(locationObj.current);
     // } 
    }  */

    if (searchBar3Value) {
      const { city, state, country, zipcode, pin, text } = locationObj.current;
      if (city) query.city = city;
      if (state) query.state = state;
      if (country) query.country = country;
      if (zipcode) query.zipcode = zipcode;
      if (pin?.lat) query.lat = pin?.lat;
      if (pin?.lon) query.lon = pin?.lon;
      if (text) query.locationtext = text;
    }
    // console.log(locationObj.current);

    setIsSearchMade(
      searchBar1Values.length > 0 || searchBar2Value || searchBar3Value
    );
    /*  if (Object.keys(query).length === 0) {
       setValidationError("Enter at least one keyword");
       return;
     } */

    // setValidationError("");
    //setIsSearchMade(true);

    // console.log(locationObj.current, "llllll");

    // console.log(searchJsonConfig.searchJSON);

    let obj = JSON.parse(JSON.stringify(searchJsonConfig.searchJSON));
    // console.log(obj, "obj1234");
    // console.log(searchJsonConfig.searchJSON, "searchJsonConfig.searchJSON");
    obj.searchKey = searchBar1Values;

    if (locationObj.current && searchBar3Value && searchBar3Value !== "") {
      obj.search.push({
        type: "location",
        location: [locationObj.current],
      });
    }

    if (parseInt(searchBar2Value) < 6) {
      obj.search.push({
        type: "experience",
        maxExp: searchBar2Value,
        minExp: 0,
      });
    }
    if (parseInt(searchBar2Value) === 6) {
      obj.search.push({
        type: "experience",
        maxExp: 50,
        minExp: 5,
      });
    }

    //sorting

    if (
      searchBar1Values.length > 0 ||
      searchBar2Value ||
      searchBar3Value != ""
    ) {
      obj.sort = [
        {
          type: "relevance",
          order: "desc",
        },
      ];
      setSortOption([{ type: "relevance", order: "desc" }]);
    } else {
      obj.sort = [
        {
          type: "date",
          order: "desc",
        },
      ];
      setSortOption([{ type: "date", order: "desc" }]);
    }

    obj.size = 6;
    obj.page = 1;
    obj.skip = 0;
    obj.companyID = companyID;
    const { search } = window.location;
    const params = new URLSearchParams(search);
    if (params.has("industry")) {
      searchJson.filter = [];
    }

    const queryStringified = queryString.stringify(query);
    window.history.replaceState({}, "", `?${queryStringified}`);
    //console.log(queryStringified,"mmmmmmm")

    getJobs(obj).then((res) => {
      setJobsListData(res?.data?.result ?? []);
      setSearchID(res?.searchID ?? "");
      setTotal(res?.data?.totalResults ?? 0);
      setAttributeData(res?.data?.attributesAll);
    });
    // setAttribute({
    //   searchKey: searchBar1Values,
    //   companyID: companyID,
    // });

    setPage(1);
    setSearchvalue(0);
    setSelectedOptions([]);
    setworkTypeAttribute([]);
    setIndustryTypeAttributes([]);
    setShowJobDetails(false);
  };
  /* console.log(selectedOptionsRef,'selectedOptionsRefin app') */

  const clearSearch = () => {
    searchJson.filter = [];
    setSelectedOptions([]);
    setworkTypeAttribute([]);
    setIndustryTypeAttributes([]);
    setSearchBar1Values([]);
    setSearchBar2Value("");
    setSearchBar3Value("");
    setShowJobDetails(false);

    setSortOption([{ type: "date", order: "desc" }]);
    //  setSelectedOptions([]);
    // setworkTypeAttribute([]);
    // setIndustryTypeAttributes([]);
    // setSearchID("");
    // setJobsListData([]);
    setIsSearchMade(false);

    let obj = {};
    obj.size = 6;
    obj.page = 1;
    obj.skip = 0;
    obj.newSearch = true;
    obj.companyID = companyID;
    obj.newSearch = true;
    obj.sort = [{ type: "date", order: "desc" }];
    obj.projection = [
      "createdAt",
      "title",
      "displayName",
      "job.details.jobTitle.text",
      "job.requirements.skills.skills.text",
      "job.details.locations",
      "job.details.jobTitle.jobType",
      "job.details.offeredSalary",
      "job.details.jobTiming",
      "job.details.workingHours",
      "job.details.jobDescription.text",
      "job.otherInformation.industry.classified.industryLabel",
      "customData.6112806bc9147f673d28c6eb.64abb309db7899f5908c77aa.value",
      "passcode",
    ];
    obj.SearchAttributes = false;
    const queryStringified = queryString.stringify({});
    window.history.replaceState({}, "", `?${queryStringified}`);

    /*  if (window.history.replaceState) {
       const urlWithoutQuery = window.location.href.split("?")[0];
       window.history.replaceState({}, "", urlWithoutQuery);
     } */

    getJobs(obj).then((res) => {
      setJobsListData(res?.data?.result ?? []);
      setSearchID(res?.searchID ?? "");
      setTotal(res?.data?.totalResults ?? 0);
      setAttributeData(res?.data?.attributesAll);
    });
    // setAttribute({
    //   searchKey: searchBar1Values,
    //   companyID: companyID,
    // });
    setPage(1);
    setSearchvalue(0);
    // setSelectedOptions([]);
    // setworkTypeAttribute([]);
    // setIndustryTypeAttributes([]);

    // window.location.reload();
  };

  useEffect(() => {
    if (searchID) {
      const queryString = window.location.search;
      const parameters = new URLSearchParams(queryString);
      parameters.set("searchID", searchID);
      if (parameters.has("page")) {
        parameters.set("page", 1);
      }
      if (parameters.has("index")) {
        parameters.set("index", 1);
      }
      window.history.replaceState(
        {},
        "",
        `${window.location.pathname}?${parameters}`
      );
    }
  }, [searchID]);

  return (
    <>
      <div className="d-flex justify-content-center">
        <div className="d-lg-block d-none">
          <InputGroup className="shadow container-div input-alignment  bg-white ">
            {/* <FontAwesomeIcon
            size="xl"
            icon={faMagnifyingGlass}
            className=" ms-3  h-20px  w-auto d-flex mx-auto"
          /> */}
            <i className="fa-solid fa-magnifying-glass ms-3  h-20px  w-auto d-flex mx-auto"></i>
            {/* <i class="fa-solid fa-location-dot"></i> */}

            <span className="ms-3">
              <SearchKey
                values={searchBar1Values}
                setValues={setSearchBar1Values}
                SearchBodyData={searchJson}
              />
            </span>

            <div className="separetor" />
            <ExperenceKey
              value={searchBar2Value}
              setValue={setSearchBar2Value}
            />
            <div className="separetor" />
            <LocationKey
              value={searchBar3Value}
              setValue={setSearchBar3Value}
              locationObj={locationObj}
            />
            <span>
              <button
                type="button"
                className="btn btn-primary  button-radius btnStyle"
                onClick={search}
                disabled={isLoading ? true : false}
              >
               <Trans i18nKey="search" defaults="Search" />
              </button>
            </span>
          </InputGroup>

          <div className="container-div input-alignment   mx-auto errorMargin ">
            {validationError && (
              <p className="error-message errorMargin">{validationError}</p>
            )}
          </div>
          <div className="clear-button me-4 text-end ">
            {(workTypeAttribute.length > 0 ||
              industryTypeAttributes.length > 0 ||
              selectedOptions.length > 0 ||
              isSearchMade) && (
              <a
                href="#"
                className="ms-2 button-radius clear-link"
                onClick={(e) => {
                  e.preventDefault();
                  clearSearch();
                }}
              >
                <Trans i18nKey="clear" defaults="Clear" />
              </a>
            )}
          </div>
        </div>
      </div>

      <div className="d-lg-block d-none">
        {JobsListData?.length > 0 && (
          <div>
            <SearchAttributes
              setSearchvalue={setSearchvalue}
              setSelectedOptions={setSelectedOptions}
              selectedOptions={selectedOptions}
              attributeData={attributeData}
              /*  handleSelectedLocations={handleSelectedLocations} */
              getJobs={getJobs}
              setJobsListData={setJobsListData}
              setPage={setPage}
              JobsListData={JobsListData}
              setTotal={setTotal}
              searchBar1Values={searchBar1Values}
              searchJson={searchJson}
              setAttributeData={setAttributeData}
              workTypeAttribute={workTypeAttribute}
              setworkTypeAttribute={setworkTypeAttribute}
              industryTypeAttributes={industryTypeAttributes}
              setIsSearchMade={setIsSearchMade}
              setIndustryTypeAttributes={setIndustryTypeAttributes}
              companyID={companyID}
              SearchBodyData={searchJson}
              Page={Page}
              limit={limit}
              total={total}
              isLoading={isLoading}
              setShowJobDetails={setShowJobDetails}
              sortOption={sortOption}
              setSortOption={setSortOption}
              // setOpenDrawer={setOpenDrawer}

            />
          </div>
        )}
      </div>
    

      

     <div className="d-lg-none d-block pt-0"  style={{ position: "sticky", top: "76px", zIndex: 98 }}>
     
         <SmallScreenSearch
          getJobs={getJobs}
          searchJson={searchJson}
          setJobsListData={setJobsListData}
          setPage={setPage}
          JobsListData={JobsListData}
          setTotal={setTotal}
          attributes={attributes}
          searchID={searchID}
          setSearchID={setSearchID}
          companyID={companyID}
          setSearchvalue={setSearchvalue}
          filtersData={filtersData}
          isLoading={isLoading}
          Page={Page}
          limit={limit}
          total={total}
          setShowJobDetails={setShowJobDetails}
          showJobDetails={showJobDetails}
          sortOption={sortOption}
          setSortOption={setSortOption}
          SearchBodyData={searchJson}
          
          searchBar1Values={searchBar1Values}
          setSearchBar1Values={setSearchBar1Values}
          searchBar2Value={searchBar2Value}
          setSearchBar2Value={setSearchBar2Value}
          searchBar3Value={searchBar3Value}
          setSearchBar3Value={setSearchBar3Value}
          locationObj ={locationObj}
          validationError={validationError}
          setValidationError={setValidationError}
          selectedOptions={ selectedOptions}
          setSelectedOptions={ setSelectedOptions}
          isSearchMade={isSearchMade}
          setIsSearchMade={setIsSearchMade}
          workTypeAttribute={workTypeAttribute}
          setworkTypeAttribute={setworkTypeAttribute}
          industryTypeAttributes={industryTypeAttributes}
          setIndustryTypeAttributes={setIndustryTypeAttributes}
          attributeData={attributeData}
          setAttributeData ={setAttributeData}         
         />
     
     </div>
     
     <div className="d-lg-none d-block">
     
     {(/* !workTypeAttribute.length > 0 ||
            !industryTypeAttributes.length > 0 ||
            !selectedOptions.length > 0 || */
            !isSearchMade) && (
              <h4 className="font-weight-bold text-primary">
              <Trans i18nKey="recentOpenings" defaults="Recent Openings" /> 
              </h4> 
          )}
    </div>

      <div className="d-lg-none d-block mt-3">
           <div className="d-flex justify-content-end">
              <b className="">
              {t("showing", { defaultValue: "Showing" })} {(Page - 1) * limit + 1} -
                {limit > total ? (
                  <span className="ps-1 pe-1"> {total} </span>
                ) : JobsListData.length < limit ? (
                  <span className="ps-1 pe-1">{total}</span>
                ) : (
                  <span className="ps-1 pe-1">{Page * limit}</span>
                )}
                 <Trans i18nKey="of" defaults="of" /> <span className="ps-1 pe-1">{total}</span><Trans i18nKey="jobs" defaults="Jobs" />
              </b>
       </div>
       </div>
  
      {/* <div className="d-lg-none d-block mt-0 pt-0">
          <div className="gray-bg p-4 shadow align-items-center d-flex flex-column">
            <h3 className="caption mb-4">
              Empower Your Journey: Forge Your Path to Success
            </h3>
            <div className="row justify-content-center align-items-center border rounded-pill bgColor rowheight shadow mb-1 inputWidth">
              <div className="col-12">
                <SearchKey
                  values={searchBar1Values}
                  setValues={setSearchBar1Values}
                  SearchBodyData={searchJson}
                />
              </div>
            </div>
            <div className="row justify-content-center align-items-center border rounded-pill bgColor rowheight shadow mb-1 inputWidth">
              <div className="col-12">
                <ExperenceKey
                  value={searchBar2Value}
                  setValue={setSearchBar2Value}
                />
              </div>
            </div>
            <div className="row justify-content-center align-items-center border rounded-pill bgColor rowheight shadow mb-3 inputWidth">
              <div className="col-12">
                <LocationKey
                  value={searchBar3Value}
                  setValue={setSearchBar3Value}
                  locationObj={locationObj}
                />
                {validationError && (
                  <p className="error-message">{validationError}</p>
                )}
              </div>
            </div>
            <div className="d-flex justify-content-start align-items-start mt-2">
              <button
                type="button"
                className="btn btn-primary button-radius shadow"
                onClick={search}
                disabled={isLoading ? true : false}
              >
                Search
              </button>

              {(workTypeAttribute.length > 0 ||
                industryTypeAttributes.length > 0 ||
                selectedOptions.length > 0 ||
                isSearchMade) && (
                <a
                  href="#"
                  className="ms-2 button-radius clear-link pt-1"
                  onClick={(e) => {
                    e.preventDefault();
                    clearSearch();
                  }}
                >
                  Clear
                </a>
              )}
            </div>
          </div>
        </div> */}
    </>
  );
}

