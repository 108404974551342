import React, { useEffect } from "react";
import { Modal, RadioGroup, Radio, useToaster, Notification } from "rsuite";
import { Button, Card, Spinner } from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClock,
  faEnvelope,
  faMobileScreen,
  faLocationDot,
} from "@fortawesome/free-solid-svg-icons";
import { Controller, useForm } from "react-hook-form";
import usePost from "../../customhooks/UsePost";
import { DateTime } from "luxon";

export default function DuplicateProfile({
  open,
  setOpen,
  duplicateProfiles,
  campaignID,
  setresumedata,
  type,
  page,
  setCookie,
  jobSeekerID,
  setResumeID,
  setupdatadataMri,
  section1Ref,
  scrollToSection,
  createdublicateprofile,
  setnotduplicate,
  createResumesLoading,
  setinputdata,
  setOpenCreateProfile,
  companyID
}) {
  const toaster = useToaster();
  const { t } = useTranslation();
  const { register, handleSubmit, control, setValue } = useForm();

  // console.log("sdvhshfdsf", duplicateProfiles)
  const { post: referral, isLoading: referralloading } = usePost(
    "/user/findReferralCount"
  );

  useEffect(() => {
    if (duplicateProfiles && duplicateProfiles.length > 0) {
      duplicateProfiles.map((x, index) => {
        if (index == 0) {
          setValue(`selectedProfile`, x?._id);
          setValue(
            `selecdeName`,
            x?.resume?.profile?.fullName || x?.resume?.profile?.firstName
          );
          setValue(`selecdeEmail`, x?.resume?.profile?.emails[0]?.text);
          setValue(`selecdePhone`, x?.resume?.profile?.phones[0]);
        }
      });
    }
  }, [duplicateProfiles]);

  // usePost
  const {
    post: linkProfileToCampaign,
    data: linkProfileToCampaignData,
    isLoading: linkProfileToCampaignLoading,
    error: linkProfileToCampaignError,
  } = usePost("/user/linkProfileToCampaign");

  const {
    post: attachDuplicateProfile,
    data: attachDuplicateProfileData,
    isLoading: attachDuplicateProfileLoading,
    error: attachDuplicateProfileError,
  } = usePost("/user/attachDuplicateProfile");

  const { post: setCookies } = usePost("/setcookie");
  const {
    post: guestSignUp,
    data: guestSignUpData,
    isLoading: guestSignUpLoading,
    error: guestSignUpError,
  } = usePost("/user/guestsignup");
  const onsubmit = (data) => {
    console.log("sfdjhsgdjfdf", data);
    if (page == "userbasicprofile") {
      let obj = {
        resumeID: data?.selectedProfile,
        jobSeekerID: jobSeekerID,
      };
      attachDuplicateProfile(obj).then((resp) => {
        if (
          resp?.result?.status &&
          resp?.result?.data?.resumeID &&
          resp?.result?.data?._id
        ) {
          setResumeID(resp?.result?.data?.resumeID);

          toaster.push(
            <Notification
              type={"success"}
              header={t("success", { defaultValue: "Success" })}
              closable
            >
              {t("resumeAttachedSuccessfully", {
                defaultValue: "Resume Attached Successfully",
              })}
            </Notification>,
            { placement: "topEnd" }
          );
          setOpen(false);
          setOpenCreateProfile(false);
        } else {
          toaster.push(
            <Notification
              type={"error"}
              header={t("error", { defaultValue: "Error" })}
              closable
            >
              {t("somethingWentWrongPleaseTryAgainLater", {
                defaultValue: "Something went wrong please try again later",
              })}
            </Notification>,
            { placement: "topEnd" }
          );
          setOpen(false);
          setOpenCreateProfile(false);
        }
      });
    } else if (page == "referalresume") {
      if (data?.selectedProfile) {
        setResumeID(data?.selectedProfile);
        // referral({
        //   resumeID: data?.selectedProfile,
        //   companyID: companyID
        // })
        //   .then((res) => {
        //     if (res?.status) {
        //       if (res?.count < 1) {
                toaster.push(
                  <Notification
                    type={"success"}
                    header={t("success", { defaultValue: "Success" })}
                    closable
                  >
                    {t("resumeAttachedSuccessfully", {
                      defaultValue: "Resume Attached Successfully",
                    })}
                  </Notification>,
                  { placement: "topEnd" }
                );
          //     }
          //   }
          // }).catch((error)=>{
          //   console.error("Error while fetching referral resume counttt:", error);
          // })
        setOpen(false);
        setOpenCreateProfile(false);
      }
    } else {
      let obj = {
        resumeID: data?.selectedProfile,
        campaignID: campaignID !== undefined ? campaignID : "",
      };

      linkProfileToCampaign(obj)
        .then((resp) => {
          // console.log("reponse", resp)
          if (resp?.status && resp?.result?.status) {
            // setting the resumeID in cookie for guest user
            if (page && page == "guest") {
              setCookies({
                cookieName: "Guest_User_ResumeID",
                cookieValue: data?.selectedProfile,
                expires: DateTime.now().plus({ months: 3 }).toISO(),
              })
                .then((res) => {
                  // console.log("cookie res", res);

                  if (res?.status) {
                    setCookie(data?.selectedProfile);
                    setupdatadataMri(true);
                  }
                })
                .catch((error) => {
                  console.log("Error in setCookie : ", error);
                });
            }

            toaster.push(
              <Notification
                type={"success"}
                header={t("success", { defaultValue: "Success" })}
                closable
              >
                {t("jobAppliedSuccessfully", {
                  defaultValue: "Job applied successfully",
                })}
              </Notification>,
              { placement: "topEnd" }
            );

            if (type == "showJob") {
              setinputdata(true);
              setresumedata(data?.selectedProfile);
              scrollToSection(section1Ref);
            } else {
              setOpenCreateProfile(false);
              guestSignUp({
                resumeID: data?.selectedProfile,
                email: data?.selecdeEmail,
                name: data?.selecdeName,
                phone: data?.selecdePhone,
              })
                .then((res) => {
                  if (res?.status) {
                    console.log("guest signup successfull");
                  }
                })
                .catch((error) => {
                  console.log("Error in guest signup : ", error);
                });

              let fullUrl;
              if (window.location.pathname.includes("/careers")) {
                fullUrl = window.location.origin + "/careers/myaccount/guest";
              } else {
                fullUrl = window.location.origin + "/myaccount/guest";
              }
              window.open(fullUrl, "_blank");
            }
            setOpen(false);
          } else {
            toaster.push(
              <Notification
                type={"error"}
                header={t("error", { defaultValue: "Error" })}
                closable
              >
                {t("somethingWentWrongPleaseTryAgainLater", {
                  defaultValue: "Something went wrong please try again later",
                })}
              </Notification>,
              { placement: "topEnd" }
            );
          }
        })
        .catch((error) => {
          console.log("error in linkProfileToCampaign :", error);
        });
    }
  };

  return (
    <Modal open={open} onClose={() => setOpen(false)} overflow={false}>
      <Modal.Header closeButton>
        <Modal.Title>
          <Trans i18nKey="duplicateProfiles" defaults="Duplicate Profiles" />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-3">
        <div className="duplicateProfiles">
          {duplicateProfiles &&
            duplicateProfiles?.length > 0 &&
            duplicateProfiles?.map((profile, index) => {
              return (
                <Controller
                  key={profile?._id}
                  control={control}
                  name={`selectedProfile`}
                  refs={register(`selectedProfile`)}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <RadioGroup
                      name="radioList"
                      onChange={onChange}
                      value={value || ""}
                    >
                      <div className="pb-2" key={profile?._id}>
                        <Card>
                          <Card.Header className="p-0">
                            <div className="row">
                              <div className="col-2 d-flex justify-content-center align-items-center">
                                <Radio
                                  defaultChecked={value ? true : false}
                                  value={profile?._id}
                                ></Radio>
                              </div>
                              <div className="col-10 ps-3 d-flex justify-content-start align-items-center fs-6 text-primary">
                                {profile?.resume?.profile?.fullName}
                              </div>
                            </div>
                          </Card.Header>
                          <Card.Body className="p-0">
                            <div className="row pb-1">
                              <div className="col-3 d-flex justify-content-end align-items-center">
                                <FontAwesomeIcon icon={faClock} />
                              </div>
                              <div className="col-9 px-4">
                                {
                                  profile?.resume?.professionalSummary
                                    ?.yearsOfExperience?.text
                                }
                              </div>
                            </div>
                            <div className="row pb-1">
                              <div className="col-3 d-flex justify-content-end align-items-center">
                                <FontAwesomeIcon icon={faEnvelope} />
                              </div>
                              <div className="col-9 px-4">
                                {profile?.resume?.profile?.emails[0]?.text}
                              </div>
                            </div>
                            <div className="row pb-1">
                              <div className="col-3 d-flex justify-content-end align-items-center">
                                <FontAwesomeIcon icon={faMobileScreen} />{" "}
                              </div>
                              <div className="col-9 px-4">
                                {profile?.resume?.profile?.phones[0]?.text}
                              </div>
                            </div>
                            <div className="row pb-1">
                              <div className="col-3 d-flex justify-content-end align-items-center">
                                <FontAwesomeIcon icon={faLocationDot} />{" "}
                              </div>
                              <div className="col-9 px-4">
                                {profile?.resume?.profile?.locations[0]?.text}
                              </div>
                            </div>
                            <div className="row pb-2">
                              <div className="col-4 d-flex justify-content-end px-1">
                                <Trans
                                  i18nKey="lastActive"
                                  defaults="Last Active"
                                />{" "}
                                {":"}
                              </div>
                              <div className="col-8 ps-0 d-flex justify-content-start">
                                {profile?.lastActiveAt != "" && (
                                  <span>
                                    {DateTime.fromISO(
                                      profile?.lastActiveAt
                                    ).toFormat("dd LLL yyyy")}
                                  </span>
                                )}
                              </div>
                            </div>
                          </Card.Body>
                        </Card>
                      </div>
                    </RadioGroup>
                  )}
                />
              );
            })}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          size="sm"
          // onClick={() => setOpen(false)}
          className="float-start"
          onClick={() => {
            setnotduplicate(true);
            if (type != "showJob") {
              setOpen(false);
            }
          }}
          disabled={createResumesLoading}
        >
          {createResumesLoading && (
            <Spinner animation="border" size="sm" className="me-1" />
          )}
          <Trans i18nKey="notDuplicate" defaults="Not Duplicate" />
        </Button>
        <Button
          variant="primary"
          size="sm"
          onClick={handleSubmit(onsubmit)}
          disabled={linkProfileToCampaignLoading}
        >
          {linkProfileToCampaignLoading && (
            <Spinner animation="border" size="sm" className="me-1" />
          )}
          {page == "userbasicprofile" ? (
            <Trans i18nKey="attach" defaults="Attach" />
          ) : (
            <Trans i18nKey="apply" defaults="Apply" />
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
