import React, { useEffect, useState } from "react";
// import { useParams, useLoaderData, useRouteLoaderData } from "react-router-dom";
import usePost from "../../customhooks/UsePost";
import { Radio, RadioGroup, Form, Checkbox, Loader, Placeholder } from "rsuite";
import { Trans } from "react-i18next";
import { Button } from "react-bootstrap";
import { useLoaderData, useSearchParams } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import axios from "axios";

function UnsubscribeEmail() {
  let data = useLoaderData();
  let { jobs } = useLoaderData();
  const [unsubcribeListData, setunsubcribeListData] = React.useState(
    jobs?.data ?? []
  );
  const [unsubscribeData, setunsubcribeData] = useState({})
  useEffect(() => {
    // console.log(data, "dfjhdfjhdahf")
    if (data) {
      setunsubcribeData(data)
    }
    setDataValues(data)

  }, [data])

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    // setError,
    control,
    setValue: searchValues,
    clearErrors,
    reset,
  } = useForm();

  const [checked, setChecked] = useState(false);
  // console.log(checked, "checkedchecked");
  const [searchParams] = useSearchParams();
  const [communicationID, setCommunicationID] = useState(null);
  const [campaignID, setCampaignID] = useState(null);
  const [showEmailTemplate, setshowEmailTemplate] = useState(true);
  const [showUnsubscribeData, setShowUnsubscribeData] = useState(false)
  const [alertMessage, setAlertMessage] = useState("")
  const [alertMessageTwo, setAlertMessageTwo] = useState("")
  const [showDeleteUnsubscribeData, setShowDeleteUnsubscribeData] = useState(false)
  const [channel, setChannel] = useState(null);
  const [dataValues, setDataValues] = useState(null)
  const [subscribecheckUrl, setSubscribecheckUrl] = useState(false)
  const[unsubscribeID ,setUnsubscribeID] =useState()
  const[unsubsribeOptionAll, setUnsubscribeOptionAll] =useState()
  const[unsubscribeOptionCompany ,setUnsubscribeOptionCompany] = useState()
  const[unsubscribeOptionCampaign ,setUnsubscribeOptionCampaign] = useState()


  useEffect(() => {
    if (unsubscribeData && unsubscribeData != "failed") {
      if (unsubscribeData.unsubscribeData) {
        let all = false;
        let campaignData = '';
        let companyData = '';
        if (unsubscribeData.unsubscribeData?.length > 0) {
          let flag = false;
          for (let i in unsubscribeData.unsubscribeData) {
            if (unsubscribeData.unsubscribeData[i].all) {
              all = true
              flag = true
              setUnsubscribeID(unsubscribeData.unsubscribeData[i]._id)
              setUnsubscribeOptionAll('all')
          } else if (unsubscribeData.unsubscribeData[i].companies.length > 0) {
            for (let j in unsubscribeData.unsubscribeData[i].companies) {
                if (unsubscribeData.unsubscribeData[i].companies[j]._id == unsubscribeData.companyID) {
                    companyData = unsubscribeData.unsubscribeData[i].companies[j];
                    setUnsubscribeID(unsubscribeData.unsubscribeData[i]._id)
                    setUnsubscribeOptionCompany(unsubscribeData.unsubscribeData[i].companies[j]._id)
                    flag = true
                }
            }
        } 
            else if (unsubscribeData.unsubscribeData[i].campaigns.length > 0) {
              for (let j in unsubscribeData.unsubscribeData[i].campaigns) {
                  if (unsubscribeData.unsubscribeData[i].campaigns[j]._id == campaignID) {
                      campaignData = unsubscribeData.unsubscribeData[i].campaigns[j];
                      flag = true
                      setUnsubscribeID(unsubscribeData.unsubscribeData[i]._id)
                      setUnsubscribeOptionCampaign(unsubscribeData.unsubscribeData[i].campaigns[j]._id);
                  }
              }
          }
            

            if (flag) {
              break;
            }
          }

          if (all) {
            setshowEmailTemplate(false)
            setShowUnsubscribeData(true)
            setShowDeleteUnsubscribeData(false)
          } else if (companyData != '') {
            setAlertMessage('you have already unsubscribed from this company');
            setAlertMessageTwo("")
            setshowEmailTemplate(false)
            setShowUnsubscribeData(true)
            setShowDeleteUnsubscribeData(false)
          } else if (campaignData != '') {
            setAlertMessage('you have already unsubscribed from this job')
            setAlertMessageTwo("")
            setshowEmailTemplate(false)
            setShowUnsubscribeData(true)
            setShowDeleteUnsubscribeData(false)
          } else {
            setshowEmailTemplate(true)
            setShowUnsubscribeData(false)
            setShowDeleteUnsubscribeData(false)
          }
        }
        else {
          setshowEmailTemplate(true)
          setShowUnsubscribeData(false)
          setShowDeleteUnsubscribeData(false)
        }
      }
    } else {
      setSubscribecheckUrl(true)
      setAlertMessageTwo("")
      setshowEmailTemplate(false)
      setShowUnsubscribeData(false)
      setShowDeleteUnsubscribeData(false)
    }
    // 
  }, [unsubscribeData])
  useEffect(() => {
    if (searchParams.get("communicationid") != null) {
      setCommunicationID(searchParams.get("communicationid"))
    }
    if (searchParams.get("cid") != null) {
      setCampaignID(searchParams.get("cid"))
    }
    if (searchParams.get("channel") != null) {
      setChannel(searchParams.get("channel"))
    }
  }, [searchParams])

  const getData = async () => {
    let data = await axios.get(
      window.location.origin +
      "/jsv4/webapi/unsubscribe/getCommunicationData",
      {
        params: {
          communicationID: communicationID,
          campaignID: campaignID,
        },
      }
    )
    // console.log(data?.data,"jihmfyugbyiejkmjui");
    setDataValues(data?.data)
  }
  const {
    post: updataUnsubscibe,
    isLoading: updateDataLoading,
  } = usePost("/unsubscribe/updateUnsubscribeDetails");
  const {
    post: deleteUnsubscribe,
    isLoading: deleteUnsubscribeLoading
  } = usePost("/unsubscribe/deleteUnsubscribeDetails")
  function unsubscribeEmailButton() {
    let communicationDetails = dataValues;
    let obj = {}
    let flag = false
    if (communicationDetails?.unsubscribeData?.length > 0) {
      obj['unsubscribeDocID'] = communicationDetails.unsubscribeData[0]._id
    }
    // if (communicationDetails.unsubscribeData[0]?.all != undefined) {
    //   obj['flag'] = 'all';
    // }
    // else if (communicationDetails.unsubscribeData[0]?.company != undefined) {
    //   obj['flag'] = 'company';
    //   obj['company'] = unsubscribeOptionCompany
    // }
    // else if (communicationDetails.unsubscribeData[0]?.campaign != undefined) {
    //   obj['flag'] = 'campaign';
    //   obj['campaign'] = campaignID
    // }
    if (communicationDetails.unsubscribeData[0].all) {
      obj['flag'] = 'all';
     }
     else if (communicationDetails.unsubscribeData[0].companies.length > 0) {
      for (let j in communicationDetails.unsubscribeData[0].companies) {
          if (communicationDetails.unsubscribeData[0].companies[j]._id == communicationDetails.companyID) {
                obj['flag'] = 'company';
                obj['company'] = communicationDetails?.unsubscribeData[0].companies[j]._id
                flag = true
          }
      }
  } 
  else if (communicationDetails.unsubscribeData[0].campaigns.length > 0) {
    for (let j in communicationDetails.unsubscribeData[0].campaigns) {
        if (communicationDetails.unsubscribeData[0].campaigns[j]._id == campaignID) {
          obj['flag'] = 'campaign';
          obj['campaign'] = communicationDetails.unsubscribeData[0].campaigns[j]._id
            flag = true
        }
    }
}
    if ("interviewID" in communicationDetails && communicationDetails.interviewID != "") {
      obj['interviewID'] = communicationDetails.interviewID
      flag = true;
    } else {
      obj['interviewID'] = communicationDetails.eventMetaData.interviewID;
      flag = true;
    }
    if ("eventMetaData" in communicationDetails && "screeningRoundNumber" in communicationDetails.eventMetaData && communicationDetails.eventMetaData.screeningRoundNumber) {
      obj['interviewRoundIndex'] = communicationDetails.eventMetaData.screeningRoundNumber;
      flag = true;
    } else {
      obj['interviewRoundIndex'] = communicationDetails.eventMetaData.screeningRoundIndex;
      flag = true;
    }
    if (flag) {
      obj['restoreProfile'] = true
      obj['interviewsCollection'] = communicationDetails.collection.interviews
      obj['channel'] = channel
    }
    if (obj.unsubscribeDocID) {
      deleteUnsubscribe(obj).then(async (res) => {
        // console.log(res,"nhxrhyfb");
        if (res) {
          setAlertMessage("Alrighty, we have removed your email address from our list.")
          setAlertMessageTwo("Having second thoughts?")
          setshowEmailTemplate(false)
          setShowUnsubscribeData(false)
          setShowDeleteUnsubscribeData(true)

          setTimeout(async () => {
            getData()
            setshowEmailTemplate(true)
            setShowUnsubscribeData(false)
            setShowDeleteUnsubscribeData(false)
          }, 1000);
        }
      });
    }
  }


  const submitUnsubscribeValues = () => {
    let unsubscribeQueryData = getValues();
    // console.log(unsubscribeQueryData, "unsubscribeQueryData");
    let communicationDetails = data;
    communicationDetails['company'] = data?.companyID;
    communicationDetails['communicationID'] = communicationID;
    communicationDetails['campaignID'] = campaignID
    if (data?.collection.interviews) {
      communicationDetails['interviewsCollection'] = data?.collection.interviews
    }
    if ("interviewID" in data && data?.interviewID != "") {
      communicationDetails['interviewID'] = data?.interviewID
    } else {
      communicationDetails['interviewID'] = data?.eventMetaData.interviewID;
    }
    if (data?.eventMetaData?.screeningRoundNumber) {
      communicationDetails['interviewRoundIndex'] = data?.eventMetaData.screeningRoundNumber;
    } else {
      communicationDetails['interviewRoundIndex'] = data?.eventMetaData.screeningRoundIndex;
    }
    communicationDetails["unsubscribeOption"] = unsubscribeQueryData?.unsubscribeOption
    communicationDetails["removeProfile"] = unsubscribeQueryData?.removeProfile

    communicationDetails["channel"] = channel
    updataUnsubscibe(communicationDetails).then(async (res) => {
      if (res) {
        getData()
        setshowEmailTemplate(false)
        setAlertMessage("Alrighty, we have removed your email address from our list.")
        setAlertMessageTwo("Having second thoughts?")
        setShowUnsubscribeData(true)
        setunsubcribeListData(res?.data ?? []);
      }
    });

  }

  return (
    <>
      <div className="row ">
        <div className="col-md-12 d-flex justify-content-center">
          <div className="card w-50 h-30px">
            <div className="card.Header">
              <h5 className="d-flex justify-content-center m-2"><Trans i18nKey="unsubscribeEmail" defaults="Unsubscribe email" /></h5>
            </div>
            {showEmailTemplate ? (
              <div className="card.body m-2">
                <div className="row">
                  <div className="col-12">
                    <Form.Group>
                      <Controller
                        control={control}
                        name="unsubscribeOption"
                        defaultValue={"campaign"}
                        refs={register("unsubscribeOption", { required: false })}
                        render={({ field: { onChange, onBlur, value } }) => (
                          <RadioGroup
                            // name="unsubscribeOption"
                            value={value}
                            onChange={(value) => {
                              onChange(value);
                            }}
                          >
                            <Radio value="campaign" ><Trans i18nKey="donotSendEmailsregardingthis" defaults="Do not send emails regarding this" /> {data?.campaignName}</Radio>
                            <Radio value="company"> <Trans i18nKey="donotSendemailsFromCoArthaTechnosolutions" defaults="Do not send emails from" /> {data?.companyName}</Radio>
                          </RadioGroup>
                        )}
                      />
                    </Form.Group>
                  </div></div>
                <div className="row">
                  <div className="col-12  p-1">
                    <Form.Group>
                      <Controller
                        control={control}
                        name="removeProfile"
                        defaultValue={false}
                        refs={register("removeProfile", { required: false })}
                        render={({ field: { onChange, onBlur, value } }) => (
                          <Checkbox onChange={(checked, value) => {
                            onChange(value ? true : false);
                          }}
                            checked={value} ><Trans i18nKey="removePersonalinformationFurthers" defaults="Remove Personal Information Further" /></Checkbox>
                        )}
                      />
                    </Form.Group>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 d-flex justify-content-center">
                    <Button
                      disabled={updateDataLoading}
                      onClick={handleSubmit(submitUnsubscribeValues)}
                      variant="primary" className="btn-sm">
                      {updateDataLoading ? (
                        <div
                          className="spinner-border spinner-border-sm me-1"
                          role="status"
                          aria-hidden="true"
                        ></div>
                      ) : (
                        <></>
                      )}
                      <Trans i18nKey="save" defaults="Save" />
                    </Button>
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}

            {showUnsubscribeData ? (
              <>
                {(updateDataLoading) ? (
                  <>
                    <div>
                      <Placeholder.Paragraph rows={8} />
                      <Loader center content="Loading..." />
                    </div>
                  </>
                ) : (
                  <>
                    <div className="card.body m-2">
                      <div className="row">
                        <div className="col-12">
                          <div className="row m-0 w-100 justify-content-center text-center">
                            <span>{alertMessage}</span>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="row m-0 w-100 justify-content-center text-center">
                            <span>{alertMessageTwo} </span>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 mt-3 d-flex justify-content-center">
                          <Button
                            onClick={unsubscribeEmailButton}
                            variant="primary" className="btn-sm">
                            {deleteUnsubscribeLoading ? (
                              <div
                                className="spinner-border spinner-border-sm me-1"
                                role="status"
                                aria-hidden="true"
                              ></div>
                            ) : (
                              <></>
                            )}
                            <Trans i18nKey="subscribe" defaults="Subscribe" />
                          </Button>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </>
            ) : (
              <></>
            )}
            {showDeleteUnsubscribeData ? (
              <div className="card.body m-2">
                <div className="row">
                  <div className="col-12">
                    <div className="row m-0 w-100 justify-content-center text-center">
                      <span>Thank you</span>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}
            {subscribecheckUrl ? (
              <div className="card.body m-2">
                <div className="row">
                  <div className="col-12">
                    <div className="row m-0 w-100 justify-content-center text-center">
                      <span>Please check the URL</span>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </>
  )
}
export default UnsubscribeEmail;