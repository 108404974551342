import { Uploader, Notification, toaster, Loader } from "rsuite";
import React, { useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import usePost from "../../../customhooks/UsePost";
import { Trans, useTranslation } from "react-i18next";
import { DateTime } from 'luxon';

function CareersUploaderesume({
  profiledata,
  JobDetails,
  setEditresumedetails,
  setProfileEditdetails,
  setProfiledata,
  setResumeCookie
}) {
  const [uploadedFile, setUploadedFile] = useState([]);
  const [profileResumeStatus, setProfileResumeStatus] = useState(false);
  const [fileID, setFileID] = useState("");
  const {
    register,
    control,
    formState: { errors },
    setError,
    clearErrors,
    handleSubmit,
    getValues,
  } = useForm();
  const uploader = React.createRef();

  const fileOnChange = (e) => {
    if (e.length > 1) {
      setUploadedFile([e[e.length - 1]]);
    } else {
      setUploadedFile(e);
    }
  };
  const { t } = useTranslation();

  function getBase64File(file) {
    if (file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result.split(";base64,")[1]);
        reader.onerror = (error) => reject(error);
      });
    }
  }
  const { post: setCookies } = usePost("/setcookie");
  const {
    post: uploadResumes,
    data: uploadresumeData,
    isLoading: uploadresumeLoading,
    error: uploadresumeError,
  } = usePost("/user/uploadCandiateResumes");

  // console.log(JobDetails);
  const uploadProfile = async () => {
    if (getValues()?.UploadProfiles?.length <= 1) {
      for (let i in getValues()?.UploadProfiles) {
        let file = getValues()?.UploadProfiles[i];
        if (file?.blobFile.size <= 2e6) {
          let fileType = file?.name?.split(".").pop().toLowerCase();
          let acceptedFileTypes = ["doc", "docx", "pdf", "txt"];
          if (acceptedFileTypes.includes(fileType)) {
            let base64Files = [];
            base64Files.push({
              originalname: file.name,
              size: file.blobFile.size,
              type: "docFile",
              path: "",
              base64File: await getBase64File(file.blobFile),
            });
            if (base64Files?.length > 0) {
              uploadResumes({
                base64Files: base64Files,
                resumeID: profiledata?.Resumedetails?._id,
                companyID: JobDetails?.companyID,
                clientID: JobDetails?.clientID,
                campaignID: JobDetails?._id,
                userID: JobDetails?.userID,
              })
                .then((resp) => {
                  console.log("response", resp);

                  if (resp?.status) {
                    if (resp?.result?.status && resp.result.status === true) {
                      // set resumeID in cookies
                      if (resp?.result?.resumeID && resp.result.resumeID != "") {
                        setCookies({
                          cookieName: "Guest_User_ResumeID",
                          cookieValue: resp?.result?.resumeID,
                          expires: DateTime.now().plus({ months: 3 }).toISO()
                        }).then(async (res) => {
                          console.log("cookie res", res);

                          if (res?.status) {
                            setResumeCookie(resp?.result?.resumeID)
                          }
                        }).catch((error) => {
                          console.log("Error in setCookie : ", error);
                        })
                      }

                      toaster.push(
                        <Notification
                          type={"success"}
                          header={t("success", { defaultValue: "success" })}
                          closable
                        >
                          {t("jobAppliedSuccessfully", {
                            defaultValue: "Job applied Successfully",
                          })}
                        </Notification>,
                        { placement: "topEnd" }
                      );

                      // console.log(resp?.result?.Resumedetails ,"sertyui9o");
                      if (resp?.result?.Resumedetails) {
                        setProfiledata(resp?.result);
                      }
                      setProfileEditdetails(true);
                      setEditresumedetails(true);
                    } else {
                      if (
                        resp?.result?.message.includes("Campaign is not active")
                      ) {
                        toaster.push(
                          <Notification
                            type={"error"}
                            header={t("error", { defaultValue: "Error" })}
                            closable
                          >
                            {t("thisJobIsNoLongerAcceptingApplications", {
                              defaultValue:
                                "This job is no longer accepting applications",
                            })}
                          </Notification>,
                          { placement: "topEnd" }
                        );
                      } else {
                        toaster.push(
                          <Notification
                            type={"error"}
                            header={t("error", { defaultValue: "Error" })}
                            closable
                          >
                            {t("somethingWentWrongPleaseTryAgainLater", {
                              defaultValue:
                                "Something went wrong please try again later",
                            })}
                          </Notification>,
                          { placement: "topEnd" }
                        );
                      }
                    }
                  } else {
                    toaster.push(
                      <Notification
                        type={"error"}
                        header={t("error", { defaultValue: "Error" })}
                        closable
                      >
                        {t("somethingWentWrongPleaseTryAgainLater", {
                          defaultValue:
                            "Something went wrong please try again later",
                        })}
                      </Notification>,
                      { placement: "topEnd" }
                    );
                  }
                })
                .catch((error) => {
                  // console.log("error", error)
                });
            }
          } else {
            toaster.push(
              <Notification
                type={"error"}
                header={t("error", { defaultValue: "Error" })}
                closable
              >
                {t("pleaseUploadTheAcceptedFileFormats", {
                  defaultValue: "Please upload the accepted file formats",
                })}
              </Notification>,
              { placement: "topEnd" }
            );
          }
        } else {
          toaster.push(
            <Notification
              type={"error"}
              header={t("error", { defaultValue: "Error" })}
              closable
            >
              {t("pleaseUploadAFileLessThanMb", {
                defaultValue: "Please upload a file less than 2Mb",
              })}
            </Notification>,
            { placement: "topEnd" }
          );
        }
      }
    } else {
      toaster.push(
        <Notification
          type={"error"}
          header={t("error", { defaultValue: "Error" })}
          closable
        >
          {t("uploadingFilesLimitIs", {
            defaultValue: "Uploading files limit is 1",
          })}
        </Notification>,
        { placement: "topEnd" }
      );
    }
  };
  return (
    <div>
      <p className="text-start text-muted mb-2">
        <Trans i18nKey="uploadResume" defaults="Upload Resume" />
      </p>
      <div className="">
        <Controller
          control={control}
          name={"UploadProfiles"}
          refs={register("UploadProfiles", {
            required: true,
          })}
          render={({ field: { onChange, onBlur, value } }) => (
            <Uploader
              action=""
              autoUpload={false}
              accept=".doc, .docx, .pdf,.txt"
              fileListVisible={true}
              onChange={(e) => {
                onChange(e);
                fileOnChange(e);
              }}
              ref={uploader}
              fileList={uploadedFile}
            >
              <div
                className="d-flex"
                style={{ display: "flex", /* height: "40px" */ }}
              >
                <label
                  className="form-control border-end-0 rounded-end-0"
                // style={{ height: "40px", width: "150px" }}
                >
                  <Trans i18nKey="chooseFile" defaults="Choose file" />
                </label>

                <span className="input-group-text border-start-0 rounded-start-0 Browse">
                  <Trans i18nKey="browse" defaults="Browse" />
                </span>
              </div>
            </Uploader>
          )}
        />
      </div>
      {getValues("UploadProfiles")?.length > 0 && (
        <div className="d-flex justify-content-center">
          <button
            className="btn btn-primary d-flex align-items-center"
            onClick={handleSubmit(uploadProfile)}
            disabled={uploadresumeLoading ? true : false}
          >
            {uploadresumeLoading && <Loader className="pe-2" />}
            <Trans i18nKey="upload" defaults="upload" />
          </button>
        </div>
      )}
    </div>
  );
}

export default CareersUploaderesume;
