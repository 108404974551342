import React, { useState, useEffect } from "react";
import { Whisper, Tooltip, Popover } from "rsuite";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faGraduationCap,
  faBuildingColumns,
  faLocationDot,
  faCalendarDays,
  faPercent,
  faStopwatch,
  faIdBadge,
} from "@fortawesome/free-solid-svg-icons";
import "../../MyAccount.css";
import { Trans, useTranslation } from "react-i18next";
import PopOverContent from "../forms/PopOverContent";
import { useForm } from "react-hook-form";
import EducationSessionEdit from "../forms/EducationSessionEdit";
import { v4 as uuidv4 } from "uuid";

const Education = ({
  educationRows,
  resumeId,
  standardFieldsList,
  status,
  resumeData,
  setUpdateResumeDataData,
  scrollRef,
  setscrollRef,
}) => {
  // console.log(standardFieldsList,"standardFieldsListstandardFieldsList")
  const { t } = useTranslation();
  const {
    control,
    register,
    setValue,
    handleSubmit,
    formState: { errors },
    setError,
    clearErrors,
    getValues,
  } = useForm();
  const [open, setOpen] = React.useState(false);
  const handleEducationOpen = () => setOpen(true);
  const [educationRowsList, setEducationRows] = useState([]);

  useEffect(() => {
    setEducationRows(educationRows);
  }, []);
  const handleMerit = (meritData) => {
    let meritValue;
    if (meritData?.percentage && meritData?.percentage != 0) {
      meritValue = meritData?.percentage;
    } else if (meritData?.GPA && meritData?.GPA != 0) {
      meritValue = meritData?.GPA;
    } else {
      meritValue = <Trans i18nKey="notSpecified" defaults="Not Specified" />;
    }
    return meritValue;
  };
useEffect(()=>{
if(scrollRef=="education"){
  handleEducationOpen()
}
},[scrollRef])
  return (
    <>
      <div className="card h-100">
        <div className="card-body ">
          <div className="row">
            <div className="col-10 d-flex  align-items-center">
              <h6 className="text-primary m-0">
                <Trans i18nKey="education" defaults="Education" />
              </h6>
            </div>

            <div className="col-2 justify-content-end d-flex">
              {/* <EducationSessionEdit educationRowsList={educationRowsList} control={control} register={register} setValue={setValue} errors={errors} getValues={getValues} educationList={resumeData?.resume?.professionalQualification?.education} handleSubmit={handleSubmit} /> */}
              {standardFieldsList?.editable &&
              status != "MERGED" &&
              status != "DISQUALIFIED" &&
              status != "DELETED" ? (
                <>
                  <span
                    className="IconArea actionIcon actionIcon-sm"
                    onClick={handleEducationOpen}
                  >
                    <FontAwesomeIcon className="mx-2" icon={faEdit} />
                  </span>
                </>
              ) : null}
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              {resumeData?.resume?.professionalQualification?.education
                ?.length > 0 ? (
                resumeData?.resume?.professionalQualification?.education?.map(
                  (keyData, index) => {
                    return (
                      <div className="row" key={uuidv4()}>
                        <div className="col-12">
                          <div
                            className={
                              resumeData?.resume?.professionalQualification
                                ?.education?.length != 1
                                ? "border-bottom"
                                : ""
                            }
                          >
                            <div className="details mt-2">
                              {educationRowsList.map((sections, index) => {
                                let colNumber = 12;
                                if (sections?.columns.length > 0) {
                                  colNumber = 12 / sections?.columns.length;
                                }
                                return (
                                  
                                    <div className="row mb-3" key={uuidv4()}>
                                      {sections?.columns.map((item, index) => {
                                        return (
                                          <div
                                            className={"col-" + colNumber}
                                            key={uuidv4()}
                                          >
                                            {item?.fieldCode == "Campus" ? (
                                              // <div className="row">
                                              <span className="d-flex">
                                                <Whisper
                                                  followCursor
                                                  placement={"bottom"}
                                                  speaker={
                                                    <Tooltip>
                                                      <Trans
                                                        i18nKey="campus"
                                                        defaults="Campus"
                                                      />
                                                    </Tooltip>
                                                  }
                                                >
                                                  <span className="displayIcon displayIcon-sm">
                                                    <FontAwesomeIcon
                                                      icon={faBuildingColumns}
                                                    />
                                                  </span>
                                                </Whisper>
                                                <b className="px-1">
                                                  {t(item?.i18nKey, {
                                                    defaultValue:
                                                      item?.fieldName,
                                                  })}
                                                </b>
                                                <span className="textOverFlow" title={keyData?.campus?.text != "" ? (
                                                  keyData?.campus?.text
                                                ) : "Not Specified"}>
                                                {keyData?.campus?.text != "" ? (
                                                  keyData?.campus?.text
                                                ) : (
                                                  <Trans
                                                    i18nKey="notSpecified"
                                                    defaults="Not Specified"
                                                  />
                                                )}
                                                </span>
                                              </span>
                                            ) : null}
                                            {item?.fieldCode == "University" ? (
                                              <span className="d-flex">
                                                <Whisper
                                                  followCursor
                                                  placement={"bottom"}
                                                  speaker={
                                                    <Tooltip>
                                                      <Trans
                                                        i18nKey="university"
                                                        defaults="University"
                                                      />
                                                    </Tooltip>
                                                  }
                                                >
                                                  <span className="displayIcon displayIcon-sm">
                                                    <FontAwesomeIcon
                                                      icon={faBuildingColumns}
                                                    />
                                                  </span>
                                                </Whisper>
                                                <b className="px-1">
                                                  {t(item?.i18nKey, {
                                                    defaultValue:
                                                      item?.fieldName,
                                                  })}
                                                </b>
                                                <span className="textOverFlow" title={keyData?.university?.text != "" ? (
                                                  keyData?.university?.text
                                                ) : "Not Specified"}>
                                                {keyData?.university?.text !=
                                                "" ? (
                                                  keyData?.university?.text
                                                ) : (
                                                  <Trans
                                                    i18nKey="notSpecified"
                                                    defaults="Not Specified"
                                                  />
                                                )}
                                                </span>
                                              </span>
                                            ) : null}
                                            {item?.fieldCode == "Degree" ? (
                                              <span className="d-flex">
                                                <Whisper
                                                  followCursor
                                                  placement={"bottom"}
                                                  speaker={
                                                    <Tooltip>
                                                      <Trans
                                                        i18nKey="degree"
                                                        defaults="Degree"
                                                      />
                                                    </Tooltip>
                                                  }
                                                >
                                                  <span className="displayIcon displayIcon-sm">
                                                    <FontAwesomeIcon
                                                      icon={faGraduationCap}
                                                    />
                                                  </span>
                                                </Whisper>
                                                <b className="px-1">
                                                  {t(item?.i18nKey, {
                                                    defaultValue:
                                                      item?.fieldName,
                                                  })}
                                                </b>
                                                <span className="textOverFlow" title={keyData?.degree?.text != "" ? (
                                                  keyData?.degree?.text
                                                ) : "Not Specified"}>
                                                {keyData?.degree?.text != "" ? (
                                                  keyData?.degree?.text
                                                ) : (
                                                  <Trans
                                                    i18nKey="notSpecified"
                                                    defaults="Not Specified"
                                                  />
                                                )}
                                                </span>
                                              </span>
                                            ) : null}
                                            {item?.fieldCode == "Major" ? (
                                              <span className="">
                                                <span>
                                                  <Whisper
                                                    followCursor
                                                    placement={"bottom"}
                                                    speaker={
                                                      <Tooltip>
                                                        {t(item?.i18nKey, {
                                                          defaultValue:
                                                            item?.fieldName,
                                                        })}
                                                      </Tooltip>
                                                    }
                                                  >
                                                    <span className="displayIcon displayIcon-sm">
                                                      <FontAwesomeIcon
                                                        icon={faGraduationCap}
                                                      />
                                                    </span>
                                                  </Whisper>
                                                </span>
                                                <b className="px-1">
                                                  {t(item?.i18nKey, {
                                                    defaultValue:
                                                      item?.fieldName,
                                                  })}
                                                </b>
                                                <div>
                                                {keyData?.major.length > 0 ? (
                                                  <>
                                                    {keyData?.major?.length ==
                                                    1 ? (
                                                      <span
                                                        key={uuidv4()}
                                                        className="me-1 textOverFlow" title={keyData?.major[0]?.text != "" ? (
                                                          keyData?.major[0]?.text
                                                        ) : "Not Specified"}
                                                      >
                                                        {
                                                          keyData?.major[0]
                                                            ?.text
                                                        }
                                                      </span>
                                                    ) : (
                                                      <>
                                                        <span
                                                          key={uuidv4()}
                                                          className="me-1 textOverFlow" title={keyData?.major[0]?.text != "" ? (
                                                            keyData?.major[0]?.text
                                                          ) : "Not Specified"}                                                        >
                                                          {
                                                            keyData?.major[0]
                                                              ?.text
                                                          }
                                                        </span>
                                                        <Whisper
                                                          followCursor
                                                          placement="auto"
                                                          trigger={"click"}
                                                          speaker={
                                                            <Popover className="popoverData">
                                                              <PopOverContent
                                                                target={"major"}
                                                                list={
                                                                  keyData.major
                                                                }
                                                              />
                                                            </Popover>
                                                          }
                                                        >
                                                          <span className="badge bd-primary p-1 font-size-9pt px-2 rounded-pill cursor-pointer">
                                                            {"+"}
                                                            {keyData?.major
                                                              ?.length - 1}
                                                          </span>
                                                        </Whisper>
                                                      </>
                                                    )}
                                                  </>
                                                ) : (
                                                  <Trans
                                                    i18nKey="notSpecified"
                                                    defaults="Not Specified"
                                                  />
                                                )}
                                                </div>
                                              </span>
                                            ) : null}
                                            {item?.fieldCode == "Minor" ? (
                                              <span className="">
                                                <span>
                                                  {/* <b>{t(item?.fieldName, { defaultValue: item?.fieldName })}: </b> */}
                                                  <Whisper
                                                    followCursor
                                                    placement={"bottom"}
                                                    speaker={
                                                      <Tooltip>
                                                        {t(item?.i18nKey, {
                                                          defaultValue:
                                                            item?.fieldName,
                                                        })}
                                                      </Tooltip>
                                                    }
                                                  >
                                                    <span className="displayIcon displayIcon-sm">
                                                      <FontAwesomeIcon
                                                        icon={faGraduationCap}
                                                      />
                                                    </span>
                                                  </Whisper>
                                                </span>
                                                
                                                <b className="px-1">
                                                  {t(item?.i18nKey, {
                                                    defaultValue:
                                                      item?.fieldName,
                                                  })}
                                                </b>
                                                
                                                <div>
                                                {keyData?.minor?.length > 0 ? (
                                                  <>
                                                    {keyData?.minor?.length ==
                                                    1 ? (
                                                      <span
                                                        key={uuidv4()}
                                                        className="me-1 textOverFlow" title={keyData?.minor[0]?.text != "" ? (
                                                          keyData?.minor[0]?.text
                                                        ) : "Not Specified"}                                                      >
                                                        {
                                                          keyData?.minor[0]
                                                            ?.text
                                                        }
                                                      </span>
                                                    ) : (
                                                      <>
                                                        <span
                                                          key={uuidv4()}
                                                          className="me-1 textOverFlow" title={keyData?.minor[0]?.text != "" ? (
                                                            keyData?.minor[0]?.text
                                                          ) : "Not Specified"}                                                        >
                                                          {
                                                            keyData?.minor[0]
                                                              ?.text
                                                          }
                                                        </span>
                                                        <Whisper
                                                          followCursor
                                                          placement="auto"
                                                          trigger={"click"}
                                                          speaker={
                                                            <Popover className="popoverData">
                                                              <PopOverContent target={"major"} list={keyData?.minor} />
                                                            </Popover>
                                                          }
                                                        >
                                                          <span className="badge bd-primary p-1 font-size-9pt px-2 rounded-pill cursor-pointer">
                                                            {"+"}
                                                            {keyData?.minor
                                                              ?.length - 1}
                                                          </span>
                                                        </Whisper>
                                                      </>
                                                    )}
                                                  </>
                                                ) : (
                                                  <Trans
                                                    i18nKey="notSpecified"
                                                    defaults="Not Specified"
                                                  />
                                                )}
                                                </div>
                                              </span>
                                            ) : (
                                              <></>
                                            )}
                                            {item?.fieldCode ==
                                            "Specialization" ? (
                                              <span>
                                                <span>
                                                  <b>
                                                    {t(item?.i18nKey, {
                                                      defaultValue:
                                                        item?.fieldName,
                                                    })}
                                                    {":"}{" "}
                                                  </b>
                                                </span>
                                                <span className="textOverFlow" title={keyData?.course?.text != "" ? (
                                                  keyData?.course?.text
                                                ) : "Not Specified"}>
                                                {keyData?.course?.text != "" ? (
                                                  keyData?.course?.text
                                                ) : (
                                                  <Trans
                                                    i18nKey="notSpecified"
                                                    defaults="Not Specified"
                                                  />
                                                )}
                                                </span>
                                              </span>
                                            ) : null}
                                            {item?.fieldCode == "Merit" ? (
                                              <span className="d-flex">
                                                <Whisper
                                                  followCursor
                                                  placement={"bottom"}
                                                  speaker={
                                                    <Tooltip>
                                                      <Trans
                                                        i18nKey="merit"
                                                        defaults="Merit"
                                                      />
                                                    </Tooltip>
                                                  }
                                                >
                                                  <span className="displayIcon displayIcon-sm">
                                                    <FontAwesomeIcon
                                                      icon={faPercent}
                                                    />
                                                  </span>
                                                </Whisper>
                                                <b className="px-1">
                                                  {t(item?.i18nKey, {
                                                    defaultValue:
                                                      item?.fieldName,
                                                  })}
                                                </b>
                                                {handleMerit(keyData?.merit)}

                                                {/* {keyData?.merit?.GPA != ""
                                                  ? keyData?.merit?.GPA
                                                  : <Trans
                                                    i18nKey="notSpecified"
                                                    defaults="Not Specified"
                                                  />} */}
                                              </span>
                                            ) : null}
                                            {item?.fieldCode == "Edu Status" ? (
                                              <span className="d-flex">
                                                {/* <span> */}
                                                {/* <b>{t(item?.fieldName, { defaultValue: item?.fieldName })}: </b> */}
                                                <Whisper
                                                  followCursor
                                                  placement={"bottom"}
                                                  speaker={
                                                    <Tooltip>
                                                      {t(item?.i18nKey, {
                                                        defaultValue:
                                                          item?.fieldName,
                                                      })}
                                                    </Tooltip>
                                                  }
                                                >
                                                  <span className="displayIcon displayIcon-sm">
                                                    <FontAwesomeIcon
                                                      icon={faIdBadge}
                                                    />
                                                  </span>
                                                </Whisper>
                                                {/* </span> */}
                                                <b className="px-1">
                                                  {t(item?.fieldName, {
                                                    defaultValue:
                                                      item?.fieldName,
                                                  })}
                                                </b>
                                                <span className="textOverFlow" title={keyData?.currentStatus !=
                                                "" ? (
                                                  keyData?.currentStatus
                                                ) : "Not Specified"}>
                                                {keyData?.currentStatus !=
                                                "" ? (
                                                  keyData?.currentStatus
                                                ) : (
                                                  <Trans
                                                    i18nKey="notSpecified"
                                                    defaults="Not Specified"
                                                  />
                                                )}
                                                </span>
                                              </span>
                                            ) : null}
                                            {item?.fieldCode ==
                                            "Edu Start Date" ? (
                                              <span className="d-flex">
                                                <Whisper
                                                  followCursor
                                                  placement={"bottom"}
                                                  speaker={
                                                    <Tooltip>
                                                      <Trans
                                                        i18nKey="startDate"
                                                        defaults="Start Date"
                                                      />
                                                    </Tooltip>
                                                  }
                                                >
                                                  <span className="displayIcon displayIcon-sm">
                                                    <FontAwesomeIcon
                                                      icon={faCalendarDays}
                                                    />
                                                  </span>
                                                </Whisper>
                                                <b className="px-1">
                                                  {t(item?.i18nKey, {
                                                    defaultValue:
                                                      item?.fieldName,
                                                  })}
                                                </b>
                                                {keyData?.startDate?.standard !=
                                                "" ? (
                                                  <>
                                                    {keyData?.startDate
                                                      ?.standard != "" &&
                                                      keyData?.startDate
                                                        ?.standard}
                                                  </>
                                                ) : (
                                                  <span>
                                                    <Trans
                                                    i18nKey="notSpecified"
                                                    defaults="Not Specified"
                                                  />
                                                  </span>
                                                )}
                                              </span>
                                            ) : null}
                                            {item?.fieldCode ==
                                            "Edu End Date" ? (
                                              <span className="d-flex">
                                                <Whisper
                                                  followCursor
                                                  placement={"bottom"}
                                                  speaker={
                                                    <Tooltip>
                                                      <Trans
                                                        i18nKey="endDate"
                                                        defaults="End Date"
                                                      />
                                                    </Tooltip>
                                                  }
                                                >
                                                  <span className="displayIcon displayIcon-sm">
                                                    <FontAwesomeIcon
                                                      icon={faCalendarDays}
                                                    />
                                                  </span>
                                                </Whisper>
                                                <b className="px-1">
                                                  {t(item?.i18nKey, {
                                                    defaultValue:
                                                      item?.fieldName,
                                                  })}
                                                </b>
                                                {keyData?.endDate?.standard !=
                                                "" ? (
                                                  <>
                                                    {keyData?.endDate
                                                      ?.standard != "" &&
                                                      keyData?.endDate
                                                        ?.standard}
                                                  </>
                                                ) : (
                                                  <span>
                                                    {" "}
                                                    <Trans
                                                    i18nKey="notSpecified"
                                                    defaults="Not Specified"
                                                  />
                                                  </span>
                                                )}
                                              </span>
                                            ) : null}
                                            {item?.fieldCode ==
                                            "Edu Location" ? (
                                              <span className="d-flex">
                                                <Whisper
                                                  followCursor
                                                  placement={"bottom"}
                                                  speaker={
                                                    <Tooltip>
                                                      <Trans
                                                        i18nKey="location"
                                                        defaults="Location"
                                                      />
                                                    </Tooltip>
                                                  }
                                                >
                                                  <span className="displayIcon displayIcon-sm">
                                                    <FontAwesomeIcon
                                                      icon={faLocationDot}
                                                    />
                                                  </span>
                                                </Whisper>
                                                <b className="px-1">
                                                  {t(item?.i18nKey, {
                                                    defaultValue:
                                                      item?.fieldName,
                                                  })}
                                                </b>
                                                <span className="textOverFlow" title={keyData?.location?.text !=
                                                "" ? (
                                                  keyData?.location?.text
                                                ) : "Not Specified"}>
                                                {keyData?.location?.text !=
                                                "" ? (
                                                  keyData?.location?.text
                                                ) : (
                                                  <Trans
                                                    i18nKey="notSpecified"
                                                    defaults="Not Specified"
                                                  />
                                                )}
                                                </span>
                                              </span>
                                            ) : null}
                                            {item?.fieldCode ==
                                            "Edu Duration" ? (
                                              <span className="d-flex">
                                                <Whisper
                                                  followCursor
                                                  placement={"bottom"}
                                                  speaker={
                                                    <Tooltip>
                                                      <Trans
                                                        i18nKey="duration"
                                                        defaults="Duration"
                                                      />
                                                    </Tooltip>
                                                  }
                                                >
                                                  <span className="displayIcon displayIcon-sm">
                                                    <FontAwesomeIcon
                                                      icon={faStopwatch}
                                                    />
                                                  </span>
                                                </Whisper>
                                                <b className="px-1">
                                                  {t(item?.i18nKey, {
                                                    defaultValue:
                                                      item?.fieldName,
                                                  })}
                                                </b>
                                                {keyData?.duration?.text !=
                                                "" ? (
                                                  keyData?.duration?.text
                                                ) : (
                                                  <Trans
                                                    i18nKey="notSpecified"
                                                    defaults="Not Specified"
                                                  />
                                                )}
                                              </span>
                                            ) : null}
                                          </div>
                                        );
                                      })}
                                    </div>
                                  
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  }
                )
              ) : (
                <div className="row">
                  <div className="col-md-12">
                    <div className="card">
                      <div className="card-body">
                        <span>
                          <Trans
                            i18nKey="noEducationDetails"
                            defaults="No Education Details"
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <EducationSessionEdit
        resumeId={resumeId}
        companyID={resumeData?.companyID}
        clientID={resumeData?.clientID}
        control={control}
        handleSubmit={handleSubmit}
        register={register}
        setValue={setValue}
        clearErrors={clearErrors}
        errors={errors}
        getValues={getValues}
        educationList={resumeData?.resume?.professionalQualification?.education}
        educationRowsList={educationRowsList}
        open={open}
        setError={setError}
        setOpen={setOpen}
        standardFieldsList={standardFieldsList}
        setUpdateResumeDataData={setUpdateResumeDataData}
        setscrollRef={setscrollRef}
      />
    </>
    // </div>
  );
};

export default Education;