import React, { useEffect, useRef, useContext } from "react";
import {
  Avatar,
  Placeholder,
  Loader,
  Notification,
  useToaster,
  Whisper,
  Tooltip,
  Progress,
} from "rsuite";
import { Button } from "react-bootstrap";
import {
  Outlet,
  redirect,
  useLoaderData,
  useNavigate,
  NavLink,
  useParams,
} from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import Loading from "../../Loder";
// import { useTranslation } from 'react-i18next';
// import { ReferFriend } from "./ReferFriend";
import { useState } from "react";
import usePost from "../../customhooks/UsePost";
import parse from "html-react-parser";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart, faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import "./JobList.css";
// import { NavLink, useParams } from "react-router-dom";
import ReferFriend from "./ReferFriend";
// import { SimilarJob } from "./SimilarJob";
// import SimilarJob from "./SimilarJob";
// import { useGetPokemonByNameQuery } from "../../app/apiConfiguration";
import { StandaloneSearchBox } from "@react-google-maps/api";

import useGetQuery from "../../customhooks/UseGetQuery";
import SubmitYourProfile from "./SubmitYourProfile/SubmitYourProfile";
import ApplyNow from "./ApplyNow";
import CreateProfileButton from "../createProfile/CreateProfileButton";
import ApplyNowModal from "./ApplyNowModal";
import UserContext from "../../app/context/UserContext";
import RecommendedJobs from "./RecomandedJobs";
import JobsList from "./JobsList";
import axios from "axios";

export default function JobDetails({ selectedJob, isLoading, Searchvalue }) {
  const [isFavourite, setIsFavourite] = useState(false);

  let loaderDetails = useLoaderData();
  // console.log(loaderDetails, "ghrtyui");
  const { t } = useTranslation();
  const {
    post: updatefavourite,
    data,
    isLoading: favouriteloading,
    error,
  } = usePost("/jobsearch/favouritejob");
  const {
    post: getOverallScore,
    data: getOverallScoreData,
    isLoading: getOverallScoreLoading,
    error: getOverallScoreError,
  } = usePost("/getOverallScore");

  const {
    post: getTotalapplicants,
    data: getTotalapplicantsData,
    isLoading: getTotalapplicantsLoading,
    error: getTotalapplicantsError,
  } = usePost("/getTotalapplicants");
  // const { data } = useGetPokemonByNameQuery("bulbasaur");
  // console.log(data);
  const inputRef = useRef();
  // console.log(selectedJob);

  // useGetQuery
  // const { data, loading, error } = useGetQuery("/api/getTheme");

  const [JobDetails, setJobDetails] = useState(null);
  const [campaignID, setcampaignID] = useState(null);
  const [passcode, setPasscode] = useState(null);
  const [jobTitle, setJobTitle] = useState(null);

  // console.log(JobDetails, "JobDetails?.job?.details?");
  let navigate = useNavigate();

  const { user, setUserDetails } = useContext(UserContext);

  useEffect(() => {
    if (user?.favoriteCampaignID?.includes(campaignID)) {
      setIsFavourite(true);
    } else {
      setIsFavourite(false);
    }
  }, [user, selectedJob, campaignID]);
  // console.log(campaignID, "fgyhjkm,");
  const toaster = useToaster();
  // console.log(loaderDetails);
  useEffect(() => {
    // console.log(data);
    if (selectedJob) {
      setJobDetails(selectedJob._source);
      setcampaignID(selectedJob?._id);
      setPasscode(selectedJob?._source?.passcode);
      // setJobTitle(selectedJob?.urlTitle?.split(' ').join('-'))
    }

    if (loaderDetails?.jobDetails) {
      setJobDetails(loaderDetails?.jobDetails);
      setcampaignID(loaderDetails?.jobDetails?._id);
      setPasscode(loaderDetails?.jobDetails?.passcode);
      // if(loaderDetails?.jobDetails?.displayName && loaderDetails?.jobDetails?.displayName == "Campaign Title"){
      setJobTitle(
        loaderDetails?.jobDetails?.urlTitle
          ?.split(" ")
          .join("-")
          .replace(/-+/g, "-")
      );
      // }else{
      //   setJobTitle(loaderDetails?.jobDetails?.job?.details?.jobTitle?.text.split(' ').join('-') )
      // }
    }
  }, [selectedJob, loaderDetails]);
  useEffect(() => {
    if (loaderDetails?.status == false) {
      window.location.replace("/jobs");
    }
  }, [loaderDetails]);
  // useEffect(() => {
  //   if (window.location.pathname.includes(`/job/${passcode}`)) {
  //     if (window.location.search == "") {
  //       window.history.replaceState({}, "", `/job/${passcode}/${jobTitle}`);
  //     } else {
  //       if (window.location.search != "") {
  //         let search = window.location.search;
  //         if (window.location.pathname != `/job/${passcode}/${jobTitle}`) {
  //           window.history.replaceState(
  //             {},
  //             "",
  //             `/job/${passcode}/${jobTitle}${search}`
  //           );
  //         }
  //       }
  //     }
  //   }
  // }, [passcode, jobTitle]);
  useEffect(() => {
    const currentPath = window.location.pathname;
    const searchParams = window.location.search;
  
    if (currentPath.includes(`/job/${passcode}`) || currentPath.includes(`/careers/job/${passcode}`)) {
      const newPath = currentPath.includes("/careers")
        ? `/careers/job/${passcode}/${jobTitle}`
        : `/job/${passcode}/${jobTitle}`;
  
      if (searchParams === "") {
        window.history.replaceState({}, "", newPath);
      } else if (currentPath !== newPath) {
        window.history.replaceState({}, "", `${newPath}${searchParams}`);
      }
    }
  }, [passcode, jobTitle]);
  

  const [cookies, setCookie] = useState("");
  const Getcookies = async () => {
    try {
      let cookieDetails = await axios.get(
        window.location.origin + "/jsv4/webapi/getcookie"
      );
      cookieDetails = cookieDetails?.data;
      // console.log("cookieDetailscookieDetails", cookieDetails);
      // console.log(user?.resumeID, "sqwdefrgthyjuki");
      if (cookieDetails["Guest_User_ResumeID"]) {
        setCookie(cookieDetails["Guest_User_ResumeID"]);
      }
    } catch (error) {
      console.log("Error in getCookies : ", error);
    }
  };
  const [updatadataMri, setupdatadataMri] = useState(false);

  useEffect(() => {
    if (JobDetails?.clientID && user?.resumeID && user?.resumeID !== "") {
      setCookie(user?.resumeID);
    } else if (JobDetails?.clientID) {
      Getcookies();
      // isMounted.current = true;
    }
  }, [user, JobDetails?.clientID, updatadataMri]);
  console.log(cookies);
  const [overall, setoverall] = useState([]);
  useEffect(() => {
    if (
      JobDetails?.clientID &&
      campaignID != "" &&
      campaignID != undefined &&
      cookies != "" &&
      cookies != undefined
    ) {
      let jobsdata = {
        campaignID: campaignID,
        companyID: loaderDetails?.companyID,
        resumeID: cookies,
        userID: user?._id,
        collection: loaderDetails?.jobDetails?.collectionName,
      };
      getOverallScore(jobsdata)
        .then((res) => {
          console.log(res, "hsssssssssssdkk");
          if (res?.status) {
            setoverall(res?.MRIscroe);
          }
        })
        .catch((error) => {
          console.error("Error while fetching favourite jobs:", error);
        });
    }
  }, [campaignID, cookies, user, updatadataMri]);
  const [totalApplicants, setTotalapplicants] = useState(0);
  useEffect(() => {
    if (campaignID != "" && campaignID != undefined) {
      let jobsdata = {
        campaignID: campaignID,
        companyID: loaderDetails?.companyID,

        collection: loaderDetails?.jobDetails?.collectionName,
      };
      getTotalapplicants(jobsdata)
        .then((res) => {
          console.log(res, "dattatts");
          if (res?.status) {
            setTotalapplicants(res.MRIscroe);
          }
        })
        .catch((error) => {
          console.error("Error while fetching favourite jobs:", error);
        });
    }
  }, [JobDetails?.clientID, user, updatadataMri]);

  // console.log(overall, "overall");

  // console.log(
  //   JobDetails?.job?.otherInformation?.industry?.classified[0]?.industryLabel,
  //   "JobDetails?.job?"
  // );
  // console.log(JobDetails, "JobDetails?.job?.details?");

  // scroll to Submit Your Profile form
  // const scrollToSubmitProfile = () => {
  //   console.log("hiii");
  // };
  const [openModal, setOpenModal] = useState(false);

  const handlefavourite = () => {
    if (user) {
      if (user?._id !== "") {
        let body = {
          userID: user?._id,
          campaignID: campaignID,
          operationType: "",
        };
        if (isFavourite) {
          body.operationType = "unFavourite";
        } else {
          body.operationType = "favourite";
        }
        // console.log(body);
        updatefavourite(body)
          .then((res) => {
            if (res?.data?.status) {
              if (isFavourite) {
                setIsFavourite(false);
                toaster.push(
                  <Notification
                    type={"success"}
                    /* header="Success" */ header={t("success", {
                      defaultValue: "Success",
                    })}
                    closable
                  >
                    <span>
                      {/* Successfully Removed As Favorite */}
                      {t("successfullyRemovedasFavorite", {
                        defaultValue: "Successfully Removed As Favorite",
                      })}
                    </span>
                    {/* <Trans
                      i18nKey="successfullyRemovedasFavorite"
                      defaults="Successfully Removed As Favorite"
                    /> */}
                  </Notification>,
                  { placement: "topEnd" }
                );
              } else {
                toaster.push(
                  <Notification
                    type={"success"}
                    /* header="Success" */ header={t("success", {
                      defaultValue: "Success",
                    })}
                    closable
                  >
                    <span>
                      {/* Successfully Saved As Favorite */}
                      {t("successfullySavedasFavorite", {
                        defaultValue: "Successfully Saved As Favorite",
                      })}
                    </span>
                    {/* <Trans
                      i18nKey="successfullySavedasFavorite"
                      defaults="Successfully Saved As Favorite"
                    /> */}
                  </Notification>,
                  { placement: "topEnd" }
                );
                setIsFavourite(true);
              }
            } else {
              toaster.push(
                <Notification
                  type={"error"}
                  /* header="Error" */ header={t("error", {
                    defaultValue: "Error",
                  })}
                  closable
                >
                  <span>
                    {/* Something went wrong please try again */}
                    {t("somethingWentWrongPleaseTryAgainLater", {
                      defaultValue:
                        "Something went wrong.Please try again later",
                    })}
                  </span>
                  {/* <Trans
                    i18nKey="successfullySavedasFavorite"
                    defaults="Successfully Saved As Favorite"
                  /> */}
                </Notification>,
                { placement: "topEnd" }
              );
            }
          })
          .catch((error) => {
            console.error("Error while fetching jobs:", error);
          });
      }
    } else {
      setOpenModal(true);
    }
  };
  const [campiganpageicon, setcampiganpageicon] = useState(false);
  useEffect(() => {
    if (typeof window !== "undefined") {
      if (window.location.pathname == "/jobs") {
        setcampiganpageicon(true);
      }
    }
  }, [JobDetails]);

  const {
    post: Recomandedjobs,
    data: recomdeddata,
    isLoading: RecomandedjobsLoading,
  } = usePost("/getRecommendedJobs");
  const { post: getPagevisits } = usePost("/pagevisit");

  const [recomadedJobs, setRecomandedJobs] = useState([]);
  useEffect(() => {
    if (JobDetails?.clientID && campaignID && loaderDetails?.companyID) {
      Recomandedjobs({
        clientID: JobDetails?.clientID,
        campaignID: campaignID,
        companyID: loaderDetails?.companyID,
      })
        .then((res) => {
          // console.log(res?.result?.data);
          if (res?.result?.data?.status) {
            setRecomandedJobs(res?.result?.data?.result);
          }
        })
        .catch((error) => {
          console.error("Error while fetching Recomadedjobs:", error);
        });
    }
  }, [JobDetails?.clientID]);
  const previousURL = React.useRef(null);

  const getpagevisitsdata = () => {
    if (
      loaderDetails?.companyID &&
      loaderDetails?.companyName &&
      JobDetails?.title
    ) {
      let path = window.location.href;
      let title;
      let titleExists = true;
      let page = "";
      let subPage = "";
      let Jobtitle;
      if (JobDetails?.displayName) {
        if (JobDetails?.displayName == "Campaign Title") {
          Jobtitle = JobDetails?.title ?? "unkown";
        } else {
          Jobtitle = JobDetails?.job?.details?.jobTitle?.text ?? "unkown";
        }
      } else {
        Jobtitle = JobDetails?.title ?? "unkown";
      }
      if (path.includes("jobdetails")) {
        title = `${Jobtitle} | Job Details | ${loaderDetails?.companyName}`;
        page = "Jobdetails";
        subPage = "Jobdetails";
      } else if (path.includes("jobs")) {
        title = `${Jobtitle} | Jobs | ${loaderDetails?.companyName}`;
        page = "Jobs";
        subPage = "Jobs";
      } else if (path.includes("job")) {
        title = `${Jobtitle} | Job Details | ${loaderDetails?.companyName}`;
        page = "Jobdetails";
        subPage = "Jobdetails";
      } else {
        titleExists = false;
        title = loaderDetails?.companyName;
      }
      let previousTableBody = JSON.parse(JSON.stringify(document.title));

      // if (
      //   titleExists &&
      //   title &&
      //   previousURL.current != window.location.href &&
      //   JSON.stringify(previousTableBody) !== JSON.stringify(title)
      // ) {
      //   getPagevisits({
      //     url: window.location.href,
      //     title: title,
      //     page: page,
      //     subPage: subPage,
      //     userID: user?._id ?? "",
      //     companyID: loaderDetails?.companyID,
      //     campaignID: campaignID,
      //   })
      //     .then((res) => {
      //       console.log(res);
      //     })
      //     .catch((error) => {
      //       console.log("Error while fetching pagevisits:", error?.stack);
      //     });
      // }
      previousURL.current = window.location.href;
      document.title = title;
    }
  };
  useEffect(() => {
    if (campaignID && campaignID !== null) {
      getpagevisitsdata();
    }
  }, [campaignID]);
  // console.log(campaignID, "ghjkbrjgtnkymuui");
  const [Loactionreslut, setLocationreslut] = useState("NA");

  useEffect(() => {
    let objectWithNonEmptyCity = null;
    for (let i = 0; i < JobDetails?.job?.details?.locations.length; i++) {
      const location = JobDetails?.job?.details?.locations[i];
      if (location.city && location.city.trim() !== "") {
        objectWithNonEmptyCity = location;
        break;
      }
    }
    if (objectWithNonEmptyCity !== null) {
      setLocationreslut(objectWithNonEmptyCity);
    } else {
      setLocationreslut("NA");
    }
  }, [Loactionreslut, campaignID, JobDetails]);
  // console.log(JobDetails);
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [loaderDetails]);
  // console.log(campaignID, selectedJob,loaderDetails,isLoading,"campaignID");
  return (
    <>
      {selectedJob?._id || loaderDetails?.jobDetails?._id || !isLoading ? (
        <>
          {isLoading ||
          campaignID == "" ||
          campaignID == null ||
          campaignID == undefined ? (
            <>
              <div className="card w-100">
                <div className="card-body">
                  <Placeholder.Grid rows={6} columns={2} active />
                </div>
              </div>
            </>
          ) : (
            <div className="row" key={JobDetails?._id}>
              <div
                className={`${
                  JobDetails?.clientID
                    ? "col-xl-9 col-lg-9  col-sm-12"
                    : "col-12"
                }`}
              >
                <div className="row">
                  <div className="col-12">
                    <div className="card">
                      <div className="card-body ">
                        <div className="d-flex align-items-center justify-content-between">
                          <div className="d-flex align-items-center">
                            <h3 className="text-capitalize titleColor">
                              {JobDetails?.displayName ? (
                                <>
                                  {JobDetails?.displayName ==
                                  "Campaign Title" ? (
                                    <b>{JobDetails?.title ?? "unkown"}</b>
                                  ) : (
                                    <>
                                      {JobDetails?.job.details?.jobTitle
                                        ?.text ?? "unkown"}
                                    </>
                                  )}
                                </>
                              ) : (
                                <b>{JobDetails?.title ?? "unkown"}</b>
                              )}
                            </h3>

                            {/* {typeof window !== "undefined" && ( */}
                            {/* <> */}
                            {/* {!window.location.href.includes("/jobdetails") && ( */}
                            {campiganpageicon && (
                              <Whisper
                                followCursor
                                placement={"bottom"}
                                speaker={
                                  <Tooltip>
                                    {/* Redirect to Job Details Page */}{" "}
                                    <Trans
                                      i18nKey="redirectToJobDetailsPage"
                                      defaults="Redirect to Job Details Page"
                                    />
                                  </Tooltip>
                                }
                              >
                                <NavLink
                                  target="_blank"
                                  className="ps-2"
                                  to={`/job/${passcode}`}
                                  // to={`/jobdetails/${campaignID}`}
                                >
                                  <FontAwesomeIcon icon={faExternalLinkAlt} />
                                </NavLink>
                              </Whisper>
                            )}
                          </div>
                          {!campiganpageicon && (
                            <div>
                              <div>Posted : {totalApplicants?.posted}</div>
                              <div>
                                Total applicants :{" "}
                                {totalApplicants?.Totalapplicants}
                              </div>
                            </div>
                          )}
                          {/* )}
                    </>
                  )} */}
                        </div>
                        <ul className="list-unstyled">
                          <li className="text-capitalize">
                            {Loactionreslut?.cityCanonical ? (
                              <>
                                {Loactionreslut?.cityCanonical},{" "}
                                {Loactionreslut?.stateCanonical
                                  ? Loactionreslut?.stateCanonical
                                  : Loactionreslut?.state}
                              </>
                            ) : Loactionreslut?.city ? (
                              <>
                                {Loactionreslut?.city},{" "}
                                {Loactionreslut?.stateCanonical
                                  ? Loactionreslut?.stateCanonical
                                  : Loactionreslut?.state}{" "}
                              </>
                            ) : (
                              "NA"
                            )}
                            {/* {JobDetails?.job?.details?.locations?.[0]?.text ?? "NA"} */}
                          </li>
                          <li className="text-capitalize">
                            {" "}
                            {JobDetails?.job?.details?.jobTitle?.jobType
                              ? JobDetails?.job?.details?.jobTitle?.jobType
                              : "NA"}
                          </li>

                          {JobDetails?.customData?.[
                            "6112806bc9147f673d28c6eb"
                          ]?.["64abb309db7899f5908c77aa"]?.value && (
                            <li className="text-capitalize">
                              {
                                JobDetails?.customData?.[
                                  "6112806bc9147f673d28c6eb"
                                ]?.["64abb309db7899f5908c77aa"]?.value
                              }
                            </li>
                          )}

                          {/* <li className="text-capitalize">Job Category</li> */}
                          {/* <li>
                        {JobDetails?.job?.otherInformation?.industry
                          ?.classified[0]?.industryLabel
                          ? JobDetails?.job?.otherInformation?.industry
                              ?.classified[0]?.industryLabel
                          : "NA"}
                      </li> */}

                          <li>
                            {" "}
                            <span>
                              $
                              <span className="pe-1">
                                {JobDetails?.job?.details?.offeredSalary
                                  ?.minvalue ==
                                JobDetails?.job?.details?.offeredSalary
                                  ?.maxvalue ? (
                                  <>
                                    {
                                      JobDetails?.job?.details?.offeredSalary
                                        ?.minvalue
                                    }
                                  </>
                                ) : (
                                  <>
                                    {JobDetails?.job?.details?.offeredSalary
                                      ?.minvalue
                                      ? JobDetails?.job?.details?.offeredSalary
                                          ?.minvalue
                                      : "0"}
                                    -
                                    {JobDetails?.job?.details?.offeredSalary
                                      ?.maxvalue
                                      ? JobDetails?.job?.details?.offeredSalary
                                          ?.maxvalue
                                      : "0"}
                                  </>
                                )}
                                {/* {JobDetails?.job?.details?.offeredSalary?.minvalue
                          ? JobDetails?.job?.details?.offeredSalary?.minvalue
                          : "0"}
                        -
                        {JobDetails?.job?.details?.offeredSalary?.maxvalue
                          ? JobDetails?.job?.details?.offeredSalary?.maxvalue
                          : "0"} */}
                              </span>
                              <span>
                                {JobDetails?.job?.details?.offeredSalary?.period
                                  ? JobDetails?.job?.details?.offeredSalary
                                      ?.period
                                  : "NA"}
                              </span>
                            </span>
                          </li>
                        </ul>

                        <div className="d-flex justify-content-between">
                          <div className="d-flex align-items-center">
                            {/* <Button
                  onClick={scrollToSubmitProfile}
                  className="btn-primary rounded-pill">
                  Apply Now
                </Button> */}

                            {/* // apply now */}
                            <ApplyNow
                              campaignID={campaignID}
                              setupdatadataMri={setupdatadataMri}
                            />

                            <div
                              className="ps-2"
                              role="button"
                              onClick={() => {
                                handlefavourite();
                              }}
                            >
                              <Avatar
                                circle
                                className={`border ${
                                  isFavourite
                                    ? "border-primary"
                                    : "text-secondary"
                                } bg-white`}
                              >
                                {favouriteloading ? (
                                  <Loader />
                                ) : (
                                  <FontAwesomeIcon
                                    icon={faHeart}
                                    className={
                                      isFavourite
                                        ? "text-primary"
                                        : "text-black-50"
                                    }
                                  />
                                )}
                              </Avatar>
                            </div>
                          </div>
                          {/* // refer friend  */}
                          <div>
                            <ReferFriend campaignID={campaignID} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="bg-white rounded p-3 jobdetailscard">
                      <h3>
                        {" "}
                        <Trans i18nKey="jobDetails" defaults="Job Details" />
                      </h3>
                      <div className="hr-navy"></div>
                      {/* <h6>
                    <b> Job Description </b>
                  </h6> */}
                      <div className="Jobdetailstext">
                        {JobDetails?.job?.details?.jobDescription?.text &&
                          parse(JobDetails?.job?.details?.jobDescription?.text)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-3 col-lg-3  col-sm-12">
                {JobDetails?.clientID && (
                  <>
                    {overall?.length > 0 ? (
                      <div className="card mb-3">
                        <div className="card-body">
                          <div className="text-center">
                            <h6>Overall Score</h6>
                            <div className="d-flex justify-content-center align-items-center">
                              <Progress.Circle
                                percent={Math.round(
                                  overall?.[0]?.MRI?.experience?.matchScore ?? 0
                                )}
                                width={70}
                              />
                            </div>
                          </div>

                          {/* </div> */}
                        </div>
                      </div>
                    ) : getOverallScoreLoading ? (
                      <Placeholder.Grid rows={3} columns={2} active />
                    ) : (
                      ""
                    )}
                    {RecomandedjobsLoading ? (
                      <>
                        <div className="card m-2">
                          <div className="card-body">
                            <Placeholder.Grid rows={3} columns={2} active />
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        {recomadedJobs.length > 0 && (
                          <>
                            <h5 className="mx-2 mt-1">
                              {/* Recommended Jobs */}
                              <Trans
                                i18nKey="recommendedJobs"
                                defaults="Recommended Jobs"
                              />
                            </h5>

                            {recomadedJobs?.map((x) => {
                              return (
                                <a
                                  key={x?._id}
                                  href={`/job/${x?._source?.passcode}`}
                                  className="text-decoration-none"
                                >
                                  <JobsList
                                    data={x}
                                    isLoading={RecomandedjobsLoading}
                                    type="jobdetails"
                                  />
                                </a>
                                // <RecommendedJobs
                                //   key={x?._id}
                                //   Job={x}
                                //   Loading={RecomandedjobsLoading}
                                //   setJobDetails={setJobDetails}
                                //   setcampaignID={setcampaignID}
                                // />
                              );
                            })}
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
              </div>
              <ApplyNowModal
                openModal={openModal}
                setOpenModal={setOpenModal}
                from={"favourite"}
              />
            </div>
          )}
        </>
      ) : (
        <div className="d-flex justify-content-center">
          <img
            src="https://recruiterqa.maprecruit.com/assets/images/campaignClosed-min.png"
            width={800}
          />
        </div>
      )}
    </>
  );
}
