import React, { useEffect } from "react";
import { Controller } from "react-hook-form";
import PropTypes from "prop-types";
import { Form } from "react-bootstrap";
// import { t } from "i18next";
import { Trans ,useTranslation} from "react-i18next";

const AgeFormField = ({
  ageData,
  control,
  getValues,
  errors,
  register,
  setValue,
  fieldList,
  type
}) => {
  const { t } = useTranslation();
  useEffect(() => {
    if (ageData) {
      setValue("ageData", ageData);
    }
  }, [ageData]);

  return (
    <>
      <div className="form-group">
        <label className="text-dark">{t(fieldList?.i18nKey, { defaultValue: fieldList?.fieldName })}</label>
        {fieldList?.required && <span className="text-danger">{"*"}</span>}
        <Controller
          control={control}
          name={"ageData"}
          refs={register("ageData", {
            required: fieldList?.required ? true : false,
          })}
          rules={{
            validate: {
              isNonNegative: (value) => {
                if (value < 0) {
                  return t("valueCannotBeNegative", { defaultValue: "Value cannot be negative" });
                }
              }
            }
          }}
          render={({ field: { onChange, onBlur, value } }) => (
            <Form.Control
              type="number"
              size="sm"
              className="w-100"
              placeholder={
                t("enter", { defaultValue: "Enter" }) +
                t("age", { defaultValue: "Age" })
              }
              disabled={type=="customLayout" ||fieldList?.editable || !fieldList ? false : true}
              value={value}
              onChange={onChange}
            />
          )}
        />
      </div>
      {errors?.ageData && errors?.ageData?.type == "required" ? (
        <span className="text-danger ">
          {errors?.ageData?.message != "" ? (
            errors?.ageData?.message
          ) : (
            <Trans
              i18nKey="ageDataIsRequired"
              defaults="Age Data is required"
            />
          )}{" "}
        </span>
      ) : (
        <>
          {errors?.ageData && (
            <span className="text-danger">
              <Trans
                defaults="Value cannot be negative"
                i18nKey="valueCannotBeNegative"
              />
            </span>
          )}
        </>
      )}
    </>
  );
};

export default AgeFormField;
