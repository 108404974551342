import React, { useEffect, useState,useContext } from "react";
import { useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { Modal } from 'rsuite';
import ProfileStandard from "../ProfileStandard";
import "../../MyAccount.css"
import OtherInformationModal from "../forms/OtherInformationModal"
import { Trans } from "react-i18next";
import { t } from "i18next";
import { v4 as uuidv4 } from "uuid"
import usePost from "../../../../customhooks/UsePost";
import UserContext from "../../../../app/context/UserContext";

const OtherInformationSession = ({ standardFieldsList, customFields, resumeId, status,resumeData,setUpdateResumeDataData }) => {
    const {
        post: updateResumeDetails,
        isLoading: UpdateResumeDataDataLoading,
    } = usePost("/user/Updateresumedeatails");
    const { user, setUserDetails } = useContext(UserContext);
    const [afterUpdateResumeData, setAfterUpdateResumeData] = useState({})
    const [open, setOpen] = React.useState(false);
    const { control, register, setValue, handleSubmit, formState: { errors }, getValues ,clearErrors} = useForm();
    const handleOtherInformationOpen = () => {
        setOpen(true)
        setAfterUpdateResumeData({})
    };
    const handleOtherInformationClose = () => {
        setOpen(false);
        clearErrors()
        setValue(
            "languagesKnown",
            []
          );
        setValue("gender", "");
        setValue("workPermit", "");
        setValue("noticePeriod","");
        setValue("veteranStatus", "");
        setValue("ageData", "");
        setValue("nationality", "");



        
    }
    const handleOtherInformationSubmit = (data) => {
        updateResumeDetails({
            target: "Other Information",
            resumeID: resumeId,
            user:user,
            companyID:resumeData?.companyID,
            clientID:resumeData?.clientID,
            getValues: {...data, "sectionName": standardFieldsList?.sectionName ? standardFieldsList?.sectionName : "Other Information"},
            type:"Profile Edited"
        }).then((res) => {
            if (res) {
                setUpdateResumeDataData(res);
                handleOtherInformationClose();
                // window.location.reload();
            }
          })
          .catch((error) => {
            console.error("Error while fetching Resume:", error);
          });
        // updateResumeDetails({ resumeID: resumeId, ...data, "sectionName": standardFieldsList?.sectionName ? standardFieldsList?.sectionName : "Other Information" })
    }
   
    return (
        <>
            <div className='card h-100'>
                <div className='card-body'>
                    <div className="row">
                        <div className="col-10 d-flex align-items-center">
                            <h6 className='text-primary m-0'>
                                {/* {t(standardFieldsList?.i18nKey, { defaultValue: standardFieldsList?.sectionName })} */}
                                <Trans i18nKey="proOtherInformation" defaults="Other Information"/>
                            </h6>
                        </div>
                        { status != "MERGED" && status != "DISQUALIFIED" && status != "DELETED" ? (
                            <>
                                <div className="col-2 justify-content-end d-flex">
                                    {standardFieldsList?.editable ? (<>
                                        <span className="IconArea actionIcon actionIcon-sm " onClick={handleOtherInformationOpen}>
                                            <FontAwesomeIcon className="mx-2" icon={faEdit} />
                                        </span>
                                    </>) : null}
                                </div>
                            </>
                        ) : (
                            <>
                            </>
                        )}
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <div className="mt-2">
                                {standardFieldsList?.rows?.map((sections, index) => {
                                    let colNumber = 12;
                                    if (sections?.columns.length > 0) {
                                        colNumber = 12 / sections?.columns.length;
                                    }
                                    return (
                                        <div className="row mb-3 details" key={uuidv4()}>
                                            {sections?.columns?.map((item, index) => {
                                                return (
                                                    <div className={"col-" + colNumber} key={uuidv4()}>
                                                        <ProfileStandard item={item} resumeID={resumeId} UpdateResumeData={afterUpdateResumeData} resumeData={resumeData} setUpdateResumeDataData={setUpdateResumeDataData} />
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal size={"lg"} open={open} onClose={handleOtherInformationClose}>
                <Modal.Header>
                    <Modal.Title>
                        {t(standardFieldsList?.i18nKey, { defaultValue: standardFieldsList?.sectionName })}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row w-100">
                        <div className="col-sm-12 col-md-12">
                            <OtherInformationModal itemObj={standardFieldsList?.rows} resumeId={resumeId} control={control} register={register} setValue={setValue} errors={errors} getValues={getValues} handleSubmit={handleSubmit} />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="row pt-2">
                        <div className="col-md-12 col-sm-12 d-flex justify-content-between">
                            <span>
                                <button className="btn btn-sm btn-lignt" onClick={handleOtherInformationClose} >
                                    <Trans
                                        i18nKey="cancel"
                                        defaults="Cancel"
                                    />
                                </button>
                            </span>
                            <span>
                                <button className="btn btn-sm btn-primary" disabled={UpdateResumeDataDataLoading} onClick={handleSubmit(handleOtherInformationSubmit)} >
                                    {
                                        UpdateResumeDataDataLoading ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : null
                                    }
                                    <span className="px-1">
                                        <Trans
                                            i18nKey="save"
                                            defaults="Save"
                                        />
                                    </span>
                                </button>
                            </span>
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default OtherInformationSession;