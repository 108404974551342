import React, { useState, useEffect, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { useForm } from "react-hook-form";
import { v4 as uuidv4 } from "uuid";
import { Popover, Whisper, useToaster, Notification } from "rsuite";
import { Trans, useTranslation } from "react-i18next";
import { t } from "i18next";
import ResumeLocationForm from "../forms/ResumeLocationForm";
import usePost from "../../../../customhooks/UsePost";
import UserContext from "../../../../app/context/UserContext";

const Location = ({
  resumeId,
  fieldObj,
  userDetails,
  resume,
  setUpdateResumeDataData,
}) => {
  const {
    control,
    register,
    setValue,
    handleSubmit,
    clearErrors,
    setError,
    getValues,
    formState: { errors },
  } = useForm();
  let toaster = useToaster();
  const { t } = useTranslation();
  const { user, setUserDetails } = useContext(UserContext);
  const [location, setLocation] = useState([]);
  const [locationFlagHide, setLocationFlagHide] = useState(false);

  useEffect(() => {
    if (
      resume?.resume?.profile?.locations &&
      resume?.resume?.profile?.locations?.length > 0
    ) {
      setLocation(resume?.resume?.profile?.locations);
    }
  }, [resume]);
  const { post: updateResumeDetails, isLoading: UpdateResumeDataDataLoading } =
    usePost("/user/Updateresumedeatails");

  const onSubmit = (data) => {
    let flag = true;
    if (
      data?.profilePostalCode &&
      fieldObj?.required &&
      fieldObj?.requiredFields.includes("PostalCode") &&
      data?.profilePostalCode == ""
    ) {
      //   console.log("PostalCode")
      flag = false;
      setError("profilePostalCode", {
        type: "manaual",
        message: t("postalCodeRequired", {
          defaultValue: "PostalCode is required",
        }),
      });
    }
    if (
      data?.profileState &&
      fieldObj?.required &&
      fieldObj?.requiredFields.includes("State") &&
      data?.profileState == ""
    ) {
      //   console.log("State")
      flag = false;
      setError("profilePostalCode", {
        type: "manaual",
        message: t("stateRequired", { defaultValue: "State is required" }),
      });
    }
    if (
      data?.profileCity &&
      fieldObj?.required &&
      fieldObj?.requiredFields.includes("City") &&
      data?.profileCity == ""
    ) {
      //   console.log("City")
      flag = false;
      setError("profileCity", {
        type: "manaual",
        message: t("cityRequired", { defaultValue: "City is required" }),
      });
    }
    if (
      data?.profileCountry &&
      fieldObj?.required &&
      fieldObj?.requiredFields.includes("Country") &&
      data?.profileCountry == ""
    ) {
      //   console.log("Country")
      flag = false;
      setError("profileCountry", {
        type: "manaual",
        message: t("countryRequired", { defaultValue: "Country is required" }),
      });
    }
    if (
      data?.profileLocation &&
      fieldObj?.required &&
      fieldObj?.requiredFields.includes("Address") &&
      data?.profileLocation == ""
    ) {
      flag = false;
      setError("profileLocation", {
        type: "manaual",
        message: t("addressRequired", { defaultValue: "Address is required" }),
      });
    }
    if (flag) {
      updateResumeDetails({
        target: "locations",
        resumeID: resumeId,
        getValues: { ...data },
        user: user,
        companyID: resume?.companyID,
        clientID: resume?.clientID,
        type: "Profile Edited",
      })
        .then((res) => {
          if (res) {
            setUpdateResumeDataData(res);
            locationTriggerRef.current.close();
          }
        })
        .catch((error) => {
          console.error("Error while fetching Resume:", error);
        });
    }
  };
  const locationTriggerRef = React.useRef();
  const close = () => locationTriggerRef.current.close();
  const open = () => locationTriggerRef.current.open();
  useEffect(() => {
    const handleEsc = (event) => {
      if (event.keyCode === 27) {
        locationTriggerRef.current.close();
      }
    };
    window.addEventListener("keydown", handleEsc);

    return () => {
      window.removeEventListener("keydown", handleEsc);
    };
  }, []);
  useEffect(() => {
    if (
      userDetails?.roleDetails?.accessabilitySettings?.sourceAI?.profile
        ?.updateProfile
    ) {
      setLocationFlagHide(true);
    } else {
      setLocationFlagHide(false);
    }
  });
  return (
    <>
      {location?.length > 0 ? (
        <>
          {location?.map((keyData, index) => {
            return (
              <div className="d-flex" key={uuidv4()}>
                <span key={uuidv4()} className="ps-1">
                  {keyData?.text}
                </span>
              </div>
            );
          })}
        </>
      ) : (
        <span>
          <Trans i18nKey="notSpecified" defaults="Not Specified" />
        </span>
      )}
      <Whisper
        placement="auto"
        trigger={"none"}
        ref={locationTriggerRef}
        speaker={
          <Popover className="locationPopover" arrow={false}>
            <h6>
              <Trans i18nKey="location" defaults="Location" />
            </h6>
            <div className="row">
              <div className="col-md-12">
                <ResumeLocationForm
                  location={location}
                  register={register}
                  setValue={setValue}
                  errors={errors}
                  control={control}
                  clearErrors={clearErrors}
                  fieldObj={fieldObj}
                  getValues={getValues}
                />
              </div>
            </div>
            <div className="row my-2">
              <div className="col-md-12 d-flex justify-content-between">
                <span>
                  <button className="btn btn-sm btn-light" onClick={close}>
                    <Trans i18nKey="cancel" defaults="Cancel" />
                  </button>
                </span>
                <span>
                  <button
                    className="btn btn-sm btn-primary"
                    disabled={UpdateResumeDataDataLoading}
                    onClick={handleSubmit(onSubmit)}
                  >
                    {UpdateResumeDataDataLoading ? (
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    ) : null}
                    <span className="px-1">
                      <Trans i18nKey="save" defaults="Save" />
                    </span>
                  </button>
                </span>
              </div>
            </div>
          </Popover>
        }
      >
        <span className="IconArea actionIcon actionIcon-sm" onClick={open}>
          <FontAwesomeIcon className="mx-2 text-primary" icon={faEdit} />
        </span>
      </Whisper>
    </>
  );
};
export default Location;
