import React from "react";
import { Card, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBuilding,
  faCalendarDays,
  faEdit,
  faEnvelope,
  faLocationDot,
  faPhone,
  faQuoteLeft,
  faStar,
  faUser,
  faUserTie,
} from "@fortawesome/free-solid-svg-icons";
import { Trans, useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import { Modal, Form, Tooltip, Whisper } from "rsuite";
import { useForm } from "react-hook-form";
import "../../MyAccount.css";
import ReferenceSessionEdit from "../forms/ReferenceSessionEdit";
import { v4 as uuidv4 } from "uuid";

const References = ({
  referenceRows,
  resumeId,
  standardFieldsList,
  status,
  resumeData,
  setUpdateResumeDataData,
}) => {
  const { t } = useTranslation();
  const {
    control,
    register,
    setValue,
    handleSubmit,
    clearErrors,
    formState: { errors },
    setError,
    getValues,
  } = useForm({
    mode: "onChange",
  });
  const [open, setOpen] = React.useState(false);
  const handleReferenceOpen = () => {
    setOpen(true);
    clearErrors();
  };

  const [referenceRowsList, setreferenceRows] = useState([]);
  useEffect(() => {
    setreferenceRows(referenceRows);
  }, []);

  return (
    <>
      <div className="card h-100">
        <div className="card-body border-0">
          <div className="row">
            <div className="col-10 d-flex my-2 align-items-center">
              <h6 className="text-primary m-0">
                <Trans i18nKey="proReferences" defaults="References" />
              </h6>
            </div>
            <div className="col-2 justify-content-end d-flex">
              {standardFieldsList?.editable ? (
                <>
                  {status != "MERGED" &&
                  status != "DISQUALIFIED" &&
                  status != "DELETED" ? (
                    <>
                      <span
                        className="IconArea actionIcon actionIcon-sm"
                        onClick={handleReferenceOpen}
                      >
                        <FontAwesomeIcon className="mx-2" icon={faEdit} />
                      </span>
                    </>
                  ) : (
                    <></>
                  )}
                </>
              ) : null}
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              {resumeData?.resume?.otherInformation?.references?.length > 0 ? (
                resumeData?.resume?.otherInformation?.references?.map(
                  (keyData, index) => {
                    return (
                      <div className="row" key={uuidv4()}>
                        <div className="col-md-12">
                          <div
                            className={
                              resumeData?.resume?.otherInformation?.references
                                ?.length != 1
                                ? "border-bottom"
                                : ""
                            }
                          >
                            <div className="details mt-2">
                              {referenceRowsList.map((sections, index) => {
                                let colNumber = 12;
                                if (sections?.columns.length > 0) {
                                  colNumber = 12 / sections?.columns.length;
                                }
                                return (
                                  <div className="row mb-3" key={uuidv4()}>
                                    {sections?.columns.map((item, index) => {
                                      return (
                                        <div
                                          className={"col-" + colNumber}
                                          key={uuidv4()}
                                        >
                                          {item?.fieldCode ==
                                          "Ref Full Name" ? (
                                            // <div className="row">
                                            <span className="d-flex">
                                              <span>
                                                {/* <b>{t(item?.fieldName, { defaultValue: item?.fieldName || "Full Name" })}: </b> */}
                                                <Whisper
                                                  followCursor
                                                  placement={"bottom"}
                                                  speaker={
                                                    <Tooltip>
                                                      {t(item?.i18nKey, {
                                                        defaultValue:
                                                          item?.fieldName ||
                                                          "Full Name",
                                                      })}
                                                    </Tooltip>
                                                  }
                                                >
                                                  <span className="displayIcon displayIcon-sm">
                                                    <FontAwesomeIcon
                                                      icon={faUser}
                                                    />
                                                  </span>
                                                </Whisper>
                                              </span>
                                              <b className="px-1">{t(item?.i18nKey, { defaultValue: item?.fieldName })}</b>
                                              <span className="textOverFlow" title={keyData?.fullName != ""
                                                ? keyData?.fullName
                                                : "Not Specified"}>
                                              {keyData?.fullName != ""
                                                ? keyData?.fullName
                                                : <Trans
                                                    i18nKey="notSpecified"
                                                    defaults="Not Specified"
                                                  />}
                                                </span>
                                            </span>
                                          ) : (
                                            // </div>
                                            <></>
                                          )}
                                          {item?.fieldCode ==
                                          "Ref Job Title" ? (
                                            <span className="d-flex">
                                              <span>
                                                {/* <b>{t(item?.fieldName, { defaultValue: item?.fieldName || "Job Title" })}: </b> */}
                                                <Whisper
                                                  followCursor
                                                  placement={"bottom"}
                                                  speaker={
                                                    <Tooltip>
                                                      {t(item?.i18nKey, {
                                                        defaultValue:
                                                          item?.fieldName ||
                                                          "Job Title",
                                                      })}
                                                    </Tooltip>
                                                  }
                                                >
                                                  <span className="displayIcon displayIcon-sm">
                                                    <FontAwesomeIcon
                                                      icon={faUserTie}
                                                    />
                                                  </span>
                                                </Whisper>
                                              </span>
                                              <b className="px-1">{t(item?.i18nKey, { defaultValue: item?.fieldName })}</b>
                                              <span className="textOverFlow" title={keyData?.jobTitle != ""
                                                ? keyData?.jobTitle
                                                : "Not Specified"}>
                                              {keyData?.jobTitle != ""
                                                ? keyData?.jobTitle
                                                : <Trans
                                                    i18nKey="notSpecified"
                                                    defaults="Not Specified"
                                                  />}
                                              </span>
                                            </span>
                                          ) : (
                                            <></>
                                          )}
                                          {item?.fieldCode == "Ref Phones" ? (
                                            <span className="d-flex">
                                              <span>
                                                {/* <b>{t(item?.fieldName, { defaultValue: item?.fieldName || "Phone" })}: </b>
                                                 */}
                                                <Whisper
                                                  followCursor
                                                  placement={"bottom"}
                                                  speaker={
                                                    <Tooltip>
                                                      {t(item?.i18nKey, {
                                                        defaultValue:
                                                          item?.fieldName ||
                                                          "Phone",
                                                      })}
                                                    </Tooltip>
                                                  }
                                                >
                                                  <span className="displayIcon displayIcon-sm">
                                                    <FontAwesomeIcon
                                                      icon={faPhone}
                                                    />
                                                  </span>
                                                </Whisper>
                                              </span>
                                              <b className="px-1">
                                                {t(item?.i18nKey, {
                                                  defaultValue: item?.fieldName,
                                                })}
                                              </b>
                                              {keyData?.phone?.length > 0 ? (
                                                keyData?.phone.map(
                                                  (keyData, index) => {
                                                    return (
                                                      // <span key={uuidv4()}>
                                                      <span key={uuidv4()} className="badge bd-primary p-1 font-size-9pt px-2 rounded-pill cursor-pointer mx-1 textOverFlow" title= {keyData?.text ? keyData?.text : "Not Specified"}  >
                                                        {keyData?.text}
                                                      </span>
                                                    );
                                                  }
                                                )
                                              ) : (
                                                <Trans
                                                    i18nKey="notSpecified"
                                                    defaults="Not Specified"
                                                  />
                                              )}
                                            </span>
                                          ) : null}
                                          {item?.fieldCode == "Ref Emails" ? (
                                            <span className="d-flex">
                                              <span>
                                                {/* <b>{t(item?.fieldName, { defaultValue: item?.fieldName || "Email" })}: </b> */}
                                                <Whisper
                                                  followCursor
                                                  placement={"bottom"}
                                                  speaker={
                                                    <Tooltip>
                                                      {t(item?.i18nKey, {
                                                        defaultValue:
                                                          item?.fieldName ||
                                                          "Email",
                                                      })}
                                                    </Tooltip>
                                                  }
                                                >
                                                  <span className="displayIcon displayIcon-sm">
                                                    <FontAwesomeIcon
                                                      icon={faEnvelope}
                                                    />
                                                  </span>
                                                </Whisper>
                                              </span>
                                              <b className="px-1">
                                                {t(item?.i18nKey, {
                                                  defaultValue: item?.fieldName,
                                                })}
                                              </b>
                                              {keyData?.email.length > 0 ? (
                                                keyData?.email.map(
                                                  (keyData, index) => {
                                                    return (
                                                      // <span key={uuidv4()}>
                                                      <span key={uuidv4()} className="badge bd-primary p-1 font-size-9pt px-2 rounded-pill cursor-pointer mx-1 textOverFlow" title= {keyData?.text && keyData?.text != "" ? keyData?.text : "Not Specified"} >
                                                        {keyData?.text}
                                                      </span>
                                                      // </span>
                                                    );
                                                  }
                                                )
                                              ) : (
                                                <Trans
                                                    i18nKey="notSpecified"
                                                    defaults="Not Specified"
                                                  />
                                              )}
                                            </span>
                                          ) : null}

                                          {item?.fieldCode == "Ref Company" ? (
                                            <span className="d-flex">
                                              <span>
                                                {/* <b>{t(item?.fieldName, { defaultValue: item?.fieldName || "Company" })}: </b> */}
                                                <Whisper
                                                  followCursor
                                                  placement={"bottom"}
                                                  speaker={
                                                    <Tooltip>
                                                      {t(item?.i18nKey, {
                                                        defaultValue:
                                                          item?.fieldName ||
                                                          "Company",
                                                      })}
                                                    </Tooltip>
                                                  }
                                                >
                                                  <span className="displayIcon displayIcon-sm">
                                                    <FontAwesomeIcon
                                                      icon={faBuilding}
                                                    />
                                                  </span>
                                                </Whisper>
                                              </span>
                                              <b className="px-1">{t(item?.i18nKey, { defaultValue: item?.fieldName })}</b>
                                              <span className="textOverFlow" title={keyData?.company != ""
                                                ? keyData?.company
                                                : "Not Specified"}>
                                              {keyData?.company != ""
                                                ? keyData?.company
                                                : <Trans
                                                    i18nKey="notSpecified"
                                                    defaults="Not Specified"
                                                  />}
                                              </span>
                                            </span>
                                          ) : null}
                                          {item?.fieldCode ==
                                          "Ref Start Date" ? (
                                            <span className="d-flex">
                                              <span>
                                                {/* <b>{t(item?.fieldName, { defaultValue: item?.fieldName || "Start Date" })}: </b> */}
                                                <Whisper
                                                  followCursor
                                                  placement={"bottom"}
                                                  speaker={
                                                    <Tooltip>
                                                      {t(item?.i18nKey, {
                                                        defaultValue:
                                                          item?.fieldName ||
                                                          "Start Date",
                                                      })}
                                                    </Tooltip>
                                                  }
                                                >
                                                  <span className="displayIcon displayIcon-sm">
                                                    <FontAwesomeIcon
                                                      icon={faCalendarDays}
                                                    />
                                                  </span>
                                                </Whisper>
                                              </span>
                                              <b className="px-1">
                                                {t(item?.i18nKey, {
                                                  defaultValue: item?.fieldName,
                                                })}
                                              </b>
                                              {keyData?.startDate?.standard !=
                                              "" ? (
                                                <>
                                                  {keyData?.startDate
                                                    ?.standard != "" &&
                                                    keyData?.startDate?.text}
                                                </>
                                              ) : (
                                                <span>
                                                  <Trans
                                                    i18nKey="notSpecified"
                                                    defaults="Not Specified"
                                                  />
                                                </span>
                                              )}
                                            </span>
                                          ) : null}
                                          {item?.fieldCode == "Ref End Date" ? (
                                            <span className="d-flex">
                                              <span>
                                                {/* <b>{t(item?.fieldName, { defaultValue: item?.fieldName || "End Date" })} </b> */}
                                                <Whisper
                                                  followCursor
                                                  placement={"bottom"}
                                                  speaker={
                                                    <Tooltip>
                                                      {t(item?.i18nKey, {
                                                        defaultValue:
                                                          item?.fieldName ||
                                                          "End Date",
                                                      })}
                                                    </Tooltip>
                                                  }
                                                >
                                                  <span className="displayIcon displayIcon-sm">
                                                    <FontAwesomeIcon
                                                      icon={faCalendarDays}
                                                    />
                                                  </span>
                                                </Whisper>
                                              </span>
                                              <b className="px-1">
                                                {t(item?.i18nKey, {
                                                  defaultValue: item?.fieldName,
                                                })}
                                              </b>
                                              {keyData?.endDate?.standard !=
                                              "" ? (
                                                <>
                                                  {keyData?.endDate?.standard !=
                                                    "" &&
                                                    keyData?.endDate?.text}
                                                </>
                                              ) : (
                                                <span>
                                                  <Trans
                                                    i18nKey="notSpecified"
                                                    defaults="Not Specified"
                                                  />
                                                </span>
                                              )}
                                              {/* {keyData?.endDate != ""
                                                ? keyData?.endDate?.text
                                                : "No End Date"} */}
                                            </span>
                                          ) : null}
                                          {item?.fieldCode == "Ref Review" ? (
                                            <span className="d-flex">
                                              <span>
                                                {/* <b>{t(item?.fieldName, { defaultValue: item?.fieldName || "Review" })}: </b> */}
                                                <Whisper
                                                  followCursor
                                                  placement={"bottom"}
                                                  speaker={
                                                    <Tooltip>
                                                      {t(item?.i18nKey, {
                                                        defaultValue:
                                                          item?.fieldName ||
                                                          "Review",
                                                      })}
                                                    </Tooltip>
                                                  }
                                                >
                                                  <span className="displayIcon displayIcon-sm">
                                                    <FontAwesomeIcon
                                                      icon={faStar}
                                                    />
                                                  </span>
                                                </Whisper>
                                              </span>
                                              <b className="px-1">{t(item?.i18nKey, { defaultValue: item?.fieldName })}</b>
                                              <span className="textOverFlow" title={keyData?.review != ""
                                                ? keyData?.review
                                                : "Not Specified"}>
                                              {keyData?.review != ""
                                                ? keyData?.review
                                                : <Trans
                                                    i18nKey="notSpecified"
                                                    defaults="Not Specified"
                                                  />}
                                                </span>
                                            </span>
                                          ) : null}
                                          {item?.fieldCode == "Ref Comment" ? (
                                            <span className="d-flex">
                                              <span>
                                                {/* <b>{t(item?.fieldName, { defaultValue: item?.fieldName || "Comment" })}: </b> */}

                                                <Whisper
                                                  followCursor
                                                  placement={"bottom"}
                                                  speaker={
                                                    <Tooltip>
                                                      {t(item?.i18nKey, {
                                                        defaultValue:
                                                          item?.fieldName ||
                                                          "Comment",
                                                      })}
                                                    </Tooltip>
                                                  }
                                                >
                                                  <span className="displayIcon displayIcon-sm">
                                                    <FontAwesomeIcon
                                                      icon={faQuoteLeft}
                                                    />
                                                  </span>
                                                </Whisper>
                                              </span>
                                              <b className="px-1">{t(item?.i18nKey, { defaultValue: item?.fieldName })}</b>
                                              <span className="textOverFlow" title={keyData?.comment != ""
                                                ? keyData?.comment
                                                : "Not Specified"}>
                                              {keyData?.comment != ""
                                                ? keyData?.comment
                                                : <Trans
                                                    i18nKey="notSpecified"
                                                    defaults="Not Specified"
                                                  />}
                                                </span>
                                            </span>
                                          ) : null}
                                          {item?.fieldCode == "Ref Location" ? (
                                            <span className="d-flex">
                                              <span>
                                                {/* <b>{t(item?.fieldName, { defaultValue: item?.fieldName || "Location" })}: </b> */}
                                                <Whisper
                                                  followCursor
                                                  placement={"bottom"}
                                                  speaker={
                                                    <Tooltip>
                                                      {t(item?.i18nKey, {
                                                        defaultValue:
                                                          item?.fieldName ||
                                                          "Location",
                                                      })}
                                                    </Tooltip>
                                                  }
                                                >
                                                  <span className="displayIcon displayIcon-sm">
                                                    <FontAwesomeIcon
                                                      icon={faLocationDot}
                                                    />
                                                  </span>
                                                </Whisper>
                                              </span>
                                              <b className="px-1">{t(item?.i18nKey, { defaultValue: item?.fieldName })}</b>
                                              <span className="textOverFlow" title={keyData?.location?.text != ""
                                                ? keyData?.location?.text
                                                : "Not Specified"}>
                                              {keyData?.location?.text != ""
                                                ? keyData?.location?.text
                                                : <Trans
                                                i18nKey="notSpecified"
                                                defaults="Not Specified"
                                              />}
                                                </span>
                                            </span>
                                          ) : (
                                            <></>
                                          )}
                                        </div>
                                      );
                                    })}
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  }
                )
              ) : (
                <div className="row">
                  <div className="col-md-12">
                    <div className="card">
                      <div className="card-body">
                        <span>
                          {t("noReferenceDetails", {
                            defaultValue: "No Reference Details",
                          })}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <ReferenceSessionEdit
        open={open}
        setOpen={setOpen}
        control={control}
        register={register}
        clearErrors={clearErrors}
        setValue={setValue}
        errors={errors}
        getValues={getValues}
        referenceList={resumeData?.resume?.otherInformation?.references}
        referenceRowsList={referenceRows}
        setError={setError}
        resumeId={resumeId}
        companyID={resumeData?.companyID}
        clientID={resumeData?.clientID}
        handleSubmit={handleSubmit}
        standardFieldsList={standardFieldsList}
        setUpdateResumeDataData={setUpdateResumeDataData}
      />
    </>
  );
};

export default References;