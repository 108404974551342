import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { Form } from 'react-bootstrap';
import { Trans } from 'react-i18next';
import { t } from 'i18next';

const NationalityFormField = ({ nationalityData, control, getValues, errors, register, setValue, fieldList, type }) => {
    useEffect(() => {
        if (nationalityData) {
            setValue("nationality", nationalityData)
        }
    }, [nationalityData])
    return (
        <>
            <div className="form-group">
                <label className='text-dark'>
                {t(fieldList?.i18nKey, { defaultValue: fieldList?.fieldName })}
                </label>
                {fieldList?.required && <span className="text-danger">{"*"}</span>}
                <Controller
                    control={control}
                    name={"nationality"}
                    refs={register("nationality",  {
                        required: fieldList?.required ? true : false,
                      })}
                    render={({ field: { onChange, onBlur, value } }) => (
                        <Form.Control
                            size="sm"
                            className="w-100"
                            onChange={onChange}
                            value={value}
                            disabled={type=="customLayout" ||fieldList?.editable || !fieldList ? false : true}
                            placeholder={t("enter", { defaultValue: "Enter" }) + " " + t("nationality", { defaultValue:"Nationality" })}

                        />
                    )}
                />
                {errors?.nationality && (
        <span className="text-danger ">
          {errors?.nationality?.message != "" ? (
            errors?.nationality?.message
          ) : (
            <Trans
              i18nKey="nationalityIsRequired"
              defaults="Nationality is required"
            />
          )}{" "}
        </span>
      )}
            </div>
        </>
    );
};
NationalityFormField.propTypes = {
    nationalityData: PropTypes.any,
    register: PropTypes.any,
    control: PropTypes.any,
    getValues: PropTypes.any,
    setValue: PropTypes.any,
    errors: PropTypes.any,
    fieldList: PropTypes.object
}
export default NationalityFormField;