import React, { useRef, useEffect, useState } from "react";
import { Controller, useFieldArray } from "react-hook-form";
import PropTypes from "prop-types";
import { Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle, faMinusCircle } from "@fortawesome/free-solid-svg-icons";
import { SelectPicker } from "rsuite";
// import { useGetResumedbSchemaQuery } from '../../../services/resume/resumeDetailsService';
import { Trans } from "react-i18next";
import { t } from "i18next";
import { IconButton } from "rsuite";
import usePost from "../../../customhooks/UsePost";

const WebsitesFormField = ({
  websitesData,
  control,
  register,
  getValues,
  setValue,
  errors,
  fieldList,
  type,
}) => {
  const {
    fields: websitesFields,
    append: websitesAppend,
    prepend,
    remove: websitesRemoved,
    swap,
    move,
    insert,
    replace: websitesReplace,
  } = useFieldArray({
    control,
    name: "websites",
  });

  const [socialMediaValues, setSocialMediaValues] = useState([]);
  const [resumeSchema, setResumeSchema] = useState({});
  const {
    post: schema,
    isLoading,
    error: err,
  } = usePost("/user/getresumedbschema");

  useEffect(() => {
    schema({ get: "schema" })
      .then((res) => {
        setResumeSchema(res?.result?.resumeSchema);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    if (resumeSchema?.schema?.values?.socialMedia) {
      setSocialMediaValues(
        resumeSchema?.schema?.values?.socialMedia.map((item) => ({
          label: item,
          value: item,
        }))
      );
    }
  }, [resumeSchema]);

  const inputRef = useRef([]);

  useEffect(() => {
    if (websitesData) {
      if (websitesData.length > 0) {
        websitesReplace();
        websitesAppend(
          websitesData.map((x) => {
            // console.log(x, "efqedqwdcas")
            return {
              text: x.text,
              socialMedia: x.socialMedia,
            };
          })
        );
      } else {
        if (websitesFields?.length == 0) {
          websitesReplace([]);
          websitesAppend([
            {
              text: "",
              socialMedia: "",
            },
          ]);
        }
      }
    }
  }, [websitesData]);
  return (
    <>
      {websitesFields.map((item, index) => {
        return (
          <div className="row my-2" key={item?.id}>
            <div className="col-sm-4 ">
              {index == 0 && (
                <>
                  <label className="text-dark">
                    {t(fieldList?.i18nKey, {
                      defaultValue: fieldList?.fieldName,
                    })}
                  </label>
                  {fieldList?.required && (
                    <span className="text-danger">{"*"}</span>
                  )}
                </>
              )}

              <Controller
                control={control}
                name={`websites[${index}].socialMedia`}
                refs={register(`websites[${index}].socialMedia`, {
                  required: fieldList?.required ? true : false,
                })}
                render={({ field: { onChange, onBlur, value } }) => (
                  <SelectPicker
                    className="w-100 text-dark"
                    data={socialMediaValues}
                    // size='md'
                    cleanable={false}
                    disabled={fieldList?.editable || !fieldList ? false : true}
                    onChange={onChange}
                    value={value}
                    placement="auto"
                  />
                )}
              />
              {errors?.["websites"]?.[index]?.socialMedia?.type ==
                "required" && (
                <span className="text-danger">
                  {errors?.["websites"]?.[index]?.socialMedia?.message != "" ? (
                    errors?.["websites"]?.[index]?.socialMedia?.message
                  ) : (
                    <Trans
                      i18nKey="socialMediaIsRequired"
                      defaults="Social Media Is Required"
                    />
                  )}{" "}
                </span>
              )}
            </div>
            <div className="col-sm-7">
              {index == 0 && (
                <>
                  <label className="text-dark">
                    <Trans i18nKey="websiteUrl" defaults="Website Url" />
                  </label>
                  {fieldList?.required && (
                    <span className="text-danger">{"*"}</span>
                  )}
                </>
              )}
              <Controller
                control={control}
                name={`websites[${index}].text`}
                refs={register(`websites[${index}].text`, {
                  required: fieldList?.required ? true : false,
                  // validate: {
                  //   emptyValue: (v) =>
                  //     v && v != "" ? v.replace(/\s/g, "") != "" : true,
                  // },
                })}
                render={({ field: { onChange, onBlur, value } }) => (
                  <Form.Control
                    size="sm"
                    className="w-100"
                    placeholder={
                      t("enter", { defaultValue: "Enter" }) +
                      " " +
                      t("websiteUrl", { defaultValue: "website url" })
                    }
                    value={value}
                    disabled={
                      type == "customLayout" ||
                      fieldList?.editable ||
                      !fieldList
                        ? false
                        : true
                    }
                    onChange={onChange}
                  />
                )}
              />
              {errors?.["websites"]?.[index]?.text?.type == "required" && (
                <span className="text-danger">
                  {errors?.["websites"]?.[index]?.text?.message != "" ? (
                    errors?.["websites"]?.[index]?.text?.message
                  ) : (
                    <Trans
                      i18nKey="websiteUrlIsRequired"
                      defaults="Website Url is required"
                    />
                  )}{" "}
                </span>
              )}
            </div>
            <div className="col-md-1 justify-content-center align-items-center d-flex ">
              {index == 0 ? (
                <span
                  className=" pb-2 IconArea actionIcon actionIcon-sm"
                  onClick={() => {
                    websitesAppend({
                      text: "",
                      socialMedia: "",
                    });
                  }}
                >
                  {/* <FontAwesomeIcon icon={faPlusCircle} ></FontAwesomeIcon> */}
                  <IconButton
                    icon={
                      <FontAwesomeIcon icon={faPlusCircle}></FontAwesomeIcon>
                    }
                    size="sm"
                  />
                </span>
              ) : (
                <span
                  className=" pb-2 IconArea actionIcon actionIcon-sm"
                  onClick={() => {
                    websitesRemoved(index);
                  }}
                >
                  <IconButton
                    icon={
                      <FontAwesomeIcon icon={faMinusCircle}></FontAwesomeIcon>
                    }
                    size="sm"
                  />
                </span>
              )}
            </div>
          </div>
        );
      })}
    </>
  );
};

export default WebsitesFormField;
