import React, { useEffect, useState,useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { useForm } from "react-hook-form";
import { Modal } from "rsuite";
import ProfileStandard from "../ProfileStandard";
import CompensationModal from "../forms/CompensationModal";
import "../../MyAccount.css";
import { Trans } from "react-i18next";
import { t } from "i18next";
import { v4 as uuidv4 } from "uuid";
import usePost from "../../../../customhooks/UsePost";
import UserContext from "../../../../app/context/UserContext";

const CompensationsSession = ({
  standardFieldsList,
  customFields,
  resumeId,
  status,
  resumeData,
  setUpdateResumeDataData,
}) => {
  const { post: updateResumeDetails, isLoading: UpdateResumeDataDataLoading } =
    usePost("/user/Updateresumedeatails");
  const { user, setUserDetails } = useContext(UserContext);

  const [afterUpdateResumeData, setAfterUpdateResumeData] = useState({});
  const [open, setOpen] = useState(false);

  const {
    control,
    register,
    setValue,
    handleSubmit,
    formState: { errors },
    setError,
    getValues,
    clearErrors,
  } = useForm();
  const handleCompensationsOpen = () => {
    setOpen(true);
    setAfterUpdateResumeData({});
  };
  const handleCompensationsClose = () => {
    clearErrors();
    setOpen(false);
    setValue("minimumExpectedSalary", "");
    setValue("maximumExpectedSalary", "");
    setValue("compensationPeriod", "");
    setValue("compensationCurrency", "");
  };
  const handleCompensationsSubmit = (data) => {
    let compensationCheckFlag = true;
    if (
      data?.minimumExpectedSalary &&
      parseFloat(data.minimumExpectedSalary) < 0
    ) {
      compensationCheckFlag = false;
      setError("minimumExpectedSalary", {
        type: "manual",
        message: (
          <Trans
            i18nKey="pleaseEnterpositiveValues"
            defaults="Please Enter Positive Values"
          />
        ),
      });
    }
    if (
      data?.maximumExpectedSalary &&
      parseFloat(data.maximumExpectedSalary) < 0
    ) {
      compensationCheckFlag = false;
      setError("maximumExpectedSalary", {
        type: "manual",
        message: (
          <Trans
            i18nKey="pleaseEnterpositiveValues"
            defaults="Please Enter Positive Values"
          />
        ),
      });
    }
    if (
      parseFloat(data.minimumExpectedSalary) >
      parseFloat(data.maximumExpectedSalary)
    ) {
      compensationCheckFlag = false;
      setError("minimumExpectedSalary", {
        type: "manual",
        message: (
          <Trans
            i18nKey="minimumPayRateShouldBeLessThanDesiredPayRate"
            defaults="Minimum Pay Rate should be less than Desired Pay Rate"
          />
        ),
      });
    }
    if (
      data?.minimumExpectedSalary != undefined &&
      data?.maximumExpectedSalary != undefined
    ) {
      if (
        data?.minimumExpectedSalary != "" &&
        data?.maximumExpectedSalary != "" &&
        (data?.compensationPeriod == undefined ||
          data?.compensationPeriod == "")
      ) {
        compensationCheckFlag = false;

        setError("compensationPeriod", {
          type: "manual",
          message: (
            <Trans
              i18nKey={"compensationPeriodIsRequired"}
              defaults="Compensation Period is required"
            />
          ),
        });
      }
    }
    if (
      data?.minimumExpectedSalary != undefined &&
      data?.maximumExpectedSalary != undefined
    ) {
      if (
        data?.minimumExpectedSalary != "" &&
        data?.maximumExpectedSalary != "" &&
        (data?.compensationCurrency == undefined ||
          data?.compensationCurrency == "")
      ) {
        compensationCheckFlag = false;
        setError("compensationCurrency", {
          type: "manual",
          message: (
            <Trans
              i18nKey="compensationCurrencyIsRequired"
              defaults="Compensation Currency is required"
            />
          ),
        });
      }
    }
    if (compensationCheckFlag) {
      updateResumeDetails({
        target: "compensation",
        resumeID: resumeId,
        companyID: resumeData?.companyID,
        clientID: resumeData?.clientID,
        getValues: { ...data, "sectionName": standardFieldsList?.sectionName ? standardFieldsList?.sectionName : "Compensation Requirement"},
        user:user,
        type:"Profile Edited"
      })
        .then((res) => {
          if (res) {
            setUpdateResumeDataData(res);
            handleCompensationsClose();
            // window.location.reload();
          }
        })
        .catch((error) => {
          console.error("Error while fetching Resume:", error);
        });
      // updateResumeDetails({ resumeID: resumeId, ...data, "sectionName": standardFieldsList?.sectionName ? standardFieldsList?.sectionName : "Compensation Requirement" })
    }
  };
  useEffect(() => {}, [open]);
  return (
    <>
      <div className="card h-100">
        <div className="card-body">
          <div className="row">
            <div className="col-10 d-flex  align-items-center">
              <h6 className="text-primary m-0">
                <Trans
                  i18nKey="proCompensationRequirement"
                  defaults="Compensation Requirement"
                />
                {/* {t(standardFieldsList?.i18nKey, {
                  defaultValue: standardFieldsList?.sectionName,
                })} */}
              </h6>
            </div>
            {status != "MERGED" &&
            status != "DISQUALIFIED" &&
            status != "DELETED" ? (
              <>
                <div className="col-2 justify-content-end d-flex">
                  {standardFieldsList?.editable ? (
                    <>
                      <span
                        className="IconArea actionIcon actionIcon-sm"
                        onClick={handleCompensationsOpen}
                      >
                        <FontAwesomeIcon className="mx-2" icon={faEdit} />
                      </span>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </>
            ) : (
              <></>
            )}
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="mt-2">
                {standardFieldsList?.rows?.map((sections, index) => {
                  let colNumber = 12;
                  if (sections?.columns.length > 0) {
                    colNumber = 12 / sections?.columns.length;
                  }
                  return (
                    <div className="row mb-3 details" key={uuidv4()}>
                      {sections?.columns?.map((item, index) => {
                        return (
                          <div className={"col-" + colNumber} key={uuidv4()}>
                            <ProfileStandard
                              item={item}
                              resumeID={resumeId}
                              UpdateResumeData={afterUpdateResumeData}
                              resumeData={resumeData}
                              setUpdateResumeDataData={setUpdateResumeDataData}
                            />
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal size={"lg"} open={open} onClose={handleCompensationsClose}>
        <Modal.Header>
          <Modal.Title>
            <Trans
              i18nKey="proCompensationRequirement"
              defaults="Compensation Requirement"
            />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row w-100">
            <div className="col-sm-12 col-md-12">
              <CompensationModal
                itemObj={standardFieldsList?.rows}
                resumeId={resumeId}
                control={control}
                register={register}
                setValue={setValue}
                errors={errors}
                getValues={getValues}
                handleSubmit={handleSubmit}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="row">
            <div className="col-md-12 col-sm-12 d-flex justify-content-between">
              <span>
                <button
                  onClick={handleCompensationsClose}
                  className="btn btn-sm btn-light"
                >
                  <Trans i18nKey="cancel" defaults="Cancel" />
                </button>
              </span>
              <span>
                <button
                  className="btn btn-sm btn-primary"
                  disabled={UpdateResumeDataDataLoading}
                  onClick={handleSubmit(handleCompensationsSubmit)}
                >
                  {UpdateResumeDataDataLoading ? (
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  ) : null}
                  <span className="px-1">
                    <Trans i18nKey="save" defaults="Save" />
                  </span>
                </button>
              </span>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CompensationsSession;
