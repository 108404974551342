import React, { useState, useRef, useEffect } from "react";
import "../SearchBar.css";
import { Dropdown, Button, Form, FormControl } from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";

function SmallScreenLocationAttributes({
  locationAttributes,
  //handleSelectedLocations  filteredLocationAttributes
  getJobs,
  setJobsListData,
  setPage,
  setTotal,
  selectedOptions,
  setSelectedOptions,
  setSearchvalue,
  SearchBodyData,
  setAttributeData,
  setShowJobDetails,
  locationAtributesList,
  setLocationAtributesList,
  locationtags,
  setlocationtags,
}) {
  const { t } = useTranslation();
  const [showDropdown, setShowDropdown] = useState(false);
  // const [locationtags, setlocationtags] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  const locations = locationtags.map((option) => ({
    city: option?.city,
    state: option?.state,
    postalCode: option?.postalCode,
    country: option?.country,
  }));

  const handleDropdownSelect = (option) => {
    // console.log(locationtags);
    if (
      locationtags.some(
        (selectedLocation) =>
          selectedLocation?.city === option.city &&
          selectedLocation?.state === option.state &&
          selectedLocation?.postalCode === option.postalCode &&
          selectedLocation?.country === option.country
      )
    ) {
      setlocationtags((prevOptions) =>
        prevOptions.filter(
          (item) =>
            item.city !== option.city ||
            item.state !== option.state ||
            item.postalCode !== option.postalCode ||
            item.country !== option.country
        )
      );
      setLocationAtributesList((prevOptions) =>
        prevOptions.filter(
          (item) =>
            item.city !== option.city ||
            item.state !== option.state ||
            item.postalCode !== option.postalCode ||
            item.country !== option.country
        )
      );
    } else {
      setlocationtags([...locationtags, option]);
      setLocationAtributesList([...locationtags, option]);
    }
  };

  const formattedSearchQuery = searchQuery.trim().toLowerCase();
  const filteredLocationAttributes = locationAttributes?.filter((option) => {
    const formattedCity = option?.key?.city.toLowerCase();
    const formattedState = option?.key?.state.toLowerCase();
    const formattedPostalCode = option?.key?.postalCode.toLowerCase();
    const formattedLocationString = `${formattedCity}, ${formattedState}`;

    const cleanedSearchQuery = formattedSearchQuery.replace(/[^\w\s,]/gi, "");

    const searchTerms = cleanedSearchQuery.split(/\s*,\s*|\s+/);

    return searchTerms.every(
      (term) =>
        formattedCity.includes(term) ||
        formattedState.includes(term) ||
        formattedPostalCode.includes(term) ||
        formattedLocationString.includes(term)
    );
  });

  return (
    <>
      <Dropdown
        show={showDropdown}
        // onToggle={(isOpen) => setShowDropdown(isOpen)}
        onToggle={(isOpen) => {
          setShowDropdown(isOpen);
          setSearchQuery("");
        }}
        className="position-static"
      >
        <Dropdown.Toggle
          variant="none"
          id="dropdown-basic"
          className="attributeSmall text-muted rounded-pill w-100 border  h-5 "
          size="md"
        >
          <Trans i18nKey="location" defaults="Location" />
        </Dropdown.Toggle>

        <Dropdown.Menu className="dropdown-menu-custom">
          <FormControl
            type="text"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            placeholder={t("searchLocation", {
              defaultValue: "Search Location",
            })}
            className="mx-2 mb-2 atributesSearch  mx-auto"
            // disabled={filteredLocationAttributes.length > 0 ? false : true}

          />
          {/* <Form className="dropdown-form   ">
            <div className="scrollable-options">
              {filteredLocationAttributes?.map((option, index) => {
                const cityNames = option?.key?.city
                  .split(" ")
                  .map(
                    (cityName) =>
                      cityName.charAt(0).toUpperCase() + cityName.slice(1)
                  )
                  .join(" ");
                const stateNames = option?.key?.state
                  .split(" ")
                  .map(
                    (stateName) =>
                      stateName.charAt(0).toUpperCase() + stateName.slice(1)
                  )
                  .join(" ");
                return (
                  <div key={index} className="ms-3">
                    <Form.Check
                      className="custom-checkbox"
                      type="checkbox"
                      id={`option-${index}`}
                      label={` ${cityNames}, ${stateNames} (${option?.doc_count})`}
                      checked={locationtags.some(
                        (selectedLocation) =>
                          selectedLocation?.city === option?.key?.city &&
                          selectedLocation?.state === option?.key?.state &&
                          selectedLocation?.postalCode ===
                            option?.key?.postalCode &&
                          selectedLocation?.country === option?.key?.country
                      )}
                      onChange={() => handleDropdownSelect(option?.key)}
                    />
                  </div>
                );
              })}
            </div>
          </Form> */}
           <Form className="dropdown-form">
            <div className="scrollable-options">
              {locationAttributes.length > 0 ? (
                filteredLocationAttributes.map((option, index) => (
                  <div key={index} className="ms-3">
                    <Form.Check
                      className="custom-checkbox"
                      type="checkbox"
                      id={`option-${index}`}
                      label={`${option.key.city}, ${option.key.state} (${option.doc_count})`}
                      checked={locationtags.some(
                        (selectedLocation) =>
                          selectedLocation.city === option.key.city &&
                          selectedLocation.state === option.key.state &&
                          selectedLocation.postalCode === option.key.postalCode &&
                          selectedLocation.country === option.key.country
                      )}
                      onChange={() => handleDropdownSelect(option.key)}
                    />
                  </div>
                ))
              ) : (
                <div className="text-center p-2">
                  <Trans i18nKey="notFound" defaults="Not Found" />
                </div>
              )}
            </div>
          </Form>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
}

export default SmallScreenLocationAttributes;
