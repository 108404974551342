import React, { useState, useContext } from "react";
import usePost from "../../customhooks/UsePost";
import UserContext from "../../app/context/UserContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faUserCog,
  faPowerOff,
} from "@fortawesome/free-solid-svg-icons";
import { Dropdown } from "react-bootstrap";
import { Loader, Notification, useToaster } from "rsuite";
const ApplyNowModal = React.lazy(() =>
  import("../../components/jobs/ApplyNowModal")
);
import { Trans, useTranslation } from "react-i18next";

function User() {
  const { user, setUser } = useContext(UserContext);
  // console.log(user, "userDetaiils");

  const [openLogin, setOpenLogin] = useState(false);

  const {
    post: logoutPost,
    data,
    loading,
    error,
  } = usePost("/user/logout "); /* /jsv4/webapi/user/logout */
  const toaster = useToaster();
  const { t } = useTranslation();

  async function handleLogout() {
    try {
      const response = await logoutPost();
      // console.log(response, "responseeee");

      if (response) {
        toaster.push(
          <Notification
            type={"success"}
            header={t("success", { defaultValue: "Success" })}
            closable
          >
            {/* <Trans
              i18nKey="WelcomeBack!YourenowLoggedin"
              defaults="Welcome back! You're now logged in."
            /> */}
            {t("logoutSuccessful", {
              defaultValue: "Logout successful",
            })}
          </Notification>,
          { placement: "topEnd" }
        );

        // window.location.reload();
        // window.location.replace("/jobs");
        const currentOrigin = window.location.pathname;
        return currentOrigin.includes("careers")
          ?  window.location.replace("/careers/list")
          : window.location.replace("/jobs");
      } else {
        console.error("Logout failed");
      }
    } catch (error) {
      window.location.reload();
      console.error("Error during logout:", error);
    }
  }

  const handleDropdownToggleClick = () => {
    if (!user || window.location.pathname.includes("guest")) {
      setOpenLogin(true);
    }
  };
  const getAccountUrl = () => {
    const currentOrigin = window.location.pathname; 
    const queryStringParams = `redirect=${currentOrigin}`;
    return currentOrigin.includes("careers")
      ? `/careers/myaccount/basicdetails?${queryStringParams}`
      : `/myaccount/basicdetails`;
  };
  return (
    <>
      <Dropdown>
        <div onClick={handleDropdownToggleClick}>
          <Dropdown.Toggle variant="none" className="trclogin">
            <b className="mytrc-account-button">
              {/* Candidate Login */}
              <Trans i18nKey="candidateLogin" defaults="Candidate Login" />
            </b>
          </Dropdown.Toggle>
        </div>
        <Dropdown.Menu className={`dropdown-menu${openLogin ? " d-none" : ""}`}>
          {user && user?._id && !window.location.pathname.includes("guest") ? (
            <>
              <div className="d-flex align-item-center justify-content-center ">
                <div className="userCss d-flex align-items-center justify-content-center ">
                  <FontAwesomeIcon
                    className=" w-100 h-100  "
                    icon={faUser}
                    size={"2x"}
                  />
                </div>
              </div>

              <div className="text-center text-decoration-none  mt-2">
                <b> {user?.fullName} </b>
              </div>

              <div className="text-center text-decoration-none  mt-1 ps-2 pe-2">
                {" "}
                <p className="plainText">{user?.email}</p>
              </div>
              <Dropdown.Divider />
              <Dropdown.Item
                // href={"/careers/myaccount/basicdetails"}
                href={getAccountUrl()}
                className="text-decoration-none"
              >
                <FontAwesomeIcon icon={faUserCog} className="me-1" />
                <Trans i18nKey="myAccount" defaults="My Account" />
                {/*   My Account */}
              </Dropdown.Item>

              <Dropdown.Item
                onClick={handleLogout}
                className="text-decoration-none"
              >
                <FontAwesomeIcon icon={faPowerOff} className="me-2" />
                {/* Logout */}
                <Trans i18nKey="logout" defaults="Logout" />
              </Dropdown.Item>
            </>
          ) : (
            <>
              <React.Suspense
                fallback={
                  <Loader center speed="normal" size="sm" backdrop={true} />
                }
              >
                <ApplyNowModal
                  openModal={openLogin}
                  setOpenModal={setOpenLogin}
                  from={"myaccount"}
                />
              </React.Suspense>
            </>
          )}
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
}
export default User;
