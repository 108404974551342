import React, { useEffect, useState } from "react";
import "./SearchBarStyles.css";
import { Loader, TagInput, TagPicker } from "rsuite";
import usePost from "../../../customhooks/UsePost";
// import SpinnerIcon from "@rsuite/icons/legacy/Spinner";
import { Trans, useTranslation } from "react-i18next";


function SmallSearchKey({
  values,
  setValues,
  SearchBodyData,
  suggestions,
  setSuggestions,
  getSuggetions,
  loading,
}) {
    const { t } = useTranslation();

  const [words, setWords] = useState("");
  const fetchdata = (word) => {
    setWords(word);

    let body = {
      searchkey: word ?? "",
    };

    getSuggetions(body).then((res) => {
      const UpdateDate = res?.data?.result?.map((x) => {
        return { label: x, value: x };
      });

      setSuggestions(UpdateDate);

      // console.log(res?.data?.result);
    });
  };
  const [cacheData, setCacheData] = React.useState([]);

  const handleSelect = (value, item, event) => {
    setCacheData([...cacheData, item]);
    setValues([...values, item]);
  };
  // console.log(SearchBodyData);
  //   useEffect(() => {
  //     const UpdateDate = SearchBodyData?.searchKey?.map((x) => {
  //       return { label: x, value: x };
  //     });
  //     setSuggestions(UpdateDate);
  //     // const updatedValues = Array.isArray(values) ? values : [];
  //     // setValues(UpdateDate);
  //   }, [SearchBodyData]);

  const handleChange = (newValues) => {
    // console.log(newValues);
    let updatedValues;
    if (Array.isArray(newValues)) {
      updatedValues = newValues;
    } else if (newValues) {
      updatedValues = [newValues];
    } else {
      updatedValues = [];
    }
    setValues(updatedValues);
  };

  return (
    <>
      <div className="search-bar-container">
        <TagPicker
          creatable
          className=" no-border d-block"
          onCreate={(value, item) => {
            // console.log(value, item);
          }}
          data={suggestions ?? []}
          cacheData={cacheData}
          value={values}
          // defaultValue={values}
          labelKey="label"
          valueKey="value"
          onChange={handleChange}
          onSearch={fetchdata}
          onSelect={handleSelect}
          block
          placeholder={t("enterSkills,Designations,Keywords", { defaultValue: "Enter skill designations keywords" })}
          renderMenu={(menu) => {
            if (loading) {
              return (
                <p style={{ padding: 4, color: "#999", textAlign: "center" }}>
                  <Loader /> Loading...
                </p>
              );
            }
            if (words === "" || words == undefined) {
              return (
                <p className="pt-1 px-2 text-muted w-100">
                  <Trans i18nKey="enterSkills,Designations,Keywords" defaults="Enter Skills, Designations,  Keywords" />
               </p>
              );
            }
            return menu;
          }}
        />
      </div>
    </>
  );
}

export default SmallSearchKey;
