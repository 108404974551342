import React, { useState, useEffect,useContext } from "react";
import PropTypes from "prop-types";
import { Trans } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import "../../MyAccount.css"
import UserContext from "../../../../app/context/UserContext";
import usePost from "../../../../customhooks/UsePost";
const Skills = ({ resumeId, UpdateResumeData,resume,setUpdateResumeDataData }) => {
    const { user, setUserDetails } = useContext(UserContext);
    const [skills, setSkills] = useState([])
    useEffect(() => {
        if (resume) {
            setSkills(resume?.resume?.professionalQualification?.skills)
        }
    }, [resume]);
    return (
        <>
            <span className="">
                {skills?.length > 0 ? (
                    <>
                        {skills?.map((keysData, index) => {
                            return (
                                <span key={uuidv4()} className="badge rounded-pill bd-primary  bd-skill-badge badge-md my-1 mx-1 details">
                                    <span className="bd-skill">{keysData?.text}</span>
                                    {keysData?.yearsOfExperience && keysData?.yearsOfExperience > 0 ?
                                        <span className="bd-skill">{keysData?.yearsOfExperience.toFixed(1)} {<Trans i18nKey={"years"} defaults="Years" />}</span>
                                        : null}
                                </span>
                            )
                        }
                        )}
                    </>
                ) : (
                  // <div className="row">
                  //   <div className="col-md-12">
                      <div className="card">
                        <div className="card-body">
                          <span>
                            <Trans
                              i18nKey="noSkillsSpecified"
                              defaults="No Skills Specified"
                            />
                          </span>
                        </div>
                      </div>
                  //   </div>
                  // </div>
                )
                }
            </span>
        </>

    )
}
Skills.propTypes = {
    resumeId: PropTypes.string.isRequired,
    UpdateResumeData: PropTypes.any
}
export default Skills;
