import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { Form } from 'react-bootstrap';
import { SelectPicker } from 'rsuite';
// import { useGetResumedbSchemaQuery } from "../../../services/resume/resumeDetailsService";
import { Trans } from 'react-i18next';
import { t } from 'i18next';
import usePost from '../../../customhooks/UsePost';


const GenderFormField = ({ genderData, control, register, getValues, setValue, errors,fieldList, type}) => {

    useEffect(() => {
        if (genderData) {
            setValue("gender", genderData)
        }
    }, [genderData])
    // const {
    //     data: resumeSchema,
    //     error,
    //     isLoading,
    //     isFetching,
    // } = useGetResumedbSchemaQuery({
    //     refetchOnMountOrArgChange: true,
    //     skip: false,
    // });

    const [genderLabelValues, setGenderLabelValues] = useState([])
    const [resumeSchema, setResumeSchema] = useState({})
    const {
        post: schema,
        isLoading,
        error: err,
      } = usePost("/user/getresumedbschema"); 
    
      useEffect(()=>{
        schema({get: "schema"}).then((res)=>{
            setResumeSchema(res?.result?.resumeSchema)
        }).catch((error)=>{
          console.log(error)
        })
      },[]);

    useEffect(() => {
        if (resumeSchema?.schema?.values?.genderLabels) {
            setGenderLabelValues(
                resumeSchema?.schema?.values?.genderLabels.map(
                    item => ({ label: item, value: item })
                )
            )
        }
    }, [resumeSchema])


    // console.log(genderLabelValues, "genderLabelValues")

    return (
        <>
            <label className='text-dark'>
            {t(fieldList?.i18nKey, { defaultValue: fieldList?.fieldName })}
            </label>
            {fieldList?.required && <span className="text-danger">{"*"}</span>}
            <Controller
                control={control}
                name={`gender`}
                refs={register(`gender`, {
                    required: fieldList?.required ? true : false,
                  })}
                render={({ field: { onChange, onBlur, value } }) => (
                    <SelectPicker
                        className="w-100"
                        data={genderLabelValues}
                        disabled={type=="customLayout" ||fieldList?.editable || !fieldList ? false : true}
                        cleanable={false}
                        onChange={onChange}
                        value={value}
                        placement="auto"
                    />
                )}
            />
            {errors?.gender && (
        <span className="text-danger ">
          {errors?.gender?.message != "" ? (
            errors?.gender?.message
          ) : (
            <Trans
              i18nKey="genderIsRequired"
              defaults="Gender is required"
            />
          )}{" "}
        </span>
      )}
        </>
    );
};

export default GenderFormField;