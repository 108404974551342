import React, { useState, useContext, useEffect } from "react";
import usePost from "../../../customhooks/UsePost";
import UserContext from "../../../app/context/UserContext";
import ResumeModal from "./resume/ResumeModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload, faDownload } from "@fortawesome/free-solid-svg-icons";
import { Whisper, Tooltip, Button } from "rsuite";
import UploadResumeModal from "./resume/UploadResumeModal";
import { Trans } from "react-i18next";
const Resume = ({ resumeID, type ,referrresumedata,companyID}) => {
  const { user, setUserDetails } = useContext(UserContext);
  const [id, setId] = useState("");
  console.log(resumeID);

  useEffect(() => {
    if (type == "referalresume") {
      setId(resumeID);
    } else if (user?.resumeID) {
      setId(user.resumeID);
    }
  }, [user, resumeID]);
  const {
    post: resumeDetails,
    data,
    isLoading: resumeDetailsLoading,
    error,
  } = usePost("/getresumedetails");
  const [resume, setResume] = useState({});

  // let resumeData = {};

  const [resumeData, setresumeData] = useState({});
  useEffect(() => {
    if (user?.collections?.resumes && id !== "") {
      setresumeData({
        resumeCollectionName: user?.collections?.resumes,
        resumeID: id,
      });
    }
  }, [user, id]);
  useEffect(() => {
    // resumeData["resumeCollectionName"] = user?.collections?.resumes;
    // resumeData["resumeID"] = id;
    if (type == "referalresume") {
      setResume(referrresumedata)
    } else if (
      resumeData?.resumeCollectionName &&
      resumeData?.resumeCollectionName != "" &&
      resumeData?.resumeID &&
      resumeData?.resumeID != ""
    ) {
      resumeDetails(resumeData)
        .then((res) => {
          if (res) {
            setResume(res);
          }
        })
        .catch((error) => {
          console.error("Error while fetching Resume:", error);
        });
    }
  }, [resumeData,referrresumedata]);
  const [showUploadResumeModal, setShowUploadResumeModal] = useState(false);
  console.log(resume);
  return (
    <>
      <div className="card">
        <div className="d-flex justify-content-end pe-5 pt-3">
          <div>
            <div className="d-flex justify-content-center">
              <Whisper
                followCursor
                placement={"bottom"}
                speaker={
                  <Tooltip>
                    <Trans
                      i18nKey="uploadYourResume"
                      defaults="Upload Your Resume"
                    />
                  </Tooltip>
                }
              >
                {/* <span
                  className="IconArea actionIcon actionIcon-sm"
                  onClick={() => {
                    setShowUploadResumeModal(true);
                  }}
                >
                  <FontAwesomeIcon icon={faUpload} />
                </span> */}
                <button
                  className="btn btn-primary btn-sm"
                  onClick={() => {
                    setShowUploadResumeModal(true);
                  }}
                  disabled={resumeDetailsLoading}
                >
                  <FontAwesomeIcon icon={faUpload} />
                  <span className="text-start ms-2 mb-2">
                    <Trans
                      i18nKey="uploadYourResume"
                      defaults="Upload Your Resume"
                    />
                  </span>
                </button>
              </Whisper>
            </div>
          </div>
        </div>
        {resume && resume != {} && (
          <ResumeModal resumeData={resume} type="ResumeTab" page={type} companyID={companyID}/>
        )}
      </div>
      <UploadResumeModal
        showUploadResumeModal={showUploadResumeModal}
        setShowUploadResumeModal={setShowUploadResumeModal}
        data={resume}
        setResume={setResume}
        companyID={companyID}
      />
    </>
  );
};

export default Resume;
