import React, { createRef,useState } from "react";
import PropTypes from "prop-types";
import { Controller, useFieldArray } from "react-hook-form";
import { faPlusCircle, faMinusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import {
  isValidPhoneNumber,
} from "react-phone-number-input";
import { Trans,useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from "uuid"
const PhoneNumberForm = ({
  phoneNumbers,
  register,
  setValue,
  control,
  errors,
  handleSubmit,
  getValues,
  required,
  setError,
  clearErrors,
  from
}) => {
  const {
    fields,
    append: phoneAppend,
    prepend,
    remove: phoneRemoved,
    swap,
    move,
    insert,
    replace: phoneReplace,
  } = useFieldArray({
    control,
    name: "phones",
  });
  const ref = createRef();
  const [indee, setIndex]=useState([]);
  const { t } = useTranslation();

//   const { userDetails } = useUserDetails();
  const phoneNumberData = (phoneData) => {
    if (phoneData?.text && phoneData?.text !== "") {
      return phoneData.text;
    } else if (phoneData?.phone && phoneData?.phone !== "") {
      return phoneData.phone;
    } else {
      return "";
    }
  };

  useEffect(() => {
    if (phoneNumbers && phoneNumbers.length > 0) {
      // console.log(getValues());
      phoneReplace();
      let ind = phoneNumbers.map((_,i)=>i);
      setIndex(ind)
      phoneAppend(
        phoneNumbers?.map((x) => {
          return {
            phone: phoneNumberData(x),
            // countryCode: x.countryCode,
            // country: x.country,
            // internationalPhoneCode: x.internationalPhoneCode,
            // phoneNumberOnly: x.phoneNumberOnly,
          };
        })
      );

    } else {
      if (fields?.length == 0) {
        phoneReplace([]);
        phoneAppend([
          {
            phone: "",
            // countryCode: "",
            // country: "",
            // internationalPhoneCode: "",
            // phoneNumberOnly: "",
          },
        ]);
      }
    }
  }, []);

  // const handlePhonenumberChange = (value, index) => {
  //   if (value && value != "" && value?.trim() != "") {
  //     let parsePhoneNumberData = parsePhoneNumber(value);
  //     setValue(`phones[${index}].phone`, parsePhoneNumberData?.number);
  //     setValue(`phones[${index}].countryCode`, parsePhoneNumberData?.country);
  //     setValue(
  //       `phones[${index}].country`,
  //       countryNames[parsePhoneNumberData?.country]
  //     );
  //     setValue(
  //       `phones[${index}].internationalPhoneCode`,
  //       "+" + parsePhoneNumberData?.countryCallingCode
  //     );
  //     setValue(
  //       `phones[${index}].phoneNumberOnly`,
  //       parsePhoneNumberData?.nationalNumber
  //     );
  //   }
  // };

  const handleAddPhone = () => {
    let flag = true;
    if (getValues()?.phones?.length > 0) {
      let phonesList = getValues()?.phones
      for (let i in phonesList) {
        if (phonesList[i]?.phone == "" || phonesList[i]?.phone === undefined || phonesList?.phone === null) {
          // console.log(phonesList, "fgjndfjbngkjdsfbnjkgbn");
          flag = false
          setError("phones." + i + ".phone", {
            type: "manual",
            message:t("phoneNumberIsRequired",{defaultValue:"Phone number is required"}),
          });
        }
        // console.log(isValidPhoneNumber((phonesList[i].phone), "fdknbkdfnkgbn"))
        if (phonesList[i].phone != "") {
          if (!isValidPhoneNumber(phonesList[i].phone)) {
            flag = false
            setError(`phones[${i}].phone`, {
              type: "manual",
              message:  t("pleaseEnterAValidPhoneNumber",{defaultValue:"Please enter a valid phone number"}),
            });
          }
        }
      }

      for (let i = 0; i < getValues()?.phones.length; i++) {
        for (let j = 0; j < getValues()?.phones.length; j++) {
          if (i != j) {
            if (getValues()?.phones[i]?.phone == getValues()?.phones[j].phone) {
              flag = false
              setError("phones[" + j + "].phone",
                {
                  type: "manual",
                  message: t("phonenumberIsAlreadyPresent",{defaultValue:"Phonenumber is already present"}),
                }
              );
            }
          }
        }
      }

      if (flag) {
        clearErrors(["phones"])
        phoneAppend({
          phone: "",
          // countryCode: "",
          // country: "",
          // internationalPhoneCode: "",
          // phoneNumberOnly: "",
        })
      }

    } else {
      phoneAppend({ phone: "", });
    }
  };

  function checkPhoneNumberDuplicateOnChange() {
    for (let i = 0; i < fields.length; i++) {
      for (let j = 0; j < fields.length; j++) {
        if (i != j) {
          clearErrors([`phones.${j}.phone`]);
          if (getValues(`phones.${i}.phone`) == getValues(`phones.${j}.phone`)) {
            setError("phones[" + j + "].phone", {
              type: "manual",
              message: t("phonenumberIsAlreadyPresent",{defaultValue:"Phonenumber is already present"}),
            });
          }
        }
      }
    }
  }

  return (
    <>
       {fields.map((item, index) => {
        return (
          
            <div className="row w-100" key={item?.id}>
              <div className="col-11 my-2">
                <Controller
                  control={control}
                  name={`phones[${index}].phone`}
                  refs={register(`phones[${index}].phone`)}
                  rules={{
                    required: false,
                    validate: (value) => {
                      if (value) {
                        return isValidPhoneNumber(String(value));
                      }
                    },
                  }}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <PhoneInput
                      international
                      defaultCountry={"US"}
                      initialValueFormat="national"
                      countryCallingCodeEditable={false}
                      limitMaxLength={true}
                      inputextraprops={{
                        // value,
                        required: true,
                        autoFocus: true,
                      }}
                      disabled={indee.includes(index) ? true : false}
                      className="from-control"
                      // placeholder="Enter Mobile Number"
                      placeholder={t("pleaseEnterPhoneNumber",{defaultValue:"Please enter phone number"})}
                      // value={value}
                      value={getValues()?.phones[index]?.phone}
                      onChange={(value) => {
                        onChange(value);
                        // handlePhonenumberChange();

                        checkPhoneNumberDuplicateOnChange()
                      }
                      }
                    />

                  )}
                />
                {errors?.["phones"]?.[index]?.['phone']?.type === "validate" ? (
                  <span className="text-danger">
                    <Trans
                      i18nKey="pleaseEnterValidPhoneNumber"
                      defaults="Please enter valid phone number"
                    />
                  </span>
                ) : (<>{errors?.["phones"]?.[index]?.['phone']?.message != "" &&
                  <span className="text-danger">
                    {errors?.["phones"]?.[index]?.['phone']?.message}
                  </span>
                }
                </>
                )}
              </div>

              <div className="col-1 my-2">
              {indee.includes(index) ? "" : 
                <span
                  className="px-2 IconArea actionIcon actionIcon-sm"
                  onClick={() => {
                    phoneRemoved(index);
                    clearErrors(["phones"])
                  }}
                >
                  <FontAwesomeIcon icon={faMinusCircle}></FontAwesomeIcon>
                </span>}
              </div>

            </div>
          
        )
      }
      )}
      <div className="row w-100">
        <div className="col-sm-12 text-center ">
          <div className="justify-content-center d-flex">
            <span
              className="px-2 IconArea actionIcon actionIcon-sm "
              onClick={handleAddPhone}
            >
              <FontAwesomeIcon icon={faPlusCircle}></FontAwesomeIcon>
            </span>
          </div>
          <div onClick={handleAddPhone}>
            <span className="text-secondary">
              <Trans
                i18nKey="addPhoneNumber"
                defaults="Add Phone Number"
              />
            </span>
          </div>
        </div>
      </div>

    </>
  );
};

export default PhoneNumberForm;
