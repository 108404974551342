function changingDateFormat(data) {
    let date = "";
    if (data) {
        if (data !== "" || data !== undefined || data !== null) {
            if (data != "present") {
                let x = data?.split("-")
                date += x[0] + "-"
                if (x[1]?.length > 1) {
                    date += x[1] + "-"
                } else {
                    date += 0 + x[1] + "-"
                }
                if (x[2]?.length > 1) {
                    date += x[2]
                } else {
                    date += 0 + x[2]
                }
            }
        }
    }
    return date;
}
export default changingDateFormat