import React, { useEffect, useState } from "react";
import { useFieldArray, Controller } from "react-hook-form";
import { Form } from "react-bootstrap";
import { v4 as uuidv4 } from "uuid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinusCircle, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import UserDateInput from "../../../Date/UserDateInput";
import { DateTime } from "luxon";
import changingDateFormat from "../../../../../api/services/changingDateFormat";
import { Trans } from "react-i18next";
import { t } from "i18next";

const LicencesModal = ({
  control,
  register,
  getValues,
  setError,
  setValue,
  errors,
  licenseList,
  licenseRows,
  clearErrors,
  handleSubmit
}) => {
  const {
    fields: licenceFields,
    append: licenceAppend,
    prepend,
    remove: licenceRemoved,
    swap,
    move,
    insert,
    replace: licenceReplace,
  } = useFieldArray({
    control,
    name: "licence",
  });
  useEffect(() => {
    if (licenseList) {
      licenceReplace();
      if (licenseList.length > 0) {
        licenceAppend(
          licenseList.map((x) => {
            return {
              licence: x.text,
              issuedBy: x.issuedBy,
              licenceType: x.type,
              issuedOn:
                x?.issuedOn.standard != "" && x?.issuedOn.standard != "Invalid DateTime"
                  ? DateTime.fromISO(
                    changingDateFormat(x?.issuedOn.standard),
                    "yyyy-MM-dd"
                  ).toJSDate()
                  : "",
              expiryDate:
                x.expiryDate.standard != "" && x?.expiryDate.standard != "Invalid DateTime"
                  ? DateTime.fromISO(
                    changingDateFormat(x.expiryDate.standard),
                    "yyyy-MM-dd"
                  ).toJSDate()
                  : "",
            };
          })
        );
      } else {
        licenceAppend([
          {
            licence: "",
            issuedBy: "",
            licenceType: "",
            issuedOn: "",
            expiryDate: "",
          },
        ]);
      }
    }
  }, []);
  const [dateClear, setDateClear] = useState(true);
  useEffect(() => {
    setDateClear(false);
  }, [dateClear]);

  function DurationValidation() {
    for (let i in licenceFields) {
      let flag = true;
      if (
        getValues("licence[" + i + "].issuedOn") != "" &&
        getValues("licence[" + i + "].expiryDate") != ""
      ) {
        if (
          DateTime.fromJSDate(
            getValues("licence[" + i + "].issuedOn")
          ).toMillis() >=
          DateTime.fromJSDate(
            getValues("licence[" + i + "].expiryDate")
          ).toMillis()
        ) {
          flag = false;
          setError("licence[" + i + "].expiryDate", {
            type: "manual",
            message: t(
              "endDateShouldBeGreaterThan",
              {
                defaultValue:
                  "End date should be greater than start date",
              }
            )
          });
        }
      }
      if (flag) {
        clearErrors([
          "licence[" + i + "].issuedOn",
          "licence[" + i + "].expiryDate",
        ]);
      }
    }
  }
  const handleAddLicense = () => {
    licenceAppend({
      licence: "",
      issuedBy: "",
      licenceType: "",
      issuedOn: "",
      expiryDate: "",
    });
  };
  // console.log(licenceFields, "licenceFieldsefad");
  return (
    <>
      {licenceFields.map((itemData, index1) => {
        return (
          
            <div className="row py-2" key={uuidv4()}>
              <div className="col-sm-12 col-md-12">
                <div className="card">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-sm-12 justify-content-end d-flex">
                        <span
                          className="px-2 IconArea actionIcon actionIcon-sm"
                          onClick={() => {
                            licenceRemoved(index1);
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faMinusCircle}
                          ></FontAwesomeIcon>
                        </span>
                      </div>
                    </div>
                    {licenseRows?.length > 0 &&
                      licenseRows.map((item, index) => {
                        let colNumber = 12;
                        if (item.columns.length > 0) {
                          colNumber = 12 / item.columns.length;
                        }

                        return (
                          
                            <div className="row py-2" key={uuidv4()}>
                              {item?.columns?.length > 0 &&
                                item?.columns.map((column, index) => {
                                  return (
                                    
                                      <div
                                        className={"col-sm-" + colNumber}
                                        key={uuidv4()}
                                      >
                                        {column?.fieldCode == "License" ? (
                                          <>
                                            <div className="form-group">
                                              <label>
                                                {t(column?.i18nKey, { defaultValue: column?.fieldName })}
                                              </label>
                                              {column?.required && <span className="text-danger">{"*"}</span>}
                                              <Controller
                                                control={control}
                                                name={`licence[${index1}].licence`}
                                                refs={register(
                                                  `licence[${index1}].licence`,
                                                  {
                                                    required: column?.required ? true : false,
                                                    validate: {
                                                      emptyValue: (v) =>
                                                        v && v != "" ? v.replace(/\s/g, "") != "" : true,
                                                    },
                                                  }
                                                )}
                                                render={({
                                                  field: {
                                                    onChange,
                                                    onBlur,
                                                    value,
                                                    refs,
                                                  },
                                                }) => (
                                                  <Form.Control
                                                    size="sm"
                                                    refs={refs}
                                                    className="w-100"
                                                    placeholder={t("enter", { defaultValue: "Enter" }) + " " + t(column?.i18nKey, { defaultValue: column?.fieldName || "Comment" })}
                                                    value={value}
                                                    disabled={column?.editable ? false : true}
                                                    onChange={onChange}
                                                  />
                                                )}
                                              />
                                              {errors?.["licence"]?.[index1]?.["licence"] && (
                                              <span className="text-danger ">
                                              {errors?.["licence"]?.[index1]?.["licence"]?.message != "" ? (
                                                errors?.["licence"]?.[index1]?.["licence"]?.message
                                              ) : (
                                                <Trans
                                                  i18nKey="licenceIsRequired"
                                                  defaults="Licence Is Required"
                                                />
                                              )}{" "}
                                            </span>
                                            )}
                                            </div>
                                          </>
                                        ) : null}
                                        {column?.fieldCode == "Lic Type" ? (
                                          <>
                                            <div className="form-group">
                                              <label>
                                                {t(column?.i18nKey, { defaultValue: column?.fieldName })}
                                              </label>
                                              {column?.required && <span className="text-danger">{"*"}</span>}
                                              <Controller
                                                control={control}
                                                name={`licence[${index1}].licenceType`}
                                                refs={register(
                                                  `licence[${index1}].licenceType`,
                                                  {
                                                    required: column?.required ? true : false,
                                                    validate: {
                                                      emptyValue: (v) =>
                                                        v && v != "" ? v.replace(/\s/g, "") != "" : true,
                                                    },
                                                  }
                                                )}
                                                render={({
                                                  field: {
                                                    onChange,
                                                    onBlur,
                                                    value,
                                                    refs,
                                                  },
                                                }) => (
                                                  <Form.Control
                                                    size="sm"
                                                    refs={refs}
                                                    className="w-100"
                                                    placeholder={t("enter", { defaultValue: "Enter" }) + " " + t(column?.i18nKey, { defaultValue: column?.fieldName || "Type" })}
                                                    value={value}
                                                    disabled={column?.editable ? false : true}
                                                    onChange={onChange}
                                                  />
                                                )}
                                              />
                                              {errors?.["licence"]?.[index1]?.["licenceType"] && (
                                              <span className="text-danger ">
                                              {errors?.["licence"]?.[index1]?.["licenceType"]?.message != "" ? (
                                                errors?.["licence"]?.[index1]?.["licenceType"]?.message
                                              ) : (
                                                <Trans
                                                  i18nKey="licenceTypeIsRequired"
                                                  defaults="Licence Type Is Required"
                                                />
                                              )}{" "}
                                            </span>
                                            )}
                                            </div>
                                          </>
                                        ) : null}
                                        {column?.fieldCode == "Lic Issued On" ? (
                                          <>
                                            <div className="form-group">
                                              <label>
                                                {t(column?.i18nKey, { defaultValue: column?.fieldName })}
                                              </label>
                                              {column?.required && <span className="text-danger">{"*"}</span>}
                                              <div>
                                                <Controller
                                                  control={control}
                                                  name={`licence[${index1}].issuedOn`}
                                                  refs={register(
                                                    `licence[${index1}].issuedOn`,
                                                    {
                                                      required: column?.required ? true : false,
                                                      // validate: function (value) {
                                                      //   if (value && getValues().licence[index1]?.expiryDate && getValues().licence[index1]?.expiryDate != "") {
                                                      //     if (DateTime.fromJSDate(getValues().licence[index1]?.expiryDate).toMillis() <
                                                      //       DateTime.fromJSDate(value).toMillis()) {
                                                      //       return t("endDateShouldBeTreaterThanStartDate", {
                                                      //         defaultValue: "End Date should be greater than Start Date",
                                                      //       })
                                                      //     }
                                                      //   }
                                                      // }
                                                    }
                                                  )}
                                                  render={({
                                                    field: {
                                                      onChange,
                                                      onBlur,
                                                      value,
                                                      refs,
                                                    },
                                                  }) =>
                                                    itemData?.issuedOn || getValues().licence[index1]?.issuedOn != "" ? (
                                                      <UserDateInput
                                                        value={value ? value : getValues().licence[index1]?.issuedOn}
                                                        refs={refs}
                                                        key={dateClear}
                                                        setDateClear={
                                                          setDateClear
                                                        }
                                                        disabled={column?.editable ? false : true}
                                                        onChange={(value) => {
                                                          onChange(value);
                                                          DurationValidation()
                                                        }}
                                                        placement="auto"
                                                      />
                                                    ) : (
                                                      <UserDateInput
                                                        refs={refs}
                                                        key={dateClear}
                                                        setDateClear={
                                                          setDateClear
                                                        }
                                                        disabled={column?.editable ? false : true}
                                                        onChange={(value) => {
                                                          onChange(value);
                                                          DurationValidation()
                                                        }}
                                                        placement="auto"
                                                      />
                                                    )
                                                  }
                                                />
                                              </div>
                                            </div>
                                            {errors?.["licence"]?.[index1]?.["issuedOn"]?.type == "required" ? (
                                              <span className="text-danger ">
                                              {errors?.["licence"]?.[index1]?.["issuedOn"]?.message != "" ? (
                                                errors?.["licence"]?.[index1]?.["issuedOn"]?.message
                                              ) : (
                                                <Trans
                                                  i18nKey="issuedOnIsRequired"
                                                  defaults="Issued On Is Required"
                                                />
                                              )}{" "}
                                            </span>
                                            ) : <>
                                            {errors?.["licence"]?.[index1]?.["issuedOn"] && (
                                              <span className="text-danger">
                                                {errors?.["licence"]?.[index1]?.["issuedOn"]?.message != ""
                                                  && errors?.["licence"]?.[index1]?.["issuedOn"].message}
                                              </span>
                                            )}
                                            </>
                                            }
                                            
                                          </>
                                        ) : null}
                                        {column?.fieldCode ==
                                          "Lic Expiry Date" ? (
                                          <>
                                            <div className="form-group">
                                              <label>
                                                {t(column?.i18nKey, { defaultValue: column?.fieldName })}
                                              </label>
                                              {column?.required && <span className="text-danger">{"*"}</span>}
                                              <div className="">
                                                <Controller
                                                  control={control}
                                                  name={`licence[${index1}].expiryDate`}
                                                  refs={register(
                                                    `licence[${index1}].expiryDate`,
                                                    {
                                                      required: column?.required ? true : false,
                                                       validate: function (value) {
                                                        if (value && getValues().licence[index1]?.issuedOn && getValues().licence[index1]?.issuedOn != "") {
                                                          if (DateTime.fromJSDate(getValues().licence[index1]?.issuedOn).toMillis() >
                                                            DateTime.fromJSDate(value).toMillis()) {
                                                              return t("endDateShouldBeTreaterThanStartDate", {
                                                                defaultValue: "End Date should be greater than Start Date",
                                                              })                                                          }
                                                        }
                                                      }
                                                     
                                                    }
                                                  )}
                                                  render={({
                                                    field: {
                                                      onChange,
                                                      onBlur,
                                                      value,
                                                      refs,
                                                    },
                                                  }) =>
                                                    itemData?.expiryDate || getValues().licence[index1]?.expiryDate != "" ? (
                                                      <UserDateInput
                                                        value={value ? value : getValues().licence[index1]?.expiryDate}
                                                        refs={refs}
                                                        key={dateClear}
                                                        setDateClear={
                                                          setDateClear
                                                        }
                                                        disabled={column?.editable ? false : true}
                                                        onChange={(v) => {
                                                          onChange(v);
                                                          DurationValidation()
                                                        }}
                                                        placement="auto"
                                                      />
                                                    ) : (
                                                      <UserDateInput
                                                        refs={refs}
                                                        key={dateClear}
                                                        setDateClear={
                                                          setDateClear
                                                        }
                                                        disabled={column?.editable ? false : true}
                                                        onChange={(v) => {
                                                          onChange(v);
                                                          DurationValidation()
                                                        }}
                                                        placement="auto"
                                                      />
                                                    )
                                                  }
                                                />
                                              </div>
                                            </div>
                                            {errors?.["licence"]?.[index1]?.["expiryDate"]?.type == "required" ? (
                                              <span className="text-danger ">
                                              {errors?.["licence"]?.[index1]?.["expiryDate"]?.message != "" ? (
                                                errors?.["licence"]?.[index1]?.["expiryDate"]?.message
                                              ) : (
                                                <Trans
                                                  i18nKey="expiryDateIsRequired"
                                                  defaults="Expiry Date Is Required"
                                                />
                                              )}{" "}
                                            </span>
                                            ) : <>
                                            {errors?.["licence"]?.[index1]?.["expiryDate"] && (
                                              <span className="text-danger">
                                                {errors?.["licence"]?.[index1]?.["expiryDate"]?.message != ""
                                                  && errors?.["licence"]?.[index1]?.["expiryDate"].message}
                                              </span>
                                            )}
                                            </>
                                            }
                                          </>
                                        ) : null}
                                        {column?.fieldCode == "Lic Issued By" ? (
                                          <>
                                            <div className="form-group">
                                              <label>
                                                {t(column?.i18nKey, { defaultValue: column?.fieldName })}
                                              </label>
                                              {column?.required && <span className="text-danger">{"*"}</span>}
                                              <Controller
                                                control={control}
                                                name={`licence[${index1}].issuedBy`}
                                                refs={register(
                                                  `licence[${index1}].issuedBy`,
                                                  {
                                                    required: column?.required ? true : false,
                                                    validate: {
                                                      emptyValue: (v) =>
                                                        v && v != "" ? v.replace(/\s/g, "") != "" : true,
                                                    },
                                                  }
                                                )}
                                                render={({
                                                  field: {
                                                    onChange,
                                                    onBlur,
                                                    value,
                                                    refs,
                                                  },
                                                }) => (
                                                  <Form.Control
                                                    size="sm"
                                                    placeholder={t("enter", { defaultValue: "Enter" }) + " " + t(column?.i18nKey, { defaultValue: column?.fieldName || "IssueD By" })}
                                                    value={value}
                                                    refs={refs}
                                                    disabled={column?.editable ? false : true}
                                                    onChange={onChange}
                                                  />
                                                )}
                                              />
                                              {errors?.["licence"]?.[index1]?.["issuedBy"] && (
                                              <span className="text-danger ">
                                              {errors?.["licence"]?.[index1]?.["issuedBy"]?.message != "" ? (
                                                errors?.["licence"]?.[index1]?.["issuedBy"]?.message
                                              ) : (
                                                <Trans
                                                  i18nKey="issuedByIsRequired"
                                                  defaults="Issued By Is Required"
                                                />
                                              )}{" "}
                                            </span>
                                            )}
                                            </div>
                                          </>
                                        ) : null}
                                      </div>
                                    
                                  );
                                })}
                            </div>
                          
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
          
        );
      })}
      <div className="my-2">
        <div className="justify-content-center d-flex">
          <span
            className="px-2 IconArea actionIcon actionIcon-lg"
            onClick={handleSubmit(handleAddLicense)}
          >
            <FontAwesomeIcon icon={faPlusCircle}></FontAwesomeIcon>
          </span>
        </div>
        <div className="justify-content-center d-flex">
          <span className="">
            <Trans
              i18nKey="addLicence"
              defaults="Add Licenses Details"
            />
          </span>
        </div>
      </div>
    </>
  );
};

export default LicencesModal;
