import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Input } from "rsuite";
import { t } from "i18next";

function LocationField({ handlePlaceChange, onChange, value, column, index }) {
  const inputRef = useRef([]);
  const isMounted = React.useRef(false);

  const Googledata = () => {
    const google = window.google;
    if (!google) return;
    const autocomplete = new google.maps.places.Autocomplete(inputRef.current, {
      types: ["geocode"],
      componentRestrictions: { country: ["US"] }, // Replace 'US' with your desired country code.
      fields: [
        "address_components",
        "geometry",
        "icon",
        "name",
        "formatted_address",
        "place_id",
      ],
    });
    autocomplete.addListener("place_changed", () => {
      const place = autocomplete.getPlace();
      if ((index != undefined && index != null) || index != "") {
        handlePlaceChange(place, index);
      } else {
        handlePlaceChange(place);
      }
    });
  };

  useEffect(() => {
    if (!isMounted?.current) {
      Googledata();
      isMounted.current = true;
    }
  }, []);

  return (
    <>
      <Input
        ref={inputRef}
        size="sm"
        disabled={column?.editable ? false : true}
        placeholder={
          t("enter", { defaultValue: "Enter" }) +
          " " +
          t("proRefLocation", { defaultValue: "Location" })
        }
        onChange={onChange}
        value={value}
        // block
      />
    </>
  );
}

// LocationField.propTypes = {
//     handlePlaceChange: PropTypes.func,
//     onChange: PropTypes.func,
//     value: PropTypes.string,
//     column:PropTypes.any,
//     index: PropTypes.any
// }

export default LocationField;
