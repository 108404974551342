import React, { useEffect, useState, useRef } from "react";
import queryString from "query-string";
import {
  Modal,
  Button,
  ButtonToolbar,
  Placeholder,
  Input,
  Form,
  Notification,
  toaster,
  Loader,
} from "rsuite";
import { Controller, useForm } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import { useLoaderData } from "react-router-dom";
// import searchJsonConfig from "../../utilities/search.json";
import referLayouts from "../../../utilities/referFriendLayouts.json";
// import { useParams } from "react-router-dom";
import usePost from "../../../customhooks/UsePost";
import Phone, {
  isValidPhoneNumber,
  parsePhoneNumber,
} from "react-phone-number-input";
import { StandaloneSearchBox } from "@react-google-maps/api";
import "./CareersReferFriend.css";

function CareersReferFriend({ JobDetails }) {
  const {
    register,
    handleSubmit,
    reset,
    control,
    clearErrors,
    setError,
    getValues,
    setValue,
    formState: { errors, isSubmitSuccessful },
  } = useForm();

  // const [openModal, setOpenModal] = useState(false);
  const [openModel2, setOpenModel2] = useState(false);
  const inputRef = useRef();
  const [referFriendSection, setReferFriendSection] = useState();

  const handleOpen = () => {
    setOpenModel2(true);
  };
  const handleClose = () => {
    setOpenModel2(false);
  };
  const {
    post: referFriend,
    // data,
    isLoading: referFriendLoading,
    // error,
  } = usePost("/referfriend");
  const { post: referFriendLayouts, isLoading: referFriendLayoutsLoading } =
    usePost("/referFriendLayouts");

  // useEffect(() => {
  //  let obj={}
  //  obj.companyID = JobDetails?.companyID;
  //  obj.enabled = true;
  //  obj.page = "Refer a Friend"
  //  referFriendLayouts(obj).then((res)=>{

  //   if(res?.result?.status == true && res?.result?.referFriendLayouts.length > 0){
  //     setReferFriendSection(res?.result?.referFriendLayouts?.[0]?.layout)
  //   }
  //   else {
  //     setReferFriendSection(referLayouts?.layout)
  //   }
  //     })
  // }, [JobDetails]);
  useEffect(() => {
    if (JobDetails && openModel2) {
      let obj = {
        companyID: JobDetails?.companyID,
        enabled: true,
        page: "Refer a Friend",
      };

      referFriendLayouts(obj)
        .then((res) => {
          if (
            res?.status === true &&
            res?.result?.referFriendLayouts.length > 0
          ) {
            setReferFriendSection(res?.result?.referFriendLayouts[0]?.layout);
          } else {
            setReferFriendSection(referLayouts?.layout);
          }
        })
        .catch((error) => {
          console.error("Error fetching referFriendLayouts:", error);
        });
    } else {
      setReferFriendSection(referLayouts?.layout);
    }
  }, [openModel2]);

  //  console.log(JobDetails,"JobDetailsJobDetails")
  //  console.log(referLayouts,"referFriendLayout")
  const [location, setLocation] = useState(null);

  const handleLocationChange = () => {
    let locationObj = {};
    const places = inputRef.current.getPlaces();
    if (places.length > 0) {
      const location = places[0];

      // console.log("klllllllllllllll", places[0]);

      if (location?.formatted_address && location?.formatted_address != "") {
        locationObj.text = location?.formatted_address;

        setValue("location", location?.formatted_address);
      }
      if (
        location?.address_components &&
        location?.address_components?.length > 0
      ) {
        for (let i in location.address_components) {
          // country
          if (location.address_components[i].types.indexOf("country") != -1) {
            locationObj.country = location.address_components[i].long_name;
          }
          if (location.address_components[i].types.indexOf("country") != -1) {
            locationObj.countryCode = location.address_components[i].short_name;
          }
          // state
          if (
            location.address_components[i].types.indexOf(
              "administrative_area_level_1"
            ) != -1
          ) {
            locationObj.state = location.address_components[i].long_name;
          }
          if (
            location.address_components[i].types.indexOf(
              "administrative_area_level_1"
            ) != -1
          ) {
            locationObj.stateCode = location.address_components[i].short_name;
          }
          // city
          if (location.address_components[i].types.indexOf("locality") != -1) {
            locationObj.city = location.address_components[i].long_name;
          }
          if (
            location.address_components[i].types.indexOf("postal_code") != -1
          ) {
            locationObj.postalcode = location.address_components[i].long_name;
          }
        }
      }

      // console.log("jsdhjgsajgfjdsggfdsgfhsgfhgdsjgf", locationObj);
      setLocation(locationObj);
    }
  };

  let loaderDetails = useLoaderData();
  // console.log(loaderDetails?.jobDetails?.collectionName, "dtaaaaa");
  const { t } = useTranslation();
  // console.log(JobDetails, "refer-friend");
  const onSubmit = (data) => {
    let payload = {
      // campaignID: JobDetails?._id,
      friendEmail: getValues("friendEmail"),
      friendName: getValues("friendName"),
      friendLocation: location,
      source:
        queryString.parse(window.location.search).source != null
          ? queryString.parse(window.location.search).source
          : "",
    };

    payload.campaignID = loaderDetails?.jobDetails?._id;
    payload.collectionname = loaderDetails?.jobDetails?.collectionName;

    if (data?.mobileNumber && data?.mobileNumber != "") {
      let parsedNumber = parsePhoneNumber(String(data?.mobileNumber));
      payload.friendMobileNumber = parsedNumber?.number;
      payload.friendPhoneCode = "+" + parsedNumber?.countryCallingCode;
      payload.friendPhoneNumberOnly = parsedNumber?.nationalNumber
    }
    if (window.location.search.includes("rid")) {
      payload.type = "careersresumepage";
      payload.resumeID = queryString.parse(window.location.search).rid;
      if (window.location.search.includes("iid")) {
        payload.interviewID = queryString.parse(window.location.search).iid;
      } else if (window.location.search.includes("jid")) {
        payload.interviewID = queryString.parse(window.location.search).jid;
      }
    } else {
      payload.type = "careerspage";
      payload.yourName = getValues("yourName");
      payload.yourEmail = getValues("yourEmail");
      if (data?.yourMobileNumber && data?.yourMobileNumber != "") {
        let parsedNumber = parsePhoneNumber(String(data?.yourMobileNumber));
        payload.yourMobileNumber = parsedNumber?.number;
        payload.yourPhoneCode = "+" + parsedNumber?.countryCallingCode;
        payload.yourPhoneNumberOnly = parsedNumber?.nationalNumber
      }
      // payload.yourMobileNumber = getValues("yourMobileNumber");
    }
    console.log(payload);
    referFriend(payload)
      .then((resp) => {
        console.log("dhgjhsgdjfdgf", resp);

        if (resp?.status) {
          if (resp?.result?.status) {
            if (
              resp?.result?.message &&
              resp?.result?.message == "Already Inserted"
            ) {
              toaster.push(
                <Notification
                  type={"info"}
                  header={t("info", { defaultValue: "Info" })}
                  closable
                >
                  {t("YouAlreadyReferredYourFriend", {
                    defaultValue: "You already referred your friend",
                  })}
                </Notification>,
                { placement: "topEnd" }
              );
            } else {
              toaster.push(
                <Notification
                  type={"success"}
                  header={t("success", { defaultValue: "success" })}
                  closable
                >
                  {t("thankYouForReferringYourFriend", {
                    defaultValue: "Thank you for referring your friend",
                  })}
                </Notification>,
                { placement: "topEnd" }
              );
              setOpenModel2(false);
            }
          } else {
            if (resp?.result?.message.includes("Campaign is not active")) {
              toaster.push(
                <Notification
                  type={"error"}
                  header={t("error", { defaultValue: "Error" })}
                  closable
                >
                  {t("thisJobIsNoLongerAcceptingApplications", {
                    defaultValue:
                      "This job is no longer accepting applications",
                  })}
                </Notification>,
                { placement: "topEnd" }
              );
            } else {
              toaster.push(
                <Notification
                  type={"error"}
                  header={t("error", { defaultValue: "Error" })}
                  closable
                >
                  {t("somethingWentWrongPleaseTryAgainLater", {
                    defaultValue: "Something went wrong please try again later",
                  })}
                </Notification>,
                { placement: "topEnd" }
              );
            }
          }
        } else {
          toaster.push(
            <Notification
              type={"error"}
              header={t("error", { defaultValue: "Error" })}
              closable
            >
              {t("somethingWentWrongPleaseTryAgainLater", {
                defaultValue: "Something went wrong please try again later",
              })}
            </Notification>,
            { placement: "topEnd" }
          );
        }
      })
      .catch((error) => {
        // console.log("eeeeeeeeeeeeeee", error)
      });

    // console.log(data, "ffffffffffffffff");
    // setOpenModel2(false);
  };

  useEffect(() => {
    if (openModel2 !== true) {
      reset();
      // setError()
    }
  }, [openModel2]);

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <ButtonToolbar>
          <Button
            onClick={handleOpen}
            className="btn btn-primary-soft  text-gray-700 me-2 py-3"
          >
            <Trans defaults="Refer Friend" i18nKey="referFriend" />
          </Button>
        </ButtonToolbar>
      </Form>
      {/*  <button className="btn btn-primary-soft  text-gray-700 me-2 py-3">
              Refer a Friend
            </button> */}
      {openModel2 && (
        <Modal
          open={openModel2}
          onClose={handleClose}
          size={"sm"}
          className="z-1"
        >
          {/* <div className="p-3">
            <h3>Refer Friend</h3> */}
          <Modal.Header>
            <Modal.Title>
              <Trans defaults="Refer Friend" i18nKey="referFriend" />
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            {!window?.location.search.includes("rid") &&
              referFriendSection?.referrer && (
                <>
                  {/* <p className=" text-capitalize heading text-center">
         {referFriendSection?.referrer?.sections?.[0]?.sectionName}
         </p> */}
                  {referFriendSection?.referrer?.sections?.[0]?.rows.map(
                    (item, rowIndex) => {
                      return (
                        <React.Fragment key={rowIndex}>
                          {item?.columns.map((column, columnIndex) => (
                            <div className="mt-1" key={columnIndex}>
                              {column.fieldCode === "Full Name" &&
                              column.fieldType === "Standard" ? (
                                <>
                                  <label className="ms-1">
                                    <Trans
                                      defaults="Your Name"
                                      i18nKey="yourName"
                                    />
                                  </label>
                                  {column.required === true && (
                                    <span className="text-danger">{"*"}</span>
                                  )}
                                  <input
                                    className="form-control mb-1"
                                    type="text"
                                    name="yourName"
                                    defaultValue={""}
                                    placeholder="Enter your name"
                                    {...register("yourName", {
                                      // required: true,
                                      required: column?.required,
                                      validate: {
                                        emptyValue: (v) =>
                                          v && v !== ""
                                            ? v.replace(/\s/g, "") !== ""
                                            : true,
                                      },
                                      maxLength: 160,
                                      minLength: 1,
                                    })}
                                  />
                                  {errors.yourName && (
                                    <span className="text-danger">
                                      <Trans
                                        defaults="Please enter your name"
                                        i18nKey="pleaseEnterYourName"
                                      />
                                    </span>
                                  )}
                                </>
                              ) : null}

                              {column.fieldCode === "email" &&
                              column.fieldType === "Standard" ? (
                                <div className="mt-3">
                                  <label className="ms-1">
                                    <Trans
                                      defaults="Your Email"
                                      i18nKey="yourEmail"
                                    />
                                  </label>
                                  {column.required === true && (
                                    <span className="text-danger">{"*"}</span>
                                  )}
                                  <input
                                    className="form-control pl-2 pr-2"
                                    size="xs"
                                    type="yourEmail"
                                    defaultValue={""}
                                    placeholder="Enter your email"
                                    {...register("yourEmail", {
                                      // required: true,
                                      required: column?.required,
                                      pattern: {
                                        value:
                                          /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                        message: "Please enter a valid email",
                                      },
                                    })}
                                  />
                                  {errors?.yourEmail &&
                                    (errors?.yourEmail?.message != "" ? (
                                      <span className="text-danger">
                                        {errors?.yourEmail?.message}
                                      </span>
                                    ) : (
                                      <span className="text-danger">
                                        <Trans
                                        defaults="Please enter your email"
                                        i18nKey="pleaseEnterYourEmail"
                                      />
                                      </span>
                                    ))}
                                </div>
                              ) : null}

                              {column.fieldCode === "phone" &&
                              column.fieldType === "Standard" ? (
                                <div className="mt-3">
                                  <label className="ms-1">
                                    <Trans
                                      defaults="Your Mobile Number"
                                      i18nKey="yourMobileNumber"
                                    />
                                  </label>
                                  {column.required === true && (
                                    <span className="text-danger">{"*"}</span>
                                  )}
                                  <Controller
                                    control={control}
                                    name="yourMobileNumber"
                                    refs={register(`yourMobileNumber`, {
                                      // required: true,
                                      required: column?.required,
                                      validate: (value) => {
                                        if (value) {
                                          if (
                                            !isValidPhoneNumber(String(value))
                                          ) {
                                            return "Please enter a valid mobile number";
                                          }
                                        }
                                      },
                                    })}
                                    defaultValue={""}
                                    render={({
                                      field: { onChange, onBlur, value },
                                    }) => (
                                      <Phone
                                        className="form-control border-0 p-0"
                                        defaultCountry="US"
                                        placeholder="Enter your mobile number"
                                        onChange={onChange}
                                        value={value}
                                      />
                                    )}
                                  />
                                  {errors?.yourMobileNumber &&
                                    (errors?.yourMobileNumber?.message != "" ? (
                                      <span className="text-danger">
                                        {errors?.yourMobileNumber?.message}
                                      </span>
                                    ) : (
                                      <span className="text-danger">
                                        <Trans
                                          defaults="Please enter your mobile number"
                                          i18nKey="pleaseEnterYourMobileNumber"
                                        />
                                      </span>
                                    ))}
                                </div>
                              ) : null}
                            </div>
                          ))}
                        </React.Fragment>
                      );
                    }
                  )}
                </>
              )}
            {/* <p className=" text-capitalize heading text-center mt-3">
         {referFriendSection?.referral?.sections?.[0]?.sectionName}
         </p> */}
            {referFriendSection?.referral?.sections?.[0]?.rows.map(
              (item, rowIndex) => {
                return (
                  <React.Fragment key={rowIndex}>
                    {item?.columns.map((column, columnIndex) => (
                      <div className="mt-3" key={columnIndex}>
                        {column.fieldCode === "Full Name" &&
                        column.fieldType === "Standard" ? (
                          <>
                            <label className="ms-1">
                              <Trans
                                defaults="Your Friend's Name"
                                i18nKey="yourFriend'sName"
                              />
                            </label>
                            {column.required === true && (
                              <span className="text-danger">{"*"}</span>
                            )}
                            <input
                              className="form-control mb-1"
                              type="text"
                              name="friendName"
                              defaultValue={""}
                              placeholder="Enter your Friend's name"
                              {...register("friendName", {
                                // required: true,
                                required: column?.required,
                                validate: {
                                  emptyValue: (v) =>
                                    v && v !== ""
                                      ? v.replace(/\s/g, "") !== ""
                                      : true,
                                },
                                maxLength: 160,
                                minLength: 1,
                              })}
                            />
                            {errors.friendName && (
                              <span className="text-danger">
                                <Trans
                                  defaults="Please Enter your Friend's name"
                                  i18nKey="pleaseEnterYourFriend'sName"
                                />
                              </span>
                            )}
                          </>
                        ) : null}

                        {column.fieldCode === "email" &&
                        column.fieldType === "Standard" ? (
                          <>
                            <label className="ms-1">
                              <Trans
                                defaults="Your Friend's Email"
                                i18nKey="yourFriend'sEmail"
                              />
                            </label>
                            {column.required === true && (
                              <span className="text-danger">{"*"}</span>
                            )}
                            <input
                              className="form-control pl-2 pr-2"
                              size="xs"
                              type="friendEmail"
                              defaultValue={""}
                              placeholder="Enter your Friend's email"
                              {...register("friendEmail", {
                                // required: true,
                                required: column?.required,
                                pattern: {
                                  value:
                                    /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                  message: "Please enter a valid email",
                                },
                              })}
                            />
                            {errors?.friendEmail &&
                              (errors?.friendEmail?.message != "" ? (
                                <span className="text-danger">
                                  {errors?.friendEmail?.message}
                                </span>
                              ) : (
                                <span className="text-danger">
                                  <Trans
                                    defaults="Please enter your Friend's email"
                                    i18nKey="pleaseEnterYourFriend'sEmail"
                                  />
                                </span>
                              ))}
                          </>
                        ) : null}

                        {column.fieldCode === "phone" &&
                        column.fieldType === "Standard" ? (
                          <>
                            <label className="ms-1">
                              <Trans
                                defaults="Your Friend's Mobile Number"
                                i18nKey="yourFriend'sMobileNumber"
                              />
                            </label>
                            {column.required === true && (
                              <span className="text-danger">{"*"}</span>
                            )}
                            <Controller
                              control={control}
                              name="mobileNumber"
                              refs={register(`mobileNumber`, {
                                required: column?.required,
                                validate: (value) => {
                                  if (value) {
                                    if (!isValidPhoneNumber(String(value))) {
                                      return "Enter your Friend's valid mobile number";
                                    }
                                  }
                                },
                              })}
                              defaultValue={""}
                              render={({
                                field: { onChange, onBlur, value },
                              }) => (
                                <Phone
                                  className="form-control border-0 p-0"
                                  defaultCountry="US"
                                  placeholder="Enter your Friend's mobile number"
                                  onChange={onChange}
                                  value={value}
                                />
                              )}
                            />
                            {errors?.mobileNumber &&
                              (errors?.mobileNumber?.message != "" ? (
                                <span className="text-danger">
                                  {errors?.mobileNumber?.message}
                                </span>
                              ) : (
                                <span className="text-danger">
                                  <Trans
                                    defaults="Please enter your Friend's mobile number"
                                    i18nKey="pleaseEnterYourFriend'sMobileNumber"
                                  />
                                </span>
                              ))}
                          </>
                        ) : null}

                        {column.fieldCode === "Location" &&
                        column.fieldType === "Standard" ? (
                          <>
                            <label className="ms-1">
                              <Trans
                                i18nKey="yourFriend'sLocation"
                                defaults="Your Friend's Location"
                              />
                            </label>
                            {column.required === true && (
                              <span className="text-danger">{"*"}</span>
                            )}
                            <Controller
                              control={control}
                              name={`location`}
                              refs={register(`location`, {
                                // required: false,
                                required: column?.required,
                                validate: {
                                  emptyValue: (v) =>
                                    v && v != ""
                                      ? v.replace(/\s/g, "") != ""
                                      : true,
                                },
                              })}
                              render={({
                                field: { onChange, onBlur, value },
                              }) => (
                                <div>
                                  <StandaloneSearchBox
                                    onLoad={(ref) => {
                                      inputRef.current = ref;
                                    }}
                                    onPlacesChanged={handleLocationChange}
                                  >
                                    <Input
                                      className="form-control"
                                      placeholder="Enter your Friend's Location"
                                      value={value || ""}
                                      onChange={onChange}
                                    />
                                  </StandaloneSearchBox>
                                </div>
                              )}
                            />
                            {errors.location && (
                              <span className="text-danger">
                                <Trans
                                  i18nKey="pleaseEnterYourFriend'sLocation"
                                  defaults="Please enter your Friend's Location"
                                />
                              </span>
                            )}
                          </>
                        ) : null}
                      </div>
                    ))}
                  </React.Fragment>
                );
              }
            )}
          </Modal.Body>

          <div className="justify-content-between d-flex">
            <Button
              onClick={() => {
                setOpenModel2(false);
              }}
            >
              <Trans defaults="Cancel" i18nKey="cancel" />
            </Button>
            <Button
              onClick={handleSubmit(onSubmit)}
              appearance="primary"
              className="bg-primary"
              disabled={referFriendLoading}
            >
              {referFriendLoading && <Loader className="me-2" />}
              <Trans defaults="Submit" i18nKey="submit" />
            </Button>
          </div>
          {/* </div> */}
        </Modal>
      )}
    </>
  );
}

export default CareersReferFriend ;
