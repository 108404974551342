import { useForm, Controller, useFieldArray } from "react-hook-form";
import React, { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle, faMinusCircle } from "@fortawesome/free-solid-svg-icons";
import { Form } from "react-bootstrap";
import { t } from "i18next";
import { Trans } from "react-i18next";
import { v4 as uuidv4 } from "uuid"

const ReferenceEmailForm = ({
  control,
  register,
  emailIndex,
  emails,
  setError,
  errors,
  clearErrors,
  fieldObj,
  getValues
}) => {
  const { fields, append, remove, replace } = useFieldArray({
    control,
    name: `reference[${emailIndex}].email`,
  });
  // console.log(getValues(), "getValuesgetValuesgetValues");
  const validateEmail = (email) => {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  };
  const handleEmailChange = () => {
    if (getValues()?.reference[emailIndex]?.email?.length > 0) {
      for (let j = 0; j < getValues()?.reference[emailIndex]?.email?.length; j++) {
        for (let k = j + 1; k < getValues()?.reference[emailIndex]?.email?.length; k++) {
          if (getValues()?.reference[emailIndex]?.email[j].text != getValues()?.reference[emailIndex]?.email[k]?.text) {
            clearErrors(`reference[${emailIndex}].email[${k}].text`)
          }
        }
      }
    }
  }
  const handleEmailAdd = () => {
    // console.log(getValues());
    let flag = true
    if (getValues()?.reference[emailIndex]?.email?.length > 0) {
      for (let i in getValues()?.reference[emailIndex]?.email) {
        if (getValues()?.reference[emailIndex]?.email[i]?.text?.trim() != "") {
          if (!validateEmail(getValues()?.reference[emailIndex]?.email[i].text)) {
            flag = false
            setError(
              `reference[${emailIndex}].email[${i}].text`,
              {
                type: "manual",
                message: t("pleaseEnterValidEmail",{defaultValue:"Please enter valid email"}),
              },
              { shouldFocus: true }
            );
          }
        } else {
          flag = false
          setError(
            `reference[${emailIndex}].email[${i}].text`,
            {
              type: "manual",
              message: t("pleaseEnterEmail",{defaultValue:"Please enter email"}),
            },
            { shouldFocus: true }
          );
        }
      }
      for (let j = 0; j < getValues()?.reference[emailIndex]?.email?.length; j++) {
        for (let k = j + 1; k < getValues()?.reference[emailIndex]?.email?.length; k++) {
          if (getValues()?.reference[emailIndex]?.email[j].text == getValues()?.reference[emailIndex]?.email[k].text) {
            flag = false
            setError(
              `reference[${emailIndex}].email[${k}].text`,
              {
                type: "manual",
                message: t("emailAlreadyExists",{defaultValue:"Email already exists"}),
              },
              { shouldFocus: true }
            );
          }
        }
      }
    }
    // console.log(flag, "djgndkfnmdanmfn");
    if (flag) {
      // clearErrors()
      append({
        text: "",
      });
    }
  }
  const handleEmailRemove = (index) => {
    // clearErrors()
    remove(index);
    if (getValues()?.reference[emailIndex]?.email?.length > 0) {
      if(getValues()?.reference[emailIndex]?.email?.length == 1) {
        clearErrors(`reference[${emailIndex}].email[${0}].text`)
      } else {
        for (let j = 0; j < getValues()?.reference[emailIndex]?.email?.length; j++) {
          for (let k = j + 1; k < getValues()?.reference[emailIndex]?.email?.length; k++) {
            if (getValues()?.reference[emailIndex]?.email[j].text != getValues()?.reference[emailIndex]?.email[k]?.text) {
              clearErrors(`reference[${emailIndex}].email[${k}].text`)
            }
          }
        }
      }
    }
  }
  useEffect(() => {
    if (getValues()) {
      if (getValues()?.reference[emailIndex]?.email?.length == 0) {
        append({
          text: "",
        });
      }
    }
  }, [])

  // console.log(errors,"errors123123");

  return (
    <>
      <div className="form-group">
        <label>
        {t("email", { defaultValue:"Email" })}
        </label>
        {fieldObj?.required && <span className="text-danger">{"*"}</span>}
        {fields.map((item, index) => {

          return (
            <>
              <div className="row my-2"
              // key={uuidv4()}
              >
                <div className="col-md-9">
                  <Controller
                    key={item?.id}
                    control={control}
                    name={`reference[${emailIndex}].email[${index}].text`}
                    refs={register(
                      `reference[${emailIndex}].email[${index}].text`,
                      {
                        required: fieldObj?.required ? true : false,
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          message:  t("invalidEmailAddress",{defaultValue:"Invalid email address"}),
                        },
                        // validate: {
                        //   emptyValue: (v) => v.replace(/\s/g, "") != "",
                        // },
                      }
                    )}
                    defaultValue={item?.text}
                    render={({
                      field: { onChange, onBlur, value, refs },
                    }) => (
                      <Form.Control
                        size="sm"
                        className="w-100"
                        placeholder={t("enter", { defaultValue: "Enter" }) + " " + t("email", { defaultValue:"Email" })}
                        value={value}
                        disabled={fieldObj?.editable ? false : true}
                        onChange={(val) => {
                          onChange(val)
                          handleEmailChange()
                        }}
                      />
                    )}
                  />
                </div>
                {
                  fieldObj?.editable && <div className="col-md-1 align-items-end d-flex">
                  <span
                    className="px-2 IconArea actionIcon actionIcon-sm"
                    onClick={() => {
                      handleEmailRemove(index)
                    }}
                  >
                    <FontAwesomeIcon icon={faMinusCircle}></FontAwesomeIcon>
                  </span>
                </div>
                }
              </div>
              <div>
                {errors?.["reference"]?.[emailIndex]?.["email"]?.[index]?.[
                  "text"
                ].type === "required" ? (
                  <>
                    <span className="text-danger">
                      <Trans i18nKey={"emailIsRequired"} defaults="Email Is Required" />
                    </span>
                  </>
                ) : (
                  <>
                    <span className="text-danger">
                      {
                        errors?.["reference"]?.[emailIndex]?.["email"]?.[index]?.[
                          "text"
                        ]?.message ? errors?.["reference"]?.[emailIndex]?.["email"]?.[index]?.[
                          "text"
                        ]?.message : ""
                      }
                    </span>
                  </>
                )
                }
              </div >
            </>
          );
        })}
        {
          fieldObj?.editable && <div className="row my-3">
          <div className="col-sm-12 col-md-12">
            <div className="d-flex justify-content-center">
              <span
                className="px-2 IconArea actionIcon actionIcon-sm"
                onClick={handleEmailAdd}
              >
                <FontAwesomeIcon icon={faPlusCircle}></FontAwesomeIcon>
              </span>
            </div>
            <div className="d-flex justify-content-center">
              <span> 
                <Trans i18nKey="addEmail" defaults="Add Email" /> 
                </span>
            </div>
          </div>
        </div>
        }
      </div >
    </>
  );
};

export default ReferenceEmailForm;
