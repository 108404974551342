import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useFieldArray, Controller, useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle, faMinusCircle } from "@fortawesome/free-solid-svg-icons";
import { Trans } from "react-i18next";
import { Form } from "react-bootstrap";
import { IconButton, Stack, Whisper, Popover, Tooltip, Loader } from "rsuite";
import { useTranslation } from "react-i18next";
import Eeachskill from "../../EachSkillTab";
import { AutoComplete } from "rsuite";
import usePost from "../../../customhooks/UsePost";
// import SpinnerIcon from "@rsuite/icons/legacy/Spinner";
import { useLoaderData } from "react-router-dom";

const SkillsFormField = ({
  skillsData,
  control,
  register,
  errors,
  handleSubmit,
  getValues,
  fieldList,
  type,
  setValue,
  open,
  from,
}) => {
  const { t } = useTranslation();
  let loaderDetails = useLoaderData();
  const {
    fields: skillsFields,
    append: skillsAppend,
    prepend,
    remove: skillsRemoved,
    swap,
    move,
    insert,
    replace: skillsReplace,
  } = useFieldArray({
    control,
    name: "skills",
  });
  const [companyID, setCompanyID] = useState("");
  const [suggestedSkills, setSuggestedSkills] = useState([]);
  const {
    post: getSkillAutoSuggestions,
    data: getSkillAutoSuggestionsData,
    isLoading: getSkillAutoSuggestionsLoading,
    error: getSkillAutoSuggestionsError,
  } = usePost("/user/getSkillAutoSuggestions");

  const isNonNegative = (value) => {
    return (
      value >= 0 ||
      t("valueCannotBeNegative", { defaultValue: "Value cannot be negative" })
    );
  };
  useEffect(() => {
    if (
      loaderDetails?.companyID != "" &&
      loaderDetails?.companyID != undefined
    ) {
      setCompanyID(loaderDetails?.companyID);
    } else {
      setCompanyID(loaderDetails?.jobDetails?.companyID);
    }
  }, [loaderDetails]);

  const handleSkillSuggestions = (skill) => {
    getSkillAutoSuggestions({ text: skill ?? "", companyID: companyID }).then(
      (res) => {
        const UpdateDate = res?.result?.results?.map((x) => {
          return { label: x.label, value: x.label };
        });

        setSuggestedSkills(UpdateDate);
      }
    );
  };
  const [style, setStyle] = useState({});
  useEffect(() => {
    if (from == "Careers") {
      setStyle({
        zIndex: 5,
      });
    }
    if (from == "BasicDetails") {
      setStyle({
        zIndex: 1050,
      });
    }
  }, [from]);

  // const [esDataArr, { data: esData }] = useGetSuggestionsMutation();
  useEffect(() => {
    if (skillsData && skillsData?.length > 0) {
      skillsReplace();
      if (skillsData?.length > 0) {
        skillsAppend(
          skillsData?.map((x) => {
            return {
              skills: x?.text ? x?.text : "",
              yearsOfExperience: x.yearsOfExperience,
            };
          })
        );
      } else if (getValues()?.experience?.length > 0) {
        skillsReplace();
        getValues()?.experience?.map((item) => {
          if (item?.skills?.length > 0) {
            item?.skills?.map((x) => {
              if (skillsFields?.length > 0) {
                let flag = true;
                skillsFields?.map((skill) => {
                  if(skill?.skills === x?.text){
                    flag = false;
                  }
                })
                if(flag){
                  skillsAppend([
                    {
                      skills: x?.text || "",
                      yearsOfExperience: x?.yearsOfExperience || 0,
                    },
                  ]);
                }
              }else{
                skillsAppend([
                  {
                    skills: x?.text || "",
                    yearsOfExperience: x?.yearsOfExperience || 0,
                  },
                ]);
              }
              
            });
          }
        });
      } else {
        skillsReplace();
        skillsAppend([
          {
            skills: "",
            yearsOfExperience: "",
          },
        ]);
      }
    } else if (getValues()?.experience?.length > 0) {
      skillsReplace();
      getValues()?.experience?.map((item) => {
        if (item?.skills?.length > 0) {
          item?.skills?.map((x) => {
            if (skillsFields?.length > 0) {
              let flag = true;
              skillsFields?.map((skill) => {
                if(skill?.skills === x?.text){
                  flag = false;
                }
              })
              if(flag){
                skillsAppend([
                  {
                    skills: x?.text || "",
                    yearsOfExperience: x?.yearsOfExperience || 0,
                  },
                ]);
              }
            }else{
              skillsAppend([
                {
                  skills: x?.text || "",
                  yearsOfExperience: x?.yearsOfExperience || 0,
                },
              ]);
            }
            
          });
        } else {
          setValue("skills", []);
        }
      });
    } else {
      setValue("skills", []);
    }
  }, [skillsData, getValues]);

  useEffect(()=>{
    if(skillsData?.length >0){
      if(skillsFields?.length >0){
        if (getValues()?.experience?.length > 0) {
          // skillsReplace();
          getValues()?.experience?.map((item) => {
            if (item?.skills?.length > 0) {
              item?.skills?.map((x) => {
                if (skillsFields?.length > 0) {
                  let flag = true;
                  skillsFields?.map((skill) => {
                    if(skill?.skills === x?.text){
                      flag = false;
                    }
                  })
                  if(flag){
                    skillsAppend([
                      {
                        skills: x?.text || "",
                        yearsOfExperience: x?.yearsOfExperience || 0,
                      },
                    ]);
                  }
                }else{
                  skillsAppend([
                    {
                      skills: x?.text || "",
                      yearsOfExperience: x?.yearsOfExperience || 0,
                    },
                  ]);
                }
                
              });
            }
          });
        }
      }
    }
  },[skillsFields,skillsData])

  // const handleAddSkill = () => {
  //   skillsAppend({
  //     skills: "",
  //     yearsOfExperience: "",
  //   });
  // };

  // console.log(skillsFields, "skillsFieldsskillsFields");
  // console.log(errors, "rrrrrr");
  const {
    formState: { errors: newexpererrors },

    getValues: newexpValues,
    setValue: newexpsetValues,
    register: newexpregister,
    control: newexpcontrol,
    handleSubmit: newexphandleSubmit,
    clearErrors: newexpclearErrors,
  } = useForm({
    defaultValues: {
      newexptext: "",
      newexpyear: 0,
    },
  });
  const triggerContactDetailsRef = React.useRef();

  const handleTagClick = (index) => {
    triggerContactDetailsRef.current.close();
    newexpsetValues("newexptext", "");
    newexpsetValues("newexpyear", 0);
    newexpclearErrors();
  };
  const handleAddSkill = () => {
    skillsAppend({
      skills: newexpValues()?.newexptext,
      yearsOfExperience: newexpValues()?.newexpyear,
    });
    triggerContactDetailsRef.current.close();
    newexpsetValues("newexptext", "");
    newexpsetValues("newexpyear", 0);
  };

  return (
    <>
      {from == "Careers" && (
        <label className="text-dark">
          {t("skills", { defaultValue: "Skills" })}
        </label>
      )}
      {fieldList?.required && <span className="text-danger">{"*"}</span>}
      <Stack wrap spacing={6}>
        {skillsFields.map((item, index) => {
          return (
            <div key={item?.id}>
              {item?.skills != "" && (
                <>
                  <Eeachskill
                    item={item}
                    index={index}
                    control={control}
                    register={register}
                    errors={errors}
                    handleSubmit={handleSubmit}
                    setdata={setValue}
                    dataValues={getValues}
                    remove={skillsRemoved}
                    type={type}
                  />
                </>
              )}
            </div>
          );
        })}
      </Stack>
      {(type == "customLayout" || fieldList?.editable) && (
        // <div className="row my-3">
        //   <div className="col-sm-12 col-md-12">
        //     <div className="d-flex justify-content-center">
        //       <span
        //         className="px-2 IconArea actionIcon actionIcon-sm"
        //         onClick={handleSubmit(handleAddSkill)}
        //       >
        //         <IconButton
        //           icon={<FontAwesomeIcon icon={faPlusCircle}></FontAwesomeIcon>}
        //           size="sm"
        //         />
        //       </span>
        //     </div>
        //     <div className="d-flex justify-content-center">
        //       <span>
        //         <Trans i18nKey="addNewSkill" defaults="Add New Skill" />
        //       </span>
        //     </div>
        //   </div>
        // </div>
        <div className="row my-3">
          <div className="col-sm-12 col-md-12">
            <div className="d-flex justify-content-center">
              <Whisper
                placement="top"
                trigger={"none"}
                className="w-100"
                ref={triggerContactDetailsRef}
                speaker={
                  <Popover style={style}>
                    <div className="row my-2">
                      <div className="col-8 mt-2">
                        <div className="form-group">
                          <Controller
                            control={newexpcontrol}
                            name={`newexptext`}
                            refs={newexpregister(`newexptext`, {
                              required: false,
                            })}
                            rules={{
                              validate: {
                                emptyValue: (v) => v.replace(/\s/g, "") != "",
                              },
                            }}
                            render={({
                              field: { onChange, onBlur, value, refs },
                            }) => (
                              <AutoComplete
                                onChange={(e) => {
                                  onChange(e);
                                  handleSkillSuggestions(e);
                                }}
                                placeholder={t("enterSkills", {
                                  defaultValue: "Enter Skills",
                                })}
                                value={value}
                                data={suggestedSkills}
                                size="sm"
                                menuClassName="w-100"
                                style={{ zIndex: 1050 }}
                                renderMenu={(menu) => {
                                  if (getSkillAutoSuggestionsLoading) {
                                    return (
                                      <p
                                        style={{
                                          padding: 4,
                                          color: "#999",
                                          textAlign: "center",
                                        }}
                                      >
                                        <Loader /> Loading...
                                      </p>
                                    );
                                  }
                                  return menu;
                                }}
                              />
                            )}
                          />
                        </div>

                        {newexpererrors?.newexptext && (
                          <span className="text-danger">
                            {newexpererrors?.newexptext?.message != ""
                              ? newexpererrors?.newexptext.message
                              : t("valueCannotBeEmpty", {
                                  defaultValue: "Value cannot be empty",
                                })}
                          </span>
                        )}
                      </div>
                      <div className="col-4 mt-2">
                        {/* <div className="row">
                          <div className="col-8"> */}
                        <div className="form-group">
                          <Controller
                            control={newexpcontrol}
                            name={`newexpyear`}
                            refs={newexpregister(`newexpyear`, {
                              required: false,
                            })}
                            rules={{
                              validate: {
                                isNonNegative: (value) => {
                                  if (value < 0) {
                                    return t("valueCannotBeNegative", {
                                      defaultValue: "Value cannot be negative",
                                    });
                                  }
                                },
                              },
                            }}
                            render={({
                              field: { onChange, onBlur, value },
                            }) => (
                              <input
                                type="number"
                                className="form-control form-control-sm"
                                placeholder={t("yearsOfExperience", {
                                  defaultValue: "Years of Experience",
                                })}
                                onChange={onChange}
                                value={value}
                              />
                            )}
                          />
                          {newexpererrors?.newexpyear && (
                            <span className="text-danger">
                              {
                                newexpererrors?.newexpyear?.message != "" &&
                                  newexpererrors?.newexpyear.message
                                // : t("valueCannotBeEmpty", {
                                //     defaultValue: "Value cannot be empty",
                                //   })
                              }
                            </span>
                          )}
                          {/* {errors?.["experience"]?.[expIndex]?.["skills"]?.[
                              index
                            ]?.["yearsOfExperience"] && (
                              <span className="text-danger">
                                {errors?.["experience"]?.[expIndex]?.[
                                  "skills"
                                ]?.[index]?.["yearsOfExperience"]?.message != ""
                                  ? errors?.["experience"]?.[expIndex]?.[
                                      "skills"
                                    ]?.[index]?.["yearsOfExperience"].message
                                  : ""}
                              </span>
                            )} */}
                        </div>
                        {/* </div>
                         
                        </div> */}
                      </div>
                    </div>
                    <div className="text-end">
                      <button
                        className="btn btn-primary btn-sm me-2"
                        onClick={newexphandleSubmit(handleAddSkill)}
                      >
                        Save
                      </button>
                      <button
                        className="btn btn-secondary btn-sm"
                        onClick={handleTagClick}
                      >
                        Cancel
                      </button>
                    </div>
                  </Popover>
                }
              >
                <span
                  className="px-2 IconArea actionIcon actionIcon-sm"
                  onClick={() => {
                    triggerContactDetailsRef.current.open();
                  }}
                >
                  <IconButton
                    icon={
                      <FontAwesomeIcon icon={faPlusCircle}></FontAwesomeIcon>
                    }
                    size="sm"
                  />
                </span>
              </Whisper>
            </div>
            <div className="d-flex justify-content-center">
              <span>
                <Trans i18nKey="addSkill" defaults="Add New Skill" />
              </span>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SkillsFormField;