import React, { useEffect, useRef, useState } from "react";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import {
  AutoComplete,
  Tag,
  Whisper,
  Popover,
  IconButton,
  Tooltip,
} from "rsuite";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlusCircle,
  faMinusCircle,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { useTranslation, Trans } from "react-i18next";
const Eeachskill = ({
  item,
  index,
  expIndex,
  setdata,
  seteditskills,
  remove,
  type,
  dataValues
}) => {
  const { t } = useTranslation();
  const triggerContactDetailsRef = React.useRef();
  const {
    formState: { errors, isDirty, isValid },

    getValues,
    setValue,
    register,
    control,
    handleSubmit,
  } = useForm({
    defaultValues: {
      EditSkills: item,
    },
  });
  const iteamdata = useRef(null);
  const iteamexp = useRef(null);
  const [open, setopen] = useState(false);
  useEffect(() => {
    iteamdata.current = item.text || item.skills;
    iteamexp.current =
      item?.yearsOfExperience != "" ? item?.yearsOfExperience : 0;
    setValue("EditSkills.text",item.text || item.skills);
    setValue("EditSkills.yearsOfExperience", item?.yearsOfExperience != "" ? item?.yearsOfExperience : 0);
  }, [item]);
  const HandleTagSave = () => {
    if (type === "CarresSuggestedskills") {
      setdata(
        `experience[${expIndex}].skills[${index}].text`,
        getValues().EditSkills.text
      );
      console.log(getValues().EditSkills.yearsOfExperience);
      setdata(
        `experience[${expIndex}].skills[${index}].yearsOfExperience`,
        getValues().EditSkills.yearsOfExperience != ""
          ? getValues().EditSkills.yearsOfExperience
          : 0
      );
      // dataValues()?.skills.map((skill)=>{
        let skillArr = dataValues()?.skills || []
        if(skillArr?.length > 0){
          skillArr?.map((skill)=>{
            if(skill?.skills === getValues().EditSkills?.text){
              skill.yearsOfExperience = getValues().EditSkills?.yearsOfExperience || 0
            }
          })
          setdata("skills",skillArr)
        }
      // })
    } else {
      setdata(
        `skills[${index}].skills`,
        getValues().EditSkills.text
      );
      console.log(getValues().EditSkills.yearsOfExperience);
      setdata(
        `skills[${index}].yearsOfExperience`,
        getValues().EditSkills.yearsOfExperience != ""
          ? getValues().EditSkills.yearsOfExperience
          : 0
      );
    }
    triggerContactDetailsRef.current.close();
    setopen(false);

    iteamdata.current = getValues().EditSkills.text;
    iteamexp.current = getValues().EditSkills.yearsOfExperience;
  };
  const Handaleopen = () => {
    setopen(true);
    triggerContactDetailsRef.current.open();
  };
  const [indexdata, setindexdata] = useState(null);
  useEffect(() => {
    setindexdata(index);
  }, [open]);
  const [skillremove, setskillremove] = useState(false);
  return (
    <div>
      {item?.text != "" && (
        <Whisper
          placement={open ? "auto" : !skillremove ? "top" : "auto"}
          trigger={open ? "none" : "hover"}
          className="w-100"
          ref={triggerContactDetailsRef}
          speaker={
            open ? (
              <Popover>
                <div className="row my-2" key={item?.id}>
                  <div className="col-7 mt-2">
                    <div className="form-group">
                      <label className="text-muted">Skill</label>
                      <Controller
                        control={control}
                        name={`EditSkills.text`}
                        refs={register(`EditSkills.text`, {
                          required: false,
                        })}
                        rules={{
                          validate: {
                            emptyValue: (v) => v.replace(/\s/g, "") != "",
                          },
                        }}
                        render={({
                          field: { onChange, onBlur, value, refs },
                        }) => (
                          <AutoComplete
                            onChange={(e) => {
                              onChange(e);
                            }}
                            placeholder={t("enterSkills", {
                              defaultValue: "Enter Skills",
                            })}
                            defaultValue={iteamdata?.current}
                            data={[]}
                            size="sm"
                            className="w-100"
                          />
                        )}
                      />
                    </div>
                    {errors?.EditSkills?.text && (
                      <span className="text-danger">
                        {errors?.EditSkills?.text?.message != ""
                          ? errors?.EditSkills?.text.message
                          : t("valueCannotBeEmpty", {
                              defaultValue: "Value cannot be empty",
                            })}
                      </span>
                    )}
                  </div>
                  <div className="col-5 mt-2">
                    <div className="row">
                      <div className="col-7">
                        <div className="form-group">
                        <label className="text-muted">Years of experience</label>
                          <Controller
                            control={control}
                            name={`EditSkills.yearsOfExperience`}
                            refs={register(`EditSkills.yearsOfExperience`, {
                              required: false,
                            })}
                            rules={{
                              validate: {
                                isNonNegative: (value) => {
                                  if (value < 0) {
                                    return t("valueCannotBeNegative", {
                                      defaultValue: "Value cannot be negative",
                                    });
                                  }
                                },
                              },
                            }}
                            render={({
                              field: { onChange, onBlur, value },
                            }) => (
                              <input
                                type="number"
                                className="form-control form-control-sm"
                                placeholder={t("yearsOfExperience", {
                                  defaultValue: "Years of Experience",
                                })}
                                onChange={onChange}
                                defaultValue={
                                  iteamexp?.current != ""
                                    ? iteamexp?.current
                                    : 0
                                }
                              />
                            )}
                          />
                        </div>
                      </div>
                      <div className="col-3 d-flex align-items-center pt-3">
                        <Whisper
                          placement="top"
                          controlId="control-id-hover"
                          trigger="hover"
                          speaker={
                            <Tooltip>Click here to remove the skill</Tooltip>
                          }
                        >
                          <span
                            className="px-2 IconArea actionIcon actionIcon-sm "
                            onClick={() => {
                              triggerContactDetailsRef.current.close();
                              remove(indexdata);

                              // setValue("EditSkills", item);
                            }}
                          >
                            <IconButton
                              icon={
                                <FontAwesomeIcon
                                  icon={faMinusCircle}
                                ></FontAwesomeIcon>
                              }
                              size="sm"
                            />
                          </span>
                        </Whisper>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="text-end">
                  
                  <button
                    className="btn btn-primary btn-sm me-2"
                    onClick={handleSubmit(HandleTagSave)}
                  >
                    Save
                  </button>
                  <button
                    className="btn btn-secondary btn-sm"
                    onClick={() => {
                      setopen(false);
                      triggerContactDetailsRef.current.close();
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </Popover>
            ) : !skillremove ? (
              <Tooltip className="text-center">Click here to edit the skill and Years of experience</Tooltip>
            ) : (
              <Tooltip className="text-center">Click here to Delete the skill </Tooltip>
            )
          }
        >
          <Tag
            size="md"
            // title="Click here to edit the tag"
            onClick={Handaleopen}
            className="cursorpointer bg-primary text-white"
          >
            {iteamdata?.current}
            <span className="mx-1">-</span>
            {iteamexp.current != "" ? iteamexp.current : 0}

            <span
              // title="Click here to delete the skill"
              className="mx-1 ps-2"
              onClick={() => {
                remove(indexdata);
              }}
              onMouseOver={() => {
                setskillremove(true);
              }}
              onMouseOut={() => {
                setskillremove(false);
              }}
            >
              <FontAwesomeIcon icon={faXmark} />
            </span>
          </Tag>
        </Whisper>
      )}
    </div>
  );
};
export default Eeachskill;
