import React, { useState, useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Modal, Notification, toaster } from "rsuite";
import { Button, Form, Spinner } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import PhoneInput, { isValidPhoneNumber, parsePhoneNumber } from "react-phone-number-input";
import 'react-phone-number-input/style.css';
import "./Telephone.css";
import usePost from "../../customhooks/UsePost";
import axios from "axios";
import useIsSsr from "../../customhooks/UseIsSSR";
import { useSearchParams } from "react-router-dom";


export default function CreateProfile({ campaignID, roundNumber, data }) {
    const [open, setOpen] = useState(true);
    const {
        register,
        handleSubmit,
        control,
        formState: { errors },
        clearErrors,
        watch
    } = useForm({
        defaultValues: {
            email: "",
            phone: ""
        }
    })
    const isSsr = useIsSsr();
    const [searchParams] = useSearchParams();
    const [logo, setLogo] = useState("");
    const { t } = useTranslation();
    const [payload, setPayload] = useState(null);
    const [countryCode, setCountryCode] = useState("US")
    useEffect(() => {
        if (campaignID) {
            setPayload({
                campaignID: campaignID
            })
        }
    }, [campaignID])

    const getLogo = async () => {
        try {
            let data = await axios.get(
                window.location.origin +
                "/jsv4/webapi/getCompanyLogo",
                {
                    params: {
                        campaignID: campaignID !== null ? campaignID : "",
                    }
                });

            if (data?.data?.status) {
                setLogo(data?.data?.clientLogo)
                if (data?.data?.countryCode) {
                    setCountryCode(data?.data?.countryCode?.toUpperCase())
                }
            } else {
                console.log("getCompanyLogo: ", data?.data);
            }
        } catch (error) {
            console.log("Error in getCompanyLogo", error);
        }
    }

    useEffect(() => {
        if (payload !== null) {
            getLogo();
        }
    }, [payload]);

    // usePost
    const {
        post: createProfile,
        // data,
        isLoading: createProfileLoading,
        // error,
    } = usePost("/user/CreateProfileForAutoschedule");
    const [result, setResult] = useState(null);

    const onSubmit = (data) => {
        console.log("34567897654345678", data);

        let phoneNumber = {
            // country: "",
            // internationalPhoneCode: "",
            // phoneNumberOnly: "",
            // text: ""
        }
        if (data?.phone && data?.phone != "") {
            let parsedPhone = parsePhoneNumber(data?.phone);
            phoneNumber.country = parsedPhone?.country
            phoneNumber.internationalPhoneCode = parsedPhone?.countryCallingCode
            phoneNumber.phoneNumberOnly = parsedPhone?.nationalNumber
            phoneNumber.text = parsedPhone?.number
        }

        let postObj = {
            firstName: data?.firstName,
            lastName: data?.lastName,
            email: data?.email,
            phone: phoneNumber,
            campaignID: campaignID,
            roundNumber: roundNumber,
            isEmailPresent: data?.email != "" ? true : false,
            isMobilePresent: data?.phone != "" ? true : false,
            source: searchParams.get('source') != null ? searchParams.get('source') : ""
        }

        createProfile(postObj)
            .then(async (res) => {
                console.log("resssssssssssss", res);

                if (res?.status && res?.result?.status) {
                    if (res?.result?.disqualifiedExists) {
                        toaster.push(
                            <Notification type={"warning"} header={<Trans i18nKey="warning" defaults="Warning" />} closable>
                                {t("youCanNotCreateAProfileWithTheseContactDetailsTheseContactDetailsAreAssociatedWithDisqualifiedProfile",
                                    { defaultValue: "You can not create a profile with these contact details. These contact details are associated with disqualified profile" })
                                }
                            </Notification>,
                            { placement: "topEnd" }
                        );
                    } else if (res?.result?.isBookedSlot) {
                        toaster.push(
                            <Notification type={"warning"} header={<Trans i18nKey="warning" defaults="Warning" />} closable>
                                {t("youHaveAlreadyBookedSlotForThisRound",
                                    { defaultValue: "You have already booked slot for this round" })
                                }
                            </Notification>,
                            { placement: "topEnd" }
                        );
                    } else {
                        setResult({
                            interviewID: res?.result?.interviewDetails?._id,
                            companyID: res?.result?.interviewDetails?.companyID,
                            resumeID: res?.result?.interviewDetails?.resumeID
                        });
                        setOpen(false);
                    }
                } else {
                    toaster.push(
                        <Notification type={"error"} header={t("error", { defaultValue: "error" })} closable>
                            {t("weAreUnableToCreateAResumeAtThisMoment", { defaultValue: "We are unable to create a resume at this moment" })}
                        </Notification>,
                        { placement: "topEnd" }
                    );
                }
            })
            .catch((error) => {
                toaster.push(
                    <Notification type={"error"} header={t("error", { defaultValue: "error" })} closable>
                        {t("weAreUnableToCreateAResumeAtThisMoment", { defaultValue: "We are unable to create a resume at this moment" })}
                    </Notification>,
                    { placement: "topEnd" }
                );
                console.log("Error", error)
            })
    }

    useEffect(() => {
        data(result);
    }, [result]);
    const [isSmallScreen, setIsSmallScreen] = useState(
        typeof window !== 'undefined' ? window.innerWidth < 768 : false
    );

    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(typeof window !== 'undefined' ? window.innerWidth < 768 : false);
        };

        if (typeof window !== 'undefined') {
            window.addEventListener('resize', handleResize);

            return () => {
                window.removeEventListener('resize', handleResize);
            };
        }
    }, []);
    return (
        <Modal
            open={open}
            // onClose={() => setOpen(false)}
            overflow={false}
            size="md"
        >
            {/* <Modal.Header closeButton={false}> */}
            <div>
                {/* <Modal.Title className="justify-content-between"> */}
                <div className="d-flex justify-content-between align-items-center">
                    <div >
                        <h5>
                            <Trans i18nKey="createProfile" defaults="Create Profile" />
                        </h5>
                    </div>
                    <div >{/* className="ml-auto" */}
                        {
                            logo?.length > 0 && (
                                <img
                                    src={logo}
                                    // src="https://recruiterqa.maprecruit.com/images/coarthatechnosolutionsIN/logos/552d016291bad07ccbb455fe_logo_MR_light.png"
                                    className="iconSize "
                                />
                            )
                        }
                    </div>
                </div>
            </div>
            {/* </Modal.Title> */}
            {/* </Modal.Header> */}
            <Modal.Body>
                <Form>
                    <div className="row pb-3">
                        <div className="col-sm-12 col-md-6">
                            <Form.Label>
                                <Trans i18nKey="firstName" defaults="First Name" />
                                <span className="text-danger">{"*"}</span>
                            </Form.Label>
                            <Form.Control
                                type="text"
                                {...register("firstName", {
                                    required: true,
                                    validate: (value) => value.replace(/\s/g, '') != ""
                                })}
                                placeholder="Enter First Name"
                            />
                            {errors?.firstName && (
                                <span className="text-danger"><Trans i18nKey="pleaseEnterFirstName" defaults="Please enter first name" /></span>
                            )}
                        </div>
                        <div className="col-sm-12 col-md-6">
                            <Form.Label>
                                <Trans i18nKey="lastName" defaults="Last Name" />
                                <span className="text-danger">{"*"}</span>
                            </Form.Label>
                            <Form.Control
                                type="text"
                                {...register("lastName", {
                                    required: true,
                                    validate: (value) => value.replace(/\s/g, '') != ""
                                })}
                                placeholder="Enter Last Name"
                            />
                            {errors?.lastName && (
                                <span className="text-danger"><Trans i18nKey="pleaseEnterLastName" defaults="Please enter last name" /></span>
                            )}
                        </div>
                    </div>
                    <div className="row pb-3">
                        <div className="col-sm-12 col-md-6">
                            <Form.Label>
                                <Trans i18nKey="email" defaults="Email" />
                                <span className="text-danger">{"*"}</span>
                            </Form.Label>
                            <Controller
                                control={control}
                                name="email"
                                refs={register("email")}
                                rules={{
                                    required: true,
                                    validate: {
                                        // emptyValue: (value) => watch()?.phone == "" ? value.replace(/\s/g, '') != "" : null,
                                        validMail: (value) => {
                                            if (value) {
                                                return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value?.trim())
                                            }
                                        }
                                    }
                                }}
                                render={({ field: { onChange, value } }) => (
                                    <input
                                        type="text"
                                        onChange={(value) => {
                                            onChange(value);
                                            clearErrors("phone")
                                        }}
                                        value={value}
                                        placeholder="Enter Email"
                                        className="form-control"
                                    />
                                )}
                            />
                            {errors?.email && (errors?.email?.type == "required" || errors?.email?.type == "emptyValue") && (
                                <span className="text-danger"><Trans i18nKey="pleaseEnterEmail" defaults="Please enter email" /></span>
                            )}
                            {errors?.email && errors?.email?.type == "validMail" && (
                                <span className="text-danger"><Trans i18nKey="pleaseEnterValidEmail" defaults="Please enter valid email" /></span>
                            )}
                            {errors?.email && errors?.email?.type == "manual" && (
                                <span className="text-danger">{errors?.email?.message}</span>
                            )}
                        </div>
                        <div className="col-sm-12 col-md-6">
                            <Form.Label>
                                <Trans i18nKey="phoneNumber" defaults="Phone Number" />
                                <span className="text-danger">{"*"}</span>
                            </Form.Label>
                            <Controller
                                control={control}
                                name="phone"
                                refs={register("phone")}
                                rules={{
                                    // required: watch()?.email == "" ? true : false,
                                    required: true,
                                    validate: (value) => {
                                        if (value) {
                                            // console.log(isValidPhoneNumber(String(value)), 'gfjkdnkdgnkdsn');
                                            return isValidPhoneNumber(String(value));
                                        }
                                    },
                                }}
                                render={({ field: { onChange, value } }) => (
                                    <PhoneInput
                                        placeholder="Enter Phone Number"
                                        onChange={(value) => {
                                            onChange(value);
                                            clearErrors("email")
                                        }}
                                        value={value}
                                        limitMaxLength
                                        defaultCountry={countryCode}
                                        international
                                    />
                                )}
                            />
                            {errors?.phone && errors?.phone?.type == "required" && (
                                <span className="text-danger"><Trans i18nKey="pleaseEnterPhoneNumber" defaults="Please enter phone number" /></span>
                            )}
                            {errors?.phone && errors?.phone?.type == "validate" && (
                                <span className="text-danger"><Trans i18nKey="pleaseEnterValidPhoneNumber" defaults="Please enter valid phone number" /></span>
                            )}
                            {errors?.phone && errors?.phone?.type == "manual" && (
                                <span className="text-danger">{errors?.phone?.message}</span>
                            )}
                        </div>
                    </div>
                </Form>
            </Modal.Body>
            <Modal.Footer className={` d-flex  ${isSmallScreen ? 'justify-content-center' : 'justify-content-end'}`}>
                <Button
                    variant="primary"
                    onClick={handleSubmit(onSubmit)}
                    disabled={createProfileLoading ? true : false}
                >
                    {createProfileLoading && (
                        <Spinner
                            animation="border"
                            size="sm"
                            className="me-1"
                        />
                    )}
                    <Trans i18nKey="createProfile" defaults="Create Profile" />
                </Button>
            </Modal.Footer>
        </Modal>
    )
}