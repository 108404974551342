import React, { useEffect, useState } from "react";
import { useLoaderData } from "react-router-dom";
import CancelSlot from "./CancelSlot";
import RescheduleSlot from "./RescheduleSlot";
import DirectRescheduleSlot from "./DirectRescheduleSlot";
import { Trans } from "react-i18next";
import ClosedCampaign from "./ClosedCampaign";

export default function ConfirmSchedule() {
    let data = useLoaderData();
    console.log("sdfghjkljhgfdsfghj", data)

    const [page, setPage] = useState(null);
    const [isExpired, setIsExpired] = useState(false);
    const [active, setActive] = useState(true)

    useEffect(() => {
        if (data?.message?.includes("Campaign is not active")) {
            // throw new Error("Bad Request", { status: 400 });
            setActive(false)
        } else {
            if (data?.expired) {
                setIsExpired(true)
            } else {
                setIsExpired(false)
                setPage(data?.renderPage)
            }
        }
    }, [data])

    return (
        <>
            {
                active ? (
                    <>
                        {
                            isExpired ? (
                                <div className="text-center fs-4 pt-4">
                                    <Trans i18nKey="thisInvitationHasExpired" defaults="This Invitation has Expired" />
                                </div>
                            ) : (
                                <>
                                    {page == "cancel" && (
                                        <CancelSlot />
                                    )}
                                    {page == "Auto Schedule" && (
                                        <RescheduleSlot />
                                    )}
                                    {page == "Direct Schedule" && (
                                        <DirectRescheduleSlot />
                                    )}
                                </>
                            )
                        }
                    </>
                ) :
                    (<ClosedCampaign />)
            }
        </>
    )
}
