import React, { Suspense, useState, useEffect, useContext } from "react";
import Loading from "../../../../Loder";
import SummaryFormField from "../../../profile/form/SummaryFormField";
import CurrentCompanyFormField from "../../../profile/form/CurrentCompanyFormField";
import YearsOfExperienceFormField from "../fields/YearsOfExperienceFormField";
import SkillsFormField from "../../../profile/form/SkillsFormField";
import usePost from "../../../../customhooks/UsePost";
import UserContext from "../../../../app/context/UserContext";
import MinExpectedSalaryFormField from "../../../profile/form/MinExpectedSalaryFormField";
import MaxExpectedSalaryFormField from "../../../profile/form/MaxExpectedSalaryFormField";
import CompensationPeriodFormField from "../../../profile/form/CompensationPeriodFormField";
import CompensationCurrencyFormField from "../../../profile/form/CompensationCurrencyFormField";
import GenderFormField from "../../../profile/form/GenderFormField";
import NationalityFormField from "../../../profile/form/NationalityFormField";
import MaritalStatusFormField from "../../../profile/form/MaritalStatusFormField";
import AgeFormField from "../../../profile/form/AgeFormField";
import DateOfBirthFormField from "../../../profile/form/DateOfBirthFormField";
import VeteranStatusFormField from "../../../profile/form/VeteranStatusFormField";
import EthnicityForm from "./EthnicityForm";
import LanguagesKnownFormField from "../../../profile/form/LanguagesKnownFormField";
import WebsitesFormField from "../../../profile/form/WebsitesFormField";
import PreferredRolesForm from "./PreferredRolesForm";
import PreferredLocationsFormField from "../../../profile/form/PreferredLocationsFormField";
import WorkPermitFormField from "../../../profile/form/WorkPermitFormField";
import NoticePeriodFormField from "../../../profile/form/NoticePeriodFormField";
// const PersonalStatusForm = React.lazy(() => import("./PersonalStatusForm"))
const StandardForms = ({
  itemList,
  resumeId,
  control,
  getValues,
  setValue,
  errors,
  register,
  handleSubmit,
  clearErrors
}) => {
  const [objectiveData, setObjectiveData] = useState("");
  const [summaryData, setSummaryData] = useState("");
  const [yearsOfExperienceData, setYearsOfExperienceData] = useState("");
  const [currentCompanyData, setCurrentCompanyData] = useState("");
  const [currentlyWorkingData, setCurrentlyWorkingData] = useState(false);
  const [skillsData, setSkillsData] = useState([]);
  const [currentSalaryData, setCurrentSalaryData] = useState("");
  const [hideEditEmployeeStatus, setHideEditEmployeeStatus] = useState(true);
  const [editPersonnelStatus, setEditPersonnelStatus] = useState(false);
  const [hideAvailabilityEdit, setHideAvailabilityEdit] = useState(false);
  const [noticePeriodData, setNoticePeriodData] = useState("");
  const [workPermitData, setWorkPermitData] = useState("");
  const [preferredLocationsData, setPreferredLocationsData] = useState([]);
  const [websitesData, setWebsitesData] = useState([]);
  const [genderData, setGenderData] = useState("");
  const [languagesKnownData, setLanguagesKnownData] = useState([]);
  const [veteranStatusData, setVeteranStatusData] = useState("");
  const [dateOfBirthData, setDateOfBirthData] = useState("");
  const [ageData, setAgeData] = useState("");
  const [maritalStatusData, setMaritalStatusData] = useState("");
  const [nationalityData, setNationalityData] = useState("");
  const [personnelStatus, setPersonnelStatus] = useState("");

  const [availability, setAvailability] = useState("");
  const [minExpectedSalaryData, setMinExpectedSalaryData] = useState("");
  const [maxExpectedSalaryData, setMaxExpectedSalaryData] = useState("");
  const [compensationPeriodData, setCompensationPeriodData] = useState("");
  const [compensationCurrencyData, setCompensationCurrencyData] = useState("");
  const [preferredRoles, setPreferredRoles] = useState([]);
  const [ethnicityData, setEthnicityData] = useState([]);

  const { user, setUserDetails } = useContext(UserContext);
  const [resume, setResume] = useState({});
  const [resumeData, setresumeData] = useState({});

  const {
    post: resumeDetails,
    isLoading: resumeDetailsIsLoading,
    error,
  } = usePost("/getresumedetails");

  useEffect(() => {
    if (user?.collections?.resumes && resumeId !== "") {
      setresumeData({
        resumeCollectionName: user?.collections?.resumes,
        resumeID: resumeId,
      });
    }
  }, [user, resumeId]);
  useEffect(() => {
    if (
      resumeData?.resumeCollectionName &&
      resumeData?.resumeCollectionName != "" &&
      resumeData?.resumeID &&
      resumeData?.resumeID != ""
    ) {
      resumeDetails(resumeData)
        .then((res) => {
          if (res) {
            setResume(res);
          }
        })
        .catch((error) => {
          console.error("Error while fetching Resume:", error);
        });
    }
  }, [resumeData]);

  useEffect(() => {
    if (resume?.resume?.professionalSummary?.summary) {
      setSummaryData(resume?.resume?.professionalSummary?.summary);
    }
    if (resume?.resume?.professionalSummary?.yearsOfExperience?.finalYears) {
      setYearsOfExperienceData(
        resume?.resume?.professionalSummary?.yearsOfExperience?.finalYears
      );
    }
    if (resume?.resume?.professionalSummary?.currentCompany.company) {
      setCurrentCompanyData(
        resume?.resume?.professionalSummary?.currentCompany.company
      );
    }
    if (resume?.resume?.professionalQualification.skills) {
      setSkillsData(resume?.resume?.professionalQualification.skills);
    }
    if (resume?.resume?.professionalSummary?.expectedSalary?.minvalue) {
      setMinExpectedSalaryData(
        resume?.resume?.professionalSummary?.expectedSalary?.minvalue
      );
    }
    if (resume?.resume?.professionalSummary?.expectedSalary?.maxvalue) {
      setMaxExpectedSalaryData(
        resume?.resume?.professionalSummary?.expectedSalary?.maxvalue
      );
    }
    if (resume?.resume?.professionalSummary?.expectedSalary?.period) {
      setCompensationPeriodData(
        resume?.resume?.professionalSummary?.expectedSalary?.period
      );
    }
    if (resume?.resume?.professionalSummary?.expectedSalary?.currency) {
      setCompensationCurrencyData(
        resume?.resume?.professionalSummary?.expectedSalary?.currency
      );
    }
    if (resume?.resume?.professionalSummary?.noticePeriod?.text) {
      setNoticePeriodData(
        resume?.resume?.professionalSummary?.noticePeriod?.days
      );
    }
    if (resume?.resume?.professionalSummary?.workPermit?.text) {
      setWorkPermitData(
        resume?.resume?.professionalSummary?.workPermit?.text
      );
    }
    if (resume?.resume?.professionalSummary?.preferredLocations) {
      setPreferredLocationsData(
        resume?.resume?.professionalSummary?.preferredLocations
      );
    }
    if (resume?.resume?.profile?.websites) {
      setWebsitesData(resume?.resume?.profile?.websites);
    }
    if (resume?.resume?.profile?.gender?.text) {
      setGenderData(resume?.resume?.profile?.gender?.text);
    }
    if (resume?.resume?.profile?.languagesKnown) {
      setLanguagesKnownData(
        resume?.resume?.profile?.languagesKnown
      );
    }
    if (resume?.resume?.profile?.veteranStatus) {
      setVeteranStatusData(resume?.resume?.profile?.veteranStatus);
    }
    if (resume?.resume?.profile?.dob?.text) {
      setDateOfBirthData(resume?.resume?.profile?.dob?.text);
    }
    if (resume?.resume?.profile?.age?.text) {
      setAgeData(resume?.resume?.profile?.age?.text);
    }
    if (resume?.resume?.profile?.maritalStatus) {
      setMaritalStatusData(resume?.resume?.profile?.maritalStatus);
    }
    if (resume?.resume?.profile?.nationality?.text) {
      setNationalityData(
        resume?.resume?.profile?.nationality?.text
      );
    }
    if (resume?.resume?.professionalSummary?.preferredRoles) {
      setPreferredRoles(
        resume?.resume?.professionalSummary?.preferredRoles
      );
    }
    if (resume?.resume?.profile?.ethnicity) {
      setEthnicityData(resume?.resume?.profile?.ethnicity);
    }
  }, [resume]);
  return (
    <>
      {/* {itemList?.fieldCode == "Objective" ? (
        <Suspense fallback={<Loading />}>
          <ObjectiveForm
            control={control}
            resumeId={resumeId}
            getValues={getValues}
            setValue={setValue}
            errors={errors}
            register={register}
            fieldList={itemList}
          />
        </Suspense>
      ) : null} */}
      {itemList?.fieldCode == "Summary" ? (
        <Suspense fallback={<Loading />}>
          <SummaryFormField
            summaryData={summaryData}
            control={control}
            resumeId={resumeId}
            getValues={getValues}
            setValue={setValue}
            errors={errors}
            register={register}
            fieldList={itemList}
            resumeData ={resumeData}
          />
        </Suspense>
      ) : null}
      {itemList?.fieldCode == "YOE" ? (
        <Suspense fallback={<Loading />}>
          <YearsOfExperienceFormField
            yearsOfExperienceData={yearsOfExperienceData}
            control={control}
            resumeId={resumeId}
            getValues={getValues}
            setValue={setValue}
            errors={errors}
            register={register}
            fieldList={itemList}
          />
        </Suspense>
      ) : null}
      {itemList?.fieldCode == "Current Company" ? (
        <Suspense fallback={<Loading />}>
          <CurrentCompanyFormField
            currentCompanyData={currentCompanyData}
            control={control}
            resumeId={resumeId}
            getValues={getValues}
            setValue={setValue}
            errors={errors}
            register={register}
            fieldList={itemList}
            clearErrors={clearErrors}
            type = "customLayout"
          />
        </Suspense>
      ) : null}
      {/* {itemList?.fieldCode == "Currently Working" ? (
        <Suspense fallback={<Loading />}>
          <CurrentlyWorkingFormField
            control={control}
            resumeId={resumeId}
            getValues={getValues}
            setValue={setValue}
            errors={errors}
            register={register}
            fieldList={itemList}
          />
        </Suspense>
      ) : null} */}
      {itemList?.fieldCode == "Skills" ? (
        <Suspense fallback={<Loading />}>
          <SkillsFormField
            skillsData={skillsData}
            control={control}
            resumeId={resumeId}
            getValues={getValues}
            setValue={setValue}
            errors={errors}
            register={register}
            fieldList={itemList}
            handleSubmit={handleSubmit}
            
          />
        </Suspense>
      ) : null}
      {/* {itemList?.fieldCode == "Current Salary" ? (
        <Suspense fallback={<Loading />}>
          <CurrentSalaryFormField
            control={control}
            resumeId={resumeId}
            getValues={getValues}
            setValue={setValue}
            errors={errors}
            register={register}
            fieldList={itemList}
          />
        </Suspense>
      ) : null} */}
      {itemList?.fieldCode == "Notice Period" ? (
        <Suspense fallback={<Loading />}>
          <NoticePeriodFormField
          noticePeriodData={noticePeriodData}
            control={control}
            resumeId={resumeId}
            getValues={getValues}
            setValue={setValue}
            errors={errors}
            register={register}
            fieldList={itemList}
          />
        </Suspense>
      ) : null}
      {itemList?.fieldCode == "Work Permit" ? (
        <Suspense fallback={<Loading />}>
          <WorkPermitFormField
            workPermitData={workPermitData}
            control={control}
            resumeId={resumeId}
            getValues={getValues}
            setValue={setValue}
            errors={errors}
            register={register}
            fieldList={itemList}
          />
        </Suspense>
      ) : null}
      {itemList?.fieldCode == "Preferred Locations" ? (
        <Suspense fallback={<Loading />}>
          <PreferredLocationsFormField
            preferredLocationsData={preferredLocationsData}
            control={control}
            resumeId={resumeId}
            getValues={getValues}
            setValue={setValue}
            errors={errors}
            register={register}
            fieldList={itemList}
          />
        </Suspense>
      ) : null}
      {itemList?.fieldCode == "Websites" ? (
        <Suspense fallback={<Loading />}>
          <WebsitesFormField
            websitesData={websitesData}
            control={control}
            resumeId={resumeId}
            getValues={getValues}
            setValue={setValue}
            errors={errors}
            register={register}
            fieldList={itemList}
          />
        </Suspense>
      ) : null}
      {itemList?.fieldCode == "Gender" ? (
        <Suspense fallback={<Loading />}>
          <GenderFormField
            genderData={genderData}
            control={control}
            resumeId={resumeId}
            getValues={getValues}
            setValue={setValue}
            errors={errors}
            register={register}
            fieldList={itemList}
          />
        </Suspense>
      ) : null}
      {itemList?.fieldCode == "Languages" ? (
        <Suspense fallback={<Loading />}>
          <LanguagesKnownFormField
            languagesKnownData={languagesKnownData}
            control={control}
            resumeId={resumeId}
            getValues={getValues}
            setValue={setValue}
            errors={errors}
            register={register}
            fieldList={itemList}
          />
        </Suspense>
      ) : null}
      {itemList?.fieldCode == "Veteran Status" ? (
        <Suspense fallback={<Loading />}>
          <VeteranStatusFormField
            veteranStatusData={veteranStatusData}
            control={control}
            resumeId={resumeId}
            getValues={getValues}
            setValue={setValue}
            errors={errors}
            register={register}
            fieldList={itemList}
          />
        </Suspense>
      ) : null}
      {itemList?.fieldCode == "Date of Birth" ? (
        <Suspense fallback={<Loading />}>
          <DateOfBirthFormField
            dateOfBirthData={dateOfBirthData}
            control={control}
            resumeId={resumeId}
            getValues={getValues}
            setValue={setValue}
            errors={errors}
            register={register}
            fieldList={itemList}
          />
        </Suspense>
      ) : null}
      {itemList?.fieldCode == "Age" ? (
        <Suspense fallback={<Loading />}>
          <AgeFormField
            ageData={ageData}
            control={control}
            resumeId={resumeId}
            getValues={getValues}
            setValue={setValue}
            errors={errors}
            register={register}
            fieldList={itemList}
          />
        </Suspense>
      ) : null}
      {itemList?.fieldCode == "Marital Status" ? (
        <Suspense fallback={<Loading />}>
          <MaritalStatusFormField
            maritalStatusData={maritalStatusData}
            control={control}
            resumeId={resumeId}
            getValues={getValues}
            setValue={setValue}
            errors={errors}
            register={register}
            fieldList={itemList}
          />
        </Suspense>
      ) : null}
      {itemList?.fieldCode == "Nationality" ? (
        <Suspense fallback={<Loading />}>
          <NationalityFormField
            nationalityData={nationalityData}
            control={control}
            resumeId={resumeId}
            getValues={getValues}
            setValue={setValue}
            errors={errors}
            register={register}
            fieldList={itemList}
          />
        </Suspense>
      ) : null}
      {/* {itemList?.fieldCode == "Personnel Status" ? (
        <Suspense fallback={<Loading />}>
          <PersonalStatusForm
            personnelStatus={personnelStatus}
            control={control}
            resumeId={resumeId}
            getValues={getValues}
            setValue={setValue}
            errors={errors}
            register={register}
            fieldList={itemList}
            editPersonnelStatus={editPersonnelStatus}
          />
        </Suspense>
      ) : null} */}
      {/* {itemList?.fieldCode == "Employment Status" ? (
        <Suspense fallback={<Loading />}>
          <EmploymentStatusForm
            control={control}
            resumeId={resumeId}
            getValues={getValues}
            setValue={setValue}
            errors={errors}
            register={register}
            fieldList={itemList}
            hideEditEmployeeStatus={hideEditEmployeeStatus}
          />
        </Suspense>
      ) : null} */}
      {/* {itemList?.fieldCode == "Availability" ? (
        <Suspense fallback={<Loading />}>
          <AvailabilityForm
            control={control}
            resumeId={resumeId}
            getValues={getValues}
            setValue={setValue}
            errors={errors}
            register={register}
            fieldList={itemList}
            hideAvailabilityEdit={hideAvailabilityEdit}
          />
        </Suspense>
      ) : null} */}

      {itemList?.fieldCode == "Min Expected Salary" ? (
        <Suspense fallback={<Loading />}>
          <MinExpectedSalaryFormField
            minExpectedSalaryData={minExpectedSalaryData}
            control={control}
            resumeId={resumeId}
            getValues={getValues}
            setValue={setValue}
            errors={errors}
            register={register}
            fieldList={itemList}
          />
        </Suspense>
      ) : null}
      {itemList?.fieldCode == "Max Expected Salary" ? (
        <Suspense fallback={<Loading />}>
          <MaxExpectedSalaryFormField
            maxExpectedSalaryData={maxExpectedSalaryData}
            control={control}
            resumeId={resumeId}
            getValues={getValues}
            setValue={setValue}
            errors={errors}
            register={register}
            fieldList={itemList}
          />
        </Suspense>
      ) : null}
      {itemList?.fieldCode == "CR Period" ? (
        <Suspense fallback={<Loading />}>
          <CompensationPeriodFormField
            compensationPeriodData={compensationPeriodData}
            control={control}
            resumeId={resumeId}
            getValues={getValues}
            setValue={setValue}
            errors={errors}
            register={register}
            fieldList={itemList}
          />
        </Suspense>
      ) : null}
      {itemList?.fieldCode == "CR Currency" ? (
        <Suspense fallback={<Loading />}>
          <CompensationCurrencyFormField
            compensationCurrencyData={compensationCurrencyData}
            control={control}
            resumeId={resumeId}
            getValues={getValues}
            setValue={setValue}
            errors={errors}
            register={register}
            fieldList={itemList}
          />
        </Suspense>
      ) : null}
      {itemList?.fieldCode == "Preferred Roles" ? (
        <Suspense fallback={<Loading />}>
          <PreferredRolesForm
          preferredRoles={preferredRoles}
            control={control}
            resumeId={resumeId}
            getValues={getValues}
            setValue={setValue}
            errors={errors}
            register={register}
            fieldList={itemList}
          />
        </Suspense>
      ) : null}
      {itemList?.fieldCode == "Ethnicity" ? (
        <Suspense fallback={<Loading />}>
          <EthnicityForm
            ethnicityData={ethnicityData}
            control={control}
            resumeId={resumeId}
            getValues={getValues}
            setValue={setValue}
            errors={errors}
            register={register}
            fieldList={itemList}
          />
        </Suspense>
      ) : null}
    </>
  );
};
export default StandardForms;
