import React, { useState, useRef } from "react";
import { Trans } from "react-i18next";
import "./ExpLayout.css";
import LocationSelect from "./LocationSelect";
import JobRoleSelect from "./JobRoleSelect";
import queryString from "query-string";
import {
  useNavigate,
  NavLink,
  useLocation,
  useRouteLoaderData,
} from "react-router-dom";
import searchJsonConfig from "../../utilities/search.json";
import usePost from "../../customhooks/UsePost";
import ExperienceSelect from "./ExperienceSelect";

function LayoutMain() {
  const [selectedRole, setSelectedRole] = useState([]);
  const [selectedJob, setSelectedJob] = useState("");
  const [selectedExperience, setSelectedExperience] = useState("");
  const [selectedLocation, setSelectedLocation] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const navigate = useNavigate();
  const [searchID, setSearchID] = useState("");
  const Loadedetails = useRouteLoaderData("jobsone");

  const locationObj = useRef({
    city: "",
    country: "",
    pin: { lat: 0, lon: 0 },
    state: "",
    zipcode: "",
  });
  const {
    post: getJobs,
    data,
    isLoading,
    error,
  } = usePost("/jobsearch/search");
  const searchJobs = () => {
    const query = {};

    if (selectedRole.length > 0) {
      query.keywords = selectedRole.map((role) => role.value).join(",");
    }

    if (selectedExperience) {
      if (selectedExperience == "6") {
        query.minExp = 5;
        query.maxExp = 50;
      } else {
        query.experience = selectedExperience;
      }
    }
    // if (selectedLocation) {
    //   console.log(selectedLocation,"selectedLocationselectedLocation")
    //   console.log(locationObj.current,"Location")
    //   const { city, state, country, zipcode, pin, text } = selectedLocation;
    //   if (city) query.city = city;
    //   if (state) query.state = state;
    //   if (country) query.country = country;
    //   if (zipcode) query.zipcode = zipcode;
    //   if (pin?.lat) query.lat = pin?.lat;
    //   if (pin?.lon) query.lon = pin?.lon;
    //   if (text) query.locationtext = text;
    // }
    if (locationObj) {
      if (locationObj.current.city) query.city = locationObj.current.city;
      if (locationObj.current.state) query.state = locationObj.current.state;
      if (locationObj.current.country)
        query.country = locationObj.current.country;
      if (locationObj.current.zipcode)
        query.zipcode = locationObj.current.zipcode;
      if (locationObj.current.pin.lat) query.lat = locationObj.current.pin.lat;
      if (locationObj.current.pin.lon) query.lon = locationObj.current.pin.lon;
    }

    let obj = JSON.parse(JSON.stringify(searchJsonConfig.searchJSON));

    obj.searchKey = selectedRole.map((e) => e.value);

    if (locationObj.current && selectedLocation && selectedLocation !== "") {
      obj.search.push({
        type: "location",
        location: [locationObj.current],
      });
    }

    if (parseInt(selectedExperience) < 6) {
      obj.search.push({
        type: "experience",
        maxExp: selectedExperience,
        minExp: 0,
      });
    }
    if (parseInt(selectedExperience) === 6) {
      obj.search.push({
        type: "experience",
        maxExp: 50,
        minExp: 5,
      });
    }

    // obj.size = 6;
    obj.size = 200;
    // obj.page = 1;
    // obj.skip = 0;
    // obj.companyID = searchJsonConfig.searchJSON.companyID;
    obj.companyID = Loadedetails?.companyID ;
    const { search } = window.location;
    const params = new URLSearchParams(search);
    // if (params.has("industry")) {
    //   searchJson.filter = [];
    // }

    getJobs(obj).then((res) => {
      // if (res?.searchID) {
      //   setSearchID(res.searchID);
      //   navigateToJobsPage(query, res.searchID);
      // }
      // console.log(res,"ressssssssssssssssss")
      if (res?.searchID) {
        setSearchID(res.searchID);
        navigateToJobsPage(query, res.searchID);
      }
    });
  };

  // const navigateToJobsPage = (query, searchID) => {
  //   const queryStringParams = queryString.stringify(query);
  //   // const newPath = `/list/${searchID}`;/* ?${queryStringParams} */
  //   // console.log(newPath,"newPathnewPath")
  //   const newPath = `/list/${searchID}`;/* ?${queryStringParams} */
  //   // console.log(newPath,"newPathnewPath")
  //   // navigate(newPath);
  //   window.history.replaceState({}, "", newPath);
  // };

  // const navigateToJobsPage = (query, searchID) => {
  //   const queryWithSearchID = { ...query, searchID };
  //   const queryStringParams = queryString.stringify(queryWithSearchID);
  //   const newPath = `/list?${queryStringParams}`;
  //   navigate("/list");
  //   window.history.replaceState({}, "", newPath);
  // };
  const navigateToJobsPage = (query, searchID) => {
    const queryWithSearchID = { ...query, searchID };
    const queryStringParams = queryString.stringify(queryWithSearchID);
    const newPath = `/careers/list?${queryStringParams}`;
    // navigate(newPath); // Navigate to the new path with the search parameters
    window.history.replaceState({}, "", newPath);
    window.location.reload();
  };

  return (
    <div className={`backGroundMain mainCard p-5  gapsAround`}>
      <h3>
        <span className="posterText"> Make your next move. </span>
      </h3>

      <h6
        className="mt-0 text-light"
        onClick={(e) => {
          e.preventDefault();
          searchJobs();
        }}
      >
        <a className="ps-2" href="#">
          see all jobs<i className="fa-solid fa-angle-right ms-2"></i>
        </a>
      </h6>

      <div className="ms-5">
        <div className="d-flex align-items-center">
          <h1 className="mt-4 text-start">
            <span className="me-3 fontSize">
              <b className="fontColor">
                <Trans i18nKey="iam" defaults="I am " />{" "}
                <Trans i18nKey="a" defaults="a" />
              </b>
            </span>
          </h1>
          <span className="mt-3">
            <JobRoleSelect
              selectedRole={selectedRole}
              setSelectedRole={setSelectedRole}
              suggestions={suggestions}
              setSuggestions={setSuggestions}
            />
          </span>
        </div>

        <div className="d-flex align-items-center">
          <h1 className="mt-4 text-start">
            <span className="me-3 fontSize">
              <b className="fontColor">
                <Trans i18nKey="having" defaults="Having" />{" "}
                <Trans i18nKey="experience" defaults="Experience" />{" "}
                <Trans i18nKey="of" defaults="of" />
              </b>
            </span>
          </h1>
          <span className="mt-3">
            <ExperienceSelect
              selectedExperience={selectedExperience}
              setSelectedExperience={setSelectedExperience}
            />
          </span>
        </div>

        <div>
          <h1 className="mt-4 text-start">
            <span className="me-3 fontSize">
              <b className="fontColor">
                <Trans i18nKey="Id" defaults="I’d" />{" "}
                <Trans i18nKey="love" defaults="love" />{" "}
                <Trans i18nKey="to" defaults="to" />{" "}
                <Trans i18nKey="live" defaults="live" />{" "}
                <Trans i18nKey="andd" defaults="and" />{" "}
                <Trans i18nKey="work" defaults="work" />{" "}
                <Trans i18nKey="in" defaults="in" />{" "}
              </b>
            </span>
          </h1>

          <LocationSelect
            selectedLocation={selectedLocation}
            setSelectedLocation={setSelectedLocation}
            locationObj={locationObj}
          />
        </div>

        {(selectedRole.length > 0 ||
          selectedExperience !== "" ||
          selectedLocation !== "") && (
          <div
            className="searchButton rounded-pill buttonColor m-2 shadow mt-5 d-flex align-items-center w-25"
            onClick={searchJobs}
          >
            <h6 className="my-2">show available positions</h6>
          </div>
        )}
      </div>
    </div>
  );
}

export default LayoutMain;
