import React from "react";
import PropTypes from "prop-types";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { useEffect, useRef } from "react";
import { StandaloneSearchBox } from "@react-google-maps/api";
import { Trans } from "react-i18next";
import { Form } from "react-bootstrap";
import { useState } from "react";
import { t } from "i18next";
import LocationField from "../fields/LocationField";

function ResumeLocationForm({ location, register, setValue, control, errors, fieldObj, clearErrors, getValues }) {

  useEffect(() => {
    clearErrors()
    location?.map((field, index) => {
      setValue("profileLocation", field?.address);
      setValue("profilePostalCode", field?.postalCode);
      setValue("profileCity", field?.city);
      setValue("profileCountry", field?.country != "" && field?.country != null ? field?.country : field?.countryCanonical);
      setValue("profileState", field?.state);
    });
  }, []);
  console.log(getValues(), "sdfksdnfksndf");
  console.log(fieldObj, "fieldObjfieldObj");
  function prepareLocation(address) {
    clearErrors(["profileLocation", "profilePostalCode", "profileCity", "profileCountry", "profileState"]);
    let address1 = "";
    let postcode = "";
    let cityCode = "";
    let state = "";
    let country = "";
    let countryCanonical = ""
    // console.log(address, "wjgrbnjgbjbwjefb");
    if (address) {
      if (address.address_components.length > 0) {
        for (let i in address.address_components) {
          if (
            address.address_components[i].types.indexOf("street_number") != -1
          ) {
            address1 += address.address_components[i].long_name + " ";
          }
          if (address.address_components[i].types.indexOf("route") != -1) {
            address1 += address.address_components[i].long_name;
          }
          if (
            address.address_components[i].types.indexOf("postal_code") != -1
          ) {
            postcode += address.address_components[i].long_name + " ";
          } else if (
            address.address_components[i].types.indexOf("postal_code_suffix") !=
            -1
          ) {
            postcode += address.address_components[i].long_name;
          }
          if (address.address_components[i].types.indexOf("locality") != -1) {
            cityCode += address.address_components[i].long_name;
          }
          if (address.address_components[i].types.indexOf("administrative_area_level_1") != -1) {
            state = address.address_components[i].long_name;
          }
          if (
            address.address_components[i].types.indexOf(
              "country"
            ) != -1
          ) {
            country = address.address_components[i].long_name;
            countryCanonical = address.address_components[i].short_name
          }
        }
      }
      setValue("profileLocation", address1);
      setValue("profilePostalCode", postcode);
      setValue("profileCity", cityCode);
      setValue("profileCountry", getValues()?.profileCountry == country ? getValues()?.profileCountry : country);
      setValue("profileState", state);
    }
  }
  const handleAddressChange = (address) => {
    if (address) {
      prepareLocation(address);
    }
  };

  const handlePostalCodeChange = (postalCode) => {
    if (postalCode) {
      prepareLocation(postalCode);
    }
  };
  const handleCityChange = (city) => {
    if (city) {
      prepareLocation(city);
    }
  };
  const handleStateChange = (state) => {
    if (state) {
      prepareLocation(state);
    }
  };
  const handleCountryChange = (country) => {
    if (country) {
      if (country) {
        prepareLocation(country);
      }
    }
  };
  return (
    <>
      <div className="col" id={fieldObj?.fieldCode}>
        <label>
          <Trans i18nKey="address" defaults="Address" />
        </label>
        <Controller
          control={control}
          name="profileLocation"
          refs={register("profileLocation", {
            required: false
          })}
          // defaultValue=""
          render={({ field: { onChange, onBlur, value } }) => (
            <div className="w-100">
              <LocationField
                handlePlaceChange={handleAddressChange}
                onChange={onChange}
                value={value}
                column={{
                  editable: true,
                  fieldName: "location",
                  i18nKey: "location"
                }}

              />
            </div>
          )}
        />
      </div>
      <div className="col" id={fieldObj?.fieldCode}>
        <label>
          <Trans i18nKey="postalCode" defaults="Postal Code" />
          <span className="text-danger">{"*"}</span>
        </label>
        <Controller
          control={control}
          name="profilePostalCode"
          refs={register("profilePostalCode", {
            required: true,
            validate:{
                emptyValue: (v) => v.replace(/\s/g, "") != ""
              }
          })}
          render={({ field: { onChange, onBlur, value } }) => (
            <div className="w-100">
              <LocationField
                handlePlaceChange={handlePostalCodeChange}
                onChange={onChange}
                value={value}
                column={{
                  editable: true,
                  fieldName: "Postal Code",
                  i18nKey: "postalCode"
                }}
              />
            </div>
          )}
        />
        {errors?.profilePostalCode && (
          <span className="text-danger">
            {errors?.profilePostalCode?.message || t("postalCodeRequired", { defaultValue: "PostalCode is required" })}
          </span>
        )}
      </div>
      <div className="col">
        <label>
          <Trans i18nKey="city" defaults="City" />
          <span className="text-danger">{"*"}</span>
        </label>
        <Controller
          control={control}
          name="profileCity"
          refs={register("profileCity", {
            required: true,
            validate: {
                emptyValue: (v) => v.replace(/\s/g, "") != "",
              }
          })}
          render={({ field: { onChange, onBlur, value } }) => (
            <div className="w-100" id={fieldObj?.fieldCode}>
              <LocationField
                handlePlaceChange={handleCityChange}
                onChange={onChange}
                value={value}
                column={{
                  editable: true,
                  fieldName: "City",
                  i18nKey: "city"
                }}
              />
            </div>
          )}
        />
        {errors?.profileCity && (
          <span className="text-danger">
            {errors?.profileCity?.message || t("cityRequired", { defaultValue: "City is required" })}
          </span>
        )}
      </div>
      <div className="col" id={fieldObj?.fieldCode}>
        <label>
          <Trans i18nKey="state" defaults="State" />
          <span className="text-danger">{"*"}</span>
        </label>
        <Controller
          control={control}
          name="profileState"
          refs={register("profileState", {
            required: true,
            validate:{
                emptyValue: (v) => v.replace(/\s/g, "") != "",
            }
          })}
          render={({ field: { onChange, onBlur, value } }) => (
            <div className="w-100">
              <LocationField
                handlePlaceChange={handleStateChange}
                onChange={onChange}
                value={value}
                column={{
                  editable: true,
                  fieldName: "State",
                  i18nKey: "state"
                }}
              />
            </div>
          )}
        />
        {errors?.profileState && (
          <span className="text-danger">
            {errors?.profileState?.message || t("stateRequired", { defaultValue: "State is required" })}
          </span>
        )}
      </div>
      <div className="col" id={fieldObj?.fieldCode}>
        <label>
          <Trans i18nKey="country" defaults="Country" />
          <span className="text-danger">{"*"}</span>
        </label>
        <Controller
          control={control}
          name="profileCountry"
          refs={register("profileCountry", {
            required: true,
            validate:{
                emptyValue: (v) => v.replace(/\s/g, "") != "",
              }
          })}
          render={({ field: { onChange, onBlur, value } }) => (
            <div className="w-100" id={fieldObj?.fieldCode}>
              <LocationField
                handlePlaceChange={handleCountryChange}
                onChange={onChange}
                value={value}
                column={{
                  editable: true,
                  fieldName: "Country",
                  i18nKey: "country"
                }}
              />
            </div>
          )}
        />
        {errors?.profileCountry && (
          <span className="text-danger">
            {errors?.profileCountry?.message || t("countryRequired", { defaultValue: "Country is required" })}
          </span>
        )}
      </div>
    </>
  );
}
export default ResumeLocationForm;