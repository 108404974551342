import React, { useContext, useEffect, useState } from "react";
import usePost from "../../../customhooks/UsePost";
import UserContext from "../../../app/context/UserContext";
// import Loading from "../../../utilities/Loading";
import ProfileStandard from "./ProfileStandard";
import ProfileDetails from "./ProfileDetails";
import profileLayout from "../../../../profiletabs.json";
import { v4 as uuidv4 } from "uuid";
import Loading from "../../../Loder";
import { useTranslation } from "react-i18next";
import { Notification, useToaster } from "rsuite";
const ProfileBasicDetails = ({
  setResume,
  resume,
  scrollRef,
  setscrollRef,
}) => {
  const {
    post: customLayout,
    data,
    isLoading: customLayoutsLoading,
    error,
  } = usePost("/getcustomlayout");
  const toaster = useToaster();
  const { t } = useTranslation();
  const { user, setUserDetails } = useContext(UserContext);
  const [custom, setCustom] = useState({});
  const [customData, setCustomData] = useState({});
  const [id, setId] = useState(user?.resumeID);
  // const [resume, setResume] = useState({});
  const [resumeData, setresumeData] = useState({});
  const [UpdateResumeDataData, setUpdateResumeDataData] = useState({});

  useEffect(() => {
    if (user?.resumeID) {
      setId(user.resumeID);
    }
  }, [user]);

  const {
    post: resumeDetails,
    isLoading: resumeDetailsIsLoading,
    error: resumeDetailsError,
  } = usePost("/getresumedetails");

  useEffect(() => {
    if (user?.collections?.resumes && id !== "") {
      setresumeData({
        resumeCollectionName: user?.collections?.resumes,
        resumeID: id,
      });
    }
  }, [user, id]);
  useEffect(() => {
    if (
      resumeData?.resumeCollectionName &&
      resumeData?.resumeCollectionName != "" &&
      resumeData?.resumeID &&
      resumeData?.resumeID != ""
    ) {
      resumeDetails(resumeData)
        .then((res) => {
          if (res) {
            setResume(res);
          }
        })
        .catch((error) => {
          console.error("Error while fetching Resume:", error);
        });
    }
  }, [resumeData, UpdateResumeDataData]);
  useEffect(() => {
    if (UpdateResumeDataData && Object.keys(UpdateResumeDataData).length !== 0) {
      toaster.push(
        <Notification type={"success"} header="Success" closable>
          {t("profileDetailsUpdatedSuccessfully", {
            defaultValue: "Profile Details Updated Successfully..!",
          })}
        </Notification>,
        { placement: "topEnd" }
      );
    }
  }, [UpdateResumeDataData]);

  useEffect(() => {
    if (user && user?.companyID && user?.companyID != "" && id !== "") {
      setCustomData({
        page: "profile",
        companyID: user?.companyID,
        user: user,
        resumeID: id,
      });
    }
  }, [user]);

  useEffect(() => {
    if (
      customData.companyID &&
      customData?.companyID != "" &&
      customData?.page &&
      customData?.page != ""
    ) {
      customLayout(customData)
        .then((res) => {
          if (res?.customLayout) {
            setCustom(res?.data);
          } else {
            let layout = {};
            layout.layout = profileLayout;
            setCustom(layout);
          }
        })
        .catch((error) => {
          console.error("Error while fetching custom layout:", error);
        });
    }
  }, [customData]);
  return (
    <>
      {/* {resumeDetailsIsLoading || customLayoutsLoading ? (
        <Loading rows={10} />
      ) : ( */}
        <>
        <div style={{minHeight:"600px"}}>
          <div className="card p-2" key={uuidv4()}>
            {custom?.layout?.profileSummary?.rows.map((rows, index) => {
              return (
                <div key={uuidv4()}>
                  {rows?.columns.map((item, index) => {
                    return (
                      <div key={uuidv4()}>
                        <React.Suspense
                          fallback={
                            <>
                              <Loading />
                            </>
                          }
                        >
                          <ProfileStandard
                            resumeData={resume}
                            resumeID={id}
                            item={item}
                            userDetails={user}
                            setUpdateResumeDataData={setUpdateResumeDataData}
                          />
                        </React.Suspense>
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
          <div>
            {custom != {} && (
              <ProfileDetails
                resumeID={id}
                customTabs={custom}
                resume={resume}
                setUpdateResumeDataData={setUpdateResumeDataData}
                resumeDetailsIsLoading={resumeDetailsIsLoading}
                scrollRef={scrollRef}
                setscrollRef={setscrollRef}
              />
            )}
          </div>
        </div>
      </>
      {/* )} */}
    </>
  );
};

export default ProfileBasicDetails;
