import React, { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { Trans } from "react-i18next";
import { t } from "i18next";
import { Loader, TagPicker } from "rsuite";
const PreferredRolesForm = ({
  preferredRoles,
  resumeId,
  control,
  register,
  errors,
  setValue,
  fieldList,
}) => {
  const [search, setSearch] = useState("");
  const [loading, setLoading] = React.useState(false);
  const [cacheData, setCacheData] = React.useState([]);

  useEffect(() => {
    if (preferredRoles?.length > 0) {
      const roles = preferredRoles.map((item) => item?.jobTitle);

      setCacheData([
        ...cacheData,
        ...roles.map((x) => {
          return { label: x, value: x };
        }),
      ]);

      setValue("preferredRoles", roles);
    }
  }, [preferredRoles]);
  const handleSelect = (value, item, event) => {
    setCacheData([...cacheData, item]);
  };
  return (
    <>
      <div className="form-group">
        <label className="text-dark">
        {t(fieldList?.i18nKey, { defaultValue: fieldList?.fieldName })}
        </label>
        {fieldList?.required && <span className="text-danger">{"*"}</span>}
      

      <Controller
        control={control}
        name={"preferredRoles"}
        refs={register("preferredRoles",{
          required:fieldList?.required ? true : false,
          // validate: {
          //   emptyValue: (v) =>
          //     v && v != "" ? v.replace(/\s/g, "") != "" : true,
          // },
      })}
        render={({ field: { onChange, onBlur, value } }) => (
          <TagPicker
            cacheData={cacheData}
            creatable
            data={[]}
            name="keywords"
            className="w-100"
            onSelect={handleSelect}
            onChange={(value) => {
              onChange(value);
              setValue("preferredRoles", value);
            }}
            value={value}
            onSearch={(e) => setSearch(e)}
            onCreate={(value, item) => {
              setCacheData([...cacheData, item]);
            }}
            placement="auto"
            size={"md"}
            disabled={fieldList?.editable || !fieldList ? false : true}
            placeholder={t("select", { defaultValue: "Select" }) + " " +  t("preferredRoles", { defaultValue: "Preferred roles"})}
            renderMenu={(menu) => {
              if (loading) {
                return (
                  <p className="checkBoxLoader">
                    <Loader />
                    <Trans defaults="Loading..." i18nKey="loading" />
                  </p>
                );
              }
              return menu;
            }}
          />
        )}
      />
      {errors?.preferredRoles?.type == "required" && (
        <span className="text-danger">
          {errors?.preferredRoles?.message != "" ? (
            errors?.preferredRoles?.message
          ) : (
            <Trans
              i18nKey="preferredRolesIsRequired"
              defaults="Preferred Roles is required"
            />
          )}{" "}
        </span>
      )}
      </div>
    </>
  );
};

export default PreferredRolesForm;
