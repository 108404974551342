import React, { Suspense, useEffect, useState } from "react";
import { Trans } from "react-i18next";
import { Steps, Button } from "rsuite";
import { Icon } from "@rsuite/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAddressCard,
  faClipboardList,
  faGraduationCap,
  faBriefcase,
  faStickyNote,
} from "@fortawesome/free-solid-svg-icons";
import Loading from "../../../utilities/Loading";
// import PropTypes  from "prop-types";
import "../../createProfile/CreateProfile.css";
// import { useForm } from "react-hook-form";

const CareersProfileDetailsMain = React.lazy(() =>
  import("./CareersProfileDetailsMain")
);
const CareersEditProfileSummarySession = React.lazy(() =>
  import("./CareersEditProfileSummarySession")
);
const CareersEditProfileEducation = React.lazy(() =>
  import("./CareersEditProfileEducation")
);
const CareersEditProfileExperience = React.lazy(() =>
  import("./CareersEditProfileExperience")
);
const CareersEditOtherInfo = React.lazy(() => import("./CareersEditOtherInfo"));

const CreateManualProfileMain = ({
  //    required,
  profiledata,
  setEditresumedetails,
  setProfileEditdetails,

  register,
  handleSubmit,
  control,
  setValue,
  getValues,
  errors,
  watch,
  clearErrors,
  required,
  setError,
  setUpdatedData,
  updatedData,
  handleUpdateValues,
  setProfiledata
}) => {
  const [step, setStep] = useState(0);
  const [targetPage, setTargetPage] = useState("Profile");
  const [checked, setchecked] = useState(false);
  const [showapprove, setshowapprove] = useState(false);
  const [exchecked, setexchecked] = useState(false);
  const [exshowapprove, setexshowapprove] = useState(false);
  const onChange = (nextStep) => {
    if (nextStep < 0) {
      setStep(0);
    } else {
      if (nextStep > 4) {
        setStep(4);
      } else {
        setStep(nextStep);
      }
    }
    // setStep(nextStep < 0 ? 0 : nextStep > 4 ? 4 : nextStep);
  };
  // const {
  //   register,
  //   control,
  //   getValues,
  //   setValue,
  //   watch,
  //   clearErrors,
  //   formState: { errors },
  //   setError,
  //   handleSubmit,
  //   required,
  // } = useForm();
  const isMounted = React.useRef(getValues()?.experience?.length > 0 ? true : false);
  const [skip, setskip] = useState(false);
  const onPrevious = () => onChange(step - 1);

  // const onPrevious = () => {
  //   onChange(step - 1);
  // };
  const onNext = () => {
    onChange(step + 1);
    if (step == 4) {
      setEditresumedetails(false);
    }
  };
  const handleStepClick = (target) => {
    handleSubmit(() => {
      handleUpdateValues();
      handleProfileDetailsDetails(target);
    })();
  };
  const handleProfileDetailsDetails = (target) => {
    if (target == "Profile") {
      setStep(0);
      setTargetPage("Profile");
    } else if (target == "Experience") {
      setStep(1);
      setTargetPage("Experience");
    } else if (target == "Education") {
      setStep(2);
      setTargetPage("Education");
    } else if (target == "Summary") {
        setStep(3);
        setTargetPage("Summary");
    } else if (target == "otherDetails") {
      if(showapprove ){
        if(checked === false){
          setStep(3);
          setTargetPage("Summary");
        }else{
        setStep(4);
        setTargetPage("otherDetails");
        }
      }else{
        setStep(4);
        setTargetPage("otherDetails");
      }
    }
  };
  useEffect(() => {
    if (step == 0) {
      setTargetPage("Profile");
    } else if (step == 1) {
      setTargetPage("Experience");
      isMounted.current=false;
      setskip(true)
    } else if (step == 2) {
      setTargetPage("Education");
    } else if (step == 3) {
      setTargetPage("Summary");
      isMounted.current=false;
      setskip(true)
    } else if (step == 4) {
      setTargetPage("otherDetails");
    }
  }, [step]);

  const [isSmallScreen, setIsSmallScreen] = useState(
    typeof window !== 'undefined' ? window.innerWidth < 768 : false
  );

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(typeof window !== 'undefined' ? window.innerWidth < 768 : false);
    };

    if (typeof window !== 'undefined') {
      window.addEventListener('resize', handleResize);

      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }
  }, []);

  return (
    <>
      <div className="row ">
        <div className="col-sm-12  d-sm-block d-none">
         <div className="text-capitalize heading pb-2">
         <Trans  i18nKey="submitYourProfile"  defaults="Submit Your Profile"  />
           {/* Submit Your profile */}
         </div>
          <Steps current={step} className="d-flex justify-content-center">
            <Steps.Item
              className="cursor-pointer"
              // onClick={handleSubmit(() =>
              //   handleProfileDetailsDetails("Profile"),
              //   // handleUpdateValues()
              // )}
              onClick={() => handleStepClick("Profile")}
              title={<Trans i18nKey="profile" defaults="Profile" />}
              icon={
                <Icon style={{ fontSize: 20 }}>
                  <FontAwesomeIcon icon={faAddressCard} />
                </Icon>
              }
            />
            <Steps.Item
              className="cursor-pointer"
              // onClick={handleSubmit(() =>
              //   handleProfileDetailsDetails("Summary"),
              //   // handleUpdateValues()
              // )}
              onClick={() => {
                if(step !== 1){
                  handleStepClick("Experience")
                }
              }}
              // title={<Trans i18nKey="summary" defaults="Summary" />}
              title={<Trans i18nKey="experience" defaults="Experience" />}
              icon={
                <Icon style={{ fontSize: 20 }}>
                  <FontAwesomeIcon icon={faClipboardList} />
                </Icon>
              }
            />
            <Steps.Item
              className="cursor-pointer"
              // onClick={handleSubmit(() =>
              //   handleProfileDetailsDetails("Education"),
              //   // handleUpdateValues()
              // )}
              onClick={() => {
                if(exshowapprove ){
                  if(exchecked === true){
                    handleStepClick("Education")
                  }
                }else{
                  handleStepClick("Education")
                }
              }}
              title={<Trans i18nKey="education" defaults="Education" />}
              icon={
                <Icon style={{ fontSize: 20 }}>
                  <FontAwesomeIcon icon={faGraduationCap} />
                </Icon>
              }
            />
            <Steps.Item
              className="cursor-pointer"
              // onClick={handleSubmit(() =>
              //   handleProfileDetailsDetails("Experience"),
              //   // handleUpdateValues()
              // )}
              onClick={() => {
                if(exshowapprove ){
                  if(exchecked === true){
                    handleStepClick("Summary")
                  }
                }else{
                  handleStepClick("Summary")
                }
              }}
              // title={<Trans i18nKey="experience" defaults="Experience" />}
              title={<Trans i18nKey="summary" defaults="Summary" />}
              icon={
                <Icon style={{ fontSize: 20 }}>
                  <FontAwesomeIcon icon={faBriefcase} />
                </Icon>
              }
            />
            <Steps.Item
              className="cursor-pointer"
              // onClick={handleSubmit(() =>
              //   handleProfileDetailsDetails("otherDetails"),

              // )}
              onClick={() => {
                if(exshowapprove ){
                  if(exchecked === true){
                    handleStepClick("otherDetails")                  
                  }
                }else{
                  handleStepClick("otherDetails")                  
                }
              }}
              title={<Trans i18nKey="otherDetails" defaults="Other Details" />}
              icon={
                <Icon style={{ fontSize: 20 }}>
                  <FontAwesomeIcon icon={faStickyNote} />
                </Icon>
              }
            />
          </Steps>
        </div>
        {/* // web responsive  rsuite steps displayed to vertical  */}
        <div className="col-sm-12  d-sm-none d-block d-flex justify-content-center">
          <Steps
            current={step}
            className="justify-content-center"
            vertical
            small
          >
            <Steps.Item
              className="cursor-pointer"
              onClick={handleSubmit(() =>
                handleProfileDetailsDetails("Profile"),
                // handleUpdateValues()
              )}
              title={<Trans i18nKey="profile" defaults="Profile" />}
              icon={
                <Icon style={{ fontSize: 20 }}>
                  <FontAwesomeIcon icon={faAddressCard} />
                </Icon>
              }
            />
            <Steps.Item
              className="cursor-pointer"
              onClick={handleSubmit(() =>
                handleProfileDetailsDetails("Experience"),
                // handleUpdateValues()
              )}
              // title={<Trans i18nKey="summary" defaults="Summary" />}
              title={<Trans i18nKey="experience" defaults="Experience" />}
              icon={
                <Icon style={{ fontSize: 20 }}>
                  <FontAwesomeIcon icon={faClipboardList} />
                </Icon>
              }
            />
            <Steps.Item
              className="cursor-pointer"
              onClick={handleSubmit(() =>{
                if(exshowapprove ){
                  if(exchecked === true){
                    handleProfileDetailsDetails("Education")
                  }
                }
              }
              )}
              title={<Trans i18nKey="education" defaults="Education" />}
              icon={
                <Icon style={{ fontSize: 20 }}>
                  <FontAwesomeIcon icon={faGraduationCap} />
                </Icon>
              }
            />
            <Steps.Item
              className="cursor-pointer"
              onClick={handleSubmit(() =>{
                if(exshowapprove ){
                  if(exchecked === true){
                    handleProfileDetailsDetails("Summary")
                  }
                }
              }
                // handleUpdateValues()
              )}
              // title={<Trans i18nKey="experience" defaults="Experience" />}
              title={<Trans i18nKey="summary" defaults="Summary" />}
              icon={
                <Icon style={{ fontSize: 20 }}>
                  <FontAwesomeIcon icon={faBriefcase} />
                </Icon>
              }
            />
            <Steps.Item
              className="cursor-pointer"
              onClick={handleSubmit(() =>{
                if(exshowapprove ){
                  if(exchecked === true){
                    handleProfileDetailsDetails("otherDetails")
                  }
                }
              }
                // handleUpdateValues()
              )}
              title={<Trans i18nKey="otherDetails" defaults="Other Details" />}
              icon={
                <Icon style={{ fontSize: 20 }}>
                  <FontAwesomeIcon icon={faStickyNote} />
                </Icon>
              }
            />
          </Steps>
        </div>
      </div>
      <div className="">
        <div className="">
          {targetPage == "Profile" && (
            <>
              <Suspense
                fallback={
                  <>
                    <Loading />
                  </>
                }
              >
                <CareersProfileDetailsMain
                  register={register}
                  control={control}
                  setValue={setValue}
                  getValues={getValues}
                  errors={errors}
                  clearErrors={clearErrors}
                  handleSubmit={handleSubmit}
                  required={required}
                  setError={setError}
                   Resumedetails={profiledata?.Resumedetails}
                  // Resumedetails={updatedData ? updatedData?.Resumedetails : profiledata?.Resumedetails}
                />
              </Suspense>
            </>
          )}
          {targetPage == "Summary" && (
            <>
              <Suspense
                fallback={
                  <>
                    <Loading />
                  </>
                }
              >
                <CareersEditProfileSummarySession
                  register={register}
                  control={control}
                  setValue={setValue}
                  getValues={getValues}
                  errors={errors}
                  clearErrors={clearErrors}
                  handleSubmit={handleSubmit}
                  Resumedetails={profiledata?.Resumedetails}
                  showapprove={showapprove}
                  setshowapprove={setshowapprove}
                  checked={checked}
                  setchecked={setchecked}
                  // Resumedetails={updatedData ? updatedData?.Resumedetails : profiledata?.Resumedetails}
                />
              </Suspense>
            </>
          )}
          {targetPage == "Education" && (
            <>
              <Suspense
                fallback={
                  <>
                    <Loading />
                  </>
                }
              >
                <CareersEditProfileEducation
                  register={register}
                  control={control}
                  setValue={setValue}
                  getValues={getValues}
                  errors={errors}
                  clearErrors={clearErrors}
                  handleSubmit={handleSubmit}
                  setError={setError}
                  //  educationList={getValues()?.education ?  getValues()?.education :  (profiledata?.Resumedetails?.resume
                  //     ?.professionalQualification?.education) ? (profiledata?.Resumedetails?.resume
                  //       ?.professionalQualification?.education) :[] }
                  educationList={
                    profiledata?.Resumedetails?.resume
                      ?.professionalQualification?.education
                      ? profiledata?.Resumedetails?.resume
                          ?.professionalQualification?.education
                      : [] 
                  }
                  // educationList={
                  //   getValues()?.education ? getValues()?.education  :
                  //   profiledata?.Resumedetails?.resume
                  //        ?.professionalQualification?.education
                  //        ? profiledata?.Resumedetails?.resume
                  //            ?.professionalQualification?.education
                  //        : []
                  // }
                 /*  educationList={updatedData?.Resumedetails?.resume
                    ?.professionalQualification?.education  ? updatedData?.Resumedetails?.resume
                    ?.professionalQualification?.education :  profiledata?.Resumedetails?.resume
                    ?.professionalQualification?.education
                    ? profiledata?.Resumedetails?.resume
                        ?.professionalQualification?.education
                    : []} */
                  target={"editProfile"}
                  
                />
              </Suspense>
            </>
          )}
          {targetPage == "Experience" && (
            <>
              <Suspense
                fallback={
                  <>
                    <Loading />
                  </>
                }
              >
                <CareersEditProfileExperience
                  register={register}
                  control={control}
                  setValue={setValue}
                  getValues={getValues}
                  watch={watch} 
                  errors={errors}
                  clearErrors={clearErrors}
                  setError={setError}
                  handleSubmit={handleSubmit}
                  experienceList={
                    profiledata?.Resumedetails?.resume?.professionalExperience
                      ? profiledata?.Resumedetails?.resume
                          ?.professionalExperience
                      : []
                  }
                  //  experienceList ={updatedData?.Resumedetails?.resume
                  //   ?.professionalExperience  ? updatedData?.Resumedetails?.resume
                  //   ?.professionalExperience :  profiledata?.Resumedetails?.resume?.professionalExperience
                  //        ? profiledata?.Resumedetails?.resume
                  //            ?.professionalExperience   : []}
                  target={"editProfile"}
                  targetPage= {targetPage}
                  skip={skip}
                  setskip={setskip}
                  isMounted={isMounted}
                  exshowapprove={exshowapprove}
                  setexshowapprove={setexshowapprove}
                  exchecked={exchecked}
                  setexchecked={setexchecked}
                />
              </Suspense>
            </>
          )}
          {targetPage == "otherDetails" && (
            <>
              <Suspense
                fallback={
                  <>
                    <Loading />
                  </>
                }
              >
                <CareersEditOtherInfo
                  register={register}
                  control={control}
                  setValue={setValue}
                  getValues={getValues}
                  errors={errors}
                  clearErrors={clearErrors}
                  handleSubmit={handleSubmit}
                  Resumedetails={profiledata?.Resumedetails}
                  // Resumedetails={updatedData ? updatedData?.Resumedetails : profiledata?.Resumedetails}
                />
              </Suspense>
            </>
          )}
        </div>
      </div>
      <div className="row mt-2">
        <div  className={` col d-flex  ${isSmallScreen ? 'justify-content-center' : 'justify-content-end'}`}>
         <>
             <button
                className="btn btn-primary m-1"
                onClick={() => {
                  handleUpdateValues();
                  if (step === 0) {
                    setEditresumedetails(false);
                    setProfileEditdetails(false);
                  } 
                  else if (step === 4) {
                    setStep(0);
                  } else {
                    onPrevious();
                  }
                }}
              >
                {step === 0 ? /* "Back" */<Trans i18nKey="back" defaults="Back" /> 
                : step === 4 ? <Trans i18nKey="edit" defaults="Edit" />/* "Edit" */ : <Trans i18nKey="previous" defaults="Previous" /> /* "Previous" */}
              </button>
              {step === 3 ? <button
                className="btn btn-primary m-1"
                disabled={showapprove ? (checked ? false : true) : false}
                onClick={handleSubmit(() => {
                  handleUpdateValues();
                  onNext();
                })}
              >
                {step === 4 ? <Trans i18nKey="preview" defaults="Preview" />/* "Preview" */ : <Trans i18nKey="next" defaults="Next" /> /* "Next" */}
              </button> 
              : 
              <>
              {step == 1 ? <button
                className="btn btn-primary m-1"
                disabled={exshowapprove ? (exchecked ? false : true) : false}
                onClick={handleSubmit(() => {
                  handleUpdateValues();
                  onNext();
                })}
              >
                {step === 4 ? <Trans i18nKey="preview" defaults="Preview" />/* "Preview" */ : <Trans i18nKey="next" defaults="Next" /> /* "Next" */}
              </button> 
              : 
              <button
              className="btn btn-primary m-1"
              // disabled={showapprove ? (checked ? false : true) : false}
              onClick={handleSubmit(() => {
                handleUpdateValues();
                onNext();
              })}
            >
              {step === 4 ? <Trans i18nKey="preview" defaults="Preview" />/* "Preview" */ : <Trans i18nKey="next" defaults="Next" /> /* "Next" */}
            </button>}
             </> }         
            </> 
        </div>
      </div>
    </>
  );
};

// CreateManualProfileMain.propTypes = {
//   register: PropTypes.func,
//   handleSubmit: PropTypes.func,
//   control: PropTypes.object,
//   setValue: PropTypes.func,
//   getValues: PropTypes.func,
//   errors: PropTypes.object,
//   clearErrors: PropTypes.func,
//   watch: PropTypes.func,

//   required: PropTypes.bool,
//   setError: PropTypes.func,
//   // customTabs: PropTypes.any
// };

export default CreateManualProfileMain;
