import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import usePost from "../../customhooks/UsePost";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ProfileStandard from "../account/tabs/ProfileStandard";
import Loading from "../../Loder";
import { Nav, Tab, Row, Col } from "react-bootstrap";
import ContactDetails from "../account/tabs/fields/ContactDetails";
import {
  Whisper,
  Tooltip,
  Avatar,
  AvatarGroup,
  useToaster,
  Notification,
} from "rsuite";
import axios from "axios";
import {
  faAddressCard,
  faFileText,
  faSuitcase,
  faLocationDot,
  faAddressBook,
} from "@fortawesome/free-solid-svg-icons";
import { Trans, useTranslation } from "react-i18next";
import ProfileDetails from "../account/tabs/ProfileDetails";
import Resume from "../account/tabs/Resume";
import ProfileRecommendedJobs from "../account/tabs/ProfileRecommendedJobs";
import CreateUserResume from "../account/CreateUserResume";
import { DateTime } from "luxon";
import profileLayout from "../../../profiletabs.json";
function Referral() {
    let params = new URLSearchParams(document.location.search);
  let rid = params.get("rid");
  const { referralcode } = useParams();
  let toaster = useToaster();
  const [referncedata, setreferncedata] = useState(null);
  const [resume, setResume] = useState(null);
  const [UpdateResumeDataData, setUpdateResumeDataData] = useState({});
  const [custom, setCustom] = useState({});
  const [customData, setCustomData] = useState({});
  const { t } = useTranslation();
  const [resumeID, setResumeID] = useState("");
  const [referrerresumeID, setrefferrerresumeID] = useState("");
  const [ReferrerResumeDetails, setReferrerResumeDetails] = useState(null);
  const [cookiesresume, setcookiesresume] = useState("");
  const [alreadyReferred, setAlreadyReffered] = useState(false);
  const [ridExist,setRidExist] = useState(false);
  const [cookieExist, setCookieExist ] = useState(false);
  useEffect(()=>{
    if(!ridExist && rid == null ){
      setRidExist(true);
    }
    if(rid != null && referrerresumeID != "" && referrerresumeID != rid){
      setResumeID(rid);
      setcookiesresume(rid);
    }
  },[rid,referrerresumeID])

  const {
    post: referraldetails,
    data: referraldetailsdata,
    isLoading: referraldetailsLoading,
  } = usePost("/user/referenceDetails");

  const {
    post: checkRefererralCollection,
  } = usePost("/user/checkRefererralCollection");

  const { post: referral, isLoading: referralloading } = usePost(
    "/user/findReferralCount"
  );

  const {
    post: setcookie,
    data: setcookiedata,
    isLoading: setcookieLoading,
  } = usePost("/setcookie ");

  const { post: getReferralCode, isLoading: getReferralCodeLoading } = usePost(
    "/user/getReferralCode "
  );

  const {
    post: updateReferraldata,
    data: updateReferraldatadata,
    isLoading: updateReferraldataLoading,
  } = usePost("/user/updateReferraldata ");
  const {
    post: customLayout,
    data,
    isLoading: customLayoutsLoading,
    error,
  } = usePost("/getcustomlayout");
  const [updatedata, setupdate] = useState(false);
  const [cokkiesupadte, setcokkiesupadte] = useState(false);
  const [copyLink, setCopyLink] = useState(false);

  useEffect(()=>{
    if(rid != null){
      handleCheckRefererralCollection(rid);
    }
  },[rid])
  
  const handleCheckRefererralCollection = (resumeID) => {
    if(referrerresumeID != ""){
      checkRefererralCollection({referralCode : referralcode, referralResumeID : resumeID, referrerResumeID: referrerresumeID})
      .then((res)=>{
        if(res?.status){
          if(res?.referral?._id){
            setResumeID(resumeID);
            setcookiesresume(resumeID);
            window.history.replaceState(
              {},
              "",
              `${window.location.pathname}?rid=${resumeID}`
            );
          }
        }
      }).catch((error) => {
        console.error("Error while checking RefererralCollection:", error);
      });
    }
  }
  const handleCopyUrl = () => {
    getReferralCode({ resumeID: resumeID, companyID: referncedata?.companyID })
      .then((res) => {
        if (res?.result?.status) {
          let x = window.location.pathname;
          let reffercode = res?.result?.result;
          x = x.replace(referralcode, reffercode);
          let link = window.location.origin + x;
          navigator.clipboard
            .writeText(link)
            .then(() => {
              setCopyLink(true);
              toaster.push(
                <Notification type={"success"} header="Success" closable>
                  <p>URL Copied SuccessFully</p>
                </Notification>,
                { placement: "topEnd" }
              );
            })
            .catch((err) => {
              console.error("Failed to copy: ", err);
              toaster.push(
                <Notification type={"error"} header="Error" closable>
                  <p>Failed to copy</p>
                </Notification>,
                { placement: "topEnd" }
              );
            });
        }
      })
      .catch((error) => {
        console.error("Error while fetching referralcode:", error);
      });
  };

  // useEffect(() => {
  //   setTimeout(() => {
  //     if (copyLink) {
  //       setCopyLink(false);
  //     }
  //   }, 5000);
  // }, [copyLink]);


  useEffect(() => {
    if (resumeID != "" && referrerresumeID != "" && resumeID != referrerresumeID) {
      updateReferraldata({
        resumeID: resumeID,
        referralcode: referralcode,
        companyID: referncedata?.companyID,
        refererresumeId: referrerresumeID,
        clientID: referncedata?.clientID?.[0],
        userID: referncedata?.userID,
        campaignID: referncedata?.campaignID
          ? referncedata?.campaignID
          : "",
      })
        .then((res) => {
          if (res?.status) {
            setcookie({
              cookieName: "referral_resumeID",
              cookieValue: resumeID,
              expires: DateTime.now().plus({ months: 3 }).toISO(),
            })
              .then((res1) => {
                if (res1?.status) {
                  setupdate(true);
                }
              })
              .catch((error) => {
                console.log("Error while setting cookie:", error);
              });
          }
        })
        .catch((error) => {
          console.error("Error while updating referral data:", error);
        });
      referral({
        resumeID: resumeID,
        companyID: referncedata?.companyID
      })
        .then((res) => {
          if (res?.status) {
            if (res?.count >= 1) {
              if(ridExist || cookieExist){
                // setAlreadyReffered(true);
                // handleCheckRefererralCollection(resumeID);
                toaster.push(
                  <Notification
                    type={"success"}
                    header={t("success", { defaultValue: "success" })}
                    closable
                  >
                    {t("youHaveAlreadyBeenReferredBySomeoneElse", {
                      defaultValue: "You have Already been Referred by Someone else",
                    })}
                  </Notification>,
                  { placement: "topEnd" }
                );
              }
            }
          }
        })
        .catch((error) => {
          console.error("Error while fetching referral resume count:", error);
        });
    }
  }, [resumeID, referrerresumeID]);
  // window.history.replaceState({}, "", `?${queryStringified}`);
  const refreansedataload = () => {
    referraldetails({
      referralcode: referralcode,
    })
      .then((res) => {
        if (res?.status) {
          //   console.log(res?.result, "usegetpercentage");
          if (res?.result) {
            setreferncedata(res?.result);
            // if (res?.result?) {
            setrefferrerresumeID(res?.result?._id);
            // } else {
            //   setrefferrerresumeID(res?.result?.resumeID);
            // }
            isMounted.current = false;
          }
        }
      })
      .catch((error) => {
        console.error("Error while fetching custom layout:", error);
      });
  };

  const isMounted = React.useRef(false);
  useEffect(() => {
    if (referralcode !== "" && referralcode !== null && !isMounted.current) {
      refreansedataload();
      isMounted.current = true;
    }
  }, [referralcode, cokkiesupadte]);

  const {
    post: resumeDetails,
    isLoading: resumeDetailsIsLoading,
    error: resumeDetailsError,
  } = usePost("/getresumedetails");
  const getcookies = async () => {
    try {

      if(resumeID == ""){
      let cookieDetails = await axios.get(
        window.location.origin + "/jsv4/webapi/getcookie"
      );
      cookieDetails = cookieDetails?.data;
      if (cookieDetails["referral_resumeID"] && referrerresumeID != "" && cookieDetails["referral_resumeID"] != referrerresumeID) {
        handleCheckRefererralCollection(cookieDetails["referral_resumeID"]);
        // referral({
        //   resumeID: cookieDetails["referral_resumeID"],
        // })
        //   .then((res) => {
        //     if (res?.status) {
        //       if (res?.count < 2) {
                setcookiesresume(cookieDetails["referral_resumeID"]);
                setcokkiesupadte(true);
                window.history.replaceState(
                  {},
                  "",
                  `${window.location.pathname}?rid=${cookieDetails["referral_resumeID"]}`
                );
        //         setResumeID(cookieDetails["referral_resumeID"])
        //       } else {
        //         setAlreadyReffered(true);
        //       }
        //     }
        //   })
        //   .catch((error) => {
        //     console.error("Error while fetching custom layout:", error);
        //   });
      }else{
        if(!cookieExist && referrerresumeID != ""){
          setCookieExist(true);
        }
      }
      }else{
        setcookiesresume(resumeID);
        window.history.replaceState(
          {},
          "",
          `${window.location.pathname}?rid=${resumeID}`
        );
      }
    } catch (error) {
      console.log("Error in getCookies : ", error);
    }
  };
  useEffect(() => {
    if (referralcode && rid == null) {
      getcookies();
    }
  }, [ referralcode,updatedata, referrerresumeID]);
  useEffect(() => {
    if (cookiesresume !== "" && referncedata?.companyID) {
      resumeDetails({
        resumeID: cookiesresume,
        companyID: referncedata?.companyID,
      })
        .then((res) => {
          if (res) {
            setResume(res);
          }
        })
        .catch((error) => {
          console.error("Error while fetching Resume:", error);
        });
    }
  }, [referncedata, UpdateResumeDataData, cookiesresume]);
  const isMutedresumedetails = useRef(false);
  // console.log("referrerresumeID",referrerresumeID);
  useEffect(() => {
    if (referrerresumeID !== "" && referncedata?.companyID) {
      resumeDetails({
        resumeID: referrerresumeID,
        companyID: referncedata?.companyID,
      })
        .then((res) => {
          if (res) {
            setReferrerResumeDetails(res);
          }
        })
        .catch((error) => {
          console.error("Error while fetching Resume:", error);
        });
      // isMutedresumedetails.current = true;
    }
  }, [referrerresumeID, referncedata]);
  useEffect(() => {
    if (
      referncedata &&
      referncedata?.companyID &&
      referncedata?.companyID != ""
    ) {
      setCustomData({
        page: "profile",
        companyID: referncedata?.companyID,
      });
    }
  }, [referncedata]);

  useEffect(() => {
    if (
      customData.companyID &&
      customData?.companyID != "" &&
      customData?.page &&
      customData?.page != ""
    ) {
      customLayout(customData)
        .then((res) => {
          if (res?.customLayout) {
            setCustom(res?.data);
          } else {
            let layout = {};
            layout.layout = profileLayout;
            setCustom(layout);
          }
        })
        .catch((error) => {
          console.error("Error while fetching custom layout:", error);
        });
    }
  }, [customData]);
  const [key, setKey] = useState("profileDetails");

  function getUserDisplayName(profile, role) {
    let modifiedName = "UK";
    if (role == "user") {
      modifiedName = profile;
    } else {
      if (profile?.fullName != "") {
        modifiedName = profile?.fullName;
      } else if (
        profile?.firstName != "" &&
        profile?.lastName != "" &&
        profile?.middleName != ""
      ) {
        modifiedName =
          profile?.firstName +
          " " +
          profile?.middleName +
          " " +
          profile?.lastName;
      } else if (profile?.firstName != "" && profile?.lastName != "") {
        modifiedName = profile?.firstName + " " + profile?.lastName;
      } else if (profile?.firstName != "" && profile?.middleName != "") {
        modifiedName = profile?.firstName + " " + profile?.middleName;
      } else if (profile?.middleName != "" && profile?.lastName != "") {
        modifiedName = profile?.middleName + " " + profile?.lastName;
      } else if (profile?.firstName != "") {
        modifiedName = profile?.firstName;
      } else if (profile?.middleName != "") {
        modifiedName = profile?.middleName;
      } else if (profile?.lastName != "") {
        modifiedName = profile?.lastName;
      }
    }
    try {
      return modifiedName
        .split(" ")
        .map((x) => x.trim())
        .slice(0, 2)
        .map((x) => x[0].toUpperCase())
        .join("");
    } catch (e) {
      return "UK";
    }
  }

  return (
    <div>
      {referraldetailsLoading || updateReferraldataLoading ? (
        <div className="card">
          <div className="card-body">
            <Loading rows="5" />
          </div>
        </div>
      ) : (
        <div className="row mt-3 align-item-stretch">
          {(resume == null || alreadyReferred) && (
            <div className="col-12 my-1">
              <div className="card h-100 d-flex">
                <div className="refercard-label bg-primary text-white">
                  <Trans i18nKey="referrer" defaults="Referrer" />
                </div>
                <div className="card-body d-flex align-items-center">
                  <div className="d-inline-block mr-3">
                    <AvatarGroup>
                      <Avatar
                        className="fs-3"
                        circle
                        style={{
                          marginRight: 4,
                          height: 80,
                          width: 80,
                          color: "rgb(148, 148, 148)",
                          border: "1px solid rgb(148, 148, 148)",
                          alignContent: "center",
                        }}
                      >
                        {getUserDisplayName(
                          ReferrerResumeDetails?.resume?.profile,
                          "candidate"
                        )}
                      </Avatar>
                    </AvatarGroup>
                  </div>
                  <div className="ms-2">
                    <div className="mx-2 fs-5">
                      {ReferrerResumeDetails?.resume?.profile?.fullName ||
                        "Un Known"}
                    </div>
                    <div className="d-flex align-items-center interview-info my-1">
                      <span className="displayIcon displayIcon-sm mx-2">
                        <FontAwesomeIcon icon={faAddressBook} />
                      </span>
                      <ContactDetails
                        resume={ReferrerResumeDetails}
                        setUpdateResumeDataData={setUpdateResumeDataData}
                        resumeID={ReferrerResumeDetails?._id}
                        //  fieldObj={item}
                        phones={[]}
                        emails={[]}
                        target="profilePage"
                        type="referrer"
                      />
                    </div>
                    <div className="mx-2">
                      <Whisper
                        followCursor
                        placement={"bottom"}
                        speaker={
                          <Tooltip>
                            <Trans i18nKey="location" defaults="Location" />
                          </Tooltip>
                        }
                      >
                        <span className="displayIcon displayIcon-sm me-2">
                          <FontAwesomeIcon icon={faLocationDot} />
                        </span>
                      </Whisper>
                      {ReferrerResumeDetails?.resume?.profile?.locations
                        ?.length > 0 ? (
                        <span
                          title={
                            ReferrerResumeDetails?.resume?.profile?.locations[0]
                              .text
                          }
                        >
                          {
                            ReferrerResumeDetails?.resume?.profile?.locations[0]
                              .text
                          }
                        </span>
                      ) : (
                        "Not Specified"
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {alreadyReferred ? (
            <div className="col-12 my-1">
              <div
                className="card h-100 d-flex align-items-center justify-content-center"
                style={{ minHeight: "100px" }}
              >
                <span>Already Referred By Someone</span>
              </div>
            </div>
          ) : (
            <>
              {resume !== null ? (
                <>
                  <div className="col-6 col-sm-12 col-md-6 my-1">
                    <div className="card h-100 d-flex">
                      <div className="refercard-label bg-primary text-white">
                        <Trans i18nKey="referral" defaults="Referral" />
                      </div>
                      <div className="card-body d-flex align-items-center">
                        <div className="d-inline-block mr-3">
                          <AvatarGroup>
                            <Avatar
                              className="fs-3"
                              circle
                              style={{
                                marginRight: 4,
                                height: 80,
                                width: 80,
                                color: "rgb(148, 148, 148)",
                                border: "1px solid rgb(148, 148, 148)",
                                alignContent: "center",
                              }}
                            >
                              {getUserDisplayName(
                                resume?.resume?.profile,
                                "candidate"
                              )}
                            </Avatar>
                          </AvatarGroup>
                        </div>
                        <div className="ms-2">
                          <div>
                            {custom?.layout?.profileSummary?.rows.map(
                              (rows, index) => {
                                return (
                                  <div key={index}>
                                    {rows?.columns.map((item, index) => {
                                      return (
                                        <div key={index}>
                                          <React.Suspense
                                            fallback={<Loading />}
                                          >
                                            <ProfileStandard
                                              resumeData={resume}
                                              resumeID={resume?._id}
                                              item={item}
                                              setUpdateResumeDataData={
                                                setUpdateResumeDataData
                                              }
                                              type="referral"
                                              handleCopyUrl={handleCopyUrl}
                                              copyLink={copyLink}
                                            />
                                          </React.Suspense>
                                        </div>
                                      );
                                    })}
                                  </div>
                                );
                              }
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-6 col-sm-12 col-md-6 my-1">
                    <div className="card h-100 d-flex">
                      <div className="refercard-label bg-primary text-white">
                        <Trans i18nKey="referrer" defaults="Referrer" />
                      </div>
                      <div className="card-body d-flex align-items-center">
                        <div className="d-inline-block mr-3">
                          <AvatarGroup>
                            <Avatar
                              className="fs-3"
                              circle
                              style={{
                                marginRight: 4,
                                height: 80,
                                width: 80,
                                color: "rgb(148, 148, 148)",
                                border: "1px solid rgb(148, 148, 148)",
                                alignContent: "center",
                              }}
                            >
                              {getUserDisplayName(
                                ReferrerResumeDetails?.resume?.profile,
                                "candidate"
                              )}
                            </Avatar>
                          </AvatarGroup>
                        </div>
                        <div className="ms-2">
                          <div>
                            <div className="mx-2 ">
                              <h5>
                                {
                                  ReferrerResumeDetails?.resume?.profile
                                    ?.fullName
                                }
                              </h5>
                            </div>
                            <div className="d-flex align-items-center interview-info my-1 mt-1">
                              <span className="displayIcon displayIcon-sm mx-2">
                                <FontAwesomeIcon icon={faAddressBook} />
                              </span>
                              <ContactDetails
                                resume={ReferrerResumeDetails}
                                setUpdateResumeDataData={
                                  setUpdateResumeDataData
                                }
                                resumeID={ReferrerResumeDetails?._id}
                                //  fieldObj={item}
                                phones={[]}
                                emails={[]}
                                target="profilePage"
                                type="referrer"
                              />
                            </div>
                            <div className="mx-2 mt-2">
                              <Whisper
                                followCursor
                                placement={"bottom"}
                                speaker={
                                  <Tooltip>
                                    <Trans
                                      i18nKey="location"
                                      defaults="Location"
                                    />
                                  </Tooltip>
                                }
                              >
                                <span className="displayIcon displayIcon-sm me-2">
                                  <FontAwesomeIcon icon={faLocationDot} />
                                </span>
                              </Whisper>
                              {ReferrerResumeDetails?.resume?.profile?.locations
                                ?.length > 0 ? (
                                <span
                                  title={
                                    ReferrerResumeDetails?.resume?.profile
                                      ?.locations[0].text
                                  }
                                  className="ps-1"
                                >
                                  {
                                    ReferrerResumeDetails?.resume?.profile
                                      ?.locations[0].text
                                  }
                                </span>
                              ) : (
                                "Not Specified"
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12 col-lg-12 col-md-12 mb-5">
                    <Tab.Container
                      id="left-tabs-example"
                      defaultActiveKey="profileDetails"
                      activeKey={key}
                      onSelect={(k) => {
                        setKey(k);
                      }}
                    >
                      <div className="">
                        <Row>
                          <Col sm={12}>
                            <Nav className="profileNav row">
                              <div className="col-4 ">
                                <Nav.Item>
                                  <Nav.Link
                                    eventKey="profileDetails"
                                    className=" Navtabsactive text-muted d-flex align-items-center text-decoration-none justify-content-center"
                                  >
                                    <span className="displayIcon displayIcon-sm me-2">
                                      <FontAwesomeIcon icon={faAddressCard} />
                                    </span>
                                    <div
                                      className="text-truncate navbarProfileLableCss"
                                      title={t("profileDetails", {
                                        defaultValue: "Profile Details",
                                      })}
                                    >
                                      <b>
                                        <Trans
                                          i18nKey="profileDetails"
                                          defaults="Profile Details"
                                        />
                                      </b>
                                    </div>
                                  </Nav.Link>
                                </Nav.Item>
                              </div>
                              <div className="col-4">
                                <Nav.Item>
                                  <Nav.Link
                                    eventKey="resume"
                                    className=" Navtabsactive text-muted d-flex align-items-center text-decoration-none justify-content-center"
                                  >
                                    <span className="displayIcon displayIcon-sm me-2">
                                      <FontAwesomeIcon icon={faFileText} />
                                    </span>
                                    <div
                                      className="text-truncate navbarProfileLableCss"
                                      title={t("resume", {
                                        defaultValue: "Resume",
                                      })}
                                    >
                                      <b>
                                        <Trans
                                          i18nKey="resume"
                                          defaults="Resume"
                                        ></Trans>
                                      </b>
                                    </div>
                                  </Nav.Link>
                                </Nav.Item>
                              </div>
                              <div className="col-4">
                                <Nav.Item>
                                  <Nav.Link
                                    eventKey="recomendedJobs"
                                    className=" Navtabsactive text-muted d-flex align-items-center text-decoration-none justify-content-center"
                                  >
                                    <span className="displayIcon displayIcon-sm me-2">
                                      <FontAwesomeIcon icon={faSuitcase} />
                                    </span>
                                    <div
                                      className="text-truncate navbarProfileLableCss"
                                      title={t("recommendedJobs", {
                                        defaultValue: "Recommended Jobs",
                                      })}
                                    >
                                      <b>
                                        <Trans
                                          i18nKey="recommendedJobs"
                                          defaults="Recommended Jobs"
                                        ></Trans>
                                      </b>
                                    </div>
                                  </Nav.Link>
                                </Nav.Item>
                              </div>
                            </Nav>
                          </Col>
                          <Col sm={12}>
                            <Tab.Content>
                              <Tab.Pane eventKey="profileDetails">
                                {key == "profileDetails" && (
                                  <React.Suspense fallback={<Loading />}>
                                    <ProfileDetails
                                      resumeID={resume?._id}
                                      customTabs={custom}
                                      resume={resume}
                                      setUpdateResumeDataData={
                                        setUpdateResumeDataData
                                      }
                                      resumeDetailsIsLoading={
                                        resumeDetailsIsLoading
                                      }
                                      type="referalresume"
                                      companyID={referncedata?.companyID}
                                      // scrollRef={scrollRef}
                                      // setscrollRef={setscrollRef}
                                    />
                                  </React.Suspense>
                                )}
                              </Tab.Pane>
                              <Tab.Pane eventKey="resume">
                                {key == "resume" && (
                                  <React.Suspense fallback={<Loading />}>
                                    <Resume
                                      key={"resume"}
                                      resumeID={resume?._id}
                                      type="referalresume"
                                      referrresumedata={resume}
                                      companyID={referncedata?.companyID}
                                    />
                                  </React.Suspense>
                                )}
                              </Tab.Pane>
                              <Tab.Pane eventKey="recomendedJobs">
                                {key == "recomendedJobs" && (
                                  <React.Suspense fallback={<Loading />}>
                                    <ProfileRecommendedJobs
                                      key={"recomendedJobs"}
                                      companyID={referncedata?.companyID}
                                      resumeID={resumeID}
                                      type="referalresume"
                                    />
                                  </React.Suspense>
                                )}
                              </Tab.Pane>
                            </Tab.Content>
                          </Col>
                        </Row>
                      </div>
                    </Tab.Container>
                  </div>
                </>
              ) : !ReferrerResumeDetails ? (
                <div className="col-12 my-1">
                  <div
                    className="card h-100 d-flex align-items-center justify-content-center"
                    style={{ minHeight: "100px" }}
                  >
                    <span>No Data Found With the Referral Code</span>
                  </div>
                </div>
              ) : (
                <CreateUserResume
                  page="referalresume"
                  companyID={referncedata?.companyID}
                  setResumeID={setResumeID}
                  updateReferraldataLoading={updateReferraldataLoading}
                />
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
}

export default Referral;
