import React, { useEffect, useState } from "react";
import { Modal, useToaster, Notification, Loader } from "rsuite";
import { Button, Spinner } from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";
import usePost from "../../customhooks/UsePost";
import axios from "axios";


export default function LinkProfile({ openLinkProfile, setOpenLinkProfile, user, setUser, campaignID, page, resumeID, setresumedata, type, setinputdata ,setupdatadataMri,section1Ref,
    scrollToSection}) {
    const toaster = useToaster()
    const { t } = useTranslation()
    const [resumeData, setResumeData] = useState(null)
    const [loading, setLoading] = useState(true)

    console.log("pagepage", page);

    const handleButtonClick = () => {
        if(window.location.pathname.includes("/careers")){
            const fullUrl = window.location.origin + "/careers/myaccount/guest";

            window.open(fullUrl, "_blank");    
        }else{
        const fullUrl = window.location.origin + "/myaccount/guest";
        window.open(fullUrl, "_blank");
        }
      };

    useEffect(() => {
        if (resumeID !== null) {
            setLoading(false)
        } else {
            setLoading(true)
        }
    }, [resumeID])

    const getResumeDetails = async () => {
        try {
            let resumeData = await axios.get(
                window.location.origin +
                "/jsv4/webapi/getResumeData/" + resumeID);

            if (resumeData?.data?.status) {
                console.log("resumeDataresumeData", resumeData?.data);
                setLoading(false)
                setResumeData(resumeData?.data?.resumeData)
            } else {
                console.log("resumeData: ", resumeData?.data)
            }
        } catch (error) {
            console.log("error in resumeData : ", error);
        }
    }

    useEffect(() => {
        if (page && page == "guest") {
            getResumeDetails();
        } else {
            setLoading(false)
        }
    }, [page])

    // usePost
    const {
        post: linkProfileToCampaign,
        data: linkProfileToCampaignData,
        isLoading: linkProfileToCampaignLoading,
        error: linkProfileToCampaignError,
    } = usePost("/user/linkProfileToCampaign");

    const handleConfirm = () => {
        let obj = {
            resumeID: "",
            campaignID: campaignID !== undefined ? campaignID : "",
            from: page ?? ""
        }

        if (page && page == "guest" && resumeID) {
            obj.resumeID = resumeID
        } else {
            if (user && user?.resumeID != "") {
                obj.resumeID = user?.resumeID
            }
        }
        // console.log("shgdhfysyetytewfe", obj)

        linkProfileToCampaign(obj)
            .then((resp) => {
                // console.log("reponse", resp)
                if (resp?.status) {
                    if (resp?.result?.status) {
                        if (type && type == "showJob" && resumeID) {
                            setresumedata(resumeID)
                            setinputdata(true)
                            scrollToSection(section1Ref)
                        }
                        if (resp?.result?.isProfileExist) {
                            toaster.push(
                                <Notification type={"success"} header={t("success", { defaultValue: "success" })} closable>
                                    {t("youHaveAlreadyAppliedForThisJob", { defaultValue: "You have already applied for this job" })}
                                </Notification>,
                                { placement: "topEnd" }
                            );
                            setOpenLinkProfile(false)
                        } else {
                            if (resp?.result?.campaignID && resp.result.campaignID.length > 0) {
                                if (page && page !== "guest") {
                                    let oldLinkedCampaigns = user.attachedCampaigns
                                    let newLinkedCampaigns = resp.result.campaignID.filter((id) => !oldLinkedCampaigns.includes(id))

                                    setUser({
                                        ...user,
                                        attachedCampaigns: [...user.attachedCampaigns, ...newLinkedCampaigns]
                                    })
                                }
                                try{
                                    setupdatadataMri(true)
                                }catch(err){
                                    console.log("errror not defined");
                                }
                            }

                            toaster.push(
                                <Notification type={"success"} header={t("success", { defaultValue: "success" })} closable>
                                    {t("jobAppliedSuccessfully", { defaultValue: "Job applied Successfully" })}
                                </Notification>,
                                { placement: "topEnd" }
                            );
                            setOpenLinkProfile(false)
                        }
                    } else {
                        if (resp?.result?.message.includes("Campaign is not active")) {
                            toaster.push(
                                <Notification type={"error"} header={t("error", { defaultValue: "Error" })} closable>
                                    {t("thisJobIsNoLongerAcceptingApplications", { defaultValue: "This job is no longer accepting applications" })}
                                </Notification>,
                                { placement: "topEnd" }
                            );
                            setOpenLinkProfile(false)
                        } else {
                            toaster.push(
                                <Notification type={"error"} header={t("error", { defaultValue: "Error" })} closable>
                                    {t("somethingWentWrongPleaseTryAgainLater", { defaultValue: "Something went wrong please try again later" })}
                                </Notification>,
                                { placement: "topEnd" }
                            );
                        }
                    }
                } else {
                    toaster.push(
                        <Notification type={"error"} header={t("error", { defaultValue: "Error" })} closable>
                            {t("somethingWentWrongPleaseTryAgainLater", { defaultValue: "Something went wrong please try again later" })}
                        </Notification>,
                        { placement: "topEnd" }
                    );
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }

    function getMasked(input) {
        let skipFirstChars = 3;
        let firstThreeChar = input.slice(0, skipFirstChars);

        let domainIndexStart = input.lastIndexOf("@");
        let maskedInput = input.slice(skipFirstChars, domainIndexStart - 1)
        maskedInput = maskedInput.replace(/./g, '*')
        let domainPlusPreviousChar = input.slice(domainIndexStart - 1, input.length);

        return firstThreeChar.concat(maskedInput).concat(domainPlusPreviousChar);
    }


    return (
        <Modal
            open={openLinkProfile}
            onClose={() => setOpenLinkProfile(false)}
            overflow={false}
        >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
                <div className="row pb-4">
                    <div className="col-12 text-center">
                        <span className="fs-5">
                            <Trans i18nKey="areYouSureYouWantToApplyForThisJob" defaults="Are you sure you want to apply for this job" />{"?"}
                        </span>
                    </div>
                </div>
                {
                    loading ? (
                        <Loader center className="pt-5" />
                    ) : (
                        <div className="pt-2 row px-lg-5 pb-2">
                            <div className="col-12 px-sm-5">
                                {resumeData && resumeData?.name && resumeData?.name != "" && (
                                    <div className="row pb-2 ">
                                        <div className="col-5 text-start">
                                            <Trans i18nKey="name" defaults="Name" />
                                        </div>
                                        <span className="col-1">{":"}</span>
                                        <div className="col-5 text-start ps-0">
                                            {resumeData?.name}
                                        </div>
                                    </div>
                                )}
                                {resumeData && resumeData?.emails && resumeData?.emails?.length > 0 && resumeData?.emails[0]?.text != "" && (
                                    <div className="row pb-2 ">
                                        <div className="col-5 text-start">
                                            <Trans i18nKey="email" defaults="Email" />
                                        </div>
                                        <span className="col-1">{":"}</span>
                                        <div className="col-5 text-start px-0">
                                            {getMasked(resumeData?.emails[0]?.text)}
                                        </div>
                                    </div>
                                )}
                                {resumeData && resumeData?.phones && resumeData?.phones?.length > 0 && resumeData?.phones[0]?.text != "" && (
                                    <div className="row pb-2">
                                        <div className="col-5 text-start">
                                            <Trans i18nKey="phoneNumber" defaults="Phone number" />
                                        </div>
                                        <span className="col-1">{":"}</span>
                                        <div className="col-5 text-start px-0">
                                            {getMasked(resumeData?.phones[0]?.text)}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    )
                }
            </Modal.Body>
            <Modal.Footer>
                <Button
                    className="float-start"
                    size="sm"
                    variant="secondary"
                    onClick={() => setOpenLinkProfile(false)}
                >
                    <Trans i18nKey="cancel" defaults="Cancel" />
                </Button>
                {resumeData && !(type === "showJob") && 
                <Button
                    size="sm"
                    variant="primary"
                    className="me-2"
                    onClick={() => handleButtonClick()}
                    disabled={linkProfileToCampaignLoading}
                >
                    {
                        linkProfileToCampaignLoading && (
                            <Spinner
                                animation="border"
                                size="sm"
                                className="me-1"
                            />
                        )
                    }
                    <Trans i18nKey="review" defaults="Preview Your Profile" />
                </Button>}
                <Button
                    size="sm"
                    variant="primary"
                    onClick={() => handleConfirm()}
                    disabled={linkProfileToCampaignLoading}
                >
                    {
                        linkProfileToCampaignLoading && (
                            <Spinner
                                animation="border"
                                size="sm"
                                className="me-1"
                            />
                        )
                    }
                    <Trans i18nKey="confirm" defaults="Confirm" />
                </Button>
            </Modal.Footer>
        </Modal>
    )
}