import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { Form } from 'react-bootstrap';
import { SelectPicker } from 'rsuite';
// import { useGetResumedbSchemaQuery } from '../../../services/resume/resumeDetailsService';
import { Trans } from 'react-i18next';
import { t } from 'i18next';
import usePost from '../../../customhooks/UsePost';

const MaritalStatusFormField = ({ maritalStatusData, control, getValues, errors, register, setValue,fieldList, type}) => {
    useEffect(() => {
        if (maritalStatusData) {
            setValue("maritalStatus", maritalStatusData)
        }
    }, [maritalStatusData])
    const [maritalStatusValues, setMaritalStatusValues] = useState([]);
    const [resumeSchema, setResumeSchema] = useState({})
    const {
        post: schema,
        isLoading,
        error: err,
      } = usePost("/user/getresumedbschema"); 
    
      useEffect(()=>{
        schema({get: "schema"}).then((res)=>{
            setResumeSchema(res?.result?.resumeSchema)
        }).catch((error)=>{
          console.log(error)
        })
      },[]);

    useEffect(() => {
        if (resumeSchema?.schema?.values?.maritalStatus) {
            setMaritalStatusValues(
                resumeSchema?.schema?.values?.maritalStatus.map(
                    item => ({ label: item, value: item })
                )
            )
        }
    }, [resumeSchema])


    return (
        <>
            <div className="form-group">
                <label className='text-dark'>
                {t(fieldList?.i18nKey, { defaultValue: fieldList?.fieldName })}
                </label>
                {fieldList?.required && <span className="text-danger">{"*"}</span>}
                <Controller
                    control={control}
                    name={"maritalStatus"}
                    refs={register("maritalStatus", {
                        required: fieldList?.required ? true : false,
                      })}
                    render={({ field: { onChange, onBlur, value } }) => (
                        <SelectPicker
                            className="w-100"
                            data={maritalStatusValues}
                            placeholder={t("enter", { defaultValue: "Enter" }) + " " + t("maritalStatus", { defaultValue: "Marital Status" })}
                            cleanable={false}
                            disabled={type=="customLayout" ||fieldList?.editable || !fieldList ? false : true}
                            onChange={onChange}
                            value={value}
                            placement="auto"
                        />
                    )}
                />
                {errors?.maritalStatus && (
        <span className="text-danger ">
          {errors?.maritalStatus?.message != "" ? (
            errors?.maritalStatus?.message
          ) : (
            <Trans
              i18nKey="maritalStatusIsRequired"
              defaults="Marital Status is required"
            />
          )}{" "}
        </span>
      )}
            </div>
        </>
    );
};

export default MaritalStatusFormField;