// import Image from "react-bootstrap/Image";
// import Navbar from "react-bootstrap/Navbar";

import { Image,Navbar } from "react-bootstrap";
import { Trans } from "react-i18next";
function NavFooter() {
  return (
    <div className="main-footer w-100">
      {/* <Container fluid> */}
      <div className="row w-100 d-flex mt-5 justify-content-center">
        <div className="col-1"></div>
        <div className="col-6 col-md-4 col-lg-2">
          <Image
            src="https://recruiterqa.maprecruit.com/images/coarthatechnosolutionsIN/logos/552d016291bad07ccbb455fe_logo_MapRecruitLogo-N-3.png"
            // className="w-100"
            style={{ maxHeight: "25px" }}
          />
          <div className="text-primary py-2">
            <p>
              {/* Powering Frictionless Recruiting */}
              <Trans  i18nKey="poweringFrictionlessRecruiting" defaults="Powering Frictionless Recruiting" /> 
            </p>
          </div>
          <div className="text-primary">
            <i className="fa-brands fa-linkedin"></i>
            {/* <FontAwesomeIcon icon={faLinkedin}  /> */}
          </div>
        </div>
        {/* <div className="col-4"></div> */}
        
        <div className="col-6 col-md-4 col-lg-2 ">
          <p className=""><strong><Trans i18nKey="platform" defaults="PLATFORM" /></strong></p>
          <p className=""  style={{ color: "#869ab8"}}><a href="https://www.maprecruit.ai/SourceAI">Source AI</a></p>
          <p className=""  style={{ color: "#869ab8"}}><a href="https://www.maprecruit.ai/MatchAI">Match AI</a></p>
          <p className=""  style={{ color: "#869ab8"}}><a href="https://www.maprecruit.ai/EngageAI">Engage AI</a></p>
          <p className=""  style={{ color: "#869ab8"}}><a href="https://www.maprecruit.ai/QualifyAI">Qualify AI</a></p>
        </div>
        <div className="col-6 col-md-4 col-lg-2 ">
          <p className=""><strong><Trans i18nKey="intelligence" defaults="INTELLIGENCE" /></strong></p>
          <p className=""  style={{ color: "#869ab8"}}><a href="https://www.maprecruit.ai/parsers">Parsers</a></p>
          <p className=""  style={{ color: "#869ab8"}}><a href="https://www.maprecruit.ai/Match-Rank-Index">Match & Rank Index</a></p>
          <p className=""  style={{ color: "#869ab8"}}><a href="https://www.maprecruit.ai/Emotional-Quotient">Emotional Quotient</a></p>
          <p className=""  style={{ color: "#869ab8"}}><a href="https://www.maprecruit.ai/chatai">Chat AI</a></p>
        </div>
        <div className="col-6 col-md-4 col-lg-2 ">
          <p className=""><strong><Trans i18nKey="Resources" defaults="RESOURCES" /></strong></p>
          <p className=""  style={{ color: "#869ab8"}}><a href="https://www.maprecruit.ai/newsroom">Newsroom</a></p>
          <p className=""  style={{ color: "#869ab8"}}><a href="https://www.maprecruit.ai/downloads">Downloads</a></p>
          <p className=""  style={{ color: "#869ab8"}}><a href="https://www.maprecruit.ai/careers">Careers</a></p>
          <p className=""  style={{ color: "#869ab8"}}><a href="https://www.maprecruit.ai/documentation-api">API</a></p>
          <p className=""  style={{ color: "#869ab8"}}><a href="https://www.maprecruit.ai/Contact-Us">Contact Us</a></p>
        </div>
      </div>
      <div className="text-center mt-4">
        <p style={{ color: "" }}>© Copyright All Rights Reserved 2024 MapRecruit Inc.</p>
        <p>
          <a href="https://www.maprecruit.ai/subscription-agreement" style={{ color: "#007bff" }}>Terms and Conditions</a>
        </p>
      </div>
      {/* </Container> */}
    </div>
  );
}

export default NavFooter;
