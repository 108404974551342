import React, { useEffect, useState } from "react";
import "./SearchBarStyles.css";
import { TagInput, TagPicker } from "rsuite";
import usePost from "../../../customhooks/UsePost";
import SpinnerIcon from "@rsuite/icons/legacy/Spinner";
import { Trans, useTranslation } from "react-i18next";
// import { t } from "i18next";

export default function SearchKey({ values, setValues, SearchBodyData }) {
  const [suggestions, setSuggestions] = React.useState([]);
  const { t } = useTranslation();
  const {
    post: getJobs,
    isLoading: loading,
    data,
    error,
  } = usePost("/jobsearch/autosuggestions");
  const [words, setWords] = useState("");
  // console.log(words);
  const fetchdata = (word) => {
    setWords(word);

    let body = {
      searchkey: word ?? "",
    };

    getJobs(body).then((res) => {
      const UpdateDate = res?.data?.result?.map((x) => {
        return { label: x, value: x };
      });

      setSuggestions(UpdateDate);

      // console.log(res?.data?.result);
    });
  };
  const [cacheData, setCacheData] = React.useState([]);

  const handleSelect = (value, item, event) => {
    setCacheData([...cacheData, item]);
  };
  // console.log(SearchBodyData);
  useEffect(() => {
    const UpdateDate = SearchBodyData?.searchKey?.map((x) => {
      return { label: x, value: x };
    });
    setSuggestions(UpdateDate);
    // const updatedValues = Array.isArray(values) ? values : [];
    // setValues(UpdateDate);
  }, [SearchBodyData]);

  const handleChange = (newValues) => {
    // console.log(newValues);
    let updatedValues;
    if (Array.isArray(newValues)) {
      updatedValues = newValues;
    } else if (newValues) {
      updatedValues = [newValues];
    } else {
      updatedValues = [];
    }
    setValues(updatedValues);
  };
  return (
    <>
      <div className="search-bar-container">
        <TagPicker
          creatable
          className="width no-border d-block"
          onCreate={(value, item) => {
            // console.log(value, item);
          }}
          data={suggestions ?? []}
          cacheData={cacheData}
          value={values}
          labelKey="label"
          valueKey="value"
          onChange={handleChange}
          onSearch={fetchdata}
          onSelect={handleSelect}
          block
          //  placeholder="Enter skill, designations, keywords"
          placeholder={t("enterSkills,Designations,Keywords", { defaultValue: "Enter Skills, Designations,  Keywords" })}
        //  placeholder={t("enter", { defaultValue: "Enter" }) + t("skill", { defaultValue: "Skill" }) + 
        //               t("designations", { defaultValue: "designations" }) + t("keywords", { defaultValue: "Keywords" })}
          renderMenu={(menu) => {
            if (loading) {
              return (
                <p style={{ padding: 4, color: "#999", textAlign: "center" }}>
                  <SpinnerIcon spin /> Loading...
                </p>
              );
            }
            if (words === "" || words == undefined) {
              return (
                <p className="pt-1 px-2 text-muted">
                  {/* Enter Skills, Designations, Keywords */}
                  <Trans i18nKey="enterSkills,Designations,Keywords" defaults="Enter Skills, Designations,  Keywords" />
                </p>
              );
            }
            return menu;
          }}
        />
      </div>
    </>
  );
}
