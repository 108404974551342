import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import { Form } from "react-bootstrap";
import { Trans } from "react-i18next";
import { t } from "i18next";

const MinExpectedSalaryFormField = ({
  minExpectedSalaryData,
  control,
  register,
  getValues,
  setValue,
  errors,
  fieldList,
  type
}) => {
  useEffect(() => {
    if (minExpectedSalaryData) {
      setValue("minimumExpectedSalary", minExpectedSalaryData);
    } else {
      setValue("minimumExpectedSalary", 0);
    }
  }, [minExpectedSalaryData]);
  // console.log(errors, "djvvnowjenowen")
  return (
    <div className="row w-100">
      <div className="col-12 ">
        <label className="text-dark">
          {fieldList != undefined ? (
            t(fieldList?.i18nKey, { defaultValue: fieldList?.fieldName })
          ) : (
            <Trans
              i18nKey="minimumExpectedSalary"
              defaults="Minimum Expected Salary"
            />
          )}
        </label>
        {fieldList?.required && <span className="text-danger">{"*"}</span>}
        <Controller
          control={control}
          name="minimumExpectedSalary"
          refs={register("minimumExpectedSalary", {
            required: fieldList?.required ? true : false,
          })}
          render={({ field: { onChange, onBlur, value } }) => (
            <input
              type="number"
              className="form-control form-control-sm"
              placeholder={
                t("enter", { defaultValue: "Enter" }) +
                " " +
                t("minimumExpectedSalary", {
                  defaultValue: "Minimum Expected Salary",
                })
              }
              onChange={onChange}
              disabled={
                type == "customLayout" || fieldList?.editable || !fieldList
                  ? false
                  : true
              }
              value={value}
              // disabled={(fieldList?.editable || !fieldList) ? false : true}
            />
          )}
        />
      </div>
      {errors?.minimumExpectedSalary && (
        <span className="text-danger ">
          {errors?.minimumExpectedSalary?.message != "" ? (
            errors?.minimumExpectedSalary?.message
          ) : (
            <Trans
              i18nKey="minimumExpectedSalaryIsRequired"
              defaults="Minimum Expected Salary is required"
            />
          )}{" "}
        </span>
      )}
    </div>
  );
};
// MinExpectedSalaryFormField.propTypes = {
//     minExpectedSalaryData: PropTypes.string.isRequired,
//     register: PropTypes.any.isRequired,
//     control: PropTypes.any.isRequired,
//     getValues: PropTypes.any.isRequired,
//     setValue: PropTypes.any.isRequired,
//     errors: PropTypes.any.isRequired,
// }
export default MinExpectedSalaryFormField;
