import React, { useContext, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBriefcase,
  faExternalLinkAlt,
} from "@fortawesome/free-solid-svg-icons";
import { Placeholder, Whisper, Tooltip } from "rsuite";
import { NavLink } from "react-router-dom";
import usePost from "../../customhooks/UsePost";
import UserContext from "../../app/context/UserContext";
import { DateTime } from "luxon";
import "./MyAccount.css";
import { Trans,useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";

const FavouriteJobs = () => {
  const { t } = useTranslation();
  const {
    post: favouriteJobs,
    data,
    isLoading: favouriteJobsLoading,
    error,
  } = usePost("/getfavouritejobs");
  const { user, setUserDetails } = useContext(UserContext);
  const favouriteJobsData = {};
  let res = {};
  const [favJobs, setFavJobs] = useState([]);
  useEffect(() => {
    favouriteJobsData["companyID"] = user?.companyID;
    favouriteJobsData["campaignIDs"] = user?.favoriteCampaignID;
    if (user?.companyID && user?.favoriteCampaignID) {
      favouriteJobs(favouriteJobsData)
        .then((res) => {
          // console.log(res?.data, "hsssssssssssdkk");
          if (res?.data?.status) {
            setFavJobs(res?.data?.result);
          }
        })
        .catch((error) => {
          console.error("Error while fetching favourite jobs:", error);
        });
    }
  }, [user]);

  function removeHtmlTags(html) {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent || "";
  }
  return (
    <>
      <div className="card py-2">
        <div className="card-header">
          <div className="row">
            <div className="col-6">
              <FontAwesomeIcon icon={faBriefcase} className="userIcon mx-2" />
              <span className="mt-3 basic-details">{/* Favorite Jobs */}
               <Trans i18nKey="favoriteJobs" defaults="Favorite Jobs " />
               </span>
            </div>
          </div>
        </div>
        {favouriteJobsLoading ? (
          <div className="card">
            <div className="card-body">
              <Placeholder.Grid rows={2} columns={2} active />
            </div>
          </div>
        ) : favJobs.length > 0 ? (
          <div className="row mt-3">
            {favJobs.map((data, index) => {
              const cleanedDescription = removeHtmlTags(
                data?._source?.job?.details?.jobDescription?.text || ""
              );
              return (
                <div key={uuidv4()} className="col-4 card p-0 pe-2 ps-2 ms-5 mb-2">
                  <div className="card-body">
                    <div className="row">
                      <h5 className=" text-capitalize titleColor">
                        {data?._source?.displayName ? (
                          <>
                            {data?._source?.displayName == "Campaign Title" ? (
                              <b>{data?._source?.title ?? "unkown"}</b>
                            ) : (
                              <>
                                {data?._source?.job.details?.jobTitle?.text ??
                                  "unkown"}
                              </>
                            )}
                          </>
                        ) : (
                          <b>{data?._source?.title ?? "unkown"}</b>
                        )}
                        {/* <b>
                          {data?._source?.title ? data._source.title : "NA"}
                        </b> */}
                        <span>
                          <Whisper
                            followCursor
                            placement={"bottom"}
                            speaker={
                              <Tooltip>{/* Redirect to Job Details Page */}
                                <Trans i18nKey="redirectToJobDetailsPage" defaults="Redirect to Job Details Page" />
                              </Tooltip>
                            }
                          >
                            <NavLink
                              target="_blank"
                              className="ps-2"
                              to={`/job/${data?._source?.passcode}`}
                            >
                              <FontAwesomeIcon
                                icon={faExternalLinkAlt}
                                className="smallIcon"
                              />
                            </NavLink>
                          </Whisper>
                        </span>
                      </h5>
                    </div>

                    <div className="my-2">
                      <i className="fa-solid fa-location-dot pe-2 text-muted"></i>
                      <span className="text-capitalize ">
                        {data?._source?.job?.details?.locations?.[0]?.text
                          ? data?._source?.job?.details?.locations?.[0]?.text
                          : "NA"}
                      </span>
                    </div>
                    <div className="my-2">
                      <i className="fa-solid fa-briefcase pe-2 text-muted"></i>
                      <span className="text-capitalize ">
                        {data?._source?.job?.details?.jobTitle?.jobType
                          ? data?._source?.job?.details?.jobTitle?.jobType
                          : "NA"}
                      </span>
                    </div>
                    <div className="my-2">
                      <i className="fa-solid fa-money-bills pe-2 text-muted"></i>
                      <span>
                        $
                        <span className="pe-1 ">
                          {data?._source?.job?.details?.offeredSalary
                            ?.minvalue ==
                          data?._source?.job?.details?.offeredSalary
                            ?.maxvalue ? (
                            <>
                              {data?._source?.job?.details?.offeredSalary
                                ?.minvalue ?? "0"}
                            </>
                          ) : (
                            <>
                              {data?._source?.job?.details?.offeredSalary
                                ?.minvalue
                                ? data?._source?.job?.details?.offeredSalary
                                    ?.minvalue
                                : "0"}
                              -
                              {data?._source?.job?.details?.offeredSalary
                                ?.maxvalue
                                ? data?._source?.job?.details?.offeredSalary
                                    ?.maxvalue
                                : "0"}
                            </>
                          )}
                        </span>
                        <span>
                          {data?._source?.job?.details?.offeredSalary?.period
                            ? data?._source?.job?.details?.offeredSalary?.period
                            : "NA"}
                        </span>
                      </span>
                    </div>
                    <div>
                      <p className="descriptionlines ">{cleanedDescription}</p>
                    </div>
                    <div>
                      <div>
                      <span>{t("posted", { defaultValue: "Posted" })}</span>
                        {data?._source?.createdAt ? (
                          <span className="ps-1">
                            {DateTime.fromJSDate(
                              new Date(data?._source?.createdAt)
                            ).toRelative()}
                          </span>
                        ) : (
                          " NA"
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <div className="d-flex justify-content-center pt-5">
            <h1>{/* No Favorite Jobs Found */}
            <Trans i18nKey="noFavoriteJobsFound" defaults="No Favorite Jobs Found" />
            </h1>
          </div>
        )}
      </div>
    </>
  );
};

export default FavouriteJobs;
