import React, { useEffect, useState,useContext } from "react";
import { Avatar, Loader, Tag, TagGroup } from "rsuite";
import CreateManualProfileMain from "./CareersEditProfile";
import usePost from "../../../customhooks/UsePost";
import { useForm } from "react-hook-form";
import { DateTime } from "luxon";
import { useTranslation, Trans } from "react-i18next";
import UserContext from "../../../app/context/UserContext";
function CareersEditResume({
  setProfileEditdetails,
  ProfileEditdetails,
  profiledata,
  setEditresumedetails,
  setMainmenudetails,
  JobDetails,
  Editresumedetails,
  setProfiledata,
}) {
  const { t } = useTranslation();
  // console.log(JobDetails);
  function progressbar(data, type) {
    if (data) {
      // console.log(data);
      try {
        var graphperc = 0;
        let mentionedYrs =
          profiledata?.Resumedetails?.resume?.professionalSummary
            ?.yearsOfExperience?.mentionedYears;
        let derivedYrs =
          profiledata?.Resumedetails?.resume?.professionalSummary
            ?.yearsOfExperience?.derivedYears;
        // console.log(data?.yearsOfExperience);
        if (derivedYrs > 0) {
          if ((data?.yearsOfExperience * 100) / derivedYrs > 100) {
            graphperc = 100;
          } else {
            graphperc = ((data?.yearsOfExperience * 100) / derivedYrs).toFixed(
              2
            );
          }
        }
        if (mentionedYrs > 0) {
          if ((data?.yearsOfExperience * 100) / mentionedYrs > 100) {
            graphperc = 100;
          } else {
            graphperc = (
              (data?.yearsOfExperience * 100) /
              mentionedYrs
            ).toFixed(2);
          }
        }

        let grapghclass = "";
        if (graphperc > 75) {
          grapghclass = "bg-success";
        } else if ((graphperc > 50) & (graphperc <= 75)) {
          grapghclass = "bg-info";
        } else if ((graphperc > 25) & (graphperc <= 50)) {
          grapghclass = "bg-warning";
        } else {
          grapghclass = "bg-danger";
        }
        if (type == "color") {
          return grapghclass;
        }
        if (type == "percentage") {
          return graphperc.toString() + "%";
        }
        if (type == "percentagetext") {
          return graphperc.toString();
        }
      } catch (error) {
        return "";
      }
    } else {
      return "";
    }
  }

  const {
    register,
    control,
    getValues,
    setValue,
    watch,
    clearErrors,
    formState: { errors },
    setError,
    handleSubmit,
    required,
  } = useForm();

  const {
    post: UpdateResumes,
    data: UpdateResumesData,
    isLoading: UpdateResumesLoading,
    error: UpdateResumesError,
  } = usePost("/user/Updateresumedeatails");

  const { user, setUserDetails } = useContext(UserContext);
  console.log(profiledata, "profiledataprofiledataprofiledata");

  const [updatedData, setUpdatedData] = useState(profiledata);
  const [role, setRole] = useState("");

  useEffect(() => {
    setUpdatedData(profiledata);
  }, [profiledata]);

  const handleUpdateValues = () => {
    const formData = getValues();

    console.log(formData, "formDataformData");

    const newData = { ...profiledata };

    if (formData?.firstName) {
      newData.Resumedetails.resume.profile.firstName = formData?.firstName;
    }
    if (formData?.middleName) {
      newData.Resumedetails.resume.profile.middleName = formData?.middleName;
    }
    if (formData?.lastName) {
      newData.Resumedetails.resume.profile.lastName = formData?.lastName;
    }
    
    if (formData?.emails) {
      newData.Resumedetails.resume.profile.emails = [];
      formData?.emails?.map((emails,i)=>{
        if(emails?.email == "" && i == 0){
          newData.Resumedetails.resume.profile.emails = [];
        }else if(emails?.email != ""){
          const newEmail = {
            phone: emails?.email,
            text: emails?.email
          };
          newData.Resumedetails.resume.profile.emails.push(newEmail);
        }
      })
    }

    if (formData?.phones) {
      newData.Resumedetails.resume.profile.phones = [];
      formData?.phones?.map((phones,i)=>{
        if(phones?.phone == "" && i == 0){
          newData.Resumedetails.resume.profile.phones = [];
        }else if(phones?.phone != ""){
          const newMobile = {
            phone: phones?.phone,
            text: phones?.phone
          };
          newData.Resumedetails.resume.profile.phones.push(newMobile);
        }
      })
    }else{
      newData.Resumedetails.resume.profile.phones = [];
    }

    if (newData.Resumedetails.resume.profile.locations?.length == 0) {
      if (formData?.profileCity && formData?.profileCountry) {
        newData.Resumedetails.resume.profile.locations.push({
          city: formData.profileCity,
          country: formData.profileCountry,
          postalCode: formData?.profilePostalCode,
          address: formData?.profileLocation,
          state: formData?.profileState,
          text: `${
            formData?.profileCity !== "" && `${formData?.profileCity} ,`
          }${formData?.profileState !== "" && `${formData.profileState},`} ${
            formData?.profileCountry !== "" && `${formData?.profileCountry},`
          } ${
            formData?.profilePostalCode !== "" &&
            `${formData?.profilePostalCode}`
          } `,
        });
      }
    } else {
      if (formData?.profileCity) {
        newData.Resumedetails.resume.profile.locations[0].city =
          formData?.profileCity;
      }
      if (formData?.profileCountry) {
        newData.Resumedetails.resume.profile.locations[0].country =
          formData?.profileCountry;
      }
      if (formData?.profilePostalCode) {
        newData.Resumedetails.resume.profile.locations[0].postalCode =
          formData?.profilePostalCode;
      }
      if (formData?.profileLocation) {
        newData.Resumedetails.resume.profile.locations[0].address =
          formData?.profileLocation;
        newData.Resumedetails.resume.profile.locations[0].text =
          formData?.profileLocation;
      }
      if (formData?.profileState) {
        newData.Resumedetails.resume.profile.locations[0].state =
          formData?.profileState;
      }
      if (formData) {
        newData.Resumedetails.resume.profile.locations[0].text = `${
          formData?.profileCity !== "" && `${formData?.profileCity} ,`
        }${formData?.profileState !== "" && `${formData.profileState},`} ${
          formData?.profileCountry !== "" && `${formData?.profileCountry},`
        } ${
          formData?.profilePostalCode !== "" && `${formData?.profilePostalCode}`
        } `;
        // formData?.profileCity ??
        // "" + ", " + formData?.profileState ??
        // "" + ", " + formData?.profileCountry ??
        // "" + ", " + formData?.profilePostalCode ??
        // "";
      }
    }

    if (formData?.Objective) {
      newData.Resumedetails.resume.professionalSummary.objective =
        formData?.Objective;
    }
    if (formData?.Summary) {
      newData.Resumedetails.resume.professionalSummary.summary =
        formData?.Summary;
    } else{
      newData.Resumedetails.resume.professionalSummary.summary = "" 
    }
    // if (formData?.skills) {
    //   for (let i in formData?.skills) {
    //     if (
    //       newData.Resumedetails.resume.professionalQualification.skills &&
    //       newData.Resumedetails.resume.professionalQualification.skills[i]
    //     ) {
    //       newData.Resumedetails.resume.professionalQualification.skills[
    //         i
    //       ].text = formData?.skills?.[i]?.skills;
    //       newData.Resumedetails.resume.professionalQualification.skills[
    //         i
    //       ].yearsOfExperience = formData?.skills?.[i]?.yearsOfExperience;
    //     }
    //   }
    // }
    if (formData?.skills?.length > 0) {
      for (let i in formData?.skills) {
        if (
          newData.Resumedetails.resume.professionalQualification.skills &&
          newData.Resumedetails.resume.professionalQualification.skills[i]
        ) {
          newData.Resumedetails.resume.professionalQualification.skills[
            i
          ].text = formData?.skills?.[i]?.skills;
          newData.Resumedetails.resume.professionalQualification.skills[
            i
          ].yearsOfExperience = formData?.skills?.[i]?.yearsOfExperience;
        } else {
          const newSkill = {
            text: formData?.skills?.[i]?.skills,
            yearsOfExperience: formData?.skills?.[i]?.yearsOfExperience,
          };

          if (!newData.Resumedetails.resume.professionalQualification.skills) {
            newData.Resumedetails.resume.professionalQualification.skills = [];
          }

          newData.Resumedetails.resume.professionalQualification.skills.push(
            newSkill
          );
        }
      }
    }else{
      newData.Resumedetails.resume.professionalQualification.skills = [];
    }

    if (
      formData?.education &&
      newData.Resumedetails.resume.professionalQualification.education
    ) {
      if (formData?.education?.length > 0) {
        for (let k in formData.education) {
          if (
            newData.Resumedetails.resume.professionalQualification.education[
              k
            ] &&
            typeof newData.Resumedetails.resume.professionalQualification
              .education[k] === "object"
          ) {
            if (
              !newData.Resumedetails.resume.professionalQualification.education[
                k
              ].merit
            ) {
              newData.Resumedetails.resume.professionalQualification.education[
                k
              ].merit = {};
            }

            if (
              !newData.Resumedetails.resume.professionalQualification.education[
                k
              ].currentStatus
            ) {
              newData.Resumedetails.resume.professionalQualification.education[
                k
              ].currentStatus = "";
            }

            if (
              !newData.Resumedetails.resume.professionalQualification.education[
                k
              ].startDate
            ) {
              newData.Resumedetails.resume.professionalQualification.education[
                k
              ].startDate = {};
            }

            if (
              !newData.Resumedetails.resume.professionalQualification.education[
                k
              ].endDate
            ) {
              newData.Resumedetails.resume.professionalQualification.education[
                k
              ].endDate = {};
            }

            if (formData.education[k]?.campus) {
              newData.Resumedetails.resume.professionalQualification.education[
                k
              ].campus.text = formData.education[k].campus;
            }

            if (formData.education[k]?.university) {
              newData.Resumedetails.resume.professionalQualification.education[
                k
              ].university.text = formData.education[k].university;
            }

            if (formData?.education[k]?.degree) {
              newData.Resumedetails.resume.professionalQualification.education[
                k
              ].degree.text = formData?.education[k]?.degree;
            }

            if (
              formData?.education[k]?.major &&
              formData?.education[k]?.major.length > 0
            ) {
              const majorList = [];
              for (let j in formData?.education[k]?.major) {
                const majorSchema = {
                  text: formData?.education[k]?.major?.[j],
                };
                majorList.push(majorSchema);
              }
              newData.Resumedetails.resume.professionalQualification.education[
                k
              ].major = majorList;
            }else{
              newData.Resumedetails.resume.professionalQualification.education[
                k
              ].major = [];
            }

            if (
              formData?.education[k]?.minor &&
              formData?.education[k]?.minor.length > 0
            ) {
              const minorList = [];
              for (let m in formData?.education[k]?.minor) {
                const minorSchema = {
                  text: formData.education?.[k]?.minor?.[m],
                };
                minorList.push(minorSchema);
              }
              newData.Resumedetails.resume.professionalQualification.education[
                k
              ].minor = minorList;
            }
            if (
              formData.education[k]?.meritType &&
              formData.education[k]?.meritGPA !== ""
            ) {
              if (formData.education[k].meritType === "GPA") {
                newData.Resumedetails.resume.professionalQualification.education[
                  k
                ].merit.GPA = parseFloat(formData.education[k].meritGPA);
              } else if (formData.education[k].meritType === "percentage") {
                newData.Resumedetails.resume.professionalQualification.education[
                  k
                ].merit.GPA = "";
                newData.Resumedetails.resume.professionalQualification.education[
                  k
                ].merit.percentage = parseFloat(formData.education[k].meritGPA);
              } else {
                newData.Resumedetails.resume.professionalQualification.education[
                  k
                ].merit.GPA = "";
                newData.Resumedetails.resume.professionalQualification.education[
                  k
                ].merit.percentage = "";
              }
            } else {
              newData.Resumedetails.resume.professionalQualification.education[
                k
              ].merit.GPA = "";
              newData.Resumedetails.resume.professionalQualification.education[
                k
              ].merit.percentage = "";
            }
            if (formData?.education[k]?.location) {
              newData.Resumedetails.resume.professionalQualification.education[
                k
              ].location.text = formData?.education?.[k]?.location;
            }

            if (
              formData?.education?.[k]?.startDate &&
              formData?.education?.[k]?.startDate !== ""
            ) {
              const date = new Date(formData?.education?.[k]?.startDate);
              const year = date.getUTCFullYear();
              const month = `0${date.getUTCMonth() + 1}`.slice(-2);
              const day = `0${date.getUTCDate()}`.slice(-2);
              const hours = `0${date.getUTCHours()}`.slice(-2);
              const minutes = `0${date.getUTCMinutes()}`.slice(-2);
              const seconds = `0${date.getUTCSeconds()}`.slice(-2);
              const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.000Z`;

              newData.Resumedetails.resume.professionalQualification.education[
                k
              ].startDate.standard = formattedDate;
              newData.Resumedetails.resume.professionalQualification.education[
                k
              ].startDate.text = formattedDate;
            }

            if (
              formData?.education?.[k]?.endDate &&
              formData?.education?.[k]?.endDate !== ""
            ) {
              const date = new Date(formData?.education?.[k]?.endDate);
              const year = date.getUTCFullYear();
              const month = `0${date.getUTCMonth() + 1}`.slice(-2);
              const day = `0${date.getUTCDate()}`.slice(-2);
              const hours = `0${date.getUTCHours()}`.slice(-2);
              const minutes = `0${date.getUTCMinutes()}`.slice(-2);
              const seconds = `0${date.getUTCSeconds()}`.slice(-2);
              const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.000Z`;

              newData.Resumedetails.resume.professionalQualification.education[
                k
              ].endDate.standard = formattedDate;
              newData.Resumedetails.resume.professionalQualification.education[
                k
              ].endDate.text = formattedDate;
            }
          } else {
            const newEducationEntry = {
              campus: { text: formData.education[k]?.campus || "" },
              university: { text: formData.education[k]?.university || "" },
              degree: { text: formData?.education[k]?.degree || "" },
              major: [],
              minor: [],
              location: { text: formData?.education?.[k]?.location || "" },
              merit: {},
              currentStatus: formData.education[k]?.status || "",
              startDate: {},
              endDate: {},
            };

            if (
              formData?.education[k]?.major &&
              formData?.education[k]?.major.length > 0
            ) {
              for (let j in formData?.education[k]?.major) {
                const majorSchema = {
                  text: formData?.education[k]?.major?.[j],
                };
                newEducationEntry.major.push(majorSchema);
              }
            }

            if (
              formData?.education[k]?.minor &&
              formData?.education[k]?.minor.length > 0
            ) {
              for (let m in formData?.education[k]?.minor) {
                const minorSchema = {
                  text: formData.education?.[k]?.minor?.[m],
                };
                newEducationEntry.minor.push(minorSchema);
              }
            }

            if (
              formData?.education?.[k]?.startDate &&
              formData?.education?.[k]?.startDate !== ""
            ) {
              const date = new Date(formData?.education?.[k]?.startDate);
              const year = date.getUTCFullYear();
              const month = `0${date.getUTCMonth() + 1}`.slice(-2);
              const day = `0${date.getUTCDate()}`.slice(-2);
              const hours = `0${date.getUTCHours()}`.slice(-2);
              const minutes = `0${date.getUTCMinutes()}`.slice(-2);
              const seconds = `0${date.getUTCSeconds()}`.slice(-2);
              const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.000Z`;

              newEducationEntry.startDate.standard = formattedDate;
              newEducationEntry.startDate.text = formattedDate;
            } else {
              newEducationEntry.startDate = {
                text: "",
                standard: "",
                year: "",
                month: "",
                date: "",
              };
            }

            if (
              formData?.education?.[k]?.endDate &&
              formData?.education?.[k]?.endDate !== ""
            ) {
              const date = new Date(formData?.education?.[k]?.endDate);
              const year = date.getUTCFullYear();
              const month = `0${date.getUTCMonth() + 1}`.slice(-2);
              const day = `0${date.getUTCDate()}`.slice(-2);
              const hours = `0${date.getUTCHours()}`.slice(-2);
              const minutes = `0${date.getUTCMinutes()}`.slice(-2);
              const seconds = `0${date.getUTCSeconds()}`.slice(-2);
              const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.000Z`;

              newEducationEntry.endDate.standard = formattedDate;
              newEducationEntry.endDate.text = formattedDate;
            } else {
              newEducationEntry.endDate = {
                text: "",
                standard: "",
                year: "",
                month: "",
                date: "",
              };
            }
            if (
              formData?.education[k]?.meritType &&
              formData?.education[k]?.meritGPA !== ""
            ) {
              if (formData.education[k].meritType === "GPA") {
                newEducationEntry.merit.GPA = parseFloat(
                  formData.education[k].meritGPA
                );
              } else if (formData.education[k].meritType === "percentage") {
                newEducationEntry.merit.GPA = "";
                newEducationEntry.merit.percentage = parseFloat(
                  formData.education[k].meritGPA
                );
              } else {
                newEducationEntry.merit.GPA = "";
                newEducationEntry.merit.percentage = "";
              }
            } else {
              newEducationEntry.merit.GPA = "";
              newEducationEntry.merit.percentage = "";
            }

            newData.Resumedetails.resume.professionalQualification.education.push(
              newEducationEntry
            );
          }
        }
      } else {
        newData.Resumedetails.resume.professionalQualification.education = [];
      }
    }
console.log(formData?.experience,"gdmoooooooooooooooooooooj")
    if (formData?.experience) {
      const professionalExperience = [];
      let flag = false;
      formData?.experience?.map((experience)=>{
        if(experience?.status){
          flag = true;
          setRole(experience?.jobTitle);
        }
      })
      if(!flag){
        setRole("")
      }

      for (let i in formData?.experience) {
        const newExperience = {
          company: { text: formData?.experience?.[i]?.company },
          jobTitle: { text: formData?.experience?.[i]?.jobTitle },
          location: { text: formData?.experience?.[i]?.location },
          description: formData?.experience?.[i]?.description,
          startDate: {},
          endDate: {},
          currentStatus: "",
          skills: [],
        };

        // Add skills
        if (formData?.experience?.[i]?.skills) {
          for (let j in formData?.experience?.[i]?.skills) {
            const skill = {
              text: formData.experience[i].skills[j].text,
              yearsOfExperience:
                formData.experience[i].skills[j].yearsOfExperience,
            };
            newExperience.skills.push(skill);
          }
        }

        // Add start date
        if (
          formData?.experience?.[i]?.startDate &&
          formData?.experience?.[i]?.startDate !== "Invalid Time" &&
          formData?.experience?.[i]?.startDate !== "Invalid Date"
        ) {
          const startDate = new Date(formData?.experience?.[i]?.startDate);
          const startYear = startDate.getUTCFullYear();
          const startMonth = `0${startDate.getUTCMonth() + 1}`.slice(-2);
          const startDay = `0${startDate.getUTCDate()}`.slice(-2);
          const startHours = `0${startDate.getUTCHours()}`.slice(-2);
          const startMinutes = `0${startDate.getUTCMinutes()}`.slice(-2);
          const startSeconds = `0${startDate.getUTCSeconds()}`.slice(-2);
          const formattedStartDate = `${startYear}-${startMonth}-${startDay}T${startHours}:${startMinutes}:${startSeconds}.000Z`;

          newExperience.startDate.standard = formattedStartDate;
          newExperience.startDate.text = formattedStartDate;
        } else {
          newExperience.startDate = {
            text: "",
            standard: "",
            year: "",
            month: "",
            date: "",
          };
        }
        if (formData?.experience?.[i]?.status) {
          newExperience.currentStatus = "Working";
          newExperience.endDate.text = "Present";
          // if(role == ""){
            // setRole(formData?.experience?.[i]?.jobTitle)
          // }
        } else {
          if (
            formData?.experience?.[i]?.endDate &&
            formData?.experience?.[i]?.endDate !== "Invalid Time" &&
            formData?.experience?.[i]?.endDate !== "Invalid Date"
          ) {
            const endDate = new Date(formData?.experience?.[i]?.endDate);
            const endYear = endDate.getUTCFullYear();
            const endMonth = `0${endDate.getUTCMonth() + 1}`.slice(-2);
            const endDay = `0${endDate.getUTCDate()}`.slice(-2);
            const endHours = `0${endDate.getUTCHours()}`.slice(-2);
            const endMinutes = `0${endDate.getUTCMinutes()}`.slice(-2);
            const endSeconds = `0${endDate.getUTCSeconds()}`.slice(-2);
            const formattedEndDate = `${endYear}-${endMonth}-${endDay}T${endHours}:${endMinutes}:${endSeconds}.000Z`;

            newExperience.endDate.standard = formattedEndDate;
            newExperience.endDate.text = formattedEndDate;
          } else {
            newExperience.endDate = {
              text: "",
              standard: "",
              year: "",
              month: "",
              date: "",
            };
          }
        }

        professionalExperience.push(newExperience);
      }

      const isAllEmpty = professionalExperience.every(
        (exp) =>
          !exp.company.text &&
          !exp.jobTitle.text &&
          !exp.location.text &&
          !exp.description &&
          exp.skills.length === 0 &&
          Object.values(exp.startDate).every((value) => value === "") &&
          Object.values(exp.endDate).every((value) => value === "") &&
          !exp.currentlyWorking
      );

      newData.Resumedetails.resume.professionalExperience = isAllEmpty
        ? []
        : professionalExperience;
    }

    if (formData?.noticePeriod) {
      newData.Resumedetails.resume.professionalSummary.noticePeriod.days =
        formData?.noticePeriod;
      newData.Resumedetails.resume.professionalSummary.noticePeriod.text =
        formData?.noticePeriod + " " + "days";
    }

    if (formData?.currentSalary) {
      newData.Resumedetails.resume.professionalSummary.currentSalary =
        typeof newData.Resumedetails.resume.professionalSummary
          .currentSalary === "object"
          ? newData.Resumedetails.resume.professionalSummary.currentSalary
          : {};

      newData.Resumedetails.resume.professionalSummary.currentSalary.value =
        formData?.currentSalary;
    }
    if (formData?.salaryPeriod) {
      newData.Resumedetails.resume.professionalSummary.currentSalary =
        typeof newData.Resumedetails.resume.professionalSummary
          .currentSalary === "object"
          ? newData.Resumedetails.resume.professionalSummary.currentSalary
          : {};

      newData.Resumedetails.resume.professionalSummary.currentSalary.period =
        formData?.salaryPeriod;
    }
    if (formData?.salaryCurrency) {
      newData.Resumedetails.resume.professionalSummary.currentSalary =
        typeof newData.Resumedetails.resume.professionalSummary
          .currentSalary === "object"
          ? newData.Resumedetails.resume.professionalSummary.currentSalary
          : {};

      newData.Resumedetails.resume.professionalSummary.currentSalary.currency =
        formData?.salaryCurrency;
    }
    newData.Resumedetails.resume.professionalSummary.currentSalary.text =
      formData?.currentSalary
        ? formData?.currentSalary +
          " " +
          formData?.salaryCurrency +
          " " +
          formData?.salaryPeriod
        : "";
    if (formData?.minimumExpectedSalary) {
      newData.Resumedetails.resume.professionalSummary.expectedSalary.minvalue =
        formData?.minimumExpectedSalary;
    }
    if (formData?.compensationCurrency) {
      newData.Resumedetails.resume.professionalSummary.expectedSalary =
        typeof newData.Resumedetails.resume.professionalSummary
          .expectedSalary === "object"
          ? newData.Resumedetails.resume.professionalSummary.expectedSalary
          : {};

      newData.Resumedetails.resume.professionalSummary.expectedSalary.currency =
        formData?.compensationCurrency;
    }
    if (formData?.compensationPeriod) {
      newData.Resumedetails.resume.professionalSummary.expectedSalary =
        typeof newData.Resumedetails.resume.professionalSummary
          .expectedSalary === "object"
          ? newData.Resumedetails.resume.professionalSummary.expectedSalary
          : {};

      newData.Resumedetails.resume.professionalSummary.expectedSalary.period =
        formData?.compensationPeriod;
    }
    if (formData?.maximumExpectedSalary) {
      newData.Resumedetails.resume.professionalSummary.expectedSalary.maxvalue =
        formData?.maximumExpectedSalary;
    }
    if (formData?.compensationCurrency) {
      newData.Resumedetails.resume.professionalSummary.expectedSalary.maxvalue =
        formData?.maximumExpectedSalary;
    }
    if (formData?.preferred) {
      newData.Resumedetails.resume.professionalSummary.preferredLocations = [];

      for (let v in formData?.preferred) {
        let preferredLocation = {
          text: formData?.preferred[v].city,
          city: formData?.preferred[v].city,
        };
        newData.Resumedetails.resume.professionalSummary.preferredLocations.push(
          preferredLocation
        );
      }
    }
    if (formData?.languagesKnown) {
      let newLanguagesKnown = [];
      for (let i in formData?.languagesKnown) {
        let languageKnown = {
          label: formData?.languagesKnown[i],
          language: formData?.languagesKnown[i],
          text: formData?.languagesKnown[i],
        };
        newLanguagesKnown.push(languageKnown);
      }

      newData.Resumedetails.resume.profile.languagesKnown = newLanguagesKnown;
    }

    // console.log(newData, "newdata");
    // setUpdatedData(newData)
    setProfiledata(newData);
  };

  const handleSumbiteditvalues = () => {
    console.log(getValues());

    UpdateResumes({
      resumeID: profiledata?.Resumedetails?._id,
      companyID: JobDetails?.companyID,
      clientID: JobDetails?.clientID,
      getValues: getValues(),
      user:user
    })
      .then((resp) => {
        if (resp?.status) {
          setMainmenudetails(true);
          setProfileEditdetails(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const [isSmallScreen, setIsSmallScreen] = useState(
    typeof window !== 'undefined' ? window.innerWidth < 768 : false
  );

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(typeof window !== 'undefined' ? window.innerWidth < 768 : false);
    };

    if (typeof window !== 'undefined') {
      window.addEventListener('resize', handleResize);

      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }
  }, []);



   return (
    <>
      {!Editresumedetails ? (
        <div>
          <h3 className="text-capitalize heading">
            <Trans i18nKey="submitYourProfile" defaults="Submit Your Profile" />
          </h3>
          <div  className={`row  mt-4 mb-3 ${isSmallScreen ? '' : 'mx-5'}`}>
            <div className="col-md-3 col-xs-12 text-center d-flex align-items-center justify-content-center">
              <Avatar size="lg" circle src="/assets/images/social.svg" />
            </div>

            <div  className="col-xs-12 col-md-8">
              <div className="text-primary text-capitalize mb-3" title={profiledata?.Resumedetails?.resume?.profile?.fullName}>
                {profiledata?.Resumedetails?.resume?.profile?.fullName}
              </div>
              <div className="row">
                <div className="col-xs-12 col-md-6">
                  {profiledata?.Resumedetails?.resume?.profile?.emails?.length >
                  0 ? (
                      <div
                        className="mb-3 d-flex align-items-center textColor2"
                        title={
                          profiledata?.Resumedetails?.resume?.profile?.emails[0]
                            ?.text
                        }
                      >
                        <i className="fa fa-envelope fa-2x pe-2 fonticon15"></i>
                        <div className="truncate-text">
                          {profiledata?.Resumedetails?.resume?.profile
                            ?.emails[0]?.text ??
                            t("notAvailable", {
                              defaultValue: "Not Available",
                            })}
                        </div>
                      </div>
                  ) : (
                    <div className="mb-3 d-flex align-items-center textColor2">
                      <i className="fa fa-envelope fa-2x pe-2 fonticon15"></i>
                      <Trans i18nKey="notAvailable" defaults="Not Available" />
                    </div>
                  )}
                  {profiledata?.Resumedetails?.resume?.profile?.phones?.length >
                  0 && profiledata?.Resumedetails?.resume?.profile?.phones[0]
                  ?.text ? (
                    <div
                      className="mb-3 d-flex align-items-center textColor2"
                      title={
                        profiledata?.Resumedetails?.resume?.profile?.phones[0]
                          ?.text
                      }
                    >
                      <i className="fa-solid fa-phone-flip fa-2x  pe-2 fonticon15"></i>
                      <div className="truncate-text">
                        {profiledata?.Resumedetails?.resume?.profile?.phones[0]
                          ?.text ?? "Not Available"}
                      </div>
                    </div>
                  ) : (
                    <div className="mb-3 d-flex align-items-center textColor2">
                      <i className="fa-solid fa-phone-flip fa-2x  pe-2 fonticon15"></i>
                      <Trans i18nKey="notAvailable" defaults="Not Available" />
                    </div>
                  )}
                </div>
                <div className="col-md-6 col-xs-12">
                  {profiledata?.Resumedetails?.resume?.profile?.locations
                    ?.length > 0 ? (
                    <div
                      className="mb-3 textColor2 d-flex align-items-center"
                      title={
                        profiledata?.Resumedetails?.resume?.profile
                          ?.locations[0]?.text?.replace(/\b\w/g, (char) => char?.toUpperCase())
                      }
                    >
                      <i className="fa-solid fa-location-dot  fa-1x  pe-2 fonticon15"></i>
                      {/* {profiledata?.Resumedetails?.resume?.profile?.locations[0]
                        ?.text ?? "Not Available"} */}
                      <div className="truncate-text">
                        {profiledata?.Resumedetails?.resume?.profile
                          ?.locations[0]?.text &&
                        profiledata?.Resumedetails?.resume?.profile
                          ?.locations[0]?.text !== ""
                          ? profiledata?.Resumedetails?.resume?.profile
                              ?.locations[0]?.text?.replace(/\b\w/g, (char) => char?.toUpperCase())
                          : t("notAvailable", {
                              defaultValue: "Not Available",
                            })}
                      </div>
                    </div>
                  ) : (
                    <div className="mb-3 textColor2 d-flex align-items-center">
                      <i className="fa-solid fa-location-dot  fa-1x  pe-2  fonticon15"></i>
                      <Trans i18nKey="notAvailable" defaults="Not Available" />
                    </div>
                  )}
                  <div
                    className="mb-3 textColor2 d-flex align-items-center"
                    title={
                      role
                    }
                  >
                    {/* <i className="fa-solid fa-briefcase"></i> */}
                    <i className="fa-solid fa-briefcase fa-1x  pe-2  fonticon15"></i>
                    <div className="truncate-text">
                      {role && role !== ""
                        ? role
                        : t("notAvailable", { defaultValue: "Not Available" })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-5">
            {/* <div className="col-12 mb-5">
              <span className="fonticon15 text-primary">
                <Trans i18nKey="objective" defaults="Objective" />
              </span>
              {profiledata?.Resumedetails?.resume?.professionalSummary
                ?.objective &&
              profiledata?.Resumedetails?.resume?.professionalSummary
                ?.objective != "" ? (
                <div className="mt-3">
                  {
                    profiledata?.Resumedetails?.resume?.professionalSummary
                      ?.objective
                  }
                </div>
              ) : (
                <div className="mt-3 text-center textColor2">
                  <Trans
                    i18nKey="noObjectiveDetails"
                    defaults="No Objective Details"
                  />
                </div>
              )}
            </div> */}
            <div className="col-12 mb-5">
              <span className="fonticon15 text-primary">
                <Trans i18nKey="summary" defaults="Summary" />
              </span>
              {profiledata?.Resumedetails?.resume?.professionalSummary
                ?.summary &&
              profiledata?.Resumedetails?.resume?.professionalSummary
                ?.summary != "" ? (
                <div className="mt-3">
                  {
                    profiledata?.Resumedetails?.resume?.professionalSummary
                      ?.summary?.split('\n').map((line, index) => (
                      <React.Fragment key={index}>
                        {line}
                        {index < profiledata?.Resumedetails?.resume?.professionalSummary
                          ?.summary?.split('\n').length - 1 && <br />}
                      </React.Fragment>
                    ))
                  }
                </div>
              ) : (
                <div className="mt-3 text-center textColor2">
                  <Trans
                    i18nKey="noSummaryDetails"
                    defaults="No Summary Details"
                  />
                </div>
              )}
            </div>

            <div className="col-12 mb-5">
              <span className="fonticon15 text-primary">
                <Trans i18nKey="skills" defaults="Skills" />
              </span>
              {profiledata?.Resumedetails?.resume?.professionalQualification
                ?.skills &&
              profiledata?.Resumedetails?.resume?.professionalQualification
                ?.skills?.length > 0 ? (
                <div  className={`mt-3 row ${isSmallScreen ? '' : 'mx-3'}`}>
                  {profiledata?.Resumedetails?.resume?.professionalQualification?.skills.map(
                    (x, index) => {
                      return (
                        <div
                          className="col-lg-6 col-md-6 col-sm-12 col-xs-12  p-2" 
                          key={index}
                        >
                          <div className="row me-2">
                            <div className=" col-lg-7 col-md-7 col-sm-7">
                              <span className="skillNameCSS">{x?.text}</span>
                            </div>
                            <div className="col-lg-2 col-md-2 col-sm-2">
                              <span className="textColor2">
                                {x?.yearsOfExperience}.0 yrs
                              </span>
                            </div>
                            <div
                              className="col-lg-3 col-md-3 col-sm-3 pad-left-5" 
                              style={{ display: "grid", alignItems: "center" }}
                            >
                              <div
                                className="progress"
                                style={{ height: "5px" }}
                              >
                                <div
                                  className={`progress-bar ${progressbar(
                                    x,
                                    "color"
                                  )}`}
                                  role="progressbar"
                                  style={{
                                    width: `${progressbar(x, "percentage")}`,
                                  }}
                                  aria-valuenow={`${progressbar(
                                    x,
                                    "percentagetext"
                                  )}`}
                                  aria-valuemin="0"
                                  aria-valuemax="100"
                                ></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    }
                  )}
                </div>
              ) : (
                <div className="mt-3 text-center textColor2">
                  <Trans
                    i18nKey="noSkillsDetails"
                    defaults="No Skills Details"
                  />
                </div>
              )}
            </div>
            <div className="col-12 mb-5">
              <span className="fonticon15 text-primary">
                <Trans i18nKey="experience" defaults="Experience" />
              </span>
              {profiledata?.Resumedetails?.resume?.professionalExperience
                .length > 0 ? (
                <>
                  {profiledata?.Resumedetails?.resume?.professionalExperience?.map(
                    (x, index) => {
                      return (
                        <div className="card mb-4 eachExpArea" key={index}>
                          <div className="card-body">
                            <div className="row mb-3">
                              <div className="col-xs-12 col-md-6">
                                <i className="fas fa-coins fa-1x  pe-2 text-primary"></i>
                                {x?.jobTitle?.text &&
                                x?.jobTitle?.text !== "" ? (
                                  x?.jobTitle?.text
                                ) : (
                                  <span className="textColor2">
                                    <Trans
                                      i18nKey="noJobTitleMentioned"
                                      defaults="No JobTitle Mentioned"
                                    />
                                  </span>
                                )}
                              </div>
                              <div className="col-xs-12 col-md-6">
                                <i className="fa-solid fa-calendar fa-1x  pe-2 text-primary"></i>
                                {x?.startDate?.text !== "" ||
                                x?.endDate?.text !== "" ? (
                                  <>
                                    {x?.startDate?.text &&
                                    x?.startDate?.text !== "" ? (
                                      <>{new Date(x?.startDate?.text).toISOString().split('T')[0]}</>
                                    ) : (
                                      "NA"
                                    )}{" "}
                                    -{" "}
                                    {x?.endDate?.text &&
                                    x?.endDate?.text !== "" ? (
                                      x?.endDate?.text == "Present" ?
                                      <>{x?.endDate?.text}</> :  new Date(x?.endDate?.text).toISOString().split('T')[0]
                                    ) : (
                                      "NA"
                                    )}
                                  </>
                                ) : (
                                  <span className="textColor2">
                                    <Trans
                                      i18nKey="noDatesMentioned"
                                      defaults="No Dates Mentioned"
                                    />
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="row mb-3">
                              <div className="col-xs-12 col-md-6">
                                <i className="fa-solid fa-building fa-1x  pe-2 text-primary"></i>
                                {x?.company?.text && x?.company?.text !== "" ? (
                                  x?.company?.text
                                ) : (
                                  <span className="textColor2">
                                    <Trans
                                      i18nKey="noCompanyMentioned"
                                      defaults="No company Mentioned"
                                    />
                                  </span>
                                )}
                              </div>
                              <div className="col-xs-12 col-md-6">
                                <i className="fa-solid fa-location-dot fa-1x  pe-2 text-primary"></i>
                                {x?.location?.text &&
                                x?.location?.text !== "" ? (
                                  x?.location?.text
                                ) : (
                                  <span className="textColor2">
                                    <Trans
                                      i18nKey="noLocationMentioned"
                                      defaults="No location Mentioned"
                                    />
                                  </span>
                                )}
                              </div>
                            </div>
                            {x?.description && x?.description !== "" && (
                              <div className="row mb-3">
                                <div className="col-12">
                                  {/* {x?.description} */}
                                  {x?.description?.split('\n').map((line, index) => (
                                    <React.Fragment key={index}>
                                      {line}
                                      {index < x?.description?.split('\n').length - 1 && <br />}
                                    </React.Fragment>
                                    ))
                                  }
                                </div>
                              </div>
                            )}
                            {x?.skills && x?.skills?.length > 0 && (
                              <div className="row mb-3">
                                <div className="col-12">
                                  <TagGroup>
                                    {x?.skills?.map((y, index) => {
                                      return (
                                        <Tag
                                          className="px-2 border border-primary bg-white rounded-pill"
                                          key={index}
                                        >
                                          {y?.text}
                                        </Tag>
                                      );
                                    })}
                                  </TagGroup>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      );
                    }
                  )}
                </>
              ) : (
                <div className="mt-3 text-center textColor2">
                  <Trans
                    i18nKey="noExperienceDetails"
                    defaults="No Experience Details"
                  />
                </div>
              )}
            </div>
            <div className="col-12 mb-5">
              <span className="fonticon15 text-primary">
                <Trans i18nKey="education" defaults="Education" />
              </span>
              {profiledata?.Resumedetails?.resume?.professionalQualification
                ?.education.length > 0 ? (
                <>
                  {profiledata?.Resumedetails?.resume?.professionalQualification?.education?.map(
                    (x, index) => {
                      return (
                        <div className={`card  eachExpArea  mb-4 `} key={index}>{/* m-4 */}
                          <div className="card-body">
                            <div className="row mb-3">
                              <div className="col-xs-12 col-md-6">
                                <i className="fa-solid fa-graduation-cap text-primary pe-2"></i>
                                {x?.degree?.text && x?.degree?.text !== "" ? (
                                  x?.degree?.text
                                ) : (
                                  <span className="textColor2">
                                    <Trans
                                      i18nKey="noDegreeMentioned"
                                      defaults="No degree Mentioned"
                                    />
                                  </span>
                                )}
                              </div>
                              <div className="col-xs-12 col-md-6">
                              <i className="fa-solid fa-calendar text-primary pe-2"></i>
                                {x?.startDate?.text !== "" &&
                                x?.endDate?.text !== "" ? (
                                  <>
                                    {x?.startDate?.text &&
                                    x?.startDate?.text !== "" ? (
                                      <>{new Date(x?.startDate?.text).toISOString().split('T')[0]}</>
                                    ) : (
                                      "NA"
                                    )}{" "}
                                    -{" "}
                                    {x?.endDate?.text &&
                                    x?.endDate?.text !== "" ? (
                                      <>{new Date(x?.endDate?.text).toISOString().split('T')[0]}</>
                                    ) : (
                                      "NA"
                                    )}
                                  </>
                                ) : (
                                  <span className="textColor2">
                                    <Trans
                                      i18nKey="noDatesMentioned"
                                      defaults="No Dates Mentioned"
                                    />
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="row mb-3">
                              <div className="col-xs-12 col-md-6">
                                <i className="fa-solid fa-building-columns text-primary pe-2"></i>
                                {x?.university?.text &&
                                x?.university?.text !== "" ? (
                                  x?.university?.text
                                ) : (
                                  <span className="textColor2">
                                    <Trans
                                      i18nKey="noUniversityMentioned"
                                      defaults="No university Mentioned"
                                    />
                                  </span>
                                )}
                              </div>
                              <div className="col-xs-12 col-md-6">
                                <i className="fa-solid fa-location-dot fa-1x  text-primary pe-2"></i>
                                {x?.location?.text &&
                                x?.location?.text !== "" ? (
                                  x?.location?.text
                                ) : (
                                  <span className="textColor2">
                                    <Trans
                                      i18nKey="noLocationMentioned"
                                      defaults="No location Mentioned"
                                    />
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="row mb-3">
                              <div className="col-xs-12 col-md-6">
                                <div className="mb-2">
                                  {" "}
                                  <span className="textColor2 ">
                                    <Trans i18nKey="major" defaults="Major" />
                                  </span>
                                </div>
                                {x?.major.length > 0 ? (
                                  <TagGroup>
                                    {x?.major?.map((y, index) => {
                                      return (
                                        <Tag
                                          className="px-2 border border-primary bg-white rounded-pill"
                                          key={index}
                                        >
                                          {y?.text}
                                        </Tag>
                                      );
                                    })}
                                  </TagGroup>
                                ) : (
                                  <div className="textColor2 text-center">
                                    <Trans
                                      i18nKey="noMajor"
                                      defaults="No Major"
                                    />
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    }
                  )}
                </>
              ) : (
                <div className="mt-3 text-center textColor2">
                  <Trans
                    i18nKey="noEducationDetails"
                    defaults="No Education Details"
                  />
                </div>
              )}
            </div>

            <div className="col-12 mb-5">
              <span className="fonticon15 text-primary">
                <Trans
                  i18nKey="otherDetails"
                  defaults="Other Details"
                />
              </span>
              <div className="row mt-3">
                <div className="col-xs-12 col-md-6">
                  <span className="textColor2">
                    <Trans i18nKey="currentSalary" defaults="Current Salary" />{" "}
                    {" : "}
                  </span>
                  {profiledata?.Resumedetails?.resume?.professionalSummary
                    ?.currentSalary?.text &&
                  profiledata?.Resumedetails?.resume?.professionalSummary
                    ?.currentSalary?.text !== ""
                    ? profiledata?.Resumedetails?.resume?.professionalSummary
                        ?.currentSalary?.text
                    : t("notAvailable", { defaultValue: "Not Available" })}
                </div>
                <div className="col-xs-12 col-md-6">
                  <div className="col-xs-12 col-md-6">
                    <span className="textColor2">
                      <Trans
                        i18nKey="expectedSalary"
                        defaults="Expected Salary"
                      />
                      {" : "}
                    </span>
                    {/*  {profiledata?.Resumedetails?.resume?.professionalSummary
                      ?.currentSalary?.text &&
                    profiledata?.Resumedetails?.resume?.professionalSummary
                      ?.currentSalary?.text !== ""
                      ? profiledata?.Resumedetails?.resume?.professionalSummary
                          ?.currentSalary?.text
                      : "Not Available"} */}
                    {profiledata?.Resumedetails?.resume?.professionalSummary
                      ?.expectedSalary?.minvalue &&
                    profiledata?.Resumedetails?.resume?.professionalSummary
                      ?.expectedSalary?.minvalue !== "" &&
                    profiledata?.Resumedetails?.resume?.professionalSummary
                      ?.expectedSalary?.maxvalue &&
                    profiledata?.Resumedetails?.resume?.professionalSummary
                      ?.expectedSalary?.maxvalue !== "" &&
                    profiledata?.Resumedetails?.resume?.professionalSummary
                      ?.expectedSalary?.currency !== "" &&
                    profiledata?.Resumedetails?.resume?.professionalSummary
                      ?.expectedSalary?.period !== ""
                      ? profiledata?.Resumedetails?.resume?.professionalSummary
                          ?.expectedSalary?.minvalue +
                        " To " +
                        profiledata?.Resumedetails?.resume?.professionalSummary
                          ?.expectedSalary?.maxvalue +
                        " " +
                        profiledata?.Resumedetails?.resume?.professionalSummary
                          ?.expectedSalary?.currency +
                        " " +
                        profiledata?.Resumedetails?.resume?.professionalSummary
                          ?.expectedSalary?.period
                      : t("notAvailable", { defaultValue: "Not Available" })}
                  </div>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-xs-12 col-md-6">
                  <span className="textColor2">
                    <Trans
                      i18nKey="languagesKnown"
                      defaults="Languages Known"
                    />
                    {" : "}
                  </span>
                  {profiledata?.Resumedetails?.resume?.profile
                    ?.languagesKnown &&
                  profiledata?.Resumedetails?.resume?.profile?.languagesKnown
                    ?.length > 0 ? (
                      <TagGroup>
                        {profiledata?.Resumedetails?.resume?.profile?.languagesKnown?.map(
                          (x, index) => {
                            return (
                              <Tag
                                key={index}
                                className="border border-primary bg-white rounded-pill"
                              >
                                {" "}
                                {x?.text}
                              </Tag>
                            );
                          }
                        )}
                      </TagGroup>
                  ) : (
                    t("notAvailable", { defaultValue: "Not Available" })
                  )}
                </div>
                <div className="col-xs-12 col-md-6">
                  <span className="textColor2">
                    <Trans defaults="Notice Period" i18nKey="noticePeriod" />
                    {" : "}
                  </span>
                  {profiledata?.Resumedetails?.resume?.professionalSummary
                    ?.noticePeriod?.text &&
                  profiledata?.Resumedetails?.resume?.professionalSummary
                    ?.noticePeriod?.text !== ""
                    ? profiledata?.Resumedetails?.resume?.professionalSummary
                        ?.noticePeriod?.text
                    : t("notAvailable", { defaultValue: "Not Available" })}
                </div>
              </div>
              <div className="row mt-3">
                {/* <div className="col-6">
                  <span className="textColor2">
                    <Trans
                      defaults="Preferred Locations"
                      i18nKey="preferredLocations"
                    />
                    :
                  </span> 
                  {profiledata?.Resumedetails?.resume?.professionalSummary
                    ?.preferredLocations &&
                  profiledata?.Resumedetails?.resume?.professionalSummary
                    ?.preferredLocations?.length > 0  ? (
                    <TagGroup>
                      {profiledata?.Resumedetails?.resume?.professionalSummary?.preferredLocations?.map(
                        (x, index) => {
                          return (
                            <Tag
                              key={index}
                              className="border border-primary bg-white rounded-pill"
                            >
                              {" "}
                              {x?.text}
                            </Tag>
                          );
                        }
                      )}
                    </TagGroup>
                  ) : (
                    <span>Not Available</span>
                  )}
                </div> */}
                <div className="col-12 col-md-6">
                  <span className="textColor2">
                    <Trans
                      defaults="Preferred Locations"
                      i18nKey="preferredLocations"
                    />
                    {" : "}
                  </span>
                  {profiledata?.Resumedetails?.resume?.professionalSummary
                    ?.preferredLocations &&
                  profiledata?.Resumedetails?.resume?.professionalSummary
                    ?.preferredLocations?.length > 0 ? (
                    (() => {
                      let nonEmptyTagExists = false;
                      const tags =
                        profiledata?.Resumedetails?.resume?.professionalSummary?.preferredLocations?.map(
                          (x, index) => {
                            if (x?.text && x?.text.trim() !== "") {
                              nonEmptyTagExists = true;
                              return (
                                <Tag
                                  key={index}
                                  className="border border-primary bg-white rounded-pill"
                                >
                                  {x?.text}
                                </Tag>
                              );
                            }
                            return null;
                          }
                        );

                      return nonEmptyTagExists ? (
                        <TagGroup>{tags}</TagGroup>
                      ) : (
                        <span>
                          {t("notAvailable", { defaultValue: "Not Available" })}
                        </span>
                      );
                    })()
                  ) : (
                    <span>
                      {t("notAvailable", { defaultValue: "Not Available" })}
                    </span>
                  )}
                </div>
              </div>
              <div className="row  mt-3">{/* w-100 */}
                <div /* className="d-flex justify-content-end" */ className={` col d-flex  ${isSmallScreen ? 'justify-content-center' : 'justify-content-end'}`} >
                  <button
                    className="btn btn-primary me-2"/*  d-flex align-items-center */
                    onClick={() => {
                      setEditresumedetails(true);
                    }}
                  >
                    <Trans defaults="Edit" i18nKey="edit" />
                  </button>
                  <button
                    className="btn btn-primary"/* d-flex align-items-center */
                    onClick={handleSumbiteditvalues}
                    disabled={UpdateResumesLoading ? true : false}
                  >
                    {UpdateResumesLoading && <Loader className="pe-2" />}
                    <Trans defaults="Submit" i18nKey="submit" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <CreateManualProfileMain
          profiledata={profiledata}
          setEditresumedetails={setEditresumedetails}
          setProfileEditdetails={setProfileEditdetails}
          register={register}
          control={control}
          getValues={getValues}
          setValue={setValue}
          watch={watch}
          clearErrors={clearErrors}
          errors={errors}
          setError={setError}
          handleSubmit={handleSubmit}
          required={required}
          setUpdatedData={setUpdatedData}
          updatedData={updatedData}
          handleUpdateValues={handleUpdateValues}
          setProfiledata={setProfiledata}
        />
      )}
    </>
  );
}
export default CareersEditResume;
