import React, { useEffect, useState } from 'react';
import Email from './Email';
import Phone from './PhoneNumber';
import { PropTypes } from "prop-types"
import { Trans } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
const ContactDetailsView = ({ resumeID, contactDetailsClose, fieldObj,editAccess, getData,triggerContactDetailsRef,setEmailClick,resume,setUpdateResumeDataData,type }) => {
    const [hideEmailDetailsView, setHideEmailDetailsView] = useState(true)
    const [hidePhoneDetailsView, setHidePhoneDetailsView] = useState(true)

    return (
        <>
            <div className='row pb-2  align-items-center' >
                <div className='col-sm-6 '>
                    <h6 className='text-primary '>
                        <Trans
                            i18nKey="contactDetails"
                            defaults="Contact Details"
                        />
                    </h6>
                </div>
                <div className='col-sm-6 d-flex justify-content-end p-0 m-0'>
                    <span className="IconArea actionIcon actionIcon-sm" onClick={contactDetailsClose} >
                        <FontAwesomeIcon
                            className="mx-1 cursor-pointer "
                            icon={faXmark}
                        />
                    </span>
                </div>
            </div>
            <div className='row' style={{
                "minWidth": "350px",
                "maxWidth": "360px"
            }}>
                <div className='col-sm-12'>

                    {hidePhoneDetailsView &&
                        <div className='row'>
                            <div className='col-sm-12'>
                                <Email type={type} resumeId={resumeID} setHideEmailDetailsView={setHideEmailDetailsView} hideEmailDetailsView={hideEmailDetailsView} hidePhoneDetailsView={hidePhoneDetailsView} setHidePhoneDetailsView={setHidePhoneDetailsView} fieldObj={fieldObj} editAccess={editAccess} getData={getData} triggerContactDetailsRef={triggerContactDetailsRef} setEmailClick={setEmailClick} resume={resume} setUpdateResumeDataData={setUpdateResumeDataData} />
                            </div>
                        </div>
                    }
                    {hideEmailDetailsView &&
                        <div className='row my-2'>
                            <div className='col-sm-12'>
                                <Phone type={type} resumeId={resumeID} setHideEmailDetailsView={setHideEmailDetailsView} hideEmailDetailsView={hideEmailDetailsView} hidePhoneDetailsView={hidePhoneDetailsView} setHidePhoneDetailsView={setHidePhoneDetailsView} fieldObj={fieldObj} editAccess={editAccess} getData={getData} triggerContactDetailsRef={triggerContactDetailsRef} resume={resume} setUpdateResumeDataData={setUpdateResumeDataData} />
                            </div>
                        </div>
                    }
                </div>
            </div>


        </>
    );
};

export default ContactDetailsView;