import React, { useEffect, useState } from "react";
import { InputPicker, SelectPicker } from "rsuite";
import "./SearchBarStyles.css";

import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { Trans, useTranslation } from "react-i18next";

export default function ExperenceKey({ value, setValue }) {
  // const experienceData = [
  //     'Fresher (less than 1 year)',
  //     '1 year',
  //     '2 years',
  //     '3 years',
  //     '4 years',
  //     '5 years'
  // ];

  // const transformedData = experienceData.map((item, index) => ({
  //     label: item,
  //     value: item,
  // }));
  const { t } = useTranslation();
  const experienceData = [
    {
      label: "Less than 1 year",
      value: "1",
    },
    // { label: "Fresher (less than 1 year)", value: "1" },

    { label: "2 years", value: "2" },
    { label: "3 years", value: "3" },
    { label: "4 years", value: "4" },
    { label: "5 years", value: "5" },
    { label: "More than 5 years", value: "6" }
  ];

  const location = useLocation();

  useEffect(() => {
    const queryParams = queryString.parse(location.search);
    setValue(queryParams.experience || "");
  }, [location.search]);

  return (
    <>
      {/* <SelectPicker data={experienceData} className=" no-border width  align-items-center" /> */}
      <InputPicker
        data={experienceData}
        className=" no-border width  align-items-center"
        // placeholder="Select Experience"
        placeholder={t("selectExperience", { defaultValue: "Select Experience" })}
        value={value}
        onChange={(newValue) => setValue(newValue)}
      />
    </>
  );
}
