import React, { useState, Suspense, useContext, useEffect } from "react";
import { Button } from "react-bootstrap";
import { Loader, toaster, Notification } from "rsuite";
import UserContext from "../../app/context/UserContext";
import { useTranslation } from "react-i18next";
import axios from "axios";
import usePost from "../../customhooks/UsePost";

const ApplyNowModal = React.lazy(() => import("./ApplyNowModal"));
const CreateProfileModal = React.lazy(() =>
  import("../createProfile/CreateProfileModal")
);
const LinkProfile = React.lazy(() => import("../jobs/LinkProfile"));
const DuplicateProfile = React.lazy(() =>
  import("../createProfile/DuplicateProfile")
);


function ApplyNow({ campaignID,setupdatadataMri }) {
  const { t } = useTranslation();
  const [openApplyNow, setOpenApplyNow] = useState(false);
  const [openCreateProfile, setOpenCreateProfile] = useState(false);
  const [openLinkProfile, setOpenLinkProfile] = useState(false);
  const [openDuplicate, setOpenDuplicate] = useState(false);
  const [duplicateProfiles, setDuplicateProfiles] = useState([]);

  const { user, setUser } = useContext(UserContext);
  const [page, setPage] = useState("login");
  const [enableApply, setEnableApply] = useState(true)
  const [cookie, setCookie] = useState(null)
  const [guestCampaignIDs, setGuestCampaignIDs] = useState([]);
  const [guestCookie, setGuestCookie] = useState("");

  // console.log("campaignIDcampaignID", campaignID);
  // console.log("useruseruseruser", user)

  const {
    post: resumeDetails,
    data,
    isLoading: resumeDetailsLoading,
    error,
  } = usePost("/getresumedetails");

  useEffect(() => {
    console.log("enterrrrrr",campaignID)
    if (user) {
      if (user?.attachedCampaigns && user?.attachedCampaigns?.length > 0) {
        if (user?.attachedCampaigns?.includes(campaignID)) {
          setEnableApply(false)
        } else {
          setEnableApply(true)
        }
      }
    }else{
      // handleEnableUsingCookie()
      // if(cookieDetails?.Guest_User_ResumeID){
        if(guestCampaignIDs?.includes(campaignID)){
          setEnableApply(false)
        }else{
          setEnableApply(true)
        }
      // }
    }
  }, [user, campaignID, guestCampaignIDs])

  useEffect(()=>{
    handleEnableUsingCookie()
  }, [openApplyNow, openCreateProfile, openLinkProfile, openDuplicate, guestCookie]);
  const handleEnableUsingCookie = async() =>{
    let cookieDetails = await axios.get(window.location.origin + "/jsv4/webapi/getcookie");
    cookieDetails = cookieDetails?.data;
    if(cookieDetails?.Guest_User_ResumeID){
      resumeDetails({campaignID: campaignID,resumeID: cookieDetails?.Guest_User_ResumeID})
        .then((res) => {
          if (res) {
            setGuestCampaignIDs(res?.campaignID);
            // if(res?.campaignID?.includes(campaignID)){
            //   setEnableApply(false)
            // }else{
            //   setEnableApply(true)
            // }
          }
        })
        .catch((error) => {
          console.error("Error while fetching Resume:", error);
        });
    }


  }

  async function handleApply() {
    if (user) {
      setPage("login")
      if (user?.resumeID != "") {
        setOpenLinkProfile(true);
      } else {
        setOpenCreateProfile(true);
      }
    } else {
      try {
        let cookieDetails = await axios.get(window.location.origin + "/jsv4/webapi/getcookie");
        cookieDetails = cookieDetails?.data;
        // console.log("cookieDetailscookieDetails", cookieDetails);

        if (cookieDetails["Guest_User_ResumeID"]) {
          setCookie(cookieDetails["Guest_User_ResumeID"])
          setOpenLinkProfile(true)
          setPage("guest")
        } else {
          setOpenApplyNow(true);
        }
      } catch (error) {
        console.log("Error in getCookies : ", error);
      }
    }
  }
  const [notduplicate, setnotduplicate] = useState(false);


  return (
    <>
      {
        enableApply ? (
          <Button
            className="rounded-pill"
            variant="primary"
            onClick={() => {
              handleApply();
            }}
          >
            {t("applyNow", { defaultValue: "Apply Now" })}
          </Button>
        ) : (
          <Button
            className="rounded-pill"
            variant="secondary"
            onClick={() => {
              toaster.push(
                <Notification type={"info"} header={t("info", { defaultValue: "info" })} closable>
                  {t("youHaveAlreadyAppliedForThisJob", { defaultValue: "You have already applied for this job" })}
                </Notification>,
                { placement: "topEnd" }
              );
            }}
          >
            {t("applied", { defaultValue: "Applied" })}
          </Button>
        )
      }

      {openApplyNow && (
        <React.Suspense
          fallback={<Loader center speed="normal" size="sm" backdrop={true} />}
        >
          <ApplyNowModal
            openModal={openApplyNow}
            setOpenModal={setOpenApplyNow}
            from={"applyNow"}
            openCreateProfile={openCreateProfile}
            setOpenCreateProfile={setOpenCreateProfile}
            setOpenLinkProfile={setOpenLinkProfile}
            setPage={setPage}
            setupdatadataMri={setupdatadataMri}
          />
        </React.Suspense>
      )}

      {openCreateProfile && (
        <Suspense
          fallback={<Loader center speed="normal" size="sm" backdrop={true} />}
        >
          <CreateProfileModal
            open={openCreateProfile}
            setOpen={setOpenCreateProfile}
            campaignID={campaignID}
            page={page}
            setOpenDuplicate={setOpenDuplicate}
            setDuplicateProfiles={setDuplicateProfiles}
            setCookie={setCookie}
            setupdatadataMri={setupdatadataMri}
            notduplicate={notduplicate}
            setGuestCookie={setGuestCookie}
          ></CreateProfileModal>
        </Suspense>
      )}

      {openLinkProfile && (
        <Suspense
          fallback={<Loader center speed="normal" size="sm" backdrop={true} />}
        >
          <LinkProfile
            openLinkProfile={openLinkProfile}
            setOpenLinkProfile={setOpenLinkProfile}
            user={user}
            setUser={setUser}
            campaignID={campaignID}
            page={page}
            resumeID={cookie}
            setupdatadataMri={setupdatadataMri}
          />
        </Suspense>
      )}

      {openDuplicate && (
        <React.Suspense
          fallback={<Loader center speed="normal" size="sm" backdrop={true} />}
        >
          <DuplicateProfile
            open={openDuplicate}
            setOpen={setOpenDuplicate}
            duplicateProfiles={duplicateProfiles}
            campaignID={campaignID}
            page={page}
            setCookie={setCookie}
            setupdatadataMri={setupdatadataMri}
            setnotduplicate={setnotduplicate}
            setOpenCreateProfile={setOpenCreateProfile}
          />
        </React.Suspense>
      )
      }
    </>
  );
}
export default ApplyNow;
