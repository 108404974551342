import usePost from "../../customhooks/UsePost"
import { Trans, useTranslation } from "react-i18next";
import { useToaster, Notification } from "rsuite";
import { useSearchParams } from "react-router-dom";

export default function ResendMail({
    interviewID,
    companyID,
    roundNumber,
    slotID,
    clientID,
    companySupportEmail
}) {
    const toaster = useToaster();
    const { t } = useTranslation()
    let [searchParams] = useSearchParams();

    // usePost
    const {
        post: resendMail,
        data: resendMailData,
        isLoading: resendMailLoading,
        error: resendMailError,
    } = usePost("/schedulevideo/resendAutoScheduleMail");

    const handleResendMail = () => {
        // {
        //     "companyID": "552d016291bad07ccbb455fe",
        //     "interviewID": "650c161f7460c889d6023323",
        //     "event": "Request-Schedule-AsNew",
        //     "clientID": "5fb276fa123f55bd44e1569d",
        //     "screeningRoundNumber": "2",
        //     "realTime": true,
        //     "previousSlot": "",
        //     "slotID": "650c1668148a4f49647c6564"
        // }

        let apiObj = {
            companyID: companyID,
            interviewID: interviewID,
            // event: "Request-Schedule-AsNew",
            clientID: clientID,
            screeningRoundNumber: roundNumber,
            // previousSlot: "",
            slotID: slotID,
            // resumeID: resumeID,
            participant: searchParams.get("participant") !== null ? searchParams.get("participant") : ""
        }

        resendMail(apiObj)
            .then((response) => {
                // console.log("response", response);

                if (response?.status) {
                    if (response?.result?.status) {
                        toaster.push(
                            <Notification type={"success"} header={t("success", { defaultValue: "success" })}>
                                <p className="m-0 p-0">
                                    {
                                        t("calendarInviteHasBeenSentToYourEmailAddress.PleaseCheckYourE-mailInboxOrSpamFolder.ThankYou!",
                                            { defaultValue: "Calendar invite has been sent to your email address. Please check your E-mail Inbox or Spam folder. Thank You!" }
                                        )
                                    }
                                </p>
                                <p className="m-0 p-0">
                                    <b>
                                        {
                                            t("ifYouHaveNotReceivedIt,PleaseSendOutAnEmailTo", { defaultValue: "If you have not received it, please send out an email to" })
                                        }
                                        {" "}{companySupportEmail}
                                    </b>
                                </p>
                            </Notification>,
                            { placement: "topEnd" },
                        );
                    } else {
                        toaster.push(
                            <Notification type={"error"} header={t("error", { defaultValue: "error" })}>
                                {
                                    t("pleaseContactSupportTeam", { defaultValue: "Please contact Support Team" })
                                }
                            </Notification>,
                            { placement: "topEnd" },
                        );
                    }
                } else {
                    toaster.push(
                        <Notification type={"error"} header={t("error", { defaultValue: "error" })}>
                            {
                                t("somethingWentWrongPleaseTryAgainLater", { defaultValue: "Something went wrong.Please try again later" })
                            }
                        </Notification>,
                        { placement: "topEnd" },
                    );
                }
            })
            .catch((error) => {
                console.log("error", error)
            })
    }

    return (
        <>
            <span
                className="fs-6 text-decoration-none text-primary resend-text"
                onClick={() => handleResendMail()}
            >
                <Trans i18nKey="resendMail" defaults="Resend Mail" />
            </span>
        </>
    )
}