import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { Trans } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faClock, faGlobe, faCheck } from "@fortawesome/free-solid-svg-icons";
import { DateTime } from "luxon";
import ResendMail from "./ResendMail";


export default function ScheduledSlotDetails({ data }) {
  // console.log("dfghjkljhgfdsfghj", data)
  let { companySupportEmail, slotDetails, defaultTimeFormat, event, interviewDetails } = data;
  let timeFormat = "HH:mm";
  if (defaultTimeFormat && defaultTimeFormat == "12 hours") {
    timeFormat = "hh:mm a"
  }

  const [duration, setDuration] = useState("");
  useEffect(() => {
    if (slotDetails?.endDate != "") {
      let duration;
      let start = DateTime.fromISO(slotDetails?.startDate)
      let end = DateTime.fromISO(slotDetails?.endDate)
      let dur = end.diff(start, ["hours", "minutes"]).toObject();

      if (dur?.hours != 0 && dur?.minutes != 0) {
        duration = `${dur.hours} hour ${dur.minutes} minutes`
      } else if (dur?.hours == 0 && dur?.minutes != 0) {
        duration = `${dur.minutes} minutes`
      } else if (dur?.hours != 0 && dur?.minutes == 0) {
        duration = `${dur.hours} hour`
      } else {
        duration = "No Duration"
      }
      setDuration(duration)
    }
  }, [slotDetails]);

  return (
    <div className="row justify-content-center pt-2">
      <Card className="detailsCard col-lg-6 col-xs-12">
        <Card.Body className="px-0 py-0">
          <div className="sucessIconDiv d-flex justify-content-center align-items-center">
            <FontAwesomeIcon
              icon={faCheck}
              size="lg"
              className=" checkCircle-icon d-flex justify-content-center align-items-center"
            />
          </div>
          <div className="row p-0 ">
            <div className="col-12 text-center">
              <p className="fs-3 m-0">
                <Trans i18nKey="awesome" defaults="Awesome" />
                {"!"}
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-12 text-center">
              {window?.location?.pathname?.includes("confirmschedule") ? (
                <p className="fs-5 m-0">
                  <Trans
                    i18nKey="selectedTimeSlotSuccessfully"
                    defaults="Selected time slot successfully"
                  />
                  {"!"}
                </p>
              ) : (
                <p className="fs-5 m-0">
                  <Trans
                    i18nKey="yourBookingHasBeenConfirmed"
                    defaults="Your booking has been confirmed"
                  />
                  {"."}
                </p>
              )}
            </div>
          </div>
          <div className="row justify-content-center  pt-4   ps-lg-5">
            <div className="row pb-1">
              <div className="col-12">
                <span className="h6 me-3">
                  <Trans i18nKey="event" defaults="Event" /> {":"}
                </span>{" "}
                <span className="fs-6">{event != "" ? event : "No Event"}</span>
              </div>
            </div>
            <div className="row pb-1">
              <div className="col-12 d-flex align-items-start">
                <span className="pt-1">
                  {" "}
                  <FontAwesomeIcon
                    icon={faCircle}
                    size="lg"
                    className="text-primary me-3"
                  />{" "}
                </span>
                <span className="fs-6 text-primary">
                  <b>{duration}</b>
                </span>
              </div>
            </div>

            <div className="row pb-1">
              <div className="col-12 d-flex align-items-start">
                <span>
                  {" "}
                  <FontAwesomeIcon
                    icon={faClock}
                    size="lg"
                    className="me-3 text-primary mt-1"
                  />
                </span>
                <span className="fs-6  text-primary">
                  {DateTime.fromISO(slotDetails?.startDate)
                    .setZone(slotDetails?.timeZoneFullName)
                    .toFormat("DDDD")}
                  {","}{" "}
                  {DateTime.fromISO(slotDetails?.startDate)
                    .setZone(slotDetails?.timeZoneFullName)
                    .toFormat(timeFormat)}{" "}
                  {"-"}{" "}
                  {slotDetails?.endDate != "" &&
                    slotDetails?.endDate != "Invalid Datetime" &&
                    DateTime.fromISO(slotDetails?.endDate)
                      .setZone(slotDetails?.timeZoneFullName)
                      .toFormat(timeFormat)}
                </span>
              </div>
            </div>
            <div className="row pb-1">
              <div className="col-12">
                <FontAwesomeIcon icon={faGlobe} size="lg" className="me-3" />
                <span className="fs-6">
                  {`${slotDetails?.timeZoneName} (GMT${slotDetails?.timeZone})`}
                </span>
              </div>
            </div>
          </div>
          {window?.location?.pathname?.includes("confirmschedule") ? null : (
            <div className="row py-3  px-0">
              <div className="col-12 text-center d-flex flex-column flex-md-row align-items-center justify-content-center">
                <div className="d-flex flex-column flex-md-row">
                  {/* https://recruiterqa.maprecruit.com/confirmschedule?status=reschedule&iid=64c8885f507a2d7144041fa9&round=3&participant=js&userid=6402d6a8ce7802a17f1a37f3&companyid=552d016291bad07ccbb455fe&event=Schedule-Confirmed */}
                  <a
                    href={`/careers/confirmschedule?status=reschedule&iid=${slotDetails?.interviewID}&round=${slotDetails?.roundNumber}&participant=js&userid=${slotDetails?.userID}&companyid=${slotDetails?.companyID}&event=Schedule-Confirmed`}
                    className="btn btn-primary text-decoration-none"
                    role="button"
                    aria-disabled="true"
                  >
                    <Trans
                      i18nKey="requestReschedule"
                      defaults="Request Reschedule"
                    />
                  </a>
                  {/* // https://recruiterqa.maprecruit.com/confirmschedule?status=cancel&iid=64c3ad792f5bf79ff37779c5&round=2&participant=js&userid=5e4b8fde67f499041bf353d1&companyid=552d016291bad07ccbb455fe&event=Schedule-Cancelled */}
                  <a
                    href={`/careers/confirmschedule?status=cancel&iid=${slotDetails?.interviewID}&round=${slotDetails?.roundNumber}&participant=js&userid=${slotDetails?.userID}&companyid=${slotDetails?.companyID}&event=Schedule-Cancelled`}
                    className="btn btn-primary text-decoration-none mt-2 mt-md-0 ms-0 ms-md-2 fit-content"
                    role="button"
                    aria-disabled="true"
                  >
                    <Trans i18nKey="cancel" defaults="Cancel" />
                  </a>
                </div>
              </div>
            </div>
          )}

          <div className="row px-3">
            <div className="row">
              <div className="col-12">
                {"*"}
                <Trans
                  i18nKey="calendarInviteHasBeenSentToYourEmailAddress.PleaseCheckYourE-mailInboxOrSpamFolder.ThankYou!"
                  defaults="Calendar invite has been sent to your email address. Please check your E-mail Inbox or Spam folder. Thank You!"
                />
              </div>
            </div>
            <div className="row pb-3">
              <div className="col-12">
                <b>
                  <Trans
                    i18nKey="ifYouHaveNotReceivedIt,PleaseSendOutAnEmailTo"
                    defaults="If you have not received it, please send out an email to"
                  />{" "}
                  {companySupportEmail}
                </b>
              </div>
            </div>
            <div className="row pb-2">
              <div className="col-12">
                <ResendMail
                  interviewID={interviewDetails?._id}
                  companyID={interviewDetails?.companyID}
                  roundNumber={slotDetails?.roundNumber}
                  slotID={slotDetails?._id}
                  clientID={interviewDetails?.clientID}
                  companySupportEmail={companySupportEmail}
                />
              </div>
            </div>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
}