import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Controller, useFieldArray } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle, faMinusCircle } from "@fortawesome/free-solid-svg-icons";
import { v4 as uuidv4 } from "uuid";
import { Form } from "react-bootstrap";
import ReferencePhonesForm from "./ReferencePhonesForm";
import ReferenceEmailForm from "./ReferenceEmailForm";
import UserDateInput from "../../../Date/UserDateInput";
import { DateTime } from "luxon";
import changingDateFormat from "../../../../../api/services/changingDateFormat";
import { Trans,useTranslation } from "react-i18next";
// import { t } from "i18next";
import LocationField from "../fields/LocationField";
const ReferenceModal = ({
  control,
  register,
  setValue,
  errors,
  setError,
  clearErrors,
  getValues,
  referenceList,
  referenceRowsList,
  handleSubmit
}) => {
  const {
    fields: referenceFields,
    append: referenceAppend,
    prepend,
    remove: referenceRemoved,
    swap,
    move,
    insert,
    replace: referenceReplace,
  } = useFieldArray({
    control,
    name: "reference",
  });
  const { t } = useTranslation();

  useEffect(() => {
    if (referenceList) {
      // console.log(referenceList, "jdnjkdnfjknsdsfkndfndfnf");
      referenceReplace();
      if (referenceList?.length > 0) {
        referenceAppend(
          referenceList?.map((x) => {
            return {
              fullName: x?.fullName,
              jobTitle: x?.jobTitle,
              phone:
                x?.phone?.length > 0
                  ? x?.phone?.map((x) => {
                    return {
                      text: x?.text,
                    };
                  })
                  : [],
              email:
                x?.email?.length > 0
                  ? x?.email?.map((x) => {
                    return {
                      text: x?.text,
                    };
                  })
                  : [],
              company: x?.company,
              startDate:
                ((x?.startDate) && (x?.startDate?.standard != ""))
                  ? DateTime.fromISO(
                    changingDateFormat(x?.startDate?.standard),
                    "yyyy-MM-dd"
                  ).toJSDate()
                  : "",
              endDate:
              ((x?.endDate) && (x?.endDate?.standard != ""))
                  ? DateTime.fromISO(
                    changingDateFormat(x?.endDate?.standard),
                    "yyyy-MM-dd"
                  ).toJSDate()
                  : "",
              review: x?.review,
              comment: x?.comment,
              location: x?.location?.text,
            };
          })
        );
      } else {
        referenceAppend([
          {
            fullName: "",
            jobTitle: "",
            phone: [],
            email: [],
            company: "",
            startDate: "",
            endDate: "",
            review: "",
            comment: "",
            location: "",
          },
        ]);
      }
    }
  }, [referenceList]);
  const inputRef = useRef([]);
  const [dateClear, setDateClear] = useState(true);
  useEffect(() => {
    setDateClear(false);
  }, [dateClear]);
  const handleAddressChange = (place,index) => {
    let location = {
      city: "",
      country: "",
      pin: { lat: 0, lon: 0 },
      state: "",
      zipcode: "",
      text: "",
    };
    let address1 = "";

    if (place) {
      if (place?.address_components?.length > 0) {
        for (let i in place?.address_components) {
          if (
            place?.address_components[i]?.types?.indexOf("street_number") != -1
          ) {
            address1 += place.address_components[i]?.long_name + ",";
          }
          if (place.address_components[i]?.types?.indexOf("route") != -1) {
            address1 += place.address_components[i].long_name + ",";
          }
          if (place.address_components[i]?.types?.indexOf("neighborhood") != -1) {
            address1 += place.address_components[i].long_name + ",";
          }
          if (
            place.address_components[i]?.types?.indexOf("sublocality_level_3") !=
            -1
          ) {
            address1 += place.address_components[i]?.long_name + ",";
          }
          if (
            place.address_components[i]?.types?.indexOf("sublocality_level_2") !=
            -1
          ) {
            address1 += place.address_components[i]?.long_name + ",";
          }
          if (
            place.address_components[i]?.types?.indexOf("sublocality_level_1") !=
            -1
          ) {
            address1 += place.address_components[i]?.long_name + ",";
          }
          if (place.address_components[i]?.types?.indexOf("postal_code") != -1) {
            location.zipcode += place?.address_components[i]?.long_name;
          }
          if (
            place.address_components[i]?.types?.indexOf("postal_code_suffix") !=
            -1
          ) {
            location.zipcode += place.address_components[i]?.long_name;
          }
          if (place.address_components[i]?.types?.indexOf("locality") != -1) {
            location.city += place?.address_components[i]?.long_name;
          }
          if (
            place.address_components[i]?.types?.indexOf(
              "administrative_area_level_1"
            ) != -1
          ) {
            location.state = place?.address_components[i]?.long_name;
          }
          if (place.address_components[i]?.types?.indexOf("country") != -1) {
            location.country = place?.address_components[i]?.long_name;
          }
        }
      }
    }

    if (address1) {
      location.text += address1;
    }
    if (location.city != "") {
      location.text += location.city + ",";
    }
    if (location.state != "") {
      location.text += location.state;
    }
    if (location.zipcode != "") {
      location.text += " " + location.zipcode + ",";
    } else {
      location.text += ",";
    }
    if (location.country != "") {
      location.text += location.country;
    }
    location.pin.lat = place.geometry.location.lat();
    location.pin.lon = place.geometry.location.lng();
    setValue(`reference[${index}].location`, location.text);
    setValue(`reference[${index}].locationObJ`, location);
  };
  const handleReferenceDetails = () => {
      referenceAppend({
        fullName: "",
        jobTitle: "",
        phone: [],
        email: [],
        company: "",
        startDate: "",
        endDate: "",
        review: "",
        comment: "",
        location: "",
      });
    
  };

  function DurationValidation() {
    for (let i in referenceFields) {
      let flag = true;
      if (
        getValues("reference[" + i + "].startDate") != "" &&
        getValues("reference[" + i + "].endDate") != ""
      ) {
        if (
          DateTime.fromJSDate(
            getValues("reference[" + i + "].startDate")
          ).toMillis() >=
          DateTime.fromJSDate(
            getValues("reference[" + i + "].endDate")
          ).toMillis()
        ) {
          flag = false;
          setError("reference[" + i + "].endDate", {
            type: "manual",
            message: t(
              "endDateShouldBeGreaterThan",
              {
                defaultValue:
                  "End date should be greater than start date",
              }
            )
          });
        }
      }
      if (flag) {
        clearErrors([
          "reference[" + i + "].startDate",
          "reference[" + i + "].endDate",
        ]);
      }
    }
  }

  return (
    <>
      {referenceFields?.map((itemData, index1) => {
        return (
          <>
            <div className="row py-2" key={itemData.id}>
              <div className="col-sm-12 col-md-12">
                <div className="card">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-sm-12 justify-content-end d-flex">
                        <span
                          className="px-2 IconArea actionIcon actionIcon-sm"
                          onClick={() => {
                            referenceRemoved(index1);
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faMinusCircle}
                          ></FontAwesomeIcon>
                        </span>
                      </div>
                    </div>
                    {referenceRowsList?.length > 0 &&
                      referenceRowsList?.map((item, index) => {
                        let colNumber = 12;
                        if (item.columns.length > 0) {
                          colNumber = 12 / item.columns.length;
                        }

                        return (
                          <>
                            <div className="row py-2">
                              {item?.columns?.length > 0 &&
                                item?.columns?.map((column, index) => {
                                  return (
                                    <>
                                      <div
                                        className={"col-sm-" + colNumber}
                                      // key={uuidv4()}
                                      >
                                        {column?.fieldCode == "Ref Phones" ? (
                                          <>
                                            <ReferencePhonesForm
                                              phones={itemData?.phone}
                                              register={register}
                                              errors={errors}
                                              setValue={setValue}
                                              clearErrors={clearErrors}
                                              control={control}
                                              phnIndex={index1}
                                              getValues={getValues}
                                              fieldObj={column}
                                              setError={setError}

                                            />
                                          </>
                                        ) : null}
                                        {column?.fieldCode == "Ref Emails" ? (
                                          <>
                                            <ReferenceEmailForm
                                              emails={itemData?.email}
                                              register={register}
                                              errors={errors}
                                              setError={setError}
                                              clearErrors={clearErrors}
                                              setValue={setValue}
                                              control={control}
                                              emailIndex={index1}
                                              getValues={getValues}
                                              fieldObj={column}
                                            />
                                          </>
                                        ) : null}
                                        {column?.fieldCode == "Ref Full Name" ? (
                                          <>
                                            <div className="form-group">
                                              <label>
                                              { t("proRefFullName", { defaultValue: "Full Name" })}
                                              </label>
                                              {column?.required && <span className="text-danger">{"*"}</span>}
                                              <Controller
                                                control={control}
                                                name={`reference[${index1}].fullName`}
                                                refs={register(
                                                  `reference[${index1}].fullName`,
                                                  {
                                                    required: column?.required ? true : false,
                                                    // validate: {
                                                    //   emptyValue: (v) =>
                                                    //     v && v != "" ? v.replace(/\s/g, "") != "" : true,
                                                    // },
                                                  }
                                                )}
                                                render={({
                                                  field: {
                                                    onChange,
                                                    onBlur,
                                                    value,
                                                    refs,
                                                  },
                                                }) => (
                                                  <Form.Control
                                                    size="sm"
                                                    className="w-100"
                                                    placeholder={t("enter", { defaultValue: "Enter" }) + " " + t("proRefFullName", { defaultValue: "Full Name" })}
                                                    refs={refs}
                                                    value={value}
                                                    disabled={column?.editable ? false : true}
                                                    onChange={onChange}
                                                  />
                                                )}
                                              />
                                            </div>
                                            {/* {errors?.["reference"]?.[index1]?.["fullName"] && (
                                              <span className="text-danger">
                                                {errors?.["reference"]?.[index1]?.["fullName"]?.message != ""
                                                  ? errors?.["reference"]?.[index1]?.["fullName"].message
                                                  : "Full name is required"}
                                              </span>
                                            )} */}
                                            {errors?.["reference"]?.[index1]?.["fullName"] && (
                                              <span className="text-danger">
                                                {errors?.["reference"]?.[index1]?.["fullName"]?.message != ""
                                                  ? errors?.["reference"]?.[index1]?.["fullName"].message
                                                  : 
                                                  <Trans i18nKey="fullNameIsRequired" defaults="Full name is required" /> 
                                                  }
                                              </span>
                                            )}
                                          </>
                                        ) : null}
                                        {column?.fieldCode == "Ref Job Title" ? (
                                          <>
                                            <div className="form-group">
                                              <label>
                                              { t("proRefJobTitle", { defaultValue:  "Job Title" })}
                                              </label>
                                              {column?.required && <span className="text-danger">{"*"}</span>}
                                              <Controller
                                                control={control}
                                                name={`reference[${index1}].jobTitle`}
                                                refs={register(
                                                  `reference[${index1}].jobTitle`,
                                                  {
                                                    required: column?.required ? true : false,
                                                    validate: {
                                                      emptyValue: (v) =>
                                                        v && v != "" ? v.replace(/\s/g, "") != "" : true,
                                                    },
                                                  }
                                                )}
                                                render={({
                                                  field: {
                                                    onChange,
                                                    onBlur,
                                                    value,
                                                    refs,
                                                  },
                                                }) => (
                                                  <Form.Control
                                                    size="sm"
                                                    refs={refs}
                                                    className="w-100"
                                                    placeholder={t("enter", { defaultValue: "Enter" }) + " " + t("proRefJobTitle", { defaultValue:  "Job Title" })}
                                                    value={value}
                                                    disabled={column?.editable ? false : true}
                                                    onChange={onChange}
                                                  />
                                                )}
                                              />
                                              {errors?.["reference"]?.[index1]?.["jobTitle"] && (
                                              <span className="text-danger ">
                                              {errors?.["reference"]?.[index1]?.["jobTitle"]?.message != "" ? (
                                                errors?.["reference"]?.[index1]?.["jobTitle"]?.message
                                              ) : (
                                                <Trans
                                                  i18nKey="jobTitleIsRequired"
                                                  defaults="Job Title Is Required"
                                                />
                                              )}{" "}
                                            </span>
                                            )}
                                            </div>
                                          </>
                                        ) : null}
                                        {column?.fieldCode == "Ref Company" ? (
                                          <>
                                            <div className="form-group">
                                              <label>
                                                {t("proRefCompany", { defaultValue: "Company" })}
                                              </label>
                                              {column?.required && <span className="text-danger">{"*"}</span>}
                                              <Controller
                                                control={control}
                                                name={`reference[${index1}].company`}
                                                refs={register(
                                                  `reference[${index1}].company`,
                                                  {
                                                    required: column?.required ? true : false,
                                                    validate: {
                                                      emptyValue: (v) =>
                                                        v && v != "" ? v.replace(/\s/g, "") != "" : true,
                                                    },
                                                  }
                                                )}
                                                render={({
                                                  field: {
                                                    onChange,
                                                    onBlur,
                                                    value,
                                                    refs,
                                                  },
                                                }) => (
                                                  <Form.Control
                                                    size="sm"
                                                    refs={refs}
                                                    className="w-100"
                                                    placeholder={t("enter", { defaultValue: "Enter" }) + " " + t("proRefCompany", { defaultValue: "Company" })}
                                                    value={value}
                                                    disabled={column?.editable ? false : true}
                                                    onChange={onChange}
                                                  />
                                                )}
                                              />
                                              {errors?.["reference"]?.[index1]?.["company"] && (
                                              <span className="text-danger ">
                                              {errors?.["reference"]?.[index1]?.["company"]?.message != "" ? (
                                                errors?.["reference"]?.[index1]?.["company"]?.message
                                              ) : (
                                                <Trans
                                                  i18nKey="companyIsRequired"
                                                  defaults="Company Is Required"
                                                />
                                              )}{" "}
                                            </span>
                                            )}
                                            </div>
                                          </>
                                        ) : null}
                                        {column?.fieldCode == "Ref Review" ? (
                                          <>
                                            <div className="form-group">
                                              <label>
                                                {t("proRefReview", { defaultValue: "Review" })}
                                              </label>
                                              {column?.required && <span className="text-danger">{"*"}</span>}
                                              <Controller
                                                control={control}
                                                name={`reference[${index1}].review`}
                                                refs={register(
                                                  `reference[${index1}].review`,
                                                  {
                                                    required: column?.required ? true : false,
                                                    validate: {
                                                      emptyValue: (v) =>
                                                        v && v != "" ? v.replace(/\s/g, "") != "" : true,
                                                    },
                                                  }
                                                )}
                                                render={({
                                                  field: {
                                                    onChange,
                                                    onBlur,
                                                    value,
                                                    refs,
                                                  },
                                                }) => (
                                                  <Form.Control
                                                    size="sm"
                                                    refs={refs}
                                                    className="w-100"
                                                    placeholder={t("enter", { defaultValue: "Enter" }) + " " + t("proRefReview", { defaultValue: "Review" })}
                                                    value={value}
                                                    disabled={column?.editable ? false : true}
                                                    onChange={onChange}
                                                  />
                                                )}
                                              />
                                              {errors?.["reference"]?.[index1]?.["review"] && (
                                              <span className="text-danger ">
                                              {errors?.["reference"]?.[index1]?.["review"]?.message != "" ? (
                                                errors?.["reference"]?.[index1]?.["review"]?.message
                                              ) : (
                                                <Trans
                                                  i18nKey="reviewIsRequired"
                                                  defaults="Review Is Required"
                                                />
                                              )}{" "}
                                            </span>
                                            )}
                                            </div>
                                          </>
                                        ) : null}
                                        {column?.fieldCode == "Ref Comment" ? (
                                          <>
                                            <div className="form-group">
                                              <label>
                                                {t("proRefComment", { defaultValue:  "Comment" })}
                                              </label>
                                              {column?.required && <span className="text-danger">{"*"}</span>}
                                              <Controller
                                                control={control}
                                                name={`reference[${index1}].comment`}
                                                refs={register(
                                                  `reference[${index1}].comment`,
                                                  {
                                                    required: column?.required ? true : false,
                                                    validate: {
                                                      emptyValue: (v) =>
                                                        v && v != "" ? v.replace(/\s/g, "") != "" : true,
                                                    },
                                                  }
                                                )}
                                                render={({
                                                  field: {
                                                    onChange,
                                                    onBlur,
                                                    value,
                                                    refs,
                                                  },
                                                }) => (
                                                  <Form.Control
                                                    size="sm"
                                                    refs={refs}
                                                    className="w-100"
                                                    placeholder={t("enter", { defaultValue: "Enter" }) + " " + t("proRefComment", { defaultValue:  "Comment" })}
                                                    value={value}
                                                    disabled={column?.editable ? false : true}
                                                    onChange={onChange}
                                                  />
                                                )}
                                              />
                                              {errors?.["reference"]?.[index1]?.["comment"] && (
                                              <span className="text-danger ">
                                              {errors?.["reference"]?.[index1]?.["comment"]?.message != "" ? (
                                                errors?.["reference"]?.[index1]?.["comment"]?.message
                                              ) : (
                                                <Trans
                                                  i18nKey="commentIsRequired"
                                                  defaults="Comment Is Required"
                                                />
                                              )}{" "}
                                            </span>
                                            )}
                                            </div>
                                          </>
                                        ) : null}
                                        {column?.fieldCode == "Ref Location" ? (
                                          <>
                                            <div className="form-group">
                                              <label>
                                                {t("proRefLocation", { defaultValue:  "Location" })}
                                              </label>
                                              {column?.required && <span className="text-danger">{"*"}</span>}
                                              <Controller
                                                control={control}
                                                name={`reference[${index1}].location`}
                                                refs={register(
                                                  `reference[${index1}].location`,
                                                  {
                                                    required: column?.required ? true : false,
                                                    // validate: {
                                                    //   emptyValue: (v) =>
                                                    //     v && v != "" ? v.replace(/\s/g, "") != "" : true,
                                                    // },
                                                  }
                                                )}
                                                render={({
                                                  field: {
                                                    onChange,
                                                    onBlur,
                                                    value,
                                                    refs,
                                                  },
                                                }) => (
                                                  <div className="w-100">
                                                    <LocationField
                                                      handlePlaceChange={handleAddressChange}
                                                      onChange={onChange}
                                                      value={value}
                                                      disabled={column?.editable ? false : true}
                                                      column={column}
                                                      index={index1}
                                                    />
                                                    {/* <StandaloneSearchBox
                                                      onLoad={(ref) => {
                                                        inputRef.current[
                                                          index1
                                                        ] = ref;
                                                      }}
                                                      onPlacesChanged={() =>
                                                        handleAddressChange(
                                                          index1
                                                        )
                                                      }
                                                    >
                                                      <Form.Control
                                                        size="sm"
                                                        placeholder={t("enter", { defaultValue: "Enter" }) + " " + t(column?.i18nKey, { defaultValue: column?.fieldName || "Location" })}
                                                        onChange={onChange}
                                                        value={value}
                                                        block
                                                      />
                                                    </StandaloneSearchBox> */}
                                                  </div>
                                                )}
                                              />
                                               {errors?.["reference"]?.[index1]?.["location"] && (
                                              <span className="text-danger ">
                                              {errors?.["reference"]?.[index1]?.["location"]?.message != "" ? (
                                                errors?.["reference"]?.[index1]?.["location"]?.message
                                              ) : (
                                                <Trans
                                                  i18nKey="locationIsRequired"
                                                  defaults="Location Is Required"
                                                />
                                              )}{" "}
                                            </span>
                                            )}
                                            </div>
                                          </>
                                        ) : null}
                                        {column?.fieldCode ==
                                          "Ref Start Date" ? (
                                          <>
                                            <div className="form-group">
                                              <label>
                                                {t("proRefStartDate", { defaultValue: "Start Date" })}
                                              </label>
                                              {column?.required && <span className="text-danger">{"*"}</span>}
                                              <div>
                                                <Controller
                                                  control={control}
                                                  name={`reference[${index1}].startDate`}
                                                  refs={register(
                                                    `reference[${index1}].startDate`,
                                                    {
                                                      required: column?.required ? true : false,
                                                      // validate: {
                                                      //   emptyValue: (v) =>
                                                      //     v && v != "" ? v.replace(/\s/g, "") != "" : true,
                                                      // },
                                                    }
                                                  )}
                                                  render={({
                                                    field: {
                                                      onChange,
                                                      onBlur,
                                                      value,
                                                      refs,
                                                    },
                                                  }) =>
                                                    ((itemData?.startDate && itemData?.startDate != "") || getValues()?.reference[index1]?.startDate) ? (
                                                      <UserDateInput
                                                        defaultValue={value ? value : getValues()?.reference[index1]?.startDate}
                                                        refs={refs}
                                                        onChange={(value) => {
                                                          onChange(value);
                                                          DurationValidation();
                                                        }}                                                        disabled={column?.editable ? false : true}
                                                        placement="auto"
                                                      />
                                                    ) : (
                                                      <UserDateInput
                                                        refs={refs}
                                                        disabled={column?.editable ? false : true}
                                                        onChange={(value) => {
                                                          onChange(value);
                                                          DurationValidation();
                                                        }}                           
                                                       placement="auto"
                                                      />
                                                    )
                                                  }
                                                />
                                                {errors?.["reference"]?.[index1]?.["startDate"] && (
                                              <span className="text-danger ">
                                              {errors?.["reference"]?.[index1]?.["startDate"]?.message != "" ? (
                                                errors?.["reference"]?.[index1]?.["startDate"]?.message
                                              ) : (
                                                <Trans
                                                  i18nKey="startDateIsRequired"
                                                  defaults="Start Date Is Required"
                                                />
                                              )}{" "}
                                            </span>
                                            )}
                                              </div>
                                            </div>

                                          </>
                                        ) : null}
                                        {column?.fieldCode == "Ref End Date" ? (
                                          <>
                                            <div className="form-group">
                                              <label>
                                                {t("proRefEndDate", { defaultValue: "End Date" })}
                                              </label>
                                              {column?.required && <span className="text-danger">{"*"}</span>}
                                              <div className="">
                                                <Controller
                                                  control={control}
                                                  name={`reference[${index1}].endDate`}
                                                  refs={register(
                                                    `reference[${index1}].endDate`,
                                                    {
                                                      required: column?.required ? true : false,
                                                      // validate: {
                                                      //   emptyValue: (v) =>
                                                      //     v && v != "" ? v.replace(/\s/g, "") != "" : true,
                                                      // },
                                                    }
                                                  )}
                                                  render={({
                                                    field: {
                                                      onChange,
                                                      onBlur,
                                                      value,
                                                      refs,
                                                    },
                                                  }) =>
                                                    ((itemData?.endDate && itemData?.endDate != "") || getValues()?.reference[index1]?.endDate) ? (
                                                      <UserDateInput
                                                        defaultValue={value ? value : getValues()?.reference[index1]?.endDate}
                                                        refs={refs}
                                                        onChange={(value) => {
                                                          onChange(value);
                                                          DurationValidation();
                                                        }} 
                                                        disabled={column?.editable ? false : true}
                                                        placement="auto"
                                                      />
                                                    ) : (
                                                      <UserDateInput
                                                        refs={refs}
                                                        onChange={(value) => {
                                                          onChange(value);
                                                          DurationValidation();
                                                        }} 
                                                        disabled={column?.editable ? false : true}
                                                        placement="auto"
                                                      />
                                                    )
                                                  }
                                                />
                                                {errors?.["reference"]?.[index1]?.["endDate"] && (
                                              <span className="text-danger">
                                                {errors?.["reference"]?.[index1]?.["endDate"]?.message != ""
                                                  ? errors?.["reference"]?.[index1]?.["endDate"].message
                                                  : 
                                                   <Trans i18nKey="endDateIsRequired" defaults="endDate is required" />
                                                    }
                                              </span>
                                            )}
                                              </div>
                                            </div>
                                          </>
                                        ) : null}
                                      </div>
                                    </>
                                  );
                                })}
                            </div>
                          </>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
          </>
        );
      })}
      <div className="my-2">
        <div className="justify-content-center d-flex">
          <span
            className="px-2 IconArea actionIcon actionIcon-lg"
            onClick={handleSubmit(handleReferenceDetails)}
          >
            <FontAwesomeIcon icon={faPlusCircle}></FontAwesomeIcon>
          </span>
        </div>
        <div className="justify-content-center d-flex">
          <span className="">
            <Trans  i18nKey="addReferenceDetails"  defaults="Add Reference Details"  />
          </span>
        </div>
      </div>
    </>
  );
};

export default ReferenceModal;
