import React, { useState, useEffect } from "react";
import { Dropdown, Button, Form, FormControl } from "react-bootstrap";
import "../SearchBar.css";
import { Notification, useToaster, Loader } from "rsuite";
import { Trans, useTranslation } from "react-i18next";

export default function IndustryAttribute({
  industryAttributesList,
  getJobs,
  setJobsListData,
  setPage,
  setTotal,
  selectedOptions,
  setSelectedOptions,
  setIsSearchMade,
  SearchBodyData,
  setAttributeData,
  isLoading,
  setSearchvalue,
  setShowJobDetails,
}) {
  // useEffect(() => {
  //   if (SearchBodyData && SearchBodyData?.filter) {
  //     const industryData = SearchBodyData?.filter.find(
  //       (item) => item?.type === "industry"
  //     );

  //     if (
  //       industryData &&
  //       industryData?.industry &&
  //       industryData?.industry?.length > 0
  //     ) {
  //       const selectedIndustries = industryData?.industry;
  //       setSelectedOptions(selectedIndustries);
  //     }
  //   }
  // }, [SearchBodyData, setSelectedOptions]);
  const { t } = useTranslation();
  const [showDropdown, setShowDropdown] = useState(false);
  const [type, setType] = useState("error");
  const [searchValue, setSearchValue] = useState("");
  const toaster = useToaster();
  const [industrytags, setindustrytags] = useState([]);
  useEffect(() => {
    if (showDropdown) {
      setindustrytags(selectedOptions);
    }
  }, [showDropdown]);
  const handleDropdownSelect = (option) => {
    if (industrytags.includes(option)) {
      setindustrytags(industrytags.filter((item) => item !== option));
    } else {
      setindustrytags([...industrytags, option]);
    }
  };

  const handleResetButtonClick = () => {
    setSelectedOptions([]);
    setindustrytags([]);
    setSearchvalue(0);
    setSearchValue("");
    const queryString = window.location.search;
    const parameters = new URLSearchParams(queryString);

    const newFilter = SearchBodyData?.filter?.filter(
      (item) => item?.type !== "industry"
    );
    let body = {
      size: 6,
      page: 1,
      skip: 0,
      searchID: parameters.get("searchID") || "",
      filter: [
        {
          type: "industry",
          industry: [],
        },
      ],
      newSearch: false,
    };

    if (parameters.has("industry")) {
      parameters.delete("industry");
      body["search"] = [
        {
          type: "industry",
          industry: [],
        },
      ];
    }
    setIsSearchMade(false);
    getJobs(body)
      .then((res) => {
        setShowJobDetails(false);
        setJobsListData(res?.data?.result ?? []);
        setTotal(res?.data?.totalResults ?? 0);
        setAttributeData(res?.data?.attributesAll);
      })
      .catch((error) => {
        console.error("Error while fetching jobs:", error);
      });

    setPage(1);
    setShowDropdown(false);
    setSearchvalue(0);
    if (parameters.has("page")) {
      parameters.set("page", 1);
    }
    if (parameters.has("index")) {
      parameters.set("index", 1);
    }
    window.history.replaceState(
      {},
      "",
      `${window.location.pathname}?${parameters}`
    );
  };

  const handleApplyButtonClick = () => {
    if (industrytags.length === 0) {
      toaster.push(
        <Notification type={type} header={t("error", { defaultValue: "Error" })} closable>
          {/* <h6>Please select at least one option in the list</h6> */}
          {t("PleaseSelectAtLeastOneOptionInTheList", {
            defaults: "Please select atleast one option in list",
          })}
        </Notification>,
        {
          placement: "topEnd",
          duration: 3000,
        }
      );
      return;
    }

    const queryString = window.location.search;
    const parameters = new URLSearchParams(queryString);

    let body = {
      searchID: parameters.get("searchID"),
      filter: [
        {
          type: "industry",
          industry: industrytags,
        },
      ],
      newSearch: false,
    };

    getJobs(body)
      .then((res) => {
        setShowJobDetails(false);
        setJobsListData(res?.data?.result ?? []);
        setTotal(res?.data?.totalResults ?? 0);
        setAttributeData(res?.data?.attributesAll);
        setSelectedOptions(industrytags);
      })
      .catch((error) => {
        console.error("Error while fetching jobs:", error);
      });
    setSearchvalue(0);
    setPage(1);
    setShowDropdown(false);
    if (parameters.has("page")) {
      parameters.set("page", 1);
    }
    if (parameters.has("index")) {
      parameters.set("index", 1);
    }
    window.history.replaceState(
      {},
      "",
      `${window.location.pathname}?${parameters}`
    );
  };

  /*   const filteredOptions = industryAttributesList?.filter((option) =>
      option?.key.toLowerCase().includes(searchValue.toLowerCase())
    ); */

  const filteredOptions = industryAttributesList
    ?.map((option) => {
      const formattedKey = option?.key
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
      return { ...option, formattedKey };
    })
    ?.filter((option) =>
      option?.formattedKey.toLowerCase().includes(searchValue.toLowerCase())
    );

  return (
    <>
      <Dropdown
        show={showDropdown}
        onToggle={(isOpen) => {
          setShowDropdown(isOpen);
          setSearchValue("");
        }}
        className="position-static"
      >
        <Dropdown.Toggle
          variant="none"
          id="dropdown-basic"
          className="attributeStyles text-left text-muted rounded-pill"
          size="md"
        >
         <Trans i18nKey="industry" defaults="Industry" />
        </Dropdown.Toggle>

        <Dropdown.Menu className="dropdown-menu-custom ">
          <FormControl
            type="text"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            // placeholder="Search industry.."
            placeholder={t("searchIndustry", {
              defaultValue: "Search Industry",
            })}
            className="mx-2 mb-2 atributesSearch custom-form-control  mx-auto"
          />

          <Form className="dropdown-form   ">
            <div className="scrollable-options">
              {filteredOptions?.map((option) => (
                <div key={option?.key} className="ms-3">
                  <Form.Check
                    className="custom-checkbox"
                    type="checkbox"
                    id={option.key}
                    label={`${option?.formattedKey} (${option?.doc_count})`}
                    checked={industrytags.includes(option?.key)}
                    onChange={() => handleDropdownSelect(option?.key)}
                  />
                </div>
              ))}
            </div>
            <Dropdown.Divider />
            <div className="d-flex justify-content-between ">
              {isLoading ? (
                <Loader className="justify-content-center text-center d-flex ms-5 reset-button" />
              ) : (
                <button
                  type="button"
                  className="justify-content-center btn btn-light text-center d-flex ms-3 reset-button"
                  onClick={handleResetButtonClick}
                  disabled={isLoading ? true : false}
                >
                    <Trans i18nKey="reset" defaults="Reset" />
                </button>
              )}
              {isLoading ? (
                <Loader className="justify-content-center  text-center d-flex me-5 reset-button" />
              ) : (
                <Button
                  variant="primary"
                  onClick={handleApplyButtonClick}
                  className="justify-content-center text-center d-flex me-3"
                  // disabled={isLoading ?  <Loader />  :false}
                >
                <Trans i18nKey="apply" defaults="Apply" />
                </Button>
              )}
            </div>
          </Form>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
}
