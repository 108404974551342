import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import { AutoComplete, TagInput } from "rsuite";
import { Trans, useTranslation } from "react-i18next";
import CheckBoxesSuggestions from "./CheckBoxesSuggestions";
// import { searchFilters } from "../../../utilities/searchJSONConfig";
import _ from "lodash";
export default function MinorFrom({
  index,
  control,
  register,
  setValue,
  getValues,
  errors,
  itemData,
  registerName,
  placeholderName,
  column,
  labelName,
  type
}) {
  // const AdvancedSearchFormRef = useRef(_.cloneDeepWith(searchFilters));
  const { t } = useTranslation();
  console.log(errors, "rrrrrrrrrrrreeeeeeetttttttt");
  return (
    <>
      {/* <label>{t(labelName.i18nKey, { defaultValue:labelName})}</label> */}
      <label>
        {" "}
        {/* {t(column?.i18nKey, { defaultValue: column?.fieldName })} */}
        <Trans i18nKey={labelName} defaults={labelName} />
      </label>
      {column?.required && <span className="text-danger">{"*"}</span>}
      <Controller
        control={control}
        name={registerName}
        refs={register(registerName, {
          required: column?.required ? true : false,
        })}
        render={({ field: { onChange, onBlur, value } }) => (
          <CheckBoxesSuggestions
            // AdvancedSearchFormRef={AdvancedSearchFormRef}
            // placeholder={t("enter", { defaultValue: "Enter" }) + " " + t(placeholderName.i18nKey, { defaultValue:placeholderName? placeholderName : "majorr"})}
            placeholder={placeholderName}
            context={"specialization"}
            type={"Specialization"}
            placement={"auto"}
            column={column}
            // disabled={column?.editable ? false : true}
            // selectedOptions={
            //     AdvancedSearchFormRef.current != undefined &&
            //         AdvancedSearchFormRef.current.length > 0 &&
            //         AdvancedSearchFormRef.current.find(
            //             (x) => x?.type == "Specialization"
            //         )?.value?.length > 0
            //         ? AdvancedSearchFormRef.current.find(
            //             (x) => x?.type == "Specialization"
            //         ).value
            //         : value
            // }
            selectedOptions={value}
            // disabled={column?.editable ? false : true}
            onChange={(values) => {
              onChange(values);
            }}
            value={value}
            size="sm"
            from={type}

          />
        )}
      />
      {/* `education[${index1}].minor` */}

      {labelName == "minor" && errors?.["education"]?.[index]?.minor && (
        <span className="text-danger mt-3">
          {errors?.["education"]?.[index]?.minor?.message != "" ? (
            errors?.["education"]?.[index]?.minor?.message
          ) : (
            <Trans i18nKey="minorIsRequired" defaults="Minor is required" />
          )}{" "}
        </span>
      )}
      {labelName == "major" && errors?.["education"]?.[index]?.major && (
        <span className="text-danger mt-3">
          {errors?.["education"]?.[index]?.major?.message != "" ? (
            errors?.["education"]?.[index]?.major?.message
          ) : (
            <Trans i18nKey="majorIsRequired" defaults="Major is required" />
          )}{" "}
        </span>
      )}
    </>
  );
}

// MinorFrom.propTypes = {
//     column: PropTypes.string,
//     labelName: PropTypes.any,
//     index: PropTypes.number,
//     control: PropTypes.object,
//     register: PropTypes.func,
//     setValue: PropTypes.func,
//     getValues: PropTypes.func,
//     errors: PropTypes.object,
//     itemData: PropTypes.object,
//     registerName: PropTypes.string,
//     placeholderName: PropTypes.any
// };
