import queryString from "query-string";
import React, { useEffect, useState } from "react";
import "./CareersJobdeatails.css";
const ImportScript = ({ source }) => {
  const isMounted = React.useRef(false);

  useEffect(() => {
    if (!isMounted.current) {
      const script = document.createElement("script");
      script.id = "buildChat";
      script.src = source;
      script.async = true;
      script.type = "text/javascript";
      script.setAttribute("crossorigin", "anonymous");
      document.body.appendChild(script);
      isMounted.current = true;
      // console.log(script, "rrr1iiii");
      // return () => {
      //   try {
      //     document.body.removeChild(script);
      //   } catch (error) {
      //     console.log(error);
      //   }
      // };
    }
  }, [source]);
};


function ChatBotPage({
  loaderDetails,
  rid,
  setRid,
  iid,
  setIid,
  jid,
  setJid,
  roundNumber,
  setRoundNumber,
}) {
  // const [rid, setRid] = useState(null);
  // const [iid, setIid] = useState(null);
  // const [jid, setJid] = useState(null);
  // const [roundNumber, setRoundNumber] = useState(null);

  const [siteName, setSiteName] = useState("");

  const isMounted = React.useRef(false);

  useEffect(() => {
    if (typeof window !== "undefined" && !isMounted.current) {
      if (window.location.host.includes("3105")) {
        setSiteName("recruiterdev.maprecruit.com");
      } else {
        setSiteName(window.location.host);
      }

      // if (
      //   process.env.NODE_ENV == "development" ||
      //   process.env.NODE_ENV == "local"
      // ) {
      //   setSiteName("recruiterdev.maprecruit.com");
      // } else {
      //   setSiteName(window.location.host);
      // }
      const searchParams = window.location.search;
      const parameters = queryString.parse(searchParams);
      //const paramName = parameters.paramName;

      setRid(parameters.rid);
      setIid(parameters.iid);
      setRoundNumber(parameters.round);
      setJid(parameters.jid);
      isMounted.current = true;
    }
  }, [loaderDetails]);

  return (
    <>
      {/* <iframe src='https://recruiterdev.maprecruit.com/chatBot/buildCode?authKey=2721c1fd9f9296d120f68e60f7cdb034&jid=6050392965033db24ad21308
            &collection=coarthatechnosolutionsIN&rid=650c4d4578401794b9a83a99&clid=5ea65d3da71bbf18d445c8d1&iid=65269b6f92c2d9d953d3790f&round=1&page=interviewPage&verified=true'
             width="800" height="600" title="Embedded Web Page"></iframe>  */}

      {rid && (
        <>
          {/* <iframe
            src={`https://${siteName}/chatBot/buildCode?authKey=${
                 loaderDetails?.jobDetails?.companyAuthKey
                 }&jid=${loaderDetails?.jobDetails?._id}&collection=${
                     loaderDetails?.jobDetails?.collectionName
                   }&rid=${rid}&clid=${loaderDetails?.jobDetails?.clientID}&iid=${iid}&round=${
                     roundNumber || ""
                   }&page=interviewPage&verified=true`}
             className="iFrameStyles"
             ></iframe> */}

          <ImportScript
            source={`https://${siteName}/chatBot/buildCode?authKey=${
              loaderDetails?.jobDetails?.companyAuthKey
            }&jid=${loaderDetails?.jobDetails?._id}&collection=${
              loaderDetails?.jobDetails?.collectionName
            }&rid=${rid}&clid=${
              loaderDetails?.jobDetails?.clientID
            }&interviewID=${iid}&round=${
              roundNumber || ""
            }&page=interviewPage&verified=true`}
          />

          {/* <iframe
                src={iframeSrc}
                id="buildChat"
                // width="290"
                // height="500" 
                className="iFrameStyles"
                // allowFullScreen
              ></iframe> */}
        </>
      )}

      {jid && (
        <>
          <ImportScript
            source={`https://${siteName}/chatBot/buildCode?authKey=${
              loaderDetails?.jobDetails?.companyAuthKey
            }&jid=${loaderDetails?.jobDetails?._id}&collection=${
              loaderDetails?.jobDetails?.collectionName
            }&jsid=${jid}&clid=${
              loaderDetails?.jobDetails?.clientID
            }&interviewID=${iid}&round=${
              roundNumber || ""
            }&page=interviewPage&verified=true`}
          />
          {/* <iframe
         className="iFrameStyles"

        ></iframe> */}
        </>
      )}
    </>
  );
}

export default ChatBotPage;
