import React from "react";
import { Loader, Placeholder } from "rsuite";
import PropTypes from "prop-types";
import { t } from "i18next";
function Loading({ rows = 3 }) {
  return (
    <>
      <Placeholder.Paragraph rows={rows} />
      <Loader center content={t("loading", { defaultValue: "Loading" })} />
    </>
  );
}
Loading.propTypes = {
  rows: PropTypes.number,
};
export default Loading;
