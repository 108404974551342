import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useFieldArray } from "react-hook-form";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Trans, useTranslation } from "react-i18next";
import EmailFields from "./EmailFields";

const EmailForm = ({ emails, register, setValue, control, errors, handleSubmit, getValues, setError, clearErrors,resume,setUpdateResumeDataData }) => {
  const {
    fields: emailsFields,
    append: emailsAppend,
    remove: emailsRemove,
    replace: emailsReplace,
  } = useFieldArray({
    control,
    name: "emails",
  });
  const { t } = useTranslation();
const [index, setIndex]=useState([]);
  const emailData = (data) => {
    if (data?.text && data?.text !== "") {
      return data.text;
    } else if (data?.email && data?.email !== "") {
      return data.email;
    } else {
      return "";
    }
  };

  const handleAddEmail = () => {
    if (getValues()?.emails?.length > 0) {
      let emailFlag = true;
      for (let i in getValues()?.emails) {
        if (getValues()?.emails[i]?.email?.trim() == "" || getValues()?.emails[i]?.email === undefined || getValues()?.emails[i]?.email == null) {
          emailFlag = false;
          setError("emails[" + i + "].email",
            {
              type: "manual",
              message: t("emailIsRequired",{defaultValue:"Email is required"}),
            }
          );
        } else {
          if (getValues()?.emails[i]?.email != "") {
            let pattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
            if (!pattern.test(getValues()?.emails[i]?.email)) {
              emailFlag = false;
              setError("emails[" + i + "].email",
                {
                  type: "manual",
                  message: t("pleaseEnterValidEmailAddress", { defaultValue: "please enter valid email address" }),
                }
              );
            }
          }
        }
      }

      for (let i = 0; i < getValues()?.emails.length; i++) {
        for (let j = 0; j < getValues()?.emails.length; j++) {
          if (i != j) {
            if (getValues()?.emails[i]?.email == getValues()?.emails[j].email) {
              emailFlag = false
              setError("emails[" + j + "].email",
                {
                  type: "manual",
                  message: t("emailAlreadyExists",{defaultValue:"Email already exists"}),
                }
              );
            }
          }
        }
      }

      if (emailFlag) {
        clearErrors(["emails"])
        emailsAppend({
          email: "",
          // subscribeStatus: "",
        });
      }
    } else {
      emailsAppend({
        email: "",
        // subscribeStatus: "",
      });
    }
  };

  useEffect(() => {
    // console.log(emails, "emailsjdbfjbdf");
    if (emails && emails.length > 0) {
      emailsReplace();
      let ind = emails.map((_,i)=>i);
      setIndex(ind)
      emailsAppend(
        emails?.map((x,i) => {
          return {
            email: emailData(x),
            // subscribeStatus: x.subscribeStatus ? x.subscribeStatus : "",
          };
        })
      );
    } else {
      emailsReplace();
      emailsAppend([
        {
          email: "",
          // subscribeStatus: "",
        },
      ]);
    }
  }, []);
  return (
    <>
      <div className="row w-100 pb-2" >
        <EmailFields
        indeee={index}
          fields={emailsFields}
          register={register}
          remove={emailsRemove}
          errors={errors}
          control={control}
          getValues={getValues}
          setError={setError}
          clearErrors={clearErrors}
          resume={resume} setUpdateResumeDataData={setUpdateResumeDataData} 
        />
      </div>
      <div className="row w-100">
        <div className="col-sm-12 text-center ">
          <div className="justify-content-center d-flex">
            <span
              className="px-2 IconArea actionIcon actionIcon-sm "
              onClick={handleAddEmail}
            >
              <FontAwesomeIcon icon={faPlusCircle}></FontAwesomeIcon>
            </span>
          </div>
          <div onClick={handleAddEmail}>
            <span className="text-secondary">
              <Trans
                i18nKey="addEmail"
                defaults="Add Email"
              />
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmailForm;