import PropTypes from "prop-types";
import React, { useState } from "react";
import { Trans } from "react-i18next";
import { Placeholder, Whisper, Tooltip } from "rsuite";
import parse from "html-react-parser";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileText, faDownload } from "@fortawesome/free-solid-svg-icons";
import Loading from "../../../../Loder";

const ResumeView = ({ pdfFileName, data, loading,type }) => {
  console.log(pdfFileName, data, "sdert");
  let Resume;

  if (pdfFileName && pdfFileName != "" && data?.result?.base64Data) {
    if (
      pdfFileName &&
      pdfFileName != "" &&
      pdfFileName?.toLocaleLowerCase().includes(".pdf") &&
      data?.result?.base64Data !== ""
    ) {
      Resume = (
        <iframe
          src={`data:application/pdf;base64,${data?.result?.base64Data}#toolbar=0`}
          width="100%"
          height={type == "ResumeTab" ? "1000" : "100%"}
        />
      );
    } else if (
      (pdfFileName && pdfFileName.toLocaleLowerCase().includes(".htm")) ||
      pdfFileName.toLocaleLowerCase().includes(".html")
    ) {
      let decode = atob(
        data?.result?.base64Data ? data?.result?.base64Data : ""
      );
      Resume = (
        <>
          <div
            className="overflow-auto p-2"
            style={{
              height: type == "ResumeTab" ? "" : "100%",
            }}
          >
            {parse(decode)}
          </div>
        </>
      );
    } else if (
      pdfFileName &&
      pdfFileName.toLocaleLowerCase().includes(".png")
    ) {
      Resume = (
        <img
          src="data:image/png;base64,' + data.base64Data + '"
          width="100%"
          height="100%"
        ></img>
      );
    } else if (
      pdfFileName &&
      pdfFileName.toLocaleLowerCase().includes(".jpg")
    ) {
      Resume = (
        <img
          src="data:image/jpg;base64,' + data.base64Data + '"
          width="100%"
          height="100%"
          className="overflow-hidden"
        ></img>
      );
    } else if (
      pdfFileName &&
      pdfFileName.toLocaleLowerCase().includes(".jpeg")
    ) {
      Resume = (
        <img
          src="data:image/jpeg;base64,' + data.base64Data + '"
          width="100%"
          height="100%"
        ></img>
      );
    } else if (
      pdfFileName &&
      data?.result?.base64Data == "" &&
      pdfFileName !== ""
    ) {
      Resume = (
        <>
          <div className="justify-content-center align-items-center text-center"
          style={{
            height: type == "ResumeTab" ? "160px" : "",
            marginTop: type == "ResumeTab" ? "100px" : "",
          }}
          >
            <div>
              <h5 className="ps-3">
                <Trans
                  i18nKey="unableToDisplayThisResumePleaseDownloadTheResumeToViewIt"
                  defaults="Unable To Display This Resume Please Download the Resume to View it."
                />
              </h5>
            </div>
          </div>
        </>
      );
    } else {
      Resume = (
        <>
          {loading ? (
            <div className="card">
              <div className="card-body">
                <Placeholder.Grid rows={2} columns={2} active />
              </div>
            </div>
          ) : (
            <>
              <h5
                className=" d-flex justify-content-center align-items-center p"
                style={{ height: "350px" }}
              >
                <FontAwesomeIcon icon={faFileText} className="me-2" />
                <Trans
                  i18nKey="resumeIsNotAvailable"
                  defaults="Resume is Not Available"
                />
              </h5>
            </>
          )}
        </>
      );
    }
  } else {
    Resume = (
      <>
        <h5
          className=" d-flex justify-content-center align-items-center p"
          style={{ height: "350px" }}
        >
          <FontAwesomeIcon icon={faFileText} className="me-2" />
          <Trans
            i18nKey="resumeIsNotAvailable"
            defaults="Resume is Not Available"
          />
        </h5>
      </>
    );
  }
  return <>{loading ? <Loading rows={8} /> : <>{Resume}</>}</>;

};
export default ResumeView;
