import React, { useEffect, useRef, useState } from "react";
import { InputGroup } from "rsuite";
import { Trans } from "react-i18next";
import SearchKey from "../jobs/SearchBarComponents/SearchKey";
import ExperenceKey from "../jobs/SearchBarComponents/ExperenceKey";
import LocationKey from "../jobs/SearchBarComponents/LocationKey";

function SearchBarNew({
  keyInput,
  SetKeyInput,
  experienceInput,
  setExperienceInput,
  locationInput,
  setLocationInput,
  searchJson,
  searchJobs,
  isLoading,
  locationObj,
  clearSearch,
  showClearButton,
  setShowClearButton,
}) {
  return (
    <>
      <div className="d-flex flex-column align-items-center">
      {/* <div className="d-flex  align-items-start">
           <a
                href="/jobsone"
                className=""
              >
                <Trans i18nKey="backToSearch" defaults="BackToSearch" />
              </a>

           </div> */}
        <div className="d-flex flex-column align-items-end">
          <InputGroup className="shadow container-div input-alignment bg-white">
            <i className="fa-solid fa-magnifying-glass ms-3 h-20px w-auto d-flex mx-auto"></i>
            <span className="ms-3">
              <SearchKey
                values={keyInput}
                setValues={SetKeyInput}
                SearchBodyData={searchJson}
              />
            </span>
            <div className="separetor" />
            <ExperenceKey
              value={experienceInput}
              setValue={setExperienceInput}
            />
            <div className="separetor" />
            <LocationKey
              value={locationInput}
              setValue={setLocationInput}
              locationObj={locationObj}
            />
            <span>
              <button
                type="button"
                className="btn btn-primary button-radius btnStyle"
                onClick={searchJobs}
                disabled={isLoading}
              >
                <Trans i18nKey="search" defaults="Search" />
              </button>
            </span>
          </InputGroup>

          

          {showClearButton && (
            <div className="clear-button me-4 mt-3 text-end">
              <a
                href="#"
                className="ms-2 button-radius clear-link"
                onClick={(e) => {
                  e.preventDefault();
                  clearSearch();
                }}
              >
                <Trans i18nKey="clear" defaults="Clear" />
              </a>
            </div>
          )}
          
        </div>
      </div>
    </>

    // <div className="d-flex flex-column align-items-center">
    //   <InputGroup className="shadow container-div input-alignment bg-white">
    //     <i className="fa-solid fa-magnifying-glass ms-3 h-20px w-auto d-flex mx-auto"></i>
    //     <span className="ms-3">
    //       <SearchKey
    //         values={keyInput}
    //         setValues={SetKeyInput}
    //         SearchBodyData={searchJson}
    //       />
    //     </span>
    //     <div className="separetor" />
    //     <ExperenceKey value={experienceInput} setValue={setExperienceInput} />
    //     <div className="separetor" />
    //     <LocationKey
    //       value={locationInput}
    //       setValue={setLocationInput}
    //       locationObj={locationObj}
    //     />
    //     <span>
    //       <button
    //         type="button"
    //         className="btn btn-primary button-radius btnStyle"
    //         onClick={searchJobs}
    //         disabled={isLoading}
    //       >
    //         <Trans i18nKey="search" defaults="Search" />
    //       </button>
    //     </span>
    //   </InputGroup>

    //   {showClearButton && (
    //     <div
    //       className="clear-button me-4 text-end"
    //     >
    //       <a
    //         href="#"
    //         className="ms-2 button-radius clear-link"
    //         onClick={(e) => {
    //           e.preventDefault();
    //           clearSearch();
    //         }}
    //       >
    //         <Trans i18nKey="clear" defaults="Clear" />
    //       </a>
    //     </div>
    //   )}
    // </div>
  );
}

export default SearchBarNew;
