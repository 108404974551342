import React from "react";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import { AutoComplete } from "rsuite";
import { useTranslation } from "react-i18next";
import { Trans } from "react-i18next";
// import { useGetSuggestionsMutation } from "../../../services/suggestions"
export default function DegreeForm({
  index,
  control,
  register,
  registerName,
  column,
  errors,
  type
}) {
  // const [esDataArr, { data: esData }] = useGetSuggestionsMutation();
  const { t } = useTranslation();

  return (
    <>
      <label>{t("degree", { defaultValue: "Degree" })}</label>
      {column?.required && <span className="text-danger">{"*"}</span>}
      <Controller
        control={control}
        name={registerName}
        refs={register(`education[${index}].degree`, {
          required: column?.required ? true : false,
        })}
        render={({ field: { onChange, onBlur, value } }) => (
          <AutoComplete
            onChange={(v) => {
              onChange(v);
              // esDataArr({ "context": "degree", "text": v });
            }}
            // data={esData?.data.map((degree) => degree.text)}

            data={[]}
            disabled={
              type == "customLayout" || column?.editable ? false : true
            }
            size="sm"
            placeholder={
              t("enter", { defaultValue: "Enter" }) +
              " " +
              t("degree", { defaultValue: "Degree" })
            }
            className="w-100"
            value={value}
          />
        )}
      />
      {errors?.["education"]?.[index]?.degree && (
        <span className="text-danger mt-3">
          {errors?.["education"]?.[index]?.degree?.message != "" ? (
            errors?.["education"]?.[index]?.degree?.message
          ) : (
            <Trans i18nKey="degreeIsRequired" defaults="Degree is required" />
          )}{" "}
        </span>
      )}
    </>
  );
}

DegreeForm.propTypes = {
  column: PropTypes.object,
  index: PropTypes.number,
  control: PropTypes.object,
  register: PropTypes.func,
  setValue: PropTypes.func,
  getValues: PropTypes.func,
  errors: PropTypes.object,
  itemData: PropTypes.object,
  registerName: PropTypes.string,
};
