

import React, { useEffect, useRef, useState ,useContext} from "react";
import { useNavigate, useLocation ,useRouteLoaderData} from "react-router-dom";
import usePost from "../../customhooks/UsePost";
import JobsCard from "./JobsCard";
import DisplayJob from "./DisplayJob";
import SearchBarNew from "./SearchBarNew";
import searchJsonConfig from "../../utilities/search.json";
import queryString from "query-string";
import { Trans } from "react-i18next";
import axios from "../../customhooks/axiosConfig";
import UserContext from "../../app/context/UserContext";
import { Loader } from "rsuite";
import InfiniteScroll from "react-infinite-scroll-component";

function ListJobs() {
  const [Page, setPage] = useState(1);
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(0);
  const [total, setTotal] = useState(0);
  const [keyInput, SetKeyInput] = useState([]);
  const [experienceInput, setExperienceInput] = useState("");
  const [locationInput, setLocationInput] = useState("");
  const [SearchedJobs, setSearchedJobs] = useState([]);
  const [searchID, setSearchID] = useState("");
  const [searchBody, setSearchBody] = useState({});
  const [searchJson, setSearchJson] = useState(searchJsonConfig.searchJSON);
  const navigate = useNavigate();
  const location = useLocation();
  const [showClearButton, setShowClearButton] = useState(false);
  const [selectedJob, setSelectedJob] = useState(SearchedJobs[0]);
  const [hasMore, setHasMore] = useState(true); 
  const Loadedetails = useRouteLoaderData("list");

  const { user, setUserDetails } = useContext(UserContext);

  const locationObj = useRef({
    city: "",
    country: "",
    pin: { lat: 0, lon: 0 },
    state: "",
    zipcode: "",
  });

  const {
    post: getJobs,
    data,
    isLoading,
    error,
  } = usePost("/jobsearch/search");

  useEffect(() => {
    const params = new URLSearchParams(location.search);

    if (params.toString().length > 0) {
      const keywords = params.get("keywords");
      const city = params.get("city");
      const state = params.get("state");
      const country = params.get("country");
      const zipcode = params.get("zipcode");
      const lat = params.get("lat");
      const lon = params.get("lon");
      const searchID = params.get("searchID");
      const locationText = params.get("locationtext")

      if (keywords) SetKeyInput(keywords.split(","));
      if(locationText){
        setLocationInput(locationText);
      }else  if (city || state || country || zipcode || lat || lon) {
        locationObj.current = { city, state, country, zipcode, pin: { lat, lon } };
        setLocationInput(`${city ? city + ", " : ""}${state ? state + ", " : ""}${country}`);
      }
      if (searchID) {
        setSearchID(searchID);
        fetchSearchResults(searchID);
      }
    } else {
      getInitialJobs();
    }
  }, [location.search]);

  const fetchSearchResults = async (searchID) => {
    try {
      const response = await axios.get(
        `${window.location.origin}/jsv4/webapi/jobsearch/SearchIdResluts/${searchID}`
      );
      const searchResults = response.data;
      setSearchBody(searchResults?.data?.body ?? {});
      setSearchJson(searchResults?.data?.body);
      console.log(searchResults?.data?.body,"searchResults?.data?.bodysearchResults?.data?.body")
      // if (
      //   searchResults?.data?.body?.searchKey &&
      //   searchResults?.data?.body?.searchKey.length > 0  
      // ) {
      //   setShowClearButton(true);
      // }
      // if( searchResults?.data?.body?.search && searchResults?.data?.body?.search.length > 0){
      //   setShowClearButton(true);
      // }
      if (
        (searchResults?.data?.body?.searchKey && searchResults?.data?.body?.searchKey.length > 0) ||
        (searchResults?.data?.body?.search && searchResults?.data?.body?.search.length > 0)
      ) {
        setShowClearButton(true);
      }
      

      if (searchResults?.data?.body) {
        let searchData = searchResults?.data?.body;
        searchData.searchID = searchID;
        searchData.newSearch = false;
        searchData.filter = [];
        searchData.size = 10;
        searchData.skip = 0;
        getJobs(searchData).then((res) => {
          setSearchedJobs(res?.data?.result ?? []);
        });
      }
    } catch (error) {
      console.error("Error fetching search results:", error);
    }
  };

  const getInitialJobs = () => {
    let obj = {};
    obj.newSearch = true;
    obj.companyID = Loadedetails?.companyID;
    obj.size = 10;
    obj.projection = [
      "createdAt",
      "title",
      "displayName",
      "job.details.jobTitle.text",
      "job.requirements.skills.skills.text",
      "job.details.locations",
      "job.details.jobTitle.jobType",
      "job.details.offeredSalary",
      "job.details.jobTiming",
      "job.details.workingHours",
      "job.details.jobDescription.text",
      "job.otherInformation.industry.classified.industryLabel",
      "customData.6112806bc9147f673d28c6eb.64abb309db7899f5908c77aa.value",
      "passcode",
    ];

    getJobs(obj).then((res) => {
      setSearchedJobs(res?.data?.result ?? []);
      setSearchID(res?.searchID ?? "");

      const queryWithSearchID = { searchID: res?.searchID ?? "" };
      const queryStringParams = queryString.stringify(queryWithSearchID);
      const newPath = `/careers/list?${queryStringParams}`;
      window.history.replaceState({}, "", newPath);
    });
  };

  const updateUrlWithQueryString = (query, searchID) => {
    const queryWithSearchID = { ...query, searchID };
    const queryStringParams = queryString.stringify(queryWithSearchID);
    const newPath = `/careers/list?${queryStringParams}`;
    window.history.replaceState({}, "", newPath);
  };

  const buildQuery = () => {
    const query = {};
    if (keyInput.length > 0) {
      query.keywords = keyInput.join(", ");
    }
 
    if (experienceInput) {
      if (experienceInput == "6") {
        query.minExp = 5;
        query.maxExp = 50;
      } else {
        query.experience = experienceInput;
      }
    }


    if (locationInput) {
      const { city, state, country, zipcode, pin, text } = locationObj.current;
      if (city) query.city = city;
      if (state) query.state = state;
      if (country) query.country = country;
      if (zipcode) query.zipcode = zipcode;
      if (pin?.lat) query.lat = pin?.lat;
      if (pin?.lon) query.lon = pin?.lon;
      if (text) query.locationtext = text;
    }
    return query;
  };

  const searchJobs = () => {
    const query = buildQuery();
    let obj = JSON.parse(JSON.stringify(searchJsonConfig.searchJSON));

    setShowClearButton(keyInput.length > 0 || experienceInput || locationInput);

    obj.searchKey = keyInput;

    if (locationObj.current && locationInput && locationInput !== "") {
      obj.search.push({
        type: "location",
        location: [locationObj.current],
      });
    }

    if (parseInt(experienceInput) < 6) {
      obj.search.push({
        type: "experience",
        maxExp: experienceInput,
        minExp: 0,
      });
    }
    if (parseInt(experienceInput) === 6) {
      obj.search.push({
        type: "experience",
        maxExp: 50,
        minExp: 5,
      });
    }
    obj.newSearch = true;
    obj.size = 10;
    obj.page = 1;
    obj.skip = 0;
    obj.companyID = Loadedetails?.companyID;

    getJobs(obj).then((res) => {
      setSearchedJobs(res?.data?.result ?? []);
      setSearchID(res?.searchID ?? "");
      updateUrlWithQueryString(query, res?.searchID);
    });
  };

  const handleJobClick = (job) => {
    setSelectedJob(job);
  };

  const clearSearch = () => {
    SetKeyInput([]);
    setExperienceInput("");
    setLocationInput("");
    let obj = {};
    obj.size = 10;
    obj.newSearch = true;
    obj.companyID = Loadedetails?.companyID;
    obj.sort = [{ type: "date", order: "desc" }];
    obj.projection = [
      "createdAt",
      "title",
      "displayName",
      "job.details.jobTitle.text",
      "job.requirements.skills.skills.text",
      "job.details.locations",
      "job.details.jobTitle.jobType",
      "job.details.offeredSalary",
      "job.details.jobTiming",
      "job.details.workingHours",
      "job.details.jobDescription.text",
      "job.otherInformation.industry.classified.industryLabel",
      "customData.6112806bc9147f673d28c6eb.64abb309db7899f5908c77aa.value",
      "passcode",
    ];
    setShowClearButton(false);
    getJobs(obj).then((res) => {
      setSearchedJobs(res?.data?.result ?? []);
      setSearchID(res?.searchID ?? "");

      const queryWithSearchID = { searchID: res?.searchID ?? "" };
      const queryStringParams = queryString.stringify(queryWithSearchID);
      const newPath = `/careers/list?${queryStringParams}`;
      window.history.replaceState({}, "", newPath);
    });
  };

  const loadMore = async () => {
    if (searchBody) {
      let searchData = { ...searchBody };
      searchData.searchID = searchID;
      searchData.newSearch = false;
      searchData.filter = [];
      searchData.size = limit + 10;
      searchData.skip = SearchedJobs.length;

      try {
        const res = await getJobs(searchData);
        const newJobs = res?.data?.result ?? [];

        if (newJobs.length === 0) {
          setHasMore(false);
        } else {
          const filteredNewJobs = newJobs.filter(
            (newJob) =>
              !SearchedJobs.some((existingJob) => existingJob._id === newJob.id)
          );

          setSearchedJobs((prevJobs) => [...prevJobs, ...filteredNewJobs]);
        }
      } catch (error) {
        console.error("Error fetching jobs:", error);
      }
    } else {
      try {
        const response = await axios.get(
          `${window.location.origin}/jsv4/webapi/jobsearch/SearchIdResluts/${searchID}`
        );
        const searchResults = response.data;

        setSearchBody(searchResults?.data?.body ?? {});
        setSearchJson(searchResults?.data?.body);
        // setShowClearButton(true);

        if (searchResults?.data?.body) {
          let searchData = { ...searchResults?.data?.body };
          searchData.searchID = searchID;
          searchData.newSearch = false;
          searchData.filter = [];
          searchData.size = limit + 10;
          searchData.skip = SearchedJobs.length;

          try {
            const res = await getJobs(searchData);
            const newJobs = res?.data?.result ?? [];

            if (newJobs.length === 0) {
              setHasMore(false);
            } else {
              const filteredNewJobs = newJobs.filter(
                (newJob) =>
                  !SearchedJobs.some(
                    (existingJob) => existingJob.id === newJob.id
                  )
              );

              setSearchedJobs((prevJobs) => [...prevJobs, ...filteredNewJobs]);
            }
          } catch (error) {
            console.error("Error fetching jobs:", error);
          }
        }
      } catch (error) {
        console.error("Error fetching search results:", error);
      }
    }
  };

  return (
    <>
      <div className="pt-5">
        <SearchBarNew
          keyInput={keyInput}
          SetKeyInput={SetKeyInput}
          experienceInput={experienceInput}
          setExperienceInput={setExperienceInput}
          locationInput={locationInput}
          setLocationInput={setLocationInput}
          searchJson={searchJson}
          searchJobs={() => searchJobs()}
          isLoading={isLoading}
          locationObj={locationObj}
          clearSearch={clearSearch}
          showClearButton={showClearButton}
          setShowClearButton={setShowClearButton}
        />
      </div>
      <div className=" pb-0 ms-1 mt-2 text-primary">
        <h5>
          {" "}
          <>
            <Trans i18nKey="showing" defaults="of" />{" "}
            {SearchedJobs.indexOf(selectedJob) + 1 === 0
              ? 1
              : SearchedJobs.indexOf(selectedJob) + 1}{" "}
            - {SearchedJobs.length} <Trans i18nKey="jobs" defaults="Jobs" />
          </>{" "}
        </h5>
      </div>

      {/* {SearchedJobs.length > 0 ? (
        <div className="mt-1 p-0">
          <div className="row">
            <div className="col-5">
              <InfiniteScroll
                dataLength={SearchedJobs.length}
                next={loadMore}
                hasMore={hasMore}
                loader={
                  <h3 className="d-flex justify-content-center pt-2">
                    {" "}
                    <Loader content="Loading..." size="sm" />{" "}
                  </h3>
                }
                endMessage={
                  <p className="d-flex justify-content-center pt-2">
                    <b>
                      <Trans
                        i18nKey="Yay!YouHaveSeenItAll"
                        defaults="Yay! You have seen it all"
                      />
                    </b>
                  </p>
                }
              >
                {SearchedJobs.map((job) => (
                  <JobsCard
                    key={job._id}
                    job={job}
                    onClick={() => handleJobClick(job)}
                    isSelected={
                      selectedJob
                        ? selectedJob._id === job._id
                        : SearchedJobs[0]._id === job._id
                    }
                  />
                ))}
              </InfiniteScroll>
            </div>
            <div className="col-7 job-details-container displayStickey">
              <DisplayJob job={selectedJob ? selectedJob : SearchedJobs[0]} />
            </div>
          </div>
        </div>
      ) : (
        <div className="d-flex justify-content-center pt-5">
          <h1>
            <Trans i18nKey="noJobsFound" defaults="No Jobs Found" />
          </h1>
        </div>
      )} */}


{SearchedJobs.length > 0 ? (
        <div className="mt-1 p-0">
          <div className="row">
            <div className="col-5">
              <InfiniteScroll
                dataLength={SearchedJobs.length}
                next={loadMore}
                hasMore={hasMore}
                loader={
                  <h3 className="d-flex justify-content-center pt-2">
                    {" "}
                    <Loader content="Loading..." size="sm" />{" "}
                  </h3>
                }
                // endMessage={
                //   <p className="d-flex justify-content-center pt-2">
                //     <b>
                //       <Trans
                //         i18nKey="Yay!YouHaveSeenItAll"
                //         defaults="Yay! You have seen it all"
                //       />
                //     </b>
                //   </p>
                // }
              >
                {SearchedJobs.map((job) => (
                  <JobsCard
                    key={job._id}
                    job={job}
                    onClick={() => handleJobClick(job)}
                    isSelected={
                      selectedJob
                        ? selectedJob._id === job._id
                        : SearchedJobs[0]._id === job._id
                    }
                  />
                ))}
              </InfiniteScroll>
            </div>
            <div className="col-7 job-details-container displayStickey">
              <DisplayJob job={selectedJob ? selectedJob : SearchedJobs[0]} />
            </div>
          </div>
        </div>
      ) : (
        <div className="d-flex justify-content-center pt-5">
          <h1>
            <Trans i18nKey="noJobsFound" defaults="No Jobs Found" />
          </h1>
        </div>
      )}




    </>
  );
}

export default ListJobs;
