import React, { useEffect, useState } from "react";
import { Modal, Loader } from "rsuite";
import { Trans, useTranslation } from "react-i18next";
import { Button } from "react-bootstrap";
import PropTypes from "prop-types";
const Login = React.lazy(() => import("../Login/Login"));
const Signup = React.lazy(() => import("../Signup/Signup"));
// const ForgotPassword = React.lazy(()=>import("../Login/Forgotpassword/ForgotPassword"))
const ResetPassword = React.lazy(() => import("../Login/Resetpassword/ResetPassword"))


export default function ApplyNowModal({
    from,
    openModal,
    setOpenModal,
    setOpenCreateProfile,
    setOpenLinkProfile,
    setOpenRefer,
    setPage
}) {
    const [target, setTarget] = useState("login");
    const [type, setType]= useState("");
    const [guestEmail, setGuestEmail] = useState("");
    const [guestFirstName, setGuestFirstName] = useState("")
    const [guestLastName, setGuestLastName] = useState("")
    return (
        <>
            <Modal
                open={openModal}
                onClose={() => {
                    setTarget("login")
                    setOpenModal(false)
                }}
                overflow={false}
                // enforceFocus={true}
                size="sm"
            >
                <Modal.Header>
                </Modal.Header>
                <Modal.Body>
                    {
                        target == "login" && (
                            <React.Suspense
                                fallback={<Loader center speed="normal" size="sm" backdrop={true} />}
                            >
                                <Login
                                    setTarget={setTarget}
                                    setOpenCreateProfile={setOpenCreateProfile}
                                    setOpenModal={setOpenModal}
                                    setOpenLinkProfile={setOpenLinkProfile}
                                    from={from}
                                    setOpenRefer={setOpenRefer}
                                    setPage={setPage}
                                    setType={setType}
                                    setGuestEmail={setGuestEmail}
                                />
                            </React.Suspense>
                        )
                    }
                    {
                        target == "signUp" && (
                            <React.Suspense
                                fallback={<Loader center speed="normal" size="sm" backdrop={true} />}
                            >
                                <Signup
                                    setTarget={setTarget}
                                    setOpenCreateProfile={setOpenCreateProfile}
                                    setOpenModal={setOpenModal}
                                    setOpenLinkProfile={setOpenLinkProfile}
                                    from={from}
                                    setPage={setPage}
                                    setOpenRefer={setOpenRefer}
                                    setType={setType}
                                    setGuestEmail={setGuestEmail}
                                    setGuestFirstName={setGuestFirstName}
                                    setGuestLastName={setGuestLastName}
                                />
                            </React.Suspense>
                        )
                    }
                    {
                        target == "forgotPassword" && (
                            <React.Suspense
                                fallback={<Loader center speed="normal" size="sm" backdrop={true} />}
                            >
                                <ResetPassword
                                    setTarget={setTarget}
                                    setOpenCreateProfile={setOpenCreateProfile}
                                    setOpenModal={setOpenModal}
                                    setOpenLinkProfile={setOpenLinkProfile}
                                    from={from}
                                    setOpenRefer={setOpenRefer}
                                    setPage={setPage}
                                    type={type}
                                    setType={setType}
                                    guestEmail={guestEmail}
                                    setGuestEmail={setGuestEmail}
                                    guestLastName={guestLastName}
                                    guestFirstName={guestFirstName}
                                />
                            </React.Suspense>
                        )
                    }

                </Modal.Body>
            </Modal>
        </>
    );
}


ApplyNowModal.propTypes = {
    openModal: PropTypes.any,
    setOpenModal: PropTypes.any,
    profileModalShow: PropTypes.any,
    setUserData: PropTypes.any,

};