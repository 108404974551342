import { useState } from 'react';
import axiosConfig from './axiosConfig';


const usePost = (url) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);

  const post = async (postData) => {
    setIsLoading(true);
    setError(null);

    try {
      // const response = await fetch(url, {
      //   method: 'POST',
      //   headers: {
      //     'Content-Type': 'application/json',
      //   },
      //   body: JSON.stringify(postData),
      // });

      const response = await axiosConfig.post(url, postData);

      // const result = await response.json();
      setIsLoading(false);
      setData(response?.data);
      return response?.data;
    } catch (err) {
      setIsLoading(false);
      setError(err.message);
      throw err;
    }
  };

  return { post, isLoading, error, data };
};

export default usePost;