import React, { useState, useEffect } from "react";
import Loading from "../../../utilities/Loading";
const FullName = React.lazy(() => import("./fields/FullName"));
import { Whisper, Tooltip, Popover } from "rsuite";
import { Trans } from "react-i18next";
import { t } from "i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const ContactDetails = React.lazy(() => import("./fields/ContactDetails"));
const Summary = React.lazy(() => import("./fields/Summary"));
const YearsOfExperience = React.lazy(() =>
  import("./fields/YearsOfExperience")
);
const CurrentCompany = React.lazy(() => import("./fields/CurrentCompany"));
const Skills = React.lazy(() => import("./fields/Skills"));
import "../MyAccount.css"
import {
  faBuilding,
  faBusinessTime,
  faAddressBook,
  faLocationDot,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import Location from "./fields/Location";
const CompensationMinimumPay = React.lazy(() =>
  import("./fields/CompensationMinimumPay")
);
const CompensationMinExpected = React.lazy(() =>
  import("./fields/CompensationMinExpected")
);
const CompensationMaxExpected = React.lazy(() =>
  import("./fields/CompensationMaxExpected")
);
const CompensationMaximumPay = React.lazy(() =>
  import("./fields/CompensationMaximumPay")
);
const CompensationCurrency = React.lazy(() =>
  import("./fields/CompensationCurrency")
);
const CompensationPeriod = React.lazy(() =>
  import("./fields/CompensationPeriod")
);
const Gender = React.lazy(() => import("./fields/Gender"));
const Nationality = React.lazy(() => import("./fields/Nationality"));
const MaritalStatus = React.lazy(() => import("./fields/MaritalStatus"));
const Age = React.lazy(() => import("./fields/Age"));
const DateOfBirth = React.lazy(() => import("./fields/DateOfBirth"));
const VeteranStatus = React.lazy(() => import("./fields/VeteranStatus"));
const Ethnicity = React.lazy(() => import("./fields/Ethnicity"));
const Languages = React.lazy(() => import("./fields/Languages"));
const Websites = React.lazy(() => import("./fields/Websites"));
const PreferedRoles = React.lazy(() => import("./fields/PreferedRoles"));
const PreferedLocation = React.lazy(() => import("./fields/PreferedLocation"));
const WorkPermit = React.lazy(() => import("./fields/WorkPermit"));
const NoticePeriod = React.lazy(() => import("./fields/NoticePeriod"));
import { DateTime } from "luxon";
const ProfileStandard = ({
  item,
  userDetails,
  UpdateResumeData,
  resumeID,
  resumeData,
  setUpdateResumeDataData,
  type,
  handleCopyUrl,
  copyLink
}) => {
  const [id, setId] = useState(resumeID);
  // console.log(item?.fieldCode);
  useEffect(() => {
    if (resumeID) {
      setId(resumeID);
    }
  }, [resumeID]);
  return (
    <>
      {item?.fieldCode == "Full Name" ? (
        <div className="d-flex align-items-center">
          <div className="row d-flex">
            <React.Suspense fallback={<Loading />}>
              <FullName
                resumeId={id}
                fieldObj={item}
                userDetails={userDetails}
                resume={resumeData}
                setUpdateResumeDataData={setUpdateResumeDataData}
                type={type}
                handleCopyUrl={handleCopyUrl}
                copyLink={copyLink}
              />
            </React.Suspense>
          </div>
          {/* {type == "referre" && (
            <Whisper
              followCursor
              placement="auto"
              trigger={"click"}
              speaker={
                <Popover>
                  <div>
                    <h6>Referrer</h6>
                    <div className="card" style={{width:"250px"}}>
                      <div className="p-2">
                        {ReferrerResumeDetails?.referrer?.fullName}
                        <div>
                          <i class="fa-solid fa-envelope"></i>
                          <span className="ms-2">
                            {ReferrerResumeDetails?.referrer?.emails[0]?.text}
                          </span>
                        </div>
                        <div>
                          <i class="fa-solid fa-mobile"></i>
                          <span className="ms-2">
                            {ReferrerResumeDetails?.referrer?.phones[0]?.text ??
                              "Not specified"}
                          </span>
                        </div>
                        <div>
                          Referred Date :
                          <span className="ms-2">
                            {ReferrerResumeDetails?.updatedAt &&
                            ReferrerResumeDetails?.updatedAt != ""
                              ? DateTime.fromISO(
                                  ReferrerResumeDetails?.updatedAt
                                )
                                  .setLocale("en-us")
                                  .toFormat("dd/MM/yyyy")
                              : "NA"}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </Popover>
              }
            >
              <i class="fa-solid fa-user-group text-primary cursorpointer"></i>
            </Whisper>
          )} */}
        </div>
      ) : null}
      {item?.fieldCode == "contacts" ? (
        <div className="row mt-1" id={item?.fieldCode}>
          <span className="d-flex align-items-center cursor-pointer">
            <Whisper
              followCursor
              placement={"bottom"}
              speaker={
                <Tooltip>
                  <Trans i18nKey="contacts" defaults="Contacts" />
                </Tooltip>
              }
            >
              <span className="displayIcon displayIcon-sm mx-2">
                <FontAwesomeIcon icon={faAddressBook} />
              </span>
            </Whisper>
            <ContactDetails
              resume={resumeData}
              setUpdateResumeDataData={setUpdateResumeDataData}
              resumeID={id}
              fieldObj={item}
              phones={[]}
              emails={[]}
              target="profilePage"
            />
          </span>
        </div>
      ) : null}
      {item?.fieldCode == "Locations" ? (
        <div className="row mt-1" id={item?.fieldCode}>
          <span className="d-flex align-items-center">
            <Whisper
              followCursor
              placement={"bottom"}
              speaker={
                <Tooltip>
                  <Trans i18nKey="location" defaults="Location" />
                </Tooltip>
              }
            >
              <span className="displayIcon displayIcon-sm mx-2">
                <FontAwesomeIcon icon={faLocationDot} />
              </span>
            </Whisper>
            <Location
              resumeId={id}
              setUpdateResumeDataData={setUpdateResumeDataData}
              resume={resumeData}
              fieldObj={item}
              userDetails={userDetails}
            />
          </span>
        </div>
      ) : (
        <></>
      )}
      {item?.fieldCode == "Summary" ? (
        <React.Suspense fallback={<Loading />}>
          <Summary
            resumeId={id}
            userDetails={userDetails}
            UpdateResumeData={UpdateResumeData}
            resume={resumeData}
          />
        </React.Suspense>
      ) : null}
      {item?.fieldCode == "YOE" ? (
        <>
          <span className="d-flex">
            <Whisper
              followCursor
              placement={"bottom"}
              speaker={
                <Tooltip>
                  <Trans
                    i18nKey="yearsOfExperience"
                    defaults="Years Of Experience"
                  />
                </Tooltip>
              }
            >
              <span className="displayIcon displayIcon-sm">
                <FontAwesomeIcon icon={faBusinessTime} />
              </span>
            </Whisper>
            <b className="pe-1">
              {t(item?.i18nKey, { defaultValue: item?.fieldName })}
            </b>
            <React.Suspense fallback={<Loading />}>
              <YearsOfExperience
                resumeId={id}
                UpdateResumeData={UpdateResumeData}
                resume={resumeData}
              />
            </React.Suspense>
          </span>
        </>
      ) : null}
      {item?.fieldCode == "Current Company" ? (
        <>
          <span className="d-flex">
            <Whisper
              followCursor
              placement={"bottom"}
              speaker={
                <Tooltip>
                  <Trans i18nKey="currentCompany" defaults="Current Company" />
                </Tooltip>
              }
            >
              <span className="displayIcon displayIcon-sm pe-1">
                <FontAwesomeIcon icon={faBuilding} />
              </span>
            </Whisper>
            <b className="pe-1">
              {t(item?.i18nKey, { defaultValue: item?.fieldName })}
            </b>
            <React.Suspense fallback={<Loading />}>
              <CurrentCompany
                resumeId={id}
                UpdateResumeData={UpdateResumeData}
                resume={resumeData}
              />
            </React.Suspense>
          </span>
        </>
      ) : null}
      {item?.fieldCode == "Skills" ? (
        <React.Suspense fallback={<Loading />}>
          <Skills
            resumeId={id}
            UpdateResumeData={UpdateResumeData}
            resume={resumeData}
          />
        </React.Suspense>
      ) : null}
      {item?.fieldCode == "Notice Period" ? (
        <>
          <span className="d-flex">
            <span className="pe-1">
              <b>{t(item?.i18nKey, { defaultValue: item?.fieldName })} </b>
            </span>
            <React.Suspense fallback={<Loading />}>
              <NoticePeriod
                resumeId={id}
                UpdateResumeData={UpdateResumeData}
                resume={resumeData}
              />
            </React.Suspense>
          </span>
        </>
      ) : null}
      {item?.fieldCode == "Work Permit" ? (
        <>
          <span className="d-flex">
            <span className="pe-1">
              <b>{t(item?.i18nKey, { defaultValue: item?.fieldName })} </b>
            </span>
            <React.Suspense fallback={<Loading />}>
              <WorkPermit
                resumeId={id}
                UpdateResumeData={UpdateResumeData}
                resume={resumeData}
              />
            </React.Suspense>
          </span>
        </>
      ) : null}
      {item?.fieldCode == "Preferred Locations" ? (
        <>
          <div className="">
            <div className="pe-1">
              <b>{t(item?.i18nKey, { defaultValue: item?.fieldName })} </b>
            </div>
            <React.Suspense fallback={<Loading />}>
              <PreferedLocation
                resumeId={id}
                UpdateResumeData={UpdateResumeData}
                resume={resumeData}
              />
            </React.Suspense>
          </div>
        </>
      ) : null}
      {item?.fieldCode == "Preferred Roles" ? (
        <>
          <div className="">
            <div className="pe-1">
              <b>{t(item?.i18nKey, { defaultValue: item?.fieldName })} </b>
            </div>
            <React.Suspense fallback={<Loading />}>
              <PreferedRoles
                resumeId={id}
                UpdateResumeData={UpdateResumeData}
                resume={resumeData}
              />
            </React.Suspense>
          </div>
        </>
      ) : null}
      {item?.fieldCode == "Websites" ? (
        <>
          <div className="">
            <div className="pe-1">
              <b>{t(item?.i18nKey, { defaultValue: item?.fieldName })} </b>
            </div>
            <React.Suspense fallback={<Loading />}>
              <Websites
                resumeId={id}
                UpdateResumeData={UpdateResumeData}
                resume={resumeData}
              />
            </React.Suspense>
          </div>
        </>
      ) : null}
      {item?.fieldCode == "Gender" ? (
        <>
          <span className="d-flex">
            <span className="pe-1">
              <b>{t(item?.i18nKey, { defaultValue: item?.fieldName })} </b>
            </span>
            <React.Suspense fallback={<Loading />}>
              <Gender
                resumeId={id}
                UpdateResumeData={UpdateResumeData}
                resume={resumeData}
              />
            </React.Suspense>
          </span>
        </>
      ) : null}
      {item?.fieldCode == "Ethnicity" ? (
        <>
          <div className="">
            <div className="pe-1">
              <b>{t(item?.i18nKey, { defaultValue: item?.fieldName })} </b>
            </div>
            <React.Suspense fallback={<Loading />}>
              <Ethnicity
                resumeId={id}
                UpdateResumeData={UpdateResumeData}
                resume={resumeData}
              />
            </React.Suspense>
          </div>
        </>
      ) : null}

      {item?.fieldCode == "Languages" ? (
        <>
          <span className="d-flex">
            <span className="pe-1">
              <b>{t(item?.i18nKey, { defaultValue: item?.fieldName })} </b>
            </span>
            <React.Suspense fallback={<Loading />}>
              <Languages
                resumeId={id}
                resume={resumeData}
                UpdateResumeData={UpdateResumeData}
              />
            </React.Suspense>
          </span>
        </>
      ) : null}
      {item?.fieldCode == "Veteran Status" ? (
        <>
          <div className="">
            <div className="pe-1">
              <b>{t(item?.i18nKey, { defaultValue: item?.fieldName })} </b>
            </div>
            <React.Suspense fallback={<Loading />}>
              <VeteranStatus
                resumeId={id}
                UpdateResumeData={UpdateResumeData}
                resume={resumeData}
              />
            </React.Suspense>
          </div>
        </>
      ) : null}
      {item?.fieldCode == "Date of Birth" ? (
        <>
          <span className="d-flex">
            <span className="pe-1">
              <b>{t(item?.i18nKey, { defaultValue: item?.fieldName })} </b>
            </span>
            <React.Suspense fallback={<Loading />}>
              <DateOfBirth
                resumeId={id}
                UpdateResumeData={UpdateResumeData}
                resume={resumeData}
              />
            </React.Suspense>
          </span>
        </>
      ) : null}
      {item?.fieldCode == "Age" ? (
        <>
          <span className="d-flex">
            <span className="pe-1">
              <b>{t(item?.i18nKey, { defaultValue: item?.fieldName })} </b>
            </span>
            <React.Suspense fallback={<Loading />}>
              <Age
                resumeId={id}
                UpdateResumeData={UpdateResumeData}
                resume={resumeData}
              />
            </React.Suspense>
          </span>
        </>
      ) : null}
      {item?.fieldCode == "Marital Status" ? (
        <>
          <span className="d-flex">
            <span className="pe-1">
              <b>{t(item?.i18nKey, { defaultValue: item?.fieldName })} </b>
            </span>
            <React.Suspense fallback={<Loading />}>
              <MaritalStatus
                resumeId={id}
                UpdateResumeData={UpdateResumeData}
                resume={resumeData}
              />
            </React.Suspense>
          </span>
        </>
      ) : null}
      {item?.fieldCode == "Nationality" ? (
        <>
          <span className="d-flex">
            <span className="pe-1">
              <b>{t(item?.i18nKey, { defaultValue: item?.fieldName })} </b>
            </span>
            <React.Suspense fallback={<Loading />}>
              <Nationality
                resumeId={id}
                UpdateResumeData={UpdateResumeData}
                resume={resumeData}
              />
            </React.Suspense>
          </span>
        </>
      ) : null}
      {item?.fieldCode == "Min Expected Salary" ? (
        <>
          <div className="">
            <div className="pe-1">
              <b>{t(item?.i18nKey, { defaultValue: item?.fieldName })} </b>
            </div>
            <React.Suspense fallback={<Loading />}>
              <CompensationMinExpected
                resumeId={id}
                UpdateResumeData={UpdateResumeData}
                resume={resumeData}
              />
            </React.Suspense>
          </div>
        </>
      ) : null}
      {item?.fieldCode == "Max Expected Salary" ? (
        <>
          <div className="">
            <div className="pe-1">
              <b>{t(item?.i18nKey, { defaultValue: item?.fieldName })} </b>
            </div>
            <React.Suspense fallback={<Loading />}>
              <CompensationMaxExpected
                resumeId={id}
                UpdateResumeData={UpdateResumeData}
                resume={resumeData}
              />
            </React.Suspense>
          </div>
        </>
      ) : null}
      {item?.fieldCode == "CR Period" ? (
        <>
          <div className="">
            <div className="pe-1">
              <b>{t(item?.i18nKey, { defaultValue: item?.fieldName })} </b>
            </div>
            <React.Suspense fallback={<Loading />}>
              <CompensationPeriod
                resumeId={id}
                UpdateResumeData={UpdateResumeData}
                resume={resumeData}
              />
            </React.Suspense>
          </div>
        </>
      ) : null}
      {item?.fieldCode == "CR Currency" ? (
        <>
          <div className="">
            <div className="pe-1">
              <b>{t(item?.i18nKey, { defaultValue: item?.fieldName })} </b>
            </div>
            <React.Suspense fallback={<Loading />}>
              <CompensationCurrency
                resumeId={id}
                UpdateResumeData={UpdateResumeData}
                resume={resumeData}
              />
            </React.Suspense>
          </div>
        </>
      ) : null}
      {item?.fieldCode == "Minimum Annual Pay Rate" ? (
        <>
          <div className="">
            <div className="pe-1">
              <b>{t(item?.i18nKey, { defaultValue: item?.fieldName })} </b>
            </div>
            <React.Suspense fallback={<Loading />}>
              <CompensationMinimumPay
                resumeId={id}
                UpdateResumeData={UpdateResumeData}
                resume={resumeData}
              />
            </React.Suspense>
          </div>
        </>
      ) : null}
      {item?.fieldCode == "Desired Annual Pay Rate" ? (
        <>
          <div className="">
            <div className="pe-1">
              <b>{t(item?.i18nKey, { defaultValue: item?.fieldName })}</b>
            </div>
            <React.Suspense fallback={<Loading />}>
              <CompensationMaximumPay
                resumeId={id}
                UpdateResumeData={UpdateResumeData}
                resume={resumeData}
              />
            </React.Suspense>
          </div>
        </>
      ) : null}
    </>
  );
};

export default ProfileStandard;
