import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import { Form } from "react-bootstrap";
import { Trans } from "react-i18next";
import { t } from "i18next";

const VeteranStatusFormField = ({
  veteranStatusData,
  control,
  getValues,
  errors,
  register,
  setValue,
  fieldList,
  type,
}) => {
  // console.log(veteranStatusData, "veteranStatusData")
  useEffect(() => {
    if (veteranStatusData) {
      setValue("veteranStatus", veteranStatusData);
    }
  }, [veteranStatusData]);
  return (
    <>
      <div className="form-group">
        <label className="text-dark">
        {t(fieldList?.i18nKey, { defaultValue: fieldList?.fieldName })}
        </label>
        {fieldList?.required && <span className="text-danger">{"*"}</span>}
        <Controller
          control={control}
          name={"veteranStatus"}
          refs={register("veteranStatus", {
            required: fieldList?.required ? true : false,
            // validate: {
            //   emptyValue: (v) => (v && v.trim() !== ""),
            // },
          })}
          render={({ field: { onChange, onBlur, value } }) => (
            <Form.Control
              size="sm"
              className="w-100"
              placeholder={
                t("enter", { defaultValue: "Enter" }) +
                " " +
                t("veteranStatus", { defaultValue: "Veteran Status" })
              }
              value={value}
              onChange={onChange}
              disabled={
                type == "customLayout" || fieldList?.editable || !fieldList
                  ? false
                  : true
              }
            />
          )}
        />
        {errors?.veteranStatus && (
          <span className="text-danger ">
            {errors?.veteranStatus?.message != "" ? (
              errors?.veteranStatus?.message
            ) : (
              <Trans
                i18nKey="veteranStatusIsRequired"
                defaults="Veteran Status is required"
              />
            )}{" "}
          </span>
        )}
      </div>
    </>
  );
};

export default VeteranStatusFormField;
