import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import { Form } from "react-bootstrap";
import { Trans } from "react-i18next";
import { t } from "i18next";

const MaxExpectedSalaryFormField = ({
  maxExpectedSalaryData,
  control,
  register,
  getValues,
  setValue,
  errors,
  fieldList,
  type,
}) => {
  useEffect(() => {
    if (maxExpectedSalaryData) {
      setValue("maximumExpectedSalary", maxExpectedSalaryData);
    }else{
      setValue("maximumExpectedSalary", 0); 
    }
  }, [maxExpectedSalaryData]);
  return (
      <div className="row w-100">
        <div className="col-12 ">
          <label className="text-dark">
            {fieldList != undefined ? (
              t(fieldList?.i18nKey, { defaultValue: fieldList?.fieldName })
            ) : (
              <Trans
                i18nKey="maximumExpectedSalary"
                defaults="Maximum Expected Salary"
              />
            )}
          </label>
          {fieldList?.required && <span className="text-danger">{"*"}</span>}
          <Controller
            control={control}
            name="maximumExpectedSalary"
            refs={register("maximumExpectedSalary", {
              required: fieldList?.required ? true : false,
            })}
            render={({ field: { onChange, onBlur, value } }) => (
              <input
                type="number"
                className="form-control form-control-sm"
                placeholder={
                  t("enter", { defaultValue: "Enter" }) +
                  " " +
                  t("maximumExpectedSalary", {
                    defaultValue: "Maximum Expected Salary",
                  })
                }
                onChange={onChange}
                value={value}
                disabled={
                  type == "customLayout" || fieldList?.editable || !fieldList
                    ? false
                    : true
                }
              />
            )}
          />
        </div>
        {errors?.maximumExpectedSalary && (
          <span className="text-danger ">
            {errors?.maximumExpectedSalary?.message != "" ? (
              errors?.maximumExpectedSalary?.message
            ) : (
              <Trans
                i18nKey="maximumExpectedSalaryIsRequired"
                defaults="Maximum Expected Salary is required"
              />
            )}{" "}
          </span>
        )}
      </div>
  );
};
MaxExpectedSalaryFormField.propTypes = {
  maxExpectedSalaryData: PropTypes.string.isRequired,
  register: PropTypes.any.isRequired,
  control: PropTypes.any.isRequired,
  getValues: PropTypes.any.isRequired,
  setValue: PropTypes.any.isRequired,
  errors: PropTypes.any.isRequired,
};

export default MaxExpectedSalaryFormField;
