import React, { useState } from "react";
import parse from "html-react-parser";
import { Trans, useTranslation } from "react-i18next";

const ShowMore = ({ text }) => {
  const { t } = useTranslation();

  // const [showMore, setShowMore] = useState(false);
  // const linesToShow = showMore ? text : text.slice(0, 5);

  // console.log(data.split("<br>").filter(Boolean));
  const lines = text.split("<br>").filter(Boolean); // Split the text into lines using <br> tag.

  // const linesToShow = showMore ? lines : lines.slice(0, 6);
  // const toggleShowMore = () => {
  //   setShowMore(!showMore);
  // };
  const [showFullContent, setShowFullContent] = useState(false);

  // Step 2: Use the state variable "showFullContent" to determine if we should show the full content or not.
  const toggleContent = () => {
    // Step 3: Define a function "toggleContent" that will toggle the value of "showFullContent" when called.
    setShowFullContent(!showFullContent);
  };
  const parsedContent = parse(
    showFullContent ? text : text.slice(0, 1000) // Adjust the character limit as needed
  );
  return (
    <div>
      {/* Step 4: Use "dangerouslySetInnerHTML" to render the HTML content conditionally based on "showFullContent". */}
      {parsedContent}

      {text.length > 400 && (
        <div className="d-flex justify-content-end">
          <button
            onClick={toggleContent}
            className="bg-primary text-white badge"
          >
            {showFullContent
              ? t("showLess", { defaultValue: "Show Less" })
              : t("showMore", { defaultValue: "Show More" })}
          </button>
        </div>
      )}
    </div>
    // <div>
    //   {parse(linesToShow.join("<br>"))}{" "}
    //   {lines.length > 5 && (
    //     <div className="d-flex justify-content-end">
    //       <button onClick={toggleShowMore} className="bg-primary text-white badge">
    //         {showMore ? "Show Less" : "Show More"}
    //       </button>
    //     </div>
    //   )}
    // </div>
  );
};

export default ShowMore;
