import React from "react";
import { useState, useEffect } from "react";
import { Dropdown, Button, Form, FormControl } from "react-bootstrap";
import "../SearchBar.css";
import { Trans, useTranslation } from "react-i18next";

function SmallScreenIndustryAttribute({
  industryAttributesList,
  getJobs,
  setJobsListData,
  setPage,
  setTotal,
  selectedOptions,
  setSelectedOptions,
  setIsSearchMade,
  SearchBodyData,
  setAttributeData,
  isLoading,
  setSearchvalue,
  setShowJobDetails,
  industryTypeSelectedList,
  setIndustryTypeSelectedList,
  industrytags,
  setindustrytags,
}) {
  const { t } = useTranslation();
  const [showDropdown, setShowDropdown] = useState(false);
  const [type, setType] = useState("error");
  const [searchValue, setSearchValue] = useState("");
  //   const [industrytags, setindustrytags] = useState([]);
  //   useEffect(() => {
  //     // if (showDropdown) {
  //       setindustrytags(industryTypeSelectedList);
  //     // }
  //   }, [showDropdown]);

useEffect(() => {
  if (industryTypeSelectedList.length > 0) {
    setindustrytags(industryTypeSelectedList);
  }
}, [industryTypeSelectedList, setindustrytags]);

  //   console.log(selectedOptions,"selectedOptionsselectedOptions")
  const handleDropdownSelect = (option) => {
    if (industrytags.includes(option)) {
      setindustrytags(industrytags.filter((item) => item !== option));
      setIndustryTypeSelectedList(
        industrytags.filter((item) => item !== option)
      );
    } else {
      setindustrytags([...industrytags, option]);
      setIndustryTypeSelectedList([...industrytags, option]);
    }
  };

  const filteredOptions = industryAttributesList
    ?.map((option) => {
      const formattedKey = option?.key
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
      return { ...option, formattedKey };
    })
    ?.filter((option) =>
      option?.formattedKey.toLowerCase().includes(searchValue.toLowerCase())
    );

  return (
    <>
      <Dropdown
        show={showDropdown}
        onToggle={(isOpen) => {
          setShowDropdown(isOpen);
          setSearchValue("");
        }}
        className="position-static"
      >
        <Dropdown.Toggle
          variant="none"
          id="dropdown-basic"
          className="attributeSmall text-muted   rounded-pill w-100 border h-5"
          size="md"
        >
          <Trans i18nKey="industry" defaults="Industry" />
        </Dropdown.Toggle>

        <Dropdown.Menu className="dropdown-menu-custom">
          <FormControl
            type="text"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            placeholder={t("searchIndustry", {
              defaultValue: "Search Industry",
            })}
            className="mx-2 mb-2 atributesSearch custom-form-control  mx-auto"
            // disabled={filteredOptions.length > 0 ? false : true}
          />

          {/* <Form className="dropdown-form   ">
            <div className="scrollable-options">
              {filteredOptions?.map((option) => (
                <div key={option?.key} className="ms-3">
                  <Form.Check
                    className="custom-checkbox"
                    type="checkbox"
                    id={option.key}
                    label={`${option?.formattedKey} (${option?.doc_count})`}
                    checked={industrytags.includes(option?.key)}
                    onChange={() => handleDropdownSelect(option?.key)}
                  />
                </div>
              ))}
            </div>
          </Form> */}
           <Form className="dropdown-form">
            {industryAttributesList.length > 0 ? (
              <div className="scrollable-options">
                {filteredOptions.map((option) => (
                  <div key={option?.key} className="ms-3">
                    <Form.Check
                      className="custom-checkbox"
                      type="checkbox"
                      id={option.key}
                      label={`${option?.formattedKey} (${option?.doc_count})`}
                      checked={industrytags.includes(option?.key)}
                      onChange={() => handleDropdownSelect(option?.key)}
                    />
                  </div>
                ))}
              </div>
            ) : (
              <div className="text-center p-2">
                <Trans i18nKey="notFound" defaults="Not Found" />
          </div>
            )}
          </Form>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
}

export default SmallScreenIndustryAttribute;
