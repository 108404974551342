import React from "react";
import queryString from "query-string";
import { useState, useEffect } from "react";
import { InputGroup, Drawer, Button, Placeholder, IconButton,Tag, TagGroup,Whisper,Tooltip } from "rsuite";
import { Trans } from "react-i18next";
import usePost from "../../customhooks/UsePost";
import {
  useLocation,
  useRouteLoaderData,
  useLoaderData,
} from "react-router-dom";
import {  Form, Col } from "react-bootstrap";
import SearchKey from "./SearchBarComponents/SearchKey";
import ExperenceKey from "./SearchBarComponents/ExperenceKey";
import SmallSearchKey from "./SearchBarComponents/SmallSearchKey"
import SmallExperenceKey from "./SearchBarComponents/SmallExperenceKey";

import LocationKey from "./SearchBarComponents/LocationKey";
import SmallScreenIndustryAttribute from "./searchattributes/SmallScreenIndustryAttribute";
import SmallScreenWorktypeAttributes from "./searchattributes/SmallScreenWorktypeAttributes";
import SmallScreenLocationAttributes from "./searchattributes/SmallScreenLocationAttributes";
import searchJsonConfig from "../../utilities/search.json";
import { faBullseye } from "@fortawesome/free-solid-svg-icons";

function SmallScreenSearch({
  getJobs,
  searchJson,
  setJobsListData,
  setPage,
  JobsListData,
  setTotal,
  attributes,
  searchID,
  setSearchID,
  companyID,
  setSearchvalue,
  filtersData,
  isLoading,
  Page,
  limit,
  total,
  setShowJobDetails,
  showJobDetails,
  sortOption,
  setSortOption,

  searchBar1Values,
  setSearchBar1Values,
  searchBar2Value,
  setSearchBar2Value,
  searchBar3Value,
  setSearchBar3Value,
  locationObj,
  validationError,
  setValidationError,
  selectedOptions,
  setSelectedOptions,
  isSearchMade,
  setIsSearchMade,
  workTypeAttribute,
  setworkTypeAttribute,
  industryTypeAttributes,
  setIndustryTypeAttributes,
  attributeData,
  setAttributeData,
  SearchBodyData,
}) {
   const [openDrawer, setOpenDrawer] = React.useState(false);
   const [industryTypeSelectedList, setIndustryTypeSelectedList] = useState([]);
   const [workTypeSelectedList, setworkTypeSelectedList] = useState([]);
   const [locationAtributesList,setLocationAtributesList]=useState([]);
   const sortingOptions = ["Relevance", "Date"];
   const [sortKey, setSortKey] = useState({});
   const [industrytags, setindustrytags] = useState([]);
   const [worktypesets, setworktypesets] = useState([]);
   const [locationtags, setlocationtags] = useState([]);
   const [suggestions, setSuggestions] = React.useState([]);
   const location = useLocation();


   const {
    post: getSuggetions,
    isLoading: loading,
    data,
    error,
  } = usePost("/jobsearch/autosuggestions");
  
// console.log(industryTypeSelectedList,"industryTypeSelectedListindustryTypeSelectedList")

  useEffect(() => {
    const queryString = window.location.search;
    const parameters = new URLSearchParams(queryString);
  
    if (parameters.get("searchID")) {
      setSearchID(parameters.get("searchID"));
      //setIsSearchMade(true);
    }
    if (parameters.get("keywords")) {
      setSearchBar1Values(parameters.get("keywords").split(", "));
      setIsSearchMade(true);
    }

    if (parameters.get("experience")) {
      setSearchBar2Value(parameters.get("experience"));
      setIsSearchMade(true);
    }
    if (parameters.get("industry")) {
      setIndustryTypeSelectedList([parameters.get("industry")])
      setIsSearchMade(true);
    }
 
  //   if (
  //     parameters.get("city") ||
  //     parameters.get("state") ||
  //     parameters.get("country") ||
  //     parameters.get("zipcode") ||
  //     parameters.get("locationtext") ||
  //     parameters.get("lat") ||
  //     parameters.get("lon")
  //   ) {
  //     const city = parameters.get("city") || "";
  //     const state = parameters.get("state") || "";
  //     const country = parameters.get("country") || "";
  //     const zipcode = parameters.get("zipcode") || "";

  //     locationObj.current = {
  //       city: city,
  //       state: state,
  //       country: country,
  //       zipcode: zipcode,
  //       pin: {
  //         lon: parameters.get("lon") ?? "",
  //         lat: parameters.get("lat") ?? "",
  //       },
  //       text: parameters.get("locationtext") ?? "",
  //     };
  //     const formattedLocation = `${city}${state ? " " + state : ""}${
  //       zipcode ? " " + zipcode : ""
  //     }  ${country ? " " + country : ""}`;
  //     if (parameters.has("locationtext")) {
  //       setSearchBar3Value(parameters.get("locationtext"));
  //     } else {
  //       setSearchBar3Value(formattedLocation);
  //     }
  //     setIsSearchMade(true);
  //   }
  }, [location.search]);


  const handleSelectChange = (eventKey) => {
  //  console.log(eventKey,"eventKeyeventKey")
   if(eventKey){
    setSortKey([{ type: eventKey, order: "desc" }]);
    setSortOption([{ type: eventKey, order: "desc" }]);
   }
  }



  const search = () => {
    
    const query = {};

    if (searchBar1Values.length > 0) {
      query.keywords = searchBar1Values.join(", ");
    }

    if (searchBar2Value) {
      if (searchBar2Value == "6") {
        query.minExp = 5;
        query.maxExp = 50;
      } else {
        query.experience = searchBar2Value;
      }
    }

    if (searchBar3Value) {
      const { city, state, country, zipcode, pin, text } = locationObj.current;
      if (city) query.city = city;
      if (state) query.state = state;
      if (country) query.country = country;
      if (zipcode) query.zipcode = zipcode;
      if (pin?.lat) query.lat = pin?.lat;
      if (pin?.lon) query.lon = pin?.lon;
      if (text) query.locationtext = text;
    }
   

    setIsSearchMade(
      searchBar1Values.length > 0 || searchBar2Value || searchBar3Value
    );
  
    let obj = JSON.parse(JSON.stringify(searchJsonConfig.searchJSON));
  
    obj.searchKey = searchBar1Values;

    if (locationObj.current && searchBar3Value && searchBar3Value !== "") {
      obj.search.push({
        type: "location",
        location: [locationObj.current],
      });
    }

    if (parseInt(searchBar2Value) < 6) {
      obj.search.push({
        type: "experience",
        maxExp: searchBar2Value,
        minExp: 0,
      });
    }
    if (parseInt(searchBar2Value) === 6) {
      obj.search.push({
        type: "experience",
        maxExp: 50,
        minExp: 5,
      });
    }

    //sorting
    if(sortKey){
      obj.sort= [
        sortKey
      ]
    }

    //filters
    let filterArray = [];
    if (industryTypeSelectedList.length > 0) {
      // console.log(industryTypeSelectedList, "industryTypeSelectedList");
      
      filterArray.push({
        type: "industry",
        industry: industryTypeSelectedList,
      });
      setIsSearchMade(true);
    }
    if (workTypeSelectedList.length > 0) {
      // console.log(workTypeSelectedList, "workTypeSelectedList");
      filterArray.push({
        type: "workType",
        workType: workTypeSelectedList,
      });
      setIsSearchMade(true);
    }
    if(locationAtributesList.length > 0){
      filterArray.push({        
            type: "location",
            location: locationAtributesList,
      })
      setIsSearchMade(true);
    }
    
    obj.filter = filterArray;

    obj.size = 6;
    obj.page = 1;
    obj.skip = 0;
    obj.companyID = companyID;
    const { search } = window.location;
    const params = new URLSearchParams(search);
    if (params.has("industry")) {
      searchJson.filter = [];
    }

    const queryStringified = queryString.stringify(query);
    window.history.replaceState({}, "", `?${queryStringified}`);
 
    getJobs(obj).then((res) => {
      setJobsListData(res?.data?.result ?? []);
      setSearchID(res?.searchID ?? "");
      setTotal(res?.data?.totalResults ?? 0);
      setAttributeData(res?.data?.attributesAll);
      setOpenDrawer(false);
      setSelectedOptions(industryTypeSelectedList);
      // setIsSearchMade(true);
    });
  

    setPage(1);
    setSearchvalue(0);
    setSelectedOptions([]);
    setworkTypeAttribute([]);
    setIndustryTypeAttributes([]);
    setShowJobDetails(false);
  };

  const clearSearch = () => {
    searchJson.filter = [];
    setSelectedOptions([]);
    setworkTypeAttribute([]);
    setIndustryTypeAttributes([]);
    setSearchBar1Values([]);
    setSearchBar2Value("");
    setSearchBar3Value("");
    setShowJobDetails(false);
    setSelectedOptions([]);
    setlocationtags([]);
    setworkTypeSelectedList([]);
    setworktypesets([]);
    setIndustryTypeSelectedList([]);
    setindustrytags([]);
    setLocationAtributesList([]);


    setSortOption([{ type: "date", order: "desc" }]);
    //  setSelectedOptions([]);
    // setworkTypeAttribute([]);
    // setIndustryTypeAttributes([]);
    // setSearchID("");
    // setJobsListData([]);
    setIsSearchMade(false);

    let obj = {};
    obj.size = 6;
    obj.page = 1;
    obj.skip = 0;
    obj.newSearch = true;
    obj.companyID = companyID;
    obj.newSearch = true;
    obj.sort = [{ type: "date", order: "desc" }];
    obj.projection = [
      "createdAt",
      "title",
      "displayName",
      "job.details.jobTitle.text",
      "job.requirements.skills.skills.text",
      "job.details.locations",
      "job.details.jobTitle.jobType",
      "job.details.offeredSalary",
      "job.details.jobTiming",
      "job.details.workingHours",
      "job.details.jobDescription.text",
      "job.otherInformation.industry.classified.industryLabel",
      "customData.6112806bc9147f673d28c6eb.64abb309db7899f5908c77aa.value",
      "passcode",
    ];
    obj.SearchAttributes = false;
    const queryStringified = queryString.stringify({});
    window.history.replaceState({}, "", `?${queryStringified}`);

  

    getJobs(obj).then((res) => {
      setJobsListData(res?.data?.result ?? []);
      setSearchID(res?.searchID ?? "");
      setTotal(res?.data?.totalResults ?? 0);
      setAttributeData(res?.data?.attributesAll);
      // setIsSearchMade(false);
      setOpenDrawer(false);
    });
    // setAttribute({
    //   searchKey: searchBar1Values,
    //   companyID: companyID,
    // });
    setPage(1);
    setSearchvalue(0);
    // setSelectedOptions([]);
    // setworkTypeAttribute([]);
    // setIndustryTypeAttributes([]);

    // window.location.reload();
  };
  const IndustrytyperemoveTag = (tag) => {
    const nextTags = industryTypeSelectedList.filter((item) => item !== tag);
    const filteredItem = industryTypeSelectedList.filter((item) => item === tag);
    setIndustryTypeSelectedList(nextTags);
    setindustrytags(nextTags) 
  };
  const worktyperemoveTag = (tag) => {
    const nextTags = workTypeSelectedList.filter((item) => item !== tag);
    setworkTypeSelectedList(nextTags);
    setworktypesets(nextTags);
  }
  const locationtyperemoveTag = (tag) => {
    // console.log(selectedOptions, "fgybuhnij");
    const nextTags = locationAtributesList.filter((item) => item !== tag);
    // console.log(nextTags);
    setLocationAtributesList(nextTags);
    setlocationtags(nextTags);
   
  };


  useEffect(() => {
    const handleResize = () => {
      if (typeof window !== 'undefined' && window.innerWidth >= 990) {
        setOpenDrawer(false)
      }
    };

    if (typeof window !== 'undefined') {
      window.addEventListener('resize', handleResize);

      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }
  }, []); 
  // console.log(searchBar1Values ,"searchbarrrr")
  // console.log(searchBar2Value,"seacrh 2")

const handleDrawerClose =()=>{
  //  setOpenDrawer(false);
//  console.log(isSearchMade,"isSearchMadeisSearchMade");
   if(isSearchMade===false){
    setSelectedOptions([]);
    setworkTypeAttribute([]);
    setIndustryTypeAttributes([]);
    setSelectedOptions([]);
    setlocationtags([]);
    setworkTypeSelectedList([]);
    setworktypesets([]);
    setIndustryTypeSelectedList([]);
    setindustrytags([]);
    setLocationAtributesList([]);
    setSearchBar1Values([]);
    setSearchBar2Value("");
    setSearchBar3Value("");
    setSortOption([{ type: "date", order: "desc" }]);
   
   }
}

  useEffect(() => {
    const UpdateDate = SearchBodyData?.searchKey?.map((x) => {
      return { label: x, value: x };
    });
    setSuggestions(UpdateDate);
    // const updatedValues = Array.isArray(values) ? values : [];
    // setValues(UpdateDate);
  }, [SearchBodyData]);


 
  return (
    <>
      <div className="buttonClear DivBgColor">
        {(workTypeAttribute.length > 0 ||
          industryTypeAttributes.length > 0 ||
          selectedOptions.length > 0 ||
          isSearchMade) && (
          <button
            type="button"
            onClick={clearSearch}
            disabled={isLoading ? true : false}
            className="text-primary justify-content-center "
          >
            <span className="text-primary">
              {" "}
              <Trans i18nKey="clearFilters" defaults="Clear Filters" />{" "}
              <i className="fa-solid fa-filter"></i>
            </span>
          </button>
        )}
      </div>

      {/* {!showJobDetails && (
      <div className="d-lg-none d-block custom-top-left d-flex" title={<Trans i18nKey="filters" defaults="Filters" />}>
        
        <Button className="customDrawerBtn" onClick={() => setOpenDrawer(true)} title="Filters">
          <i className="fa-solid fa-filter"></i>
        </Button>
        <div className="d-flex flex-nowrap flex-wrap  mb-2 d-flex">
          <div className="attributeStyles text-left text-muted rounded-pill border me-2">
            <Trans i18nKey="industry" defaults="Industry" />
            </div>
          <div className=" attributeStyles rounded-pill border me-2">
            <Trans i18nKey="workType" defaults="Work type" />
            </div>
          <div className="rounded-pill border me-2">
            <Trans i18nKey="location" defaults="Location" />
            </div>
        </div>
       
      </div>
    )} */}
  
{!showJobDetails && (
   <div className="d-lg-none d-block  d-flex pl-0  DivBgColor p-1">
    <div className="d-flex">
        <Button className="me-2 pl-0 btnColor" onClick={() => setOpenDrawer(true)} title="Filters">
          <i className="fa-solid fa-filter fs-2 iconColor"></i>
        </Button>
    </div>
    <div className="d-flex flex-nowrap   overFlowDiv " onClick={() => setOpenDrawer(true)}>
      <div className="rounded-pill text-dark FiltersStyles justify-content-center"><Trans i18nKey="industry" defaults="Industry" /></div>
      <div className="rounded-pill text-dark FiltersStyles justify-content-center"><Trans i18nKey="workType" defaults="Work type" /></div>
      <div className="rounded-pill text-dark FiltersStyles justify-content-center"> <Trans i18nKey="location" defaults="Location" /></div>
    </div>
   </div>
)} 


 

   

      <Drawer
        // size="xs"
        // size='full'typeof window !== 'undefined' && window.innerWidth >= 990
        size={
          typeof window !== "undefined" && window.innerWidth <= 500
            ? "full"
            : "xs"
        }
        // size={deviceWidth ? "full" : "xs"}
        placement={"left"}
        open={openDrawer}
        // onClose={() => setOpenDrawer(false)}
        onClose={() => {
          setOpenDrawer(false);
          handleDrawerClose();
        }}
      >
        <Drawer.Header closeButton={false} className="p-4">
          <div className="d-flex justify-content-between align-items-center w-100 ">
            <h3 className="font-weight-bold mb-0 text-primary ">
              <Trans i18nKey="searchJobs" defaults="Search Jobs" />
            </h3>
            <Button
              className=""
              onClick={() => {
                setOpenDrawer(false);
                handleDrawerClose();
              }}
            >
              <i className="fa-solid fa-xmark"></i>
            </Button>
          </div>
        </Drawer.Header>

        <Drawer.Body className="drawerBody">
          <div className="row justify-content-center align-items-center border rounded-pill bgColor rowheight  mb-3 inputWidth">
            <div className="col-12">
              <SmallSearchKey
                values={searchBar1Values}
                setValues={setSearchBar1Values}
                SearchBodyData={searchJson}
                setSuggestions={setSuggestions}
                suggestions={suggestions}
                getSuggetions={getSuggetions}
                loading={loading}
              />
            </div>
          </div>
          <div className="row justify-content-center align-items-center border rounded-pill bgColor rowheight  mb-3 inputWidth">
            <div className="col-12">
              <SmallExperenceKey
                value={searchBar2Value}
                setValue={setSearchBar2Value}
              />
            </div>
          </div>
          <div className="row justify-content-center align-items-center border rounded-pill bgColor rowheight  mb-3 inputWidth">
            <div className="col-12">
              <LocationKey
                value={searchBar3Value}
                setValue={setSearchBar3Value}
                locationObj={locationObj}
              />
              {validationError && (
                <p className="error-message">{validationError}</p>
              )}
            </div>
          </div>

          {/* {JobsListData?.length > 0 && (
            <> */}
          <div className="row mb-3">
            <div className="col-12 p-0">
              <SmallScreenIndustryAttribute
                industryAttributesList={attributeData?.industry}
                getJobs={setJobsListData}
                setJobsListData={setJobsListData}
                setPage={setPage}
                setTotal={setTotal}
                selectedOptions={selectedOptions}
                setSelectedOptions={setSelectedOptions}
                setIsSearchMade={setIsSearchMade}
                SearchBodyData={SearchBodyData}
                setAttributeData={setAttributeData}
                isLoading={isLoading}
                setSearchvalue={setSearchvalue}
                setShowJobDetails={setShowJobDetails}
                industryTypeSelectedList={industryTypeSelectedList}
                setIndustryTypeSelectedList={setIndustryTypeSelectedList}
                industrytags={industrytags}
                setindustrytags={setindustrytags}
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-12 p-0">
              <SmallScreenWorktypeAttributes
                // ={attributeData?.workType}
                workTypeAttribute={attributeData?.workType}
                getJobs={getJobs}
                setJobsListData={setJobsListData}
                setPage={setPage}
                setTotal={setTotal}
                selectedOptions={selectedOptions}
                setSelectedOptions={setSelectedOptions}
                setSearchvalue={setSearchvalue}
                SearchBodyData={SearchBodyData}
                isLoading={isLoading}
                setAttributeData={setAttributeData}
                // setworktypetags={}
                setShowJobDetails={setShowJobDetails}
                workTypeSelectedList={workTypeSelectedList}
                setworkTypeSelectedList={setworkTypeSelectedList}
                worktypesets={worktypesets}
                setworktypesets={setworktypesets}
              />
            </div>
          </div>

          <div className="row mb-3">
            <div className="col-12 p-0">
              <SmallScreenLocationAttributes
                locationAttributes={attributeData?.location}
                //handleSelectedLocations  filteredLocationAttributes
                getJobs={getJobs}
                setJobsListData={setJobsListData}
                setPage={setPage}
                setTotal={setTotal}
                selectedOptions={selectedOptions}
                setSelectedOptions={setSelectedOptions}
                setSearchvalue={setSearchvalue}
                SearchBodyData={SearchBodyData}
                setAttributeData={setAttributeData}
                setShowJobDetails={setShowJobDetails}
                locationAtributesList={locationAtributesList}
                setLocationAtributesList={setLocationAtributesList}
                locationtags={locationtags}
                setlocationtags={setlocationtags}
              />
            </div>
          </div>
          {/* </> 
             )}  */}

          <div className="row">
            <div className="col-12 d-flex align-items-center">
              <label className="me-2 mb-0">
                <b className="btnStyle">
                  <Trans i18nKey="sort" defaults="Sort" />:
                </b>
              </label>
              <Form.Select
                className="rounded-pill"
                role="button"
                size="sm"
                onChange={(e) => handleSelectChange(e.target.value)}
                value={
                  sortOption?.length > 0
                    ? sortOption[0].type
                    : sortingOptions[0]
                }
              >
                <option value={"relevance"}>
                  <Trans i18nKey="relevance" defaults="Relevance" />
                </option>
                <option value={"date"}>
                  <Trans i18nKey="date" defaults="Date" />
                </option>
              </Form.Select>
            </div>
          </div>

          <div className="row mt-3">
            <div
              className={
                workTypeAttribute.length > 0 ||
                industryTypeAttributes.length > 0 ||
                selectedOptions.length > 0 ||
                isSearchMade
                  ? "col-6"
                  : "col-12"
              }
            >
              <button
                type="button"
                className="btn btn-primary  w-100"
                onClick={search}
                disabled={isLoading ? true : false}
              >
                <Trans i18nKey="search" defaults="Search" />
              </button>
            </div>
            <div className="col-6">
              {(workTypeAttribute.length > 0 ||
                industryTypeAttributes.length > 0 ||
                selectedOptions.length > 0 ||
                isSearchMade) && (
                <button
                  type="button"
                  className="btn btn-primary  w-100"
                  onClick={clearSearch}
                  disabled={isLoading ? true : false}
                >
                  <Trans i18nKey="clear" defaults="Clear" />
                </button>
              )}
            </div>
          </div>

          <div className="m-2">
            <TagGroup>
              {industryTypeSelectedList?.map((x, index) => {
                return (
                  <Tag
                    closable
                    className="Attributtestags"
                    key={index}
                    onClose={() => IndustrytyperemoveTag(x)}
                  >
                    {x}
                  </Tag>
                );
              })}
              {workTypeSelectedList?.map((x, index) => {
                return (
                  <Tag
                    closable
                    className="Attributtestags"
                    key={index}
                    onClose={() => worktyperemoveTag(x)}
                  >
                    {x}
                  </Tag>
                );
              })}
              {locationAtributesList?.map((option, index) => {
                return (
                  <Tag
                    closable
                    className="Attributtestags"
                    key={index}
                    onClose={() => locationtyperemoveTag(option)}
                  >
                    {option?.city?.charAt(0).toUpperCase() +
                      option?.city?.slice(1)}{" "}
                    -{" "}
                    {option?.state?.charAt(0).toUpperCase() +
                      option?.state?.slice(1)}
                    {/* {option?.city} - {option?.state} */}
                  </Tag>
                );
              })}
            </TagGroup>
          </div>
        </Drawer.Body>
      </Drawer>
    </>
  );
}

export default SmallScreenSearch;
