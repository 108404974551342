import React, { useState, useEffect, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarDays,
  faEdit,
  faIdCard,
  faIdCardClip,
  faPassport,
} from "@fortawesome/free-solid-svg-icons";
import { Trans, useTranslation } from "react-i18next";
import LicencesModal from "../forms/LicencesModal";
import { useForm } from "react-hook-form";
import { Modal, Tooltip, Whisper } from "rsuite";
import { DateTime } from "luxon";
import usePost from "../../../../customhooks/UsePost";
import { v4 as uuidv4 } from "uuid";
import UserContext from "../../../../app/context/UserContext";
import "../../MyAccount.css";
const Licenses = ({
  licenseRows,
  resumeId,
  standardFieldsList,
  resumeData,
  status,
  setUpdateResumeDataData,
}) => {
  const { t } = useTranslation();
  const {
    control,
    register,
    setValue,
    formState: { errors },
    setError,
    getValues,
    clearErrors,
    handleSubmit,
  } = useForm();

  const { post: updateResumeDetails, isLoading: UpdateResumeDataDataLoading } =
    usePost("/user/Updateresumedeatails");
  const { user, setUserDetails } = useContext(UserContext);
  const [open, setOpen] = React.useState(false);
  const handleLicenseOpen = () => setOpen(true);
  const handleLicenseClose = () => {
    clearErrors();
    setOpen(false);
  };
  const submitLicenceDetails = () => {
    let licenceData = getValues();
    let licenceDataList = [];
    let flag = true;
    if (licenceData?.licence.length > 0) {
      for (let i in licenceData?.licence) {
        let licenceObj = {
          expiryDate: "",
          issuedBy: "",
          issuedOn: "",
          licence: "",
          licenceType: "",
        };
        if (
          licenceData?.licence[i].issuedOn != "" &&
          licenceData?.licence[i].issuedOn != null &&
          licenceData?.licence[i].expiryDate != ""
        ) {
          if (
            DateTime.fromJSDate(licenceData?.licence[i].issuedOn).toMillis() >
            DateTime.fromJSDate(licenceData?.licence[i].expiryDate).toMillis()
          ) {
            flag = false;
            setError(
              `licence[${i}].expiryDate`,
              {
                type: "manual",
                message: t("expiredDateShouldBeGreterThanIssuedDate", {
                  defaultValue:
                    "Expired date should be greter than issued date",
                }),
              },
              { shouldFocus: true }
            );
          } else {
            flag = flag ? true : false;
            clearErrors(`licence[${i}].issuedOn`);
            clearErrors(`licence[${i}].expiryDate`);
          }
        } else {
          if (
            licenceData?.licence[i].expiryDate != "" &&
            (licenceData?.licence[i].issuedOn == null ||
              licenceData?.licence[i].issuedOn == "")
          ) {
            flag = false;
            setError(
              `licence[${i}].issuedOn`,
              {
                type: "manual",
                message: t("issuedDateRequired", {
                  defaultValue: "Issued date required",
                }),
              },
              { shouldFocus: true }
            );
          }
        }

        licenceObj.licence = licenceData?.licence[i].licence;
        licenceObj.licenceType = licenceData?.licence[i].licenceType;
        licenceObj.issuedBy = licenceData?.licence[i].issuedBy;
        if (
          licenceData?.licence[i].issuedOn == null ||
          licenceData?.licence[i].issuedOn == ""
        ) {
          licenceObj.issuedOn = "";
        } else {
          licenceObj.issuedOn = DateTime.fromJSDate(
            licenceData?.licence[i].issuedOn
          ).toFormat("yyyy-MM-dd");
        }
        if (
          licenceData?.licence[i].expiryDate == null ||
          licenceData?.licence[i].expiryDate == ""
        ) {
          licenceObj.expiryDate = "";
        } else {
          licenceObj.expiryDate = DateTime.fromJSDate(
            licenceData?.licence[i].expiryDate
          ).toFormat("yyyy-MM-dd");
        }
        licenceDataList.push(licenceObj);
      }
      if (flag) {
        updateResumeDetails({
          target: "licence",
          resumeID: resumeId,
          user: user,
          companyID: resumeData.companyID,
          clientID: resumeData.clientID,
          getValues: {
            licenceDetails: licenceDataList,
            sectionName: standardFieldsList?.sectionName
              ? standardFieldsList?.sectionName
              : "License",
          },
          type:"Profile Edited"
        })
          .then((res) => {
            if (res) {
              setUpdateResumeDataData(res);
              handleLicenseClose();
            }
          })
          .catch((error) => {
            console.error("Error while fetching Resume:", error);
          });
      }
    } else {
      updateResumeDetails({
        target: "licence",
        resumeID: resumeId,
        user: user,
        companyID: resumeData.companyID,
        clientID: resumeData.clientID,
        getValues: {
          licenceDetails: [],
          sectionName: standardFieldsList?.sectionName
            ? standardFieldsList?.sectionName
            : "License",
        },
        type:"Profile Edited"
      })
        .then((res) => {
          if (res) {
            setUpdateResumeDataData(res);
            handleLicenseClose();
          }
        })
        .then((res) => {
          if (res) {
            setUpdateResumeDataData(res);
            handleLicenseClose();
          }
        })
        .catch((error) => {
          console.error("Error while fetching Resume:", error);
        });
    }
  };
  const [licenseRowsList, setLicenseRows] = useState([]);
  useEffect(() => {
    setLicenseRows(licenseRows);
  }, []);
  return (
    <div className="card h-100">
      <div className="card-body border-0 ">
        <div className="row">
          <div className="col-10 d-flex my-2 align-items-center">
            <h6 className="text-primary m-0">
              <Trans i18nKey="prolicense" defaults="Licence" />
            </h6>
          </div>
          {status != "MERGED" &&
          status != "DISQUALIFIED" &&
          status != "DELETED" ? (
            <>
              <div className="col-2 justify-content-end d-flex">
                {standardFieldsList?.editable ? (
                  <>
                    <span
                      className="IconArea actionIcon actionIcon-sm"
                      onClick={handleLicenseOpen}
                    >
                      <FontAwesomeIcon icon={faEdit} />
                    </span>
                  </>
                ) : null}
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
        <div className="row">
          <div className="col-md-12">
            {resumeData?.resume?.professionalQualification?.licences?.length >
            0 ? (
              resumeData?.resume?.professionalQualification?.licences?.map(
                (keysData, index) => {
                  return (
                    <div className="row" key={uuidv4()}>
                      <div className="col-md-12">
                        <div
                          className={
                            resumeData?.resume?.professionalQualification
                              ?.licences?.length != 1
                              ? "border-bottom"
                              : ""
                          }
                        >
                          <div className="mt-2">
                            {licenseRowsList.map((sections, index) => {
                              let colNumber = 12;
                              if (sections?.columns.length > 0) {
                                colNumber = 12 / sections?.columns.length;
                              }
                              return (
                                <div
                                  className="row mb-3 details"
                                  key={uuidv4()}
                                >
                                  {sections?.columns.map((item, index) => {
                                    return (
                                      <div
                                        className={"col-" + colNumber}
                                        key={uuidv4()}
                                      >
                                        {item?.fieldCode == "License" ? (
                                          <>
                                            <span className="d-flex">
                                              {/* <b>
                                              <Trans
                                                i18nKey="licenceName"
                                                defaults="Licence Name"
                                              />
                                              : </b> */}
                                              <Whisper
                                                followCursor
                                                placement={"bottom"}
                                                speaker={
                                                  <Tooltip>
                                                    <Trans
                                                      i18nKey="licenceName"
                                                      defaults="License Name"
                                                    />
                                                  </Tooltip>
                                                }
                                              >
                                                <span className="displayIcon displayIcon-sm">
                                                  <FontAwesomeIcon
                                                    icon={faIdCard}
                                                  />
                                                </span>
                                              </Whisper>
                                              <b className="px-1">
                                                {t(item?.i18nKey, {
                                                  defaultValue:
                                                    item?.fieldName ||
                                                    "License",
                                                })}
                                              </b>
                                              <span
                                                key={uuidv4()}
                                                className="textOverFlow"
                                                title={
                                                  keysData?.text
                                                    ? keysData?.text
                                                    : "Not Specified"
                                                }
                                              >
                                                {keysData?.text ? (
                                                  keysData?.text
                                                ) : (
                                                  <Trans
                                                    i18nKey="notSpecified"
                                                    defaults="Not Specified"
                                                  />
                                                )}
                                              </span>
                                            </span>
                                          </>
                                        ) : null}
                                        {item?.fieldCode == "Lic Type" ? (
                                          <>
                                            <span className="d-flex">
                                              {/* <b>
                                              <Trans
                                                i18nKey="licenceType"
                                                defaults="Licence Type"
                                              />
                                              : </b> */}
                                              <Whisper
                                                followCursor
                                                placement={"bottom"}
                                                speaker={
                                                  <Tooltip>
                                                    <Trans
                                                      i18nKey="licenceType"
                                                      defaults="License Type"
                                                    />
                                                  </Tooltip>
                                                }
                                              >
                                                <span className="displayIcon displayIcon-sm">
                                                  <FontAwesomeIcon
                                                    icon={faIdCardClip}
                                                  />
                                                </span>
                                              </Whisper>

                                              <b className="px-1">
                                                {t(item?.i18nKey, {
                                                  defaultValue: item?.fieldName,
                                                })}
                                              </b>
                                              <span
                                                key={uuidv4()}
                                                className="textOverFlow"
                                                title={
                                                  keysData?.type != ""
                                                    ? keysData?.type
                                                    : "Not Specified"
                                                }
                                              >
                                                {keysData?.type != "" ? (
                                                  keysData?.type
                                                ) : (
                                                  <Trans
                                                    i18nKey="notSpecified"
                                                    defaults="Not Specified"
                                                  />
                                                )}
                                              </span>
                                            </span>
                                          </>
                                        ) : null}

                                        {item?.fieldCode == "Lic Issued By" ? (
                                          <span className="d-flex">
                                            <span>
                                              {/* <b>
                                              <Trans
                                                i18nKey="issuedBy"
                                                defaults="Issued By"
                                              />
                                              : </b> */}
                                              <Whisper
                                                followCursor
                                                placement={"bottom"}
                                                speaker={
                                                  <Tooltip>
                                                    <Trans
                                                      i18nKey="issuedBy"
                                                      defaults="Issued By"
                                                    />
                                                  </Tooltip>
                                                }
                                              >
                                                <span className="displayIcon displayIcon-sm">
                                                  <FontAwesomeIcon
                                                    icon={faPassport}
                                                  />
                                                </span>
                                              </Whisper>
                                            </span>
                                            <b className="px-1">
                                              {t(item?.i18nKey, {
                                                defaultValue: item?.fieldName,
                                              })}
                                            </b>
                                            <span
                                              className="textOverFlow"
                                              title={
                                                keysData?.issuedBy != ""
                                                  ? keysData?.issuedBy
                                                  : "No Issued By"
                                              }
                                            >
                                              {keysData?.issuedBy != "" ? (
                                                keysData?.issuedBy
                                              ) : (
                                                <Trans
                                                    i18nKey="noIssuedBy"
                                                    defaults="No Issued By"
                                                  />
                                              )}
                                            </span>
                                          </span>
                                        ) : null}
                                        {item?.fieldCode == "Lic Issued On" ? (
                                          <span className="d-flex">
                                            <span>
                                              {/* <b>
                                              <Trans
                                                i18nKey="issuedOn"
                                                defaults="Issued On"
                                              />
                                              : </b> */}
                                              <Whisper
                                                followCursor
                                                placement={"bottom"}
                                                speaker={
                                                  <Tooltip>
                                                    <Trans
                                                      i18nKey="issuedOn"
                                                      defaults="Issued On"
                                                    />
                                                  </Tooltip>
                                                }
                                              >
                                                <span className="displayIcon displayIcon-sm">
                                                  <FontAwesomeIcon
                                                    icon={faCalendarDays}
                                                  />
                                                </span>
                                              </Whisper>
                                            </span>
                                            <b className="px-1">
                                              {t(item?.i18nKey, {
                                                defaultValue: item?.fieldName,
                                              })}
                                            </b>
                                            {keysData?.issuedOn?.standard !=
                                            "" ? (
                                              <>
                                                {keysData?.issuedOn?.standard !=
                                                  "" &&
                                                  keysData?.issuedOn?.standard}
                                                {/* {keysData?.issuedOn?.text != "" &&
                                                  new Date(
                                                    keysData?.issuedOn?.text
                                                  ) != "Invalid Date" ? (
                                                  <UserDate
                                                    date={new Date(
                                                      keysData?.issuedOn?.text
                                                    ).toISOString()}
                                                  />
                                                ) : (
                                                  keysData?.issuedOn?.text
                                                )} */}
                                              </>
                                            ) : (
                                              <span>
                                                <Trans
                                                    i18nKey="noIssuedDate"
                                                    defaults="No Issued Date"
                                                  />
                                              </span>
                                            )}
                                          </span>
                                        ) : null}
                                        {item?.fieldCode ==
                                        "Lic Expiry Date" ? (
                                          <span className="d-flex">
                                            <span>
                                              {/* <b>
                                              <Trans
                                                i18nKey="expiryDate"
                                                defaults="Expiry Date"
                                              />
                                              : </b> */}
                                              <Whisper
                                                followCursor
                                                placement={"bottom"}
                                                speaker={
                                                  <Tooltip>
                                                    <Trans
                                                      i18nKey="expiryDate"
                                                      defaults="Expiry Date"
                                                    />
                                                  </Tooltip>
                                                }
                                              >
                                                <span className="displayIcon displayIcon-sm">
                                                  <FontAwesomeIcon
                                                    icon={faCalendarDays}
                                                  />
                                                </span>
                                              </Whisper>
                                            </span>
                                            <b className="px-1">
                                              {t(item?.i18nKey, {
                                                defaultValue: item?.fieldName,
                                              })}
                                            </b>
                                            {keysData?.expiryDate?.standard !=
                                            "" ? (
                                              <>
                                                {keysData?.expiryDate
                                                  ?.standard != "" &&
                                                  keysData?.expiryDate
                                                    ?.standard}

                                                {/* {keysData?.expiryDate?.text !=
                                                  "" &&
                                                  new Date(
                                                    keysData?.expiryDate?.text
                                                  ) != "Invalid Date" ? (
                                                  <UserDate
                                                    date={new Date(
                                                      keysData?.expiryDate?.text
                                                    ).toISOString()}
                                                  />
                                                ) : (
                                                  keysData?.expiryDate?.text
                                                )} */}
                                              </>
                                            ) : (
                                              <span>
                                                <Trans
                                                    i18nKey="noExpiryDate"
                                                    defaults="No Expiry Date"
                                                  />
                                              </span>
                                            )}
                                          </span>
                                        ) : null}
                                      </div>
                                    );
                                  })}
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                }
              )
            ) : (
              <div className="row">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-body ">
                      <span>
                        <Trans
                          i18nKey="noLicencesAvailable"
                          defaults="No Licenses Available"
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <Modal size={"lg"} open={open} onClose={handleLicenseClose}>
          <Modal.Header>
            <Modal.Title>
              {t(standardFieldsList?.i18nKey, {
                defaultValue: standardFieldsList?.sectionName,
              })}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row w-100">
              <div className="col-sm-12 col-md-12">
                {/* <CertificationModal control={control} register={register} setValue={setValue} errors={errors} getValues={getValues} certificationList={resumeData?.resume?.professionalQualification?.certifications} certificationsRowsList={certificationsRowsList} /> */}
                <LicencesModal
                  control={control}
                  register={register}
                  setValue={setValue}
                  errors={errors}
                  setError={setError}
                  getValues={getValues}
                  clearErrors={clearErrors}
                  handleSubmit={handleSubmit}
                  licenseList={
                    resumeData?.resume?.professionalQualification?.licences
                  }
                  licenseRows={licenseRows}
                  standardFieldsList={standardFieldsList}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="row mt-3">
              <div className="col-md-12 col-sm-12 d-flex justify-content-between">
                <span>
                  <button
                    onClick={handleLicenseClose}
                    className="btn btn-sm btn-light"
                  >
                    <Trans i18nKey="cancel" defaults="Cancel" />
                  </button>
                </span>
                <span>
                  <button
                    onClick={handleSubmit(submitLicenceDetails)}
                    disabled={UpdateResumeDataDataLoading}
                    className="btn btn-sm btn-primary"
                  >
                    {UpdateResumeDataDataLoading && (
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    )}
                    <span className="px-1">
                      <Trans i18nKey="save" defaults="Save" />
                    </span>
                  </button>
                </span>
              </div>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};
export default Licenses;