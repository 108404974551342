import React, { useState, useEffect, useRef } from "react";
import { Popover,Overlay,Form } from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";

function LocationSelect({
  selectedLocation,
  setSelectedLocation,
  locationObj,
}) {
  const [showPopover, setShowPopover] = useState(false);
  const [predictions, setPredictions] = useState([]);
  const [searchInput, setSearchInput] = useState("");

  const target = useRef(null);
  const popoverRef = useRef(null);
  const inputRef = useRef(null);

  const { t } = useTranslation();

  const handleSearchClick = () => {
    setShowPopover(true);
  };

  const handleLocationSelect = (prediction) => {
    setSearchInput(prediction.description);
    setShowPopover(false);

    const service = new window.google.maps.places.PlacesService(
      document.createElement("div")
    );
    service.getDetails({ placeId: prediction.place_id }, (place, status) => {
      if (status === window.google.maps.places.PlacesServiceStatus.OK) {
        handlePlacesChanged(place);
      }
    });
  };

  const handleClickOutside = (event) => {
    if (
      popoverRef.current &&
      !popoverRef.current.contains(event.target) &&
      target.current &&
      !target.current.contains(event.target)
    ) {
      setShowPopover(false);
    }
  };

  useEffect(() => {
    if (showPopover) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showPopover]);

  useEffect(() => {
    const google = window.google;
    if (!google) return;
    const autocompleteService = new google.maps.places.AutocompleteService();

    if (searchInput) {
      autocompleteService.getPlacePredictions(
        { input: searchInput, componentRestrictions: { country: "US" } },
        (predictions) => {
          if (predictions) {
            setPredictions(predictions);
          }
        }
      );
    } else {
      setPredictions([]);
    }
  }, [searchInput]);

  const handleChange = (event) => {
    setSearchInput(event.target.value);
  };

  const handlePlacesChanged = (place) => {
    if (place) {
      setSelectedLocation(place.formatted_address);

      if (place.address_components) {
        place.address_components.forEach((component) => {
          const types = component.types;
          if (types.includes("locality")) {
            locationObj.current.city = component.long_name;
          }
          if (types.includes("administrative_area_level_1")) {
            locationObj.current.state = component.long_name;
          }
          if (types.includes("country")) {
            locationObj.current.country = component.long_name;
          }
          if (types.includes("postal_code")) {
            locationObj.current.zipcode = component.long_name;
          }
        });

        locationObj.current.pin.lat = place.geometry.location.lat();
        locationObj.current.pin.lon = place.geometry.location.lng();
        locationObj.current.text = place.formatted_address;
      }
    }
  };

  return (
    <div>
      <Overlay
        show={showPopover}
        target={target.current}
        placement="bottom"
        containerPadding={20}
        rootClose={true}
        onHide={() => setShowPopover(false)}
      >
        <Popover id="popover-basic" ref={popoverRef} style={{zIndex: 5}}>
          <Popover.Body>
            <Form.Control
              ref={inputRef}
              value={searchInput}
              placeholder="search Location"
              onChange={handleChange}
              autoComplete="off"
            />
            {predictions.length > 0 && (
              <div>
                {predictions.map((prediction, index) => (
                  <div
                    key={index}
                    className="suggestion-item mt-2"
                    onClick={() => handleLocationSelect(prediction)}
                  >
                    {prediction.description}
                  </div>
                ))}
              </div>
            )}
          </Popover.Body>
        </Popover>
      </Overlay>
      {selectedLocation ? (
        <span
          className="me-3 TitleStyles fontSize"
          ref={target}
          onClick={() => {
            setShowPopover(true);
            setSearchInput("");
          }}
        >
          <i>{selectedLocation}</i>
        </span>
      ) : (
        <span
          className="me-3 anchorLike fontSize fontPlcaeholdr"
          ref={target}
          onClick={() => {
            setShowPopover(true);
             setSearchInput("");
          }}
        >
          <Trans i18nKey="SelectLocation" defaults="Select location" />
        </span>
      )}
    </div>
  );
}

export default LocationSelect;
