import React, { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { Trans, useTranslation } from "react-i18next";
import { InputPicker, Placeholder, TagPicker } from "rsuite";
import { useRouteLoaderData, useLoaderData, useLocation } from "react-router-dom";
const Results = React.lazy(() => import("./Results"));
const LocationSearch = React.lazy(() => import("./LocationSearch"));
import SpinnerIcon from "@rsuite/icons/legacy/Spinner";
import usePost from "../../customhooks/UsePost";
import searchJsonConfig from "../../utilities/search.json";
import queryString from "query-string";
// import { motion,useIsPresent } from "framer-motion"

const NewLayout = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const [experienceValue, setExperienceValue] = useState("");
  const [keywordValues, setKeywordValues] = useState([]);
  const [locationValue, setLocationValue] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [cacheData, setCacheData] = useState([]);
  const [size, setSize] = useState(10)
  let { jobs } = useLoaderData();
  const Loaderdetails = useRouteLoaderData("newLayout");
  const [searchID, setSearchID] = useState(jobs?.searchID ?? "");
  const [JobsListData, setJobsListData] = useState(
    jobs?.data?.result ?? []
  );
  const [total, setTotal] = useState(jobs?.data?.totalResults ?? 0);
  // const isPresent = useIsPresent();

  const experienceData = [
    {
      label: "Less than 1 year",
      value: "1",
    },
    { label: "2 years", value: "2" },
    { label: "3 years", value: "3" },
    { label: "4 years", value: "4" },
    { label: "5 years", value: "5" },
    { label: "More than 5 years", value: "6" },
  ];
  const locationObj = useRef({
    city: "",
    country: "",
    pin: { lat: 0, lon: 0 },
    state: "",
    zipcode: "",
  });
  const { post: getSuggestions, isLoading: suggestionsLoading } = usePost(
    "/jobsearch/autosuggestions"
  );
  const { post: getJobs, isLoading } = usePost("/jobsearch/search");

  const [words, setWords] = useState("");
  const fetchdata = (word) => {
    setWords(word);

    let body = {
      searchkey: word ?? "",
    };

    getSuggestions(body).then((res) => {
      const UpdateDate = res?.data?.result?.map((x) => {
        return { label: x, value: x };
      });
      setSuggestions(UpdateDate);
    });
  };

  useEffect(() => {
    const queryString = window.location.search;
    const parameters = new URLSearchParams(queryString);

    if (parameters.get("searchID")) {
      setSearchID(parameters.get("searchID"));
    }
    if (parameters.get("keywords")) {
      setKeywordValues(parameters.get("keywords").split(", "));
    }

    if (parameters.get("experience")) {
      setExperienceValue(parameters.get("experience"));
    }
    if (parameters.get("maxExp")) {
      setExperienceValue("6");
    }
    if (
      parameters.get("city") ||
      parameters.get("state") ||
      parameters.get("country") ||
      parameters.get("zipcode") ||
      parameters.get("locationtext") ||
      parameters.get("lat") ||
      parameters.get("lon")
    ) {
      const city = parameters.get("city") || "";
      const state = parameters.get("state") || "";
      const country = parameters.get("country") || "";
      const zipcode = parameters.get("zipcode") || "";

      locationObj.current = {
        city: city,
        state: state,
        country: country,
        zipcode: zipcode,
        pin: {
          lon: parameters.get("lon") ?? "",
          lat: parameters.get("lat") ?? "",
        },
        text: parameters.get("locationtext") ?? "",
      };
      const formattedLocation = `${city}${state ? " " + state : ""}${
        zipcode ? " " + zipcode : ""
      }  ${country ? " " + country : ""}`;
      if (parameters.has("locationtext")) {
        setLocationValue(parameters.get("locationtext"));
      } else {
        setLocationValue(formattedLocation);
      }
    }
  }, [location.search]);

  useEffect(() => {
    if (Loaderdetails) {
      const UpdateDate = Loaderdetails?.searchbody?.searchKey?.map((x) => {
        return { label: x, value: x };
      });
      setSuggestions(UpdateDate);
    }
  }, [Loaderdetails]);
  const handleSelect = (value, item, event) => {
    setCacheData([...cacheData, item]);
  };
  const handleChange = (newValues) => {
    let updatedValues;
    if (Array.isArray(newValues)) {
      updatedValues = newValues;
    } else if (newValues) {
      updatedValues = [newValues];
    } else {
      updatedValues = [];
    }
    setKeywordValues(updatedValues);
  };
  useEffect(() => {
    if (searchID) {
      const queryString = window.location.search;
      const parameters = new URLSearchParams(queryString);
      parameters.set("searchID", searchID);
      window.history.replaceState(
        {},
        "",
        `${window.location.pathname}?${parameters}`
      );
    }
  }, [searchID]);
  const search = () => {
    console.log("searchhhhhhhhhhhhhh")
    const query = {};

    if (keywordValues.length > 0) {
      query.keywords = keywordValues.join(", ");
    }

    if (experienceValue) {
      if (experienceValue == "6") {
        query.minExp = 5;
        query.maxExp = 50;
      } else {
        query.experience = experienceValue;
      }
    }

    if (locationValue) {
      const { city, state, country, zipcode, pin, text } = locationObj.current;
      if (city) query.city = city;
      if (state) query.state = state;
      if (country) query.country = country;
      if (zipcode) query.zipcode = zipcode;
      if (pin?.lat) query.lat = pin?.lat;
      if (pin?.lon) query.lon = pin?.lon;
      if (text) query.locationtext = text;
    }

    let obj = JSON.parse(JSON.stringify(searchJsonConfig.searchJSON));
    obj.searchKey = keywordValues;

    if (locationObj.current && locationValue && locationValue !== "") {
      obj.search.push({
        type: "location",
        location: [locationObj.current],
      });
    }

    if (parseInt(experienceValue) < 6) {
      obj.search.push({
        type: "experience",
        maxExp: experienceValue,
        minExp: 0,
      });
    }
    if (parseInt(experienceValue) === 6) {
      obj.search.push({
        type: "experience",
        maxExp: 50,
        minExp: 5,
      });
    }

    if (keywordValues.length > 0 || experienceValue || locationValue != "") {
      obj.sort = [
        {
          type: "relevance",
          order: "desc",
        },
      ];
    }

    obj.size = size;
    obj.skip = 0;
    obj.companyID = Loaderdetails?.companyID;
    const { search } = window.location;
    const params = new URLSearchParams(search);
    if (params.has("industry")) {
      searchJson.filter = [];
    }

    const queryStringified = queryString.stringify(query);
    window.history.replaceState({}, "", `?${queryStringified}`);

    getJobs(obj).then((res) => {
      setJobsListData(res?.data?.result ?? []);
      setSearchID(res?.searchID ?? "");
      setTotal(res?.data?.totalResults ?? 0);
    });
    // setShowJobDetails(false);
  };
  return (
    <>
      <div className="bg-primary text-white px-5 py-3 rounded layoutcontent show">
        <div className="row">
          <span className="mt-2 mb-3 fs-2 fw-bold">
            <FontAwesomeIcon className="" height={"50px"} width={"50px"} icon={faSearch}></FontAwesomeIcon>{" "}
            Search Jobs
          </span>
        </div>
        <div className="row bg-white text-dark px-5 mb-2 rounded-3 shadow-lg ">
          <div className="col-3 mt-3 mb-3">
            <span className="fw-bold fs-6">
              {/* <Trans i18nKey="keywordSearch" default="Keyword Search" /> */}
              Keyword Search
            </span>
            <div>
              <TagPicker
                creatable
                className="width d-block mt-1"
                data={suggestions ?? []}
                cacheData={cacheData}
                value={keywordValues}
                labelKey="label"
                valueKey="value"
                onChange={handleChange}
                onSearch={fetchdata}
                onSelect={handleSelect}
                block
                placeholder={t("enterSkills,Designations,Keywords", {
                  defaultValue: "Enter Skills, Designations,  Keywords",
                })}
                renderMenu={(menu) => {
                  if (suggestionsLoading) {
                    return (
                      <p
                        style={{
                          padding: 4,
                          color: "#999",
                          textAlign: "center",
                        }}
                      >
                        <SpinnerIcon spin /> Loading...
                      </p>
                    );
                  }
                  if (words === "" || words == undefined) {
                    return (
                      <p className="pt-1 px-2 text-muted">
                        {/* Enter Skills, Designations, Keywords */}
                        <Trans
                          i18nKey="enterSkills,Designations,Keywords"
                          defaults="Enter Skills, Designations,  Keywords"
                        />
                      </p>
                    );
                  }
                  return menu;
                }}
              />
            </div>
          </div>
          <div className="col-3 mt-3 mb-3">
            <span className="fw-bold fs-6">
              {/* <Trans i18nKey="keywordSearch" default="Keyword Search" /> */}
              Experience Search
            </span>
            <div>
              <InputPicker
                data={experienceData}
                className="mt-1 align-items-center"
                placeholder={t("selectExperience", {
                  defaultValue: "Select Experience",
                })}
                value={experienceValue}
                onChange={(newValue) => setExperienceValue(newValue)}
              />
            </div>
          </div>
          <div className="col-4 mt-3 mb-3">
            <span className="fw-bold fs-6">
              {/* <Trans i18nKey="keywordSearch" default="Keyword Search" /> */}
              Location Search
            </span>
            <div>
              <React.Suspense>
                <LocationSearch
                  value={locationValue}
                  setValue={setLocationValue}
                  locationObj={locationObj}
                />
              </React.Suspense>
            </div>
          </div>
          <div className="col-2 mt-3 mb-3 d-flex align-items-center justify-content-center">
            <button
              className="btn btn-primary fw-bold newsearch-button"
              onClick={search}
              disabled={isLoading}
            >
              <span className="newsearch-text"> Search</span>
              <span className="newsearch-icon">
                <FontAwesomeIcon className="" icon={faSearch}></FontAwesomeIcon>
              </span>{" "}
            </button>
          </div>
        </div>
      </div>
      <div className="text-dark layoutcontent show">
        <span>
        <React.Suspense>
          <Results searchJson={Loaderdetails?.searchbody} searchID={searchID} setJobsListData={setJobsListData} setTotal={setTotal} total={total} JobsListData={JobsListData} isLoading={isLoading}/>
        </React.Suspense>
        </span>
      </div>
      {/* <motion.div
        initial={{ scaleX: 1 }}
        animate={{ scaleX: 0, transition: { duration: 0.5, ease: "circOut" } }}
        exit={{ scaleX: 1, transition: { duration: 0.5, ease: "circIn" } }}
        style={{ originX: isPresent ? 0 : 1 }}
        className="privacy-screen"
      /> */}
    </>
  );
};

export default NewLayout;
