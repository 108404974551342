import React from "react";
import "../scheduleVideo/ScheduleVideo.css";

const ClosedCampaign = () => {
    return (
        <div className="row justify-content-center">
            <img
                className="closed-campaign"
                src="/assets/images/campaignClosed-min.png"
            />
        </div>
    )
}

export default ClosedCampaign;
