import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import UserDateInput from "../../Date/UserDateInput";
import { DateTime } from "luxon";
import { Trans } from "react-i18next";
import { t } from "i18next";
const DateOfBirthFormField = ({
  dateOfBirthData,
  control,
  getValues,
  errors,
  register,
  setValue,
  fieldList,
  type,
}) => {
  const [dateClear, setDateClear] = useState(true);
  // console.log(dateOfBirthData, "dateOfBirthData")
  // console.log(DateTime.fromFormat(
  //     dateOfBirthData
  //     , "yyyy-M-d").toJSDate(),"uiwehfuiwerbfibwe")
  // useEffect(() => {
  //     if (dateOfBirthData) {
  //         setValue("dateOfBirth", dateOfBirthData)
  //     }
  // }, [])

  useEffect(() => {
    if (dateOfBirthData != "") {
      setValue("dateOfBirth", dateOfBirthData);
      // console.log("emptyyyyyydate")
    } else {
      if (getValues()) {
        setValue("dateOfBirth", getValues()?.dateOfBirth);
      }
    }
  }, [dateOfBirthData]);

  return (
    <>
      <div>
        <label className="text-dark">
          {/* {t("dateOfBirth", { defaultValue: "Date Of Birth" })} */}
          {/* <Trans i18nKey="dateOfBirth" defaults="Date Of Birth"/> */}
          {t(fieldList?.i18nKey, { defaultValue: fieldList?.fieldName })}
        </label>
        {fieldList?.required && <span className="text-danger">{"*"}</span>}
      </div>
      {/* 
            <Controller
                control={control}
                name="dateOfBirth"
                refs={register(`dateOfBirth`, {
                    required: false
                })}
                render={({ field: { onChange, onBlur, value, refs } }) =>

                    dateOfBirthData && dateOfBirthData != "" ?
                        <UserDateInput
                            defaultValue={DateTime.fromFormat(
                                dateOfBirthData
                                , "yyyy-M-d").toJSDate() != "Invalid Date" ? DateTime.fromFormat(
                                    dateOfBirthData
                                    , "yyyy-M-d").toJSDate() : DateTime.fromISO(dateOfBirthData).toJSDate()}
                            refs={refs}
                            key={dateClear}
                            setDateClear={setDateClear}
                            onChange={onChange}
                            placement="auto"
                            // disabledDate={(date) =>
                            //     date < DateTime.fromJSDate(new Date()).startOf("day")
                            // }
                        />
                        :
                        <UserDateInput
                            refs={refs}
                            key={dateClear}
                            setDateClear={setDateClear}
                            onChange={onChange}
                            placement="auto"
                            // disabledDate={(date) =>
                            //     date >= DateTime.fromJSDate(new Date()).startOf("day")
                            // }
                        />
                }
            /> */}
      {dateOfBirthData && dateOfBirthData != "" ? (
        <>
          <Controller
            control={control}
            name="dateOfBirth"
            refs={register(`dateOfBirth`, {
              required: fieldList?.required ? true : false,
            })}
            render={
              ({ field: { onChange, onBlur, value, refs } }) => (
                // dateOfBirthData && dateOfBirthData != "" ? (
                <UserDateInput
                  defaultValue={
                    dateOfBirthData ? new Date(dateOfBirthData) : null
                  }
                  refs={refs}
                  key={dateClear}
                  disabled={type=="customLayout" ||fieldList?.editable || !fieldList ? false : true}
                  setDateClear={setDateClear}
                  onChange={onChange}
                  placement="auto"
                  // disabledDate={(date) =>
                  //     date < DateTime.fromJSDate(new Date()).startOf("day")
                  // }
                />
              )
              // ) : (
              //   <UserDateInput
              //     refs={refs}
              //     key={dateClear}
              //     defaultValue={new Date()}
              //     // disabled={(fieldList?.editable || !fieldList) ? false : true}
              //     setDateClear={setDateClear}
              //     onChange={onChange}
              //     disabled={fieldList?.editable || !fieldList ? false : true}
              //     placement="auto"
              //     value={value}
              //     // disabledDate={(date) =>
              //     //     date >= DateTime.fromJSDate(new Date()).startOf("day")
              //     // }
              //   />
              // )
            }
          />

          {errors?.dateOfBirth && (
            <span className="text-danger ">
              {errors?.dateOfBirth?.message != "" ? (
                errors?.dateOfBirth?.message
              ) : (
                <Trans
                  i18nKey="dateOfBirthIsRequired"
                  defaults="Date Of Birth is required"
                />
              )}{" "}
            </span>
          )}
        </>
      ) : (
        <Controller
          control={control}
          name="dateOfBirth"
          refs={register(`dateOfBirth`, {
            required: fieldList?.required ? true : false,
          })}
          render={
            ({ field: { onChange, onBlur, value, refs } }) => (
              // dateOfBirthData && dateOfBirthData != "" ? (
              <UserDateInput
                defaultValue={null}
                refs={refs}
                key={dateClear}
                disabled={type=="customLayout"|| fieldList?.editable || !fieldList ? false : true}
                setDateClear={setDateClear}
                onChange={onChange}
                placement="auto"
                // disabledDate={(date) =>
                //     date < DateTime.fromJSDate(new Date()).startOf("day")
                // }
              />
            )
            // ) : (
            //   <UserDateInput
            //     refs={refs}
            //     key={dateClear}
            //     defaultValue={new Date()}
            //     // disabled={(fieldList?.editable || !fieldList) ? false : true}
            //     setDateClear={setDateClear}
            //     onChange={onChange}
            //     disabled={fieldList?.editable || !fieldList ? false : true}
            //     placement="auto"
            //     value={value}
            //     // disabledDate={(date) =>
            //     //     date >= DateTime.fromJSDate(new Date()).startOf("day")
            //     // }
            //   />
            // )
          }
        />
      )}
    </>
  );
};

export default DateOfBirthFormField;
