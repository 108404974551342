import React, { useEffect, useState, useContext } from "react";
// import PropTypes from 'prop-types';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { useForm } from "react-hook-form";
import { Modal, Checkbox } from "rsuite";
import ProfileStandard from "../ProfileStandard";
// import SummaryModal from '../forms/SummaryModal';
import SummaryFormField from "../../../profile/form/SummaryFormField";
import usePost from "../../../../customhooks/UsePost";
import { Trans } from "react-i18next";
import { t } from "i18next";
import "../../MyAccount.css";
import { v4 as uuidv4 } from "uuid";
import Summary from "./Summary";
import UserContext from "../../../../app/context/UserContext";

const SummarySession = ({
  standardFieldsList,
  resumeId,
  status,
  resumeData,
  setUpdateResumeDataData,
  scrollRef,
  setscrollRef,
  type,
  companyID : referrealcompanyID,
}) => {
  const { user, setUserDetails } = useContext(UserContext);
  const { post: updateResumeDetails, isLoading: UpdateResumeDataDataLoading } =
    usePost("/user/Updateresumedeatails");

  const [afterUpdateResumeData, setAfterUpdateResumeData] = useState({});
  const [open, setOpen] = React.useState(false);
  const {
    control,
    register,
    setValue,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm();
  const handleSummaryOpen = () => {
    setOpen(true);
    setAfterUpdateResumeData({});
  };
  const handleSummaryClose = () => {
    setOpen(false);
    setValue("yearsOfExperience", "");
    setValue("currentCompany", "");
    setValue("Summary", "");
    setshowapprove(false);
    if (type != "referalresume") {
      setscrollRef(null);
    }
  };
  const handleSummarySubmit = (data) => {
    updateResumeDetails({
      target: "summary",
      resumeID: resumeId,
      companyID: resumeData?.companyID,
      clientID: resumeData?.clientID,
      getValues: { ...data },
    })
      .then((res) => {
        if (res) {
          setUpdateResumeDataData(res);
          handleSummaryClose();
        }
      })
      .catch((error) => {
        console.error("Error while fetching Resume:", error);
      });
  };
  useEffect(() => {
    if (scrollRef == "about") {
      handleSummaryOpen();
    }
  }, [scrollRef]);

  useEffect(() => {
    if (resumeData?.resume?.professionalSummary?.summary) {
      setValue("Summary", resumeData?.resume?.professionalSummary?.summary);
    }
  }, [resumeData, open]);
  const [checked, setchecked] = useState(false);
  const [showapprove, setshowapprove] = useState(false);
  const [showGenerateButton, setShowGenerateButton] = useState(false);
  const [companyID, setCompanyID] = useState("");
  const {
    post: showGenerateButtons,
    data: showGenerateButtondata,
    isLoading: showGenerateButtonloading,
    error: showGenerateButtonerror,
  } = usePost("/user/showGenerateButton");

  //showGenerateButton
  useEffect(()=>{
    if(user && user?.companyID && user?.companyID !== ""){
      setCompanyID(user?.companyID)
    }else{
      setCompanyID(referrealcompanyID)
    }
  },[user,referrealcompanyID])

  useEffect(()=>{
    if(open && companyID != ""){
      showGenerateButtons({companyID: companyID}).then((res) => {
        if (res?.status) {
          if(res?.result?.productSettings?.jobseekerPage?.generatedResumeBuilder){
            setShowGenerateButton(res?.result?.productSettings?.jobseekerPage?.generatedResumeBuilder)
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
    }
  },[open])

  return (
    <div className="card h-100">
      <div className="card-body ">
        <div className="row">
          <div className="col-10 d-flex  align-items-center">
            <h6 className="text-primary m-0">
              <Trans i18nKey="summary" defaults="Summary" />
            </h6>
          </div>
          {status != "MERGED" &&
          status != "DISQUALIFIED" &&
          status != "DELETED" ? (
            <>
              <div className="col-2 justify-content-end d-flex">
                {standardFieldsList?.editable ? (
                  <>
                    <span
                      className="IconArea actionIcon actionIcon-sm"
                      onClick={handleSummaryOpen}
                    >
                      <FontAwesomeIcon className="mx-2" icon={faEdit} />
                    </span>
                  </>
                ) : null}
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
        <Modal size={"full"} open={open} onClose={handleSummaryClose}>
          <Modal.Header>
            <Modal.Title>
              {t(standardFieldsList?.i18nKey, {
                defaultValue: standardFieldsList?.sectionName || "Summary",
              })}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row w-100">
              <div className="col-sm-12 col-md-12">
                {/* <SummaryModal
                                    itemObj={standardFieldsList?.rows}
                                    resumeId={resumeId}
                                    control={control}
                                    register={register}
                                    setValue={setValue}
                                    errors={errors}
                                    getValues={getValues}
                                    handleSubmit={handleSubmit}
                                /> */}

                <SummaryFormField
                  summaryData={
                    resumeData?.resume?.professionalSummary?.summary
                      ? resumeData?.resume?.professionalSummary?.summary
                      : ""
                  }
                  control={control}
                  getValues={getValues}
                  setValue={setValue}
                  errors={errors}
                  register={register}
                  type="AigenerateSummary"
                  resumeData={resumeData}
                  setshowapprove={setshowapprove}
                  showGenerateButton={showGenerateButton}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="row">
              <div className="col-md-12 col-sm-12 d-flex justify-content-between">
                <span>
                  <button
                    onClick={handleSummaryClose}
                    className="btn btn-sm btn-light"
                  >
                    <Trans i18nKey="cancel" defaults="Cancel" />
                  </button>
                </span>
                <span>
                  {showapprove && (
                    <Checkbox
                      className="me-2 text-muted"
                      defaultValue={checked}
                      onChange={() => {
                        if (checked) {
                          setchecked(false);
                        } else {
                          setchecked(true);
                        }
                      }}
                    >
                      I approve the Summary template for use.
                    </Checkbox>
                  )}
                  <button
                    className="btn btn-sm btn-primary"
                    disabled={
                      UpdateResumeDataDataLoading || showapprove
                        ? !checked
                        : false
                    }
                    onClick={handleSubmit(handleSummarySubmit)}
                  >
                    {UpdateResumeDataDataLoading ? (
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    ) : null}
                    <span className="px-1">
                      <Trans i18nKey="save" defaults="Save" />
                    </span>
                  </button>
                </span>
              </div>
            </div>
          </Modal.Footer>
        </Modal>
        <div className="row">
          <div className="col-md-12">
            {/* <div className="card-body"> */}
              {/* {standardFieldsList?.rows?.map((sections, index) => {
                let colNumber = 12;
                if (sections?.columns.length > 0) {
                  colNumber = 12 / sections?.columns.length;
                }
                return ( */}
              <>
                {/* <div className="row mb-3 details" key={uuidv4()}> */}
                  {/* {sections?.columns?.map((item, index) => {
                        return (
                          <>
                            <div className={"col-" + colNumber} key={uuidv4()}>
                              {item?.fieldType == "Custom" ? (
                                <></>
                              ) : (
                                <>
                                  <ProfileStandard
                                    item={item}
                                    resumeID={resumeId}
                                    UpdateResumeData={afterUpdateResumeData}
                                    resumeData={resumeData}
                                    setUpdateResumeDataData={
                                      setUpdateResumeDataData
                                    }
                                  />
                                </>
                              )}
                            </div>
                          </>
                        );
                      })} */}
                  <Summary resume={resumeData} />
                {/* </div> */}
              </>
              {/* );
              })} */}
            {/* </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SummarySession;