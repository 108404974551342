import React, { useEffect } from "react";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

const EmailFields = ({
  register,
  errors,
  control,
  getValues,
  setError,
  clearErrors,
  emails,
}) => {
  const {
    fields: fields,
    append: emailsAppend,
    remove: remove,
    replace: emailsReplace,
  } = useFieldArray({
    control,
    name: "emails",
  });

  const emailData = (data) => {
    if (data?.text && data?.text !== "") {
      return data.text;
    } else if (data?.email && data?.email !== "") {
      return data.email;
    } else {
      return "";
    }
  };

  useEffect(() => {
    if (emails && emails.length > 0) {
      emailsReplace();
      emailsAppend(
        emails?.map((x) => {
          return {
            email: emailData(x),
          };
        })
      );
    } else {
      emailsReplace();
      emailsAppend([
        {
          email: "",
        },
      ]);
    }
  }, []);
  const { t } = useTranslation();

  function checkEmailDuplicateOnChange() {
    // console.log("changegee")
    for (let i = 0; i < fields.length; i++) {
      for (let j = 0; j < fields.length; j++) {
        if (i != j) {
          clearErrors([`emails.${j}.email`]);
          if (
            getValues(`emails.${i}.email`) == getValues(`emails.${j}.email`)
          ) {
            setError("emails[" + j + "].email", {
              type: "manual",
              message: "Email already exists",
            });
          }

          // else {
          //     clearErrors([`emails.${j}.email`])
          // }
        }
      }
    }
  }
  const handleAddEmail = () => {
    if (getValues()?.emails?.length > 0) {
      let emailFlag = true;
      for (let i in getValues()?.emails) {
        if (
          getValues()?.emails[i]?.email?.trim() == "" ||
          getValues()?.emails[i]?.email === undefined ||
          getValues()?.emails[i]?.email == null
        ) {
          emailFlag = false;
          setError("emails[" + i + "].email", {
            type: "manual",
            message: "Email is required",
          });
        } else {
          if (getValues()?.emails[i]?.email != "") {
            let pattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
            if (!pattern.test(getValues()?.emails[i]?.email)) {
              emailFlag = false;
              setError("emails[" + i + "].email", {
                type: "manual",
                message: "please enter valid email address",
              });
            }
          }
        }
      }

      for (let i = 0; i < getValues()?.emails.length; i++) {
        for (let j = 0; j < getValues()?.emails.length; j++) {
          if (i != j) {
            if (getValues()?.emails[i]?.email == getValues()?.emails[j].email) {
              emailFlag = false;
              setError("emails[" + j + "].email", {
                type: "manual",
                message: "Email already exists",
              });
            }
          }
        }
      }

      if (emailFlag) {
        clearErrors(["emails"]);
        emailsAppend({
          email: "",
          // subscribeStatus: "",
        });
      }
    } else {
      emailsAppend({
        email: "",
        // subscribeStatus: "",
      });
    }
  };

  return (
    <>
      {fields.map((field, index) => (
        <div key={field.id} className="row  d-flex   pb-2">
          <div className="col-10 ">
            <Controller
              control={control}
              name={`emails[${index}].email`}
              refs={register(`emails[${index}].email`, {
                required: true,
                pattern: {
                  value: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/gi,
                  message: "Please enter valid email address",
                },
                validate: (v) => v.replace(/\s/g, "") != "",
              })}
              render={({ field: { onChange, onBlur, value } }) => (
                <input
                  type="email"
                  className="form-control"
                  placeholder={
                    t("enter", { defaultValue: "Enter" }) +
                    " " +
                    t("Email", { defaultValue: "Email" })
                  }
                  value={value}
                  onChange={(e) => {
                    onChange(e?.target?.value);
                    // handleEmailChange()
                    checkEmailDuplicateOnChange();
                  }}
                />
              )}
            />
          </div>
          {index == 0 ? (
            <div className="col-2 align-items-end  d-flex ">
              <span
                className=" IconArea actionIcon actionIcon-sm cursorpointer"
                onClick={handleAddEmail}
              >
                <i className="fa fa-plus-circle pulscircle  pt-2"></i>
              </span>
            </div>
          ) : (
            <div className="col-2 align-items-end  d-flex ">
              <span
                className=" IconArea actionIcon actionIcon-sm cursorpointer"
                onClick={() => {
                  remove(index);
                  checkEmailDuplicateOnChange();
                }}
              >
                <i className="fa fa-minus-circle pulscircle ms-2 pt-2"></i>
              </span>
            </div>
          )}
          {errors?.["emails"]?.[index]?.["email"] && (
            <span className="text-danger">
              {errors?.["emails"]?.[index]?.["email"]?.message != ""
                ? errors?.["emails"]?.[index]?.["email"].message
                : "Email is required"}
            </span>
          )}
        </div>
      ))}
    </>
  );
};

const CareesEmailForm = ({
  emails,
  register,
  setValue,
  control,
  errors,
  handleSubmit,
  getValues,
  setError,
  clearErrors,
}) => {
  return (
    <>
      <div className="row d-flex pb-2">
        <div className="col">
          <EmailFields
            register={register}
            errors={errors}
            control={control}
            getValues={getValues}
            setError={setError}
            clearErrors={clearErrors}
            emails={emails}
          />
        </div>
      </div>
    </>
  );
};

export default CareesEmailForm;
