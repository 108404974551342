import React, { useEffect, useState } from "react";
import { Trans } from "react-i18next";
import { Button, Modal, Popover, Tooltip, Whisper } from "rsuite";
import Loading from "../../../../Loder";
import ContactDetailsView from "./ContactDetailsView";
import { PropTypes } from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBan, faCircleCheck } from "@fortawesome/free-solid-svg-icons";

const ContactDetails = ({
  resumeID,
  fieldObj,
  emails,
  phones,
  target,
  getData,
  resume,
  setUpdateResumeDataData,
  type
}) => {
  const triggerContactDetailsRef = React.useRef();
  const contactDetailsOpen = () => {
    triggerContactDetailsRef?.current?.open();
  };

  const contactDetailsClose = () => triggerContactDetailsRef.current.close();
  const [unSubscribe, setUnSubscribe] = useState(true);
  const [targetFrom, setTargetFrom] = useState("");
  useEffect(() => {
    if (target) {
      setTargetFrom(target);
    }
  }, [target]);
  useEffect(() => {
    const handleEsc = (event) => {
      if (event.keyCode === 27) {
        contactDetailsClose();
      }
    };
    window.addEventListener("keydown", handleEsc);

    return () => {
      window.removeEventListener("keydown", handleEsc);
    };
  }, []);
  useEffect(() => {
    if (phones) {
      if (phones.length > 0) {
        for (let i in phones) {
          if (phones[i]?.subscribeStatus == "Opt-out") {
            setUnSubscribe(false);
          }
        }
      }
    }
    if (emails) {
      if (emails.length > 0) {
        for (let i in emails) {
          if (emails[i]?.subscribeStatus == "Opt-out") {
            setUnSubscribe(false);
          } else {
            setUnSubscribe(true);
          }
        }
      }
    }
  }, [phones, emails]);

  useEffect(() => {}, [unSubscribe]);
  const [emailClick, setEmailClick] = useState(false);
  return (
    <>
      {emails?.length == 0 && phones?.length == 0 && target != "profilePage" ? (
          <Whisper
            placement="auto"
            trigger={emailClick ? "none" : "click"}
            ref={triggerContactDetailsRef}
            speaker={
              <Popover className="contactDetailsPopover">
                <React.Suspense fallback={<Loading />}>
                  <ContactDetailsView
                    resumeID={resumeID}
                    contactDetailsClose={contactDetailsClose}
                    fieldObj={fieldObj}
                    getData={getData}
                    triggerContactDetailsRef={triggerContactDetailsRef}
                    setEmailClick={setEmailClick}
                    resume={resume}
                    setUpdateResumeDataData={setUpdateResumeDataData}
                    type={type}
                  />
                </React.Suspense>
              </Popover>
            }
          >
            <span
              onClick={contactDetailsOpen}
              className="text-primary cursor-pointer"
            >
              <Trans i18nKey="addContacts" defaults="Add Contacts" />
            </span>
          </Whisper>
      ) : (
          <Whisper
            placement="auto"
            trigger={emailClick ? "none" : "click"}
            ref={triggerContactDetailsRef}
            speaker={
              <Popover
                className={emailClick ? "d-none" : "contactDetailsPopover"}
              >
                <React.Suspense fallback={<Loading />}>
                  <ContactDetailsView
                    resumeID={resumeID}
                    contactDetailsClose={contactDetailsClose}
                    fieldObj={fieldObj}
                    getData={getData}
                    triggerContactDetailsRef={triggerContactDetailsRef}
                    setEmailClick={setEmailClick}
                    resume={resume}
                    setUpdateResumeDataData={setUpdateResumeDataData}
                    type={type}
                  />
                </React.Suspense>
              </Popover>
            }
          >
            <span
              onClick={contactDetailsOpen}
              className="text-primary"
              style={{cursor:"pointer"}}
            >
              {targetFrom == "table" ? (
                  <div className="d-flex">
                    {unSubscribe ? (
                        <span className=" displayIcon displayIcon-sm text-success">
                          <Whisper
                            followCursor
                            speaker={
                              <Tooltip>
                                <Trans i18nKey="optIn" defaults="Opt-in" />
                                {" "}
                              </Tooltip>
                            }
                          >
                            <FontAwesomeIcon icon={faCircleCheck} />
                          </Whisper>
                        </span>
                    ) : (
                        <span className=" displayIcon displayIcon-sm text-danger">
                          <Whisper
                            followCursor
                            speaker={
                              <Tooltip>
                                <Trans
                                  i18nKey="optOut"
                                  defaults="Opt-out"
                                />
                              </Tooltip>
                            }
                          >
                            <FontAwesomeIcon icon={faBan} />
                          </Whisper>
                        </span>
                    )}
                      <Trans
                        i18nKey="contactDetails"
                        defaults="Contact Details"
                      />
                  </div>
              ) : (                  
                    <Trans
                      i18nKey="contactDetails"
                      defaults="Contact Details"
                    />
              )}
            </span>
          </Whisper>
      )}
    </>
  );
};

export default ContactDetails;
