import React, { useEffect, useRef, useState } from "react";
import "./ScheduleVideo.css";
import { Trans } from "react-i18next";
import { DateTime } from "luxon";
import { Divider, Loader } from "rsuite";
import { Card, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarDay } from "@fortawesome/free-solid-svg-icons";

function SlotsMobileView({
  data,
  isSlotSelected,
  setIsSlotSelected,
  bookedSlotDetails,
  profileCreate,
  dates,
  slotsInADay,
  slots,
  selectedDate,
  setSelectedDate,
  prepareSlotsInday,
  dateLoading,
  timeZoneRef,
  alreadyBookedSlots,
  setAlreadyBookedSlots,
  notAvailableSlots,
  setNotAvailableSlots,
  slotID,
  setSlotID,
  selectedSlot,
  setSelectedSlot,
  cancelSlot,
  setCancelSlot,
  timeFormat,
}) {
  const sliderRef = useRef(null);
  const [isLeftDisabled, setIsLeftDisabled] = useState(true);
  const [isRightDisabled, setIsRightDisabled] = useState(false);

  const scrollLeft = () => {
    if (sliderRef.current) {
      sliderRef.current.scrollBy({ left: -100, behavior: "smooth" });
    }
  };

  const scrollRight = () => {
    if (sliderRef.current) {
      sliderRef.current.scrollBy({ left: 100, behavior: "smooth" });
    }
  };

  const handleScroll = () => {
    if (sliderRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = sliderRef.current;

      setIsLeftDisabled(scrollLeft === 0);
      setIsRightDisabled(scrollLeft + clientWidth >= scrollWidth);
    }
  };

  useEffect(() => {
    if (selectedDate) {
      setSelectedDate(selectedDate);
    } else {
      setSelectedDate(dates[0]);
    }
  }, [dates]);

  useEffect(() => {
    if (sliderRef.current) {
      handleScroll();
      sliderRef.current.addEventListener("scroll", handleScroll);

      return () => {
        if (sliderRef.current) {
          sliderRef.current.removeEventListener("scroll", handleScroll);
        }
      };
    }
  }, [sliderRef.current, dates]);

  return (
    <>
      {dateLoading ? (
        <Loader backdrop />
      ) : (
        <>
          <div className="py-0">
            <div
              className="d-flex justify-content-between align-items-center"
              style={{ fontSize: "0.75rem", padding: "0 0.5rem" }}
            >
              <p className="text-muted mt-0 pt-0 mb-0 h6">
                <i className="fa-solid fa-calendar-days"></i>{" "}
                <Trans i18nKey="selectDate" defaults="Select Date" />{" "}
              </p>
              <p className="textMorgin mt-0 mb-0 h6">{timeZoneRef.current}</p>
            </div>
            <div className="text-center text-dark">
              <h6 className="p-2">
                <big>
                  {dates?.length !== 0 &&
                    DateTime.fromISO(selectedDate).toFormat("MMMM")}
                </big>
              </h6>
            </div>
            {dates.length === 0 ? (
              <div className="text-center text-muted mt-4 w-100 card p-4 mx-0">
                <Trans
                  i18nKey="noDatesAvailable"
                  defaults="No dates available"
                />
              </div>
            ) : (
              <div className="slider-container d-flex align-items-center justify-content-between">
                <button
                  className="arrow left-arrow"
                  onClick={scrollLeft}
                  disabled={isLeftDisabled}
                >
                  <i className="fa-solid fa-angle-left"></i>
                </button>
                <div className={`dateSlider d-flex `}/* ${dates.length <= 4 ? "justify-content-center" : "" } */
                  ref={sliderRef}
                >
                  {dates.map((date, index) => {
                    const formattedDate = DateTime.fromISO(date);
                    return (
                      <div
                        key={index}
                        className={`date-box text-center text-muted border ${
                          selectedDate === date
                            ? "text-primary border-primary shadow"
                            : "text-muted border-muted "
                        }`}
                      >
                        <div
                          onClick={() => {
                            prepareSlotsInday(date);
                            setSelectedDate(date);
                          }}
                        >
                          {formattedDate.toFormat("ccc")}
                        </div>
                        <div>{formattedDate.toFormat("dd")}</div>
                      </div>
                    );
                  })}
                </div>
                <button
                  className="arrow right-arrow"
                  onClick={scrollRight}
                  disabled={isRightDisabled}
                >
                  <i className="fa-solid fa-angle-right"></i>
                </button>
              </div>
            )}
          </div>

          <div className="card mt-2">
            <div className="h6">
              <FontAwesomeIcon
                icon={faCalendarDay}
                className="ms-3 me-3 mt-3 text-secondary"
              />
              <Trans i18nKey="selectSlot" defaults="Select Slot" />
            </div>
            <div className="scrollable-slots-container ">
              {slotsInADay.length > 0 ? (
                <div className="row align-items-center">
                  {slotsInADay.map((slot) => (
                    <div className="col-xs-12 col-sm-6" key={slot?._id}>
                      {(alreadyBookedSlots?.length > 0 &&
                        alreadyBookedSlots?.includes(slot?._id)) ||
                      (notAvailableSlots?.length > 0 &&
                        notAvailableSlots?.includes(slot?._id)) ? (
                        <div className="shadow-sm rounded text-center my-2  border border-primary text-primary notAvailable ">
                          {`${DateTime.fromISO(slot?.startDate)
                            .setZone(slot?.timeZoneFullName)
                            .toFormat(timeFormat)} - ${DateTime.fromISO(
                            slot?.endDate
                          )
                            .setZone(slot?.timeZoneFullName)
                            .toFormat(timeFormat)}`}
                        </div>
                      ) : (
                        <>
                          {slotID === slot?._id ? (
                            <div className="d-flex row slotButtons text-primary justify-content-evenly">
                              <div className="col-6">
                                <Button
                                  className="btn-secondary-soft"
                                  size="sm"
                                  variant="light"
                                  onClick={() => {
                                    setSlotID(null);
                                    setCancelSlot(slot?._id);
                                  }}
                                >
                                  <Trans i18nKey="cancel" defaults="Cancel" />
                                </Button>
                              </div>
                              <div className="col-6 text-end">
                                <Button
                                  variant="primary"
                                  className="btn-soft "
                                  size="sm"
                                  onClick={() => setSelectedSlot(slot)}
                                >
                                  <Trans i18nKey="confirm" defaults="Confirm" />
                                </Button>
                              </div>
                            </div>
                          ) : (
                            <div
                              onClick={() => {
                                setSlotID(slot?._id);
                                setCancelSlot(null);
                              }}
                              className={`shadow-sm rounded bg-white text-center cursor-pointer my-2 p-2 slot ${
                                cancelSlot === slot?._id
                                  ? "border border-primary text-primary"
                                  : "border border-muted"
                              }`}
                            >
                              {`${DateTime.fromISO(slot?.startDate)
                                .setZone(slot?.timeZoneFullName)
                                .toFormat(timeFormat)} - ${DateTime.fromISO(
                                slot?.endDate
                              )
                                .setZone(slot?.timeZoneFullName)
                                .toFormat(timeFormat)}`}
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  ))}
                </div>
              ) : (
                <div className="col-12 d-flex justify-content-center align-items-center h-100 p-5">
                  <div className="text-center">
                    <Trans
                      i18nKey="noSlotsAvailable"
                      defaults="No Slots Available"
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default SlotsMobileView;
