import React from "react";
import io from "socket.io-client";

// export const socket = io("http://localhost:3104", {
//     // transports: ["websocket", "polling"],
//     "transports": ['websocket'],
// });

export const socket = io({
    transports: ["websocket", "polling"],
    path: "/jsv4socket.io",
  }
);

export const SocketContext = React.createContext();
