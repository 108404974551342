import React, { useEffect,useContext } from "react";
import { Modal, Button, toaster, Notification, Form } from "rsuite";
import EducationModal from "./EducationModal";
// import {Form} from "rsuite";
import usePost from "../../../../customhooks/UsePost";
import { DateTime } from "luxon";
import { Trans } from "react-i18next";
import { t } from "i18next";
import UserContext from "../../../../app/context/UserContext";
const EducationSessionEdit = ({
  control,
  register,
  setValue,
  errors,
  getValues,
  educationList,
  educationRowsList,
  handleSubmit,
  educationRefetch,
  clearErrors,
  resumeId,
  companyID,
  clientID,
  open,
  setError,
  setOpen,
  standardFieldsList,
  setUpdateResumeDataData,
  setscrollRef
}) => {
  const { post: updateResumeDetails, isLoading: UpdateResumeDataDataLoading } =
    usePost("/user/Updateresumedeatails");

  const handleEducationClose = () =>{ 
    clearErrors()
    setOpen(false);
    setscrollRef(null)
  };
  const { user, setUserDetails } = useContext(UserContext);
  const submitEducationDetails = () => {
    let data = getValues();
    let flag = true;
    let educationDetails = [];
    if (data?.education?.length > 0) {
      for (let i in data?.education) {
        let educationObj = {
          campus: "",
          university: "",
          degree: "",
          major: [],
          minor: [],
          meritGPA: "",
          meritPercentage: "",
          locationObJ: {},
          meritType: "",
          status: "",
          location: "",
          startDate: "",
          endDate: "",
        };
        if (
          DateTime.fromJSDate(data?.education[i].startDate).toMillis() >
          DateTime.fromJSDate(data?.education[i].endDate).toMillis()
        ) {
          flag = false;
          setError(
            `education[${i}].endDate`,
            {
              type: "manual",
              message: t("endDateShouldBeGreaterThan", {
                defaultValue: "End date should be greater than start date",
              }),
            },
            { shouldFocus: true }
          );
        } else {
          flag = flag ? true : false;
        }

        if (
          data?.education[i]?.startDate == null ||
          data?.education[i]?.startDate == "" ||
          data?.education[i]?.startDate == undefined
        ) {
          educationObj.startDate = "";
        } else {
          // console.log(data?.education[i]?.startDate, "sdgfnkdnfkdnfnkdnf");
          // educationObj.startDate = data?.education[i]?.startDate?.toISOString()
          educationObj.startDate = DateTime.fromJSDate(
            data?.education[i].startDate
          ).toFormat("yyyy-MM-dd");
        }
        if (
          data?.education[i]?.endDate == null ||
          data?.education[i]?.endDate == "" ||
          data?.education[i]?.endDate == undefined
        ) {
          educationObj.endDate = "";
        } else {
          // educationObj.endDate = data?.education[i]?.endDate?.toISOString()
          educationObj.endDate = DateTime.fromJSDate(
            data?.education[i].endDate
          ).toFormat("yyyy-MM-dd");
          // DateTime.fromJSDate(
          //   data?.education[i].endDate
          // ).toISO();
        }
        educationObj.campus = data?.education[i].campus;
        educationObj.university = data?.education[i].university;
        educationObj.degree = data?.education[i].degree;
        educationObj.major = data?.education[i].major;
        educationObj.minor = data?.education[i].minor;
        educationObj.meritGPA = data?.education[i].meritGPA;
        educationObj.meritPercentage = data?.education[i].meritPercentage;
        educationObj.meritType = data?.education[i].meritType;
        educationObj.status = data?.education[i].status;
        educationObj.location = data?.education[i].location;
        educationObj.locationObJ = data?.education[i].locationObJ
          ? data?.education[i].locationObJ
          : {};
        educationDetails.push(educationObj);
      }
      // console.log(educationDetails, "kdnvkndvnsdvmnsvnmsdnff");
      if (flag) {
        updateResumeDetails({
          target: "email",
          resumeID: resumeId,
          companyID: companyID,
          clientID: clientID,
          user:user,
          getValues: { education: educationDetails, "sectionName": standardFieldsList?.sectionName ? standardFieldsList?.sectionName : "Education"},
          type:"Profile Edited"
        })
          .then((res) => {
            if (res) {
              setUpdateResumeDataData(res);
              handleEducationClose();
            }
          })
          .catch((error) => {
            console.error("Error while fetching Resume:", error);
          });
      } else {
        toaster.push(
          <Notification
            type={"warning"}
            header={<Trans i18nKey="warning" defaults="Warning" />}
            closable
          >
            <Trans
              i18nKey="endDateShouldBeGreaterThanStartDate"
              defaults="End Date Should Be Greater Than Start Date"
            />
          </Notification>,
          {
            placement: "topEnd",
          }
        );
      }
    } else {
      updateResumeDetails({
        target: "education",
        resumeID: resumeId,
        companyID: companyID,
        user:user,
        clientID: clientID,
        getValues: { education: [], "sectionName": standardFieldsList?.sectionName ? standardFieldsList?.sectionName : "Education" },
        type:"Profile Edited"
      })
        .then((res) => {
          if (res) {
            setUpdateResumeDataData(res);
            handleEducationClose();
          }
        })
        .catch((error) => {
          console.error("Error while fetching Resume:", error);
        });
    }
  };
  return (
    <>
      <Modal size={"lg"} open={open} onClose={handleEducationClose}>
        <Modal.Header>
          <Modal.Title>
            {/* {t(standardFieldsList?.i18nKey, {
              defaultValue: standardFieldsList?.sectionName,
            })} */}
            <Trans i18nKey="education" defaults="Education" />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row w-100">
            <div className="col-sm-12 col-md-12">
              <Form>
                <EducationModal
                  control={control}
                  register={register}
                  setValue={setValue}
                  errors={errors}
                  setError={setError}
                  target={"profilePage"}
                  getValues={getValues}
                  educationList={educationList}
                  clearErrors={clearErrors}
                  educationRowsList={educationRowsList}
                  handleSubmit={handleSubmit}
                />
              </Form>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="row mt-3">
            <div className="col-md-12 col-sm-12 d-flex justify-content-between">
              <span>
                <button
                  onClick={handleEducationClose}
                  className="btn btn-sm btn-light"
                >
                  <Trans i18nKey="cancel" defaults="Cancel" />
                </button>
              </span>
              <span>
                <button
                  onClick={handleSubmit(submitEducationDetails)}
                  disabled={UpdateResumeDataDataLoading}
                  className="btn btn-sm btn-primary"
                >
                  {UpdateResumeDataDataLoading && (
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  )}
                  <span>
                    <Trans i18nKey="save" defaults="Save" />
                  </span>
                </button>
              </span>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default EducationSessionEdit;
