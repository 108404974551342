import React, { useState, useRef, useContext, useEffect } from "react";
import "../SearchBar.css";
import { Dropdown, Button, Form, FormControl } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { Notification, useToaster, Placeholder } from "rsuite";
// import { set } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";

export default function LocationAttribute({
  locationAttributes,
  //handleSelectedLocations  filteredLocationAttributes
  getJobs,
  setJobsListData,
  setPage,
  setTotal,
  selectedOptions,
  setSelectedOptions,
  setSearchvalue,
  SearchBodyData,
  setAttributeData,
  setShowJobDetails,
}) {
  const [searchQuery, setSearchQuery] = useState("");
  const [locationtags, setlocationtags] = useState([]);
  const { t } = useTranslation();
  // useEffect(() => {
  //   if (SearchBodyData && SearchBodyData?.filter) {
  //     const locationData = SearchBodyData?.filter.find(
  //       (item) => item?.type === "location"
  //     );

  //     if (
  //       locationData &&
  //       locationData?.location &&
  //       locationData?.location.length > 0
  //     ) {
  //       const selectedLocations = locationData?.location.map((loc) => ({
  //         key: {
  //           city: loc.city,
  //           state: loc.state,
  //           postalCode: loc.postalCode,
  //         },
  //       }));

  //       setSelectedOptions(selectedLocations);
  //     }
  //   }
  // }, [SearchBodyData, setSelectedOptions]);

  const [showDropdown, setShowDropdown] = useState(false);
  const [type, setType] = React.useState("error");
  const toaster = useToaster();

  const handleDropdownSelect = (option) => {
    // console.log(locationtags);
    if (
      locationtags.some(
        (selectedLocation) =>
          selectedLocation?.city === option.city &&
          selectedLocation?.state === option.state &&
          selectedLocation?.postalCode === option.postalCode &&
          selectedLocation?.country === option.country
      )
    ) {
      setlocationtags((prevOptions) =>
        prevOptions.filter(
          (item) =>
            item.city !== option.city ||
            item.state !== option.state ||
            item.postalCode !== option.postalCode ||
            item.country !== option.country
        )
      );
    } else {
      setlocationtags([...locationtags, option]);
    }
  };
  useEffect(() => {
    if (showDropdown) {
      setlocationtags(selectedOptions);
      // console.log(selectedOptions, "selectedOptions");
    }
  }, [showDropdown]);
  const locations = locationtags.map((option) => ({
    city: option?.city,
    state: option?.state,
    postalCode: option?.postalCode,
    country: option?.country,
  }));

  const handleResetButtonClick = () => {
    setSearchQuery("");
    const queryString = window.location.search;
    const parameters = new URLSearchParams(queryString);
    // parameters.delete("loc");
    // let location;
    // if (parameters.get("loc")) {
    //   location = [JSON.parse(parameters.get("loc"))];
    // } else {
    //   location = [];
    // }

    let body = {
      size: 6,
      page: 1,
      skip: 0,
      searchID: parameters.get("searchID") || "",
      newSearch: false,
      filter: [
        {
          type: "location",
          location: [],
        },
      ],
    };

    getJobs(body)
      .then((res) => {
        setShowJobDetails(false);
        setJobsListData(res?.data?.result ?? []);
        setTotal(res?.data?.totalResults ?? 0);
        setAttributeData(res?.data?.attributesAll);
        setSelectedOptions([]);
      })
      .catch((error) => {
        console.error("Error while fetching jobs:", error);
      });

    setPage(1);
    setShowDropdown(false);

    setSearchvalue(0);
    if (parameters.has("page")) {
      parameters.set("page", 1);
    }
    if (parameters.has("index")) {
      parameters.set("index", 1);
    }
    window.history.replaceState(
      {},
      "",
      `${window.location.pathname}?${parameters}`
    );
  };

  const handleApplyButtonClick = () => {
    if (locations.length === 0) {
      toaster.push(
        <Notification type={type} header={t("error", { defaultValue: "Error" })} closable>
          {/* <h6>Please select at least one option in the list</h6> */}
          {t("PleaseSelectAtLeastOneOptionInTheList", { defaults: "Please select at least one option in the list" })}
        </Notification>,
        {
          placement: "topEnd",
          duration: 3000,
        }
      );
      return;
    }

    const queryString = window.location.search;
    const parameters = new URLSearchParams(queryString);

    let filter;
    // if (parameters.get("loc")) {
    //   filter = [
    //     {
    //       type: "location",
    //       location: [...locations, JSON.parse(parameters.get("loc"))],
    //     },
    //   ];
    //   setSelectedOptions([...locations, JSON.parse(parameters.get("loc"))]);
    // } else {
    filter = [
      {
        type: "location",
        location: locations,
      },
    ];
    setSelectedOptions(locations);
    //}
    // setSelectedOptions(locations);
    let body = {
      size: 6,
      page: 1,
      skip: 0,
      searchID: parameters.get("searchID"),
      filter: filter,
      newSearch: false,
    };
    getJobs(body)
      .then((res) => {
        setShowJobDetails(false);
        setJobsListData(res?.data?.result ?? []);
        setTotal(res?.data?.totalResults ?? 0);
        setAttributeData(res?.data?.attributesAll);
      })
      .catch((error) => {
        console.error("Error while fetching jobs:", error);
      });

    setPage(1);
    setShowDropdown(false);
    setSearchvalue(0);
    if (parameters.has("page")) {
      parameters.set("page", 1);
    }
    if (parameters.has("index")) {
      parameters.set("index", 1);
    }
    window.history.replaceState(
      {},
      "",
      `${window.location.pathname}?${parameters}`
    );
  };

  /*  const filteredLocationAttributes = locationAttributes?.filter((option) =>
    option?.key?.city.toLowerCase().includes(searchQuery.toLowerCase())
  );
  */

  const formattedSearchQuery = searchQuery.trim().toLowerCase();
  const filteredLocationAttributes = locationAttributes?.filter((option) => {
    const formattedCity = option?.key?.city.toLowerCase();
    const formattedState = option?.key?.state.toLowerCase();
    const formattedPostalCode = option?.key?.postalCode.toLowerCase();
    const formattedLocationString = `${formattedCity}, ${formattedState}`;

    const cleanedSearchQuery = formattedSearchQuery.replace(/[^\w\s,]/gi, "");

    const searchTerms = cleanedSearchQuery.split(/\s*,\s*|\s+/);

    return searchTerms.every(
      (term) =>
        formattedCity.includes(term) ||
        formattedState.includes(term) ||
        formattedPostalCode.includes(term) ||
        formattedLocationString.includes(term)
    );
  });

  return (
    <>
      <Dropdown
        show={showDropdown}
       // onToggle={(isOpen) => setShowDropdown(isOpen)}
        onToggle={(isOpen) =>{ setShowDropdown(isOpen) ;setSearchQuery("")}}
        className="position-static"
      >
        <Dropdown.Toggle
          variant="none"
          id="dropdown-basic"
          className="attributeStyles text-muted rounded-pill"
          size="md"
        >
          {/* Location */}
          <Trans i18nKey="location" defaults="Location" />
        </Dropdown.Toggle>

        <Dropdown.Menu className="dropdown-menu-custom ">
          <FormControl
            type="text"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            // placeholder="Search location.."
            placeholder={t("searchLocation", { defaultValue: "Search Location" })}
            className="mx-2 mb-2 atributesSearch  mx-auto"
          />
          <Form className="dropdown-form   ">
            <div className="scrollable-options">
              {filteredLocationAttributes?.map((option, index) => {
                const cityNames = option?.key?.city
                  .split(" ")
                  .map(
                    (cityName) =>
                      cityName.charAt(0).toUpperCase() + cityName.slice(1)
                  )
                  .join(" ");
                const stateNames = option?.key?.state
                  .split(" ")
                  .map(
                    (stateName) =>
                      stateName.charAt(0).toUpperCase() + stateName.slice(1)
                  )
                  .join(" ");
                return (
                  <div key={index} className="ms-3">
                    <Form.Check
                      className="custom-checkbox"
                      type="checkbox"
                      id={`option-${index}`}
                      label={` ${cityNames}, ${stateNames} (${option?.doc_count})`}
                      checked={locationtags.some(
                        (selectedLocation) =>
                          selectedLocation?.city === option?.key?.city &&
                          selectedLocation?.state === option?.key?.state &&
                          selectedLocation?.postalCode ===
                            option?.key?.postalCode &&
                          selectedLocation?.country === option?.key?.country
                      )}
                      onChange={() => handleDropdownSelect(option?.key)}
                    />
                  </div>
                );
              })}
            </div>
            <Dropdown.Divider />
            <div className="d-flex justify-content-between ">
              <button
                type="button"
                className="justify-content-center btn btn-light text-center d-flex ms-3 reset-button"
                onClick={handleResetButtonClick}
              >
               <Trans i18nKey="reset" defaults="Reset"/>
              </button>
              <Button
                variant="primary"
                onClick={handleApplyButtonClick}
                className="justify-content-center text-center d-flex me-3"
              >
               <Trans i18nKey="apply" defaults="Apply"/>
              </Button>
            </div>
          </Form>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
}
