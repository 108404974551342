import React from 'react';
import PropTypes from 'prop-types';
import StandardForms from './StandardForms';

const CompensationModal = ({ itemObj, resumeId, control, getValues, setValue, errors, register,handleSubmit }) => {
    return (
        <>
            {itemObj?.map((column, index) => {
                let colNumber = 12;
                if (column?.columns.length > 0) {
                    colNumber = 12 / column?.columns.length;
                }
                return (
                    
                        <div className="row my-3" key={index}>
                            {column?.columns?.map((columnList, index) => {
                                return (
                                    
                                        <div className={"col-sm-12 col-md-" + colNumber} key={index}> 
                                            <StandardForms itemList={columnList} resumeId={resumeId} control={control} getValues={getValues} setValue={setValue} errors={errors} register={register} handleSubmit={handleSubmit}  />
                                        </div>
                                
                                )
                            })
                            }
                        </div>
                
                )
            })}
        </>
    )
};

export default CompensationModal;